import { clone } from 'ramda';
import { isNilEmpty } from 'src/lib/isNilEmpty';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const internalPurge = (o: any) => {
  const src = Object.keys(o);
  for (let i = src.length - 1; i >= 0; i--) {
    const k = src[i];
    if (!isNilEmpty(o[k]) && typeof o[k] === 'object') {
      internalPurge(o[k]);

      if (isNilEmpty(o[k])) {
        delete o[k];
      }
    } else if (isNilEmpty(o[k])) {
      Array.isArray(o) ? o.splice(parseInt(k, 10), 1) : delete o[k];
    }
  }

  return o;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const purge = (o: any) => internalPurge(clone(o));
