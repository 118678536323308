import { createReducer } from '@reduxjs/toolkit';
import { settingsActions } from 'src/state/settings/settingsActions';

export interface SettingsState {
  productsPreference: 'new' | 'used';
}

const initialState: SettingsState = {
  productsPreference: 'new',
};

export const settingsReducer = createReducer<SettingsState>(
  initialState,
  (builder) =>
    builder.addCase(settingsActions.setProductsPreference, (state, action) => {
      state.productsPreference = action.payload;
    })
);
