import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { skewLeft } from 'src/common/styles/skew';
import ArrowRight from 'src/assets/icons/ArrowRight';

const Container = styled.div`
  flex: 0 0 7.5rem;
  max-height: 7.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.w.min.px768(css`
    display: none;
  `)};
`;

const EnterButton = styled(Button)`
  ${media.w.max.px768(css`
    &.btn-skew.btn-default > .btn__content {
      padding-right: 1.1875rem;
      ${skewLeft('1.4375rem')};
    }
  `)}
`;

export interface HomeMobileEntranceProps {
  onClick: () => void;
}

export const HomeMobileEntrance = memo<HomeMobileEntranceProps>(
  ({ onClick }) => (
    <Container>
      <EnterButton onClick={onClick} kind="skew" icon={ArrowRight}>
        ZAPOZNAJ SIĘ Z OFERTĄ
      </EnterButton>
    </Container>
  )
);
