import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M9.99414 0H40.4668l15.5278 15.8888V64H9.99414V0zm2.99996 3v58h40.0005V18.5H37.9941V3h-25zm28 1.83183L51.42 15.5H40.9941V4.83183zM45.9945 27H19.9941v-3h26.0004v3zm0 10.5H19.9941v-3h26.0004v3zm0 10.5H19.9941v-3h26.0004v3z"
    />
  </IconBox>
);
