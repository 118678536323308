import { createSelector } from 'reselect';
import { RootState } from 'src/state/reducer';
import { CompanyConfirmationState } from 'src/state/companyConfirmation/companyConfirmationReducer';

export const companyConfirmationStateSelector = (state: RootState) =>
  state.companyConfirmation;

export const companyConfirmationEmailSelector = createSelector(
  companyConfirmationStateSelector,
  (state: CompanyConfirmationState) => state.companyEmail
);

export const companyConfirmationViewStateSelector = createSelector(
  companyConfirmationStateSelector,
  (state: CompanyConfirmationState) => state.companyConfirmationViewState
);

export const needConfirmationSelector = createSelector(
  companyConfirmationStateSelector,
  (state: CompanyConfirmationState) => state.needConfirmation
);
