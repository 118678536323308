import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const ConstraintContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;

  ${media.w.min.px768(css`
    padding: 0 2rem;
  `)};
`;
