import React, { FC } from 'react';
import { environment } from 'src/config/environment';
import TagManager from 'react-gtm-module';

export const GoogleAnalytics: FC = () => {
  if (environment.gtmMeasurementId) {
    TagManager.initialize({ gtmId: environment.gtmMeasurementId });
  }

  return <></>;
};
