import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M31.5748 9.06791C30.4561 9.25383 28.6811 10.2206 26.6873 11.7264C23.7811 13.9264 19.6873 17.7562 15.5498 22.1561C14.5311 23.2406 14.2623 23.5628 13.9561 24.1081C13.3811 25.1245 13.2873 25.6946 13.3686 27.5723C13.4373 29.0906 13.4998 29.357 13.7811 29.4252C14.0186 29.481 14.1186 29.419 15.0561 28.6196C17.9686 26.116 21.2686 23.6062 24.0936 21.7347C27.3123 19.5967 29.7998 18.3945 31.4561 18.1652C32.1748 18.066 32.8436 18.159 33.8748 18.4998C36.8623 19.479 42.0873 22.9183 47.8373 27.6962C48.6873 28.4027 49.5186 29.0906 49.6686 29.2145C50.0686 29.5492 50.3561 29.5182 50.4936 29.1278C50.6061 28.8179 50.6936 26.2957 50.6186 25.738C50.4623 24.6535 49.9811 23.7921 48.8373 22.5651C45.6061 19.0947 41.6623 15.2898 39.0623 13.127C35.4436 10.1214 33.0498 8.82003 31.5748 9.06791ZM32.8561 10.2454C34.4811 10.7597 37.1248 12.6994 40.9373 16.1511C43.1811 18.19 48.2998 23.3831 48.8748 24.2073C49.1623 24.6287 49.4873 25.3476 49.5623 25.7256C49.6311 26.085 49.6373 27.2624 49.5686 27.5475C49.5311 27.7148 49.5248 27.7148 48.6248 26.965C42.6311 21.9826 37.3811 18.5494 34.1248 17.4897C33.0623 17.1427 32.1998 17.0125 31.5123 17.1055C29.6498 17.3534 27.2873 18.4193 24.1623 20.4209C21.6248 22.0445 18.1186 24.6597 15.3623 26.9774L14.4686 27.7272L14.4248 27.4608C14.4061 27.312 14.3811 26.8906 14.3811 26.525C14.3686 24.8456 14.6748 24.418 17.5311 22.1375C21.4248 19.039 26.1498 16.1573 30.1498 14.4531C31.6686 13.8087 31.8373 13.6104 31.9373 12.4019C31.9686 12.0115 31.9998 11.3236 31.9998 10.8713V10.047L32.2373 10.0842C32.3623 10.109 32.6436 10.1834 32.8561 10.2454Z" />
    <path d="M31.1871 22.0698C28.6496 22.7638 24.2559 26.228 18.2497 32.2763C16.5122 34.0301 14.6997 35.945 14.3434 36.4035C14.0247 36.8249 13.6559 37.5314 13.4997 38.0334C13.3247 38.5849 13.2809 39.533 13.3622 40.9212C13.4247 42.0305 13.5059 42.3093 13.7747 42.3775C13.9809 42.4271 14.1184 42.3403 14.8747 41.6896C17.8747 39.0993 22.1371 35.8768 24.8122 34.1788C28.1184 32.0718 30.4496 31.0617 31.9996 31.0617C34.5934 31.0617 39.6996 34.0549 46.6871 39.6694C47.3559 40.2085 48.3309 41.0141 48.8559 41.4603C49.3809 41.9065 49.8684 42.3031 49.9434 42.3403C50.1371 42.4457 50.3621 42.3651 50.4809 42.1358C50.6246 41.8569 50.7434 39.3719 50.6371 38.6779C50.5434 38.0272 50.2434 37.2835 49.8184 36.6638C49.4059 36.0565 48.0246 34.5692 45.7809 32.3073C41.6434 28.1429 38.3309 25.2427 35.8121 23.588C33.6184 22.1379 32.3996 21.7413 31.1871 22.0698ZM32.6934 23.1047C35.2246 23.8421 40.8496 28.5519 47.1809 35.2385C48.8434 36.9985 49.1809 37.4446 49.4809 38.3122C49.5996 38.6593 49.6246 38.8452 49.6246 39.688C49.6246 40.2271 49.6121 40.6671 49.5934 40.6671C49.5746 40.6671 49.1309 40.3077 48.6059 39.8677C42.7809 35.0216 37.5934 31.6132 34.3059 30.473C32.5496 29.8657 31.4496 29.8657 29.6871 30.473C26.4059 31.6132 21.2184 35.0216 15.4059 39.8615C14.8747 40.3015 14.4247 40.6671 14.4059 40.6671C14.3872 40.6671 14.3747 40.2147 14.3747 39.657C14.3747 37.9094 14.5497 37.5624 16.0684 36.2672C20.2372 32.7039 25.7121 29.246 30.1246 27.3868C31.6746 26.73 31.8434 26.5316 31.9434 25.3046C31.9684 24.908 31.9996 24.2325 31.9996 23.7926V23.0055H32.1746C32.2746 23.0055 32.5059 23.0489 32.6934 23.1047Z" />
    <path d="M48.1875 46.0765C47.4062 46.4855 47.7437 47.1548 48.7687 47.223C49.2 47.2477 49.2375 47.2354 49.7 46.9937C49.9687 46.8511 50.2375 46.7396 50.3 46.7396C50.3625 46.7396 50.5375 46.8202 50.6937 46.9131C51.0625 47.1486 51.3375 47.2354 51.6875 47.2354C52.2875 47.2354 52.75 46.9441 52.75 46.5785C52.75 46.1757 52.2062 45.8968 51.5375 45.9464C51.2187 45.9774 51.0625 46.0269 50.7562 46.2128C50.3125 46.4855 50.1937 46.4793 49.6875 46.1695C49.4125 46.0022 49.2875 45.965 48.9125 45.9464C48.5375 45.9278 48.425 45.9464 48.1875 46.0765Z" />
    <path d="M3.53121 48.1033C2.30621 48.1777 1.59996 48.4504 0.906207 49.132C-0.418793 50.421 -0.206293 52.4227 1.35621 53.4514C2.05621 53.9162 2.59371 54.0587 3.81871 54.1145C5.18121 54.1764 7.28746 54.0959 7.77496 53.9657C8.65621 53.724 9.48121 53.0919 9.84371 52.3731L9.99996 52.0694H8.85621H7.71246L7.48121 52.3793C7.19371 52.7573 6.71246 53.0796 6.22496 53.2159C5.93746 53.3026 5.66246 53.315 4.91246 53.3026C3.98746 53.2779 3.95621 53.2717 3.54996 53.0734C2.97496 52.7945 2.54371 52.3235 2.37496 51.7782C2.22496 51.2948 2.21871 51.0345 2.34371 50.5636C2.51871 49.9005 3.01871 49.3427 3.68121 49.0825C3.99371 48.9585 4.13746 48.9399 5.09996 48.9399H6.16246L6.63746 49.1754C6.89996 49.2994 7.19371 49.4977 7.29371 49.6092C7.38746 49.727 7.53121 49.8943 7.60621 49.981L7.74996 50.1484H8.88121H10.0062L9.83121 49.8261C9.42496 49.0701 8.43121 48.3884 7.46871 48.2025C6.94996 48.0971 4.60621 48.0414 3.53121 48.1033Z" />
    <path d="M38.3435 48.1653C37.706 48.2025 37.4435 48.2458 37.1185 48.3636C35.9185 48.8098 35.1747 49.6216 34.9872 50.6999C34.7372 52.1314 35.8497 53.5691 37.506 53.9595C38.0872 54.0897 41.9122 54.0897 42.4935 53.9595C43.7622 53.6621 44.7685 52.7077 45.006 51.5861C45.281 50.2537 44.381 48.8965 42.8747 48.3698C42.1872 48.1343 40.4497 48.0537 38.3435 48.1653ZM40.831 48.9709C41.631 49.0886 42.2685 49.5162 42.606 50.1669C42.8435 50.6255 42.881 51.3382 42.6872 51.8339C42.4247 52.528 41.7122 53.0981 40.931 53.2469C40.4935 53.3274 39.456 53.3274 39.0497 53.2407C38.3247 53.0857 37.7122 52.6334 37.3997 52.026C37.2372 51.71 37.2185 51.6046 37.2185 51.1089C37.2185 50.4396 37.3435 50.1235 37.7747 49.665C38.3747 49.0267 39.481 48.7788 40.831 48.9709Z" />
    <path d="M11.3438 48.3018C11.3251 48.3452 11.3188 49.6342 11.3251 51.1711L11.3438 53.9597L12.4563 53.9783L13.5626 53.9907V51.233C13.5626 49.4421 13.5376 48.4258 13.5001 48.3452C13.4376 48.2337 13.3563 48.2275 12.4001 48.2275C11.6376 48.2275 11.3626 48.2461 11.3438 48.3018Z" />
    <path d="M14.8562 48.2709C14.8312 48.2895 14.8125 48.5435 14.8125 48.8286V49.3429H15.9375C17.1687 49.3429 17.3687 49.3863 17.7062 49.7024C18.0875 50.0618 18.0937 50.0742 18.125 52.1006L18.1562 53.9597L19.2687 53.9783L20.375 53.9907V51.766C20.375 49.8201 20.3875 49.5227 20.475 49.4421C20.5562 49.3553 20.8 49.3429 22.1625 49.3429H23.75V48.903C23.75 48.1469 24.275 48.2275 19.2562 48.2275C16.8562 48.2275 14.875 48.2461 14.8562 48.2709Z" />
    <path d="M25.6375 48.3576C25.4813 48.4382 25.2938 48.5993 25.2 48.7418L25.0312 48.9959V51.4747V53.9597L26.1438 53.9783L27.25 53.9907V52.9682V51.9395L28.5813 51.9581C30.1375 51.9829 30.3875 52.051 30.9625 52.5716C31.3313 52.9062 31.6875 53.5135 31.6875 53.8172V53.9907H32.8188H33.9563L33.9125 53.7366C33.8125 53.1293 33.275 52.4786 32.6 52.1502C32.4063 52.0572 32.25 51.9643 32.25 51.9395C32.25 51.9209 32.4188 51.8403 32.6188 51.766C33.0563 51.6111 33.5688 51.1773 33.7688 50.8054C33.9563 50.446 33.9563 49.7271 33.7688 49.3677C33.5813 49.0145 33.0625 48.5683 32.6375 48.401C32.2813 48.2584 32.2563 48.2584 29.0938 48.2399C25.9313 48.2275 25.9063 48.2275 25.6375 48.3576ZM31.2563 49.3119C31.5125 49.4483 31.6875 49.7519 31.6875 50.0742C31.6875 50.4274 31.5313 50.6815 31.2188 50.8302C30.9813 50.948 30.825 50.9542 29.1063 50.9542H27.25V50.2415C27.25 49.5598 27.2563 49.5164 27.4063 49.3739L27.5563 49.219H29.3125C30.775 49.219 31.1 49.2376 31.2563 49.3119Z" />
    <path d="M46.2124 48.308C46.1936 48.3576 46.1874 49.4793 46.1999 50.8116L46.2186 53.2223L46.3874 53.4764C46.4874 53.6251 46.6624 53.78 46.8249 53.8606C47.0999 53.9907 47.1624 53.9907 50.5124 53.9907C52.3936 53.9907 53.9686 53.9721 54.0249 53.9535C54.1061 53.9226 54.1249 53.8172 54.1249 53.4578V52.9992H51.3811C48.9686 52.9992 48.6249 52.9868 48.5374 52.9C48.4624 52.8257 48.4374 52.6646 48.4374 52.1874V51.5739H50.7811H53.1249V51.0781V50.5823H50.7811H48.4374V50.006C48.4374 49.5227 48.4561 49.4049 48.5499 49.3243C48.6561 49.2314 48.9436 49.219 51.3999 49.219H54.1311L54.1124 48.7356L54.0936 48.2585L50.1686 48.2399C46.9311 48.2275 46.2436 48.2399 46.2124 48.308Z" />
    <path d="M55.4503 48.3018C55.344 48.4072 55.344 53.811 55.4503 53.9164C55.5003 53.9659 55.844 53.9907 56.5753 53.9907H57.6253V51.7226C57.6253 50.2787 57.6503 49.4173 57.6878 49.3367C57.7503 49.2252 57.8315 49.219 59.1628 49.219C60.469 49.219 60.5878 49.2314 60.8315 49.3491C61.194 49.5289 61.419 49.7519 61.6128 50.1176L61.7815 50.4274L61.8003 52.1316C61.8128 53.2099 61.844 53.8606 61.8878 53.9102C61.9378 53.9721 62.219 53.9907 62.9753 53.9907H64.0003V52.6088C64.0003 51.8527 63.969 51.0347 63.9378 50.7992C63.7628 49.5908 62.7565 48.5993 61.4065 48.3204C60.8253 48.2027 55.569 48.1841 55.4503 48.3018Z" />
  </IconBox>
);
