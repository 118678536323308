import { OptionFilter } from 'src/app/common/models/filter';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { MOTORCYCLE_ID } from 'src/common/constants';

export const isMotorcycles = () => {
  const { state: brandState } = useOptionFilterControl(OptionFilter.Brand);
  if (brandState.value.includes(MOTORCYCLE_ID)) {
    return true;
  }
  return false;
};
