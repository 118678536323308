import { createSelector } from 'reselect';
import { SearchState } from 'src/app/search/state';
import { FilterGroupPanel } from 'src/app/search/models/filterGroupPanel';
import { SearchPanelState } from 'src/app/search/state/searchPanel/searchPanelReducer';

export const searchPanelStateSelector = (state: SearchState) =>
  state.searchPanel;

export const activeSearchPanelSelector = createSelector(
  searchPanelStateSelector,
  (state: SearchPanelState) => state.activePanel
);

export const createActiveSearchPanelComparatorSelector = () =>
  createSelector(
    [
      activeSearchPanelSelector,
      (_: SearchState, group: FilterGroupPanel) => group,
    ],
    (activePanel: string | undefined, group) => activePanel === group
  );
