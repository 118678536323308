import React, { memo, useCallback, useRef } from 'react';
import { StyleProps } from '@summer/react-kit';
import { RangeFilter } from 'src/app/common/models/filter';
import { filterConfig } from 'src/app/common/models/filterConfig';
import { useRangeFilterControl } from 'src/app/search/hooks/useFilterControl';
import { RangeSliderProps } from 'src/app/common/components/RangeSlider';
import {
  SearchFilterSlider,
  SearchFilterSliderContainer,
  SearchFilterSliderPrefix,
} from 'src/app/search/components/SearchPanel/SearchFilterSlider';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import CloseBold from 'src/assets/icons/CloseBold';

export interface CommonOptionFilterProps<T extends RangeFilter>
  extends StyleProps {
  kind: T;
  pristine: boolean;
  reset: () => void;
  roundValue?: boolean;
  value: [number, number];
  onChange: (v: [number, number]) => void;
  thumbLabelComponent?: RangeSliderProps['thumbLabelComponent'];
  width?: string;
}

export const unitMapper = (unit?: string) => {
  switch (unit) {
    case 'g / km':
      return 'g';
    case 'l / 100km':
      return 'l';
    case 'cm³':
      return 'l';
    default:
      return unit;
  }
};

const StyledButton = styled(Button)`
  position: absolute;
  right: -3.5rem;

  &.btn-link > .btn__content > .btn__label {
    margin: 0;
    font-size: 0.625rem;
    color: rgba(255, 255, 255, 0.5);
  }

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

export const CommonRangeFilter = memo(
  <T extends RangeFilter>({
    kind,
    pristine,
    reset,
    roundValue,
    value,
    onChange,
    className,
    style,
    width,
    thumbLabelComponent,
  }: CommonOptionFilterProps<T>) => {
    const reactSliderOptions = useRef({
      min: filterConfig[kind].min,
      max: filterConfig[kind].max,
      step: filterConfig[kind].step,
    });

    const onAfterChange = useCallback((v) => onChange(v as [number, number]), [
      onChange,
    ]);

    return (
      <SearchFilterSliderContainer className={className} style={style}>
        <SearchFilterSlider
          width={width}
          onAfterChange={onAfterChange}
          value={value}
          roundValue={roundValue}
          reactSliderOptions={reactSliderOptions.current}
          unit={unitMapper(filterConfig[kind].unit)}
          prefix={
            <SearchFilterSliderPrefix
              name={filterConfig[kind].name}
              sub={filterConfig[kind].unit}
              onClear={!pristine ? reset : undefined}
            />
          }
          thumbLabelComponent={thumbLabelComponent}
        />
        {!pristine && (
          <StyledButton
            kind="link"
            variant="transparent"
            size="small"
            style={{ marginLeft: rem(8) }}
            icon={CloseBold}
            iconProps={{ size: 13, align: 'right' }}
            onClick={reset}
          >
            wyczyść
          </StyledButton>
        )}
      </SearchFilterSliderContainer>
    );
  }
);

export const ConnectedCommonRangeFilter = memo(
  <T extends RangeFilter>({
    kind,
    className,
    roundValue,
    thumbLabelComponent,
    width,
    style,
  }: StyleProps & {
    kind: T;
    roundValue?: boolean;
    thumbLabelComponent?: RangeSliderProps['thumbLabelComponent'];
    width?: string;
  }) => {
    const { state, pristine, setValue, reset } = useRangeFilterControl(kind);

    return (
      <CommonRangeFilter
        kind={kind}
        className={className}
        style={style}
        width={width}
        roundValue={roundValue}
        value={state.value}
        onChange={setValue}
        reset={reset}
        pristine={pristine}
        thumbLabelComponent={thumbLabelComponent}
      />
    );
  }
);
