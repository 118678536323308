import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { typography } from 'src/config/typography';

export const Label = styled.h3`
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 0.375rem;
  font-size: 0.8125rem;

  ${media.w.min.px1200(css`
    font-size: 0.875rem;
  `)}
`;

export const LabelRoboto = styled(Label)`
  font-family: ${typography.fontFamilyDefault};
`;
