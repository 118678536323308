import { countLabelFormatter } from '@summer/react-kit/functions';
import { FinancingType, PackageType } from 'src/common/models/offer';

export const contractPeriodLabelMapper = countLabelFormatter(
  '0 miesięcy',
  '1 miesiąc',
  (count) => `${count} mieciące`,
  (count) => `${count} miesięcy`
);

export const financingModelAvailabilityLabelMapper = countLabelFormatter(
  '0 modelach finansowania',
  '1 modelu finansowania',
  (count) => `${count} modelach finansowania`,
  (count) => `${count} modelach finansowania`
);

export const financingTypeMapper = {
  [FinancingType.Rent]: 'Najem',
  [FinancingType.Leasing]: 'Leasing',
  [FinancingType.Loan]: 'Kredyt',
};

export const packageTypeMapper = {
  [PackageType.Basic]: 'Bazowy',
  [PackageType.Premium]: 'Kompleksowy',
};
