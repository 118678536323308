import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.3312 61.1322C44.5639 61.1322 44.7526 61.3208 44.7526 61.5536V63.107C44.7526 63.3397 44.5639 63.5284 44.3312 63.5284H36.8829C36.6502 63.5284 36.4615 63.3397 36.4615 63.107V61.7079C36.4615 61.6289 36.4838 61.5514 36.5257 61.4844L41.225 53.9711H36.9241C36.6913 53.9711 36.5027 53.7825 36.5027 53.5498V51.9758C36.5027 51.743 36.6913 51.5544 36.9241 51.5544H44.1254C44.3582 51.5544 44.5468 51.743 44.5468 51.9758V53.3234C44.5468 53.4027 44.5245 53.4803 44.4824 53.5474L39.7229 61.1322H44.3312ZM46.4097 56.8687L45.8738 57.1123C45.7434 57.1716 45.5918 57.1605 45.4713 57.0829C45.3509 57.0054 45.2781 56.8719 45.2781 56.7287V55.4633C45.2781 55.298 45.3746 55.148 45.5251 55.0797L46.4097 54.6776V47.3052C46.4097 47.0724 46.5984 46.8838 46.8311 46.8838H48.652C48.8847 46.8838 49.0734 47.0724 49.0734 47.3052V53.4728L49.7746 53.1556C49.905 53.0966 50.0564 53.1078 50.1767 53.1854C50.297 53.263 50.3696 53.3963 50.3696 53.5395V54.8049C50.3696 54.9704 50.2727 55.1206 50.1219 55.1888L49.0734 55.6631V63.107C49.0734 63.3397 48.8847 63.5284 48.652 63.5284H46.8311C46.5984 63.5284 46.4097 63.3397 46.4097 63.107V56.8687Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.2296 59.9489C29.8152 62.3414 27.5667 63.5277 24.6309 63.5277C21.7786 63.5277 19.5655 62.3858 18.1233 60.0872C16.7266 57.8613 16.0542 54.6186 16.0001 50.4719L16 50.4612L16.0001 45.4661C16.0001 41.1648 16.6287 37.816 17.9945 35.5349L17.9964 35.5317C19.4255 33.1699 21.6715 32 24.5909 32C27.4558 32 29.6744 33.1044 31.0963 35.3571C32.4815 37.5307 33.1541 40.7148 33.2216 44.7932L33.2218 44.8067L33.2217 49.9018C33.2217 54.2432 32.5917 57.6304 31.2296 59.9489ZM28.8667 50.8608C28.8401 53.8446 28.4871 56.0624 27.8078 57.5144C27.1284 58.9663 26.0695 59.6922 24.6309 59.6922C23.1789 59.6922 22.1 58.9263 21.394 57.3945C20.688 55.8493 20.335 53.5449 20.335 50.4812V44.487C20.3617 41.5965 20.7147 39.4386 21.394 38.0133C22.0734 36.5747 23.139 35.8554 24.5909 35.8554C26.0828 35.8554 27.1684 36.5881 27.8477 38.0533C28.5271 39.5052 28.8667 41.7697 28.8667 44.8467V50.8608Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9165 29.0223L22.9313 29.0144L23.0015 28.9776C23.0667 28.944 23.1681 28.893 23.3033 28.8285C23.5738 28.6993 23.9786 28.5167 24.4984 28.3122C25.5395 27.9027 27.032 27.4094 28.822 27.0807C32.4041 26.4231 37.1145 26.434 41.8129 29.0218C42.2185 29.2452 42.7129 29.2327 43.1066 28.9892L43.1076 28.9887L43.1169 28.9831L43.1762 28.9483C43.2322 28.916 43.3205 28.8662 43.4391 28.8031C43.6766 28.6766 44.035 28.4967 44.4991 28.2949C45.4289 27.8907 46.7738 27.4026 48.4146 27.0773C51.6876 26.4284 56.1196 26.4278 60.8291 29.0218C61.4498 29.3637 62.23 29.1376 62.5719 28.517C62.9137 27.8963 62.6877 27.1161 62.0671 26.7742C56.7103 23.8238 51.6342 23.8232 47.9156 24.5604C46.0607 24.9281 44.539 25.4796 43.4761 25.9417C43.0488 26.1275 42.6942 26.2995 42.4202 26.4403C37.2655 23.8196 32.1712 23.857 28.3586 24.557C26.3735 24.9214 24.72 25.4677 23.559 25.9244C23.0471 26.1258 22.629 26.3105 22.3184 26.4559C17.1834 23.8248 12.3458 23.8507 8.7664 24.5604C6.91154 24.9281 5.38995 25.4797 4.32715 25.9418C3.795 26.1731 3.37556 26.3831 3.0843 26.5382C2.9386 26.6158 2.82476 26.6798 2.74469 26.726C2.70465 26.7491 2.67304 26.7678 2.65009 26.7815L2.62219 26.7984L2.61316 26.8039L2.60989 26.8059L2.60856 26.8067C2.60856 26.8067 2.60745 26.8074 3.28307 27.8979L2.60745 26.8074C2.00511 27.1806 1.81935 27.9714 2.19253 28.5737C2.56553 29.1758 3.35573 29.3616 3.95795 28.9892L3.95886 28.9886L3.96817 28.9831L4.02753 28.9482C4.08347 28.9159 4.17172 28.8662 4.29038 28.803C4.52784 28.6766 4.88618 28.4967 5.35027 28.2949C6.27997 27.8907 7.62476 27.4026 9.26543 27.0773C12.5382 26.4285 16.9699 26.4278 21.6794 29.0218C22.0645 29.2339 22.5313 29.2341 22.9165 29.0223Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3629 2.627e-06C33.0715 -0.00141592 33.6471 0.571841 33.6485 1.28041L33.6504 2.24093C49.6245 2.80745 62.731 13.8864 62.731 27.8966C62.731 28.6052 62.1565 29.1796 61.448 29.1796C60.7394 29.1796 60.165 28.6052 60.165 27.8966C60.165 15.3336 47.9394 4.78413 32.3655 4.78413C16.7917 4.78413 4.56602 15.3336 4.56602 27.8966C4.56602 28.6052 3.99161 29.1796 3.28305 29.1796C2.57448 29.1796 2.00007 28.6052 2.00007 27.8966C2.00007 13.8853 15.1086 2.80567 31.0844 2.24079L31.0825 1.28554C31.0811 0.576978 31.6544 0.00142109 32.3629 2.627e-06ZM27.0122 9.08055C27.6048 9.469 27.7703 10.2643 27.3819 10.8569C24.7855 14.8177 22.5298 20.5066 23.5682 27.7137C23.6692 28.415 23.1826 29.0655 22.4813 29.1665C21.7799 29.2676 21.1295 28.7809 21.0285 28.0796C19.8791 20.1019 22.391 13.7901 25.2359 9.45018C25.6244 8.85758 26.4196 8.69209 27.0122 9.08055ZM37.7187 9.08055C38.3113 8.69209 39.1066 8.85758 39.4951 9.45018C42.34 13.7902 44.8509 20.102 43.7016 28.0796C43.6005 28.7809 42.9501 29.2676 42.2487 29.1665C41.5474 29.0655 41.0608 28.415 41.1618 27.7137C42.2002 20.5065 39.9454 14.8176 37.3491 10.8569C36.9606 10.2643 37.1261 9.469 37.7187 9.08055Z"
    />
  </IconBox>
);
