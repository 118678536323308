import React, { ReactNode } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { MainMenuDrawer } from 'src/common/components/MainMenuDrawer';
import { Provider } from 'react-redux';
import { persistor, store } from 'src/state';
import 'src/assets/styles.scss';
import { SnackbarProvider } from 'notistack';
import { AutoUnfocusObserver } from 'src/lib/AutoUnfocusObserver';
import { StoreSnackbarController } from 'src/common/components/StoreSnackbarController';
import { ThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'src/config/muiTheme';
import { CookiesBanner } from 'src/common/components/CookiesBanner';
import { GoogleAnalytics } from 'src/common/components/GoogleAnalytics';
import { LivechatProvider } from 'src/common/components/LivechatProvider';

export const gatsbyBrowserRootWrapper = ({
  element,
}: {
  element: ReactNode;
}) => (
  <ThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AutoUnfocusObserver>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <LivechatProvider>
              <MainMenuDrawer />
              {element}
            </LivechatProvider>
            <StoreSnackbarController />
            <CookiesBanner />
            <GoogleAnalytics />
          </SnackbarProvider>
        </AutoUnfocusObserver>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);
