import React, { FC } from 'react';
import { CleanUnorderedList, ListItem } from 'src/common/components/List';
import { EquipmentItem } from 'src/app/common/models/product';
import styled, { css } from 'styled-components';
import { CategoryEquipmentListTitle } from 'src/app/product/components/ProductPageSectionEquipment/CategoryEquipmentListTitle';
import { isNotNilEmpty } from 'src/lib/isNilEmpty';
import { StyleProps } from '@summer/react-kit';

const StyledCleanUnorderedList = styled(CleanUnorderedList)`
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
`;

const ItemCode = styled.span`
  font-weight: bold;
  margin-right: 0.25rem;
`;

const EquipmentListItem = styled<
  FC<StyleProps & { overflowEllipsis?: boolean }>
>(ListItem)`
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;

  ${({ overflowEllipsis }) =>
    overflowEllipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export interface CategoryEquipmentListProps {
  label?: string;
  flatEquipmentItems?: string[];
  equipmentItems?: EquipmentItem[];
  displayCodes?: boolean;
  overflowEllipsis?: boolean;
}

export const CategoryEquipmentList: FC<
  CategoryEquipmentListProps & StyleProps
> = ({
  label,
  flatEquipmentItems,
  equipmentItems,
  displayCodes = false,
  overflowEllipsis = false,
}) => (
  <>
    {isNotNilEmpty(equipmentItems) && (
      <div>
        {label && (
          <CategoryEquipmentListTitle>{label}</CategoryEquipmentListTitle>
        )}
        <StyledCleanUnorderedList>
          {equipmentItems.map((item) =>
            displayCodes ? (
              <EquipmentListItem
                key={item.id}
                overflowEllipsis={overflowEllipsis}
              >
                {item.code && <ItemCode>{item.code}</ItemCode>}
                <span>{item.label}</span>
              </EquipmentListItem>
            ) : (
              <EquipmentListItem
                key={item.id}
                overflowEllipsis={overflowEllipsis}
              >
                <div>{item.label}</div>
              </EquipmentListItem>
            )
          )}
        </StyledCleanUnorderedList>
      </div>
    )}
    {isNotNilEmpty(flatEquipmentItems) && (
      <div>
        {label && (
          <CategoryEquipmentListTitle>{label}</CategoryEquipmentListTitle>
        )}
        <StyledCleanUnorderedList>
          {flatEquipmentItems.map((item) => (
            <EquipmentListItem key={item} overflowEllipsis={overflowEllipsis}>
              <span>{item}</span>
            </EquipmentListItem>
          ))}
        </StyledCleanUnorderedList>
      </div>
    )}
  </>
);
