import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75391 11H60.4388V60.2695H3.75391V11ZM6.75391 14H57.4388V57.2695H6.75391V14Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0H13V4H0V64H64V4H51V0H48V4H16V0ZM48 10V7H16V10H13V7H3V61H61V7H51V10H48Z"
    />
    <path d="M10.5781 19.9412H21.8056V29.8274H10.5781V19.9412Z" />
    <path d="M26.4824 19.9412H37.7099V29.8274H26.4824V19.9412Z" />
    <path d="M42.3867 19.9412H53.6142V29.8274H42.3867V19.9412Z" />
  </IconBox>
);
