import React, { FC } from 'react';
import { MainMenu } from 'src/common/components/MainMenu';
import { Logo } from 'src/common/components/Logo';
import { Button } from 'src/common/components/Button';
import { authActions } from 'src/state/auth/authActions';
import { media } from 'src/config/breakpoints';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { HomePageRoute } from 'src/app/routes';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';

const Container = styled.div`
  display: none;
  height: 100%;

  ${media.w.min.px768(css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `)}
`;

const Header = styled.div`
  height: 5vh;
  min-height: 3.5rem;
  display: flex;
`;

export const MainMenuAside: FC = () => {
  const isLogged = useSelector(isAuthenticatedSelector);
  const dispatch = useDispatch();

  return (
    <Container>
      <Header>
        <Link to={HomePageRoute}>
          <Logo variant="light" />
        </Link>
      </Header>
      <MainMenu />
      <div>
        {isLogged && (
          <Button
            kind="link"
            variant="transparent"
            onClick={() => {
              dispatch(authActions.logout());
            }}
          >
            WYLOGUJ
          </Button>
        )}
      </div>
    </Container>
  );
};
