import { createAction } from 'src/lib/createAction';
import { FilterGroupPanel } from 'src/app/search/models/filterGroupPanel';

export const searchPanelActions = {
  openCrossroad: createAction('searchPanel/open/crossroad')(),
  openGeneral: createAction('searchPanel/open/general')(),
  openPerformance: createAction('searchPanel/open/performance')(),
  openAppearance: createAction('searchPanel/open/appearance')(),
  openInterior: createAction('searchPanel/open/interior')(),
  openEquipment: createAction('searchPanel/open/equipment')(),
  openPrice: createAction('searchPanel/open/price')(),
  close: createAction('searchPanel/close')(),
} as const;

export const groupPanelToOpenActionMap = {
  [FilterGroupPanel.Crossroad]: searchPanelActions.openCrossroad,
  [FilterGroupPanel.General]: searchPanelActions.openGeneral,
  [FilterGroupPanel.Performance]: searchPanelActions.openPerformance,
  [FilterGroupPanel.Appearance]: searchPanelActions.openAppearance,
  [FilterGroupPanel.Interior]: searchPanelActions.openInterior,
  [FilterGroupPanel.Equipment]: searchPanelActions.openEquipment,
  [FilterGroupPanel.Price]: searchPanelActions.openPrice,
} as const;
