import React, {
  ComponentType,
  createElement,
  PureComponent,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { HomeAdvertSlide } from 'src/app/home/components/HomeAdvert/HomeAdvertSlide';
import {
  HomeAdvertSlideStep,
  pickSlideBackground,
} from 'src/app/home/components/HomeAdvert/utils';
import { LightDarkVariant } from 'src/common/models/lightDarkVariant';
import { values } from 'ramda';
import { homeAdvertAnimation } from 'src/app/home/components/HomeAdvert/homeAdvertAnimation';
import { HomeAdvertControls } from 'src/app/home/components/HomeAdvert/HomeAdvertControls';
import { HomeMobileDivider } from 'src/app/home/components/HomeMobileDivider';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';
import { preloadImage } from '@summer/react-kit/functions';
import { CancelablePromiseType } from 'cancelable-promise/dist/CancelablePromise';
import {
  SwipeDirection,
  SwipeListener,
} from 'src/common/components/SwipeListener';
import {
  ClearableTimeoutsMap,
  setClearableTimeout,
} from '@summer/react-kit/functions';

const Advert = styled(SwipeListener)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 0.9375rem 2.5rem 0;

  display: grid;
  grid-template-areas: 'header' 'slide-content' 'controls';
  grid-template-rows: 5rem 1fr 3.375rem;
  grid-template-columns: 100%;

  ${media.w.min.px768(css`
    grid-template-rows: 5rem 1fr 5rem;
    padding: 1.5625rem;
    justify-content: flex-end;
    grid-template-columns: 100%;
  `)};
`;

const Header = styled.div`
  grid-area: header;
  text-align: center;
  z-index: 3;
  position: relative;
`;

const Gradient = styled.div`
  position: absolute;
  z-index: 2;
  mix-blend-mode: multiply;

  ${media.w.max.px768(css`
    height: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(42, 43, 50, 0) 0%,
      rgba(42, 43, 50, 1) 100%
    );
  `)};

  ${media.w.min.px768(css`
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    width: 45%;
    background: linear-gradient(
      90deg,
      rgba(42, 43, 50, 0) 0%,
      rgba(42, 43, 50, 0.8) 100%
    );
  `)};
`;

const LoadingIndicator = styled.div`
  grid-area: slide-content;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  color: #fff;
  align-self: flex-end;
  justify-self: flex-end;
  z-index: 3;
  position: relative;

  ${media.w.min.px768(css`
    width: 17rem;
    margin-left: auto;
  `)};
`;

const LoadingIndicatorText = styled.span`
  font-size: 2rem;
  line-height: 1.5625rem;
`;

interface HomeAdvertSlideshowState {
  ready: boolean;
  previousSlideIndex: number;
  currentSlideIndex: number;
  currentSlideStep: HomeAdvertSlideStep;
  previousSlideStep: HomeAdvertSlideStep;
  variant: LightDarkVariant;
}

export interface HomeAdvertSlideshowProps {
  slides: HomeAdvertSlideType[];
  header: ComponentType<{ variant: LightDarkVariant }>;
}

export class HomeAdvertSlideshow extends PureComponent<
  HomeAdvertSlideshowProps,
  HomeAdvertSlideshowState
> {
  state: HomeAdvertSlideshowState = {
    ready: false,
    previousSlideIndex: 0,
    previousSlideStep: HomeAdvertSlideStep.hidden,
    currentSlideIndex: 0,
    currentSlideStep: HomeAdvertSlideStep.static,
    variant: 'light',
  };
  autoplayTimeout?: number;
  animationTimeouts: ClearableTimeoutsMap = {};
  autoplayPaused = false;
  promises: CancelablePromiseType<unknown>[] = [];
  unmount = false;

  get currentSlide(): HomeAdvertSlideType {
    return this.props.slides[this.state.currentSlideIndex];
  }

  get previousSlide(): HomeAdvertSlideType {
    return this.props.slides[this.state.previousSlideIndex];
  }

  componentDidMount(): void {
    if (!this.state.ready && this.props.slides.length > 0) {
      this.play();
    }
  }

  componentDidUpdate(): void {
    if (!this.state.ready && this.props.slides.length > 0) {
      this.play();
    }
  }

  scheduleNextAutoplay(): void {
    if (this.unmount) {
      return;
    }

    this.autoplayTimeout = window.setTimeout(
      () =>
        this.nextSlide(
          this.state.currentSlideIndex +
            Math.floor(Math.random() * (this.props.slides.length - 1) + 1)
        ),
      5000
    );
  }

  componentWillUnmount(): void {
    this.unmount = true;
    this.promises.forEach((a) => a.cancel());
    clearTimeout(this.autoplayTimeout);
    values(this.animationTimeouts)
      .filter((x) => x != null)
      .forEach(clearTimeout);
  }

  play(): void {
    const startBackground = pickSlideBackground(this.props.slides[0]);

    this.promises.push(
      preloadImage(startBackground.url).then(() => {
        this.setState({
          ready: true,
          variant: startBackground.variant,
        });

        this.scheduleNextAutoplay();
      })
    );
  }

  nextSlide(target: number): void {
    const normalizedTarget =
      target >= 0
        ? target % this.props.slides.length
        : this.props.slides.length - 1;

    if (
      normalizedTarget === this.state.currentSlideIndex ||
      this.state.currentSlideStep !== HomeAdvertSlideStep.static
    ) {
      return;
    }

    clearTimeout(this.autoplayTimeout);

    this.promises.push(
      preloadImage(
        pickSlideBackground(this.props.slides[normalizedTarget]).url
      ).then(() => this.animateSlides(normalizedTarget))
    );
  }

  animateSlides(target: number) {
    setClearableTimeout(
      () =>
        this.setState({
          currentSlideIndex: target,
          currentSlideStep: HomeAdvertSlideStep.onlyBackground,
          previousSlideStep: HomeAdvertSlideStep.animateOut,
        }),
      homeAdvertAnimation.contentOut.start,
      'step1',
      this.animationTimeouts
    );

    setClearableTimeout(
      () => {
        const background = pickSlideBackground(this.currentSlide);
        this.setState({
          currentSlideStep: HomeAdvertSlideStep.animateIn,
          variant: background.variant,
        });
      },
      homeAdvertAnimation.contentIn.start,
      'step2',
      this.animationTimeouts
    );

    setClearableTimeout(
      () =>
        this.setState({
          previousSlideStep: HomeAdvertSlideStep.hidden,
          previousSlideIndex: target,
        }),
      homeAdvertAnimation.backgroundOut.finish,
      'step3',
      this.animationTimeouts
    );

    setClearableTimeout(
      () => {
        this.setState({
          currentSlideStep: HomeAdvertSlideStep.static,
        });

        this.scheduleNextAutoplay();
      },
      homeAdvertAnimation.contentIn.finish,
      'step4',
      this.animationTimeouts
    );
  }

  pauseAutoplay() {
    clearTimeout(this.autoplayTimeout);
    this.autoplayPaused = true;
  }

  resumeAutoplay() {
    this.autoplayPaused = false;
    this.scheduleNextAutoplay();
  }

  onAdvertSwipe(direction: SwipeDirection) {
    if (this.unmount) {
      return;
    }

    clearTimeout(this.autoplayTimeout);
    this.nextSlide(
      direction === SwipeDirection.Left
        ? this.state.currentSlideIndex + 1
        : this.state.currentSlideIndex - 1
    );
    this.scheduleNextAutoplay();
  }

  render(): ReactNode {
    const currentSlide = this.currentSlide;
    const previousSlide = this.previousSlide;

    return (
      <Advert onSwipe={(direction) => this.onAdvertSwipe(direction)}>
        {this.state.ready && (
          <>
            <HomeAdvertSlide
              slide={currentSlide}
              step={this.state.currentSlideStep}
              onMouseEnter={() => this.pauseAutoplay()}
              onMouseLeave={() => this.resumeAutoplay()}
            />
            <HomeAdvertSlide
              slide={previousSlide}
              step={this.state.previousSlideStep}
            />
          </>
        )}
        <Gradient />
        <Header>
          {createElement(this.props.header, { variant: this.state.variant })}
        </Header>
        {/* {this.state.ready && (
          <HomeAdvertControls
            elements={this.props.slides.length}
            active={this.state.currentSlideIndex}
            onChange={(i) => this.nextSlide(i)}
          />
        )} */}
        {!this.state.ready && (
          <LoadingIndicator>
            <LoadingIndicatorText>
              Przygotowujemy specjalne&nbsp;oferty dla&nbsp;Ciebie.
            </LoadingIndicatorText>
            <br />
            To potrwa tylko chwilę.
          </LoadingIndicator>
        )}
        <HomeMobileDivider />
      </Advert>
    );
  }
}
