import { Button } from 'src/common/components/Button';
import { navigate } from 'gatsby';
import Close from 'src/assets/icons/Close';
import React, { FC } from 'react';
import { IconProps } from 'src/lib/IconBox';
import { previousPageUrlSelector } from 'src/state/pageContext/pageContextSelectors';
import { useSelector } from 'react-redux';
import { RootRoute } from '@summer/react-kit';

export const BackButton: FC<IconProps> = (props) => {
  const previousPageUrl = useSelector(previousPageUrlSelector);

  return (
    <Button
      kind="box"
      variant="transparent"
      icon={Close}
      onClick={() =>
        previousPageUrl ? navigate(previousPageUrl.url) : navigate(RootRoute)
      }
      iconProps={props}
    />
  );
};
