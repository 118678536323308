import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.713 24.615a3.598 3.598 0 00-3.598 3.598v27.266a3.598 3.598 0 003.598 3.598h27.266a3.598 3.598 0 003.598-3.598V28.213a3.598 3.598 0 00-3.598-3.598H28.713zm-8.52 3.598a8.52 8.52 0 018.52-8.52h27.266a8.52 8.52 0 018.521 8.52v27.266A8.52 8.52 0 0155.98 64H28.712a8.52 8.52 0 01-8.52-8.52V28.212z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.02 4.923a3.598 3.598 0 00-3.597 3.598v27.266a3.598 3.598 0 003.598 3.598h3.03a2.462 2.462 0 010 4.923H9.02A8.52 8.52 0 01.5 35.787V8.521A8.52 8.52 0 019.02 0h27.267a8.52 8.52 0 018.52 8.52v3.03a2.461 2.461 0 11-4.922 0V8.52a3.598 3.598 0 00-3.598-3.597H9.021z"
    />
  </IconBox>
);
