import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path d="M19.9755 44.8712C19.3006 45.3782 18.4337 45.661 17.5319 45.661H8.529C7.62437 45.661 6.75756 45.3768 6.08398 44.8712L6.1512 44.6738H19.9069L19.9755 44.8712ZM20.9908 43.9176H5.07857C4.64586 43.9176 4.41334 44.4273 4.69621 44.755L4.81669 44.8922C5.61069 45.8095 6.76456 46.3346 7.97727 46.3346H18.0822C19.2501 46.3346 20.3648 45.8473 21.156 44.9889L21.3619 44.7662C21.6615 44.4427 21.4305 43.9176 20.9908 43.9176Z" />
    <path d="M4.13379 42.5012C3.72209 42.5012 3.39027 42.1679 3.39027 41.7576C3.39027 41.3473 3.72209 41.014 4.13379 41.014C4.5441 41.014 4.87739 41.3473 4.87739 41.7576C4.87739 42.1679 4.5441 42.5012 4.13379 42.5012ZM19.9032 37.5369L3.75854 39.9372C2.87772 40.0674 2.22656 40.8236 2.22656 41.7142C2.22656 42.7057 3.03038 43.5109 4.02323 43.5109H22.949C23.9419 43.5109 24.7457 42.7057 24.7457 41.7142C24.7457 39.1348 22.4533 37.1589 19.9032 37.5369Z" />
    <path d="M20.1147 31.5792C20.1216 33.2058 21.2682 34.1141 22.6891 34.4867C24.141 34.8682 25.5321 34.5783 26.3218 33.1122L26.4087 32.9514C26.947 31.9537 26.5021 30.7169 25.514 30.4575L22.1175 29.565C21.1122 29.3009 20.1074 30.1931 20.1126 31.3423L20.1147 31.5792Z" />
    <path d="M3.42602 38.2341L2.74076 39.6925C5.73467 39.3024 10.1061 38.5947 12.342 37.7284C15.0735 36.6688 17.2731 35.3543 18.9427 34.1612C19.5946 33.6952 19.9426 32.9125 19.8525 32.1155C19.817 31.7975 19.7815 31.4794 19.7449 31.1605C19.5823 29.7146 18.0901 28.82 16.7372 29.3558L1.70068 35.315C2.04946 35.3369 2.3967 35.4471 2.70789 35.6544C3.54706 36.2133 3.85634 37.3227 3.42602 38.2341Z" />
    <path d="M4.13856 41.2683C3.86689 41.2683 3.64844 41.4882 3.64844 41.7585C3.64844 42.0287 3.86689 42.2486 4.13856 42.2486C4.40883 42.2486 4.62869 42.0287 4.62869 41.7585C4.62869 41.4882 4.40883 41.2683 4.13856 41.2683Z" />
    <path d="M43.0171 40.7076L45.8172 40.2917C45.9503 38.0077 45.8523 35.2098 45.1689 32.1109C44.5401 29.2569 43.5781 26.8889 42.6561 25.0629C42.2956 24.3487 41.5774 23.8866 40.7782 23.853C40.4586 23.8404 40.1392 23.8278 39.8198 23.8152C38.3702 23.7563 37.2608 25.0909 37.5836 26.5052L41.2251 42.4553C41.3832 41.566 42.1008 40.8435 43.0171 40.7076Z" />
    <path d="M39.3524 23.4546L39.5724 23.3692C41.0844 22.7811 41.5209 21.3891 41.3605 19.9328C41.197 18.4428 40.4296 17.251 38.7825 17.0396L38.6014 17.0158C37.48 16.8716 36.4873 17.7258 36.5984 18.741L36.9807 22.2209C37.0939 23.2516 38.2831 23.8705 39.3524 23.4546Z" />
    <path d="M43.4417 43.6083C43.0313 43.6083 42.6993 43.2764 42.6993 42.8675C42.6993 42.4572 43.0313 42.1253 43.4417 42.1253C43.8506 42.1253 44.1825 42.4572 44.1825 42.8675C44.1825 43.2764 43.8506 43.6083 43.4417 43.6083ZM59.1705 38.6581L43.068 41.0513C42.1895 41.1815 41.5391 41.9349 41.5391 42.8241C41.5391 43.8128 42.3416 44.6152 43.3311 44.6152H62.2079C63.1993 44.6152 64.0017 43.8128 64.0017 42.8241C64.0017 40.2503 61.7149 38.28 59.1705 38.6581Z" />
    <path d="M59.2385 45.972C58.565 46.4776 57.701 46.7604 56.8005 46.7604H47.8214C46.9196 46.7604 46.0542 46.4762 45.382 45.972L45.4507 45.7746H59.1713L59.2385 45.972ZM60.251 45.0212H44.3808C43.9481 45.0212 43.7156 45.5295 43.9999 45.8558L44.1189 45.993C44.9101 46.9075 46.0612 47.4326 47.2697 47.4326H57.3509C58.516 47.4326 59.6264 46.9453 60.4162 46.0897L60.6221 45.867C60.9204 45.5449 60.6907 45.0212 60.251 45.0212Z" />
    <path d="M43.4385 42.4045C43.183 42.4045 42.9746 42.6118 42.9746 42.8681C42.9746 43.1229 43.183 43.3315 43.4385 43.3315C43.6934 43.3315 43.9021 43.1229 43.9021 42.8681C43.9021 42.6118 43.6934 42.4045 43.4385 42.4045Z" />
  </IconBox>
);
