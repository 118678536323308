import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { useDispatch, useSelector } from 'react-redux';
import { isMenuOpenSelector } from 'src/state/navigation/navigationSelectors';
import { navigationActions } from 'src/state/navigation/navigationActions';
import { Button } from 'src/common/components/Button';
import {
  acceleratedEasing,
  deceleratedEasing,
  slideInRight,
  slideOutRight,
} from 'src/common/styles/animations';
import { CSSTransition } from 'react-transition-group';
import { authActions } from 'src/state/auth/authActions';
import { MainMenu } from 'src/common/components/MainMenu';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { media } from 'src/config/breakpoints';
import Close from 'src/assets/icons/Close';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { useClickOutside } from 'src/app/common/hooks/useClickOutside';

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 25rem;
  background-color: ${colors.background};
  padding: 5vh;
  z-index: 100;
  color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.w.min.px1400(css`
    padding: 2.5rem;
  `)}
  &.enter-active {
    animation: ${slideInRight} 250ms ${deceleratedEasing};
  }

  &.exit-active {
    animation: ${slideOutRight} 250ms ${acceleratedEasing} forwards;
  }
`;

const StyledScrollContainer = styled(ScrollContainer)`
  height: 100%;
  padding-right: 3.5vh;
  margin-right: -3.5vh;

  ${media.w.min.px1400(css`
    padding-right: 2rem;
    margin-right: -2rem;
  `)}
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSections = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
`;

export const MainMenuDrawer: FC = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(isAuthenticatedSelector);
  const isOpen = useSelector(isMenuOpenSelector);
  const wrapperRef = useRef(null);

  useKeyPress(KEY_ESCAPE, CODE_ESCAPE, () =>
    dispatch(navigationActions.close())
  );
  useClickOutside(wrapperRef, () => {
    dispatch(navigationActions.close());
  });

  return (
    <CSSTransition in={isOpen} timeout={250} unmountOnExit>
      <Container ref={wrapperRef}>
        <header style={{ textAlign: 'right', color: colors.white }}>
          <Button
            kind="box"
            icon={Close}
            iconProps={{ size: 36 }}
            variant="transparent"
            onClick={() => dispatch(navigationActions.close())}
          />
        </header>
        <StyledScrollContainer>
          <StyledContent>
            <StyledSections>
              <MainMenu
                onNavigate={() => dispatch(navigationActions.close())}
              />
            </StyledSections>
            <footer style={{ textAlign: 'right' }}>
              {isLogged && (
                <Button
                  kind="link"
                  variant="transparent"
                  onClick={() => {
                    dispatch(navigationActions.close());
                    dispatch(authActions.logout());
                  }}
                >
                  WYLOGUJ
                </Button>
              )}
            </footer>
          </StyledContent>
        </StyledScrollContainer>
      </Container>
    </CSSTransition>
  );
};
