import React, { FC, useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { array, object, string } from 'yup';
import {
  BaseAxios,
  getErrorMessages,
  toErrorResponse,
} from 'src/common/services/axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  userEmailSelector,
} from 'src/state/auth/authSelectors';
import { ContactFormFields } from 'src/public/components/contact/ContactFormFields';
import { useLocation } from '@reach/router';
import { ContactPageState } from 'src/public/models/contactPageState';
import { navigate } from 'gatsby';
import { ContactThankYouRoute } from 'src/public/publicRoutes';
import { currentPageUrlSelector } from 'src/state/pageContext/pageContextSelectors';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

interface ContactFormModel {
  name: string;
  email: string;
  reason: string[];
  content: string;
}

const validationSchema = object({
  name: string().required('Podaj swoje imię i nazwisko'),
  email: string()
    .email('Zwrotny adres e-mail musi być poprawnym adresem e-mail')
    .required('Podaj adres e-mail, na który będziemy mogli odpisać'),
  reason: array()
    .of(string().required('Wybierz powód kontaktu'))
    .length(1, 'Wybierz powód kontaktu'),
  content: string()
    .required('Uzupełnij komentarz')
    .max(500, 'Wiadomość może zawierać maksymalnie 500 znaków'),
});

export interface ContactFormProps {
  onSuccess: () => void;
}

export const ContactForm: FC<ContactFormProps> = ({ onSuccess }) => {
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const userEmail = useSelector(userEmailSelector);
  const currentPageUrl = useSelector(currentPageUrlSelector);

  const { state } = useLocation();
  const carCashAllowance = (state as ContactPageState)?.carCashAllowance;

  const { enqueueSnackbar } = useSnackbar();

  const initialValues: ContactFormModel = {
    name: '',
    email: userEmail,
    reason: [carCashAllowance ? 'Car Platform Business' : ''],
    content: '',
  };

  const onSubmit = useCallback(
    (
      values: ContactFormModel,
      formikHelpers: FormikHelpers<ContactFormModel>
    ) => {
      BaseAxios.post('leads/contact', { ...values, reason: values.reason[0] })
        .then(() => {
          pushEvent(EventName.Contact, {});
          navigate(ContactThankYouRoute, {
            state: { contactFormPageUrl: currentPageUrl },
          });
          onSuccess();
          setTimeout(() => {
            formikHelpers.setSubmitting(false);
            formikHelpers.resetForm({ values: initialValues });
          });
        })
        .catch((error) => {
          getErrorMessages(toErrorResponse(error)).forEach((message) =>
            enqueueSnackbar(message, { variant: 'error' })
          );
          formikHelpers.setSubmitting(false);
        });
    },
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <ContactFormFields
          showAgreement={!isLoggedIn}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  );
};
