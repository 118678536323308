import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { OrderPageSummary } from 'src/app/product/components/OrderPageSummary';
import { OrderPageRequestForm } from 'src/app/product/components/OrderPageRequestForm';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { OrderPageRequestFormGuestUser } from 'src/app/product/components/OrderPageRequestFormGuestUser';

const Content = styled.div`
  display: grid;
  grid-template-areas:
    'main'
    'sidebar';
  grid-template-columns: 1fr;
  min-height: 100%;

  ${media.w.min.px768(css`
    grid-template-areas: 'sidebar main';
    grid-template-columns: 22rem 1fr;
    grid-template-rows: 100%;
  `)};

  ${media.w.min.px992(css`
    grid-template-columns: 24rem 1fr;
  `)};
`;

const Sidebar = styled.div`
  position: relative;
  grid-area: sidebar;
  padding: 1.5rem 4rem 3rem 2.5rem;
  background: rgba(255, 255, 255, 0.03);
`;

const Main = styled.main`
  grid-area: main;
  display: grid;
  height: 100%;
  padding: 3vh calc(1.5vh + 1rem);

  ${media.w.min.px768(css`
    padding-left: 15%;
    grid-template-columns: minmax(20rem, 26rem);
    align-items: center;
  `)};
`;

export const OrderRequest = memo<{ onSubmit: () => void }>(({ onSubmit }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <Content>
      <Sidebar>
        <OrderPageSummary />
      </Sidebar>
      <Main>
        {isAuthenticated ? (
          <OrderPageRequestForm onSubmit={onSubmit} />
        ) : (
          <OrderPageRequestFormGuestUser onSubmit={onSubmit} />
        )}
      </Main>
    </Content>
  );
});
