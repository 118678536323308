import { getErrorMessages } from 'src/common/services/axios';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { companyConfirmation, confirmCompany } from 'src/common/services/user';
import { CompanyConfirmationViewState } from 'src/common/models/companyConfirmationViewState';
import {
  companyConfirmationActions,
  companyConfirmationViewActions,
  confirmCompanyActions,
} from 'src/state/companyConfirmation/companyConfirmationActions';
import { userCompanyEmailSelector } from 'src/state/auth/authSelectors';

function* companyConfirmationHandler(
  action: ReturnType<typeof companyConfirmationActions.request>
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME
  const userCompanyEmail = yield select(userCompanyEmailSelector);

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(companyConfirmation, action.payload);
    yield put(
      companyConfirmationViewActions.setCompanyConfirmationViewState(
        action.payload.companyEmail === userCompanyEmail
          ? CompanyConfirmationViewState.SuccessConfirmation
          : CompanyConfirmationViewState.SuccessChange
      )
    );
    yield put(companyConfirmationActions.requestSuccess(response));
  } catch (error) {
    yield put(companyConfirmationActions.requestFailure(error));

    if (error.statusCode === 403) {
      yield put(
        companyConfirmationViewActions.setCompanyConfirmationViewState(
          CompanyConfirmationViewState.Failure
        )
      );
      return;
    }

    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* companyConfirmationWatcher() {
  yield takeEvery(
    companyConfirmationActions.request.type,
    companyConfirmationHandler
  );
}

function* confirmCompanyHandler(
  action: ReturnType<typeof confirmCompanyActions.request>
) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(confirmCompany, action.payload);
    yield put(confirmCompanyActions.requestSuccess(response));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Aktualizacja danych przebiegła pomyślnie.',
        options: { variant: 'success' },
      })
    );
  } catch (error) {
    yield put(confirmCompanyActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* confirmCompanyWatcher() {
  yield takeEvery(confirmCompanyActions.request.type, confirmCompanyHandler);
}

export function* companyConfirmationSagas() {
  yield all([companyConfirmationWatcher(), confirmCompanyWatcher()]);
}
