import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M25.4786 1.32253C25.0742 2.06919 22.0947 7.63719 18.8598 13.6959L12.9858 24.7252L19.5621 37.0559L26.117 49.3865L27.9473 49.4505C29.6499 49.5145 29.7989 49.4719 30.0968 49.0239C30.6289 48.1705 42.9939 24.9172 42.9939 24.7465C42.9939 24.5545 34.9279 9.38652 34.6938 9.10919C34.5448 8.93852 32.7784 12.0105 32.7784 12.4159C32.7784 12.5012 34.2256 15.2532 35.9707 18.5385C37.7372 21.8239 39.1631 24.6185 39.1631 24.7465C39.1631 24.9812 28.3942 45.2052 28.1176 45.5039C28.0324 45.5892 25.436 40.9599 22.3713 35.1999L16.7741 24.7465L23.1801 12.7359C26.6916 6.14386 29.6286 0.575859 29.7137 0.362526C29.8414 0.0425262 29.6712 -0.000141144 28.0324 -0.000141144H26.2234L25.4786 1.32253Z" />
    <path d="M33.7148 0.170498C33.4168 0.362499 20.6475 24.3838 20.6475 24.7465C20.6475 24.9812 26.8619 36.8425 28.4581 39.6585L28.9901 40.5758L29.9265 38.8265L30.863 37.0772L27.7345 31.1678C25.9893 27.9252 24.5847 25.0452 24.5847 24.7465C24.5847 24.2558 35.2897 3.83983 35.5451 3.83983C35.7153 3.83983 46.5055 24.1492 46.5693 24.5972C46.6119 24.8105 43.76 30.3998 40.2485 37.0132C36.7156 43.6265 33.8425 49.1305 33.8425 49.2585C33.8425 49.4078 34.4597 49.4932 35.5025 49.4932C36.8433 49.4932 37.2477 49.4078 37.503 49.1092C37.95 48.5972 50.4427 25.0452 50.4427 24.7465C50.4427 24.4052 37.652 0.362499 37.3541 0.170498C37.2477 0.0851631 36.4177 -0.000167847 35.5451 -0.000167847C34.6725 -0.000167847 33.8425 0.0851631 33.7148 0.170498Z" />
    <path d="M2.04636 54.9546C1.93994 55.2106 2.02507 55.4453 2.21661 55.5946C2.51457 55.7866 2.55713 56.4693 2.55713 59.8186V63.7866H3.51484H4.47254V62.08V60.3733H5.30255C6.11128 60.3733 6.15384 60.416 7.13283 62.08L8.1331 63.7866H9.06952C9.58029 63.7866 10.0059 63.7226 10.0059 63.6586C10.0059 63.5733 9.55901 62.8266 9.02695 61.952C8.09053 60.4586 7.98412 59.9466 8.62259 59.9466C9.26106 59.9466 9.79312 58.88 9.79312 57.5573C9.79312 55.1466 9.17593 54.784 5.08973 54.656C2.42944 54.5706 2.17405 54.592 2.04636 54.9546ZM7.70745 56.4693C8.09053 56.8106 8.21823 57.92 7.92027 58.3893C7.66489 58.7946 6.64333 59.0933 5.51537 59.0933H4.47254V57.6V56.1066H5.89845C6.92 56.1066 7.43078 56.2133 7.70745 56.4693Z" />
    <path d="M11.2193 54.7626C10.9639 55.0186 11.0703 55.6373 11.3895 55.7653C11.6662 55.8506 11.7087 56.512 11.7087 59.84V63.7866H15.0075H18.3063V63.04V62.2933H15.8588H13.4113V61.12V59.968L15.2842 59.904L17.1357 59.84L17.1996 59.136L17.2634 58.4533H15.348H13.4113V57.28V56.1066H15.7524H18.0934V55.36V54.6133H14.7308C12.8793 54.6133 11.2831 54.6773 11.2193 54.7626Z" />
    <path d="M19.3062 54.7626C19.0508 55.0186 19.1572 55.6373 19.4764 55.7653C19.7531 55.8506 19.7957 56.512 19.7957 59.84V63.7866H20.647H21.4982V60.544V57.3013L23.8819 60.544L26.2655 63.7866H27.1806H28.0958L28.0532 59.2426L27.9894 54.72L27.2019 54.656L26.3932 54.592V57.792C26.3932 59.5626 26.3293 61.0133 26.2442 60.992C26.1378 60.992 25.0311 59.5626 23.7755 57.792L21.477 54.6133H20.4554C19.9021 54.6133 19.37 54.6773 19.3062 54.7626Z" />
    <path d="M32.3947 54.8906C31.8627 55.5093 30.0962 59.968 29.3088 62.6773L28.9683 63.7866H29.8834C30.7773 63.7866 30.7985 63.7653 31.0965 62.7626L31.4157 61.76H33.4163H35.4168L35.736 62.784C36.034 63.7653 36.0553 63.7866 36.9491 63.7866H37.843L37.6302 62.976C37.1832 61.3546 35.7147 57.4506 35.055 56.1066C34.3952 54.7626 34.3527 54.72 33.5014 54.656C32.9481 54.6133 32.5437 54.6986 32.3947 54.8906ZM34.1399 58.176C34.4804 59.0933 34.7996 59.968 34.8635 60.096C34.9273 60.3093 34.5442 60.3733 33.4163 60.3733C32.2883 60.3733 31.9052 60.3093 31.9691 60.096C32.4798 58.624 33.3311 56.5333 33.4163 56.5333C33.4801 56.5333 33.7993 57.28 34.1399 58.176Z" />
    <path d="M38.1624 54.9547C38.0773 55.168 38.1624 55.424 38.3539 55.552C38.6306 55.7653 38.7157 56.4053 38.7796 58.88C38.8434 62.2507 39.035 62.848 40.2055 63.4453C41.0781 63.9147 43.0148 64.064 44.3343 63.7867C45.2494 63.5947 45.675 63.3813 46.1645 62.8267C46.952 61.9307 47.0797 61.0987 46.9945 57.4933L46.9307 54.72H46.0794H45.2281L45.1217 58.0267C45.0579 59.84 44.9302 61.4827 44.845 61.6747C44.462 62.464 42.5678 62.7627 41.5037 62.2293C40.695 61.824 40.4609 60.8 40.4609 57.536L40.4396 54.6133H39.3755C38.5668 54.6133 38.2688 54.6987 38.1624 54.9547Z" />
    <path d="M48.4629 55.04C48.3991 55.3173 48.4629 55.5306 48.6544 55.616C48.8886 55.7013 48.9524 56.4693 48.9524 59.7546V63.7866H51.9319H54.9114V63.04V62.2933H52.8896H50.8678L50.8252 58.496L50.7614 54.72L49.676 54.656C48.7183 54.592 48.5693 54.656 48.4629 55.04Z" />
    <path d="M54.0601 55.36V56.1066H55.4434H56.8268V59.9466V63.7866H57.7845H58.7422V59.9466V56.1066H60.2532H61.743L61.6791 55.4026L61.6153 54.72L57.8483 54.656L54.0601 54.6133V55.36Z" />
  </IconBox>
);
