import React, { memo } from 'react';
import styled from 'styled-components';
import {
  ToggleButtonGroup,
  ToggleButtonGroupItemOptions,
} from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import { colors } from 'src/config/colors';
import { OfferParameterHeader } from 'src/app/product/components/ProductPageSummary/OfferParameterHeader';
import { transparentize } from 'polished';

const Container = styled.div`
  margin: 0.5rem 0;
`;

const StyledHorizontalToggleGroupContainer = styled(
  HorizontalToggleGroupContainer
)`
  width: 100%;
  background: ${transparentize(0.9, colors.white)};
  border-color: transparent;
`;

const StyledHorizontalToggleButton = styled(HorizontalToggleButton)`
  &:disabled {
    opacity: 1;
  }
`;

export interface ProductPageSummaryOfferVariantsProps {
  items: ToggleButtonGroupItemOptions[];
  name: string;
  onChange: (v: string[]) => void;
  value: string;
}

export const ProductPageSummaryOfferVariants = memo<ProductPageSummaryOfferVariantsProps>(
  (props) => (
    <Container>
      <OfferParameterHeader title={props.name} />

      <StyledHorizontalToggleGroupContainer>
        <ToggleButtonGroup
          limit={1}
          value={[props.value]}
          onChange={props.onChange}
          items={props.items}
          disabled={props.items.length === 1}
          toggleButtonComponent={StyledHorizontalToggleButton}
        />
      </StyledHorizontalToggleGroupContainer>
    </Container>
  )
);
