import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 67 85" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M15.037 17.678c0 .736.597 1.333 1.333 1.333h33.968c.736 0 1.333-.597 1.333-1.333v-5.226c0-2.9-2.35-5.253-5.253-5.253h-1.28V2c0-.736-.596-1.333-1.333-1.333H22.902c-.736 0-1.333.597-1.333 1.333v5.2h-1.28a5.252 5.252 0 00-5.252 5.252v5.226zm2.666-1.334v-3.892a2.586 2.586 0 012.586-2.586h2.613c.737 0 1.333-.597 1.333-1.333v-5.2h18.237v5.2c0 .736.597 1.333 1.333 1.333h2.613a2.586 2.586 0 012.586 2.586v3.892h-31.3z" />
      <path d="M28.102 11.146c0 .736.596 1.333 1.333 1.333h7.839c.736 0 1.333-.597 1.333-1.333V7.199H35.94v2.613h-5.172V7.2h-2.666v3.947z" />
      <path d="M.667 80.387a3.947 3.947 0 003.946 3.947h57.483a3.947 3.947 0 003.947-3.947V15.065a3.947 3.947 0 00-3.947-3.946H50.312v2.667h11.784c.706 0 1.28.573 1.28 1.279v65.322a1.28 1.28 0 01-1.28 1.28H4.613a1.28 1.28 0 01-1.28-1.28V15.065c0-.706.574-1.28 1.28-1.28h7.865V11.12H4.613a3.947 3.947 0 00-3.946 3.946v65.322z" />
      <path d="M12.424 45.113c0 .736.597 1.333 1.333 1.333h14.37c.737 0 1.334-.597 1.334-1.333v-3.92a1.333 1.333 0 10-2.666 0v2.587H15.09V32.075h5.2a1.333 1.333 0 100-2.666h-6.533c-.736 0-1.333.597-1.333 1.333v14.37zM12.424 69.936c0 .736.597 1.333 1.333 1.333h14.37c.737 0 1.334-.597 1.334-1.333V55.565c0-.737-.597-1.334-1.333-1.334H13.757c-.736 0-1.333.597-1.333 1.334v14.37zm2.666-1.334V56.898h11.704v11.704H15.09z" />
      <path d="M21.659 41.22c.374-.017.724-.191.964-.479l6.532-7.838a1.333 1.333 0 00-2.049-1.708l-5.597 6.717-1.58-1.58a1.333 1.333 0 10-1.886 1.886l2.613 2.612c.265.265.629.407 1.003.39zM34.635 37.274c0 .737.597 1.334 1.334 1.334H46.42a1.333 1.333 0 000-2.667H35.97c-.737 0-1.334.597-1.334 1.333zM34.635 45.113c0 .736.597 1.333 1.334 1.333h18.29a1.333 1.333 0 100-2.666h-18.29c-.737 0-1.334.596-1.334 1.333zM34.635 60.79c0 .737.597 1.334 1.334 1.334H46.42a1.333 1.333 0 000-2.667H35.97c-.737 0-1.334.597-1.334 1.333zM34.635 68.629c0 .736.597 1.333 1.334 1.333h18.29a1.333 1.333 0 100-2.666h-18.29c-.737 0-1.334.597-1.334 1.333z" />
    </g>
  </IconBox>
);
