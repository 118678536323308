import styled, { css } from 'styled-components';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { media } from 'src/config/breakpoints';

export const ProductSectionContainer = styled(ConstraintContainer)`
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;

  ${media.w.min.px1200(css`
    padding: 5rem 19rem 5rem 10rem;
  `)}
`;
