import React, { FC } from 'react';
import { InstructionSteps } from 'src/common/components/InstructionSteps';
import { InstructionStep } from 'src/common/models/instructionStep';
import { colors } from 'src/config/colors';
import styled, { css } from 'styled-components';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { media } from 'src/config/breakpoints';
import LPHowToStartImage1 from 'src/assets/images/LPHowToStartImage1.jpg';
import LPHowToStartImage2 from 'src/assets/images/LPHowToStartImage2.jpg';
import LPHowToStartImage3 from 'src/assets/images/LPHowToStartImage3.jpg';
import { skewBoth } from 'src/common/styles/skew';
import { Button } from 'src/common/components/Button';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { navigate } from 'gatsby';
import { ContactRoute } from 'src/public/publicRoutes';

const getNewCarInstructions: InstructionStep[] = [
  {
    title: 'ZGŁOŚ SWOJĄ FIRMĘ',
    content:
      'przez formularz kontaktowy, a nasz doradca skontaktuje się z Tobą.',
  },
  {
    title: 'PODPISZ UMOWĘ ONLINE',
    content: 'i otrzymaj dostęp do Car Platform Business dla Twojej firmy.',
  },
  {
    title: 'ZAPROŚ SWOICH PRACOWNIKÓW',
    content: 'i korzystaj z szerokiej oferty dostępnej na platformie.',
    customCounterColor: colors.primary,
  },
];

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1440px;
  margin: 0 auto 1rem;
  padding: 0 1rem;
  position: relative;

  ${media.w.min.px992(css`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    padding: 10rem 5rem 2rem;
  `)};
`;

const StyledSectionHeading = styled(SectionHeading)`
  margin-bottom: 0.625rem;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;

  ${media.w.min.px992(css`
    text-align: start;
  `)};

  & span {
    color: ${colors.primary};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  margin-top: 4.375rem;

  ${media.w.max.px992(css`
    margin-bottom: 6rem;
  `)};
`;

const AbsoluteImage = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  ${skewBoth('8rem')};
  opacity: 0.9;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 4px);
    display: inline-block;
  }
`;

const Image1 = styled(AbsoluteImage)`
  left: calc(50% - 29rem);

  ${media.w.max.px992(css`
    left: calc(50% - 13rem);

    & img {
      max-width: 24.25rem;
      max-height: 8.25rem;
    }

    ${skewBoth('3rem')};
  `)};

  &::after {
    background: linear-gradient(270deg, rgba(60, 61, 66, 0) 2%, #2a2b32 95.64%);
  }
`;

const Image2 = styled.div`
  ${skewBoth('12rem')};

  ${media.w.max.px992(css`
    & img {
      max-width: 29rem;
    }

    ${skewBoth('6rem')};
  `)};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 4px);
    display: inline-block;
    background: linear-gradient(
      270deg,
      rgba(60, 61, 66, 0) 28%,
      #2a2b32 95.64%
    );
  }
`;

const Image3 = styled(AbsoluteImage)`
  left: calc(50% + 31rem);

  ${media.w.max.px992(css`
    left: calc(50% - 1rem);
    top: calc(50% + 8.5rem);

    & img {
      max-width: 18.75rem;
    }

    ${skewBoth('3rem')};
  `)};

  &::after {
    background: linear-gradient(90deg, rgba(60, 61, 66, 0) 28%, #2a2b32 95.64%);
  }
`;

const JoinButtonContainer = styled.div`
  z-index: 2;
  text-align: center;
  margin: 2.5rem auto 0;

  ${media.w.min.px768(css`
    margin: 0 auto;
  `)};
`;

export const HowToStartSection: FC = () => (
  <Container>
    <Content>
      <div>
        <StyledSectionHeading>
          Jak przystąpić <br /> <span> do programu?</span>
        </StyledSectionHeading>
      </div>
      <InstructionSteps steps={getNewCarInstructions} />
      <JoinButtonContainer>
        <Button
          icon={ArrowRight}
          onClick={() =>
            navigate(ContactRoute, { state: { carCashAllowance: true } })
          }
        >
          Dołącz
        </Button>
      </JoinButtonContainer>
    </Content>
    <ImageContainer>
      <Image2>
        <img src={LPHowToStartImage2} alt="Krok 1" />
      </Image2>
      <Image1>
        <img src={LPHowToStartImage1} alt="Krok 2" />
      </Image1>
      <Image3>
        <img src={LPHowToStartImage3} alt="Krok 3" />
      </Image3>
    </ImageContainer>
  </Container>
);
