import React from 'react';
import { useSelector } from 'react-redux';
import { CompanyConfirmationViewState } from 'src/common/models/companyConfirmationViewState';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { CleanUnorderedList, ListItem } from 'src/common/components/List';
import { CompanyConfirmResend } from 'src/public/components/CompanyConfirmResend';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Link } from 'gatsby';
import { ContactRoute } from 'src/public/publicRoutes';
import {
  companyConfirmationEmailSelector,
  companyConfirmationViewStateSelector,
} from 'src/state/companyConfirmation/companyConfirmationSelectors';

export const CompanyConfirmationSuccess = () => {
  const companyEmail = useSelector(companyConfirmationEmailSelector);
  const companyConfirmationViewState = useSelector(
    companyConfirmationViewStateSelector
  );

  return (
    <>
      <Seo title="Potwierdzenie służbowego adresu e-mail" />

      <Title level={1} variant="condensed">
        Potwierdzenie służbowego adresu e-mail
      </Title>

      {companyConfirmationViewState ===
        CompanyConfirmationViewState.SuccessConfirmation && (
        <Paragraph size="small">
          Na Twój służbowy adres e-mail wysłaliśmy link aktywacyjny. Po
          kliknięciu w&nbsp;niego będziesz mógł zalogować się do
          Car&nbsp;Platform i&nbsp;dalej przeglądać oferty.
        </Paragraph>
      )}

      {companyConfirmationViewState ===
        CompanyConfirmationViewState.SuccessChange && (
        <Paragraph size="small">
          Na Twój nowy służbowy adres e-mail wysłaliśmy link aktywacyjny. Po
          kliknięciu w&nbsp;niego będziesz mógł zalogować się do
          Car&nbsp;Platform i&nbsp;dalej przeglądać oferty.
        </Paragraph>
      )}

      <Text as="div" size="xSmall" style={{ marginTop: '3.6vh' }}>
        Nie otrzymałeś linka?
        <br />
        Sprawdź, czy wiadomość nie trafiła do spamu.
      </Text>

      {companyEmail && (
        <CleanUnorderedList style={{ margin: 0 }}>
          <ListItem style={{ margin: 0 }}>
            <CompanyConfirmResend companyEmail={companyEmail} />
          </ListItem>
        </CleanUnorderedList>
      )}

      <Text as="div" size="xSmall" style={{ marginTop: '3.6vh' }}>
        Jeśli masz problem z&nbsp;ponowną aktywacją dostępu, skontaktuj się
        z&nbsp;nami za pomocą adresu e-mail hello@carplatform.pl lub formularza
        kontaktowego zamieszczonego na stronie
        <SimpleLink
          as={Link}
          to={ContactRoute}
          style={{ marginLeft: '0.2rem' }}
        >
          carplatform.pl/contact
        </SimpleLink>
        .
      </Text>
    </>
  );
};
