import React from 'react';
import { StyleProps } from '@summer/react-kit';
import { ConnectedCommonRangeFilter } from 'src/app/search/components/SearchPanel/CommonRangeFilter';
import { RangeFilter } from 'src/app/common/models/filter';

export const SearchCapacityPerformanceFilter = (props: StyleProps) => (
  <ConnectedCommonRangeFilter
    kind={RangeFilter.Capacity}
    roundValue={true}
    {...props}
  />
);
