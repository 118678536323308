import React, { FC } from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { StyleProps } from '@summer/react-kit';
import {
  ToggleButton,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export interface SearchEquipmentFilterToggleProps extends StyleProps {
  variant?: ToggleButtonVariant;
  labels?: [string, string];
  setValue: (value: string[]) => void;
  value: string[];
}

const StyledToggleButton = styled(ToggleButton)`
  padding: 0.25rem 0.5rem;
  height: 4.875rem;
  white-space: initial;
  display: flex;
  justify-content: center;

  ${media.w.min.px768(css`
    padding: 0.25rem 0.875rem;
    width: 9.375rem;
  `)};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.375rem;

  ${media.w.min.px768(css`
    display: none;
  `)};
`;

export const SearchEquipmentFilterToggle: FC<SearchEquipmentFilterToggleProps> = ({
  variant,
  labels = ['OPCJE WYPOSAŻENIA', 'POPULARNE PAKETY WYPOSAŻENIA'],
  setValue,
  value,
}) => {
  return (
    <Container>
      <ToggleButtonGroup
        variant={variant}
        limit={1}
        value={value}
        onChange={(v) => setValue(v)}
        items={[
          { id: 'options', label: labels[0] },
          { id: 'packages', label: labels[1] },
        ]}
        toggleButtonComponent={StyledToggleButton}
      />
    </Container>
  );
};
