import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 50 64" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M11.311 13.613c0 .737.597 1.334 1.333 1.334h25.162c.736 0 1.333-.597 1.333-1.334v-3.87a4.236 4.236 0 00-4.236-4.237H34.3V2c0-.736-.597-1.333-1.334-1.333H17.483c-.736 0-1.333.597-1.333 1.333v3.506h-.602a4.236 4.236 0 00-4.237 4.236v3.871zm2.667-1.333V9.742c0-.866.702-1.57 1.57-1.57h1.935c.736 0 1.334-.597 1.334-1.333V3.334h12.817v3.505c0 .736.597 1.333 1.333 1.333h1.936c.867 0 1.57.704 1.57 1.57v2.538H13.978z" />
      <path d="M20.99 8.772c0 .736.598 1.333 1.334 1.333h5.807c.736 0 1.333-.597 1.333-1.333v-3.27h-2.667v1.936h-3.14V5.503h-2.666v3.269z" />
      <path d="M.667 60.065a3.27 3.27 0 003.268 3.268h42.581a3.27 3.27 0 003.27-3.268V11.677a3.27 3.27 0 00-3.27-3.269h-9.075v2.667h9.075c.333 0 .603.27.603.602v48.388c0 .332-.27.602-.602.602H3.934a.603.603 0 01-.602-.602V11.677c0-.332.27-.602.602-.602h6.172V8.408H3.935a3.27 3.27 0 00-3.268 3.269v48.388z" />
      <path d="M9.239 31.668c0 .736.597 1.333 1.333 1.333h8.572c.736 0 1.333-.597 1.333-1.333V29.33a1.333 1.333 0 10-2.667 0v1.005h-5.905V24.43h2.563a1.333 1.333 0 000-2.667h-3.896c-.736 0-1.333.597-1.333 1.333v8.572zM9.239 44.856c0 .736.597 1.333 1.333 1.333h8.572c.736 0 1.333-.597 1.333-1.333v-8.572c0-.736-.597-1.333-1.333-1.333h-8.572c-.736 0-1.333.597-1.333 1.333v8.572zm2.666-1.334v-5.905h5.905v5.905h-5.905zM9.239 58.043c0 .736.597 1.333 1.333 1.333h8.572c.736 0 1.333-.597 1.333-1.333v-8.572c0-.736-.597-1.333-1.333-1.333h-8.572c-.736 0-1.333.597-1.333 1.334v8.571zm2.666-1.333v-5.905h5.905v5.905h-5.905z" />
      <path d="M15.434 29.703c.38-.017.733-.195.973-.489l3.768-4.615a1.333 1.333 0 00-2.066-1.686l-2.825 3.46-.465-.473a1.333 1.333 0 10-1.905 1.866l1.508 1.538c.265.271.633.416 1.012.4zM25.829 24.416c0 .736.597 1.333 1.333 1.333h7.742a1.333 1.333 0 100-2.666h-7.742c-.736 0-1.333.597-1.333 1.333zM25.829 30.223c0 .736.597 1.333 1.333 1.333H40.71a1.333 1.333 0 000-2.667H27.16c-.735 0-1.332.597-1.332 1.334z" />
    </g>
  </IconBox>
);
