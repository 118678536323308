import styled, { css } from 'styled-components';
import {
  TypographyBase,
  typographyBaseCss,
} from 'src/common/styles/typography';

type TextSize = 'xSmall' | 'small' | 'default';

export interface TextProps extends TypographyBase {
  size?: TextSize;
  noScrollOverflow?: boolean;
}

const noScrollOverflowStyle = css<TextProps>`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

// todo: unify with typography base
const paragraphSizeConfig: Record<TextSize, ReturnType<typeof css>> = {
  ['xSmall']: css`
    font-size: 0.75rem;
    line-height: 1.25;
  `,
  ['small']: css`
    font-size: 0.875rem;
    line-height: 1.25;
  `,
  ['default']: css`
    font-size: 1rem;
  `,
};

const baseStyle = css<TextProps>`
  ${typographyBaseCss};
  ${(props) => paragraphSizeConfig[props.size ?? 'default']};
  ${({ noScrollOverflow }) => noScrollOverflow && noScrollOverflowStyle};
  line-height: 1.4;
`;

export const Text = styled.span<TextProps>`
  ${baseStyle}
`;

export const Paragraph = styled.p<TextProps>`
  ${baseStyle}
`;
