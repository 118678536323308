import styled from 'styled-components';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const ScrollContainer = styled(OverlayScrollbarsComponent)`
  & > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    border: 0.5px solid #55555a;
    height: 3px;
    width: 9px;
  }

  & > .os-scrollbar-vertical {
    right: 4px;
  }

  & > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  & > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
    background: rgba(255, 255, 255, 1);
  }
`;

export const BlockScrollContainer = styled(ScrollContainer)`
  display: block;
  height: 100%;
  width: 100%;
`;
