import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-tag': {
    backgroundColor: 'white',
    color: 'black',
    margin: '2px',
    padding: '2px 6px',
    fontSize: '0.75rem',
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: 'white',
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: 'white',
  },
}));
export const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    padding: '6px 9px',
    fontSize: '1rem',

    '& fieldset': {
      borderColor: 'rgba(147, 150, 156, 0.4)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(147, 150, 156, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(147, 150, 156, 0.4)',
    },
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 15px) scale(0.9)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -8px) scale(0.7)',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: 'white',
  },
}));
