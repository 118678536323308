import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56433 0L32.8601 27.2957L60.1558 1.52588e-06L64.6193 4.46348L37.3236 31.7592L64.8134 59.2491L60.3499 63.7126L32.8601 36.2227L5.37022 63.7126L0.906738 59.2491L28.3966 31.7592L1.10084 4.46348L5.56433 0Z"
    />
  </IconBox>
);
