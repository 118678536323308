import { all, call, put, takeEvery } from 'redux-saga/effects';
import { businessPartnerActions } from 'src/state/businessPartner/businessPartnerActions';
import { fetchBusinessPartner } from 'src/common/services/businessPartner';

function* processFetchBusinessPartner(
  action: ReturnType<typeof businessPartnerActions.request>
) {
  const registrationPath = action.payload;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchBusinessPartner, registrationPath);
    yield put(businessPartnerActions.requestSuccess(response));
  } catch (error) {
    yield put(businessPartnerActions.requestFailure(error));
  }
}

function* fetchBusinessPartnerWatcher() {
  yield takeEvery(
    businessPartnerActions.request.type,
    processFetchBusinessPartner
  );
}

export function* businessPartnerSaga() {
  yield all([fetchBusinessPartnerWatcher()]);
}
