import React, { memo, useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormItem } from 'src/common/components/FormItem';
import { Button } from 'src/common/components/Button';
import {
  AuthorizedAxios,
  getErrorMessages,
  toErrorResponse,
} from 'src/common/services/axios';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { apiFormattedSearchFiltersSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { object, string } from 'yup';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { TextControl } from 'src/common/components/TextControl';
import {
  isAuthenticatedSelector,
  userPhoneSelector,
} from 'src/state/auth/authSelectors';
import { authActions } from 'src/state/auth/authActions';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { RequestProductSectionFormSearchFilterTags } from 'src/app/search/components/RequestProductSection/RequestProductSectionFormSearchFilterTags';
import { RequestProductSectionTitle } from 'src/app/search/components/RequestProductSection/RequestProductSectionTitle';
import { RequestProductSectionParagraph } from 'src/app/search/components/RequestProductSection/RequestProductSectionParagraph';
import { omit } from 'ramda';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { pushEvent, EventName } from 'src/common/services/googleTagManager';

const Content = styled.div`
  color: #d4d6dc;
`;

const ButtonContainer = styled.div`
  margin-top: 0.25rem;
  padding-bottom: 0.1875rem;
  display: flex;
  justify-content: center;

  ${media.w.min.px768(css`
    transform: translateX(-1.4rem);
    justify-content: flex-start;
  `)}
`;

const Heading = styled.h3`
  color: ${colors.lightGray};
  line-height: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  font-size: 0.9375rem;
  ${media.w.min.px1200(css`
    font-size: 1.125rem;
  `)}
`;

const ContactInfoFields = styled.div<{ $isAuthenticated?: boolean }>`
  display: grid;

  ${media.w.min.px768(css`
    grid-column-gap: 18px;
  `)}

  ${({ $isAuthenticated }) =>
    !$isAuthenticated &&
    css`
      ${media.w.min.px768(css`
        grid-template-columns: 1fr 1fr;
      `)}
    `}

  ${({ $isAuthenticated }) =>
    $isAuthenticated &&
    css`
      ${media.w.min.px1200(css`
        grid-template-columns: 1fr 1.4fr 1fr;
      `)}
    `}
`;

const PhoneFormItemWrapper = styled.div<{ $isAuthenticated?: boolean }>`
  ${({ $isAuthenticated }) =>
    $isAuthenticated &&
    css`
      ${media.w.between.px768.px1200(css`
        grid-column: 1/3;
      `)}
    `}
`;

const StyledTextControl = styled(TextControl)`
  margin-bottom: 0 !important;

  textarea {
    min-height: 1rem !important;
  }

  .MuiOutlinedInput-multiline {
    padding: 0.875rem;
  }
`;

const StyledRequestProductSectionFormSearchFilterTags = styled(
  RequestProductSectionFormSearchFilterTags
)`
  margin-bottom: 1.75rem;
`;

interface FormModel {
  firstName: string;
  lastName: string;
  message: string;
  phone: string;
  email?: string;
}

const validationSchema = object({
  firstName: string().required('Uzupełnij imię'),
  lastName: string().required('Uzupełnij nazwisko'),
  message: string()
    .required('Uzupełnij komentarz')
    .max(500, 'Wiadomość może zawierać maksymalnie 500 znaków'),
  phone: string()
    .required('Uzupełnij numer telefonu')
    .matches(
      /^\+?[\d\s]*$/,
      'Numer telefonu może zaczynać się od + oraz musi składać się z samych cyfr i spacji'
    )
    .max(30, 'Numer telefonu powinien składać się z maksymalnie 30 znaków'),
});

const guestValidationSchema = validationSchema.shape({
  email: string()
    .required('Uzupełnij adres email')
    .email('Podaj poprawny adres email'),
});

export interface RequestProductSectionFormProps {
  onSuccess: () => void;
}

export const RequestProductSectionForm = memo<RequestProductSectionFormProps>(
  ({ onSuccess }) => {
    const dispatch = useDispatch();
    const phone = useSelector(userPhoneSelector);
    const customerType = useSelector(customerTypeSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const selectedFilters = useSelector(apiFormattedSearchFiltersSelector);

    const { enqueueSnackbar } = useSnackbar();
    const initialValues: FormModel = isAuthenticated
      ? {
          firstName: '',
          lastName: '',
          message: '',
          phone,
        }
      : {
          firstName: '',
          lastName: '',
          message: '',
          phone: '',
          email: '',
        };

    const onSubmit = useCallback(
      (values: FormModel, formikHelpers: FormikHelpers<FormModel>) => {
        AuthorizedAxios.post('leads/dream-car', {
          filters: {
            ...omit(
              [
                'newProductOffersPagination',
                'usedProductOffersPagination',
                'customerType',
              ],
              selectedFilters
            ),
          },
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            customerType,
          },
          message: values.message,
        })
          .then(() => {
            isAuthenticated &&
              dispatch(authActions.updateUser({ phone: values.phone }));
            pushEvent(EventName.Contact, {});
            onSuccess();
          })
          .catch((error) => {
            getErrorMessages(toErrorResponse(error)).forEach((message) =>
              enqueueSnackbar(message, { variant: 'error' })
            );
            formikHelpers.setSubmitting(false);
          });
      },
      [selectedFilters]
    );

    const stopPropagation = useCallback((event) => event.stopPropagation(), []);

    return (
      <Content>
        <RequestProductSectionTitle>
          Znajdź auto Twoich marzeń
        </RequestProductSectionTitle>
        <RequestProductSectionParagraph>
          Nie możesz znaleźć swojego wymarzonego modelu? Napisz nam czego
          szukasz.
        </RequestProductSectionParagraph>

        <Heading>Twoje potrzeby</Heading>
        <StyledRequestProductSectionFormSearchFilterTags />

        <Heading>Powiedz nam czego szukasz</Heading>
        <RequestProductSectionParagraph>
          Podaj swoje dane kontaktowe i napisz na czym szczególnie Ci zależy, co
          chciałbyś mieć w swoim aucie, o czym marzysz.
        </RequestProductSectionParagraph>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={
            isAuthenticated ? validationSchema : guestValidationSchema
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <ContactInfoFields $isAuthenticated={isAuthenticated}>
                <FormItem name="firstName">
                  <StyledTextControl
                    label="Imię"
                    name="firstName"
                    onKeyDown={stopPropagation}
                  />
                </FormItem>
                <FormItem name="lastName">
                  <StyledTextControl
                    label="Nazwisko"
                    name="lastName"
                    onKeyDown={stopPropagation}
                  />
                </FormItem>

                <PhoneFormItemWrapper>
                  <FormItem name="phone">
                    <StyledTextControl
                      label="Podaj nr telefonu"
                      name="phone"
                      onKeyDown={stopPropagation}
                    />
                  </FormItem>
                </PhoneFormItemWrapper>

                {!isAuthenticated && (
                  <FormItem name="email">
                    <StyledTextControl
                      label="Podaj adres email"
                      name="email"
                      onKeyDown={stopPropagation}
                    />
                  </FormItem>
                )}
              </ContactInfoFields>

              <FormItem name="message">
                <StyledTextControl
                  label="Twój komentarz"
                  name="message"
                  multiline
                  onKeyDown={stopPropagation}
                />
              </FormItem>

              <ButtonContainer>
                <Button
                  kind="skew"
                  type="submit"
                  icon={ArrowRight}
                  disabled={isSubmitting}
                  onClick={() => {
                    pushEvent(EventName.Try, {});
                  }}
                >
                  Wyślij
                </Button>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Content>
    );
  }
);
