import React, { FC, useEffect, useState } from 'react';
import { ProductPageEquipmentProps } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentProps';
import styled from 'styled-components';
import { CleanButton, CleanButtonProps } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { CleanUnorderedList } from 'src/common/components/List';
import { CategoryEquipmentList } from 'src/app/product/components/ProductPageSectionEquipment/CategoryEquipmentList';
import { EquipmentGroup, EquipmentItem } from 'src/app/common/models/product';
import { CategoryEquipmentListContainer } from 'src/app/product/components/ProductPageSectionEquipment/CategoryEquipmentListContainer';
import { CarSpecificationLink } from 'src/app/product/components/ProductPageSectionEquipment/CarSpecificationLink';
import { ProductPageEquipmentModalLauncher } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentModalLauncher';
import { ScrollContainer } from 'src/common/components/ScrollContainer';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(0, 3fr);
  grid-template-rows: 28rem min-content;
  grid-column-gap: 3rem;
  grid-row-gap: 0.5rem;
  color: ${colors.lightGray};
`;

const CategorySelectListContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

const CarSpecificationContainer = styled.div`
  margin-right: -0.5rem;
  text-align: right;
  margin-top: auto;
`;

const EquipmentCategoryItem = styled.li`
  font-size: 0.875rem;
  line-height: 1.125;
  letter-spacing: 0;
  text-align: right;
  margin-bottom: 2rem;
`;

const StyledCategoryEquipmentListContainer = styled(
  CategoryEquipmentListContainer
)`
  padding-top: 1rem;
  padding-right: 3rem;
  overflow: hidden;
`;

const FullEquipmentListContainer = styled.div`
  grid-column-start: 2;
`;

const EquipmentCategoryItemButton = styled(CleanButton).attrs(
  (props: CleanButtonProps & { isActive?: boolean }) => ({
    className: props.isActive && 'active',
  })
)<{ isActive?: boolean }>`
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${colors.lightGray};
    margin-top: 0.5rem;
    margin-left: auto;

    transition: width 0.15s ease-in-out;
    width: 1rem;
  }

  &:hover ::after,
  &:focus ::after {
    width: 100%;
  }

  &.active {
    font-weight: 700;

    ::after {
      background-color: ${colors.primary};
      width: 100%;
    }
  }
`;

const truncateEquipmentGroups = ({
  options = [],
  standardOptions = [],
}: EquipmentGroup) => {
  const limit = 8;
  const optionsDif = limit - options.length;
  const standardOptionsDif = limit - standardOptions.length;

  const truncatedOptions =
    options.length <= limit
      ? options
      : options.slice(
          0,
          standardOptionsDif > 0 ? standardOptionsDif + limit : limit
        );
  const truncatedStandardOptions =
    standardOptions.length <= limit
      ? standardOptions
      : standardOptions.slice(0, optionsDif > 0 ? optionsDif + limit : limit);

  return [truncatedOptions, truncatedStandardOptions] as const;
};

export type ProductPageEquipmentDesktopProps = ProductPageEquipmentProps;

export const ProductPageEquipmentDesktop: FC<ProductPageEquipmentDesktopProps> = ({
  activeCategory,
  onActiveCategoryChange,
  categories,
  product,
  onModalToggle,
}) => {
  const [
    [truncatedOptions, truncatedStandardOptions],
    setActiveCategoryEquipmentGroups,
  ] = useState<readonly [EquipmentItem[], string[]]>([[], []]);

  useEffect(() => {
    if (activeCategory && product.equipment) {
      const activeEquipmentGroup = product.equipment.find(
        (group) => group.id === activeCategory.id
      );
      activeEquipmentGroup &&
        setActiveCategoryEquipmentGroups(
          truncateEquipmentGroups(activeEquipmentGroup)
        );
    }
  }, [activeCategory, product.equipment]);

  const hasFullEquipment = (product.fullEquipment ?? []).length > 0;

  return (
    <Container>
      <CategorySelectListContainer>
        <CleanUnorderedList>
          {!hasFullEquipment &&
            categories.map(({ id, label }) => (
              <EquipmentCategoryItem key={id}>
                <EquipmentCategoryItemButton
                  onClick={() =>
                    onActiveCategoryChange && onActiveCategoryChange([id])
                  }
                  isActive={id === activeCategory?.id}
                >
                  {label}
                </EquipmentCategoryItemButton>
              </EquipmentCategoryItem>
            ))}
        </CleanUnorderedList>
        <CarSpecificationContainer>
          {product?.carSpecification && (
            <CarSpecificationLink link={product.carSpecification} />
          )}
        </CarSpecificationContainer>
      </CategorySelectListContainer>
      <StyledCategoryEquipmentListContainer>
        {hasFullEquipment && (
          <ScrollContainer style={{ height: '100%' }}>
            <CategoryEquipmentList
              flatEquipmentItems={product?.fullEquipment}
              overflowEllipsis={true}
            />
          </ScrollContainer>
        )}

        {!hasFullEquipment && (
          <>
            <CategoryEquipmentList
              label="Wyposażenie dodatkowe"
              equipmentItems={truncatedOptions}
              overflowEllipsis={true}
            />
            <CategoryEquipmentList
              label="Wyposażenie standardowe"
              flatEquipmentItems={truncatedStandardOptions}
              overflowEllipsis={true}
            />
          </>
        )}
      </StyledCategoryEquipmentListContainer>
      <FullEquipmentListContainer>
        <ProductPageEquipmentModalLauncher
          product={product}
          activeCategory={activeCategory}
          categories={categories}
          onActiveCategoryChange={onActiveCategoryChange}
          onModalToggle={onModalToggle}
        />
      </FullEquipmentListContainer>
    </Container>
  );
};
