import { createReducer } from '@reduxjs/toolkit';
import {
  homeAdvertDealsActions,
  homeAdvertSlidesActions,
} from 'src/app/home/state/homeAdvert/homeAdvertActions';
import { HomeAdvertDeal } from 'src/app/home/models/homeAdvertDeal';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';

export interface HomeAdvertState {
  deals?: HomeAdvertDeal[];
  slides?: HomeAdvertSlideType[];
}

const initialState: HomeAdvertState = {};

export const homeAdvertReducer = createReducer<HomeAdvertState>(
  initialState,
  (builder) =>
    builder
      .addCase(homeAdvertDealsActions.requestSuccess, (state, action) => {
        state.deals = action.payload;
      })
      .addCase(homeAdvertSlidesActions.requestSuccess, (state, action) => {
        state.slides = action.payload;
      })
);
