import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-tag': {
    backgroundColor: 'red',
    color: 'black',
    margin: '2px',
    fontSize: '0.75rem',
  },

  '& .MuiAutocomplete-popupIndicator': { color: '#dfdfe1', transform: 'none' },

  '& .MuiAutocomplete-clearIndicator': {
    color: '#dfdfe1',
  },
}));
export const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    color: '#93969b',
    padding: '3px 10px !important',
    fontSize: '1rem',

    '& fieldset': {
      borderColor: 'rgba(147, 150, 156, 0.6)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(147, 150, 156, 0.6)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(147, 150, 156, 0.6)',
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(10px, 6px) scale(0.9)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -8px) scale(0.7)',
  },
  '& .MuiInputLabel-root': {
    color: '#93969b',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: '#93969b',
  },
}));
