import { keyframes } from 'styled-components';

export const acceleratedEasing = 'cubic-bezier(0, 0, 0.2, 1)';

export const deceleratedEasing = 'cubic-bezier(0.0, 0.0, 0.2, 1)';

export const slideOutDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(1rem);
    opacity: 0;
  }
`;

export const slideInTop = keyframes`
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const slideInBottom = keyframes`
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const scaleOut = keyframes`
  from {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  to {
    transform: scale3d(1.1, 1.1, 1.1);
    opacity: 0;
  }
`;

export const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideOutLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideOutRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const fadeIn = (from = 0, to = 1) => keyframes`
  from {
    opacity: ${from};
  }
  to {
    opacity: ${to};
  }
`;
