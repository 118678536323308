import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 69 85" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M33.56 84.035c.49.398 1.19.398 1.68 0l19.663-15.952a35.753 35.753 0 0013.23-27.768V11.868c0-.726-.58-1.318-1.306-1.333-9.388-.192-17.32-2.606-22.906-4.976a58.175 58.175 0 01-6.488-3.225 39.457 39.457 0 01-1.7-1.043 22.34 22.34 0 01-.529-.355L35.181.92l-.004-.003a1.334 1.334 0 00-1.554 0l-.005.003-.023.016a22.34 22.34 0 01-.529.355c-.38.248-.951.608-1.7 1.043A58.184 58.184 0 0124.88 5.56c-5.587 2.37-13.518 4.784-22.906 4.976a1.333 1.333 0 00-1.306 1.333v28.447c0 10.776 4.86 20.98 13.23 27.767L33.56 84.036zm.84-80.43c.41.265.977.618 1.692 1.034a60.847 60.847 0 006.788 3.375c5.553 2.356 13.348 4.761 22.586 5.146v27.155a33.086 33.086 0 01-12.243 25.696L34.4 81.283 15.577 66.012A33.087 33.087 0 013.333 40.315V13.16c9.239-.385 17.034-2.79 22.587-5.146a60.829 60.829 0 006.788-3.375A42.91 42.91 0 0034.4 3.605z" />
      <path d="M31.68 48.856c.517-.013.98-.325 1.187-.799l8.25-18.857 4.412 13.236c.182.544.691.912 1.265.912h9.64a1.333 1.333 0 100-2.667h-8.679L42.55 25.067a1.333 1.333 0 00-2.486-.113l-8.5 19.427-4.235-8.472a1.333 1.333 0 00-2.385 0l-2.386 4.772H12.365a1.333 1.333 0 100 2.667h11.017c.505 0 .967-.286 1.193-.737l1.562-3.124 4.316 8.632c.231.463.71.75 1.226.737z" />
    </g>
  </IconBox>
);
