import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path clipRule="evenodd" fillRule="evenodd" d="M46 38H17v-1h29v1z" />
    <path clipRule="evenodd" fillRule="evenodd" d="M46 40H16v-1h30v1z" />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M51.063 41.0836c2.0401 0 3.6939-1.6538 3.6939-3.6939 0-2.0401-1.6538-3.6939-3.6939-3.6939-2.04 0-3.6938 1.6538-3.6938 3.6939 0 2.0401 1.6538 3.6939 3.6938 3.6939zm0 .8418c2.505 0 4.5357-2.0307 4.5357-4.5357 0-2.505-2.0307-4.5357-4.5357-4.5357-2.505 0-4.5357 2.0307-4.5357 4.5357 0 2.505 2.0307 4.5357 4.5357 4.5357z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M51.0594 39.9968c1.4389 0 2.6053-1.1665 2.6053-2.6053 0-1.4389-1.1664-2.6053-2.6053-2.6053-1.4388 0-2.6053 1.1664-2.6053 2.6053 0 1.4388 1.1665 2.6053 2.6053 2.6053zm0 .8418c1.9038 0 3.4472-1.5433 3.4472-3.4471s-1.5434-3.4472-3.4472-3.4472-3.4471 1.5434-3.4471 3.4472 1.5433 3.4471 3.4471 3.4471z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M11.5357 41.0836c2.0401 0 3.6939-1.6538 3.6939-3.6939 0-2.0401-1.6538-3.6939-3.6939-3.6939-2.04007 0-3.69387 1.6538-3.69387 3.6939 0 2.0401 1.6538 3.6939 3.69387 3.6939zm0 .8418c2.505 0 4.5357-2.0307 4.5357-4.5357 0-2.505-2.0307-4.5357-4.5357-4.5357C9.0307 32.854 7 34.8847 7 37.3897c0 2.505 2.0307 4.5357 4.5357 4.5357z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M11.538 39.9968c1.4388 0 2.6053-1.1665 2.6053-2.6053 0-1.4389-1.1665-2.6053-2.6053-2.6053-1.4389 0-2.60535 1.1664-2.60535 2.6053 0 1.4388 1.16645 2.6053 2.60535 2.6053zm0 .8418c1.9037 0 3.4471-1.5433 3.4471-3.4471s-1.5434-3.4472-3.4471-3.4472c-1.90385 0-3.44718 1.5434-3.44718 3.4472s1.54333 3.4471 3.44718 3.4471z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M17.5848 29.1034c-6.2444 0-14.34665 2.361-16.3722 3.6279l-.328305 5.5811L7 39.0001l-.14798.8288L0 39.0096l.397237-6.753.17683-.1161C2.56524 30.8338 10.7806 28.2615 17.408 28.2615c0 0 9.5543-5.2615 20.592-5.2615 5 0 7.805.672 10.2635 1.3298 1.236.3307 2.3358.6613 3.1265.9093.3955.124.714.2273.9339.2998.1099.0363.1953.0648.2533.0844l.0662.0224.0124.0042 9.3294 2.6577 1.1077 1.1077v3.2876L64 33.7915v3.3457l-1.0635 1.9142-7.2867.9342-.1071-.835 6.8646-.88.7509-1.3515v-2.8228l-.9072-1.0886v-3.2438l-.7066-.7066-9.1403-2.6038-.0105-.0036-.0012-.0004-.0039-.0013-.016-.0055-.0639-.0216c-.0564-.019-.1401-.047-.2483-.0827-.2164-.0713-.531-.1734-.9221-.2961-.7826-.2453-1.8705-.5724-3.0923-.8993C45.5887 24.4855 42 23.8418 38 23.8418c-10.5377 0-20.4152 5.2616-20.4152 5.2616z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M54.5 28.5L20 30.1039l2.5-.8417L51 28l3.5.5z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M59.0754 29.3506h3.2319v.8418h-2.9366l-.4246.3397 1.5399 1.5399h1.8213v.8418h-2.17l-2.4515-2.4515 1.3896-1.1117zM2.57585 32.072h4.0331l.18018.8013-3.53267 1.6734H.803251v-.8418H3.06717l1.66994-.7911h-2.0779l-1.773411.3547-.165096-.8255 1.855147-.371z"
    />
  </IconBox>
);
