import React, { useEffect, useRef, useState } from 'react';
import { Box, Popper, Skeleton, Typography, styled } from '@mui/material';
import { Brand } from 'src/app/common/models/product';
import { rem } from 'polished';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { useSelector } from 'react-redux';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import {
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { Icon } from 'src/common/components/Icon';
import {
  ExoticFilter,
  ModelFilter,
  OptionFilter,
} from 'src/app/common/models/filter';
import {
  StyledAutocomplete,
  StyledTextField,
} from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBrandAutocomplete/styles';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import {
  useFilterControl,
  useOptionFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { BrandIconsSection } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBrandAutocomplete/brand-icons-section';
import { useMediaQuery } from 'react-responsive';
import { widthBreakpoints } from 'src/config/breakpoints';
import { isMotorcycles } from 'src/app/search/utils/is-motorcycles';

export const SearchBrandAutocomplete = () => {
  const { state: brandState, setValue: setBrandValue } = useOptionFilterControl(
    OptionFilter.Brand
  );
  const { state: modelState, setValue: setModelState } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery({ maxWidth: widthBreakpoints.px992 });
  const brandsList = useSelector(brandsListSelector);

  if (!brandsList) {
    return <Skeleton style={{ height: rem(47) }} />;
  }

  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
      backgroundColor: '#52555a',
    },
  });

  let sortedBrandsList = [...brandsList].sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    return labelA.localeCompare(labelB);
  });

  const motorcycleId = sortedBrandsList.find(
    (brand) => brand.code === 'motorcycle'
  )?.id;

  if (motorcycleId) {
    if (!brandState.value.includes(motorcycleId)) {
      sortedBrandsList = sortedBrandsList.filter(
        (brand) => brand.code !== 'motorcycle'
      );
    }
  }

  function filterOptions(options: any, { inputValue }: { inputValue: string }) {
    return options.filter((option: any) =>
      option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }

  const [selectedValues, setSelectedValues] = useState<any>([]);

  useEffect(() => {
    const defaultValues = sortedBrandsList.filter((option: any) =>
      brandState.value.includes(option.id)
    );
    setSelectedValues(defaultValues);
  }, [brandState]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ...(isMotorcycles() && { visibility: 'hidden' }),
      }}
    >
      <StyledAutocomplete
        multiple
        size={isMobile ? 'medium' : 'small'}
        filterOptions={filterOptions}
        noOptionsText="Brak wyników"
        id="search-brand-autocomplete"
        options={sortedBrandsList as Brand[]}
        value={selectedValues}
        onChange={(_, newValue: any) => {
          const ids = newValue.map((v: any) => v.id);
          const filteredModelState: ModelFilter = Object.keys(modelState.value)
            .filter((key) => ids.includes(key))
            .reduce((obj: ModelFilter, key) => {
              obj[key] = modelState.value[key];
              return obj;
            }, {} as ModelFilter);
          setBrandValue(ids);
          setModelState(filteredModelState);
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        {...(!isMobile && { renderTags: () => null })}
        getOptionLabel={(brand) => {
          const typedBrand = brand as Brand & { models: BrandModel[] };
          return typedBrand.label;
        }}
        renderOption={(props, option: any) => {
          return (
            <li
              {...props}
              style={{
                color: 'white',
              }}
              id="autocomplete-element"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
                id="autocomplete-element"
              >
                <Box
                  sx={{
                    width: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  id="autocomplete-element"
                >
                  <OptionalFold
                    key={option.id}
                    value={brandIconMapper(option.code)}
                    onSome={(code) => (
                      <FilterBoxToggleButtonIcon>
                        <Icon
                          code={code}
                          size={Math.round(24 * brandIconResizeRates[code])}
                          id="autocomplete-element"
                        />
                      </FilterBoxToggleButtonIcon>
                    )}
                    onNone={
                      <FilterBoxToggleButtonTitle id="autocomplete-element">
                        {option.label}
                      </FilterBoxToggleButtonTitle>
                    }
                  />
                </Box>
                <Typography id="autocomplete-element">
                  {option.label}
                </Typography>
              </Box>
            </li>
          );
        }}
        PopperComponent={StyledPopper}
        renderInput={(params) => (
          <StyledTextField {...params} label="MARKA" inputRef={inputRef} />
        )}
      />
      {!isMobile && !isMotorcycles() && (
        <BrandIconsSection style={{ gridArea: 'brand' }} />
      )}
    </Box>
  );
};
