import React from 'react';
import { ToggleButton } from 'src/common/components/ToggleButton';
import { StyleProps } from '@summer/react-kit';
import { OptionFilter } from 'src/app/common/models/filter';
import styled, { css } from 'styled-components';
import { ConnectedCommonOptionFilter } from 'src/app/search/components/SearchPanel/CommonOptionFilter';
import { media } from 'src/config/breakpoints';

const StyledToggleButton = styled(ToggleButton)`
  justify-content: center;
  border-radius: 0.125rem;

  ${media.w.min.px768(css`
    width: 2.25rem;
  `)};
`;

export const SearchCabinSpaceInteriorFilter = (props: StyleProps) => (
  <ConnectedCommonOptionFilter
    kind={OptionFilter.CabinSpace}
    toggleButtonComponent={StyledToggleButton}
    {...props}
  />
);
