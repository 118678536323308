import React, { memo } from 'react';
import { OptionFilter } from 'src/app/common/models/filter';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { ToggleOffTagButton } from 'src/app/search/components/RequestProductSection/ToggleOffTagButton';

export const SearchEquipmentFilterTags = memo(() => {
  const { state, setValue } = useOptionFilterControl(
    OptionFilter.EquipmentItemGroup
  );

  return (
    <>
      {(state.groups ?? []).map((group) => (
        <ToggleButtonGroup
          key={group.id}
          items={group.options ?? []}
          value={state.value}
          onChange={setValue}
          toggleButtonComponent={ToggleOffTagButton}
        />
      ))}
    </>
  );
});
