import React, { ComponentType, FC, PropsWithChildren } from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { StyleProps } from '@summer/react-kit';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import {
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { Availability } from 'src/app/common/models/product';

export interface AvailabilitySwitchProps extends StyleProps {
  variant?: ToggleButtonVariant;
  labels?: [string, string];
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
}

export const AvailabilitySwitch: FC<AvailabilitySwitchProps> = ({
  variant,
  labels = ['Dostępne od ręki', 'Do produkcji'],
  style,
  className,
  toggleButtonComponent,
}) => {
  const { state, setValue } = useOptionFilterControl(OptionFilter.Availability);

  return (
    <HorizontalToggleGroupContainer style={style} className={className}>
      <ToggleButtonGroup
        value={state.value}
        variant={variant}
        onChange={(v) => setValue(v)}
        items={[
          { id: Availability.Available, label: labels[0] },
          { id: Availability.Unavailable, label: labels[1] },
        ]}
        toggleButtonComponent={
          toggleButtonComponent ? toggleButtonComponent : HorizontalToggleButton
        }
      />
    </HorizontalToggleGroupContainer>
  );
};
