import { createReducer } from '@reduxjs/toolkit';
import { pageContextActions } from 'src/state/pageContext/pageContextActions';
import { RedirectUrl } from 'src/state/pageContext/pageContextPayloads';

export enum PageContextType {
  PageUrl = 'pageUrl',
}

export interface PageContextState {
  [PageContextType.PageUrl]?: {
    current?: RedirectUrl;
    previous?: RedirectUrl;
  };
}

const initialState: PageContextState = {};

export const pageContextReducer = createReducer<PageContextState>(
  initialState,
  (builder) =>
    builder.addCase(pageContextActions.setPageUrl, (state, action) => ({
      ...state,
      [PageContextType.PageUrl]: {
        current: action.payload,
        previous: state[PageContextType.PageUrl]?.current,
      },
    }))
);
