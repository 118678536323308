import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import getVideoId from 'get-video-id';
import YouTube from 'react-youtube';

const Container = styled.div`
  display: flex;

  .player {
    overflow: hidden;
    width: 100%;
  }

  .player-item {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export interface GalleryModalVideoPlayerProps {
  url: string;
}

export const GalleryModalVideoPlayer = memo<GalleryModalVideoPlayerProps>(
  ({ url }) => {
    const videoId = useMemo(() => {
      return getVideoId(url).id;
    }, [url]);

    return (
      <Container>
        <YouTube
          className="player-item"
          containerClassName="player"
          onReady={(event) => {
            event.target.playVideo();
          }}
          videoId={videoId}
        />
      </Container>
    );
  }
);
