import React, { FC } from 'react';
import { ProductOfferTile } from 'src/app/product/components/ProductOfferTile';
import {
  getProductBasicParamList,
  PartialProduct,
} from 'src/app/common/models/product';
import { ProductParamsList } from 'src/app/product/components/ProductParamsList';
import { ProductOfferTilePrice } from 'src/app/product/components/ProductOfferTile/ProductOfferTilePrice';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { getResponsive } from 'src/common/models/image';
import { financingModelAvailabilityLabelMapper } from 'src/app/product/services/offerMappers';
import { deceleratedEasing } from 'src/common/styles/animations';
import { prune } from '@summer/react-kit/functions';
import { localFormatNumber } from 'src/lib/localFormatNumber';

export interface UsedProductTile {
  product: PartialProduct;
}

const StyledProductOfferTile = styled(ProductOfferTile)``;

const PhotoContainer = styled.div`
  height: 12.625rem;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Photo = styled.img`
  object-fit: cover;
  width: calc(100% + 0.5rem);
  height: 100%;
  margin-left: -0.5rem;
`;

export const StyledProductOfferTileParams = styled(ProductParamsList)`
  color: ${colors.lightGray};
  font-size: 0.75rem;
  font-weight: 300;
`;

const ProductFooterLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterRightContentWrapper = styled.div`
  margin-top: 0.7rem;
  overflow: hidden;
  height: 2rem;
`;

const FooterRightContent = styled.div`
  display: flex;
  flex-direction: column;

  transition: transform 200ms ${deceleratedEasing};

  ${StyledProductOfferTile}:hover & {
    transform: translateY(-2.7rem);
  }
`;

const FinancingInformation = styled.div`
  margin-top: 0.8rem;
  font-size: 0.75rem;
`;

export const UsedProductTile: FC<UsedProductTile> = ({ product }) => (
  <StyledProductOfferTile
    title={product.label}
    tagline={product.version}
    badges={product.badges}
    product={product}
    photo={
      product.mainPhoto && (
        <PhotoContainer>
          <Photo
            src={getResponsive('small', product.mainPhoto)}
            draggable={false}
          />
        </PhotoContainer>
      )
    }
    footerLeft={
      <ProductFooterLeftContainer>
        <StyledProductOfferTileParams
          valueEmphasis
          params={prune([
            product.productionYear != null
              ? {
                  value: product.productionYear,
                }
              : null,
            product.resellDetails != null
              ? {
                  label: 'przebiegu',
                  value: `${Math.floor(
                    product.resellDetails.mileage / 1000
                  )} tys. przebiegu`,
                }
              : null,
          ])}
        />
        <StyledProductOfferTileParams
          valueEmphasis
          params={getProductBasicParamList(product)}
        />
      </ProductFooterLeftContainer>
    }
    footerRight={
      <FooterRightContentWrapper>
        <FooterRightContent>
          <ProductOfferTilePrice price={product.offer.price} />
          <FinancingInformation>
            {product.availableFinancingTypes.length > 0 && (
              <div>
                dostępny w{' '}
                <strong>
                  {financingModelAvailabilityLabelMapper(
                    product.availableFinancingTypes.length
                  )}
                </strong>
              </div>
            )}
            {product.listPrice && (
              <div>
                cena katalogowa:{' '}
                <strong>{localFormatNumber(product.listPrice)}&nbsp;zł</strong>
              </div>
            )}
          </FinancingInformation>
        </FooterRightContent>
      </FooterRightContentWrapper>
    }
  />
);
