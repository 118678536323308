import { createRequestActions } from 'src/lib/requestActions';
import { Product } from 'src/app/common/models/product';
import { createAction } from 'src/lib/createAction';
import { GuestUserOffer, Offer } from 'src/common/models/offer';

export const bookProductActions = createRequestActions(
  `products/bookProduct/request`,
  `products/bookProduct/requestSuccess`,
  `products/bookProduct/requestFailure`
)<Offer, undefined, Record<string, unknown>>();

export const bookProductGuestActions = createRequestActions(
  `products/bookProductGuest/request`,
  `products/bookProductGuest/requestSuccess`,
  `products/bookProductGuest/requestFailure`
)<GuestUserOffer, undefined, Record<string, unknown>>();

export const fetchProductActions = createRequestActions(
  `products/fetchProduct/request`,
  `products/fetchProduct/requestSuccess`,
  `products/fetchProduct/requestFailure`
)<string, Product, { error: unknown; productId: string }>();

export const productsActions = {
  setActiveProductId: createAction('products/setActiveProductId')<string>(),
  setActiveOffer: createAction('products/setActiveOffer')<Offer | undefined>(),
  clear: createAction('products/clear')(),
};
