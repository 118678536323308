import React, { FC, memo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { TextField } from 'src/common/components/TextField';
import { Title } from 'src/common/components/typography/Title';
import { colors } from 'src/config/colors';
import styled, { css } from 'styled-components';
import { ChangePasswordForm } from 'src/app/account/components/ChangePasswordForm';
import { ChangePersonalDataForm } from 'src/app/account/components/ChangePersonalDataForm';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { AsideMainMenuLayout } from 'src/common/components/AsideMainManuLayout';
import { useSelector } from 'react-redux';
import { unsafeUserSelector } from 'src/state/auth/authSelectors';
import { UnboundFormItem } from 'src/common/components/FormItem';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import { media } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { ChangeCompanyRoute } from 'src/public/publicRoutes';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { navigate } from 'gatsby';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.white};
`;

const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${media.w.min.px768(css`
    margin-right: 2rem;
    max-width: 25rem;
  `)}
`;

const MarginSection = styled.section`
  margin-bottom: 3vh;
`;

const CompanyBox = styled.div`
  border: 1px solid ${colors.white};
  padding: 0.75rem 0.75rem;
`;

const CompanyName = styled(Paragraph)`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const AccountPage: FC<RouteComponentProps> = memo(() => {
  useStorePageUrl();
  const user = useSelector(unsafeUserSelector);

  return (
    <AsideMainMenuLayout>
      <Container>
        <Title level={1} variant="condensed">
          Moje Konto
        </Title>
        <ScrollContent>
          <FlexScrollContainer>
            <Paragraph size="small">
              Zarządzaj swoimi danymi, aktualizuj i&nbsp;zmieniaj wg. potrzeby.
            </Paragraph>

            <MarginSection>
              <ChangePersonalDataForm />
            </MarginSection>
            <MarginSection>
              <Title level={2}>Dane do logowania</Title>
              <UnboundFormItem>
                <TextField
                  name="personalEmail"
                  label="Prywatny adres e-mail"
                  value={user.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ marginBottom: '0.75rem' }}
                />
              </UnboundFormItem>
              <ChangePasswordForm />
            </MarginSection>

            <section>
              <Title level={2}>Aktualnie miejsce pracy</Title>
              <Paragraph size="small">
                Dostępne zniżki przypisane zostały na podstawie aktualnego
                zatrudnienia.
              </Paragraph>
              <CompanyBox>
                <CompanyName noScrollOverflow>
                  {user.businessPartner?.name}
                </CompanyName>
                <Text size="small" noScrollOverflow>
                  {user.companyEmail}
                </Text>
              </CompanyBox>

              <Button
                kind="box"
                variant="gray"
                size="small"
                onClick={() => navigate(ChangeCompanyRoute)}
                style={{ marginTop: 14 }}
              >
                Zaktualizuj swoje miejsce zatrudnienia
              </Button>
            </section>
          </FlexScrollContainer>
        </ScrollContent>
      </Container>
    </AsideMainMenuLayout>
  );
});

// eslint-disable-next-line import/no-default-export
export default AccountPage;
