import { useEffect } from 'react';

export const useKeyPress = (
  targetKey: number,
  targetCode: string,
  callback: () => void
) => {
  useEffect(() => {
    const handlePress = (event: KeyboardEvent) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      if (event.keyCode === targetKey || event.code === targetCode) {
        callback();
      }
    };

    window.addEventListener('keydown', handlePress);
    return () => {
      window.removeEventListener('keydown', handlePress);
    };
  }, [targetKey, targetCode, callback]);
};
