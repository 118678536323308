import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  flex: 1,
  '& .MuiAutocomplete-tag': {
    backgroundColor: '#4f5056',
    color: 'white',
    margin: '2px',
    padding: '2px 6px',
    fontSize: '0.75rem',
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: '#cececf',
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: '#cececf',
  },
}));
export const StyledTextField = styled(TextField)(() => ({
  marginTop: '0.5rem',
  '& .MuiOutlinedInput-root': {
    color: '#d5d5d6',

    '& fieldset': {
      borderColor: '#4f5056',
    },
    '&:hover fieldset': {
      borderColor: '#4f5056',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4f5056',
    },
  },

  '& .MuiInputLabel-root': {
    color: '#d5d5d6',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: '#d5d5d6',
  },
}));
