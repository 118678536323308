import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import {
  PageContextState,
  PageContextType,
} from 'src/state/pageContext/pageContextReducer';

export const pageStateSelector = (state: RootState) => state.page;

export const pageUrlSelector = createSelector(
  pageStateSelector,
  (state: PageContextState) => state[PageContextType.PageUrl]
);

export const currentPageUrlSelector = createSelector(
  pageUrlSelector,
  (state: PageContextState[PageContextType.PageUrl]) => state?.current
);

export const previousPageUrlSelector = createSelector(
  pageUrlSelector,
  (state: PageContextState[PageContextType.PageUrl]) => state?.previous
);
