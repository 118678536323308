import { createReducer } from '@reduxjs/toolkit';
import { CustomerType } from 'src/common/models/customerType';
import { customerTypeActions } from 'src/app/state/customerType/customerTypeActions';
import {
  authActions,
  fetchUserActions,
  loginActions,
} from 'src/state/auth/authActions';

export interface CustomerTypeState {
  type: CustomerType;
}

const initialState: CustomerTypeState = {
  type: CustomerType.B2B,
};

export const customerTypeReducer = createReducer<CustomerTypeState>(
  initialState,
  (builder) =>
    builder
      .addCase(customerTypeActions.setCustomerType, (state, action) => {
        state.type = action.payload;
      })
      .addCase(loginActions.requestSuccess, (state, action) => {
        state.type = action.payload.user.customerType;
      })
      .addCase(fetchUserActions.requestSuccess, (state, action) => {
        state.type = action.payload.customerType;
      })
      .addCase(authActions.logout, () => initialState)
);
