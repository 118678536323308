import { createReducer } from '@reduxjs/toolkit';
import { isBefore, parseISO, sub } from 'date-fns';
import { CompanyConfirmationViewState } from 'src/common/models/companyConfirmationViewState';
import { environment } from 'src/config/environment';
import {
  companyConfirmationActions,
  companyConfirmationViewActions,
  confirmCompanyActions,
} from 'src/state/companyConfirmation/companyConfirmationActions';
import {
  authActions,
  fetchUserActions,
  loginActions,
} from 'src/state/auth/authActions';
import { User } from 'src/common/models/user';

export interface CompanyConfirmationState {
  companyConfirmationViewState: CompanyConfirmationViewState;
  companyEmail?: string;
  needConfirmation: boolean;
}

const initialState: CompanyConfirmationState = {
  companyConfirmationViewState: CompanyConfirmationViewState.Main,
  needConfirmation: false,
};

const needConfirmationReducer = (
  state: CompanyConfirmationState,
  user: User
) => {
  state.needConfirmation = isBefore(
    parseISO(user.companyEmailConfirmDate),
    sub(new Date(), {
      months: parseFloat(environment.companyConfirmationExpirationMonths),
    })
  );
};

export const companyConfirmationReducer = createReducer<CompanyConfirmationState>(
  initialState,
  (builder) =>
    builder
      .addCase(loginActions.requestSuccess, (state, action) => {
        state.companyConfirmationViewState = CompanyConfirmationViewState.Main;
        needConfirmationReducer(state, action.payload.user);
      })
      .addCase(confirmCompanyActions.requestSuccess, (state, action) =>
        needConfirmationReducer(state, action.payload.user)
      )
      .addCase(fetchUserActions.requestSuccess, (state, action) =>
        needConfirmationReducer(state, action.payload)
      )
      .addCase(companyConfirmationActions.request, (state, action) => {
        state.companyEmail = action.payload.companyEmail;
      })
      .addCase(
        companyConfirmationViewActions.setCompanyConfirmationViewState,
        (state, action) => {
          state.companyConfirmationViewState = action.payload;
        }
      )
      .addCase(
        companyConfirmationViewActions.resetCompanyConfirmationViewState,
        (state) => ({
          ...initialState,
          needConfirmation: state.needConfirmation,
        })
      )
      .addCase(authActions.logout, (state) => ({
        ...initialState,
        companyConfirmationViewState: state.companyConfirmationViewState,
      }))
);
