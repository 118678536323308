import React from 'react';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled from 'styled-components';
import { Paragraph } from 'src/common/components/typography/Text';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import { CenteredLayoutBackground } from 'src/public/components/CenteredLayoutBackground';
import { typography } from 'src/config/typography';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const Layout = styled.div`
  height: 100%;
  display: grid;
  padding: 3vh 2rem;
  grid-template-columns: 100%;
  grid-template-rows: 3.5rem 1fr;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled(Title)`
  font-size: ${typography.headingSize[0]};
`;

const BrowserSupport = () => {
  useStorePageUrl();

  return (
    <Layout>
      <Seo title="O nas" />
      <Header>
        <HomeLogoLink style={{ marginBottom: 0 }} />
      </Header>
      <div>
        <SectionTitle level={2} variant="condensed">
          Car Platform nie jest dostępne na przeglądarce Internet Explorer
        </SectionTitle>
        <Paragraph style={{ lineHeight: 1.55 }}>
          Skorzystaj z przeglądarki Google Chrome, Safari lub Microsoft Edge,
          aby skorzystać z serwisu. W razie problemów skontaktuj się pisząc na
          adres{' '}
          <SimpleLink href="hello@mobilitybenefit.pl">
            hello@mobilitybenefit.pl
          </SimpleLink>
        </Paragraph>
      </div>
      <CenteredLayoutBackground />
    </Layout>
  );
};

// eslint-disable-next-line import/no-default-export
export default BrowserSupport;
