import React, { memo } from 'react';
import { FilterKind, FilterStateKindMap } from 'src/app/common/models/filter';
import { rem } from 'polished';
import { FilterStatusList } from 'src/app/search/components/FilterStatusInfo/FilterStatusList';
import { FilterStatusSpan } from 'src/app/search/components/FilterStatusInfo/FilterStatusSpan';
import { keys } from 'ramda';
import { FilterStatusPrinter } from 'src/app/search/components/FilterStatusInfo/FilterStatusPrinter';
import { StyleProps } from '@summer/react-kit';
import { FilterStatusItem } from 'src/app/search/components/FilterStatusInfo/FilterStatusItem';
import styled from 'styled-components';

export interface MixedFilterStatusListProps extends StyleProps {
  filters: {
    [P in FilterKind]?: FilterStateKindMap[P];
  };
  length: number;
}

const StyledFilterStatusItem = styled(FilterStatusItem)`
  margin-right: 0.25rem;
`;

const StyledFilterStatusList = styled(FilterStatusList)`
  min-width: 4.5rem;
  padding-right: 0.25rem;
  max-width: 15.75rem;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  white-space: nowrap;
`;

export const MixedFilterStatusList = memo<MixedFilterStatusListProps>(
  ({ filters, length, className, style }) => {
    const filterKeys = keys(filters);

    return (
      <StyledFilterStatusList className={className} style={style}>
        {filterKeys.length > 0 &&
          filterKeys.slice(0, length).map((a, index) => (
            <span key={index}>
              <FilterStatusPrinter
                key={a}
                kind={a}
                state={filters[a] as FilterStateKindMap[typeof a]}
              />
              {index < filterKeys.length - 1 && (
                <StyledFilterStatusItem>{','}</StyledFilterStatusItem>
              )}
            </span>
          ))}
      </StyledFilterStatusList>
    );
  }
);
