import React from 'react';
import { StyleProps } from '@summer/react-kit';
import { OptionFilter } from 'src/app/common/models/filter';
import { ConnectedCommonOptionFilter } from 'src/app/search/components/SearchPanel/CommonOptionFilter';

export const SearchGearboxPerformanceFilter = (props: StyleProps) => (
  <ConnectedCommonOptionFilter
    columns={{ desktop: 2, mobile: 2 }}
    kind={OptionFilter.Gearbox}
    {...props}
  />
);
