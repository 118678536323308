export class AnimationKeyframe {
  start: number;
  duration: number;

  constructor(start: number, duration: number) {
    this.start = start;
    this.duration = duration;
  }

  get finish(): number {
    return this.start + this.duration;
  }
}
