import {
  FilterKind,
  FilterStateKindMap,
  ModelFilterState,
  OptionFilter,
  OptionFilterState,
  PaginationFilterState,
  RangeFilter,
  RangeFilterState,
} from 'src/app/common/models/filter';
import { createAction } from 'src/lib/createAction';
import { createRequestActions } from 'src/lib/requestActions';
import { SrpFiltersDTO } from 'src/app/search/models/srpFiltersDTO';
import { authActions } from 'src/state/auth/authActions';

export const searchFiltersActions = {
  loadQueryParams: createAction('searchFilters/loadQueryParams/load')(),
  loadQueryParamsSuccess: createAction(
    'searchFilters/loadQueryParams/loadSuccess'
  )<
    {
      [P in FilterKind]?: FilterStateKindMap[FilterKind]['value'];
    }
  >(),
  setOptionFilterValue: createAction('searchFilters/setFilterValue/option')<{
    kind: OptionFilter;
    value: OptionFilterState['value'];
  }>(),
  setRangeFilterValue: createAction('searchFilters/setFilterValue/range')<{
    kind: RangeFilter;
    value: RangeFilterState['value'];
  }>(),
  setModelFilterValue: createAction(
    'searchFilters/setFilterValue/exotic/model'
  )<{
    value: ModelFilterState['value'];
  }>(),

  setNewProductOffersPaginationFilterValue: createAction(
    'searchFilters/setFilterValue/exotic/newProductOffersPagination'
  )<{
    value: PaginationFilterState['value'];
  }>(),
  setUsedProductOffersPaginationFilterValue: createAction(
    'searchFilters/setFilterValue/exotic/usedProductOffersPagination'
  )<{
    value: PaginationFilterState['value'];
  }>(),

  resetFilterValue: createAction('searchFilters/resetFilterValue')<{
    kind: FilterKind;
  }>(),

  clearFiltersValues: createAction('searchFilters/clearFiltersValues')(),
};

export const srpFiltersActions = createRequestActions(
  'searchFilters/srpFilters/request',
  'searchFilters/srpFilters/requestSuccess',
  'searchFilters/srpFilters/requestFailure'
)<void, SrpFiltersDTO, Record<string, unknown>>();

export const commonActionsModifyingFilters = [
  searchFiltersActions.setOptionFilterValue.type,
  searchFiltersActions.setRangeFilterValue.type,
  searchFiltersActions.setModelFilterValue.type,
  searchFiltersActions.resetFilterValue.type,
  searchFiltersActions.loadQueryParamsSuccess.type,
  searchFiltersActions.clearFiltersValues.type,
  authActions.logout,
];

export const actionsModifyingFilters = [
  ...commonActionsModifyingFilters,
  searchFiltersActions.setNewProductOffersPaginationFilterValue.type,
  searchFiltersActions.setUsedProductOffersPaginationFilterValue.type,
];

export const newProductOffersActionsModifyingFilters = [
  ...commonActionsModifyingFilters,
  searchFiltersActions.setNewProductOffersPaginationFilterValue.type,
];

export const usedProductOffersActionsModifyingFilters = [
  ...commonActionsModifyingFilters,
  searchFiltersActions.setUsedProductOffersPaginationFilterValue.type,
];
