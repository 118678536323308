import React, { memo } from 'react';
import {
  FilterBoxToggleButton,
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { SearchFilterOptionsGrid } from 'src/app/search/components/SearchPanel/SearchFilterOptions';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { Icon } from 'src/common/components/Icon';
import styled, { css } from 'styled-components';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { media } from 'src/config/breakpoints';
import { BlockScrollContainer } from 'src/common/components/ScrollContainer';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { StyleProps } from '@summer/react-kit';
import { ToggleButtonGroupBrandIcons } from 'src/common/components/ToggleButtonGroupBrandIcons';

const ScrollContainer = styled(BlockScrollContainer)`
  margin: -0.1875rem;

  ${media.w.max.px992(css`
    height: 4.75rem;
  `)}
`;

const Grid = styled(SearchFilterOptionsGrid)`
  align-content: flex-start;
  margin: 0;

  ${media.w.max.px992(css`
    flex-wrap: nowrap;
  `)}
`;

const BrandToggleButton = styled(FilterBoxToggleButton)`
  width: 3.75rem;
  height: 3.75rem;
  min-width: 3.5rem;
  border-radius: 0.125rem;
`;

const Container = styled.div`
  height: 11.25rem;
  max-height: 11.25rem;
  margin-top: 0.75rem;

  ${media.w.min.px1200(css`
    height: 15rem;
    max-height: 15rem;
    margin-top: 0.75rem;
  `)}
`;

export const BrandIconsSection = memo<StyleProps>(({ className, style }) => {
  const { state, setValue } = useOptionFilterControl(OptionFilter.Brand);

  const items = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? [])
      .filter((x) => x.code !== 'motorcycle')
      .map((a) => ({
        id: a.id,
        label: (
          <OptionalFold
            key={a.id}
            value={brandIconMapper(a.code)}
            onSome={(code) => (
              <FilterBoxToggleButtonIcon>
                <Icon
                  code={code}
                  size={Math.round(36 * brandIconResizeRates[code])}
                />
              </FilterBoxToggleButtonIcon>
            )}
            onNone={
              <FilterBoxToggleButtonTitle>{a.label}</FilterBoxToggleButtonTitle>
            }
          />
        ),
      }))
  );

  return (
    <Container className={className}>
      <ScrollContainer style={style} options={{ sizeAutoCapable: false }}>
        <Grid>
          <ToggleButtonGroupBrandIcons
            items={items}
            toggleButtonComponent={BrandToggleButton}
            value={state.value}
            onChange={setValue}
          />
        </Grid>
      </ScrollContainer>
    </Container>
  );
});
