import { AppState } from 'src/app/state';
import { createSelector } from 'reselect';

export const customerTypeStateSelector = (state: AppState) =>
  state.customerType;

export const customerTypeSelector = createSelector(
  customerTypeStateSelector,
  (state) => state?.type
);
