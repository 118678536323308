import React, { FC } from 'react';
import { useDrag } from 'react-use-gesture';
import { StyleProps } from '@summer/react-kit';
import styled from 'styled-components';

const Container = styled.div`
  user-select: none;
`;

const vThreshold = 0.3;

export enum SwipeDirection {
  Left = 'left',
  Right = 'right',
}

export interface SwipeListenerProps extends StyleProps {
  onSwipe: (direction: SwipeDirection) => void;
}

export const SwipeListener: FC<SwipeListenerProps> = ({
  children,
  className,
  style,
  onSwipe,
}) => {
  const bind = useDrag(({ last, vxvy: [vx] }) => {
    if (!last) {
      return;
    }

    if (vx < -vThreshold) {
      onSwipe(SwipeDirection.Left);
    } else if (vx > vThreshold) {
      onSwipe(SwipeDirection.Right);
    }
  });

  return (
    <Container className={className} style={style} {...bind()}>
      {children}
    </Container>
  );
};
