import { Button } from 'src/common/components/Button';
import Document from 'src/assets/icons/Document';
import React, { FC } from 'react';

export const CarSpecificationLink: FC<{ link: string }> = ({ link }) => (
  <Button
    as="a"
    href={link}
    kind="box"
    variant="transparent"
    target="_blank"
    icon={Document}
  >
    specyfikacja pojazdu
  </Button>
);
