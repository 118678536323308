import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GalleryModalVideoPlayer } from 'src/app/product/components/GalleryModal/GalleryModalVideoPlayer';
import { CleanButton } from 'src/common/components/Button';
import ThumbnailPlay from 'src/assets/icons/ThumbnailPlay';
import { GalleryItem } from 'src/app/product/components/GalleryModal/galleryItem';
import { deceleratedEasing } from 'src/common/styles/animations';

const Icon = styled(ThumbnailPlay)`
  transform-origin: center center;
  transition: all 150ms ${deceleratedEasing};
  opacity: 0.7;
`;

const Container = styled.div`
  display: flex;
  max-width: 100%;
  position: relative;
  width: 100vw;
  height: 100%;

  @media (hover: hover) {
    &:hover ${Icon} {
      opacity: 1;
      transform: scale(1.25);
    }
  }
`;

const Image = styled.img`
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const PlayButton = styled(CleanButton)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export interface PGalleryModalSlideItemProps {
  active: boolean;
  item: GalleryItem;
}

export const GalleryModalSlideItem = memo<PGalleryModalSlideItemProps>(
  ({ active, item }) => {
    const [showPlayer, setShowPlayer] = useState(false);

    useEffect(() => {
      setShowPlayer(false);
    }, [active]);

    return (
      <Container>
        {!showPlayer && (
          <>
            <Image src={item.fullImage.original} />

            {item.ytLink && (
              <PlayButton onClick={() => setShowPlayer(true)}>
                <Icon size={96} />
              </PlayButton>
            )}
          </>
        )}

        {item.ytLink && showPlayer && (
          <GalleryModalVideoPlayer url={item.ytLink} />
        )}
      </Container>
    );
  }
);
