import React, { memo } from 'react';
import styled from 'styled-components';
import { acceleratedEasing, scaleOut } from 'src/common/styles/animations';
import { homeAdvertAnimation } from 'src/app/home/components/HomeAdvert/homeAdvertAnimation';
import { HomeAdvertSlideHero } from 'src/app/home/components/HomeAdvert/HomeAdvertSlideHero';
import {
  HomeAdvertSlideStep,
  pickSlideBackground,
} from 'src/app/home/components/HomeAdvert/utils';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  user-select: none;

  &.animate-out {
    animation: ${scaleOut} ${homeAdvertAnimation.backgroundOut.duration}ms
      ${acceleratedEasing} ${homeAdvertAnimation.backgroundOut.start}ms forwards;
  }

  &.hidden {
    display: none;
  }
`;

export interface HomeAdvertSlideProps {
  slide: HomeAdvertSlideType;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  step: HomeAdvertSlideStep;
}

export const HomeAdvertSlide = memo<HomeAdvertSlideProps>(
  ({ slide, step, onMouseLeave, onMouseEnter }) => {
    const background = pickSlideBackground(slide);

    return (
      <>
        <Background className={step}>
          <img
            src={background.url}
            draggable={false}
            style={{
              display: 'block',
              left: 0,
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              objectPosition: background.focus
                ? `${background.focus.horizontal} ${background.focus.vertical}`
                : 'center center',
            }}
          />
        </Background>

        <HomeAdvertSlideHero
          slide={slide}
          className={step}
          step={step}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {slide.text && (
            <div dangerouslySetInnerHTML={{ __html: slide.text }} />
          )}
        </HomeAdvertSlideHero>
      </>
    );
  }
);
