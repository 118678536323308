import React from 'react';
import coveredCar from 'src/assets/images/covered-car.png';
import styled from 'styled-components';
import { ProductOfferTile } from 'src/app/product/components/ProductOfferTile';
import { typography } from 'src/config/typography';

const PhotoContainer = styled.div`
  height: 11.25rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1.5625rem;
`;

export const Photo = styled.img`
  object-fit: contain;
  width: calc(105%);
  height: 110%;
`;

const Emphasis = styled.div`
  font-size: 1.375rem;
  font-family: ${typography.headingFontFamily};
  text-transform: capitalize;
  font-weight: bold;
`;

const FooterRightContent = styled.div`
  margin-top: 0.75rem;
  font-family: ${typography.fontFamilyDefault};
  font-weight: bold;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

export const DreamProductTile = () => (
  <ProductOfferTile
    title="Auto Twoich marzeń"
    photo={
      <PhotoContainer>
        <Photo src={coveredCar} draggable={false} />
      </PhotoContainer>
    }
    footerRight={
      <FooterRightContent>
        <Emphasis>ZAPYTAJ</Emphasis>
        <div>Znajdziemy je dla Ciebie</div>
      </FooterRightContent>
    }
  />
);
