import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { ProductPageSectionHeading } from 'src/app/product/components/ProductPageSectionHeading';
import { ProductPageSection } from 'src/app/product/models/productPageSection';
import { CleanButton } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { Product } from 'src/app/common/models/product';
import { ProductSectionContainer } from 'src/app/product/components/ProductSectionContainer';
import { typography } from 'src/config/typography';
import SwiperCore, { Mousewheel, Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { GalleryModal } from 'src/app/product/components/GalleryModal';
import { GalleryItem } from 'src/app/product/components/GalleryModal/galleryItem';
import { EnlargeWrapper } from 'src/app/product/components/EnlargeWrapper';
import { getResponsive } from 'src/common/models/image';

SwiperCore.use([Mousewheel]);

const Background = styled.div`
  background-color: ${colors.white};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

const Overlay = styled.div`
  background-color: ${colors.background};
  bottom: 0;
  left: calc(50% + 1440px / 2);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  opacity: 0.95;
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2.875rem;
`;

const Container = styled(ProductSectionContainer)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.w.max.px992(css`
    padding-left: 0;
    padding-right: 0;
  `)};
`;

const Group = styled(CleanButton).attrs<{ active: boolean }>((props) => ({
  className: props.active && 'active',
}))<{ active: boolean }>`
  margin: 0 0.625rem;
  text-transform: uppercase;
  font-weight: 400;
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.75rem;
  color: ${colors.background};

  &:first-child {
    padding-left: 0.625rem;
  }

  &:last-child {
    padding-right: 0.625rem;
  }

  &.active {
    color: ${colors.primary};
  }
`;

const Groups = styled.div`
  flex: 0 0 auto;
`;

const Heading = styled.div`
  margin-bottom: 3.4375rem;
`;

const StyledSwiper = styled(Swiper)`
  &.swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    align-items: center;
    display: flex;
    height: 16.25rem;
    max-width: 100vw;
    width: auto;

    ${media.w.min.px576(css`
      height: 21.25rem;
    `)};

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
`;

interface ProductPhoto {
  group: string;
  image?: string;
}

interface ProductPhotoGroup {
  id: string;
  label: string;
}

const swiperOptions: SwiperOptions = {
  mousewheel: {
    releaseOnEdges: true,
    sensitivity: 5,
  },
  slidesPerView: 'auto',
  spaceBetween: 20,
};

export interface ProductPageSectionGalleryRef {
  openGalleryModal: () => void;
}

export interface ProductPageSectionGalleryProps {
  product?: Product;
  section: ProductPageSection;
  onModalToggle: (modalOpen: boolean) => void;
}

export const ProductPageSectionGallery = memo(
  forwardRef<ProductPageSectionGalleryRef, ProductPageSectionGalleryProps>(
    ({ product, section, onModalToggle }, ref) => {
      const [activeGroup, setActiveGroup] = useState<string | null>(null);
      const [images, setImages] = useState<ProductPhoto[]>([]);
      const [itemGroups, setItemGroups] = useState<ProductPhotoGroup[]>([]);

      const swiperRef = useRef<SwiperInstance | undefined>(undefined);
      const onSwiperCallback = useCallback((swiper: SwiperInstance) => {
        swiperRef.current = swiper;
      }, []);

      useEffect(() => {
        if (!product) {
          return;
        }

        const items: ProductPhoto[] = [
          ...product.outsidePhotos.map((photo) => ({
            group: 'exterior',
            image: getResponsive('medium', photo),
          })),
          ...product.insidePhotos.map((photo) => ({
            group: 'interior',
            image: getResponsive('medium', photo),
          })),
        ];
        const groups: ProductPhotoGroup[] = [];

        if (product.outsidePhotos.length > 0) {
          groups.push({ id: 'exterior', label: 'wygląd zewnętrzny' });
        }

        if (product.insidePhotos.length > 0) {
          groups.push({ id: 'interior', label: 'stylistyka wnętrza' });
        }

        setImages(items);
        setItemGroups(groups);
        groups.length > 0 && setActiveGroup(groups[0].id);
      }, [product]);

      const onTransitionEnd = useCallback(
        (swiper: SwiperInstance) => {
          if (swiper == null) {
            return;
          }

          let activeSlide =
            (swiper as SwiperInstance & { snapIndex: number }).snapIndex ?? 0;

          if (swiper.slides.length > 2 && swiper.isEnd) {
            activeSlide = swiper.slides.length - 1 || 0;
          }

          if (activeSlide > 0) {
            setActiveGroup(images[activeSlide].group);
          }
        },
        [images]
      );

      const changeGroup = (group: string) => {
        swiperRef.current?.slideTo(
          images.findIndex((item) => item.group === group)
        );
        setActiveGroup(group);
      };

      const [selectedSlide, setSelectedSlide] = useState<number | undefined>(
        undefined
      );

      useEffect(() => {
        onModalToggle(selectedSlide != null);
      }, [selectedSlide]);

      const galleryModalImages = useMemo<GalleryItem[]>(
        () =>
          [
            ...(product?.outsidePhotos ?? []),
            ...(product?.insidePhotos ?? []),
          ].map((a) => ({ fullImage: a, thumbImage: a })),
        [product]
      );

      useImperativeHandle(ref, () => ({
        openGalleryModal: () => setSelectedSlide(0),
      }));

      return (
        <>
          <ThemeProvider theme={{ variant: 'light' }}>
            <div style={{ overflow: 'hidden' }}>
              <Background />

              <Container>
                <Heading>
                  <ProductPageSectionHeading
                    icon={section.icon}
                    subtitle={section.subtitle}
                    title={section.title}
                  />
                </Heading>

                {images.length > 0 && (
                  <StyledSwiper
                    {...swiperOptions}
                    onSwiper={onSwiperCallback}
                    onTransitionEnd={onTransitionEnd}
                  >
                    {images.map((x, index) => (
                      <SwiperSlide key={index}>
                        <EnlargeWrapper
                          onClick={() => setSelectedSlide(index)}
                          style={{ height: '100%' }}
                        >
                          <img src={x.image} alt="" />
                        </EnlargeWrapper>
                      </SwiperSlide>
                    ))}
                  </StyledSwiper>
                )}

                <Buttons>
                  <Groups>
                    {itemGroups.map((group) => (
                      <Group
                        active={activeGroup === group.id}
                        key={group.id}
                        onClick={() => {
                          changeGroup(group.id);
                        }}
                      >
                        {group.label}
                      </Group>
                    ))}
                  </Groups>
                </Buttons>
              </Container>

              <Overlay />
            </div>
          </ThemeProvider>

          <ModalPortal
            open={selectedSlide != null}
            onClose={() => setSelectedSlide(undefined)}
          >
            <GalleryModal
              items={galleryModalImages}
              initialSlide={selectedSlide}
              thumbnails={false}
            />
          </ModalPortal>
        </>
      );
    }
  )
);
