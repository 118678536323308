import { ContactDepartment } from 'src/public/models/contactDepartment';
import React from 'react';

export const contactDepartments: ContactDepartment[] = [
  {
    title: 'Wsparcie sprzedażowe i oferta finansowa',
    contactPageTitle: 'Wsparcie sprzedażowe i oferta finansowa',
    excerpt: 'dla klientów i użytkowników platformy',
    description: (
      <>
        Chcesz spełnić swoje marzenie o nowym samochodzie, ale nie wiesz, która
        oferta finansowa jest dla Ciebie? Potrzebujesz pomocy przy wyborze auta?
        Skontaktuj się z&nbsp;nami.
      </>
    ),
    contact: {
      label: 'Car Platform Team',
      mail: 'hello@carplatform.pl',
      phone: '+48 661 188 188',
    },
  },
  {
    title: 'Wsparcie firmowe',
    contactPageTitle: 'Wsparcie firmowe',
    excerpt: 'dla firm zainteresowanych dostępem dla pracowników ',
    description: (
      <>
        Jeśli chcesz, aby Twoi pracownicy otrzymali dostęp do naszej platformy,
        skontaktuj się z&nbsp;nami.
      </>
    ),
    contact: {
      mail: 'p.jaczynowski@carplatform.pl',
      phone: '+48 501 180 252',
    },
  },
  {
    title: 'Wsparcie dealerskie',
    contactPageTitle: 'Wsparcie dealerskie',
    excerpt: 'dla przedstawicieli marek samochodowych',
    description: (
      <>
        Jeśli chcesz, aby samochody oferowane przez Twój punkt dealerski
        pojawiły się na w&nbsp;Car Platform, skontaktuj się z&nbsp;nami.
      </>
    ),
    contact: {
      mail: 'l.lotoszynski@carplatform.pl',
      phone: '+48 720 811 206',
    },
  },
];
