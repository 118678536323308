import React, { FC } from 'react';
import styled from 'styled-components';
import { StyleProps } from '@summer/react-kit';

export interface ProductParam {
  value: string | number;
  label?: string;
}

const Param = styled.span`
  margin-right: 0.25rem;
  line-height: 0.875rem;
  white-space: nowrap;
`;

const Value = styled.span<{ $emphasis?: boolean }>`
  white-space: nowrap;
  margin-right: 0.25rem;
  font-weight: ${({ $emphasis }) => ($emphasis ? 700 : 'normal')};
`;

export interface ProductBasicParamsPresentationProps extends StyleProps {
  params: ProductParam[];
  valueEmphasis?: boolean;
}

export const ProductParamsList: FC<ProductBasicParamsPresentationProps> = ({
  params,
  className,
  style,
  valueEmphasis = false,
}) => (
  <span className={className} style={style}>
    {params.map(({ value, label }, i) => (
      <Param key={i}>
        <Value $emphasis={valueEmphasis}>{value}</Value>
        <span>{label}</span>
      </Param>
    ))}
  </span>
);
