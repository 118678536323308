import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { SearchFiltersGroupContainer } from 'src/app/search/components/SearchPanel/SearchFiltersGroupContainer';
import { media } from 'src/config/breakpoints';
import { SearchBrandAutocomplete } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBrandAutocomplete';
import { SearchBodyAutocomplete } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBodyAutocomplete';
import { SearchModelAutocomplete } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchModelAutocomplete';
import { SearchBrandGeneralFilter } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBrandGeneralFilter';
import { SearchModelGeneralFilter } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchModelGeneralFilter';
import { SearchBodyGeneralFilter } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBodyGeneralFilter';
import { SearchCategoryGeneralFilter } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchCategoryGeneralFilter';
import { isMotorcycles } from 'src/app/search/utils/is-motorcycles';

const Container = styled(SearchFiltersGroupContainer)`
  grid-row-gap: 1.125rem;
  display: grid;
  width: 100%;
  grid-template-areas: 'brand' 'model' 'body' 'category';
  grid-template-rows: repeat(4, min-content);

  ${media.w.min.px992(css`
    display: flex;
    flex-direction: row;
    gap: 30px;
  `)};
`;

export const SearchGeneralFilters = memo(() => (
  <Container>
    {isMotorcycles() ? (
      <>
        <SearchModelAutocomplete />
        <SearchBrandAutocomplete />
      </>
    ) : (
      <>
        <SearchBrandAutocomplete />
        <SearchModelAutocomplete />
      </>
    )}

    <SearchBodyAutocomplete />
    {/* <SearchBrandGeneralFilter style={{ gridArea: 'brand' }} /> */}
    {/* <SearchModelGeneralFilter style={{ gridArea: 'model' }} /> */}
    {/* <SearchBodyGeneralFilter style={{ gridArea: 'body' }} /> */}
    {/* <SearchCategoryGeneralFilter style={{ gridArea: 'category' }} /> */}
  </Container>
));
