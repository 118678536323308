import React, { memo, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { isUsedProduct, Product } from 'src/app/common/models/product';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { Button, CleanButton } from 'src/common/components/Button';
import { Link } from 'gatsby';
import { SearchPageRoute } from 'src/app/routes';
import { transparentize } from 'polished';
import {
  acceleratedEasing,
  deceleratedEasing,
  fadeIn,
  slideInLeft,
} from 'src/common/styles/animations';
import { Loader } from 'src/common/Loader';
import { stringify } from 'qs';
import { currentSearchParamsVersion } from 'src/app/search/searchParamsVersion';
import { preloadImages } from '@summer/react-kit/functions';
import { skewBoth } from 'src/common/styles/skew';
import { OptionalFold } from 'src/lib/OptionalFold';
import { Tooltip } from 'src/common/components/Tooltip';
import {
  paintTypeLabelMapper,
  upholsteryTypeLabelMapper,
} from 'src/app/product/services/productMappers';
import { useMediaQuery } from 'react-responsive';
import { EnlargeWrapper } from 'src/app/product/components/EnlargeWrapper';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { getResponsive } from 'src/common/models/image';
import { BadgesBinder } from 'src/app/common/components/BadgesBinder';
import { useSelector } from 'react-redux';
import { activeOfferSelector } from 'src/app/product/state/products/productsSelectors';
import { isNilEmpty } from 'src/lib/isNilEmpty';
import { GeneralSearchAutocomplete } from 'src/app/search/components/SearchHeader/GeneralSearchAutocomplete';

interface SectionVisualProps {
  $withBadges?: boolean;
  $usedProduct?: boolean;
}

const Container = styled.div<SectionVisualProps>`
  position: relative;
  background: linear-gradient(180deg, #ffffff 50%, ${colors.grayBasic} 100%);
  height: ${({ $withBadges, $usedProduct }) =>
    $withBadges && $usedProduct ? '31.5rem' : '27rem'};
  overflow: hidden;
  color: ${colors.grayBasic};

  ${media.w.min.px768(
    css`
      height: 38.5rem;
    `
  )};
`;

const LeftRectangle = styled.div`
  position: absolute;
  width: calc(50% + 5rem);
  bottom: 0;
  height: 67%;
  background: linear-gradient(
    140deg,
    rgba(224, 227, 232, 0.2) 13%,
    rgba(224, 227, 232, 0.8) 45%
  );
  clip-path: polygon(0 0, calc(100% - 8rem) 0, 100% 100%, 0% 100%);

  opacity: 0;
  transform: translateX(-100%);
  animation: ${slideInLeft} 250ms 400ms ${deceleratedEasing} forwards;

  ${media.w.min.px768(
    css`
      height: 90%;
      clip-path: polygon(0 0, calc(100% - 15rem) 0, 100% 100%, 0% 100%);
    `
  )};
`;

const slideInRight = keyframes`
  from {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const RightRectangle = styled.div<{ photo?: string }>`
  position: absolute;
  width: calc(50% + 5rem);
  left: calc(50% - 5rem);
  bottom: 0;
  height: 90%;
  clip-path: polygon(8rem 0, 100% 0, 100% 100%, 0% 100%);
  background: ${({ photo }) =>
    photo
      ? `url(${photo}) left center`
      : `linear-gradient(
    -110deg,
    ${transparentize(0.4, colors.background)} 13%,
    rgba(224, 227, 232, 0.8) 55%
  )`};
  background-size: cover;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  animation: ${slideInRight} 250ms 550ms ${deceleratedEasing} forwards;

  ${media.w.min.px768(
    css`
      height: 100%;
      width: calc(50% + 10rem);
      left: calc(50% - 10rem);
      clip-path: polygon(20rem 0, 100% 0, 100% 100%, 0% 100%);
    `
  )};
`;

const RadialGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 55%);
  width: 100%;
  height: 135%;
  background: radial-gradient(rgba(224, 227, 232, 1) 30%, transparent 70%);

  ${media.w.min.px768(
    css`
      width: 70%;
      height: 135%;
      background: radial-gradient(rgba(224, 227, 232, 1) 30%, transparent 60%);
    `
  )};
`;

const Content = styled(ConstraintContainer)`
  position: relative;
  height: 100%;
  padding-top: 0.25rem;

  ${media.w.min.px768(
    css`
      padding-top: 1rem;
    `
  )};
`;

const Heading = styled.h1<{ isVisible: boolean }>`
  color: ${colors.background};
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.5625rem;
  padding-top: 1.5rem;
  z-index: 2;
  position: relative;
  margin: 0;

  ${media.w.min.px768(css`
    font-size: 2.5rem;
    line-height: 1.375;
  `)};

  ${(props) =>
    media.w.min.px1200(css`
      opacity: ${props.isVisible ? '1' : '0'};
      transform: translateY(${props.isVisible ? '0' : '-3.125rem'});
      transition: all 250ms ${acceleratedEasing};
    `)};
`;

const SubHeading = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const Version = styled.span`
  line-height: 1;
  font-size: 1.125rem;
  font-weight: 300;
  margin-right: 1rem;

  ${media.w.min.px768(css`
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1;
  `)}
`;

const ColorDescriptionButton = styled(CleanButton)`
  display: flex;
  align-items: center;
`;

const ColorSample = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  position: relative;
  width: 2.625rem;
  height: 1.375rem;
  ${skewBoth('0.625rem')};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        22.97deg,
        rgba(0, 0, 0, 0.15) 46.67%,
        rgba(0, 0, 0, 0) 53.42%
      ),
      linear-gradient(140.55deg, #ffffff 3.29%, rgba(255, 255, 255, 0) 95.3%);
    mix-blend-mode: lighten;
  }
`;

const Image = styled.div<SectionVisualProps>`
  position: absolute;
  opacity: 0;
  animation: ${fadeIn(0, 1)} 250ms 100ms ${deceleratedEasing} forwards;

  ${({ $usedProduct, $withBadges }) => css`
    top: ${$usedProduct ? '8rem' : $withBadges ? '8rem' : '6rem'};
    bottom: ${$withBadges ? '6rem' : '2rem'};
    left: 1rem;
    right: 1rem;

    ${$usedProduct &&
    media.w.min.px768(
      css`
        top: 11rem;
      `
    )}

    ${media.w.min.px992(
      css`
              top: ${$usedProduct ? '11rem' : '8rem'}
              bottom: 4rem;
              left: 4rem;
              right: 4rem;
            `
    )};

    ${media.w.min.px1200(
      $usedProduct
        ? css`
            top: 8rem;
            bottom: 8rem;
            left: 15rem;
            right: 20rem;
          `
        : css`
            top: 5.5rem;
            bottom: 4.5rem;
            left: 12rem;
            right: 18rem;
          `
    )};

    img {
      width: 100%;
      height: 100%;
      object-fit: ${$usedProduct ? 'cover' : 'contain'};
      ${$usedProduct &&
      css`
        clip-path: polygon(
          100% 0,
          100% 95%,
          81% 95%,
          80% 100%,
          0 100%,
          0 100%,
          0 0
        );
      `}
    }
  `};
`;

const BadgesContainer = styled.div`
  position: absolute;
  bottom: 1rem;

  display: grid;
  grid-auto-columns: min-content;
  grid-column-gap: 2rem;
  grid-auto-flow: column;
  align-content: center;
  justify-content: center;

  height: 5rem;

  left: 1rem;
  right: 1rem;

  ${media.w.min.px1200(
    css`
      left: 12rem;
      right: 18rem;
    `
  )};
`;

export interface ProductPageSectionBenefitsProps {
  isPageTop: boolean;
  product: Product;
  onImageClick: () => void;
}

export const ProductPageSectionTop = memo<ProductPageSectionBenefitsProps>(
  ({ isPageTop, product, onImageClick }) => {
    const selectedOffer = useSelector(activeOfferSelector);
    const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });
    const [loading, setLoading] = useState(true);
    const isSmallMobile = useMediaQuery({ maxWidth: widthBreakpoints.px576 });

    const sectionVisualProps = {
      $usedProduct: isUsedProduct(product),
      $withBadges: !(
        isNilEmpty(product?.badges) && isNilEmpty(selectedOffer?.badges)
      ),
    };

    useEffect(() => {
      preloadImages(
        getResponsive('large', product.mainPhoto),
        getResponsive('large', product.backgroundPhoto)
      ).then(() => setLoading(false));
    }, []);

    if (loading) {
      return (
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </Container>
      );
    }

    const previousPage = `${SearchPageRoute}?${stringify({
      v: currentSearchParamsVersion,
    })}`;

    return (
      <Container {...sectionVisualProps}>
        <RightRectangle
          photo={getResponsive('large', product.backgroundPhoto)}
        />
        <RadialGradient />
        <LeftRectangle />

        <Content>
          {isSmallMobile && (
            <GeneralSearchAutocomplete
              fullWidth={true}
              textfieldSx={{
                marginBottom: '0.5rem',
              }}
            />
          )}
          <Button
            kind="box-inverted"
            variant="transparent"
            size="small"
            icon={ChevronLeft}
            as={Link}
            to={previousPage}
          >
            Powrót
          </Button>

          <Heading isVisible={isPageTop}>
            {product.label}
            <SubHeading>
              <OptionalFold
                value={product.version}
                onSome={(a) => <Version>{a}</Version>}
              />
              <Tooltip
                placement={isMobile ? 'bottom' : 'right-end'}
                title={
                  <div>
                    <strong>Kolor lakieru i tapicerki</strong>
                    <div style={{ marginTop: 8 }}>
                      Lakier – {product.paintColor?.label} (
                      {product.paintColor?.code}),{' '}
                      {paintTypeLabelMapper(product.paintType)}
                    </div>
                    <div>
                      Tapicerka – {product.upholsteryColor?.label} (
                      {product.upholsteryColor?.code}),{' '}
                      {upholsteryTypeLabelMapper(product.upholsteryType)}
                    </div>
                  </div>
                }
              >
                <ColorDescriptionButton>
                  <OptionalFold
                    value={product.paintColor?.paintColorGroups[0]?.hex}
                    onSome={(a) => <ColorSample color={a} />}
                  />
                  <OptionalFold
                    value={
                      product.upholsteryColor?.upholsteryColorGroups[0]?.hex
                    }
                    onSome={(a) => <ColorSample color={a} />}
                  />
                </ColorDescriptionButton>
              </Tooltip>
            </SubHeading>
          </Heading>

          <Image {...sectionVisualProps}>
            <EnlargeWrapper onClick={onImageClick}>
              {product.mainPhoto && (
                <img src={getResponsive('large', product.mainPhoto)} alt="" />
              )}
            </EnlargeWrapper>
          </Image>

          {sectionVisualProps.$withBadges && (
            <BadgesContainer>
              <BadgesBinder
                product={product}
                offer={selectedOffer}
                elements={3}
                variant={isMobile ? 'small' : 'big'}
              />
            </BadgesContainer>
          )}
        </Content>
      </Container>
    );
  }
);
