import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { colors } from 'src/config/colors';
import React, { FC } from 'react';
import CarDiscount from 'src/assets/images/car-discount.svg';
import Handshake from 'src/assets/images/handshake.svg';
import Fist from 'src/assets/images/fist.svg';
import SwitchArrows from 'src/assets/images/switch-arrows.svg';
import DiscountBadge from 'src/assets/images/discount-badge.svg';
import Flame from 'src/assets/images/flame.svg';

const employeesBenefits = [
  {
    id: 1,
    icon: <Flame />,
    name: 'Najlepsza oferta',
    description: 'na samochody prywatne',
  },
  {
    id: 2,
    icon: <SwitchArrows />,
    name: 'Dowolność',
    description: 'wydatkowania i kumulowania środków na karcie MOBILITY 3.0',
  },
  {
    id: 3,
    icon: <DiscountBadge />,
    name: 'Zniżki',
    description: 'na produkty i usługi',
  },
];

const employersBenefits = [
  {
    id: 1,
    icon: <Fist />,
    name: 'Rozszerzenie programu benefitowego',
    description: 'dla pracowników',
  },
  {
    id: 2,
    icon: <Handshake />,
    name: 'Transparentne zasady współpracy',
    description: 'oraz pełna obsługa',
  },
  {
    id: 3,
    icon: <CarDiscount />,
    name: 'Preferencyjne ceny samochodów',
    description: 'dla firmy i pracowników',
  },
];

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1440px;
  margin: 0 auto 1rem;
  padding: 0 1rem;
  position: relative;

  ${media.w.min.px768(css`
    padding: 0 5rem;
  `)};
`;

const StyledSectionHeading = styled(SectionHeading)`
  margin-bottom: 0.625rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  color: ${colors.lightGray};

  & span {
    color: ${colors.primary};
  }
`;

const Benefits = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  padding: 1.875rem 0;

  ${media.w.min.px992(css`
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2.5rem;
    padding: 4rem 0;
  `)};
`;

const BenefitsGradient = styled.div`
  background: radial-gradient(
    78.67% 123.7% at 28.98% 161.85%,
    #007fff 0%,
    rgba(0, 127, 255, 0.40625) 7.28%,
    rgba(0, 127, 255, 0) 100%
  );
  position: absolute;
  left: 0;
  z-index: -1;

  ${media.w.between.px768.px992(css`
    left: -2rem;
  `)}

  ${media.w.min.px992(css`
    height: 16.875rem;
    width: 44rem;
    bottom: 1rem;
  `)};

  ${media.w.max.px992(css`
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 30.75rem;
    width: 21.0625rem;
    top: 1.875rem;
  `)};
`;

const BenefitRowTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1.8125rem;
  margin-left: 1.125rem;
  color: ${colors.white};

  &:after {
    content: '';
    background-color: ${colors.primary};
    display: block;
    height: 1px;
    width: 6.625rem;
    margin-top: 0.625rem;

    ${media.w.min.px992(css`
      margin-top: 1.625rem;
    `)};
  }

  ${media.w.min.px992(css`
    margin-left: 2.8125rem;
  `)};
`;

const Benefit = styled.div`
  position: relative;
  display: flex;
  font-size: 0.75rem;
  padding: 1rem;

  ${media.w.min.px992(css`
    flex-direction: column;
    font-size: 1rem;
    padding: 0;
  `)};
`;

const Icon = styled.div`
  svg {
    width: 1.5625rem;
    height: 1.5625rem;
  }

  ${media.w.min.px992(css`
    opacity: 1;

    svg {
      width: 1.875rem;
      height: 1.875rem;
    }
  `)};
`;

const BenefitDescription = styled.div`
  ${media.w.max.px992(css`
    padding-left: 1rem;
  `)};

  font-size: 0.875rem;
  line-height: 1.1;
`;

const BenefitName = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.3125rem;

  ${media.w.min.px768(css`
    font-size: 1.2rem;
  `)};

  ${media.w.min.px992(css`
    margin-top: 1.25rem;
  `)};
`;

export const CompanyBenefitsSection: FC = () => {
  return (
    <Container>
      <Content>
        <StyledSectionHeading>
          UNIKALNE KORZYŚCI KARTY MOBILITY 3.0 <br />
          <span>DLA CIEBIE I TWOICH PRACOWNIKÓW</span>
        </StyledSectionHeading>
        <Benefits>
          <BenefitsGradient />
          <BenefitRowTitle>
            Dla <br /> pracowników:
          </BenefitRowTitle>
          {employeesBenefits.map((benefit) => (
            <Benefit key={benefit.id}>
              <Icon>{benefit.icon}</Icon>
              <BenefitDescription>
                <BenefitName>{benefit.name}</BenefitName>
                {benefit.description}
              </BenefitDescription>
            </Benefit>
          ))}
        </Benefits>
        <Benefits>
          <BenefitsGradient />
          <BenefitRowTitle>
            Dla <br /> pracodawcy:
          </BenefitRowTitle>
          {employersBenefits.map((benefit) => (
            <Benefit key={benefit.id}>
              <Icon>{benefit.icon}</Icon>
              <BenefitDescription>
                <BenefitName>{benefit.name}</BenefitName>
                {benefit.description}
              </BenefitDescription>
            </Benefit>
          ))}
        </Benefits>
      </Content>
    </Container>
  );
};
