import React, { FC } from 'react';
import { Skeleton } from 'src/common/components/Skeleton';

export const ContactFormSSRPlaceholder: FC = () => (
  <div>
    <Skeleton style={{ width: '15rem', height: '3rem' }} />
    <Skeleton style={{ width: '100%', height: '3rem', marginTop: '1.25rem' }} />
    <Skeleton style={{ width: '100%', height: '3rem', marginTop: '1.25rem' }} />
    <Skeleton
      style={{ width: '100%', height: '14rem', marginTop: '1.25rem' }}
    />
    <Skeleton style={{ width: '100%', height: '7rem', marginTop: '1.25rem' }} />
    <Skeleton style={{ width: '100%', height: '3rem', marginTop: '1.25rem' }} />
  </div>
);
