import {
  all,
  call,
  debounce,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { apiFormattedSearchFiltersSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { searchUsedProducts } from 'src/app/search/services/product';
import { usedProductOffersActionsModifyingFilters } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { findUsedProductsRequestActions } from 'src/app/search/state/searchProducts/searchUsedProductsActions';
import { customerTypeActions } from 'src/app/state/customerType/customerTypeActions';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { CustomerType } from 'src/common/models/customerType';

function* processFindUsedProducts() {
  const customerType: CustomerType = yield select(customerTypeSelector);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME
  const selectedFilters = yield select(apiFormattedSearchFiltersSelector);
  selectedFilters['customerType'] = customerType;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const { results, total } = yield call(searchUsedProducts, selectedFilters);

    yield put(
      findUsedProductsRequestActions.requestSuccess({
        products: results,
        total,
      })
    );
  } catch (error) {
    yield put(findUsedProductsRequestActions.requestFailure(error));
  }
}

function* findUsedProductsWatcher() {
  yield debounce(
    1000,
    findUsedProductsRequestActions.request.type,
    function* () {
      yield race({
        task: processFindUsedProducts(),
        cancel: take(findProductsRequestActions.request.type),
      });
    }
  );
}

function* spawnFindUsedProducts() {
  yield put(findUsedProductsRequestActions.request());
}

function* synchronizeUsedProductsWatcher() {
  yield takeEvery(
    usedProductOffersActionsModifyingFilters,
    spawnFindUsedProducts
  );
}

function* setUserCustomerTypeWatcher() {
  yield takeEvery(customerTypeActions.setCustomerType, function* () {
    if (location.pathname.includes('/app/search')) {
      yield spawnFindUsedProducts();
    }
  });
}

export function* searchUsedProductsSaga() {
  yield all([
    findUsedProductsWatcher(),
    synchronizeUsedProductsWatcher(),
    setUserCustomerTypeWatcher(),
  ]);
}
