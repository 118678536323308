import React, { FC } from 'react';
import { Link } from 'gatsby';
import { HomePageRoute } from 'src/app/routes';
import { Logo } from 'src/common/components/Logo';
import { Button, CleanButton } from 'src/common/components/Button';
import { skewBoth, skewLeft } from 'src/common/styles/skew';
import { Flex } from '@summer/react-kit';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ArrowRight from 'src/assets/icons/ArrowRight';

const HeaderWrapper = styled.section`
  padding-top: 1.125rem;
  padding-bottom: 0.5rem;
  width: 100%;

  ${media.w.min.px768(css`
    padding-top: 2.5rem;
  `)};
`;

const Container = styled.div`
  position: relative;
`;

const HeroContent = styled.div`
  padding-top: 11.125rem;

  ${media.w.min.px1200(css`
    display: flex;
    align-items: flex-end;
    padding-top: 5.3125rem;
    padding-bottom: 6.3125rem;
  `)};
`;

const BusinessPartnerLogo = styled.img`
  max-height: 10rem;
  max-width: 100%;

  ${media.w.max.px768(css`
    margin-bottom: 3.5rem;
  `)};
`;

const HeroSectionHeading = styled(SectionHeading)`
  text-align: left;
  text-transform: uppercase;
  line-height: 1.1;
  color: ${colors.white};
  max-width: 20rem;
  margin-bottom: 0;

  ${media.w.min.px768(css`
    max-width: 22.875rem;
  `)};

  & span {
    color: ${colors.primary};
  }
`;

const Advantages = styled.div`
  ${media.w.max.px1200(css`
    margin-top: 1rem;
  `)};

  ${media.w.min.px1200(css`
    margin-left: 9.4375rem;
  `)};
`;

const AdvantagesTitle = styled.div`
  color: ${colors.lightGray};
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-bottom: 1rem;
`;

const AdvantageItem = styled.div`
  padding: 0.625rem 0;
  font-size: 1rem;

  ${media.w.max.px768(css`
    margin-right: 1.5rem;
  `)};

  ${media.w.min.px768(css`
    position: relative;
    height: 6.5625rem;
    width: 6.9375rem;
  `)};

  ${media.w.min.px768(css`
    width: 12.9375rem;
  `)};
`;

const ItemText = styled.span`
  font-size: 0.75rem;
`;

const ItemBackground = styled.div`
  height: 6.5rem;
  width: 16.875rem;
  position: absolute;
  z-index: -1;
  top: 0;
  left: -3.5rem;
  background: linear-gradient(
    153.26deg,
    #37383f 9.7%,
    rgba(55, 56, 63, 0) 45.29%
  );
  ${skewLeft('3rem')};
`;

const GradientTile1 = styled.div`
  clip-path: polygon(0 0, calc(100% - 5.5rem) 0, 100% 100%, 5.5rem 100%);
  background: linear-gradient(
    210deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
  opacity: 0.2;
  height: 11.25rem;
  width: 22.25rem;
  position: absolute;
  z-index: -1;
  right: 40%;
  top: 5rem;

  ${media.w.min.px768(css`
    clip-path: polygon(0 0, calc(100% - 10.5rem) 0, 100% 100%, 10.5rem 100%);
    height: 19.4375rem;
    width: 38.125rem;
    right: 43%;
    top: -10%;
  `)};
`;

const GradientTile2 = styled.div`
  ${skewBoth('4.5rem')};
  background: linear-gradient(
    215deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
  opacity: 0.7;
  height: 9.875rem;
  width: 23.625rem;
  position: absolute;
  z-index: -1;
  right: 0;
  top: -1rem;

  ${media.w.min.px768(css`
    ${skewBoth('7.5rem')};
    background: linear-gradient(
      235deg,
      #007fff 9.99%,
      rgba(0, 127, 255, 0) 46.64%
    );
    height: 16.875rem;
    width: 28rem;
    right: 25%;
    top: -20%;
  `)};
`;

const GradientTile3 = styled.div`
  clip-path: polygon(
    calc(100% - 11.125rem) 0,
    100% 21.875rem,
    11.125rem 100%,
    0 calc(100% - 21.875rem)
  );
  background: linear-gradient(
    295deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
  opacity: 0.5;
  height: 41.25rem;
  width: 19.8125rem;
  position: absolute;
  z-index: -1;
  right: -35%;
  bottom: 23%;

  ${media.w.min.px768(css`
    clip-path: polygon(
      calc(100% - 13.125rem) 0,
      100% 21.875rem,
      13.125rem 100%,
      0 calc(100% - 21.875rem)
    );
    background: linear-gradient(
      280deg,
      #007fff 9.99%,
      rgba(0, 127, 255, 0) 46.64%
    );
    height: 70.8125rem;
    width: 34rem;
    right: 0;
    bottom: 0;
  `)};
`;

const GradientTile4 = styled.div`
  ${media.w.min.px768(css`
    clip-path: polygon(
      calc(100% - 13.125rem) 0,
      100% 21.875rem,
      13.125rem 100%,
      0 calc(100% - 21.875rem)
    );
    background: linear-gradient(
      295deg,
      #007fff 9.99%,
      rgba(0, 127, 255, 0) 46.64%
    );
    opacity: 0.5;
    height: 47.0625rem;
    width: 22.5625rem;
    position: absolute;
    z-index: -1;
    right: -10%;
    bottom: 45%;
  `)};
`;

interface HeroSectionProps {
  logo: string;
  onPrimaryButtonClick: () => void;
}

export const HeroSection: FC<HeroSectionProps> = ({
  logo,
  onPrimaryButtonClick,
}) => {
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });

  return (
    <Container>
      <>
        <GradientTile1 />
        <GradientTile2 />
        <GradientTile3 />
        <GradientTile4 />
      </>
      <HeaderWrapper>
        <CleanButton as={Link} to={HomePageRoute}>
          <Logo variant={'light'} />
        </CleanButton>
      </HeaderWrapper>

      <HeroContent>
        <div>
          <BusinessPartnerLogo src={logo} alt="logo" />
          <HeroSectionHeading>
            CIESZYMY SIĘ, ŻE JESTEŚ Z NAMI! AKTYWUJ SWÓJ DOSTĘP DO <br />
            <span>car platform</span>
          </HeroSectionHeading>
        </div>

        {isMobile ? (
          <div style={{ marginTop: '2rem' }}>
            <Button
              kind="skew"
              icon={ArrowRight}
              onClick={onPrimaryButtonClick}
            >
              Dołącz do platformy
            </Button>
            <Flex>
              <AdvantageItem>
                Ponad 30 marek <br />{' '}
                <ItemText>aut na wyciągnięcie ręki</ItemText>
              </AdvantageItem>
              <AdvantageItem>
                MAX rabaty <br /> <ItemText>w jednej ofercie</ItemText>
              </AdvantageItem>
            </Flex>
          </div>
        ) : (
          <Advantages>
            <AdvantagesTitle>
              Dzięki Car Platform zyskujesz dostęp do:
            </AdvantagesTitle>
            <Flex>
              <AdvantageItem>
                <ItemBackground />
                Ponad 30 marek <br />{' '}
                <ItemText>aut na wyciągnięcie ręki</ItemText>
              </AdvantageItem>
              <AdvantageItem>
                <ItemBackground />
                0% wpłaty własnej
              </AdvantageItem>
              <AdvantageItem>
                <ItemBackground />
                Atrakcyjnych modeli finansowania
              </AdvantageItem>
            </Flex>
          </Advantages>
        )}
      </HeroContent>
    </Container>
  );
};
