import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5113 43.7662C13.9912 43.7662 16.0015 41.7558 16.0015 39.2759C16.0015 36.796 13.9912 34.7856 11.5113 34.7856C9.03135 34.7856 7.02098 36.796 7.02098 39.2759C7.02098 41.7558 9.03135 43.7662 11.5113 43.7662ZM11.5113 44.6075C14.4558 44.6075 16.8428 42.2205 16.8428 39.2759C16.8428 36.3314 14.4558 33.9443 11.5113 33.9443C8.56672 33.9443 6.17969 36.3314 6.17969 39.2759C6.17969 42.2205 8.56672 44.6075 11.5113 44.6075Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.511 42.4854C13.2842 42.4854 14.7217 41.0479 14.7217 39.2747C14.7217 37.5014 13.2842 36.064 11.511 36.064C9.73776 36.064 8.30028 37.5014 8.30028 39.2747C8.30028 41.0479 9.73776 42.4854 11.511 42.4854ZM11.511 43.3267C13.7488 43.3267 15.563 41.5125 15.563 39.2747C15.563 37.0368 13.7488 35.2227 11.511 35.2227C9.27313 35.2227 7.45898 37.0368 7.45898 39.2747C7.45898 41.5125 9.27313 43.3267 11.511 43.3267Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.648 43.7662C51.1279 43.7662 53.1383 41.7558 53.1383 39.2759C53.1383 36.796 51.1279 34.7856 48.648 34.7856C46.1681 34.7856 44.1577 36.796 44.1577 39.2759C44.1577 41.7558 46.1681 43.7662 48.648 43.7662ZM48.648 44.6075C51.5925 44.6075 53.9796 42.2205 53.9796 39.2759C53.9796 36.3314 51.5925 33.9443 48.648 33.9443C45.7034 33.9443 43.3164 36.3314 43.3164 39.2759C43.3164 42.2205 45.7034 44.6075 48.648 44.6075Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.6477 42.4854C50.4209 42.4854 51.8584 41.0479 51.8584 39.2747C51.8584 37.5014 50.4209 36.064 48.6477 36.064C46.8745 36.064 45.437 37.5014 45.437 39.2747C45.437 41.0479 46.8745 42.4854 48.6477 42.4854ZM48.6477 43.3267C50.8856 43.3267 52.6997 41.5125 52.6997 39.2747C52.6997 37.0368 50.8856 35.2227 48.6477 35.2227C46.4098 35.2227 44.5957 37.0368 44.5957 39.2747C44.5957 41.5125 46.4098 43.3267 48.6477 43.3267Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3045 19H54.8664L56.0865 20.0167L55.8613 20.2869H56.3333L57.3339 24.4897L58.2578 30.9571L58.5967 35.3624H60.1747L58.4885 38.735L54.0508 39.5068L53.9066 38.6779L57.9259 37.9789L58.8135 36.2037H57.8176L57.4211 31.049L56.5066 24.647L55.6688 21.1282H24.062L19.2997 28.8212L6.16485 30.4862L4.57668 31.7568V35.2219L3.84129 35.9573V38.661L5.18427 39.4071H6.36221V40.2484H4.96627L3 39.156V35.6088L3.73539 34.8734V31.3524L5.82417 29.6814L18.7957 28.0371L23.5934 20.2869H24.3694L24.3027 19.9263L29.3045 19ZM26.9753 20.2869H55.0965L54.5618 19.8413H29.3818L26.9753 20.2869ZM43.4994 38.7776H16.4738V37.9363H43.4994V38.7776ZM16.8415 39.7748H43.4994V40.6161H16.8415V39.7748Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5436 29.7684L57.1091 29.2172L57.0964 28.376L20.4907 28.9278L5.57903 30.5847L5.67194 31.4208L20.5436 29.7684ZM57.663 31.4233L4.53113 32.5264L4.51367 31.6853L57.6456 30.5822L57.663 31.4233Z"
    />
  </IconBox>
);
