import React from 'react';
import styled, { css } from 'styled-components';
import centeredLayoutBg from 'src/assets/images/centered-layout-bg.url.svg';
import { media } from 'src/config/breakpoints';

const Background = styled.div`
  display: none;
  position: absolute;
  top: 4.8125rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: url('${centeredLayoutBg}') no-repeat;
  background-size: cover;
  background-position-y: 1rem;

  ${media.w.min.px992(css`
    display: block;
  `)};
`;

export const CenteredLayoutBackground = () => <Background />;
