import { Seo } from 'src/public/components/Seo';
import { Form, Formik } from 'formik';
import { store } from 'src/state';
import { loginActions } from 'src/state/auth/authActions';
import { Title } from 'src/common/components/typography/Title';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Link, navigate } from 'gatsby';
import { Button } from 'src/common/components/Button';
import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import {
  CarPlatformBusinessRoute,
  ForgotPasswordRoute,
  RegisterRoute,
} from 'src/public/publicRoutes';
import { colors } from 'src/config/colors';
import styled, { css } from 'styled-components';
import { Text } from 'src/common/components/typography/Text';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { useSelector } from 'react-redux';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import Register from 'src/assets/icons/Register';
import { Flex, RootRoute } from '@summer/react-kit';
import { useRequest } from 'src/lib/useRequest';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { Logo } from 'src/common/components/Logo';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { previousPageUrlSelector } from 'src/state/pageContext/pageContextSelectors';
import { useMediaQuery } from 'react-responsive';
import mobilityCard from 'src/assets/images/mobility-card.png';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { ScrollContext } from 'src/common/contexts/ScrollContext';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import Fuel from 'src/assets/images/fuel.svg';
import CarWash from 'src/assets/images/car-wash.svg';
import Bus from 'src/assets/images/bus.svg';
import Sprinkler from 'src/assets/images/sprinkler.svg';
import Bicycle from 'src/assets/images/bicycle.svg';
import Speedometer from 'src/assets/images/speedometer.svg';
import { skewBoth } from 'src/common/styles/skew';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const initialValues = { email: '', password: '' };
const validationSchema = object().shape({
  email: string().required('Pole wymagane').email('Podaj poprawny adres email'),
  password: string().required('Pole wymagane'),
});

const FullPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const Container = styled(ConstraintContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  max-width: 23.4375rem;

  ${media.w.min.px992(css`
    height: 100%;
    max-width: 70rem;
  `)}
`;

const Content = styled.div`
  width: 100%;

  ${media.w.min.px992(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10rem;
  `)}
`;

const HeaderWrapper = styled.section`
  ${media.w.max.px992(css`
    padding-top: 1.125rem;
    padding-bottom: 0.5rem;
  `)}
`;

const Gradient = styled.div`
  width: 100%;
  height: 7.375rem;
  left: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  background: radial-gradient(
    44.79% 79.17% at 50% 100%,
    #0081ff 0%,
    rgba(0, 129, 255, 0) 100%
  );
  opacity: 0.4;
  transform: matrix(1, 0, 0, -1, 0, 0);

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const LoginFormWrapper = styled.div`
  position: relative;

  ${media.w.min.px992(css`
    padding-left: 4rem;

    &:before {
      content: '';
      opacity: 0.5;
      background: radial-gradient(
        35.35% 35.46% at 50% 50%,
        rgba(0, 127, 255, 0.64) 0%,
        rgba(0, 127, 255, 0) 100%
      );
      width: 40rem;
      height: 40rem;
      position: absolute;
      z-index: -1;
      left: -30%;
      bottom: -10%;
    }
  `)}
`;

const Tagline = styled(Text).attrs({ variant: 'condensed' })`
  text-transform: uppercase;
  color: ${colors.lightGray};
  font-size: 0.75rem;
  margin-bottom: 1.5vh;
  margin-top: 2rem;

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const Description = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.34375rem;
  color: ${colors.lightGray};
  margin-bottom: 1rem;
  max-width: 13rem;
  text-transform: uppercase;
`;

const CreateAccount = styled(Flex)`
  ${media.w.min.px992(css`
    display: none;
  `)}
  svg {
    margin-bottom: 1rem;
  }

  a {
    font-weight: bold;
  }
`;

const MobilityCard = styled.div`
  position: relative;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;

  ${media.w.min.px992(css`
    flex-direction: column-reverse;
    margin-top: -4rem;
    padding: 0;
  `)};

  &:before {
    content: '';
    background: radial-gradient(
      35.35% 35.46% at 50% 50%,
      #007fff 0%,
      rgba(0, 127, 255, 0) 100%
    );
    position: absolute;
    z-index: -1;
    top: -20%;
    left: -20%;
    width: 30rem;
    height: 30rem;
    opacity: 0.5;

    ${media.w.min.px992(css`
      top: -50%;
      left: -5%;
    `)};
  }
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 11rem;
  height: 7rem;
  border: 24px;
  transform: rotate(-20.14deg);

  ${media.w.min.px992(css`
    width: 12.5625rem;
    height: 7.875rem;
  `)};
`;

const StyledTitle = styled(Title)`
  margin-bottom: 2.75rem;
`;

const BenefitsTopRow = styled(Flex)`
  margin-bottom: -1rem;

  ${media.w.min.px992(css`
    flex-direction: column-reverse;
    margin: -5rem 0 0 -1rem;
  `)};
`;
const BenefitsBottomRow = styled(Flex)`
  margin-top: -1rem;

  ${media.w.min.px992(css`
    flex-direction: column;
    align-items: flex-end;
    margin: 0 2rem -5rem 0;
  `)};
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 6rem;
  height: 2.5rem;

  ${media.w.min.px992(css`
    width: 7rem;
    height: 2.75rem;
  `)};
`;

const Icon = styled.div`
  opacity: 0.5;

  svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  ${media.w.min.px992(css`
    svg {
      width: 0.9125rem;
      height: 0.9125rem;
    }
  `)};
`;

const BenefitName = styled.div`
  font-weight: 700;
  font-size: 0.4825rem;
  line-height: 0.5625rem;
  text-transform: uppercase;
  color: ${colors.lightGray};
`;

const BenefitTop = styled(Benefit)`
  justify-content: flex-end;

  ${BenefitName} {
    text-align: right;
  }

  ${Icon} {
    margin-left: 0.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, calc(100% - 1rem) 0, 100% 100%, 1rem 100%);
    background: linear-gradient(
      200deg,
      #007fff 9.99%,
      rgba(0, 127, 255, 0) 46.64%
    );

    ${media.w.min.px992(css`
      ${skewBoth('1rem')};
      background: linear-gradient(
        335deg,
        #007fff 9.99%,
        rgba(0, 127, 255, 0) 46.64%
      );
    `)};
  }
`;

const BenefitBottom = styled(Benefit)`
  ${BenefitName} {
    text-align: left;
  }

  ${Icon} {
    margin-right: 0.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, calc(100% - 1rem) 0, 100% 100%, 1rem 100%);
    background: linear-gradient(
      30deg,
      #007fff 9.99%,
      rgba(0, 127, 255, 0) 46.64%
    );

    ${media.w.min.px992(css`
      ${skewBoth('1rem')};
      background: linear-gradient(
        155deg,
        #007fff 9.99%,
        rgba(0, 127, 255, 0) 46.64%
      );
    `)};
  }
`;

const Benefit1 = styled(BenefitTop)`
  margin-top: 2.375rem;

  ${media.w.min.px992(css`
    margin-top: 0;
    margin-left: 3rem;
  `)};
`;

const Benefit2 = styled(BenefitTop)`
  margin-top: 1.1875rem;
  margin-left: -2.5rem;

  ${media.w.min.px992(css`
    margin-top: 0;
    margin-left: 1.5rem;
  `)};
`;

const Benefit3 = styled(BenefitTop)`
  margin-left: -2.5rem;

  ${media.w.min.px992(css`
    margin: 0;
  `)};
`;

const Benefit4 = styled(BenefitBottom)`
  margin-right: -2.5rem;
  margin-top: 2.375rem;

  ${media.w.min.px992(css`
    margin: 0;
  `)};
`;

const Benefit5 = styled(BenefitBottom)`
  margin-top: 1.1875rem;
  margin-right: -2.5rem;

  ${media.w.min.px992(css`
    margin-top: 0;
    margin-right: -1.5rem;
  `)};
`;

const Benefit6 = styled(BenefitBottom)`
  margin-bottom: 2.375rem;

  ${media.w.min.px992(css`
    margin-bottom: 0;
    margin-right: -3rem;
  `)};
`;

const CreateAccountButton = styled(Button)`
  display: block;
  text-align: center;
  margin-bottom: 4.75rem;

  ${media.w.min.px992(css`
    margin-top: -1.5rem;
    text-align: right;
  `)};
`;

const StyledSimpleLink = styled(SimpleLink)`
  position: relative;
  z-index: 1;
`;

const LoginPage = () => {
  useStorePageUrl();

  const previousPage = useSelector(previousPageUrlSelector);

  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px992,
  });

  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const { isLoading } = useRequest(loginActions.request);

  if (isPlatformBrowser() && isLoggedIn) {
    navigate(previousPage ? previousPage.url : RootRoute);
  }

  const [scrollRef, setScrollRef] = useState<OverlayScrollbarsComponent | null>(
    null
  );

  useEffect(() => {
    const elements = scrollRef?.osInstance()?.getElements();

    if (elements) {
      elements.viewport.tabIndex = -1;
      elements.viewport.focus();
    }
  }, [scrollRef]);

  return (
    <ScrollContext.Provider value={scrollRef}>
      <FullPageLayout>
        <Seo title="Logowanie" />

        <ScrollContainer ref={setScrollRef} style={{ height: '100%' }}>
          <Container>
            <HeaderWrapper>
              <Link to={RootRoute}>
                <Logo variant="light" />
              </Link>
              <Gradient />
            </HeaderWrapper>
            <Content>
              <LoginFormWrapper>
                <Tagline as="div">Zaloguj się</Tagline>
                <StyledTitle level={1} variant="condensed">
                  {isDesktop ? 'Zaloguj się' : 'Car Platform Business'}
                </StyledTitle>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    store.dispatch(
                      loginActions.request({
                        identifier: values.email,
                        password: values.password,
                      })
                    );
                  }}
                >
                  <Form>
                    <FormItem name="email">
                      <TextControl
                        type="email"
                        name="email"
                        label="Twój prywatny adres e-mail"
                      />
                    </FormItem>
                    <FormItem
                      name="password"
                      hint={
                        <Button
                          as={Link}
                          to={ForgotPasswordRoute}
                          kind="link"
                          variant="transparent"
                        >
                          Zapomniałem hasła
                        </Button>
                      }
                    >
                      <TextControl
                        type="password"
                        name="password"
                        label="Hasło"
                      />
                    </FormItem>
                    <FormActionsContainer
                      style={{ marginBottom: '4vh', marginTop: '2.5rem' }}
                      justifyContent="space-between"
                    >
                      <Button
                        kind="box-inverted"
                        variant="transparent"
                        icon={ChevronLeft}
                        onClick={() => {
                          navigate(-1);
                          // const search = location.search;
                          // const cutSearch = search.slice(10);

                          // if (cutSearch) {
                          //   navigate(cutSearch);
                          // } else {
                          //   navigate(LandingRoute);
                          // }
                          pushEvent(EventName.ButtonClick, {
                            click_cta: 'wroc',
                          });
                        }}
                        style={{
                          textTransform: 'uppercase',
                          position: 'relative',
                        }}
                      >
                        Wróć
                      </Button>

                      <Button
                        kind="skew"
                        size="small"
                        type="submit"
                        icon={ArrowRight}
                        disabled={isLoading}
                      >
                        Zaloguj się
                      </Button>
                    </FormActionsContainer>
                    <CreateAccount alignCenter>
                      <Register size={27} />
                      <div style={{ marginLeft: '0.75rem' }}>
                        <Text as="div" size="xSmall">
                          <SimpleLink as={Link} to={RegisterRoute}>
                            Załóż konto
                          </SimpleLink>{' '}
                          w&nbsp;Car&nbsp;Platform&nbsp;Business
                          <br />i otrzymaj dostęp do:
                        </Text>
                      </div>
                    </CreateAccount>
                  </Form>
                </Formik>
              </LoginFormWrapper>
              <div>
                {isDesktop && (
                  <>
                    <Title level={1} variant="condensed">
                      Dołącz do Car Platform Business
                    </Title>
                    <Description>
                      Załóż konto i korzystaj z wielu benefitów programu
                      MOBILITY 3.0
                    </Description>
                    <StyledSimpleLink
                      as={Link}
                      to={CarPlatformBusinessRoute}
                      onClick={() =>
                        pushEvent(EventName.ButtonClick, {
                          click_cta: 'wiecej_car_platform_business',
                        })
                      }
                    >
                      Więcej!
                    </StyledSimpleLink>
                  </>
                )}
                <MobilityCard>
                  <BenefitsTopRow>
                    <Benefit1>
                      <BenefitName>
                        transport <br /> publiczny
                      </BenefitName>
                      <Icon>
                        <Bus />
                      </Icon>
                    </Benefit1>
                    <Benefit2>
                      <BenefitName>myjnia</BenefitName>
                      <Icon>
                        <CarWash />
                      </Icon>
                    </Benefit2>
                    <Benefit3>
                      <BenefitName>
                        zakup <br /> paliwa
                      </BenefitName>
                      <Icon>
                        <Fuel />
                      </Icon>
                    </Benefit3>
                  </BenefitsTopRow>
                  <Image src={mobilityCard} alt="karta mobility" />
                  <BenefitsBottomRow justifyEnd>
                    <Benefit4>
                      <Icon>
                        <Speedometer />
                      </Icon>
                      <BenefitName>
                        opłaty <br /> drogowe <br /> i parkingowe
                      </BenefitName>
                    </Benefit4>
                    <Benefit5>
                      <Icon>
                        <Bicycle />
                      </Icon>
                      <BenefitName>
                        wynajem aut, <br /> hulajnóg <br /> i rowerów
                      </BenefitName>
                    </Benefit5>
                    <Benefit6>
                      <Icon>
                        <Sprinkler />
                      </Icon>
                      <BenefitName>
                        akcesoria <br /> samochodowe
                      </BenefitName>
                    </Benefit6>
                  </BenefitsBottomRow>
                </MobilityCard>
                <CreateAccountButton
                  kind="skew"
                  variant={isDesktop ? 'secondary' : 'primary'}
                  size="small"
                  icon={ArrowRight}
                  forwardedAs={Link}
                  to={RegisterRoute}
                  onClick={() =>
                    pushEvent(EventName.ButtonClick, {
                      click_cta: 'zaloz_konto',
                    })
                  }
                >
                  Załóż konto
                </CreateAccountButton>
              </div>
            </Content>
          </Container>
        </ScrollContainer>
      </FullPageLayout>
    </ScrollContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export
export default LoginPage;
