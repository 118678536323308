import styled from 'styled-components';
import { typography } from 'src/config/typography';
import { ExpandMore } from 'src/common/components/ExpansionPanel/ExpandMore';
import { colors } from 'src/config/colors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import React from 'react';

export const EquipmentCategoryExpansionPanel = styled(Accordion)`
  position: relative;
  margin-bottom: 1rem;
  box-shadow: none;

  &.Mui-expanded {
    margin: 0 0 1rem 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white};
    opacity: 0.1;
    z-index: -1;
  }
`;

export const EquipmentCategoryExpansionPanelSummary = styled(
  AccordionSummary
).attrs({
  expandIcon: <ExpandMore />,
})`
  && {
    padding: 0 2rem 0 1.25rem;
    font-family: ${typography.fontFamilyCondensed};
    color: ${colors.white};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8125rem;
    letter-spacing: 1px;
    min-height: auto;
  }

  & > .MuiAccordionSummary-content {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .Mui-expanded {
      margin: initial;
    }
  }
`;

export const EquipmentCategoryExpansionPanelDetails = styled(AccordionDetails)`
  && {
    padding: 0.5rem 2rem 0.5rem 1.25rem;
  }
`;
