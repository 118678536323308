import React from 'react';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { AsideMainMenuLayout } from 'src/common/components/AsideMainManuLayout';
import { HowItWorksInfographicSlide1 } from 'src/public/components/how-it-works/HowItWorksInfographicSlide1';
import { HowItWorksInfographicSlide2 } from 'src/public/components/how-it-works/HowItWorksInfographicSlide2';
import { HowItWorksInfographicSlide3 } from 'src/public/components/how-it-works/HowItWorksInfographicSlide3';
import { HowItWorksInfographicSlide4 } from 'src/public/components/how-it-works/HowItWorksInfographicSlide4';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const Content = styled.div`
  margin-top: 8.125rem;

  ${media.w.max.px768(css`
    margin-top: 0.5rem;
    margin-bottom: 4rem;
    padding-right: 1rem;
  `)};
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5.875rem;

  ${media.w.max.px768(css`
    margin-bottom: 2rem;
  `)};
`;

const StyledScrollContainer = styled(ScrollContainer)`
  height: 100%;
`;

const HowItWorksPage = () => {
  useStorePageUrl();

  return (
    <AsideMainMenuLayout>
      <Seo title="Jak działa Car Platform" />
      <StyledScrollContainer>
        <Content>
          <Header>
            <Title level={1} variant="condensed">
              Jak Działa Car Platform
            </Title>
          </Header>
          <div>
            <HowItWorksInfographicSlide1 />
            <HowItWorksInfographicSlide2 />
            <HowItWorksInfographicSlide3 />
            <HowItWorksInfographicSlide4 />
          </div>
        </Content>
      </StyledScrollContainer>
    </AsideMainMenuLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default HowItWorksPage;
