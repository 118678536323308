import React, { FC, useRef, useState } from 'react';
import { Box, Popper, Skeleton, Typography, styled } from '@mui/material';
import { Brand } from 'src/app/common/models/product';
import { rem } from 'polished';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { useSelector } from 'react-redux';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import {
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { Icon } from 'src/common/components/Icon';
import { ExoticFilter, OptionFilter } from 'src/app/common/models/filter';

import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import {
  useFilterControl,
  useOptionFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import {
  StyledAutocomplete,
  StyledTextField,
} from 'src/app/search/components/SearchHeader/GeneralSearchAutocomplete/styles';
import { navigate } from 'gatsby';
import SearchIcon from '@mui/icons-material/Search';
interface GeneralSearchAutocompleteProps {
  fullWidth?: boolean;
  textfieldSx?: any;
}

export const GeneralSearchAutocomplete: FC<GeneralSearchAutocompleteProps> = ({
  fullWidth,
  textfieldSx,
}) => {
  const { setValue: setBrandValue } = useOptionFilterControl(
    OptionFilter.Brand
  );
  const { setValue: setModelState } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const brandsList = useSelector(brandsListSelector);
  const [isTyping, setIsTyping] = useState(false);

  if (!brandsList) {
    return <Skeleton style={{ height: rem(47) }} />;
  }

  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
      backgroundColor: 'rgb(255,255,255,0.98)',
      display: isTyping ? 'block' : 'none',
    },
  });
  const sortedBrandsList = [...brandsList].sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    return labelA.localeCompare(labelB);
  });

  const getCombineBrandAndModel = () => {
    const combinedBrandsAndModels: any[] = sortedBrandsList.map((brand) => {
      return {
        type: 'Marka',
        id: brand.id,
        code: brand.code,
        label: brand.label,
      };
    });

    sortedBrandsList.forEach((brand) => {
      if (brand.models.length > 0) {
        brand.models.forEach((model) => {
          const createdModel = {
            type: 'Model',
            id: `${brand.id} ${model.id}`,
            brandId: brand.id,
            modelId: model.id,
            code: brand.code,
            label: `${brand.label} ${model.label}`,
            brandLabel: brand.label,
            modelLabel: model.label,
            modelImageUrl: model.imageUrl,
          };
          combinedBrandsAndModels.push(createdModel);
        });
      }
    });

    return combinedBrandsAndModels;
  };

  const combineBrandAndModelWithFilter = getCombineBrandAndModel();

  return (
    <StyledAutocomplete
      popupIcon={<SearchIcon />}
      size="small"
      fullWidth={fullWidth}
      noOptionsText={isTyping ? 'Brak wyników' : ''}
      id="general-search-autocomplete"
      options={combineBrandAndModelWithFilter}
      groupBy={(option: any) => option.type}
      onInputChange={(_, value) => {
        setIsTyping(value.length > 0);
      }}
      onChange={(_, newValue: any) => {
        if (!newValue) return;
        if (!location.pathname.includes('search')) {
          navigate('/app/search?f=&v=2');
        }
        if (newValue.type === 'Marka') {
          setBrandValue([newValue.id]);
          setModelState({});
        } else if (newValue.type === 'Model') {
          setBrandValue([newValue.brandId]);
          setModelState({ [newValue.brandId]: [newValue.modelId] });
        }

        if (inputRef.current) {
          inputRef.current.blur();
          inputRef.current.value = '';
        }
      }}
      getOptionLabel={(brand) => {
        const typedBrand = brand as Brand & { models: BrandModel[] };
        return typedBrand.label;
      }}
      renderOption={(props, option: any) => {
        return (
          <li
            {...props}
            style={{
              color: 'black',
              paddingLeft: '1rem',
            }}
            id="autocomplete-element"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
              id="autocomplete-element"
            >
              <Box
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                id="autocomplete-element"
              >
                <OptionalFold
                  key={option.id}
                  value={brandIconMapper(option.code)}
                  onSome={(code) => (
                    <FilterBoxToggleButtonIcon>
                      <Icon
                        code={code}
                        size={Math.round(24 * brandIconResizeRates[code])}
                        id="autocomplete-element"
                      />
                    </FilterBoxToggleButtonIcon>
                  )}
                  onNone={
                    <FilterBoxToggleButtonTitle id="autocomplete-element">
                      {option.label}
                    </FilterBoxToggleButtonTitle>
                  }
                />
              </Box>
              <Typography id="autocomplete-element">{option.label}</Typography>
            </Box>
          </li>
        );
      }}
      PopperComponent={StyledPopper}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label="Szukaj"
          inputRef={inputRef}
          sx={{
            '& .MuiAutocomplete-popupIndicator': { transform: 'none' },

            ...(!fullWidth && {
              width: '240px',
            }),
            ...textfieldSx,
          }}
        />
      )}
    />
  );
};
