import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredLayout } from 'src/public/components/CenteredLayout';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import { CompanyConfirmationHeader } from 'src/public/components/company-confirmation/CompanyConfirmationHeader';
import { LandingRoute } from 'src/public/publicRoutes';
import { CompanyConfirmationLogo } from 'src/public/components/company-confirmation/CompanyConfirmationLogo';
import { CompanyConfirmationViewState } from 'src/common/models/companyConfirmationViewState';
import { CompanyConfirmationEmail } from 'src/public/components/company-confirmation/CompanyConfirmationEmail';
import { CompanyConfirmationSuccess } from 'src/public/components/company-confirmation/CompanyConfirmationSuccess';
import { CompanyConfirmationFailure } from 'src/public/components/company-confirmation/CompanyConfirmationFailure';
import { companyConfirmationViewStateSelector } from 'src/state/companyConfirmation/companyConfirmationSelectors';
import { companyConfirmationViewActions } from 'src/state/companyConfirmation/companyConfirmationActions';
import { AccountPageRoute } from 'src/app/routes';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const ChangeCompanyPage = () => {
  useStorePageUrl();
  const dispatch = useDispatch();

  const companyConfirmationViewState = useSelector(
    companyConfirmationViewStateSelector
  );
  const isLoggedIn = useSelector(isAuthenticatedSelector);

  const renderView = (view: CompanyConfirmationViewState) => {
    switch (view) {
      case CompanyConfirmationViewState.Failure:
        return <CompanyConfirmationFailure />;
      case CompanyConfirmationViewState.SuccessChange:
      case CompanyConfirmationViewState.SuccessConfirmation:
        return <CompanyConfirmationSuccess />;
      default:
        return <CompanyConfirmationEmail backButtonRoute={AccountPageRoute} />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        companyConfirmationViewActions.resetCompanyConfirmationViewState()
      );
    };
  }, []);

  if (
    isPlatformBrowser() &&
    !isLoggedIn &&
    companyConfirmationViewState !== CompanyConfirmationViewState.Failure
  ) {
    navigate(LandingRoute);
    return null;
  }

  return (
    <CenteredLayout>
      <div>
        <CompanyConfirmationLogo />
        <CompanyConfirmationHeader />

        {renderView(companyConfirmationViewState)}
      </div>
    </CenteredLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default ChangeCompanyPage;
