import React, { useRef } from 'react';
import { Box, Popper, Skeleton, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { Brand } from 'src/app/common/models/product';
import { rem } from 'polished';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { useSelector } from 'react-redux';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import {
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { Icon } from 'src/common/components/Icon';
import { pushBrandChoiceEvent } from 'src/app/home/services/homeGoogleTagManagerEvents';
import { OptionFilter } from 'src/app/common/models/filter';
import {
  StyledAutocomplete,
  StyledTextField,
} from 'src/app/home/components/HomePanel/HomePanelFiltersTab/BrandAutocomplete/styles';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import { HomePanelFiltersState } from 'src/app/home/components/HomePanel/HomePanelContent';

interface BrandAutocompleteProps {
  id?: string;
  changeValue: (value: string[], kind: OptionFilter) => void;
  state: HomePanelFiltersState;
}

export const BrandAutocomplete: FC<BrandAutocompleteProps> = ({
  changeValue,
  state,
}) => {
  const brandsList = useSelector(brandsListSelector);

  const brands = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: (
        <OptionalFold
          key={a.id}
          value={brandIconMapper(a.code)}
          onSome={(code) => (
            <FilterBoxToggleButtonIcon>
              <Icon
                code={code}
                size={Math.round(36 * brandIconResizeRates[code])}
              />
            </FilterBoxToggleButtonIcon>
          )}
          onNone={
            <FilterBoxToggleButtonTitle>{a.label}</FilterBoxToggleButtonTitle>
          }
        />
      ),
      textLabel: a.label,
    }))
  );

  const inputRef = useRef<HTMLInputElement>(null);

  if (!brandsList) {
    return <Skeleton style={{ height: rem(47) }} />;
  }

  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
      backgroundColor: 'rgb(255,255,255,0.98)',
    },
  });

  let sortedBrandsList = [...brandsList].sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    return labelA.localeCompare(labelB);
  });

  const motorcycleId = sortedBrandsList.find(
    (brand) => brand.code === 'motorcycle'
  )?.id;

  if (motorcycleId) {
    if (!state.brand.value.includes(motorcycleId)) {
      sortedBrandsList = sortedBrandsList.filter(
        (brand) => brand.code !== 'motorcycle'
      );
    } else {
      return <></>;
    }
  }

  function filterOptions(options: any, { inputValue }: { inputValue: string }) {
    return options.filter((option: any) =>
      option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }

  return (
    <StyledAutocomplete
      multiple
      filterOptions={filterOptions}
      noOptionsText="Brak wyników"
      id="tags-outlined"
      options={sortedBrandsList as Brand[]}
      onChange={(_, newValue: any) => {
        const ids = newValue.map((v: any) => v.id);
        changeValue(ids, OptionFilter.Brand);
        if (ids.length > state.brand.value.length) {
          pushBrandChoiceEvent(brands, ids);
        }
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }}
      getOptionLabel={(brand) => {
        const typedBrand = brand as Brand & { models: BrandModel[] };
        return typedBrand.label;
      }}
      renderInput={(params) => (
        <StyledTextField {...params} label="MARKA" inputRef={inputRef} />
      )}
      renderOption={(props, option: any) => {
        return (
          <li
            {...props}
            style={{
              color: 'black',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <OptionalFold
                  key={option.id}
                  value={brandIconMapper(option.code)}
                  onSome={(code) => (
                    <FilterBoxToggleButtonIcon>
                      <Icon
                        code={code}
                        size={Math.round(24 * brandIconResizeRates[code])}
                      />
                    </FilterBoxToggleButtonIcon>
                  )}
                  onNone={
                    <FilterBoxToggleButtonTitle>
                      {option.label}
                    </FilterBoxToggleButtonTitle>
                  }
                />
              </Box>
              <Typography>{option.label}</Typography>
            </Box>
          </li>
        );
      }}
      PopperComponent={StyledPopper}
    />
  );
};
