import { injectReducer, injectSaga } from 'src/state';
import {
  searchPanelReducer,
  SearchPanelState,
} from 'src/app/search/state/searchPanel/searchPanelReducer';
import {
  searchFiltersReducer,
  SearchFiltersState,
} from 'src/app/search/state/searchFilters/searchFiltersReducer';
import { searchFiltersSaga } from 'src/app/search/state/searchFilters/searchFiltersSagas';
import { AppState } from 'src/app/state';
import {
  searchProductsReducer,
  SearchProductsState,
} from 'src/app/search/state/searchProducts/searchProductsReducer';
import { searchProductsSaga } from 'src/app/search/state/searchProducts/searchProductsSagas';
import {
  searchUsedProductsReducer,
  SearchUsedProductsState,
} from 'src/app/search/state/searchProducts/searchUsedProductsReducer';
import { searchUsedProductsSaga } from 'src/app/search/state/searchProducts/searchUsedProductsSagas';

export const injectSearchState = () => {
  injectReducer('searchPanel', searchPanelReducer);
  injectReducer('searchFilters', searchFiltersReducer);
  injectReducer('searchProducts', searchProductsReducer);
  injectReducer('searchUsedProducts', searchUsedProductsReducer);
  injectSaga('searchFilters', searchFiltersSaga);
  injectSaga('searchProducts', searchProductsSaga);
  injectSaga('searchUsedProducts', searchUsedProductsSaga);
};

export interface SearchState extends AppState {
  searchPanel: SearchPanelState;
  searchFilters: SearchFiltersState;
  searchProducts: SearchProductsState;
  searchUsedProducts: SearchUsedProductsState;
}
