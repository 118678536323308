import { AuthorizedAxios } from 'src/common/services/axios';
import { HomeAdvertDeal } from 'src/app/home/models/homeAdvertDeal';
import { flatten } from 'src/lib/axios';
import { map } from 'fp-ts/lib/Array';
import { evolve } from 'ramda';
import { prependApiUrl } from 'src/config/environment';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';

export const fetchHomeAdvertDeals = () =>
  AuthorizedAxios.get<HomeAdvertDeal[]>('home-advert-deals')
    .then(flatten)
    .then(map(evolve({ imageUrl: prependApiUrl })));

export const fetchHomeAdvertSlides = () =>
  AuthorizedAxios.get<HomeAdvertSlideType[]>('home-advert-slides')
    .then(flatten)
    .then(
      map(
        evolve({
          background: {
            large: { url: prependApiUrl },
            small: { url: prependApiUrl },
          },
        })
      )
    );
