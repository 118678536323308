import { createReducer } from '@reduxjs/toolkit';
import { commonFiltersActions } from 'src/app/state/commonFilters/commonFiltersActions';
import { Brand, Category } from 'src/app/common/models/product';
import { filterConfig } from 'src/app/common/models/filterConfig';
import { OptionFilter } from 'src/app/common/models/filter';
import { authActions } from 'src/state/auth/authActions';
import { Image } from 'src/common/models/image';

export interface BrandModel {
  id: string;
  label: string;
  imageUrl?: Image;
}

export interface CommonFiltersState {
  brands?: (Brand & {
    models: BrandModel[];
  })[];
  categories?: Category[];
  bodies: { id: string; label: string }[];
}

const initialState: CommonFiltersState = {
  bodies: filterConfig[OptionFilter.Body].options,
};

export const commonFiltersReducer = createReducer<CommonFiltersState>(
  initialState,
  (builder) =>
    builder
      .addCase(commonFiltersActions.requestSuccess, (state, action) => {
        state.brands = action.payload.brands;
        state.categories = action.payload.categories;
      })
      .addCase(authActions.logout, () => initialState)
);
