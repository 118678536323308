import React from 'react';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

export const HowItWorksInfographicCounter = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 4.375rem;
  height: 4.375rem;
  font-size: 1.5625rem;
  color: white;
  background: ${colors.primary};
  border: 1px solid ${colors.darkGray};

  ${media.w.max.px768(css`
    width: 3.125rem;
    height: 3.125rem;
  `)};
`;

export interface CounterWrapperProps {
  number: number;
  position: {
    left?: string;
    right?: string;
    bottom?: string;
    top?: string;
  };
  sendToBack?: boolean;
  responsive?: {
    position: {
      left?: string;
      right?: string;
      bottom?: string;
      top?: string;
    };
    sendToBack?: boolean;
  };
  className?: string;
}

export const HowItWorksInfographicCounterWrapper: FC<CounterWrapperProps> = ({
  number,
  position,
  sendToBack,
  responsive = { position, sendToBack },
  children,
  className,
}) => {
  const isLarge = useMediaQuery({ minWidth: widthBreakpoints.px768 });

  return (
    <div style={{ position: 'relative' }} className={className}>
      {isLarge && (
        <>
          {children}
          <HowItWorksInfographicCounter
            style={{
              ...position,
              zIndex: sendToBack ? -1 : 1,
            }}
          >
            {number}
          </HowItWorksInfographicCounter>
        </>
      )}
      {!isLarge && (
        <>
          {children}
          <HowItWorksInfographicCounter
            style={{
              ...(responsive?.position ?? {}),
              zIndex: responsive.sendToBack ? -1 : 1,
            }}
          >
            {number}
          </HowItWorksInfographicCounter>
        </>
      )}
    </div>
  );
};
