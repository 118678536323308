import React, { FC, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { HiddenContent } from 'src/common/components/HiddenContent';
import { Button } from 'src/common/components/Button';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { object, ref, string } from 'yup';
import { FormItem } from 'src/common/components/FormItem';
import {
  AuthorizedAxios,
  getErrorMessages,
  toErrorResponse,
} from 'src/common/services/axios';
import { useSnackbar } from 'notistack';
import { TextControl } from 'src/common/components/TextControl';
import { PasswordValidationFieldDescription } from 'src/common/components/PasswordValidationFieldDescription';

interface ChangePasswordFormModel {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const initialValues: ChangePasswordFormModel = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validationSchema = object().shape({
  password: string().required('Pole wymagane'),
  newPassword: string()
    .required('Pole wymagane')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Hasło musi mieć co najmniej 8 znaków, w tym co najmniej jedna wielka litera, jedna mała litera, jedna cyfra i jeden znak specjalny (@$!%*#?&)'
    ),
  confirmNewPassword: string()
    .required('Pole wymagane')
    .oneOf([ref('newPassword'), ''], 'Hasła muszą być takie same'),
});

export const ChangePasswordForm: FC = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    (
      values: ChangePasswordFormModel,
      formikHelpers: FormikHelpers<ChangePasswordFormModel>
    ) =>
      AuthorizedAxios.post('users/me/password', values)
        .then(() => {
          enqueueSnackbar('Hasło zostało zmienione', { variant: 'success' });
          setIsEditable(false);
          formikHelpers.resetForm();
        })
        .catch((e) =>
          getErrorMessages(toErrorResponse(e)).forEach((message) =>
            enqueueSnackbar(message, {
              variant: 'error',
            })
          )
        )
        .finally(() => formikHelpers.setSubmitting(false)),
    []
  );

  return (
    <>
      <HiddenContent isVisible={isEditable}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormItem name="password">
                <TextControl
                  type="password"
                  name="password"
                  label="Wpisz aktualne hasło"
                />
              </FormItem>
              <FormItem name="newPassword">
                <TextControl
                  type="password"
                  name="newPassword"
                  label="Utwórz nowe hasło"
                />
                <PasswordValidationFieldDescription />
              </FormItem>
              <FormItem name="confirmNewPassword">
                <TextControl
                  type="password"
                  name="confirmNewPassword"
                  label="Powtórz nowe hasło"
                />
              </FormItem>

              <FormActionsContainer>
                <Button kind="box" type="submit" disabled={isSubmitting}>
                  Zatwierdź
                </Button>

                <Button
                  kind="box"
                  variant="transparent"
                  onClick={() => setIsEditable(false)}
                  disabled={isSubmitting}
                  style={{ marginLeft: 8 }}
                >
                  Anuluj
                </Button>
              </FormActionsContainer>
            </Form>
          )}
        </Formik>
      </HiddenContent>
      {!isEditable && (
        <Button
          kind="box"
          variant="gray"
          size="small"
          onClick={() => setIsEditable(true)}
          style={{ marginTop: -10 }}
        >
          Aktualizuj hasło
        </Button>
      )}
    </>
  );
};
