import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 15 64 34" {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M1.68227 45.4663C.174781 44.7235 0 44.2647 0 40.8565v-2.8402h2.62172v2.2721c0 2.4251.24032 3.5175.8739 3.8452.54619.284 7.64668.284 7.64668 0 0-.1092.3496-.6117.7865-1.0924l.7865-.8739h-.8957c-.6555 0-.8958-.0874-.8958-.3277 0-.9831 1.5075-2.6654 2.9495-3.3208.7646-.3496 1.6822-.3933 7.1004-.4589l6.2266-.0655 1.8352 1.3109c1.005.7428 1.9445 1.3327 2.0755 1.3327.153 0 1.158-.5899 2.2722-1.3109l2.0318-1.3108h2.2722c2.01 0 2.2721.0436 2.4032.4151.0656.2184.0874 1.5074.0656 2.862-.0305 1.5453-.0563 2.3621.3387 2.7937.4539.4959 1.4633.4835 3.6594.4835h3.0368v-6.5762l2.731.1311.1311 6.4451 5.156-.0437c4.6317-.0656 5.1779-.1093 5.3964-.437.1747-.284.1747-.5025 0-.7647-.2185-.3495-.7429-.3932-4.9813-.4588l-4.7191-.0437v-.6554c0-.9613.3714-1.7696 1.2672-2.6873 1.2234-1.2453 2.2503-1.4637 6.9694-1.4637h3.9981v1.5074l-3.9107.0656c-3.8015.0655-3.9326.0874-4.4788.5899-1.1142 1.0487-.8958 1.1142 3.3645 1.1142 4.3696 0 4.9595.1311 5.5712 1.3109.4151.7865.4151 1.3764.0218 2.141-.104.2011-.187.3697-.3032.511-.6162.7498-2.1635.7344-12.6961.7344-10.3558 0-11.5574-.0437-12.1692-.3715-1.2016-.6554-1.289-.8957-1.3545-3.9107l-.0874-2.7965-.5899.3059c-.7647.3714-3.9544 2.4906-3.9544 2.5998 0 .0437 1.1579.9395 2.578 2.01l2.5562 1.9445-4.02.1311-1.6822-1.2235c-.9176-.6554-1.7916-1.2016-1.9008-1.2016-.1311 0-1.1798.5899-2.3814 1.3108l-2.1411 1.3327-22.83074-.131-1.04869-.5244zm26.91633-3.1461l-1.2453-.8957c-.3053-.2265-.6947-.5106-1.0866-.7964-.4776-.3484-.9588-.6994-1.2948-.9514-.6336-.4588-1.1798-.7647-1.1798-.6991-.0146.0437-.0291.1456-.0437.2476-.0073.0509-.0146.1019-.0219.1456-.0218.2185-1.0268.3059-4.0636.3496-3.7797.0437-4.0637.0874-4.5225.5243-.284.2622-.5025.5462-.5025.6554 0 .0874 1.9008.1967 4.2166.2185l4.1948.0656v1.5293l-3.6049.0655c-3.3208.0437-3.6267.0874-4.0855.5244-1.0705 1.005-.8739 1.0487 4.7628 1.0487h5.2653l1.6167-1.005 1.5949-1.0269z"
    />
    <path d="M20.406 23.0508c1.442-2.9276 4.6099-4.9595 8.6736-5.5275 3.299-.4806 7.8433-.0437 7.8433.721 0 .1748-.8739 1.4419-1.9226 2.8183-3.1679 4.0637-5.025 7.035-4.7847 7.6249.1311.3277.721.3714 5.9207.3714h5.7897l.3495-.6555c.4807-.9394.437-3.43-.1311-4.9157-.5243-1.4201-1.9225-2.9057-3.6267-3.867-.9394-.5244-1.1142-.721-.9176-.9613.3933-.4807.8739-.3714 2.294.4806 2.4251 1.442 3.6923 3.0369 4.3696 5.5275.3277 1.1579.3495 1.5949.1529 2.7746-.2622 1.4857-.9394 3.0806-1.5512 3.5175-.4151.3277-13.1741.3933-14.5505.0874-1.2672-.3059-2.0974-1.2016-2.0974-2.3158 0-1.1361.7865-2.4688 3.9326-6.7291 1.3327-1.7697 2.4032-3.3209 2.4032-3.4301 0-.3277-2.1192-.2622-3.6922.1092-5.3527 1.2672-8.4988 5.8552-6.9257 10.1155.8302 2.2503 2.9713 4.2166 5.44 4.9594 4.0419 1.2016 9.0231.6991 12.0381-1.2235.9394-.5898 2.3377-.8957 2.6654-.5898.1748.1966-1.9226 1.6385-3.2553 2.2503-1.7915.8302-3.8889 1.2016-6.8165 1.2016-3.1242 0-5.1123-.3933-7.2097-1.3983-2.0974-1.005-3.4301-2.2503-4.304-4.0199-.6554-1.3327-.7209-1.6168-.7209-3.5393 0-1.8571.0655-2.2067.6335-3.3864z" />
  </IconBox>
);
