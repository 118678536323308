import React, { memo } from 'react';
import { Button } from 'src/common/components/Button';
import { useDispatch } from 'react-redux';
import { navigationActions } from 'src/state/navigation/navigationActions';
import Menu from 'src/assets/icons/Menu';
import { StyleProps } from '@summer/react-kit';

export const MainMenuButton = memo<StyleProps>(({ className, style }) => {
  const dispatch = useDispatch();

  return (
    <Button
      kind="box"
      variant="transparent"
      onClick={() => dispatch(navigationActions.open())}
      className={className}
      style={style}
      icon={Menu}
    />
  );
});
