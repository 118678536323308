import styled from 'styled-components';
import { colors } from 'src/config/colors';

export const AuthHeaderItem = styled.div<{ active?: boolean }>`
  color: ${colors.lightGray};
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: 0.9375rem;
  margin-right: 1.125rem;
  opacity: ${(props) => (props.active ? '1' : '0.5')};
  padding-bottom: 0.5rem;
  position: relative;
  text-transform: uppercase;
  width: 6.875rem;

  &::after {
    background-color: ${colors.primary};
    bottom: -0.125rem;
    content: ${(props) => props.active && '""'};
    display: block;
    height: 0.1875rem;
    left: 0;
    position: absolute;
    width: 2rem;
  }
`;
