import React, { FC } from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import { Paragraph } from 'src/common/components/typography/Text';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Title } from 'src/common/components/typography/Title';
import { ToggleButtonGroupControl } from 'src/common/components/ToggleButtonGroupControl';
import { ToggleButton } from 'src/common/components/ToggleButton';
import { Button } from 'src/common/components/Button';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Link } from 'gatsby';
import { PrivacyPolicyAndCookiesRoute } from 'src/public/publicRoutes';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const ToggleButtonGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.375rem;
  grid-column-gap: 0.375rem;
  grid-auto-rows: 1fr;
`;

const StyledToggleButton = styled(ToggleButton)`
  text-align: center;
  white-space: normal;
  justify-content: center;
  line-height: 1.25;
  min-height: 3.125rem;
  height: 100%;
`;

export interface ContactFormContentProps {
  showAgreement: boolean;
  isSubmitting: boolean;
}

export const ContactFormFields: FC<ContactFormContentProps> = ({
  showAgreement,
  isSubmitting,
}) => (
  <Form>
    <Paragraph size="small">
      Jeśli chcesz wiedzieć więcej,
      <br />
      skorzystaj z&nbsp;naszej infolinii lub napisz maila.
    </Paragraph>

    <FormItem name="name">
      <TextControl type="text" name="name" label="Imię i nazwisko" />
    </FormItem>

    <FormItem name="email">
      <TextControl type="email" name="email" label="Adres e-mail" />
    </FormItem>

    <FormItem name="reason">
      <Title level={3} variant="condensed">
        Wybierz temat wiadomości
      </Title>
      <ToggleButtonGroupContainer>
        <ToggleButtonGroupControl
          name="reason"
          limit={1}
          toggleButtonComponent={StyledToggleButton}
          items={[
            {
              id: 'Produkt',
              label: 'Produkt',
            },
            {
              id: 'Finansowanie',
              label: 'Finansowanie',
            },
            {
              id: 'Oferta',
              label: 'Oferta',
            },
            {
              id: 'Wsparcie przy korzystaniu z platformy',
              label: <>Wsparcie przy korzystaniu z&nbsp;platformy</>,
            },
            {
              id: 'Inny powód',
              label: 'Inny powód',
            },
            {
              id: 'Car Platform Business',
              label: 'Car Platform Business',
            },
          ]}
        />
      </ToggleButtonGroupContainer>
    </FormItem>

    <FormItem name="content">
      <TextControl name="content" multiline label="Twój komentarz" />
    </FormItem>

    {showAgreement && (
      <Paragraph size="xSmall">
        Administratorem Twoich danych jest Mobility Benefit Sp. z o.o. Twoje
        dane zostaną użyte do celów obsługi Twojego zgłoszenia złożonego za
        pośrednictwem formularza kontaktowego. Masz prawo żądać dostępu do
        danych osobowych, ich sprostowania lub usunięcia, ograniczenia
        przetwarzania lub sprzeciwu wobec przetwarzania, a także prawa do ich
        przenoszenia danych. Więcej informacji o przetwarzaniu Twoich danych
        znajdziesz w naszej{' '}
        <SimpleLink
          as={Link}
          to={PrivacyPolicyAndCookiesRoute}
          style={{ paddingTop: 0 }}
        >
          Polityce prywatności i cookies
        </SimpleLink>
      </Paragraph>
    )}

    <div style={{ textAlign: 'right' }}>
      <Button
        kind="skew"
        size="small"
        icon={ArrowRight}
        type="submit"
        disabled={isSubmitting}
        onClick={() => {
          pushEvent(EventName.Try, {});
        }}
      >
        Wyślij
      </Button>
    </div>
  </Form>
);
