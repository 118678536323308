import { FinancingType, Offer } from 'src/common/models/offer';
import { pipe } from 'fp-ts/pipeable';
import { purge } from 'src/lib/purge';
import { match } from 'src/lib/match';
import { findCheapest } from 'src/app/product/services/offer';
import { prune } from '@summer/react-kit/functions';
import { isNil, sort, uniq } from 'ramda';
import { CustomerType } from 'src/common/models/customerType';
import { luxuryCarTypes, ProductType } from 'src/app/common/models/product';

type secondaryParamKey =
  | 'initialPayment'
  | 'annualMileage'
  | 'contractPeriod'
  | 'buyoutPrice';

const paramsOrder: secondaryParamKey[] = [
  'initialPayment',
  'contractPeriod',
  'annualMileage',
  'buyoutPrice',
];

const sortAsc = sort((a: number, b: number) => a - b);

export const findOfferWithParams = (
  primaryParams: Partial<Offer>,
  secondaryParams: Partial<Offer>
) => (offers: Offer[], baseOffer?: Offer, customerType?: CustomerType) => {
  const validSecondaryParams: Partial<Offer> = {};

  for (const param of paramsOrder) {
    const paramValue = secondaryParams[param];

    if (isNil(paramValue)) {
      continue;
    }

    const list = createOptionsList(param, {
      ...primaryParams,
      ...validSecondaryParams,
    })(offers);

    if (list.length === 0) {
      continue;
    }

    const foundIndex = list.indexOf(paramValue);

    if (foundIndex !== -1) {
      validSecondaryParams[param] = paramValue;
      continue;
    }

    let smallerIndex;

    const largerIndex = list.findIndex((value) => value > paramValue);

    if (largerIndex === -1) {
      smallerIndex = list.length - 1;
    } else if (largerIndex === 0) {
      smallerIndex = -1;
    } else {
      smallerIndex = largerIndex - 1;
    }

    if (largerIndex === -1) {
      validSecondaryParams[param] = list[smallerIndex];
      continue;
    }

    if (smallerIndex === -1) {
      validSecondaryParams[param] = list[largerIndex];
      continue;
    }

    const smallerValueDiff = paramValue - list[smallerIndex];
    const largerValueDiff = list[largerIndex] - paramValue;

    validSecondaryParams[param] =
      list[largerValueDiff > smallerValueDiff ? smallerIndex : largerIndex];
  }

  const params = {
    ...primaryParams,
    ...validSecondaryParams,
  };

  return findCheapest(
    offers.filter(match(purge(params))),
    customerType,
    baseOffer
  );
};

export const createOptionsList = (
  key: secondaryParamKey,
  params: Partial<Offer>
) => (offers: Offer[]) =>
  pipe(
    offers.filter(match(purge(params))).map((a) => a[key]),
    prune,
    uniq,
    sortAsc
  );

export const getPackageTypeFilter = (
  activeOffer?: Offer,
  productType?: ProductType
) =>
  productType &&
  activeOffer?.financingType === FinancingType.Rent &&
  !luxuryCarTypes.includes(productType)
    ? activeOffer?.packageType
    : undefined;
