import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="11 0 42 64" {...props}>
    <path d="M17.2383 0.000349839H13.4972C12.6963 0.000349839 12.0508 0.649225 12.0508 1.44679V14.0118C12.0508 14.8127 12.6963 15.4582 13.4972 15.4582H17.2383C18.0358 15.4582 18.6847 14.8127 18.6847 14.0118V1.44679C18.6847 0.649225 18.0358 0.000349839 17.2383 0.000349839Z" />
    <path d="M50.8341 0.000349839H47.0929C46.2954 0.000349839 45.6465 0.649225 45.6465 1.44679V14.0118C45.6465 14.8127 46.2954 15.4582 47.0929 15.4582H50.8341C51.635 15.4582 52.2805 14.8127 52.2805 14.0118L52.2839 1.44679C52.2805 0.649225 51.635 0.000349839 50.8341 0.000349839Z" />
    <path
      opacity="0.5"
      d="M17.1876 48.5391H13.4464C12.6455 48.5391 12 49.188 12 49.9856V62.5539C12 63.3515 12.6455 64.0003 13.4464 64.0003H17.1876C17.9851 64.0003 18.634 63.3515 18.634 62.5539V49.9856C18.634 49.188 17.9851 48.5391 17.1876 48.5391Z"
    />
    <path
      opacity="0.5"
      d="M50.7793 48.5391H47.0382C46.2407 48.5391 45.5918 49.188 45.5918 49.9856V62.5539C45.5918 63.3515 46.2407 64.0003 47.0382 64.0003H50.7793C51.5802 64.0003 52.2291 63.3515 52.2291 62.5539V49.9856C52.2291 49.188 51.5802 48.5391 50.7793 48.5391Z"
    />
    <path d="M38.2578 7.77644V8.29352H45.1351V6.67134H38.2578V7.77644Z" />
    <path d="M25.9712 8.29352V6.67134H19.1953V8.29352H25.9712Z" />
    <path d="M40.7477 55.1648C38.3854 55.1648 36.0536 54.5328 34.0732 53.2452C33.7454 53.0323 33.3838 52.8735 32.9985 52.7789V37.3244V32.8026V32.3937C32.9985 28.8182 33.5156 25.2697 34.4517 21.8192C34.9147 20.1092 35.1952 18.2133 35.1952 15.7834V15.0805H34.7559V14.7459H35.736L36.2665 14.2187V14.0227L36.4964 13.7929H37.5203L37.7501 13.5597V11.7922L37.5203 11.5624H36.4964L36.2665 11.3293V11.1298L36.4964 10.9H37.5203L37.7501 10.6668V8.89937L37.5203 8.66959H36.4964L36.2665 8.43976V8.237L36.4964 8.00718H37.5203L37.7501 7.77401V6.00992L37.5203 5.77671H36.4964L36.2665 5.54693V5.34751L36.4964 5.11434H37.5203L37.7501 4.88452V3.11705L37.5203 2.88388H36.6044C36.2226 2.88388 35.8576 2.72164 35.6041 2.43438L35.4656 2.27894C35.0465 1.80581 34.4483 1.53884 33.8197 1.53884H32.1131H30.4099C29.7813 1.53884 29.1831 1.80581 28.764 2.27894L28.6255 2.43438C28.372 2.72164 28.007 2.88388 27.6252 2.88388H26.7092L26.4795 3.11705V4.88452L26.7092 5.11434H27.7332L27.9665 5.34751V5.54693L27.7332 5.77671H26.7092L26.4795 6.00992V7.77401L26.7092 8.00718H27.7332L27.9665 8.237V8.43976L27.7332 8.66959H26.7092L26.4795 8.89937V10.6668L26.7092 10.9H27.7332L27.9665 11.1298V11.3293L27.7332 11.5624H26.7092L26.4795 11.7922V13.5597L26.7092 13.7929H27.7332L27.9665 14.0227V14.2187L28.4936 14.7459H29.4737V15.0805H29.0344V15.7834C29.0344 18.2133 29.3149 20.1092 29.7779 21.8192C30.714 25.2697 31.2311 28.8182 31.2311 32.3937V32.6573V37.3244V38.078V52.7789C30.8458 52.8735 30.4842 53.0323 30.1564 53.2452C28.176 54.5328 25.8442 55.1648 23.4819 55.1648H19.1426V57.3716H23.5224C25.905 57.3716 28.2098 58.0982 30.2409 59.3418C30.785 59.6764 31.427 59.869 32.1131 59.869C32.8025 59.869 33.4446 59.6764 33.9887 59.3418C36.0232 58.0982 38.3247 57.3716 40.7072 57.3716H45.087V55.1648H40.7477Z" />
  </IconBox>
);
