import React, { memo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { Link } from 'gatsby';
import { CarPlatformBusinessRoute, LoginRoute } from 'src/public/publicRoutes';
import { SimpleLink } from 'src/common/components/SimpleLink';

const Modal = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'footer';
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;

  ${media.w.min.px768(css`
    padding-top: 4rem;
  `)}
`;

const Header = styled.div`
  grid-area: header;
  padding: 2.5rem 0 1.25rem;
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyCondensed};
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5625rem;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  grid-area: content;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 67.5rem;
  padding: 0 1.8125rem;

  ${media.w.min.px576(css`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  `)}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding-top: 2.125rem;
  display: block;
  text-align: center;
`;

const Footer = styled.div`
  grid-area: footer;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
`;

export interface CarPlatformBusinessModalProps {
  onClose: () => void;
}

export const CarPlatformBusinessModal = memo<CarPlatformBusinessModalProps>(
  ({ onClose }) => {
    const scrollRef = useRef<OverlayScrollbarsComponent>(null);

    useKeyPress(KEY_ESCAPE, CODE_ESCAPE, onClose);

    return (
      <Modal>
        <Header>Otrzymaj lepszą ofertę na to auto</Header>
        <Content>
          <StyledScrollContainer ref={scrollRef}>
            <Container>
              <div>
                Czy wiesz, że pracownicy firm, które mają podpisaną umowę z
                CarPlatform mogą cieszyć się lepszą ofertą i dodatkowymi
                zwrotami do auta (cashback).{' '}
                <SimpleLink as={Link} to={CarPlatformBusinessRoute}>
                  Sprawdź szczegóły programu
                </SimpleLink>{' '}
                lub załóż konto, jeśli Twoja firma współpracuje już z Car
                Platform.
              </div>
              <StyledButton
                size="small"
                icon={ArrowRight}
                forwardedAs={Link}
                to={LoginRoute}
              >
                Załóż konto
              </StyledButton>
            </Container>
          </StyledScrollContainer>
        </Content>
        <Footer>
          <Button
            kind="box"
            variant="transparent"
            size="small"
            style={{ textDecoration: 'underline' }}
            onClick={onClose}
          >
            Zamknij
          </Button>
        </Footer>
      </Modal>
    );
  }
);
