import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fill="#0081FF"
      opacity=".9"
      d="M64 32c0 17.6731-14.3269 32-32 32C14.3269 64 0 49.6731 0 32 0 14.3269 14.3269 0 32 0c17.6731 0 32 14.3269 32 32z"
    />
    <path
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
      d="M22.6953 15.2446l26.1839 16.3649-26.1839 16.3649v-6.999h3v1.5863l17.5235-10.9522-17.5235-10.9521v17.5863h-3V15.2446z"
    />
  </IconBox>
);
