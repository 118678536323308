import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 85 85" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M79.081 80.414c.736 0 1.333-.597 1.333-1.333v-3.787l2.587-12.935c.018-.087.026-.174.026-.262v-9.323a9.17 9.17 0 00-1.614-5.198L68.422 28.68a1.333 1.333 0 00-2.198 1.511l12.992 18.896a6.505 6.505 0 011.145 3.687v9.191L77.774 74.9a1.335 1.335 0 00-.026.262v3.919c0 .736.597 1.333 1.333 1.333z" />
      <path d="M63.404 80.414c.736 0 1.333-.597 1.333-1.333v-3.92c0-.444-.222-.859-.59-1.106l-.002-.001-.019-.014a8.588 8.588 0 01-.497-.389c-.344-.29-.825-.732-1.345-1.327C61.24 71.132 60.06 69.356 59.47 67m2.6 8.823v3.258c0 .736.596 1.333 1.333 1.333m-1.334-4.591l-.158-.131c-.431-.364-1.012-.9-1.635-1.612-1.243-1.421-2.676-3.565-3.393-6.434-.874-3.5-.518-6.669.047-8.947.283-1.141.62-2.07.89-2.718.13-.31.244-.557.33-.735V42.5a5.264 5.264 0 015.252-5.253c2.748 0 5.016 2.13 5.236 4.823l5.02 7.53c.145.218.223.476.223.739v3.92a3.947 3.947 0 01-7.892 0V42.5a2.598 2.598 0 00-2.586-2.586 2.598 2.598 0 00-2.587 2.586v13.065c0 .224-.056.445-.164.641v.001l-.001.002-.001.001-.017.031-.074.148c-.066.136-.163.344-.276.616a16.403 16.403 0 00-.764 2.336c-.493 1.985-.79 4.695-.049 7.659m9.185-20.096l2.56 3.839v3.515a1.28 1.28 0 01-2.56 0v-7.354z" />
      <path d="M60.818 84.334v-3.92h20.85v3.92h2.666V79.08c0-.736-.597-1.333-1.334-1.333H59.485c-.736 0-1.333.597-1.333 1.333v5.253h2.667zM43.78 75.189h18.344v-2.667H43.779v2.667zM68.656 43.834V9.839A9.173 9.173 0 0059.484.667H8.505v2.667h50.979a6.507 6.507 0 016.505 6.505v33.995h2.667z" />
      <path d="M13.731 73.856c0 .736.597 1.333 1.333 1.333h7.866v-2.667h-6.532V8.532c0-4.328-3.537-7.865-7.866-7.865S.667 4.204.667 8.533v3.919c0 .736.596 1.333 1.333 1.333h6.559V11.12H3.333V8.533c0-2.857 2.343-5.2 5.2-5.2 2.856 0 5.198 2.343 5.198 5.2v65.323zM32.021 17.678c0 .736.597 1.333 1.334 1.333h15.677a1.333 1.333 0 000-2.667H33.355c-.737 0-1.334.597-1.334 1.334zM24.183 29.436c0 .736.597 1.333 1.333 1.333h31.355a1.333 1.333 0 000-2.667H25.516c-.736 0-1.333.597-1.333 1.334zM24.183 37.275c0 .736.597 1.333 1.333 1.333h27.436a1.333 1.333 0 000-2.667H25.516c-.736 0-1.333.597-1.333 1.334zM24.183 45.113c0 .737.597 1.334 1.333 1.334h14.371a1.333 1.333 0 000-2.667h-14.37c-.737 0-1.334.597-1.334 1.333zM28.679 84.098c.4.276.918.312 1.353.095l4.63-2.315 4.629 2.315a1.333 1.333 0 001.923-1.325l-1.44-14.392-2.653.266 1.199 11.984-3.063-1.531a1.333 1.333 0 00-1.192 0l-3.062 1.53L32.2 68.743l-2.653-.266-1.44 14.392c-.048.483.17.955.57 1.23z" />
      <path d="M34.661 69.45a7.42 7.42 0 00-5.199-2.154 7.421 7.421 0 00-2.153-5.199 7.422 7.422 0 002.153-5.199 7.422 7.422 0 005.2-2.153 7.421 7.421 0 005.198 2.153 7.42 7.42 0 002.154 5.2 7.42 7.42 0 00-2.154 5.198 7.42 7.42 0 00-5.199 2.153zm-1.865 1.905a2.64 2.64 0 003.73 0 4.757 4.757 0 013.361-1.392 2.64 2.64 0 002.64-2.64c0-1.26.5-2.468 1.392-3.36a2.642 2.642 0 000-3.731 4.757 4.757 0 01-1.392-3.36 2.64 2.64 0 00-2.64-2.64c-1.26 0-2.467-.501-3.36-1.393a2.642 2.642 0 00-3.731 0 4.757 4.757 0 01-3.36 1.392 2.64 2.64 0 00-2.64 2.64c0 1.26-.501 2.468-1.392 3.36a2.642 2.642 0 00-.001 3.731 4.757 4.757 0 011.393 3.36 2.64 2.64 0 002.64 2.64c1.259 0 2.468.502 3.36 1.393z" />
    </g>
  </IconBox>
);
