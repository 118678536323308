import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4696 43.4664C16.4696 44.8053 17.5552 45.8919 18.8951 45.8919H23.2636C24.6035 45.8919 25.6891 44.8053 25.6891 43.4664V41.2821C25.6891 40.5457 25.0921 39.9488 24.3557 39.9488C23.6193 39.9488 23.0224 40.5457 23.0224 41.2821V43.2252H19.1363V40.7361C19.1363 40.6483 19.1277 40.5607 19.1104 40.4746L18.6704 38.2745C18.6171 38.0082 18.5903 37.737 18.5903 37.4652V31.897C18.5903 31.4022 18.6792 30.9115 18.853 30.448L20.1435 27.0067C20.2971 26.5972 20.2401 26.1385 19.991 25.779C19.7419 25.4196 19.3324 25.2051 18.8951 25.2051H16.7554L16.8986 24.5955H19.4412C19.9291 24.5955 20.378 24.329 20.6117 23.9006L22.9568 19.6015C23.297 18.9774 23.9512 18.5888 24.6626 18.5888H39.3385C40.0497 18.5888 40.7038 18.977 41.0446 19.6014L43.3894 23.9006C43.623 24.3289 44.072 24.5955 44.56 24.5955H47.1025L47.2456 25.2051H45.106C44.6687 25.2051 44.2592 25.4196 44.0101 25.779C43.761 26.1384 43.704 26.5972 43.8575 27.0066L45.1479 30.4478C45.3218 30.9117 45.4108 31.4025 45.4108 31.897V37.4652C45.4108 37.7367 45.384 38.008 45.3307 38.2749L44.8907 40.4745C44.8735 40.5606 44.8648 40.6483 44.8648 40.7361V43.2252H40.9788V41.2821C40.9788 40.5457 40.3818 39.9488 39.6454 39.9488C38.909 39.9488 38.3121 40.5457 38.3121 41.2821V43.4664C38.3121 44.8056 39.3983 45.8919 40.7375 45.8919H45.106C46.4453 45.8919 47.5315 44.8056 47.5315 43.4664V40.8681L47.9457 38.7976C48.0333 38.3589 48.0775 37.9123 48.0775 37.4652V31.897C48.0775 31.0821 47.9307 30.2743 47.6449 29.5117L47.03 27.8719H48.2393C49.451 27.8719 50.346 26.7424 50.0687 25.5627L49.6548 23.7994C49.3975 22.7035 48.4195 21.9288 47.2935 21.9288H45.3515L43.3857 18.3246C42.5776 16.8436 41.0256 15.9221 39.3385 15.9221H24.6626C22.9757 15.9221 21.4233 16.8432 20.6156 18.3247L18.6496 21.9288H16.7075C15.5812 21.9288 14.6041 22.7038 14.3464 23.7987L13.9324 25.5621C13.6552 26.7418 14.5501 27.8719 15.7619 27.8719H16.9711L16.3561 29.5116C16.0701 30.2745 15.9236 31.0824 15.9236 31.897V37.4652C15.9236 37.9119 15.9675 38.3583 16.0554 38.7975L16.4696 40.8681V43.4664Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8368 41.2822C20.8368 42.0186 21.4337 42.6155 22.1701 42.6155H41.2822C42.0186 42.6155 42.6156 42.0186 42.6156 41.2822C42.6156 40.5458 42.0186 39.9488 41.2822 39.9488H22.1701C21.4337 39.9488 20.8368 40.5458 20.8368 41.2822Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6541 34.1835C18.6541 34.9199 19.2511 35.5169 19.9875 35.5169H24.0183C25.4147 35.5169 26.3247 34.0477 25.6997 32.7972L25.0025 31.403C24.6731 30.7443 23.8722 30.4774 23.2136 30.8067C22.555 31.1361 22.288 31.937 22.6174 32.5956L22.7446 32.8502H19.9875C19.2511 32.8502 18.6541 33.4471 18.6541 34.1835Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.3012 32.7973C37.6756 34.0481 38.5866 35.5169 39.9821 35.5169H44.0131C44.7495 35.5169 45.3464 34.9199 45.3464 34.1835C45.3464 33.4471 44.7495 32.8502 44.0131 32.8502H41.256L41.3834 32.5954C41.7126 31.9367 41.4455 31.1358 40.7868 30.8066C40.1281 30.4774 39.3272 30.7445 38.998 31.4032L38.3012 32.7973Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8444 37.4599C26.8444 38.1963 27.4413 38.7932 28.1777 38.7932H35.8226C36.559 38.7932 37.1559 38.1963 37.1559 37.4599C37.1559 36.7235 36.559 36.1265 35.8226 36.1265H28.1777C27.4413 36.1265 26.8444 36.7235 26.8444 37.4599Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3832 26.5386C21.3832 27.275 21.9802 27.8719 22.7165 27.8719H41.2826C42.019 27.8719 42.616 27.275 42.616 26.5386C42.616 25.8022 42.019 25.2052 41.2826 25.2052H22.7165C21.9802 25.2052 21.3832 25.8022 21.3832 26.5386Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 2.66671C15.7995 2.66671 2.66671 15.7995 2.66671 32C2.66671 35.3153 3.21579 38.4988 4.22766 41.4673C4.46525 42.1643 4.09282 42.9219 3.39581 43.1595C2.6988 43.3971 1.94116 43.0247 1.70357 42.3277C0.598444 39.0856 0 35.6116 0 32C0 14.3267 14.3267 0 32 0C41.7703 0 50.5177 4.37957 56.3855 11.2787V5.29031C56.3855 4.55392 56.9825 3.95696 57.7189 3.95696C58.4553 3.95696 59.0522 4.55392 59.0522 5.29031V15.1828C59.0522 15.9192 58.4553 16.5161 57.7189 16.5161H47.8264C47.09 16.5161 46.4931 15.9192 46.4931 15.1828C46.4931 14.4464 47.09 13.8494 47.8264 13.8494H55.0448C49.6715 7.03625 41.3461 2.66671 32 2.66671ZM60.6063 20.8414C61.3034 20.6039 62.061 20.9765 62.2984 21.6735C63.4025 24.9145 64.0009 28.3895 64.0009 32C64.0009 49.6733 49.6742 64 32.0009 64C22.2287 64 13.481 59.6195 7.61251 52.7183V58.7091C7.61251 59.4455 7.01555 60.0425 6.27916 60.0425C5.54277 60.0425 4.94581 59.4455 4.94581 58.7091V48.8166C4.94581 48.0802 5.54277 47.4833 6.27916 47.4833H16.1716C16.908 47.4833 17.505 48.0802 17.505 48.8166C17.505 49.553 16.908 50.15 16.1716 50.15H8.95519C14.3287 56.9636 22.6544 61.3333 32.0009 61.3333C48.2014 61.3333 61.3342 48.2005 61.3342 32C61.3342 28.6857 60.7851 25.5011 59.7742 22.5335C59.5367 21.8364 59.9093 21.0788 60.6063 20.8414Z"
    />
  </IconBox>
);
