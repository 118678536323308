import React, { memo, useMemo } from 'react';
import {
  FilterGroupPanel,
  filtersByPanel,
} from 'src/app/search/models/filterGroupPanel';
import { createSearchFilterStateByKindsSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { SearchState } from 'src/app/search/state';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { isFilterPristine } from 'src/app/common/models/filterConfig';
import pickBy from 'lodash/fp/pickBy';
import {
  FilterKind,
  FilterStateKindMap,
  OptionFilter,
} from 'src/app/common/models/filter';
import { EquipmentFilterStatusList } from 'src/app/search/components/FilterStatusInfo/EquipmentFilterStatusList';
import { MixedFilterStatusList } from 'src/app/search/components/FilterStatusInfo/MixedFilterStatusList';
import { StyleProps } from '@summer/react-kit';
import { GeneralFilterStatusList } from 'src/app/search/components/FilterStatusInfo/GeneralFilterStatusList';

export interface SearchHeaderFilterStatusProps extends StyleProps {
  group: FilterGroupPanel;
  length?: number;
}

export const FilterStatusInfo = memo<SearchHeaderFilterStatusProps>(
  ({ group, length = 3, className, style }) => {
    const filterSelector = useMemo(
      () => createSearchFilterStateByKindsSelector(),
      []
    );
    const filters: {
      [P in FilterKind]?: FilterStateKindMap[P];
    } = useSelectorTransform(
      (state: SearchState) => filterSelector(state, filtersByPanel[group]),
      pickBy(
        (state: FilterStateKindMap[FilterKind], key) =>
          !isFilterPristine(key as FilterKind, state.value)
      )
    );

    if (group === FilterGroupPanel.General) {
      return (
        <GeneralFilterStatusList
          className={className}
          filters={filters}
          length={length}
        />
      );
    }

    if (group === FilterGroupPanel.Equipment) {
      return (
        <EquipmentFilterStatusList
          className={className}
          style={{ maxWidth: '10rem' }}
          length={length}
          state={filters[OptionFilter.EquipmentItemGroup]}
        />
      );
    }

    if (group === FilterGroupPanel.Performance) {
      return (
        <MixedFilterStatusList
          className={className}
          style={{ maxWidth: '15.75rem' }}
          length={length}
          filters={filters}
        />
      );
    }

    if (group === FilterGroupPanel.Appearance) {
      return (
        <MixedFilterStatusList
          className={className}
          style={{ maxWidth: '15.75rem' }}
          length={length}
          filters={filters}
        />
      );
    }

    return (
      <MixedFilterStatusList
        className={className}
        style={style}
        length={length}
        filters={filters}
      />
    );
  }
);
