import { rem } from 'polished';

const fontSizeBase = 14;
const fontFamilyCondensed =
  '"Roboto Condensed", "Arial Narrow", "Impact", sans-serif';
const fontFamilyRacingSansOne =
  '"Racing Sans One", "Arial Narrow", "Impact", sans-serif';
const fontFamilyDefault = '"Roboto", sans-serif';

export const typography = {
  fontSizeBase: rem(fontSizeBase),
  fontSizeLg: rem(fontSizeBase + 2),
  fontSizeSm: '0.75rem',
  fontWeightBold: 600,
  fontFamilyCondensed,
  fontFamilyDefault,
  fontFamilyRacingSansOne,
  headingFontFamily: fontFamilyCondensed,
  headingSize: [
    rem(Math.ceil(fontSizeBase * 2.428)), // h1 hero
    rem(Math.ceil(fontSizeBase * 2.142)), // h1 (30px)
    rem(Math.ceil(fontSizeBase * 1.571)), // h2 (22px)
    rem(Math.ceil(fontSizeBase * 1.142)), // h3 (16px)
    rem(Math.ceil(fontSizeBase)), // h4 (14px)
    rem(Math.ceil(fontSizeBase)), // h5 (14px), for adaptive h4
  ],
  lineHeight: [
    'initial',
    'initial',
    'initial',
    'initial',
    1.2, // fixe issue with capital Ż
    'initial',
  ],
  headingMargin: [
    [rem(6), rem(19)],
    [rem(6), rem(16)],
    [rem(6), rem(12)],
    [rem(6), rem(10)],
    [rem(6), rem(10)],
  ],
  paragraphSize: {
    ['default']: '1rem',
    ['small']: '0.875rem',
  },
  headingWeight: 'bold',
  fontStrong: 600,
} as const;
