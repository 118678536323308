import { Image } from 'src/common/models/image';

export interface ProductTestImageDetails {
  width: number;
}

export interface ProductTestGroup {
  rows: ProductTest[][];
  type: ProductTestType;
}

export interface ProductTestThumb {
  url: string;
  width: number;
}

export enum ProductTestType {
  Regular = 'regular',
  Promoted = 'promoted',
}

export interface ProductTest {
  date?: string;
  id: string;
  fullImage: Image;
  thumbImage?: Image;
  imageDetails: {
    full: ProductTestImageDetails;
    thumb: ProductTestImageDetails;
  };
  text?: string;
  type: ProductTestType;
  ytLink?: string;
}
