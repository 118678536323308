import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M30.8574 28.5709V33.1423H34.286V29.7138C34.286 29.0826 34.7977 28.5709 35.4289 28.5709H51.4289C52.0601 28.5709 52.5717 29.0826 52.5717 29.7138V33.1423H56.0003V28.5709C56.0003 26.6774 54.4653 25.1423 52.5717 25.1423H34.286C32.3924 25.1423 30.8574 26.6774 30.8574 28.5709Z" />
    <path d="M28.5715 33.144V28.5726C28.5753 25.4182 31.1314 22.8621 34.2858 22.8583H37.7144V20.5726C37.7144 19.3102 36.691 18.2869 35.4286 18.2869H2.28572C1.02335 18.2869 0 19.3102 0 20.5726V54.8584C0 56.1207 1.02335 57.1441 2.28572 57.1441H20.5715V36.5726C20.5715 34.6791 22.1065 33.144 24 33.144H28.5715ZM5.7143 52.5726C5.7143 53.2038 5.20262 53.7155 4.57144 53.7155C3.94025 53.7155 3.42858 53.2038 3.42858 52.5726V22.8583C3.42858 22.2271 3.94025 21.7154 4.57144 21.7154C5.20262 21.7154 5.7143 22.2271 5.7143 22.8583V52.5726ZM11.4286 52.5726C11.4286 53.2038 10.9169 53.7155 10.2857 53.7155C9.65455 53.7155 9.14287 53.2038 9.14287 52.5726V22.8583C9.14287 22.2271 9.65455 21.7154 10.2857 21.7154C10.9169 21.7154 11.4286 22.2271 11.4286 22.8583V52.5726ZM17.1429 52.5726C17.1429 53.2038 16.6312 53.7155 16 53.7155C15.3688 53.7155 14.8572 53.2038 14.8572 52.5726V22.8583C14.8572 22.2271 15.3688 21.7154 16 21.7154C16.6312 21.7154 17.1429 22.2271 17.1429 22.8583V52.5726ZM22.8572 29.7155C22.8572 30.3466 22.3455 30.8583 21.7143 30.8583C21.0831 30.8583 20.5715 30.3466 20.5715 29.7155V22.8583C20.5715 22.2271 21.0831 21.7154 21.7143 21.7154C22.3455 21.7154 22.8572 22.2271 22.8572 22.8583V29.7155Z" />
    <path d="M9.14258 10.2881H11.4283V16.0024H9.14258V10.2881Z" />
    <path d="M51.4238 43.9016V50.2857H55.9964L55.9953 43.9016L53.7153 41.6216L53.7095 41.6228L53.7038 41.6216L51.4238 43.9016Z" />
    <path d="M26.2852 10.2881H28.5709V16.0024H26.2852V10.2881Z" />
    <path d="M9.14286 8.00001H11.4286C12.0598 8.00001 12.5714 7.48834 12.5714 6.85715V4.57144C12.5714 3.94025 13.0831 3.42858 13.7143 3.42858H24C24.6312 3.42858 25.1429 3.94025 25.1429 4.57144V6.85715C25.1429 7.48834 25.6546 8.00001 26.2857 8.00001H28.5715C29.2026 8.00001 29.7143 7.48834 29.7143 6.85715V1.14286C29.7143 0.511675 29.2026 0 28.5715 0H9.14286C8.51168 0 8 0.511675 8 1.14286V6.85715C8 7.48834 8.51168 8.00001 9.14286 8.00001Z" />
    <path d="M3.42383 62.8567C3.42383 63.4879 3.9355 63.9995 4.56669 63.9995H6.85241C7.48359 63.9995 7.99527 63.4879 7.99527 62.8567V59.4281H3.42383V62.8567Z" />
    <path d="M24.0003 35.4292C23.3691 35.4292 22.8574 35.9409 22.8574 36.5721V58.2864C22.8574 58.9176 23.3691 59.4292 24.0003 59.4292H32.0003V35.4292H24.0003ZM28.5717 40.0006H27.4289V44.5721C27.4289 45.2033 26.9172 45.7149 26.286 45.7149C25.6548 45.7149 25.1431 45.2033 25.1431 44.5721V38.8578C25.1431 38.2266 25.6548 37.7149 26.286 37.7149H28.5717C29.2029 37.7149 29.7146 38.2266 29.7146 38.8578C29.7146 39.489 29.2029 40.0006 28.5717 40.0006Z" />
    <path d="M49.1423 50.2864V43.9024C49.1407 43.2958 49.3819 42.7139 49.812 42.2864L52.0978 40.0006C52.2393 39.8626 52.3984 39.7439 52.5709 39.6475V35.4292H34.2852V59.4292H52.5709V52.5721H51.428C50.1657 52.5721 49.1423 51.5487 49.1423 50.2864Z" />
    <path d="M62.8574 35.4292H54.8574V39.6475C55.03 39.7439 55.1891 39.8626 55.3306 40.0006L57.6163 42.2864C58.0465 42.7139 58.2876 43.2958 58.286 43.9024V50.2864C58.286 51.5487 57.2626 52.5721 56.0003 52.5721H54.8574V59.4292H62.8574C63.4886 59.4292 64.0003 58.9176 64.0003 58.2864V36.5721C64.0003 35.9409 63.4886 35.4292 62.8574 35.4292Z" />
    <path d="M26.2852 62.8553C26.2852 63.4865 26.7968 63.9982 27.428 63.9982H30.8566C31.4878 63.9982 31.9994 63.4865 31.9994 62.8553V61.7125H26.2852V62.8553Z" />
    <path d="M54.8574 62.8553C54.8574 63.4865 55.3691 63.9982 56.0003 63.9982H59.4289C60.06 63.9982 60.5717 63.4865 60.5717 62.8553V61.7125H54.8574V62.8553Z" />
  </IconBox>
);
