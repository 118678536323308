import React, {
  ComponentType,
  memo,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { takeLast } from 'ramda';
import {
  Checkbox,
  CheckboxProps,
  Switch,
} from 'src/common/components/Checkbox';
import { useDerivedState } from '@summer/react-kit/hooks';
import { useMediaQuery } from 'react-responsive';
import { below, widthBreakpoints } from 'src/config/breakpoints';
import styled from 'styled-components';
import { ID, idToggleEnhancer } from '@summer/react-kit';

const StyledCheckbox = styled(Checkbox)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

const StyledSwitch = styled(Switch)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export interface CheckboxItemOptions {
  id: string;
  label: ReactNode;
}

export interface CheckboxListProps {
  limit?: number;
  items: CheckboxItemOptions[];
  initialValue?: string[];
  value?: string[];
  onChange?: (v: string[]) => void;
  checkboxComponent?: ComponentType<CheckboxProps>;
}

export const CheckboxList = memo<CheckboxListProps>(
  ({
    limit,
    items,
    initialValue,
    value,
    onChange,
    checkboxComponent,
  }: CheckboxListProps) => {
    const [active, setActive] = useDerivedState(initialValue ?? [], {
      value,
      onChange,
    });

    const onActiveChange = useCallback(
      (value: boolean, id: ID) => {
        setActive((currentValue) => {
          const prep = (x: string[]) => (limit ? takeLast(limit - 1, x) : x);
          return value
            ? prep(currentValue).concat([id as string])
            : prep(currentValue).filter((_) => _ !== id);
        });
      },
      [limit, setActive]
    );

    const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });

    const Component = useMemo(() => {
      if (checkboxComponent) {
        return idToggleEnhancer<boolean, CheckboxProps>(checkboxComponent);
      } else {
        return idToggleEnhancer<boolean, CheckboxProps>(StyledCheckbox);
      }
    }, [checkboxComponent, isMobile]);

    return (
      <>
        {items.map((item) => (
          <Component
            id={item.id}
            key={item.id}
            label={item.label}
            value={!!active.find((_) => _ === item.id)}
            onChange={onActiveChange}
          />
        ))}
      </>
    );
  }
);
