import { createReducer } from '@reduxjs/toolkit';
import { authActions } from 'src/state/auth/authActions';
import { Product } from 'src/app/common/models/product';
import {
  fetchProductActions,
  productsActions,
} from 'src/app/product/state/products/productsActions';
import { Option } from 'fp-ts/lib/Option';
import { none, some } from 'fp-ts/Option';
import { Offer } from 'src/common/models/offer';

export interface ProductMapState {
  [key: string]: Option<Product>;
}

export interface ProductsState {
  productMap: ProductMapState;
  productId?: string;
  offer?: Offer;
}

const initialState: ProductsState = {
  productMap: {},
};

export const productsReducer = createReducer<ProductsState>(
  initialState,
  (builder) =>
    builder
      .addCase(productsActions.setActiveProductId, (state, action) => {
        state.productId = action.payload;
      })
      .addCase(productsActions.setActiveOffer, (state, action) => {
        state.offer = action.payload;
      })
      .addCase(fetchProductActions.requestSuccess, (state, action) => {
        state.productMap[action.payload.id] = some(action.payload);
        state.productId = action.payload.id;
      })
      .addCase(fetchProductActions.requestFailure, (state, action) => {
        state.productMap[action.payload.productId] = none;
        state.productId = undefined;
      })
      .addCase(authActions.logout, () => initialState)
      .addCase(productsActions.clear, (state) => ({
        ...initialState,
        productMap: state.productMap,
      }))
);
