import React, { memo } from 'react';
import { AuthHeader } from 'src/common/components/AuthHeader';
import { AuthHeaderItem } from 'src/common/components/AuthHeaderItem';

export enum RegistrationHeaderSteps {
  Step1 = 'step1',
  Step2 = 'step2',
}

export interface RegistrationHeaderProps {
  active: RegistrationHeaderSteps;
}

export const RegistrationHeader = memo<RegistrationHeaderProps>(
  ({ active }) => (
    <AuthHeader>
      <AuthHeaderItem active={active === RegistrationHeaderSteps.Step1}>
        Potwierdzenie miejsca pracy
      </AuthHeaderItem>
      <AuthHeaderItem active={active === RegistrationHeaderSteps.Step2}>
        Założenie konta
      </AuthHeaderItem>
    </AuthHeader>
  )
);
