import React, { memo } from 'react';
import { Title } from 'src/common/components/typography/Title';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import styled, { css } from 'styled-components';
import { CleanUnorderedList } from 'src/common/components/List';
import OrderNextStep1 from 'src/assets/images/order-next-steps-1.svg';
import OrderNextStep3 from 'src/assets/images/order-next-steps-3.svg';
import OrderNextStep4 from 'src/assets/images/order-next-steps-4.svg';
import OrderNextStep5 from 'src/assets/images/order-next-steps-5.svg';
import { Link } from 'gatsby';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { AppRoute } from 'src/app/routes';
import { Button } from 'src/common/components/Button';
import { activeProductSelector } from 'src/app/product/state/products/productsSelectors';
import { useSelector } from 'react-redux';
import Phone from 'src/assets/icons/Phone';
import Envelope from 'src/assets/icons/Envelope';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { isSome } from 'fp-ts/lib/Option';

const Container = styled.div`
  padding: 3vh 3vh 2rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  margin-bottom: 1.5rem;
  max-width: 56rem;
`;

const Image = styled.div`
  display: none;

  ${media.w.min.px576(css`
    display: block;
    max-width: 19.5rem;
    min-width: 16rem;
  `)}
  & > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const CarName = styled.span`
  text-decoration: underline;
`;

const Steps = styled(CleanUnorderedList)`
  display: grid;
  counter-reset: steps;
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.w.min.px576(css`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.375rem;
  `)}

  ${media.w.min.px1200(css`
    grid-auto-flow: column;
    grid-template-columns: 16rem 15rem 15rem 15rem;
    max-width: 1366px;
  `)};

  & > li {
    display: flex;
    align-items: center;
    height: 9.4375rem;

    ${media.w.max.px576(css`
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    `)};
  }
`;

const StepIcon = styled.div`
  height: 6.625rem;
  width: 6.625rem;
  flex-shrink: 0;
  position: relative;

  & > svg {
    max-height: 100%;
  }

  &::before {
    background-color: ${colors.darkGray};
    border-radius: 100%;
    color: ${colors.lightGray};
    content: counter(steps);
    counter-increment: steps;
    font-size: 1.5625rem;
    font-weight: 300;
    height: 2.3125rem;
    top: 0;
    left: -1rem;
    letter-spacing: 0.0625rem;
    line-height: 2.3125rem;
    position: absolute;
    text-align: center;
    width: 2.3125rem;
  }
`;

const StyledStepIcon = styled(StepIcon)`
  width: 9rem;
  height: 9rem;

  &::before {
    top: 1.25rem;
  }
`;

const StepLabel = styled(Text).attrs({ variant: 'condensed' })`
  font-size: 0.875rem;
  padding: 1rem;

  ${media.w.min.px576(css`
    font-size: 1rem;
  `)};
`;

const StepsParagraph = styled(Paragraph)`
  margin-top: 1.375rem;
  font-size: 1.125rem;
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  font-size: 1.125rem;
`;

const TextContainer = styled.div`
  margin: 1rem 0;
  width: 100%;
  ${media.w.min.px1200(css`
    width: 56rem;
  `)};
`;

const HeaderText = styled(Text)`
  font-weight: 700;
`;

const TeamParagraph = styled(Paragraph)`
  font-size: 1.125rem;
  color: ${colors.primary};
  font-weight: 700;
  margin: 0.438rem 0 0.625rem;
`;

const ContactContainer = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin-bottom: 2.25rem;

  ${media.w.min.px576(css`
    grid-template-columns: max-content max-content;
    grid-column-gap: 1.25rem;
    margin-bottom: 0;
  `)}
`;

export const OrderNextSteps = memo(() => {
  const product = useSelector(activeProductSelector);

  return (
    <Container>
      <Header>
        <div>
          <Title level={1}>Dziękujemy za zainteresowanie ofertą.</Title>
          <HeaderText>
            Na podany przez Ciebie adres e-mail wysłaliśmy potwierdzenie
            przyjęcia zgłoszenia. W&nbsp;przeciągu 24h nasz doradca skontaktuje
            się z&nbsp;Tobą, aby szczegółowo omówić ofertę.
          </HeaderText>
          <StepsParagraph>
            Tylko kilka kroków dzieli Cię od jazdy nowym{' '}
            <CarName>
              <b>{product != null && isSome(product) && product.value.label}</b>
            </CarName>
            :
          </StepsParagraph>
        </div>
        <Image>
          {product != null && isSome(product) && product.value.mainPhoto && (
            <img
              src={product.value.mainPhoto.original}
              alt="Twój nowy samochód"
            />
          )}
        </Image>
      </Header>
      <Steps>
        <li>
          <StyledStepIcon>
            <OrderNextStep1 />
          </StyledStepIcon>
          <StepLabel>
            <b>Kontakt od doradcy Car Platform</b>
          </StepLabel>
        </li>
        <li>
          <StepIcon>
            <OrderNextStep3 />
          </StepIcon>
          <StepLabel>Wypełnienie wniosku</StepLabel>
        </li>
        <li>
          <StepIcon>
            <OrderNextStep4 />
          </StepIcon>
          <StepLabel>Podpisanie umowy</StepLabel>
        </li>
        <li>
          <StepIcon>
            <OrderNextStep5 />
          </StepIcon>
          <StepLabel>
            Odebranie auta (u&nbsp;dealera bądź w&nbsp;wybranej lokalizacji)
          </StepLabel>
        </li>
      </Steps>
      <TextContainer>
        <StyledParagraph>
          Podczas całego procesu nasz dedykowany doradca będzie z&nbsp;Tobą
          w&nbsp;kontakcie mailowym lub telefonicznym.
        </StyledParagraph>
      </TextContainer>
      <TextContainer>
        <StyledParagraph>Pozdrawiamy,</StyledParagraph>
        <TeamParagraph>Team Car Platform</TeamParagraph>
        <ContactContainer>
          <Text>
            <Envelope size={16} style={{ marginRight: '0.625rem' }} />
            <SimpleLink href="mailto:hello@carplatform.pl">
              hello@carplatform.pl
            </SimpleLink>
          </Text>
          <Text>
            <Phone size={16} style={{ marginRight: '0.625rem' }} />
            <SimpleLink href="tel:+48 720 811 206">+48 720 811 206</SimpleLink>
          </Text>
        </ContactContainer>
      </TextContainer>
      <div>
        <Button kind="box" variant="secondary" as={Link} to={AppRoute}>
          Wróć do Car&nbsp;Platform
        </Button>
      </div>
    </Container>
  );
});
