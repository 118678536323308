import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M31.4869 11.3584C28.4697 13.9573 22.7117 17.4685 19.2074 18.844C18.1242 19.2711 18.1169 19.2711 18.1896 19.3363C18.2478 19.3869 26.3032 25.012 30.44 27.8789C31.1016 28.3422 32.7446 29.486 34.0896 30.4199C35.4346 31.3538 36.5324 32.1429 36.5397 32.1646C36.5397 32.237 35.8563 32.6642 35.7472 32.6642C35.66 32.6642 35.1002 32.2877 30.978 29.4715C29.6984 28.5956 28.3898 27.7558 28.0699 27.5965C27.0811 27.1332 25.9033 26.8726 24.7401 26.8726C22.9734 26.8726 20.5306 27.6327 18.684 28.7621L18.0733 29.1385L18.3423 29.334C18.495 29.4354 20.0581 30.514 21.8175 31.723C23.5769 32.932 26.4922 34.9374 28.288 36.1753C30.091 37.4133 31.7123 38.5354 31.9086 38.6657L32.2503 38.8974L33.0718 38.1951C34.933 36.5807 38.1173 34.4378 41.0327 32.8307C42.4868 32.0271 44.4425 31.0715 45.4603 30.6661C45.9547 30.4706 46.3545 30.2896 46.3545 30.2679C46.3545 30.2462 46.2018 30.1231 46.0128 29.9856C45.3003 29.486 40.0221 25.8011 38.9025 25.0265C38.2627 24.5777 37.463 24.0202 37.1213 23.7813C36.7796 23.5424 35.5073 22.652 34.2859 21.8049C33.0645 20.9579 31.6468 19.9661 31.1234 19.5969C30.6072 19.2349 29.7057 18.5978 29.124 18.1924C28.5424 17.787 28.0626 17.4323 28.0481 17.4033C28.0335 17.3454 28.7096 16.8821 28.8187 16.8821C28.8478 16.8821 29.1095 17.0413 29.3858 17.2296C29.9965 17.6495 30.8107 18.2069 33.6825 20.1688C36.9032 22.3696 37.5575 22.6302 39.775 22.6302C40.771 22.6302 41.1418 22.6013 41.7016 22.4782C42.5449 22.29 43.5628 21.9497 44.3043 21.6167C44.9732 21.3199 46.4272 20.5018 46.4272 20.4222C46.4272 20.3932 45.8311 19.9661 45.104 19.4666C43.3228 18.2503 42.6685 17.8015 39.8113 15.8324C35.0202 12.5311 32.432 10.7502 32.3593 10.714C32.323 10.6923 31.9304 10.9819 31.4869 11.3584Z" />
    <path d="M4.5651 43.4509C2.6603 43.6536 1.29349 44.3776 0.784577 45.4562C0.624632 45.811 0.588281 45.9775 0.588281 46.5277C0.588281 47.2734 0.711875 47.6498 1.10447 48.1349C1.40982 48.5113 2.29679 48.9602 3.11106 49.1629C3.809 49.3439 5.45934 49.5828 6.35358 49.6334C7.35688 49.6986 7.89487 49.9737 7.89487 50.437C7.89487 50.8859 7.40777 51.1248 6.5208 51.1248C6.04096 51.1248 5.91737 51.0958 5.59021 50.9076C5.37937 50.7845 5.11037 50.5528 4.97951 50.3646L4.74686 50.0388H0.312012L0.362903 50.4153C0.442876 51.0596 0.631902 51.4867 1.03904 51.9211C1.84603 52.7899 3.22738 53.1808 5.7865 53.2677C8.8909 53.3763 10.9556 52.674 11.6681 51.2623C12.2061 50.1836 11.9226 48.9746 10.9556 48.2724C10.1559 47.686 8.80365 47.2661 7.1315 47.0707C4.92135 46.81 4.40516 46.6435 4.40516 46.1874C4.40516 45.8906 4.69597 45.6445 5.19034 45.5431C6.04096 45.3694 6.79707 45.601 7.34961 46.2164L7.66223 46.5639H11.6027V46.3105C11.6027 45.8399 11.3192 45.2535 10.8757 44.8192C10.2577 44.1966 9.33438 43.7839 8.06936 43.5667C7.28417 43.422 5.39391 43.364 4.5651 43.4509Z" />
    <path d="M12.8387 46.7956C12.846 50.8715 12.9114 51.3637 13.5512 52.0732C13.9511 52.5148 14.3509 52.7465 15.1216 52.9637C16.0521 53.2315 17.1427 53.3256 18.873 53.275C21.8829 53.1881 23.1116 52.6017 23.5768 51.038C23.6786 50.7194 23.7004 50.0679 23.7222 47.1576L23.7586 43.6682H20.3343L20.3125 46.7594C20.2907 50.2199 20.3052 50.0968 19.7672 50.5384C19.3674 50.8715 18.8294 51.009 18.0733 50.9656C17.179 50.9149 16.6774 50.6253 16.3939 50.01C16.2703 49.7349 16.2557 49.4815 16.2557 46.687V43.6682H12.8387V46.7956Z" />
    <path d="M24.6892 46.0573L27.2701 46.0717L29.8583 46.0934L27.1683 48.5911L24.4711 51.0887V53.0796H35.4492V50.6905L32.7228 50.676L29.9965 50.6543L31.4651 49.315C32.2721 48.5766 32.9627 47.9395 33.0064 47.9033C33.05 47.8599 33.6171 47.3459 34.2714 46.7522L35.4492 45.6736V43.6682H24.6892V46.0573Z" />
    <path d="M36.3216 46.9043C36.3216 48.8228 36.3507 50.343 36.4016 50.6181C36.7287 52.6524 38.3282 53.3836 42.2468 53.275C45.1477 53.1954 46.4127 52.6814 46.9507 51.3493C47.1179 50.9512 47.1179 50.9005 47.1397 47.3025L47.1615 43.6682H43.8099V46.5857C43.8099 49.9376 43.7954 50.0752 43.2937 50.4951C42.8648 50.8643 42.3341 51.0091 41.5489 50.9656C40.691 50.915 40.233 50.6688 39.9422 50.1114L39.7386 49.7349V43.6682H36.3216V46.9043Z" />
    <path d="M48.0994 53.0796H51.6618L51.6763 51.4L51.6981 49.7204L52.716 50.6905C53.2758 51.2262 54.0682 51.9791 54.4754 52.3701L55.2169 53.0796H59.9862L59.6954 52.8045C59.2665 52.3918 55.3187 48.7359 55.0134 48.468C54.868 48.3449 54.7662 48.2291 54.788 48.2146C58.0523 45.4564 59.8045 43.9723 59.9499 43.842L60.1316 43.6682H57.8051L55.4787 43.6755L53.6248 45.2609C52.6069 46.1369 51.749 46.8536 51.72 46.8536C51.6836 46.8536 51.6618 46.1369 51.6618 45.2609V43.6682H48.0994V53.0796Z" />
    <path d="M60.7496 53.0796H64.312V43.6682H60.7496V53.0796Z" />
  </IconBox>
);
