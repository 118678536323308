import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  injectToken,
  mapToRegisterStep2DTO,
  RegisterStep2FormModel,
} from 'src/common/models/auth';
import { Seo } from 'src/public/components/Seo';
import { CenteredLayout } from 'src/public/components/CenteredLayout';
import { Title } from 'src/common/components/typography/Title';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { TextControl } from 'src/common/components/TextControl';
import { FormItem } from 'src/common/components/FormItem';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { Button } from 'src/common/components/Button';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import styled from 'styled-components';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import {
  registerActions,
  registerStep2Actions,
} from 'src/state/auth/authActions';
import { useRequest } from 'src/lib/useRequest';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { AppRoute } from 'src/app/routes';
import { pipe } from 'fp-ts/lib/pipeable';
import {
  RegistrationHeader,
  RegistrationHeaderSteps,
} from 'src/public/components/auth/RegistrationHeader';
import { Tooltip } from 'src/common/components/Tooltip';
import {
  LoginRoute,
  PrivacyPolicyAndCookiesRoute,
} from 'src/public/publicRoutes';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { typography } from 'src/config/typography';
import { useLocation } from '@reach/router';
import { getErrorMessages } from 'src/common/services/axios';
import { validateToken } from 'src/common/services/user';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { colors } from 'src/config/colors';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { registerBaseValidationSchema } from 'src/lib/validations';
import { PasswordValidationFieldDescription } from 'src/common/components/PasswordValidationFieldDescription';

const initialValues: RegisterStep2FormModel = {
  firstName: '',
  email: '',
  emailConfirm: '',
  password: '',
  newsletterAgreement: false,
};

const InfoParagraph = styled(Paragraph)`
  margin-bottom: 1.375rem;
  margin-top: 0;
`;

const HighlightedParagraph = styled.p`
  color: ${colors.red};
`;

const Main = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCenteredLayout = styled(CenteredLayout)`
  align-items: center;
`;

const TooltipButton = styled(Tooltip)`
  & .tooltip {
    box-shadow: 0 0 1rem 0.25rem rgba(0, 0, 0, 0.3);
    margin: 0 2.25rem !important;
    max-width: 17.5rem;
  }
`;

const TooltipText = styled.div`
  font-family: ${typography.fontFamilyDefault};
  font-weight: 400;
`;

const RegisterPageStep2: FC = () => {
  useStorePageUrl();

  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading } = useRequest(registerActions.request);
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    const token = searchParams.get('token') ?? undefined;

    if (isLoggedIn && !token) {
      navigate(AppRoute);
    }

    validateToken({ token })
      .then(() => {
        setIsTokenValid(true);
      })
      .catch((error) => {
        getErrorMessages(error).map((message) =>
          dispatch(
            snackbarActions.enqueueSnackbar({
              message,
              options: { variant: 'error' },
            })
          )
        );
        navigate(LoginRoute);
      });
  }, []);

  if (!isTokenValid) {
    return <></>;
  }

  return (
    <StyledCenteredLayout>
      <Main>
        <HomeLogoLink />

        <Seo title="Rejestracja" />

        <RegistrationHeader active={RegistrationHeaderSteps.Step2} />

        <Title level={1} variant="condensed">
          Ustaw dane do logowania
        </Title>

        <FlexScrollContainer>
          <section>
            <InfoParagraph size="small">
              Do logowania wykorzystaj swój prywatny adres e-mail. Będzie on
              Twoim loginem do konta w&nbsp;Car&nbsp;Platform.
            </InfoParagraph>

            <Formik
              initialValues={initialValues}
              validationSchema={registerBaseValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                pipe(
                  values,
                  injectToken(searchParams.get('token') || undefined),
                  mapToRegisterStep2DTO,
                  registerStep2Actions.request,
                  dispatch
                );
                setSubmitting(false);
              }}
            >
              <Form>
                <FormItem name="firstName">
                  <TextControl type="text" name="firstName" label="Imię" />
                </FormItem>
                <FormItem name="email">
                  <TextControl
                    type="email"
                    name="email"
                    label="Twój prywatny adres e-mail"
                  />
                </FormItem>
                <FormItem name="emailConfirm">
                  <TextControl
                    type="email"
                    name="emailConfirm"
                    label="Powtórz prywatny adres e-mail"
                  />
                </FormItem>
                <FormItem name="password">
                  <TextControl type="password" name="password" label="Hasło" />
                  <PasswordValidationFieldDescription />
                </FormItem>

                <Paragraph size="small">
                  Udzielenie poniższej zgody jest dobrowolne. Możesz ją wycofać
                  w&nbsp;dowolnym momencie, kontaktując się z&nbsp;nami.
                  Wycofanie zgody nie wpłynie na zgodność z&nbsp;prawem
                  przetwarzania dokonanym na podstawie zgody przed jej
                  wycofaniem.
                </Paragraph>

                <FormItem name="newsletterAgreement">
                  <CheckboxControl
                    name="newsletterAgreement"
                    label={
                      <TooltipButton
                        arrow
                        interactive
                        placement="right-end"
                        title={
                          <TooltipText>
                            Jeśli udzielisz nam zgody, Twoje dane zostaną użyte
                            przez Mobility Benefit w&nbsp;celu zaprezentowania
                            Ci informacji o&nbsp;oferowanych usługach,
                            produktach oraz organizowanych promocjach
                            w&nbsp;oparciu o&nbsp;Twoje preferencje
                            i&nbsp;zainteresowania. Masz prawo dostępu do danych
                            osobowych, ich sprostowania lub usunięcia,
                            ograniczenia przetwarzania lub zgłoszenia sprzeciwu
                            wobec przetwarzania, a&nbsp;także przeniesienia
                            danych. Więcej informacji o&nbsp;przetwarzaniu
                            Twoich danych znajdziesz w naszej{' '}
                            <SimpleLink
                              as={Link}
                              to={PrivacyPolicyAndCookiesRoute}
                            >
                              Polityce prywatności
                            </SimpleLink>
                            .
                          </TooltipText>
                        }
                      >
                        <div>
                          <Text size="small">
                            <p style={{ marginTop: 0 }}>
                              Chcę otrzymywać od Mobility Benefit sp.
                              z&nbsp;o.o. informacje tekstowe o&nbsp;usługach
                              i&nbsp;produktach prezentowanych na stronie
                              internetowej Mobility Benefit sp. z&nbsp;o.o. oraz
                              organizowanych promocjach. Informacje te
                              przesyłane będą środkami komunikacji
                              elektronicznej, w&nbsp;szczególności poprzez
                              e-mail, sms, mms, komunikaty wyświetlane na
                              stronie internetowej.
                            </p>
                            <HighlightedParagraph>
                              Niezaznaczenie tej zgody nie pozwoli nam
                              poinformować o&nbsp;tym, że do Platformy zostały
                              dodane atrakcyjne oferty, z&nbsp;którymi warto się
                              zapoznać.
                            </HighlightedParagraph>
                            <p>
                              Zespół Car Platform deklaruje, że nie będzie
                              nadużywał zaufania użytkowników, którzy
                              zdecydowali się udzielić tej zgody i&nbsp;nie
                              będzie zbyt często wysyłał wiadomości
                              marketingowych.
                            </p>
                          </Text>
                        </div>
                      </TooltipButton>
                    }
                  />
                </FormItem>

                <FormActionsContainer
                  style={{ margin: '2vh 0' }}
                  justifyContent="flex-end"
                >
                  <Button
                    kind="skew"
                    size="small"
                    type="submit"
                    icon={ArrowRight}
                    disabled={isLoading}
                  >
                    WEJDŹ DO PLATFORMY
                  </Button>
                </FormActionsContainer>
              </Form>
            </Formik>
          </section>
        </FlexScrollContainer>
      </Main>
    </StyledCenteredLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegisterPageStep2;
