import React, { FC, useEffect, useMemo, useState } from 'react';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import RegisterAdvantage1 from 'src/assets/images/register-step2-advantage-1.svg';
import RegisterAdvantage2 from 'src/assets/images/register-step2-advantage-2.svg';
import RegisterAdvantage3 from 'src/assets/images/register-step2-advantage-3.svg';
import RegisterAdvantage4 from 'src/assets/images/register-step2-advantage-4.svg';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import Envelope from 'src/assets/icons/Envelope';
import { SimpleLink } from 'src/common/components/SimpleLink';
import Phone from 'src/assets/icons/Phone';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { CleanUnorderedList } from 'src/common/components/List';
import { useLocation } from '@reach/router';
import { HomePageRoute } from 'src/app/routes';
import { Button } from 'src/common/components/Button';
import RegisterStep2Car from 'src/assets/images/register-step2-car.png';
import { Logo } from 'src/common/components/Logo';
import Close from 'src/assets/icons/Close';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  registerByCodeTokenSelector,
} from 'src/state/auth/authSelectors';
import { RegisterStep2SuccessState } from 'src/common/models/auth';
import { Link, navigate } from 'gatsby';
import { LoginRoute } from 'src/public/publicRoutes';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { validateToken } from 'src/common/services/user';
import { getErrorMessages } from 'src/common/services/axios';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { isSome } from 'fp-ts/lib/Option';
import { authActions } from 'src/state/auth/authActions';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const FullPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledScrollContainer = styled(ScrollContainer)`
  height: 100%;
`;

const Container = styled(ConstraintContainer)`
  padding: 3vh 3vh 2rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Navbar = styled.header`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: block;

  ${media.w.min.px768(css`
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    margin-bottom: 1.5rem;
    max-width: 56rem;
  `)}
`;

const HeaderText = styled(Text)`
  font-weight: 700;
`;

const CarImage = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 2rem;

  ${media.w.min.px576(css`
    display: block;
    max-width: 25.5rem;
    min-width: 25.5rem;
  `)}
  ${media.w.min.px768(css`
    padding-top: 0;
  `)}
  & > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const CarImageBackground = styled.div`
  background: linear-gradient(
    144.89deg,
    #e0e3e8 13.21%,
    rgba(224, 227, 232, 0) 45.29%
  );
  mix-blend-mode: normal;
  opacity: 0.2;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 23.4375rem;
  height: 100%;
  max-height: 15.625rem;
  position: absolute;
  left: 0;
  top: 60%;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%);

  ${media.w.min.px768(css`
    top: 25%;
    left: 40%;
    width: 53.8125rem;
  `)};
`;

const Advantages = styled(CleanUnorderedList)`
  display: grid;
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.w.min.px576(css`
    grid-template-columns: 1fr 1fr;
  `)}

  ${media.w.min.px1200(css`
    grid-auto-flow: column;
    grid-template-columns: 15rem 15rem 15rem 15rem;
    max-width: 1366px;
  `)};

  & > li {
    display: flex;
    align-items: center;
    height: 9.4375rem;

    ${media.w.max.px576(css`
      &:nth-child(even) {
        justify-content: flex-end;
        width: 21.25rem;
        max-width: 100%;
      }
    `)};
  }
`;

const AdvantageIcon = styled.div`
  height: 6.625rem;
  width: 6.625rem;
  flex-shrink: 0;
  position: relative;

  & > svg {
    max-height: 100%;
  }
`;

const AdvantageLabel = styled(Text).attrs({ variant: 'condensed' })`
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 1rem;

  position: relative;
  font-weight: 700;
  margin-top: 4rem;
  margin-left: -2rem;

  ${media.w.min.px576(css`
    font-size: 1.125rem;
  `)};
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  max-width: 25rem;
`;

const TextContainer = styled.div`
  margin: 1rem 0;
  width: 100%;
  ${media.w.min.px1200(css`
    width: 56rem;
  `)};
`;

const ContactContainer = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin-bottom: 2.25rem;

  ${media.w.min.px576(css`
    grid-template-columns: max-content max-content;
    grid-column-gap: 1.25rem;
    margin-bottom: 0;
  `)}
`;

const RegisterPageStep2Success: FC = () => {
  useStorePageUrl();

  const dispatch = useDispatch();
  const location = useLocation();
  const registerByCodeToken = useSelector(registerByCodeTokenSelector);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    if (isSome(registerByCodeToken)) {
      dispatch(authActions.updateToken(registerByCodeToken));
    }
  }, []);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const navigateHomePageTimeouted = () => {
    const navigationTimeout = setTimeout(() => {
      navigate(HomePageRoute);
    }, 8000);

    return () => {
      clearTimeout(navigationTimeout);
    };
  };

  const isLoggedIn = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    const registrationByCodeToken = searchParams.get('token') ?? undefined;
    const registrationCompanyToken = (location.state as RegisterStep2SuccessState)
      ?.token;

    if (registrationByCodeToken) {
      pushEvent(EventName.SignUp, {
        method: 'email',
      });

      validateToken({ token: registrationByCodeToken })
        .then(() => {
          setIsTokenValid(true);
          navigateHomePageTimeouted();
        })
        .catch((error) => {
          getErrorMessages(error).map((message) =>
            dispatch(
              snackbarActions.enqueueSnackbar({
                message,
                options: { variant: 'error' },
              })
            )
          );
          navigate(LoginRoute);
        });
    } else if (registrationCompanyToken) {
      setIsTokenValid(true);
      pushEvent(EventName.SignUp, {
        method: 'email',
      });
      navigateHomePageTimeouted();
    } else {
      isLoggedIn ? navigate(HomePageRoute) : navigate(LoginRoute);
    }
  }, []);

  if (!isTokenValid) {
    return <></>;
  }

  return (
    <FullPageLayout>
      <Seo title="Dziękujemy za rejestrację!" />

      <StyledScrollContainer>
        <Container>
          <Navbar>
            <Link to={HomePageRoute}>
              <Logo variant={'light'} />
            </Link>
            <Button
              kind="box"
              variant="transparent"
              icon={Close}
              as={Link}
              to={HomePageRoute}
            >
              zamknij
            </Button>
          </Navbar>
          <Header>
            <div>
              <Title level={1}>Dziękujemy za rejestrację!</Title>
              <HeaderText>
                Od teraz możesz przeglądać oferty i wybierać spośród ponad 30
                marek dostępnych na naszej platformie. Skorzystaj z pomocy
                doradcy i zamów samochód w dogodnej dla siebie formie
                finansowania.
              </HeaderText>
            </div>
            <CarImage>
              <img src={RegisterStep2Car} alt="Twój potencjalny samochód" />
            </CarImage>
            <CarImageBackground />
          </Header>
          <Advantages>
            <li>
              <AdvantageIcon>
                <RegisterAdvantage1 />
              </AdvantageIcon>
              <AdvantageLabel>
                Atrakcyjne
                <br />
                oferty
              </AdvantageLabel>
            </li>
            <li>
              <AdvantageIcon>
                <RegisterAdvantage2 />
              </AdvantageIcon>
              <AdvantageLabel>
                Szeroki
                <br />
                wybór marek
              </AdvantageLabel>
            </li>
            <li>
              <AdvantageIcon>
                <RegisterAdvantage3 />
              </AdvantageIcon>
              <AdvantageLabel>
                Różne formy
                <br />
                finansowania
              </AdvantageLabel>
            </li>
            <li>
              <AdvantageIcon>
                <RegisterAdvantage4 />
              </AdvantageIcon>
              <AdvantageLabel>
                Kompleksowa
                <br />
                opieka doradcy
              </AdvantageLabel>
            </li>
          </Advantages>
          <TextContainer>
            <StyledParagraph>
              Masz pytania? Zapoznaj się z najczęściej zadawanymi pytaniami
              (FAQ) lub skontaktuj się z naszym zespołem.
            </StyledParagraph>
            <StyledParagraph>
              Pozdrawiamy, <b>Team Car Platform</b>
            </StyledParagraph>
          </TextContainer>
          <TextContainer>
            <ContactContainer>
              <Text>
                <Envelope size={16} style={{ marginRight: '0.625rem' }} />
                <SimpleLink href="mailto:hello@carplatform.pl">
                  hello@carplatform.pl
                </SimpleLink>
              </Text>
              <Text>
                <Phone size={16} style={{ marginRight: '0.625rem' }} />
                <SimpleLink href="tel:+48 720 811 206">
                  +48 720 811 206
                </SimpleLink>
              </Text>
            </ContactContainer>
          </TextContainer>
          <div>
            <Button kind="box" variant="secondary" as={Link} to={HomePageRoute}>
              Wróć do Car&nbsp;Platform
            </Button>
          </div>
        </Container>
      </StyledScrollContainer>
    </FullPageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegisterPageStep2Success;
