import { createRootRoute, createRoute } from '@summer/react-kit';

export const AppOverlayId = 'app-overlay';

export const AppRoute = createRootRoute('app');

export const HomePageRoute = createRoute(AppRoute);

export const ProductPageRoute = createRoute(AppRoute, 'product');
export const ProductItemPageRoute = createRoute(ProductPageRoute, ':productId');
export const ProductOrderPageRoute = createRoute(ProductItemPageRoute, 'order');
export const ProductOrderItemPageRoute = createRoute(
  ProductOrderPageRoute,
  ':offerId'
);
export const ProductOrderThankYouPageRoute = createRoute(
  ProductOrderPageRoute,
  ':offerId',
  'thank-you'
);

export const SearchPageRoute = createRoute(AppRoute, 'search');
export const AccountPageRoute = createRoute(AppRoute, 'account');
