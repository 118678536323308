import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { SettingsState } from 'src/state/settings/settingsReducer';

export const settingsStateSelector = (state: RootState) => state.settings;

export const productsPreferenceSelector = createSelector(
  settingsStateSelector,
  (state: SettingsState) => state.productsPreference
);
