import { object, ref, string } from 'yup';

export const emailValidationSchema = object().shape({
  email: string().required('Pole wymagane').email('Podaj poprawny adres email'),
});

export const registerBaseValidationSchema = object().shape({
  firstName: string().required('Pole wymagane'),
  email: string().required('Pole wymagane').email('Podaj poprawny adres email'),
  emailConfirm: string()
    .required('Pole wymagane')
    .oneOf([ref('email'), ''], 'Adresy email muszą być takie same'),
  password: string()
    .required('Pole wymagane')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Hasło musi mieć co najmniej 8 znaków, w tym co najmniej jedna wielka litera, jedna mała litera, jedna cyfra i jeden znak specjalny (@$!%*#?&)'
    ),
});
