import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 60.8C47.9058 60.8 60.8 47.9058 60.8 32C60.8 16.0942 47.9058 3.2 32 3.2C16.0942 3.2 3.2 16.0942 3.2 32C3.2 47.9058 16.0942 60.8 32 60.8ZM32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 32C59 46.9117 46.9117 59 32 59C17.0883 59 5 46.9117 5 32C5 17.0883 17.0883 5 32 5C46.9117 5 59 17.0883 59 32ZM29.9982 34.1258C29.9982 37.607 29.3943 40.1937 28.1863 41.8861C26.9784 43.5785 25.0903 44.4247 22.5221 44.4247C19.9866 44.4247 18.1094 43.5999 16.8906 41.9504C15.6718 40.2901 15.0406 37.8158 14.9971 34.5275V30.559C14.9971 27.1207 15.601 24.566 16.809 22.8951C18.0169 21.2241 19.9104 20.3887 22.4895 20.3887C25.0468 20.3887 26.9294 21.1974 28.1374 22.8148C29.3453 24.4214 29.9656 26.9065 29.9982 30.2698V34.1258ZM26.9784 30.0609C26.9784 27.5438 26.6193 25.7122 25.9011 24.566C25.1828 23.4092 24.0457 22.8308 22.4895 22.8308C20.9442 22.8308 19.8179 23.4039 19.1106 24.55C18.4032 25.6961 18.0387 27.4581 18.0169 29.836V34.5918C18.0169 37.1196 18.3869 38.9887 19.1269 40.1991C19.8778 41.3987 21.0095 41.9986 22.5221 41.9986C24.013 41.9986 25.1176 41.4309 25.8358 40.2955C26.5649 39.1601 26.9458 37.3713 26.9784 34.9292V30.0609ZM47.9971 30.9482L46.5805 31.5042V25.4248H44.9129V32.1514L43.6219 32.6527V33.7738L44.9129 33.2725V39.4248H46.5805V32.6253L47.9971 32.0693V30.9482ZM42.6985 38.0485H37.0233L42.4295 30.7568V29.5628H35.0778V30.9574H40.3495L34.9971 38.1852V39.4248H42.6985V38.0485Z"
    />
  </IconBox>
);
