import {
  all,
  call,
  debounce,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { apiFormattedSearchFiltersSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { searchProducts } from 'src/app/search/services/product';
import { newProductOffersActionsModifyingFilters } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { customerTypeActions } from 'src/app/state/customerType/customerTypeActions';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { CustomerType } from 'src/common/models/customerType';

function* processFindProducts() {
  const customerType: CustomerType = yield select(customerTypeSelector);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME
  const selectedFilters = yield select(apiFormattedSearchFiltersSelector);
  selectedFilters['customerType'] = customerType;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const { results, total } = yield call(searchProducts, selectedFilters);

    yield put(
      findProductsRequestActions.requestSuccess({ products: results, total })
    );
  } catch (error) {
    yield put(findProductsRequestActions.requestFailure(error));
  }
}

function* findProductsWatcher() {
  yield debounce(1000, findProductsRequestActions.request.type, function* () {
    yield race({
      task: processFindProducts(),
      cancel: take(findProductsRequestActions.request.type),
    });
  });
}

function* spawnFindProducts() {
  yield put(findProductsRequestActions.request());
}

function* synchronizeProductsWatcher() {
  yield takeEvery(newProductOffersActionsModifyingFilters, spawnFindProducts);
}

function* setUserCustomerTypeWatcher() {
  yield takeEvery(customerTypeActions.setCustomerType, function* () {
    if (location.pathname.includes('/app/search')) {
      yield spawnFindProducts();
    }
  });
}

export function* searchProductsSaga() {
  yield all([
    findProductsWatcher(),
    synchronizeProductsWatcher(),
    setUserCustomerTypeWatcher(),
  ]);
}
