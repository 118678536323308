import React, { FC, memo, useMemo } from 'react';
import {
  ExoticFilter,
  FilterStateKindMap,
  ModelFilter,
  OptionFilter,
} from 'src/app/common/models/filter';
import { useSelector } from 'react-redux';
import {
  bodiesListSelector,
  brandsListSelector,
  categoriesListSelector,
} from 'src/app/state/commonFilters/commonFiltersSelectors';
import { FilterStatusItem } from 'src/app/search/components/FilterStatusInfo/FilterStatusItem';
import { prune } from '@summer/react-kit/functions';
import { FilterStatusSpan } from 'src/app/search/components/FilterStatusInfo/FilterStatusSpan';
import { toPairs } from 'ramda';
import styled from 'styled-components';
import { StyleProps } from '@summer/react-kit';
import { FilterStatusList } from 'src/app/search/components/FilterStatusInfo/FilterStatusList';

const SingleBrandFilterStatusPrinter: FC<{
  state: {
    [OptionFilter.Brand]: string;
    [ExoticFilter.Model]: string[];
  };
  printModels: boolean;
}> = ({ state, printModels }) => {
  const brandsMetadata = useSelector(brandsListSelector);
  const brand = brandsMetadata?.find((b) => b.id === state[OptionFilter.Brand]);

  const models = printModels
    ? prune(
        state[ExoticFilter.Model].map(
          (modelId) => brand?.models?.find((m) => m.id === modelId)?.label
        )
      ).join(', ')
    : '';

  return brandsMetadata ? (
    <FilterStatusItem>
      <FilterStatusSpan>
        {brand?.label} {models}
      </FilterStatusSpan>
    </FilterStatusItem>
  ) : null;
};

const Separator = styled.span`
  margin-right: 0.25rem;
  line-height: 1.5;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
`;

const BrandModelFilterStatusPrinter: FC<{
  [OptionFilter.Brand]?: FilterStateKindMap[OptionFilter.Brand];
  [ExoticFilter.Model]?: FilterStateKindMap[ExoticFilter.Model];
}> = ({ brand, model }) => {
  const brandsMetadata = useSelector(brandsListSelector);
  const brandOrderLookup = useMemo(
    () =>
      brandsMetadata?.reduce((lookup, x, index) => {
        lookup[x.id] = index;
        return lookup;
      }, {} as { [a: string]: number }) ?? {},
    [brandsMetadata]
  );

  const { selectedBrandModels, selectedModels } = useMemo(() => {
    const brandsFilter = brand?.value ?? [];
    const modelsFilter = model?.value ?? {};

    const selectedBrandModels = brandsFilter
      ?.filter((brandId) => !modelsFilter[brandId])
      .reduce((acc, brandId) => {
        const brand = brandsMetadata?.find((b) => brandId === b.id);
        if (brand) {
          acc[brand.id] = brand.models.map((m) => m.label); //[]; // If whole brand is selected, we don't want to show models
        }
        return acc;
      }, {} as ModelFilter);

    const selectedModels = toPairs({
      ...modelsFilter,
      ...selectedBrandModels,
    }).sort((a, b) => brandOrderLookup[a[0]] - brandOrderLookup[b[0]]); // without sort order might change

    return { selectedBrandModels, selectedModels };
  }, [brandsMetadata, brand, model]);

  if (selectedModels.length > 0) {
    return (
      <>
        {selectedModels.map(([brand, models], index) => (
          <span
            style={{ display: 'inline-flex', alignItems: 'center' }}
            key={brand}
          >
            <SingleBrandFilterStatusPrinter
              state={{
                [OptionFilter.Brand]: brand,
                [ExoticFilter.Model]: models,
              }}
              printModels={!selectedBrandModels.hasOwnProperty(brand)}
            />
            {selectedModels.length - 1 > index && <Separator>{','}</Separator>}
          </span>
        ))}
      </>
    );
  }

  return null;
};

const CategoryBodyFiltersStatusPrinter: FC<{
  [OptionFilter.Body]?: FilterStateKindMap[OptionFilter.Body];
  [OptionFilter.Category]?: FilterStateKindMap[OptionFilter.Body];
}> = memo(({ body, category }) => {
  const categories = useSelector(categoriesListSelector);
  const bodies = useSelector(bodiesListSelector);

  const categoryFilter = (category?.value ?? []).map(
    (a) => categories?.find((b) => b.id === a)?.label
  );
  const bodyFilter = (body?.value ?? []).map(
    (a) => bodies?.find((b) => b.id === a)?.label
  );

  return (
    <FilterStatusItem style={{ display: 'inline-flex' }}>
      <FilterStatusSpan>
        {[...categoryFilter, ...bodyFilter].join(', ')}
      </FilterStatusSpan>
    </FilterStatusItem>
  );
});

const StyledFilterStatusList = styled(FilterStatusList)`
  min-width: 4.5rem;
  padding-right: 0.25rem;
  max-width: 15.75rem;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  white-space: nowrap;
`;

const Container = styled.div``;

type GeneralGroupFiltersKind =
  | OptionFilter.Body
  | OptionFilter.Brand
  | OptionFilter.Category
  | ExoticFilter.Model;

export interface GeneralFilterStatusListProps extends StyleProps {
  filters: {
    [P in GeneralGroupFiltersKind]?: FilterStateKindMap[P];
  };
  length: number;
}

export const GeneralFilterStatusList: FC<GeneralFilterStatusListProps> = memo(
  ({ filters, className }) => {
    return (
      <Container className={className}>
        <StyledFilterStatusList>
          <BrandModelFilterStatusPrinter
            brand={filters.brand}
            model={filters.model}
          />
          {filters && (filters.category || filters.body) && filters.brand && (
            <Separator>{','}</Separator>
          )}
          <CategoryBodyFiltersStatusPrinter
            category={filters.category}
            body={filters.body}
          />
        </StyledFilterStatusList>
      </Container>
    );
  }
);
