import React, { FC, useMemo } from 'react';
import {
  FilterGroupPanel,
  filtersByPanel,
  groupPanelToLabelMap,
} from 'src/app/search/models/filterGroupPanel';
import { groupPanelToOpenActionMap } from 'src/app/search/state/searchPanel/searchPanelActions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'src/common/components/Button';
import { FilterStatusInfo } from 'src/app/search/components/FilterStatusInfo';
import { FilterStatusSpan } from 'src/app/search/components/FilterStatusInfo/FilterStatusSpan';
import { createSearchFilterStateByKindsSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { FilterKind, FilterStateKindMap } from 'src/app/common/models/filter';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { SearchState } from 'src/app/search/state';
import pickBy from 'lodash/fp/pickBy';
import { isFilterPristine } from 'src/app/common/models/filterConfig';
import { isEmpty } from 'ramda';
import ChevronRight from 'src/assets/icons/ChevronRight';
import { colors } from 'src/config/colors';

const FilterButton = styled(Button).attrs({
  kind: 'box',
  variant: 'transparent',
})`
  background: rgba(255, 255, 255, 0.1);
  height: 3.125rem;

  & > .btn__content {
    text-align: left;
    height: 100%;
  }

  &.btn-box.btn-default > .btn__content,
  .btn-box-inverted.btn-default > .btn__content {
    padding: 0 0.75rem;
  }
`;

const FilterButtonTitle = styled.span`
  font-weight: bold;
  display: flex;
  flex: 1;
  align-items: center;
  text-transform: uppercase;
`;

const StyledFilterStatusInfo = styled(FilterStatusInfo)`
  text-transform: none;
  margin-top: 0.125rem;

  ${FilterStatusSpan} {
    font-size: 0.75rem;
  }
`;

export interface CrossroadFilterPanelRouteProps {
  group: FilterGroupPanel;
}

export const CrossroadFilterPanelRoute: FC<CrossroadFilterPanelRouteProps> = ({
  group,
}) => {
  const dispatch = useDispatch();

  const filterSelector = useMemo(
    () => createSearchFilterStateByKindsSelector(),
    []
  );
  const filters: {
    [P in FilterKind]?: FilterStateKindMap[P];
  } = useSelectorTransform(
    (state: SearchState) => filterSelector(state, filtersByPanel[group]),
    pickBy(
      (state: FilterStateKindMap[FilterKind], key) =>
        !isFilterPristine(key as FilterKind, state.value)
    )
  );

  return (
    <FilterButton
      onClick={() => dispatch(groupPanelToOpenActionMap[group]())}
      icon={ChevronRight}
      iconProps={{ size: 14, color: colors.grayBasic }}
    >
      <div>
        <FilterButtonTitle>{groupPanelToLabelMap[group]}</FilterButtonTitle>
        {!isEmpty(filters) && (
          <StyledFilterStatusInfo group={group} length={2} />
        )}
      </div>
    </FilterButton>
  );
};
