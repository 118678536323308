import React from 'react';
import { OfferInformationIcon } from 'src/app/search/components/OfferInformationIcon';
import { IconCode } from 'src/common/components/Icon';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

const Container = styled.div`
  display: grid;

  grid-template-rows: min-content min-content;
  grid-template-columns: min-content min-content;
  justify-content: space-around;

  grid-row-gap: 3.875rem;

  ${media.w.min.px576(css`
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-template-rows: unset;
    grid-template-columns: unset;
  `)}
`;
export const UsedProductOfferIconography = () => {
  return (
    <Container>
      <OfferInformationIcon
        iconCode={IconCode.Warranty}
        label="Auta z gwarancją dealera"
      />
      <OfferInformationIcon
        iconCode={IconCode.Garage}
        label={
          <>
            Tylko <br /> maksymalnie 5-letnie auta
          </>
        }
      />
      <OfferInformationIcon
        iconCode={IconCode.CarHealth}
        label={
          <>
            Średni przebieg <br /> między 60 a 120 tys km
          </>
        }
      />
      <OfferInformationIcon
        iconCode={IconCode.CheckList}
        label="Dostępne w leasingu lub kredycie"
      />
    </Container>
  );
};
