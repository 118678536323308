import { createAction } from 'src/lib/createAction';

export const cookiesActions = {
  accept: createAction('cookies/accept')(),
  acceptAdvertInternal: createAction('cookies/acceptAdvertInternal')(),
  declineAdvertInternal: createAction('cookies/declineAdvertInternal')(),
  acceptAdvertExternal: createAction('cookies/acceptAdvertExternal')(),
  declineAdvertExternal: createAction('cookies/advertExternal')(),
  acceptAnalytic: createAction('cookies/acceptAnalytic')(),
  declineAnalytic: createAction('cookies/declineAnalytic')(),
  decline: createAction('cookies/decline')(),
} as const;
