import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { RangeSlider } from 'src/app/common/components/RangeSlider';
import { Icon } from 'src/common/components/Icon';
import { Flex } from '@summer/react-kit';
import { Label } from 'src/app/common/components/typography/Label';
import { rem } from 'polished';
import { Button } from 'src/common/components/Button';
import { StyleProps } from '@summer/react-kit';
import { media } from 'src/config/breakpoints';
import Bin from 'src/assets/icons/Bin';

export const SearchFilterSliderContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  ${media.w.min.px992(css`
    padding: 0.5rem;
  `)};
`;

export const SearchFilterSlider = styled(RangeSlider)<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
  position: relative;
`;

export const SearchFilterSliderPrefixIcon = styled(Icon).attrs({ size: 24 })``;

const SuffixSub = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 1.5;
  opacity: 0.7;
`;

const StyledFlex = styled(Flex)`
  min-width: 4.75rem;
  position: relative;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: -1.5rem;
  top: -0.5rem;

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

export interface SearchFilterSliderSuffixProps extends StyleProps {
  name?: string;
  sub?: string;
  onClear?: () => void;
}

export const SearchFilterSliderPrefix = memo<SearchFilterSliderSuffixProps>(
  ({ name, sub, onClear, style, className }) => (
    <StyledFlex alignCenter className={className} style={style}>
      <Flex column>
        <Label style={{ margin: 0 }}>{name}</Label>
        <SuffixSub>{sub}</SuffixSub>
      </Flex>
      {onClear && (
        <StyledButton
          kind="box"
          variant="transparent"
          size="small"
          style={{ marginLeft: rem(8), zIndex: 5 }}
          icon={Bin}
          iconProps={{ size: 13 }}
          onClick={onClear}
        />
      )}
    </StyledFlex>
  )
);
