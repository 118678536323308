import React from 'react';
import { Button } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { RequestProductSectionTitle } from 'src/app/search/components/RequestProductSection/RequestProductSectionTitle';
import { RequestProductSectionParagraph } from 'src/app/search/components/RequestProductSection/RequestProductSectionParagraph';
import { media } from 'src/config/breakpoints';

const Content = styled.div`
  color: #d4d6dc;
`;

const ButtonContainer = styled.div`
  margin-top: 0.25rem;
  padding-bottom: 0.1875rem;
  display: flex;
  justify-content: center;

  ${media.w.min.px768(css`
    transform: translateX(-1.4rem);
    justify-content: flex-start;
  `)}
`;

export const RequestProductSectionFormSummary = () => {
  return (
    <Content>
      <RequestProductSectionTitle>
        Dziękujemy, Twoja wiadomość została wysłana.
      </RequestProductSectionTitle>
      <RequestProductSectionParagraph>
        Nasz doradca skontaktuje się z Tobą aby pomóc w doborze samochodu, który
        spełnia Twoje potrzeby.
      </RequestProductSectionParagraph>

      <ButtonContainer>
        <Button kind="skew" type="submit" icon={ArrowRight}>
          Poznaj inne oferty
        </Button>
      </ButtonContainer>
    </Content>
  );
};
