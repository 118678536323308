import React, { FC } from 'react';
import styled, { css, ThemeProps } from 'styled-components';
import LogoSvg from 'src/assets/images/logo.svg';
import { LightDarkVariant } from 'src/common/models/lightDarkVariant';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';

export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: LightDarkVariant;
}

const StyledLogo = styled(LogoSvg)<ThemeProps<any> & LogoProps>`
  height: 2.25rem;

  ${media.w.max.px768(css`
    width: 9.9375rem;
  `)};

  #typo {
    fill: ${(props) =>
      props.variant === 'dark' || props.theme.variant === 'dark'
        ? colors.background
        : colors.white};
  }

  #symbol-bottom {
    fill: ${(props) =>
      props.variant === 'dark' || props.theme.variant === 'dark'
        ? colors.grayBasic
        : colors.white};
  }
`;

export const Logo: FC<LogoProps> = (props) => <StyledLogo {...props} />;
