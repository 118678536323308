import React, { memo, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { Button, CleanButton } from 'src/common/components/Button';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { StyleProps } from '@summer/react-kit';
import { SearchHeaderOptions } from 'src/app/search/components/SearchHeader/SearchHeaderOptions';
import { useDispatch, useSelector } from 'react-redux';
import { searchProductsResultsTotalSelector } from 'src/app/search/state/searchProducts/searchProductsSelectors';
import { countLabelFormatter } from '@summer/react-kit/functions';
import { NewUsedProductsPreferenceSwitch } from 'src/common/components/NewUsedProductsPreferenceSwitch';
import { activeSearchPanelSelector } from 'src/app/search/state/searchPanel/searchPanelSelectors';
import { searchPanelActions } from 'src/app/search/state/searchPanel/searchPanelActions';
import { useMediaQuery } from 'react-responsive';
import { AppHeader } from 'src/common/components/AppHeader';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import Close from 'src/assets/icons/Close';
import { searchFiltersStateSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { allFiltersPristine } from 'src/app/common/models/filterConfig';
import { useRequest } from 'src/lib/useRequest';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { searchUsedProductsResultsTotalSelector } from 'src/app/search/state/searchProducts/searchUsedProductsSelectors';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { Link, navigate } from 'gatsby';
import { HomePageRoute } from 'src/app/routes';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { LoginRoute } from 'src/public/publicRoutes';
import { rgba } from 'polished';
import { CarPlatformBusinessBenefitsModal } from 'src/common/components/CarPlatformBusinessBenefitsModal';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { typography } from 'src/config/typography';
import { GeneralSearchAutocomplete } from 'src/app/search/components/SearchHeader/GeneralSearchAutocomplete';
import { CarsMotorcyclesSwitch } from 'src/common/components/CarsMotorcyclesSwitch';

const Container = styled.header`
  width: 100%;
  color: ${colors.grayBasic};
  display: flex;
  flex-direction: column;
  padding: 1.125rem;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1;

  ${media.w.min.px992(css`
    z-index: 2;
    padding: 2.5rem 3vh 0.75rem;
    padding-bottom: 0.5rem;
    background-color: ${colors.white};
  `)};

  ${media.w.min.px1200(css`
    padding: 1rem 3vh;
    padding-bottom: 0.5rem;
  `)};
`;

const Gradient = styled.div`
  width: 100%;
  height: 7.375rem;
  left: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  background: radial-gradient(
    44.79% 79.17% at 50% 100%,
    #0081ff 0%,
    rgba(0, 129, 255, 0) 100%
  );
  opacity: 0.4;
  transform: matrix(1, 0, 0, -1, 0, 0);

  ${media.w.min.px992(css`
    height: 70%;
    bottom: 0;
    top: initial;
    background: linear-gradient(
      180deg,
      #93969c 0%,
      rgba(255, 255, 255, 0) 88.85%
    );
    opacity: 0.8;
    transform: matrix(1, 0, 0, -1, 0, 0);
  `)};
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${colors.background};
  display: flex;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 0.625rem 0;
  font-size: 1.375rem;
  opacity: 0.8;

  ${media.w.min.px768(css`
    font-size: 1.875rem;
  `)}

  ${media.w.min.px992(css`
    font-size: 1.75rem;
    margin-top: 1rem;
  `)}

  ${media.w.max.px992(css`
    display: none;
  `)}
`;

const NewUsedProductsPreferenceSwitchWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 2.25rem;

  ${media.w.max.px768(css`
    display: none;
  `)}
`;

const offerCountLabelFormatter = countLabelFormatter(
  '0 ofert',
  '1 ofertę',
  (count) => `${count} oferty`,
  (count) => `${count} ofert`
);

const HeaderChildrenWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const HeaderContent = styled.div`
  flex: 1;
  display: flex;

  ${media.w.max.px768(css`
    justify-content: flex-end;
  `)};
`;

const StyledButton = styled(Button)<{ hideClear: boolean }>`
  margin-left: 1rem;

  &.btn-link > .btn__content > .btn__label {
    margin: 0;
    font-size: 0.625rem;
    opacity: 0.5;
  }

  .btn__icon {
    margin-left: 0 !important;
  }

  ${media.w.max.px768(css`
    display: none;
  `)};
`;

const BackContainer = styled.div`
  display: flex;
`;

const BackButtonContent = styled.span`
  font-size: 0.625rem;
`;

const StyledChevronLeft = styled(ChevronLeft)`
  margin-right: 0.5rem;
`;

const StyledTitle = styled(Title)`
  margin-top: 0.5rem;
`;

const LoginButton = styled(Button)`
  font-weight: 700;
  text-align: left;

  .btn__content {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NewUsedProductsSwitchLabel = styled.span`
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.75rem;
  font-weight: 300;
  margin-right: 0.625rem;
`;

export const SearchHeader = memo<StyleProps>(({ className }) => {
  const total = useSelector(searchProductsResultsTotalSelector);
  const totalUsed = useSelector(searchUsedProductsResultsTotalSelector);
  const activePanel = useSelector(activeSearchPanelSelector);
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const dispatch = useDispatch();
  const closeFilters = useCallback(() => dispatch(searchPanelActions.close()), [
    dispatch,
  ]);
  const filtersState = useSelector(searchFiltersStateSelector);
  const openFilters = useCallback(
    () => dispatch(searchPanelActions.openCrossroad()),
    [dispatch]
  );
  const [filtersPristine, setFiltersPristine] = useState<boolean>(true);
  const { isLoading } = useRequest(findProductsRequestActions.request);
  const productsPreference = useSelector(productsPreferenceSelector);

  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  useEffect(() => {
    setFiltersPristine(allFiltersPristine(filtersState));
  }, [filtersState]);

  const isMobile = useMediaQuery({
    maxWidth: below(widthBreakpoints.px992),
  });

  const isMobileHeader = useMediaQuery({
    maxWidth: below(widthBreakpoints.px768),
  });

  const isSmallMobile = useMediaQuery({
    maxWidth: below(widthBreakpoints.px576),
  });

  const reset = useCallback(
    () => dispatch(searchFiltersActions.clearFiltersValues()),
    [dispatch]
  );

  const handleLogin = () => {
    navigate(LoginRoute);
    pushEvent(EventName.TopMenu, {
      menu_type: 'text_menu',
      menu_item: 'login',
    });
  };

  return (
    <>
      <Container className={className}>
        <AppHeader logoVariant={isMobile ? 'light' : 'dark'}>
          <HeaderContent>
            <NewUsedProductsPreferenceSwitchWrapper>
              <CarsMotorcyclesSwitch />
            </NewUsedProductsPreferenceSwitchWrapper>
            <HeaderChildrenWrapper>
              {!isSmallMobile && (
                <GeneralSearchAutocomplete
                  textfieldSx={{
                    ...(isMobile &&
                      !isMobileHeader && {
                        width: '170px',
                      }),
                  }}
                />
              )}
              {isMobileHeader && (
                <>
                  {isLoggedIn ? (
                    <Button
                      size="small"
                      kind="box"
                      variant="transparent"
                      onClick={() => setShowModal(true)}
                      style={{ fontSize: '0.75rem' }}
                    >
                      TWOJE <br /> BONUSY
                    </Button>
                  ) : (
                    <LoginButton
                      kind="box"
                      size="small"
                      variant="transparent"
                      onClick={handleLogin}
                    >
                      {/* ZAREJESTRUJ <br /> / ZALOGUJ */}
                      ZALOGUJ
                    </LoginButton>
                  )}
                </>
              )}
              {/* <FiltersButton
                onClick={activePanel != null ? closeFilters : openFilters}
                icon={Sliders}
              /> */}
            </HeaderChildrenWrapper>
          </HeaderContent>
        </AppHeader>
        {isSmallMobile && <GeneralSearchAutocomplete fullWidth={true} />}
        <BackContainer>
          <CleanButton as={Link} to={HomePageRoute}>
            <Button kind="box" variant="transparent">
              <StyledChevronLeft size={8} />
              <BackButtonContent>STRONA GŁÓWNA</BackButtonContent>
            </Button>
          </CleanButton>
        </BackContainer>
        <StyledTitle>
          {!isLoading && (
            <>
              przygotowaliśmy DLA CIEBIE{' '}
              {offerCountLabelFormatter(
                productsPreference === 'new' ? total : totalUsed
              )}
            </>
          )}
          {isLoading && <>Przygotowujemy dla Ciebie oferty</>}
          {!filtersPristine && (
            <StyledButton
              kind="box"
              variant="transparent"
              size="small"
              icon={Close}
              iconProps={{ size: 20, align: 'right' }}
              onClick={reset}
            >
              wyczyść filtry{' '}
            </StyledButton>
          )}
        </StyledTitle>
        <SearchHeaderOptions />
        <Gradient />
      </Container>

      <ModalPortal
        open={showModal}
        onClose={onClose}
        background={rgba(colors.background, 0.8)}
        blur={10}
      >
        <CarPlatformBusinessBenefitsModal onClose={onClose} />
      </ModalPortal>
    </>
  );
});
