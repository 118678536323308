import React, { FC, memo } from 'react';
import styled from 'styled-components';
import ThumbnailPlay from 'src/assets/icons/ThumbnailPlay';
import { transparentize } from 'polished';
import { colors } from 'src/config/colors';
import { deceleratedEasing } from 'src/common/styles/animations';
import { GalleryItem } from 'src/app/product/components/GalleryModal/galleryItem';
import { getResponsive } from 'src/common/models/image';

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(ThumbnailPlay)`
  transform-origin: center center;
  transition: all 150ms ${deceleratedEasing};
  opacity: 0.7;
`;

const Container = styled.div`
  cursor: pointer;
  position: relative;
  height: 5.75rem;

  @media (hover: hover) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${transparentize(0.5, colors.primary)};
      mix-blend-mode: lighten;
      opacity: 0;
      transition: opacity 150ms ${deceleratedEasing};
    }

    &:hover:after {
      opacity: 1;
    }

    &:hover ${Icon} {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

const Image = styled.img`
  height: 5.75rem;
`;

export interface GalleryModalThumbnailSliderProps {
  item: GalleryItem;
  onClick: () => void;
}

export const GalleryModalThumbnailSliderItem: FC<GalleryModalThumbnailSliderProps> = ({
  item,
  onClick,
}) => (
  <Container onClick={onClick}>
    <Image
      src={
        getResponsive('thumbnail', item.thumbImage) ??
        getResponsive('thumbnail', item.fullImage)
      }
    />

    {item.ytLink && (
      <IconContainer>
        <Icon size={54} />
      </IconContainer>
    )}
  </Container>
);
