import React, { FC, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { sendCompanyConfirmationEmail } from 'src/common/services/user';
import { Button } from 'src/common/components/Button';

export interface CompanyConfirmResendProps {
  companyEmail: string;
}

export const CompanyConfirmResend: FC<CompanyConfirmResendProps> = ({
  companyEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onResend = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const snackbarKey = enqueueSnackbar(
      `Ponawiamy wysłanie wiadomości na adres ${companyEmail}...`
    );
    sendCompanyConfirmationEmail({ companyEmail })
      .then(() =>
        enqueueSnackbar(
          `Wiadomość na adres ${companyEmail} została ponownie wysłana`,
          {
            variant: 'success',
          }
        )
      )
      .catch((e: AxiosError) => {
        if (e.response?.status === 429) {
          enqueueSnackbar(
            'Zbyt wiele prób w krótkim czasie, spróbuj ponownie za chwilę',
            {
              variant: 'error',
            }
          );
        } else if (
          e.response?.data?.message ===
          'Auth.sendEmailConfirmation.alreadyConfirmed'
        ) {
          enqueueSnackbar('Ten adres email został już potwierdzony', {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(
            'Wystąpił nieznany błąd, skontaktuj się z administratorem',
            {
              variant: 'error',
            }
          );
        }
      })
      .finally(() => {
        closeSnackbar(snackbarKey);
        setLoading(false);
      });
  };

  return (
    <Button
      kind="box"
      size="small"
      variant="secondary"
      style={{ marginTop: 8 }}
      onClick={onResend}
    >
      Wyślij link aktywacyjny jeszcze raz
    </Button>
  );
};
