import React, {
  ButtonHTMLAttributes,
  ComponentType,
  forwardRef,
  PropsWithChildren,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { prune } from '@summer/react-kit/functions';
import { skewBoth } from 'src/common/styles/skew';
import { IconProps } from 'src/lib/IconBox';
import { pipe } from 'fp-ts/function';
import { concat, join, map } from 'ramda';
import { typography } from 'src/config/typography';
import { transparentize } from 'polished';
import { StyleProps } from '@summer/react-kit';

export interface CleanButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: keyof JSX.IntrinsicElements | ComponentType<unknown>;
}

export const CleanButton = styled.button.attrs<CleanButtonProps>(
  ({ as, type }) =>
    !as && {
      type: type ?? 'button',
    }
)`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  border-radius: 0;
  white-space: nowrap;
  color: currentColor;
  text-decoration: none;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export type ButtonKind = 'skew' | 'box' | 'box-inverted' | 'link' | 'dropdown';

export type ButtonSize = 'small' | 'default';

export type ButtonVariant = 'primary' | 'secondary' | 'gray' | 'transparent';

export type ButtonProps = PropsWithChildren<
  {
    kind?: ButtonKind;
    size?: ButtonSize;
    variant?: ButtonVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: ComponentType<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    iconProps?: any;
  } & StyleProps &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<any, any>
>;

const ButtonContainer = styled(CleanButton)<{ $iconProps?: IconProps }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  max-width: 100%;

  & > .btn__content {
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.btn-primary {
    color: ${colors.white};
    font-family: ${typography.fontFamilyCondensed};

    & > .btn__content {
      background: ${colors.primary};
    }

    @media (hover: hover) {
      &:hover:not(:focus):not(:active):not(:disabled) {
        filter: drop-shadow(2px 3px 0 ${colors.white});
      }
    }

    &:focus > .btn__content {
      color: ${colors.primary};
      background: ${colors.darkBlue};
    }

    &:active:not(:disabled) > .btn__content {
      color: ${colors.primary};
      background: ${colors.white};
    }
  }

  &.btn-secondary {
    color: ${colors.primary};
    font-family: ${typography.fontFamilyCondensed};

    & > .btn__content {
      background: ${colors.white};
    }

    @media (hover: hover) {
      &:hover:not(:focus):not(:active):not(:disabled) {
        filter: drop-shadow(2px 3px 0 ${colors.primary});
      }
    }

    &:focus > .btn__content {
      color: ${colors.primary};
      background: ${colors.darkBlue};
    }

    &:active:not(:disabled) > .btn__content {
      color: ${colors.white};
      background: ${colors.primary};
    }
  }

  &.btn-gray {
    color: ${colors.white};
    font-family: ${typography.fontFamilyCondensed};

    & > .btn__content {
      background: #63656a;
    }

    @media (hover: hover) {
      &:hover:not(:focus):not(:active):not(:disabled) {
        filter: drop-shadow(2px 3px 0 ${transparentize(0.2, colors.woodsmoke)});

        & > .btn__content {
          background: ${transparentize(0.5, colors.grayBasic)};
        }
      }
    }

    &:focus > .btn__content {
      color: ${colors.white};
      background: ${colors.woodsmoke};
    }

    &:active:not(:disabled) > .btn__content {
      color: ${colors.woodsmoke};
      background: ${colors.white};
    }
  }

  &.btn-skew {
    text-transform: uppercase;
    font-weight: 700;

    & > .btn__content > .btn__label {
      margin-top: 0.125rem;
    }

    &.btn-default {
      font-size: 1.3125rem;
      line-height: 1.25;

      & > .btn__content {
        padding: 0.675rem calc(1.1875rem + 1.4375rem);
        ${skewBoth('1.4375rem')};
        min-height: 3.375rem;
      }

      &.btn-o-icon > .btn__content {
        padding: 0.675rem calc(0.5rem + 1.4375rem);
      }

      ${({ $iconProps }) =>
        $iconProps?.width == null &&
        $iconProps?.height == null &&
        $iconProps?.size == null &&
        css`
          & > .btn__content > .btn__icon {
            width: 1.75rem;
            height: 1.25rem;
          }
        `};

      &.btn-w-icon > .btn__content > .btn__icon {
        margin-left: 1.875rem;
      }
    }

    &.btn-small {
      font-size: 1.125rem;
      line-height: 1.25;

      & > .btn__content {
        padding: 0.375rem calc(0.75rem + 1.125rem);
        ${skewBoth('1.125rem')};
        min-height: 2.5rem;
      }

      &.btn-o-icon > .btn__content {
        padding: 0.375rem calc(0.25rem + 1.125rem);
      }

      ${({ $iconProps }) =>
        $iconProps?.width == null &&
        $iconProps?.height == null &&
        $iconProps?.size == null &&
        css`
          & > .btn__content > .btn__icon {
            width: 1.75rem;
            height: 1.25rem;
          }
        `};

      &.btn-w-icon > .btn__content > .btn__icon {
        margin-left: 0.875rem;
      }
    }
  }

  &.btn-box,
  &.btn-box-inverted {
    & > .btn__content > .btn__label {
      margin-top: 0.125rem;
    }

    &.btn-small {
      font-size: 0.75rem;
      line-height: 1.25;

      & > .btn__content {
        padding: 0.25rem 0.625rem;
      }

      ${({ $iconProps }) =>
        $iconProps?.width == null &&
        $iconProps?.height == null &&
        $iconProps?.size == null &&
        css`
          & > .btn__content {
            min-height: 1.6875rem;
          }

          & > .btn__content > .btn__icon {
            width: 0.9375rem;
            height: 0.9375rem;
          }
        `};

      &:not(.btn-box-inverted).btn-w-icon > .btn__content > .btn__icon {
        margin-left: 0.375rem;
      }

      &.btn-box-inverted.btn-w-icon > .btn__content > .btn__icon {
        margin-right: 0.375rem;
      }

      &.btn-o-icon > .btn__content {
        padding: 0.375rem 0.375rem;
      }

      &.btn-box-inverted.btn-w-icon > .btn__content {
        flex-direction: row-reverse;
      }
    }

    &.btn-default {
      font-size: 0.875rem;
      line-height: 1.25;

      & > .btn__content {
        padding: 0.5rem 0.75rem;
      }

      ${({ $iconProps }) =>
        $iconProps?.width == null &&
        $iconProps?.height == null &&
        $iconProps?.size == null &&
        css`
          & > .btn__content {
            min-height: 2.25rem;
          }

          & > .btn__content > .btn__icon {
            width: 1.25rem;
            height: 1.25rem;
          }
        `};

      &:not(.btn-box-inverted).btn-w-icon > .btn__content > .btn__icon {
        margin-left: 0.5rem;
      }

      &.btn-box-inverted.btn-w-icon > .btn__content > .btn__icon {
        margin-right: 0.5rem;
      }

      &.btn-o-icon > .btn__content {
        padding: 0.5rem 0.5rem;
      }

      &.btn-box-inverted.btn-w-icon > .btn__content {
        flex-direction: row-reverse;
      }
    }

    &.btn-transparent {
      color: currentColor;
      font-family: ${typography.fontFamilyCondensed};

      @media (hover: hover) {
        &:hover:not(:focus):not(:active):not(:disabled) {
          outline: solid 2px ${colors.primary};
          outline-offset: -2px;
        }
      }

      &:focus > .btn__content {
        color: ${colors.primary};
        background: ${colors.darkBlue};
      }

      &:active:not(:disabled) > .btn__content {
        color: ${colors.white};
        background: ${colors.primary};
      }
    }
  }

  &.btn-link {
    line-height: 1.25;

    & > .btn__content {
      background: none;
      height: auto;
    }

    @media (hover: hover) {
      &:hover:not(:focus):not(:active):not(:disabled) {
        filter: none;
      }
    }

    &:focus > .btn__content {
      background: none;
      color: currentColor;
    }

    &.btn-primary {
      color: ${colors.primary};

      & > .btn__content {
        border-bottom: 1px solid transparent;
      }

      @media (hover: hover) {
        &:hover:not(:focus):not(:active):not(:disabled) {
          & > .btn__content {
            border-bottom: 1px solid ${colors.primary};
          }
        }
      }

      &:focus > .btn__content {
        outline: solid 1px ${colors.primary};
        outline-offset: -1px;
      }

      &:active:not(:disabled) > .btn__content {
        background: none;
        color: ${colors.white};
        border-bottom: 1px solid ${colors.white};
        outline: none;
      }
    }

    &.btn-secondary {
      color: ${colors.white};

      & > .btn__content {
        border-bottom: 1px solid transparent;
      }

      @media (hover: hover) {
        &:hover:not(:focus):not(:active):not(:disabled) {
          & > .btn__content {
            border-bottom: 1px solid ${colors.white};
          }
        }
      }

      &:focus > .btn__content {
        outline: solid 1px ${colors.white};
        outline-offset: -1px;
      }

      &:active:not(:disabled) > .btn__content {
        background: none;
        color: ${colors.primary};
        border-bottom: 1px solid ${colors.primary};
        outline: none;
      }
    }

    &.btn-transparent {
      & > .btn__content {
        border-bottom: 1px solid transparent;
      }

      @media (hover: hover) {
        &:hover:not(:focus):not(:active):not(:disabled) {
          & > .btn__content {
            border-bottom: 1px solid ${colors.primary};
          }
        }
      }

      &:focus > .btn__content {
        outline: solid 1px currentColor;
        outline-offset: 1px;
      }

      &:active:not(:disabled) > .btn__content {
        background: none;
        color: ${colors.primary};
        border-bottom: 1px solid ${colors.primary};
        outline: none;
      }
    }
  }

  &.btn-dropdown {
    line-height: 1.25;
    color: ${colors.background};
    font-size: 0.75rem;
    text-transform: uppercase;

    & > .btn__content {
      background: rgba(255, 255, 255, 0.2);
      padding: 0.5rem 0.75rem;
      border-radius: 2px;
    }

    ${({ $iconProps }) =>
      $iconProps?.width == null &&
      $iconProps?.height == null &&
      $iconProps?.size == null &&
      css`
        & > .btn__content {
          height: 2rem;
        }

        & > .btn__content > .btn__icon {
          width: 0.625rem;
          height: 0.625rem;
        }
      `};

    &.btn-w-icon > .btn__content > .btn__icon {
      margin-left: 0.5rem;
    }

    @media (hover: hover) {
      &:hover:not(:focus):not(:active):not(:disabled) {
        filter: none;

        & > .btn__content {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }

    &:active:not(:disabled) > .btn__content {
      color: ${colors.white};
      background: ${colors.primary};
    }

    &:focus > .btn__content {
      background: none;
      color: currentColor;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Button = forwardRef<any, ButtonProps>(
  (
    {
      kind = 'skew',
      size = 'default',
      variant = 'primary',
      icon: Icon,
      children,
      className,
      iconProps,
      ...rest
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any
  ) => {
    const classNames = useMemo(
      () =>
        pipe(
          [
            kind,
            size,
            variant,
            Icon != null && children != null ? 'w-icon' : undefined,
            children == null ? 'o-icon' : undefined,
          ],
          prune,
          map((a) => `btn-${a}`),
          concat(['btn', className]),
          prune,
          join(' ')
        ),
      [kind, size, variant, Icon, className, children]
    );

    return (
      <ButtonContainer
        tabIndex={0}
        $iconProps={iconProps}
        className={classNames}
        ref={ref}
        {...rest}
      >
        <span className="btn__content">
          {Icon == null && children}
          {Icon != null && children != null && iconProps?.align !== 'right' && (
            <>
              <span className="btn__label">{children}</span>
              <Icon className="btn__icon" {...(iconProps ?? {})} />
            </>
          )}
          {Icon != null && children != null && iconProps?.align === 'right' && (
            <>
              <Icon
                className="btn__icon"
                style={{ marginRight: '0.5rem' }}
                {...(iconProps ?? {})}
              />
              <span className="btn__label">{children}</span>
            </>
          )}
          {Icon != null && children == null && (
            <Icon className="btn__icon" {...(iconProps ?? {})} />
          )}
        </span>
      </ButtonContainer>
    );
  }
);
