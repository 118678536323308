import { getErrorMessages } from 'src/common/services/axios';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  mapToConfirmEmailsPageState,
  mapToRegisterStep2SuccessState,
} from 'src/common/models/auth';
import { navigate } from 'gatsby';
import {
  authActions,
  fetchUserActions,
  loginActions,
  registerActions,
  registerByCodeActions,
  registerStep2Actions,
  setUserCustomerTypeActions,
  updateUserActions,
} from 'src/state/auth/authActions';
import {
  RegisterStep2SuccessRoute,
  RegisterSuccessRoute,
} from 'src/public/publicRoutes';
import { REHYDRATE } from 'redux-persist';
import {
  authStateSelector,
  userIdSelector,
} from 'src/state/auth/authSelectors';
import { isSome } from 'fp-ts/lib/Option';
import { AnyAction } from 'redux';
import { snackbarActions } from 'src/state/notification/notificationActions';
import {
  fetchUser,
  login,
  pushUserIdEvent,
  register,
  registerByCode,
  registerStep2,
  storeLoginFlag,
  updateUser,
} from 'src/common/services/user';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

function* processLogin(action: ReturnType<typeof loginActions.request>) {
  const { identifier, password } = action.payload;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(login, identifier, password);
    yield put(loginActions.requestSuccess(response));

    pushUserIdEvent(response.user.id);

    pushEvent(EventName.Login, {
      method: 'email',
    });
  } catch (error) {
    yield put(loginActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* loginWatcher() {
  yield takeEvery(loginActions.request.type, processLogin);
}

function* processLoginSuccess() {
  storeLoginFlag();
}

function* loginSuccessWatcher() {
  yield takeEvery(loginActions.requestSuccess, processLoginSuccess);
}

function* processRegister(action: ReturnType<typeof registerActions.request>) {
  const data = action.payload;

  try {
    yield call(register, data);
    navigate(RegisterSuccessRoute, {
      state: mapToConfirmEmailsPageState(data.companyEmail),
    });
    pushEvent(EventName.Contact, {});
    yield put(registerActions.requestSuccess());
  } catch (error) {
    yield put(registerActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* registerWatcher() {
  yield takeEvery(registerActions.request.type, processRegister);
}

function* processRegisterStep2(
  action: ReturnType<typeof registerStep2Actions.request>
) {
  const data = action.payload;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(registerStep2, data);
    navigate(RegisterStep2SuccessRoute, {
      state: mapToRegisterStep2SuccessState(data),
    });
    yield put(registerStep2Actions.requestSuccess(response));
  } catch (error) {
    yield put(registerStep2Actions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* registerStep2Watcher() {
  yield takeEvery(registerStep2Actions.request.type, processRegisterStep2);
}

function* processRegisterByCode(
  action: ReturnType<typeof registerByCodeActions.request>
) {
  const data = action.payload;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(registerByCode, data);
    navigate(RegisterSuccessRoute, {
      state: mapToConfirmEmailsPageState(data.email, true),
    });
    yield put(registerByCodeActions.requestSuccess(response));
  } catch (error) {
    yield put(registerByCodeActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error', autoHideDuration: 10000 },
          })
        )
      )
    );
  }
}

function* registerByCodeWatcher() {
  yield takeEvery(registerByCodeActions.request.type, processRegisterByCode);
}

function* processFetchUser() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchUser);
    yield put(fetchUserActions.requestSuccess(response));
  } catch (error) {
    yield put(fetchUserActions.requestFailure(error));
  }
}

function* fetchUserWatcher() {
  yield takeEvery(fetchUserActions.request.type, processFetchUser);
}

function* processUpdateUser(
  action: ReturnType<typeof updateUserActions.request>
) {
  const data = action.payload;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME
  const userId = yield select(userIdSelector);

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const user = yield call(updateUser, userId, data);
    yield put(updateUserActions.requestSuccess(user));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Twoje dane zostały zaktualizowane',
        options: { variant: 'success' },
      })
    );
  } catch (error) {
    yield put(updateUserActions.requestFailure(error));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Wystąpił problem podczas aktualizowania danych',
        options: { variant: 'error' },
      })
    );
  }
}

function* updateUserWatcher() {
  yield takeEvery(updateUserActions.request.type, processUpdateUser);
}

function* processSetUserCustomerType(
  action: ReturnType<typeof setUserCustomerTypeActions.request>
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME
  const userId = yield select(userIdSelector);

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const user = yield call(updateUser, userId, {
      customerType: action.payload,
    });
    yield put(setUserCustomerTypeActions.requestSuccess(user));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Twoja preferencja została zaktualizowana',
        options: { variant: 'success' },
      })
    );
  } catch (error) {
    yield put(setUserCustomerTypeActions.requestFailure(error));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Wystąpił problem podczas aktualizowania Twojej preferencji',
        options: { variant: 'error' },
      })
    );
  }
}

function* setUserCustomerTypeWatcher() {
  yield takeEvery(
    setUserCustomerTypeActions.request.type,
    processSetUserCustomerType
  );
}

function* rehydrationWatcher() {
  yield take(
    (action: AnyAction) => action.type === REHYDRATE && action.key === 'auth'
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME
  const authState = yield select(authStateSelector);

  if (!isSome(authState.token)) {
    return;
  }

  let user = isSome(authState.user) && authState.user;

  if (!user) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore FIXME
      user = yield call(fetchUser);
      yield put(fetchUserActions.requestSuccess(user));
    } catch (error) {
      yield put(fetchUserActions.requestFailure(error));
    }
  }

  pushUserIdEvent(user.id);
}

function* logoutWatcher() {
  yield takeEvery(authActions.logout.type, () => {
    pushUserIdEvent();
  });
}

export function* authSagas() {
  yield all([
    loginWatcher(),
    loginSuccessWatcher(),
    registerWatcher(),
    registerStep2Watcher(),
    registerByCodeWatcher(),
    fetchUserWatcher(),
    updateUserWatcher(),
    setUserCustomerTypeWatcher(),
    rehydrationWatcher(),
    logoutWatcher(),
  ]);
}
