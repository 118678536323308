import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6365 38V58.3636H5.81836V38H11.6365Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6365 6V26.3636H5.81836V6H11.6365Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.91 32.1818V58.3636H29.0918V32.1818H34.91Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.91 6V20.5455H29.0918V6H34.91Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.1815 43.8182V58.3637H52.3633V43.8182H58.1815Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.1815 6V32.1818H52.3633V6H58.1815Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4545 40.9091H0V35.0909H17.4545V40.9091Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.728 23.4545H23.2734V17.6364H40.728V23.4545Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.9995 46.7272H46.5449V40.9091H63.9995V46.7272Z"
    />
  </IconBox>
);
