import React, { FC, ReactNode } from 'react';
import { colors } from 'src/config/colors';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { StyleProps } from '@summer/react-kit';

const Title = styled.div`
  color: ${colors.white};
  font-size: 0.75rem;
  font-weight: 700;
`;

const Content = styled(Markdown)`
  color: ${colors.lightGray};
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
`;

export interface ProductOfferInstallmentProps {
  title?: ReactNode;
  children?: string;
}

export const ProductOfferTileInstallment: FC<
  StyleProps & ProductOfferInstallmentProps
> = ({ className, style, title, children }) => (
  <div className={className} style={style}>
    <Title>{title}</Title>
    {children && <Content>{children}</Content>}
  </div>
);
