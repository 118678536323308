import React, { ComponentType, FC, PropsWithChildren } from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { StyleProps } from '@summer/react-kit';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import {
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { FinancingType } from 'src/common/models/offer';

export interface FinancingTypeSwitchProps extends StyleProps {
  variant?: ToggleButtonVariant;
  labels?: [string, string];
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
}

export const FinancingTypeSwitch: FC<FinancingTypeSwitchProps> = ({
  variant,
  labels = ['Najem', 'Leasing'],
  style,
  className,
  toggleButtonComponent,
}) => {
  const { state, setValue } = useOptionFilterControl(
    OptionFilter.FinancingType
  );

  return (
    <HorizontalToggleGroupContainer style={style} className={className}>
      <ToggleButtonGroup
        value={state.value}
        variant={variant}
        onChange={(v) => setValue(v)}
        items={[
          { id: FinancingType.Rent, label: labels[0] },
          { id: FinancingType.Leasing, label: labels[1] },
        ]}
        toggleButtonComponent={
          toggleButtonComponent ? toggleButtonComponent : HorizontalToggleButton
        }
      />
    </HorizontalToggleGroupContainer>
  );
};
