import styled, { css } from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import {
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { typography } from 'src/config/typography';
import { fade } from '@material-ui/core';
import { colors } from 'src/config/colors';
import React, { FC } from 'react';
import { useDerivedState } from '@summer/react-kit/hooks';

export const StyledHorizontalToggleButton = styled(CleanButton)<{
  variant?: ToggleButtonVariant;
  active?: boolean;
  disabled?: boolean;
}>`
  display: inline-flex;
  padding: 0.375rem 0.25rem;
  font-family: ${typography.fontFamilyCondensed};
  font-weight: normal;
  font-size: 0.75rem;
  flex: 1;
  line-height: 1;
  justify-content: center;
  align-items: center;
  color: ${fade(colors.white, 0.8)};
  border: 2px solid transparent;
  border-radius: 2px;
  user-select: none;
  text-transform: uppercase;

  ${({ active, variant = 'primary' }) => {
    if ('primary' == variant) {
      return css`
        &:enabled {
          @media (hover: hover) {
            &:hover {
              background-color: ${fade(colors.primary, 0.2)};
            }
          }

          &:focus {
            border-color: ${colors.primary};
            background-color: ${colors.darkBlue};
          }
        }

        ${active &&
        css`
          color: ${colors.white};
          background-color: ${colors.primary};
          font-weight: bold;

          &:enabled {
            @media (hover: hover) {
              &:hover {
                background-color: ${colors.darkBlue};
              }
            }

            &:focus {
              border-color: ${colors.darkBlue};
              background-color: ${colors.primary};
            }
          }
        `}
        &:disabled {
          opacity: 0.3;
        }
      `;
    }

    if ('secondary' == variant) {
      return css`
        &:enabled {
          @media (hover: hover) {
            &:hover {
              background-color: ${fade(colors.white, 0.2)};
            }
          }

          &:focus {
            border-color: ${colors.white};
            background-color: #55555b;
          }
        }

        ${active &&
        css`
          color: ${colors.gray1};
          background-color: #cacaca;
          font-weight: bold;

          &:enabled {
            @media (hover: hover) {
              &:hover {
                background-color: ${colors.white};
              }
            }

            &:focus {
              border-color: ${colors.white};
              background-color: #cacaca;
            }
          }
        `}
        &:disabled {
          opacity: 0.3;
        }
      `;
    }
  }}
`;

export const HorizontalToggleButton: FC<ToggleButtonProps> = ({
  initialValue,
  value,
  onChange,
  className,
  style,
  variant = 'primary',
  disabled,
  children,
}) => {
  const [active, setActive] = useDerivedState(initialValue ?? false, {
    value,
    onChange,
  });

  return (
    <StyledHorizontalToggleButton
      className={className}
      style={style}
      variant={variant}
      active={active}
      onClick={() => setActive((currentValue: boolean) => !currentValue)}
      disabled={disabled}
    >
      {children}
    </StyledHorizontalToggleButton>
  );
};
