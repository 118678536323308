import { ProductPageSection } from 'src/app/product/models/productPageSection';
import React, { FC, RefObject } from 'react';
import { Icon } from 'src/common/components/Icon';
import { CleanButton } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';
import { styledIs } from '@summer/react-kit/functions';
import { transparentize } from 'polished';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';

const Container = styled(ConstraintContainer)`
  position: sticky;
  display: flex;
  bottom: 5.875rem;
  height: 4rem;
  z-index: 50;
  background-color: ${transparentize(0.1, colors.background)};
  justify-content: space-between;
  align-items: center;
`;

const NavigationItem = styled(CleanButton)<{
  active?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  color: ${colors.grayBasic};

  ${styledIs('active')(css`
    color: ${colors.white};
  `)};
`;

const Title = styled.span`
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.5625rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
`;

export interface ProductPageNavigationMobileProps {
  active?: string;
  items: ProductPageSection[];
  onClick: (sectionRef: RefObject<HTMLDivElement>) => void;
}

export const ProductPageNavigationMobile: FC<ProductPageNavigationMobileProps> = ({
  active,
  items,
  onClick,
}) => (
  <Container>
    {items.map((item) => (
      <NavigationItem
        key={item.key}
        onClick={() => onClick(item.ref)}
        active={active === item.key}
      >
        <Icon code={item.icon} width={22} style={{ flexShrink: 0 }} />
        <Title>{item.menuTitleSm}</Title>
      </NavigationItem>
    ))}
  </Container>
);
