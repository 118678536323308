import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

// todo: should be unified with application headings
export const RequestProductSectionTitle = styled.h1`
  margin: 0;

  line-height: 1.45;
  font-size: 1.375rem;

  ${media.w.min.px768(css`
    font-size: 2.5rem;
  `)}
`;
