import { Paragraph, Text } from 'src/common/components/typography/Text';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { Link, navigate, PageProps } from 'gatsby';
import { AppRoute } from 'src/app/routes';
import { CenteredLayout } from 'src/public/components/CenteredLayout';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import React, { useMemo } from 'react';
import { Button } from 'src/common/components/Button';
import { ContactRoute, LandingRoute } from 'src/public/publicRoutes';
import {
  ConfirmEmailsPageState,
  isConfirmEmailsPageState,
} from 'src/common/models/auth';
import { CleanUnorderedList, ListItem } from 'src/common/components/List';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { EmailConfirmResend } from 'src/public/components/EmailConfirmResend';
import {
  RegistrationHeader,
  RegistrationHeaderSteps,
} from 'src/public/components/auth/RegistrationHeader';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const RegisterSuccessPage = ({ location }: PageProps) => {
  useStorePageUrl();
  const isLoggedIn = useSelector(isAuthenticatedSelector);

  if (isPlatformBrowser()) {
    if (isLoggedIn) {
      navigate(AppRoute);
      return null;
    }

    if (!isConfirmEmailsPageState(location?.state)) {
      navigate(LandingRoute);
      return null;
    }
  }

  const standardRegistrationEmail = useMemo(
    () => (location?.state as ConfirmEmailsPageState)?.email,
    [location]
  );
  const registrationByCodeEmail = useMemo(
    () => (location?.state as ConfirmEmailsPageState)?.registrationByCodeEmail,
    [location]
  );

  const email = registrationByCodeEmail ?? standardRegistrationEmail;

  return (
    <CenteredLayout>
      <div>
        <HomeLogoLink />

        <Seo title="Rejestracja udana" />

        {standardRegistrationEmail && (
          <RegistrationHeader active={RegistrationHeaderSteps.Step1} />
        )}

        <Title level={1} variant="condensed">
          Potwierdź adres e-mail
        </Title>

        <Paragraph size="small">
          Na podany adres <i>{email}</i> wysłaliśmy link aktywacyjny, dzięki
          któremu dokończysz rejestrację w&nbsp;Car&nbsp;Platform.
        </Paragraph>

        <Text as="div" size="xSmall" style={{ marginTop: '3.6vh' }}>
          Nie otrzymałeś linku aktywacyjnego?
          <br />
          Sprawdź, czy wiadomość nie trafiła do spamu.
        </Text>

        <CleanUnorderedList style={{ margin: 0 }}>
          {email && (
            <ListItem style={{ margin: 0 }}>
              <EmailConfirmResend email={email} />
            </ListItem>
          )}
        </CleanUnorderedList>

        <Text as="div" size="xSmall" style={{ marginTop: '3.6vh' }}>
          Jeśli masz problem z&nbsp;rejestracją, skontaktuj się z&nbsp;nami za
          pomocą adresu e-mail hello@carplatform.pl lub formularza kontaktowego
          zamieszczonego na stronie
          <SimpleLink
            as={Link}
            to={ContactRoute}
            style={{ marginLeft: '0.2rem' }}
          >
            carplatform.pl/contact
          </SimpleLink>
          .
        </Text>

        <Button
          kind="box-inverted"
          variant="transparent"
          icon={ChevronLeft}
          as={Link}
          to={LandingRoute}
          style={{ textTransform: 'uppercase', marginTop: '10vh' }}
        >
          Wróć
        </Button>
      </div>
    </CenteredLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegisterSuccessPage;
