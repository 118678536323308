import React from 'react';
import { AsideMainMenuLayout } from 'src/common/components/AsideMainManuLayout';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import { typography } from 'src/config/typography';
import { ExpansionPanelSummary } from 'src/common/components/ExpansionPanel/ExpansionPanelSummary';
import { ExpansionPanelDetails } from 'src/common/components/ExpansionPanel/ExpansionPanelDetails';
import { ExpansionPanel } from 'src/common/components/ExpansionPanel/ExpansionPanel';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Paragraph } from 'src/common/components/typography/Text';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

// todo: Extract to AsideMainMenuLayout ?
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.875rem;
`;

// todo: Extract to AsideMainMenuLayout ?
const ScrollContent = styled.div`
  max-width: 49rem;
`;

const Level2List = styled.ol`
  list-style-type: lower-alpha;
  margin-top: 1rem;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const TosPage = () => {
  useStorePageUrl();

  return (
    <AsideMainMenuLayout>
      <Seo title="Regulamin" />
      <Container>
        <Title level={1} variant="condensed" style={{ color: colors.white }}>
          Regulamin świadczenia usług drogą elektroniczną w&nbsp;serwisie
          www.carplatform.pl
        </Title>
        <FlexScrollContainer>
          <ScrollContent>
            <section>
              <Paragraph size="small">
                Obowiązuje od dnia 14 lipca 2020r. do odwołania
              </Paragraph>

              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary>
                  I. Postanowienia ogólne
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      W niniejszym regulaminie (dalej{' '}
                      <strong>&bdquo;Regulamin&rdquo;</strong>), przedstawiamy
                      zasady korzystania z serwisu dostępnego na stronie
                      internetowej www.carplatform.pl, (dalej{' '}
                      <strong>&bdquo;Serwis&rdquo;</strong>).
                    </ListItem>
                    <ListItem>
                      Pisząc w Regulaminie o &bdquo;nas&rdquo;, mamy na myśli
                      spółkę <strong>Mobility Benefit sp. z o.o.</strong> z
                      siedzibą w Warszawie, Rondo Daszyńskiego 1, 00-843
                      Warszawa, wpisaną do rejestru przedsiębiorców prowadzonego
                      przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII
                      Wydział Gospodarczy Krajowego Rejestru Sądowego, pod
                      numerem KRS: 0000827524, NIP 5272920409, REGON 385514410,
                      kapitał zakładowy w wysokości 25.000 zł.
                    </ListItem>
                    <ListItem>
                      W naszym Serwisie publikujemy informacje dotyczące towarów
                      i usług współpracujących z nami podmiotów. W braku
                      przeciwnego zastrzeżenia, informacje te nie stanowią
                      oferty w rozumieniu Kodeksu Cywilnego.
                    </ListItem>
                    <ListItem>
                      Przed rozpoczęciem korzystania z Serwisu
                      powinnaś/powinieneś zapoznać się ze wszystkimi
                      postanowieniami niniejszego Regulaminu.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary id="jump1">
                  II. Warunki korzystania z Serwisu
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      Udostępniamy nasz Serwis niezarejestrowanym użytkownikom w
                      ograniczonym zakresie. Bez rejestracji możesz skorzystać z
                      naszych usług informacyjno- reklamowych, dotyczących
                      przykładowych ofert współpracujących z nami dealerów
                      samochodowych, banków i podmiotów oferujących
                      długoterminowy najem pojazdów oraz wypełnić formularz
                      kontaktowy.
                    </ListItem>
                    <ListItem>
                      <Paragraph size="small">
                        Możesz korzystać ze wszystkich funkcjonalności Serwisu
                        (dalej <strong>&bdquo;Usługa&rdquo;</strong>), jeśli
                        spełniasz poniższe warunki:
                      </Paragraph>
                      <Level2List>
                        <ListItem>
                          jesteś zatrudniona/ zatrudniony na podstawie umowy o
                          pracę, wykonujesz zlecenie lub świadczysz usługi w
                          ramach indywidualnej działalności gospodarczej na
                          rzecz jednego z naszych partnerów, których listę
                          udostępniamy w Serwisie oraz
                        </ListItem>
                        <ListItem>
                          utworzysz konto w Serwisie zgodnie z pkt. III.1-3
                          Regulaminu.
                        </ListItem>
                      </Level2List>
                    </ListItem>
                    <ListItem>
                      <Paragraph size="small">
                        W ramach Usługi możesz:
                      </Paragraph>
                      <Level2List>
                        <ListItem>
                          precyzyjnie określać parametry interesujących Cię
                          pojazdów oraz modeli finansowania;
                        </ListItem>
                        <ListItem>
                          zapoznawać się z informacjami o pojazdach dostępnych w
                          ofercie współpracujących z nami dealerów;
                        </ListItem>
                        <ListItem>
                          składać zapytania ofertowe dotyczące pojazdu
                          prezentowanego w Serwisie oraz preferowanego modelu
                          finansowania.
                        </ListItem>
                      </Level2List>
                    </ListItem>
                    <ListItem>
                      Umowę o świadczenie Usługi drogą elektroniczną (dalej
                      <strong>&bdquo;Usługa&rdquo;</strong>) zawierasz z nami z
                      chwilą otrzymania od nas wiadomości e-mail z
                      potwierdzeniem rejestracji w Serwisie. Umowę zawieramy na
                      czas nieoznaczony.
                    </ListItem>
                    <ListItem>
                      Możemy weryfikować aktualność spełniania przez Ciebie
                      warunków korzystania z Usługi poprzez wysłanie wiadomości
                      e-mail na Twój adres służbowy nie częściej niż raz na 2
                      miesiące.
                    </ListItem>
                    <ListItem>
                      W przypadku, w którym przestaniesz spełniać warunek
                      wskazany w pkt II 2. a powyżej, zachowasz dostęp do
                      swojego konta w Serwisie, ale nie będziesz mieć możliwości
                      skorzystania z Usługi oraz ofert naszych partnerów
                      przesłanych w wyniku zapytania ofertowego przesłanego
                      przez Ciebie w ramach Usługi.
                    </ListItem>
                    <ListItem>
                      <Paragraph size="small">
                        Do prawidłowego korzystania z Serwisu konieczne jest
                        spełnienie minimalnych wymagań technicznych:
                      </Paragraph>
                      <Level2List>
                        <ListItem>
                          korzystanie z przeglądarki Chrome lub Firefox w
                          najnowszej wersji
                        </ListItem>
                        <ListItem>zaakceptowanie Polityki cookies</ListItem>
                      </Level2List>
                    </ListItem>
                    <ListItem>
                      Zastrzegamy sobie prawo do wprowadzania zmian w zakresie
                      technicznego sposobu realizacji Usługi bez pogarszania jej
                      jakości oraz bez wpływu na funkcjonalności dostępne w jej
                      ramach.
                    </ListItem>
                    <ListItem>
                      W celu zabezpieczenia bezpieczeństwa przekazu komunikatów
                      w związku ze świadczoną Usługą, podejmiemy środki
                      techniczne i organizacyjne odpowiednie do stopnia
                      zagrożenia bezpieczeństwa Usługi.
                    </ListItem>
                    <ListItem>
                      Możemy wysyłać na Twoje konto w Serwisie informacje
                      techniczne i systemowe dotyczące funkcjonowania Serwisu.
                    </ListItem>
                    <ListItem>
                      Jeśli jesteś konsumentem w rozumieniu art. 221 Kodeksu
                      cywilnego, możesz odstąpić od Umowy bez podania przyczyny,
                      informując nas o tym w ciągu 14 dni od dnia zawarcia
                      Umowy. W takim przypadku, Umowa uważana jest za
                      niezawartą. Możesz skorzystać ze wzoru oświadczenia o
                      odstąpieniu, który dołączyliśmy do niniejszego Regulaminu.
                    </ListItem>
                    <ListItem>
                      Możesz rozwiązać Umowę poprzez usunięcie konta przez
                      wysłanie do nas żądania usunięcia konta na adres e-mail
                      podany w Serwisie, przy czym w przypadku zgłoszenia
                      żądania usunięcia konta Umowa ulegnie rozwiązaniu po
                      upływie 30 dni od dnia złożenia żądania.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>III. Rejestracja</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      Możesz rozpocząć korzystanie z Usługi po założeniu konta w
                      Serwisie (dalej <strong>&bdquo;Rejestracja&rdquo;</strong>
                      ).
                    </ListItem>
                    <ListItem>
                      W celu potwierdzenia spełniania przez Ciebie warunków
                      korzystania z Usługi wyślemy link aktywacyjny na służbowy
                      adres e-mail, podany przez Ciebie w Serwisie.
                    </ListItem>
                    <ListItem>
                      <Paragraph size="small">W trakcie Rejestracji:</Paragraph>
                      <Level2List>
                        <ListItem>
                          potwierdzasz zapoznanie się zapoznanie się z
                          Regulaminem i akceptujesz jego postanowienia;
                        </ListItem>
                        <ListItem>
                          podajesz swoje dane: m.in. imię, nazwisko, prywatny
                          adres e-mail.
                        </ListItem>
                      </Level2List>
                    </ListItem>
                    <ListItem>
                      Po zakończeniu Rejestracji wyślemy do Ciebie e-mail z
                      potwierdzeniem.
                    </ListItem>
                    <ListItem>
                      Twój prywatny adres e-mail jest loginem do Twojego konta
                      w&nbsp;Serwisie, do którego zalogujesz się za pomocą
                      ustalonego przez siebie hasła. Minimalne wymagania hasła
                      to co najmniej 8&nbsp;znaków, w&nbsp;tym co najmniej jedna
                      wielka litera, jedna mała litera, jedna cyfra i&nbsp;jeden
                      znak specjalny (@$!%*#?&).
                    </ListItem>
                    <ListItem>
                      W czasie Rejestracji jesteś zobowiązana/ zobowiązany do
                      podania aktualnych danych, nienaruszających praw osób
                      trzecich.
                    </ListItem>
                    <ListItem>
                      W czasie korzystania z Usługi jesteś zobowiązana/
                      zobowiązany do niezwłocznego aktualizowania swoich danych
                      oraz do nieujawniania osobom trzecim hasła do konta.
                    </ListItem>
                    <ListItem>
                      W przypadku skorzystania przez Ciebie z opcji „zapamiętaj
                      mnie” dostęp do Twojego konta z urządzenia końcowego, z
                      którego miał miejsce wybór tej opcji, zapewniany jest z
                      pominięciem każdorazowej konieczności logowania się do
                      Serwisu przez czas ważności pliku cookie, tj. przez 30
                      dni. Zwracamy uwagę, że wybór opcji „zapamiętaj mnie” w
                      przypadku korzystania z danego urządzenia końcowego przez
                      kilka osób może mieć dla Ciebie negatywne konsekwencje.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary id="jump3">
                  IV. Twoja odpowiedzialność
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      <Paragraph size="small">
                        Jesteś zobowiązana / zobowiązany do:
                      </Paragraph>
                      <Level2List>
                        <ListItem>
                          korzystania z Serwisu w sposób zgodny z Regulaminem,
                          obowiązującymi przepisami oraz do niedostarczania
                          treści o charakterze bezprawnym lub sprzecznym z
                          dobrymi obyczajami;
                        </ListItem>
                        <ListItem>
                          korzystania z Serwisu w sposób niezakłócający jego
                          funkcjonowania, w szczególności poprzez użycie
                          określonego oprogramowania lub urządzeń;
                        </ListItem>
                        <ListItem>
                          niepodejmowania działań polegających na przesyłaniu
                          niezamówionej informacji handlowej, korzystania z kont
                          innych użytkowników lub udostępnianiu swojego konta
                          innym użytkownikom;
                        </ListItem>
                        <ListItem>
                          nieposługiwania się danymi osób trzecich;
                        </ListItem>
                        <ListItem>
                          niepodejmowania działań informatycznych mających na
                          celu wejście w posiadanie adresów e-mail, haseł lub
                          loginów;
                        </ListItem>
                        <ListItem>
                          korzystania z wszelkich treści zamieszczonych w
                          Serwisie wyłącznie w ramach własnego użytku
                          osobistego;
                        </ListItem>
                        <ListItem>
                          każdorazowego wylogowania się w przypadku zakończenia
                          danej sesji w Serwisie;
                        </ListItem>
                        <ListItem>
                          powiadomienia nas o naruszeniu przez osobę trzecią
                          praw do Twojego loginu lub hasła;
                        </ListItem>
                      </Level2List>
                    </ListItem>
                    <ListItem>
                      Ponosisz wyłączną odpowiedzialność za udostępnienie przez
                      Ciebie loginu lub hasła do konta w Serwisie osobom
                      trzecim.
                    </ListItem>
                    <ListItem>
                      W przypadku, w którym Twoje działania naruszają
                      postanowienia Regulaminu, w szczególności jeśli podałaś/
                      podałeś w trakcie Rejestracji dane niezgodne z prawdą lub
                      naruszające prawa osób trzecich, możemy wysłać Ci
                      ostrzeżenie na adres e-mail podany przez Ciebie w czasie
                      Rejestracji lub zablokować Twój dostęp do konta i
                      rozwiązać Umowę.{' '}
                    </ListItem>
                    <ListItem>
                      Informujemy, że używane przez nas środki techniczne
                      służące zabezpieczeniu Serwisu są zgodne z dobrą praktyką
                      na rynku, lecz nie gwarantują całkowitego bezpieczeństwa w
                      związku ze szczególnymi zagrożeniami wynikającymi z
                      elektronicznego charakteru komunikacji wymienianej w
                      ramach Serwisu, w przypadku ataku hackerów lub obecności
                      wirusów na urządzeniu, z którego logujesz się do Serwisu.
                      Dlatego wskazane jest zapewnienie przez Ciebie ochrony
                      Twoich urządzeń, oprogramowania i danych przed zagrożeniem
                      wirusowym.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary id="jump4">
                  V. Nasza odpowiedzialność
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      Zobowiązujemy się do świadczenia usług na zasadach
                      określonych w tym Regulaminie.
                    </ListItem>
                    <ListItem>
                      Jesteśmy odpowiedzialni za świadczenie Usługi oraz usług
                      dostępnych dla niezarejestrowanych użytkowników Serwisu.
                    </ListItem>
                    <ListItem>
                      <Paragraph size="small">
                        Nie ponosimy odpowiedzialności za:
                      </Paragraph>
                      <Level2List>
                        <ListItem>
                          niedziałanie Serwisu z powodu Siły wyższej;
                        </ListItem>
                        <ListItem>
                          korzystanie przez Ciebie z Serwisu w sposób niezgodny
                          z Regulaminem lub przepisami prawa;
                        </ListItem>
                        <ListItem>
                          przypadki Twojej nieuprawnionej ingerencji w Serwis,
                        </ListItem>
                        <ListItem>
                          ograniczenia techniczne i usterki Twojego
                          oprogramowania lub sprzętu komputerowego oraz sieci
                          telekomunikacyjnej operatora, z którego usług
                          korzystasz.
                        </ListItem>
                      </Level2List>
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary id="jump5">
                  VI. Opłaty
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      Korzystanie z Serwisu na zasadach określonych w
                      Regulaminie nie wiąże się z koniecznością ponoszenia
                      dodatkowych opłat, za wyjątkiem ewentualnych kosztów
                      związanych z połączeniem z Internetem, naliczanych przez
                      Twojego dostawcę usług telekomunikacyjnych.
                    </ListItem>
                    <ListItem>
                      Możemy prezentować w Serwisie informacje o towarach i
                      usługach naszych partnerów, rozpatrywać oraz przesyłać
                      Twoje zapytania ofertowe na podstawie umów zawartych z
                      naszymi parterami, w szczególności z dealerami, bankami,
                      podmiotami oferującymi długoterminowy najem pojazdów oraz
                      ubezpieczycielami. Jeśli jesteś uprawniony/ uprawniona do
                      korzystania z Usługi, oznacza to, że zawarliśmy umowę z
                      Twoim pracodawcą.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>VII. Reklamacje</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      Podejmujemy działania w celu zapewnienia poprawnego
                      działania Serwisu, w zakresie jaki wynika z aktualnej
                      wiedzy technicznej i dobrych praktyk.
                    </ListItem>
                    <ListItem>
                      Reklamacje dotyczące niewłaściwego działania Serwisu lub
                      Usługi możesz składać drogą elektroniczną na adres e-mail:{' '}
                      <SimpleLink href="mailto:hello@mobilitybenefit.pl">
                        hello@mobilitybenefit.pl
                      </SimpleLink>{' '}
                      lub poprzez formularz na stronie Serwisu pod adresem
                      www.carplatform.pl/contact.
                    </ListItem>
                    <ListItem>
                      Reklamacja powinna zawierać: imię, nazwisko, adres e-mail
                      oraz opis i powód reklamacji.
                    </ListItem>
                    <ListItem>
                      Powiadomimy Cię o rozpatrzeniu reklamacji w terminie 30
                      dni od daty jej otrzymania.
                    </ListItem>
                    <ListItem>
                      Nasza decyzja co do zgłoszonej reklamacji kończy procedurę
                      reklamacyjną, ale nie pozbawia Cię możliwości dochodzenia
                      uprawnień wynikających z bezwzględnie obowiązujących
                      przepisów prawa.
                    </ListItem>
                    <ListItem>
                      Jeśli korzystasz z Serwisu jako konsument, masz możliwość
                      uzyskania bezpłatnej informacji prawnej u miejskich lub
                      powiatowych rzeczników konsumentów. Nie jesteśmy
                      zobowiązani przepisami prawa do korzystania z pozasądowych
                      sposobów rozwiązywania sporów, ani sami nie zobowiązaliśmy
                      się do ich stosowania, z tego względu jeśli złożysz
                      wniosek o pozasądowe rozwiązanie sporu, możemy nie
                      zaakceptować tej propozycji. Nie ogranicza to możliwości
                      dochodzenia przez Ciebie roszczeń przed właściwym sądem.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  VIII. Nasze dane kontaktowe
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      W kwestiach związanych z Serwisem możesz kontaktować się z
                      nami drogą elektroniczną wysyłając wiadomość na adres
                      e-mail:{' '}
                      <SimpleLink href="mailto:hello@mobilitybenefit.pl">
                        hello@mobilitybenefit.pl
                      </SimpleLink>{' '}
                      lub poprzez lub poprzez formularz na stronie Serwisu pod
                      adresem www.carplatform.pl/contact.
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  IX. Postanowienia końcowe
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ol>
                    <ListItem>
                      Zastrzegamy sobie prawo do okresowego zawieszania
                      świadczenia Usługi w Serwisie w stosunku do wszystkich lub
                      niektórych kont w związku z przeprowadzaniem prac
                      konserwacyjnych.
                    </ListItem>
                    <ListItem>
                      Zastrzegamy sobie prawo do zaprzestania świadczenia Usługi
                      bez podania przyczyn po uprzednim poinformowaniu
                      użytkowników uprawnionych do korzystania z Usługi z 30-
                      dniowym wyprzedzeniem.
                    </ListItem>
                    <ListItem>
                      Jeśli jesteś uprawniona/ uprawniony do korzystania z
                      Usługi będziemy przesyłać Ci informacje o zmianach
                      Regulaminu na prywatny adres e-mail, podany przez Ciebie
                      przy zakładaniu konta w Serwisie z 14-dniowym
                      wyprzedzeniem. Zmiany Regulaminu nie wpłyną na prawa
                      nabyte przez Ciebie przed ich dokonaniem.
                    </ListItem>
                    <ListItem>
                      <Paragraph size="small">
                        Możemy zmienić Regulaminu w przypadkach:
                      </Paragraph>
                      <Level2List>
                        <ListItem>
                          zmiany bezwzględnie obowiązujących przepisów;
                        </ListItem>
                        <ListItem>
                          realizacji obowiązków nałożonych na nas przez
                          uprawnione organy administracji;
                        </ListItem>
                        <ListItem>
                          poprawy ochrony prywatności użytkowników Serwisu;
                        </ListItem>
                        <ListItem>
                          w których jest to konieczne w związku z postępem
                          technologicznym;
                        </ListItem>
                        <ListItem>dodania nowych usług.</ListItem>
                      </Level2List>
                    </ListItem>
                    <ListItem>
                      Nie udzielamy dodatkowych gwarancji dotyczących usług
                      świadczonych w ramach Serwisu.
                    </ListItem>
                    <ListItem>
                      Treści publikowane w ramach Serwisu, nazwa Serwisu, nazwy
                      handlowe, wszelkie znaki towarowe oraz oprogramowanie
                      podlegają ochronie prawnej.
                    </ListItem>
                    <ListItem>
                      Niniejszy Regulamin dostępny jest na stronie internetowej
                      www.carplatform.pl/tos
                    </ListItem>
                  </ol>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </section>
          </ScrollContent>
        </FlexScrollContainer>
      </Container>
    </AsideMainMenuLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default TosPage;
