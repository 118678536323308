export interface CompanyConfirmationDTO {
  companyEmail: string;
}

export interface CompanyConfirmationFormModel {
  companyEmail: string;
}

export const mapToCompanyConfirmationDTO = ({
  companyEmail,
}: CompanyConfirmationFormModel): CompanyConfirmationDTO => ({
  companyEmail,
});
