import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { filterConfig } from 'src/app/common/models/filterConfig';
import React, { FC, memo, useCallback } from 'react';
import { useRangeFilterControl } from 'src/app/search/hooks/useFilterControl';
import { RangeFilter } from 'src/app/common/models/filter';
import { Button } from 'src/common/components/Button';
import { rem } from 'polished';
import Bin from 'src/assets/icons/Bin';
import { OneSideRangeSlider } from 'src/app/common/components/OneSideRangeSlider';

const StyledRangeSlider = styled(OneSideRangeSlider)`
  width: 100%;

  ${media.w.min.px1366(css`
    width: 12rem;
    flex: 0 0 12rem;
  `)};

  ${media.w.min.px1600(css`
    width: 14rem;
    flex: 0 0 14rem;
  `)};
`;

const StyledButton = styled(Button)`
  position: absolute;
  left: 0;
  top: -0.5rem;

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const Container = styled.div`
  position: relative;
`;

export const PriceRangeFilter: FC<{
  variant?: string;
}> = memo(({ variant = 'light' }) => {
  const { state, setValue, reset, pristine } = useRangeFilterControl(
    RangeFilter.Price
  );

  const onAfterChange = useCallback(
    (v) => setValue([0, v] as [number, number]),
    [setValue]
  );

  return (
    <Container>
      <StyledRangeSlider
        onAfterChange={onAfterChange}
        value={state.value}
        unit="zł"
        variant={variant}
        reactSliderOptions={{
          min: filterConfig[RangeFilter.Price].min + 500,
          max: filterConfig[RangeFilter.Price].max,
          step: filterConfig[RangeFilter.Price].step,
        }}
      />
      {!pristine && (
        <StyledButton
          kind="box"
          variant="transparent"
          size="small"
          style={{ marginLeft: rem(8), zIndex: 5 }}
          icon={Bin}
          iconProps={{ size: 13 }}
          onClick={reset}
        />
      )}
    </Container>
  );
});
