import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3531 1.05575C27.6384.413748 28.275 0 28.9776 0c2.8761 0 5.6344 1.14253 7.6681 3.17625 2.0338 2.03371 3.1763 4.79203 3.1763 7.66815v10.311h15.3184c1.1303-.0112 2.2495.2226 3.2808.6854 1.0345.4643 1.9557 1.148 2.6998 2.0037.744.8556 1.2932 1.8628 1.6093 2.9518.3162 1.089.3918 2.2337.2216 3.3547l-4.1706 27.1996c0 .0005.0001-.0004 0 0-.2831 1.8647-1.2306 3.5657-2.6677 4.7872-1.4347 1.2195-3.261 1.8805-5.1436 1.8618H7.82218c-2.07457 0-4.06417-.8241-5.53112-2.2911C.82412 60.2416 0 58.252 0 56.1774V35.022c0-2.0746.82412-4.0642 2.29106-5.5311 1.46695-1.4669 3.45655-2.2911 5.53112-2.2911h7.91132L27.3531 1.05575zM14.1103 30.7554H7.82218c-1.13158 0-2.21681.4495-3.01696 1.2497-.80015.8001-1.24966 1.8853-1.24966 3.0169v21.1554c0 1.1316.44951 2.2168 1.24966 3.017.80015.8001 1.88538 1.2497 3.01696 1.2497H13.567l-7.22402-10.836c-.34289-.5144-.39399-1.1699-.13495-1.7312l7.90227-17.1215zm3.7299 29.6887h33.1591c1.029.0116 2.0275-.3489 2.8115-1.0154.7841-.6664 1.3009-1.5938 1.4552-2.6112l4.1706-27.2c.0001-.0003 0 .0003 0 0 .0927-.6112.0515-1.2362-.1208-1.8299-.1725-.5939-.472-1.1433-.8779-1.6101-.4058-.4667-.9083-.8396-1.4726-1.0928-.5642-.2533-1.1768-.3808-1.7953-.3738l-.0201.0002-17.1057-.0001c-.9818 0-1.7778-.796-1.7778-1.7778V10.8444c0-1.93313-.7679-3.78707-2.1348-5.15399-1.1008-1.1008-2.5174-1.81313-4.0369-2.04878L18.5031 29.7227 9.85325 48.4636l7.98695 11.9805z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.7024 36.7987H35.0137v-3.5555h25.6887v3.5555zM59.1998 45.8646H32v-3.5555h27.1998v3.5555zM56.1732 54.9306h-30.222V51.375h30.222v3.5556z"
    />
  </IconBox>
);
