import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { Link } from 'gatsby';
import { RegisterRoute } from 'src/public/publicRoutes';
import { media } from 'src/config/breakpoints';
import { PagesInfographicText } from 'src/common/components/PagesInfographicText';
import { PagesInfographicTitle } from 'src/common/components/PagesInfographicTitle';
import { HowItWorksInfographicContainer } from 'src/public/components/how-it-works/HowItWorksInfographicContainer';
import HowItWorksInfographicSlide1Svg from 'src/assets/images/how-it-works-infographic-slide-1.svg';
import { HowItWorksInfographicCounterWrapper } from 'src/public/components/how-it-works/HowItWorksInfographicCounter';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';

const Box = styled.div`
  display: flex;

  ${media.w.max.px768(css`
    flex-direction: column;
  `)};
`;

const StyledPagesInfographicTitle = styled(PagesInfographicTitle)`
  ${media.w.min.px768(css`
    max-width: 10.625rem;
  `)}
`;

const Content = styled.div`
  max-width: 20.625rem;
  padding-top: 1.25rem;

  ${media.w.min.px768(css`
    padding-top: 2rem;
  `)};
`;

const StyledLink = styled(Link)`
  color: ${colors.primary};
`;

const StyledHowItWorksInfographicSlide1 = styled(
  HowItWorksInfographicSlide1Svg
)`
  margin-top: 1.75rem;

  ${media.w.min.px768(css`
    margin-right: 1.5rem;
  `)};
`;

export const HowItWorksInfographicSlide1 = memo(() => {
  const isLogged = useSelector(isAuthenticatedSelector);
  return (
    <HowItWorksInfographicContainer>
      <Box>
        <HowItWorksInfographicCounterWrapper
          number={1}
          position={{ left: '27%', top: '-2rem' }}
          responsive={{
            position: { left: '24%', top: '-1rem' },
            sendToBack: true,
          }}
        >
          <StyledHowItWorksInfographicSlide1 />
        </HowItWorksInfographicCounterWrapper>

        <Content>
          <StyledPagesInfographicTitle>
            WYBIERASZ SWÓJ WYMARZONY SAMOCHÓD
          </StyledPagesInfographicTitle>
          <PagesInfographicText>
            Wybierasz swoje auto, a&nbsp;nasz doradca przedstawi Ci
            najkorzystniejsze możliwości finansowania
          </PagesInfographicText>
          {!isLogged && (
            <PagesInfographicText>
              <StyledLink to={RegisterRoute}>Załóż konto teraz</StyledLink>
            </PagesInfographicText>
          )}
        </Content>
      </Box>
    </HowItWorksInfographicContainer>
  );
});
