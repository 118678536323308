import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';

export interface UsedProductMetadataPropertyProps {
  label: ReactNode;
}

const Container = styled.div`
  display: grid;
  grid-auto-columns: 6.25rem auto;

  ${media.w.min.px768(css`
    grid-auto-columns: min-content auto;
  `)}

  grid-auto-flow: column;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  align-items: center;
`;

const Label = styled.div`
  text-transform: uppercase;
  margin-right: 0.5rem;
  opacity: 0.5;
  text-align: right;
  white-space: nowrap;
`;

const Content = styled.div`
  height: 2rem;
  border: 1px solid ${rgba(colors.white, 0.2)};
  border-radius: 0.125rem;
  color: ${colors.white};
  padding: 0.125rem 0.5rem;
  line-height: 1.25;
  display: flex;
  align-items: center;
  opacity: 0.8;
  width: min-content;
  white-space: nowrap;
`;

export const UsedProductParameter: FC<UsedProductMetadataPropertyProps> = ({
  label,
  children,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Content>{children}</Content>
    </Container>
  );
};
