import React, { FC, useMemo } from 'react';
import { ProductPageEquipmentProps } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentProps';
import { indexBy, prop } from 'ramda';
import { CategoryEquipmentList } from 'src/app/product/components/ProductPageSectionEquipment/CategoryEquipmentList';
import {
  EquipmentCategoryExpansionPanel,
  EquipmentCategoryExpansionPanelDetails,
  EquipmentCategoryExpansionPanelSummary,
} from 'src/app/product/components/ProductPageSectionEquipment/EquipmentCategoryExpansionPanel';
import styled from 'styled-components';
import { CarSpecificationLink } from 'src/app/product/components/ProductPageSectionEquipment/CarSpecificationLink';
import { ProductPageEquipmentModalLauncher } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentModalLauncher';

const ActionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export type ProductPageEquipmentMobile = ProductPageEquipmentProps;

export const ProductPageEquipmentMobile: FC<ProductPageEquipmentMobile> = ({
  categories,
  product,
  onActiveCategoryChange,
  activeCategory,
  onModalToggle,
}) => {
  const equipmentGroupCategoryLookup = useMemo(
    () => indexBy(prop('id'), product.equipment),
    [product.equipment]
  );

  const hasFullEquipment = (product.fullEquipment ?? []).length > 0;

  return (
    <div>
      {hasFullEquipment && (
        <EquipmentCategoryExpansionPanel>
          <EquipmentCategoryExpansionPanelSummary>
            Pełne wyposażenie
          </EquipmentCategoryExpansionPanelSummary>
          <EquipmentCategoryExpansionPanelDetails>
            <CategoryEquipmentList
              flatEquipmentItems={product?.fullEquipment}
            />
          </EquipmentCategoryExpansionPanelDetails>
        </EquipmentCategoryExpansionPanel>
      )}

      {!hasFullEquipment &&
        categories.map(({ id, label }) => (
          <EquipmentCategoryExpansionPanel key={id}>
            <EquipmentCategoryExpansionPanelSummary>
              {label}
            </EquipmentCategoryExpansionPanelSummary>
            <EquipmentCategoryExpansionPanelDetails>
              <CategoryEquipmentList
                label="Wyposażenie dodatkowe"
                equipmentItems={equipmentGroupCategoryLookup[id]?.options}
              />
              <CategoryEquipmentList
                label="Wyposażenie standardowe"
                flatEquipmentItems={
                  equipmentGroupCategoryLookup[id]?.standardOptions
                }
              />
            </EquipmentCategoryExpansionPanelDetails>
          </EquipmentCategoryExpansionPanel>
        ))}
      <ActionsButtonsContainer>
        <ProductPageEquipmentModalLauncher
          product={product}
          activeCategory={activeCategory}
          categories={categories}
          onActiveCategoryChange={onActiveCategoryChange}
          onModalToggle={onModalToggle}
        />
        {product?.carSpecification && (
          <CarSpecificationLink link={product.carSpecification} />
        )}
      </ActionsButtonsContainer>
    </div>
  );
};
