import { formatNumber } from '@summer/react-kit/functions';
import { appLocale } from 'src/config/locale';

export const localFormatNumber: typeof formatNumber = (
  a: number,
  opts?: {
    locale?: string;
    suffix?: string;
    prefix?: string;
  } & Intl.NumberFormatOptions
) => formatNumber(a, { locale: appLocale, useGrouping: true, ...opts });
