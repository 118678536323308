import {
  ExoticFilter,
  FilterKind,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';

export enum FilterGroupPanel {
  Crossroad = 'crossroad',
  General = 'general',
  Performance = 'performance',
  Appearance = 'appearance',
  Interior = 'interior',
  Equipment = 'equipment',
  Price = 'price',
}

export const groupPanelToLabelMap = {
  [FilterGroupPanel.Crossroad]: 'Filtruj oferty',
  [FilterGroupPanel.General]: 'Marka, model, potrzeba',
  [FilterGroupPanel.Performance]: 'Parametry',
  [FilterGroupPanel.Appearance]: 'Wygląd i wnętrze',
  [FilterGroupPanel.Interior]: 'Wnętrze',
  [FilterGroupPanel.Equipment]: 'Wyposażenie',
  [FilterGroupPanel.Price]: 'Rata',
} as const;

export const filtersByPanel: { [P in FilterGroupPanel]: FilterKind[] } = {
  [FilterGroupPanel.Crossroad]: [],
  [FilterGroupPanel.General]: [
    OptionFilter.Brand,
    ExoticFilter.Model,
    OptionFilter.Category,
    OptionFilter.Body,
  ],
  [FilterGroupPanel.Performance]: [
    OptionFilter.Engine,
    OptionFilter.Gearbox,
    RangeFilter.Power,
    RangeFilter.Capacity,
    RangeFilter.FuelConsumption,
    RangeFilter.Emission,
    RangeFilter.Range,
    OptionFilter.CabinSpace,
    OptionFilter.Drive,
  ],
  [FilterGroupPanel.Appearance]: [
    OptionFilter.PaintColorGroup,
    RangeFilter.RimSize,
    OptionFilter.UpholsteryType,
    OptionFilter.UpholsteryColorGroup,
  ],
  [FilterGroupPanel.Interior]: [
    OptionFilter.UpholsteryType,
    OptionFilter.UpholsteryColorGroup,
  ],
  [FilterGroupPanel.Price]: [RangeFilter.Price],
  [FilterGroupPanel.Equipment]: [OptionFilter.EquipmentItemGroup],
};
