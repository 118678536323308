import React, {
  ComponentType,
  createElement,
  FC,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import ReactSlider from 'react-slider';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { Label } from 'src/app/common/components/typography/Label';
import { equals } from 'ramda';
import { typography } from 'src/config/typography';
import { media } from 'src/config/breakpoints';
import { rgba } from 'polished';

const StyledSlider = styled(ReactSlider)<{ variant: string }>`
  max-width: 100%;
  height: 1.75rem;

  --slider-height: 1.75rem;
  --thumb-width: 3rem;
  --thumb-height: 1.75rem;
  --track-height: 0.063rem;

  ${media.w.min.px992(css`
    padding: 0;
    --slider-height: 1.75rem;
    --thumb-size: 0.6875rem;
    --track-height: 0.063rem;
  `)};
`;

const StyledThumb = styled.div`
  position: absolute;
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: 2px;
  transition: all 150ms;
`;

const ThumbLabelContainer = styled.span`
  position: absolute;
  color: ${colors.lightGray};
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  cursor: grab;
  display: inline-flex;
  flex-direction: column;
`;

export const RangeSliderBasicThumbLabel = styled.span`
  width: 3rem;
  text-align: center;
  justify-content: center;
  display: flex;
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.75rem;
`;

const StyledTrack = styled.div<{ index: number; variant: string }>`
  height: var(--slider-height);
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: var(--track-height);
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    background: ${colors.grayBasic};
    border-radius: 999px;

    ${(props) => css`
      background: ${props.variant === 'dark'
        ? colors.grayBasic
        : rgba(255, 255, 255, 0.5)};
    `}
  }
`;

const ThumbWrapper = styled.div<{ variant: string }>`
  cursor: grab;
  height: var(--thumb-height);
  width: var(--thumb-width);

  &:hover {
    ${StyledThumb} {
      background-color: ${colors.grayBasic};
    }
  }

  &:active {
    ${StyledThumb} {
      background-color: ${colors.primary};
    }
  }

  &.active {
    outline: none;

    ${StyledThumb} {
      background-color: white;
      border: 1px solid ${colors.white};
    }

    ${ThumbLabelContainer} {
      color: ${colors.black};
    }
  }

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      ${StyledThumb} {
        background-color: ${colors.background};
        border: 1px solid ${colors.grayBasic};
      }
    `}
  ${({ variant }) =>
    variant === 'light' &&
    css`
      ${StyledThumb} {
        background-color: ${colors.lightGray};
        border: none;
      }

      ${ThumbLabelContainer} {
        color: ${colors.background};
      }
    `}
`;

const ThumbFactory = (
  thumbLabelComponent?: ComponentType<{ value: number }>,
  unit?: string,
  roundValue?: boolean,
  variant = 'dark'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (props: any, state: any) => (
  <ThumbWrapper
    variant={variant}
    {...props}
    style={{ ...props.style, willChange: undefined }}
  >
    <StyledThumb />
    <ThumbLabelContainer>
      {thumbLabelComponent ? (
        createElement(thumbLabelComponent, { value: state.valueNow })
      ) : (
        <RangeSliderBasicThumbLabel>
          {roundValue ? state.valueNow / 1000 : state.valueNow} {unit}
        </RangeSliderBasicThumbLabel>
      )}
    </ThumbLabelContainer>
  </ThumbWrapper>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Track = (variant: string) => (props: any, state: any) => (
  <StyledTrack {...props} variant={variant} index={state.index} />
);

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: 'prefix slider suffix';
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.25rem;
  grid-template-columns: min-content 1fr min-content;

  ${media.w.min.px992(css`
    grid-template-areas: 'prefix slider suffix';
    align-items: center;
    grid-column-gap: 0.75rem;
  `)};
`;

const Prefix = styled(Label)`
  grid-area: prefix;
  margin: 0;
`;

const Suffix = styled(Label)`
  grid-area: suffix;
  margin: 0;
`;

const Slider = styled.div<{ variant?: string }>`
  grid-area: slider;
  height: 1.75rem;
  position: relative;
  padding: 0 1.375rem;

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      &::before {
        left: 0;
        background: linear-gradient(
          90deg,
          rgba(147, 150, 156, 0) 0%,
          rgba(147, 150, 156, 0.2) 18%,
          rgba(147, 150, 156, 0.65) 55%,
          rgba(147, 150, 156, 1) 100%
        );
      }
      &::after {
        right: 0;
        background: linear-gradient(
          270deg,
          rgba(147, 150, 156, 0) 0%,
          rgba(147, 150, 156, 0.2) 18%,
          rgba(147, 150, 156, 0.65) 55%,
          rgba(147, 150, 156, 1) 100%
        );
      }
    `}
  ${({ variant }) =>
    variant === 'light' &&
    css`
      &::before {
        left: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 18%,
          rgba(255, 255, 255, 0.25) 41%,
          rgba(255, 255, 255, 0.45) 67%,
          rgba(255, 255, 255, 0.5) 100%
        );
      }
      &::after {
        right: 0;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 18%,
          rgba(255, 255, 255, 0.25) 41%,
          rgba(255, 255, 255, 0.45) 67%,
          rgba(255, 255, 255, 0.5) 100%
        );
      }
    `}

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.375rem;
    height: 0.063rem;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

// workaround for https://github.com/zillow/react-slider/issues/181
const sanitizeValue = (v: ReactSlider.ReactSliderProps['value']) =>
  Array.isArray(v) ? v.slice() : v;

export interface RangeSliderProps {
  onAfterChange?: ReactSlider.ReactSliderProps['onAfterChange'];
  value?: ReactSlider.ReactSliderProps['value'];
  prefix?: ReactNode;
  unit?: string;
  roundValue?: boolean;
  variant?: string;
  suffix?: ReactNode;
  reactSliderOptions?: Omit<
    ReactSlider.ReactSliderProps,
    'value' | 'onAfterChange'
  >;
  thumbLabelComponent?: ComponentType<{ value: number }>;
  className?: string;
}

export const RangeSlider: FC<RangeSliderProps> = ({
  onAfterChange,
  value,
  roundValue,
  unit,
  variant = 'dark',
  prefix,
  suffix,
  reactSliderOptions,
  thumbLabelComponent,
  className,
}) => {
  // workaround for https://github.com/zillow/react-slider/issues/180
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>(null);
  const onSliderClick = useCallback(() => {
    if (
      onAfterChange != null &&
      sliderRef.current != null &&
      typeof sliderRef.current.getValue === 'function'
    ) {
      setTimeout(() => {
        // noinspection TypeScriptValidateJSTypes
        const v = sliderRef.current.getValue();
        if (!equals(v, value)) {
          onAfterChange(sanitizeValue(v));
        }
      });
    }
  }, [onAfterChange, value]);

  const onAfterChangeInternal = useCallback(
    (v: ReactSlider.ReactSliderProps['value']) =>
      onAfterChange && onAfterChange(sanitizeValue(v)),
    [onAfterChange]
  );

  return (
    <Container>
      {typeof prefix === 'string' && <Prefix>{prefix}</Prefix>}
      {prefix != null && typeof prefix !== 'string' && prefix}
      <Slider variant={variant}>
        <StyledSlider
          ref={sliderRef}
          variant={variant}
          className={className}
          renderTrack={Track(variant)}
          renderThumb={ThumbFactory(
            thumbLabelComponent,
            unit,
            roundValue,
            variant
          )}
          {...reactSliderOptions}
          value={sanitizeValue(value)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Ignoring because of stupid types on react-slider
          onAfterChange={onAfterChangeInternal}
          onSliderClick={onSliderClick}
        />
      </Slider>
      {typeof suffix === 'string' && <Suffix>{suffix}</Suffix>}
      {suffix != null && typeof suffix !== 'string' && suffix}
    </Container>
  );
};
