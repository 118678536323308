import { useMediaQuery } from 'react-responsive';
import { below, widthBreakpoints } from 'src/config/breakpoints';
import { useMemo } from 'react';

export const useResponsivePageSize = (mobile: number, desktop: number) => {
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });
  return useMemo(() => (isMobile ? mobile : desktop), [
    isMobile,
    mobile,
    desktop,
  ]);
};
