import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.9609 52.1194c.1907-.7292.3563-1.4685.4959-2.217.0491-.2636.0951-.5284.1377-.7942C63.8614 47.4448 64 45.7386 64 44c0-17.6731-14.3269-32-32-32C14.3269 12 0 26.3269 0 44c0 2.8054.361014 5.5265 1.03912 8.1194l4.69222-.8432 1.19966-.0547c-.33474-.9855-.60287-2.0019-.79903-3.0437-.25497-1.3541-.38834-2.7511-.38834-4.1792 0-12.3976 10.05017-22.4478 22.44777-22.4478 12.3976 0 22.4478 10.0502 22.4478 22.4478 0 .7382-.0357 1.4682-.1053 2.1882-.0853.8818-.2217 1.7487-.406 2.5977-.0345.159-.0708.3174-.1087.4751l.1301-.0059 12.8116 2.8657zM54.6353 25.993C49.3361 19.3407 41.166 15.0769 32 15.0769c-11.5976 0-21.6009 6.826-26.21146 16.6796C10.1231 23.8412 18.5304 18.4739 28.1914 18.4739c8.9542 0 16.8315 4.6107 21.3873 11.5874l5.0566-4.0683zm-.1916 19.5808s.7542-7.043-2.6442-13.3501l3.5778-2.8785c.5442.6808 1.1256 1.5528 1.6874 2.5915 1.5027 2.7783 2.8652 6.7494 3.0024 11.4453.0293 1.003.0027 2.039-.0904 3.1036l-5.533-.9118z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0867 46.8738c0 1.2022.9746 2.1768 2.1768 2.1768.8072 0 1.516-.4379 1.8951-1.1038.7489-1.3159 1.5587-2.5166 2.4225-3.6304-1.4149.2404-2.8558.3806-4.3176.3806-1.2022 0-2.1768.9746-2.1768 2.1768zm10.2237-6.6206c-2.6281.8112-5.2919 1.3669-8.0469 1.3669-2.9016 0-5.2537 2.3522-5.2537 5.2537 0 2.9016 2.3521 5.2538 5.2537 5.2538 1.9578 0 3.6654-1.0709 4.5692-2.6588 1.4453-2.5395 3.1409-4.6085 5.0859-6.4976 2.1954-2.1323 4.7087-4.0355 7.5389-6.1274-.8637.3293-1.7242.669-2.5836 1.0082-2.1845.8622-4.3615 1.7214-6.5635 2.4012z"
    />
  </IconBox>
);
