import React, { FC } from 'react';
import { ProductPageEquipmentProps } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentProps';
import { useMediaQuery } from 'react-responsive';
import { below, widthBreakpoints } from 'src/config/breakpoints';
import { ProductPageEquipmentDesktop } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentDesktop';
import { ProductPageEquipmentMobile } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentMobile';

export const ProductPageEquipment: FC<ProductPageEquipmentProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });

  return (
    <>
      {!isMobile && <ProductPageEquipmentDesktop {...props} />}
      {isMobile && <ProductPageEquipmentMobile {...props} />}
    </>
  );
};
