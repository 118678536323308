import React, { FC } from 'react';
import { B2BB2CSwitch } from 'src/common/components/B2BB2CSwitch';
import styled from 'styled-components';
import { colors } from 'src/config/colors';

const SubTitle = styled.div`
  color: ${colors.lightGray};
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-bottom: 0.375rem;
`;

const Title = styled.div`
  color: ${colors.lightGray};
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
`;

export const MainMenuB2BB2CSwitch: FC = () => (
  <>
    <Title>Jaka jest twoja forma zatrudnienia?</Title>
    <SubTitle>Przedstawimy odpowiednie propozycje</SubTitle>
    <B2BB2CSwitch
      variant="secondary"
      labels={['Umowa o pracę', 'Działaność gospodarcza']}
    />
  </>
);
