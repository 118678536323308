import React, { memo, useCallback, useMemo } from 'react';
import { ExoticFilter, OptionFilter } from 'src/app/common/models/filter';
import {
  useFilterControl,
  useOptionFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { ToggleOffTagButton } from 'src/app/search/components/RequestProductSection/ToggleOffTagButton';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { dissoc } from 'ramda';

export const SearchModelFilterTags = memo(() => {
  const { state: brandFilterState } = useOptionFilterControl(
    OptionFilter.Brand
  );

  const brands = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: a.label,
      options: a.models.map(({ id, label }) => ({
        id,
        label,
      })),
    }))
  );

  const visibleBrands = useMemo(
    () =>
      (brands ?? [])
        .filter(
          (a) =>
            brandFilterState.value.includes(a.id) ||
            brandFilterState.value.length === 0
        )
        .filter((a) => a.options.length > 0),
    [brandFilterState.value, brands]
  );

  const { state, setValue } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );

  const onChangeHandler = useCallback(
    (brand: string, value: string[]) => {
      value.length > 0
        ? setValue({
            ...state.value,
            [brand]: value,
          })
        : setValue(dissoc(brand, state.value));
    },
    [setValue, state.value]
  );

  return (
    <>
      {(visibleBrands ?? []).map((brand) => (
        <ToggleButtonGroup
          key={brand.id}
          items={brand.options ?? []}
          value={state.value?.[brand.id] ?? []}
          onChange={(value) => onChangeHandler(brand.id, value)}
          toggleButtonComponent={ToggleOffTagButton}
        />
      ))}
    </>
  );
});
