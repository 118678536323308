import React, { memo, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { ProductPageSectionHeading } from 'src/app/product/components/ProductPageSectionHeading';
import { ProductPageSection } from 'src/app/product/models/productPageSection';
import { ToggleButtonGroupItemOptions } from 'src/common/components/ToggleButtonGroup';
import { below, widthBreakpoints } from 'src/config/breakpoints';
import { EquipmentGroup, Product } from 'src/app/common/models/product';
import { ProductPageEquipment } from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipment';
import { ProductSectionContainer } from 'src/app/product/components/ProductSectionContainer';

const Heading = styled.div`
  margin-bottom: 2.875rem;
`;

export interface ProductPageSectionEquipmentProps {
  product: Product;
  section: ProductPageSection;
  onModalToggle: (modalOpen: boolean) => void;
}

export const ProductPageSectionEquipment = memo<ProductPageSectionEquipmentProps>(
  ({ product, section, onModalToggle }) => {
    const isMobile = useMediaQuery({
      maxWidth: below(widthBreakpoints.px768),
    });
    const [activeCategory, setActiveCategory] = useState<
      EquipmentGroup | undefined
    >();
    const [categories, setCategories] = useState<
      ToggleButtonGroupItemOptions[]
    >([]);

    const handleActiveCategoryChange = useCallback(
      ([id]: string[]) => {
        if (id && product && product.equipment) {
          setActiveCategory(product.equipment.find((group) => group.id === id));
        }
      },
      [product]
    );

    useEffect(() => {
      if (!product || product.equipment.length === 0) {
        return;
      }

      setCategories(product.equipment);
      setActiveCategory(product.equipment[0]);
    }, [product, isMobile]);

    return (
      <ProductSectionContainer>
        <Heading>
          <ProductPageSectionHeading
            icon={section.icon}
            subtitle={section.subtitle}
            title={section.title}
          />
        </Heading>

        <ProductPageEquipment
          activeCategory={activeCategory}
          onActiveCategoryChange={handleActiveCategoryChange}
          categories={categories}
          product={product}
          onModalToggle={onModalToggle}
        />
      </ProductSectionContainer>
    );
  }
);
