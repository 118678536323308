import { CustomerType } from 'src/common/models/customerType';
import { Badge } from 'src/app/common/models/badge';
import { GuestUserDTO } from 'src/common/models/guestUser';

export interface Artefakta4DekraExternal extends ArtefaktaExternal {
  type: 'artefakta4dekra';
}

export interface Artefakta4InternalExternal extends ArtefaktaExternal {
  type: 'artefakta4internal';
}

export interface ArtefaktaExternal {
  priceB2B: number;
  priceB2C: number;
  details: {
    down?: number;
    month?: number;
    purchase?: number;
    product?: string;
    mileage?: number;
  };
}

export enum FinancingType {
  Rent = 'rent',
  Leasing = 'leasing',
  Loan = 'loan',
}

export enum PackageType {
  Basic = 'basic',
  Premium = 'premium',
}

export interface Offer {
  id: string;
  price: number;
  fullPrice?: number;
  annualMileage?: number;
  customerType: CustomerType;
  contractPeriod: number;
  financingType: FinancingType;
  initialPayment: number;
  listPrice: number;
  buyoutPrice: number;
  badges: Badge[];
  packageType: PackageType | null;
  external: undefined | Artefakta4DekraExternal | Artefakta4InternalExternal;
}

export interface GuestUserOffer {
  user: GuestUserDTO;
  offer: Offer;
}

export interface Artefakta4DekraOffer extends Offer {
  external: Artefakta4DekraExternal;
}

export interface Artefakta4InternalOffer extends Offer {
  external: Artefakta4InternalExternal;
}

export const isInternalOffer = (a: Offer) =>
  a.external == null || a.external.type === 'artefakta4internal';

export const isExternalOffer = (a: Offer) => !isInternalOffer(a);

export const isArtefakta4DekraOffer = (a: Offer): a is Artefakta4DekraOffer =>
  a.external?.type === 'artefakta4dekra';
