import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 8 64 48" {...props}>
    <path d="M25.5212 9.0634C22.0037 9.42581 18.6142 10.2572 17.1645 11.1312C15.9068 11.8774 14.7769 14.4782 14.7556 16.6526C14.7556 17.2921 14.8409 17.4627 15.1393 17.4627C17.9107 17.4627 33.622 19.9142 45.1763 22.1526C46.967 22.515 48.4806 22.7922 48.5446 22.7922C48.6085 22.7922 48.6512 22.1953 48.6085 21.4705L48.5446 20.1274L47.0523 19.8503C46.2422 19.7011 42.2131 19.0189 38.1201 18.3154C30.6588 17.0576 29.465 16.7379 30.3177 16.1836C30.7441 15.9065 35.7325 15.9704 40.124 16.3115C42.0639 16.4607 44.5155 16.6313 45.5601 16.7165C46.626 16.7805 47.7345 16.8871 48.0543 16.951L48.6512 17.0576L48.5019 13.6894C48.438 11.8347 48.374 10.2998 48.3527 10.2785C48.2674 10.1719 45.0697 9.76689 41.936 9.44713C38.717 9.14867 27.3545 8.87154 25.5212 9.0634Z" />
    <path d="M14.6489 19.5305C14.6916 19.7437 14.7342 20.1274 14.7342 20.3832C14.7555 20.7456 14.9261 20.8522 15.5656 20.9588C22.3874 22.0034 29.2944 23.3464 41.1685 25.9046C45.1976 26.7786 48.5445 27.4821 48.5871 27.4821C48.6085 27.4821 48.6511 26.9492 48.6511 26.3096V25.1585L47.8623 24.9453C46.8391 24.6681 42.5755 23.8367 38.7169 23.1333C33.5153 22.1953 15.1606 19.1681 14.6276 19.1681C14.6063 19.1681 14.6063 19.3387 14.6489 19.5305Z" />
    <path d="M14.7556 23.5383C14.7556 24.1352 14.8409 24.3058 15.2459 24.3697C15.5017 24.4337 16.9087 24.7322 18.3797 25.0306C21.215 25.6062 32.1511 28.3562 33.153 28.7399C33.4728 28.8678 33.7925 29.1236 33.8778 29.3155C34.155 30.0403 33.6433 30.1043 29.7421 29.7419C25.4572 29.3795 24.5192 29.2942 19.1258 28.8892C17.0153 28.7186 15.2246 28.6333 15.1393 28.676C14.9262 28.8252 14.9262 37.3097 15.1393 37.6508C15.2246 37.8001 16.7382 38.0346 19.0192 38.2264C21.0657 38.4183 23.3254 38.6101 24.0289 38.6741C26.4379 38.8873 35.967 38.9512 38.3972 38.7594C43.4709 38.3543 46.2849 37.5869 47.3508 36.2865C48.1609 35.3059 48.6512 33.4938 48.6512 31.4899V29.8911L46.157 29.1237C40.998 27.5248 30.8933 25.393 18.4863 23.3252C16.7382 23.0267 15.1607 22.7922 15.0327 22.7922C14.8622 22.7922 14.7556 23.0907 14.7556 23.5383Z" />
    <path d="M1.53838 47.3292C0.0461199 47.9261 -0.465511 49.5889 0.472479 50.7827C1.02675 51.4649 1.28256 51.5288 5.90856 51.6994C8.01904 51.7633 9.91634 51.9126 10.1295 52.0192C10.5559 52.2537 10.6198 52.9571 10.2361 53.1916C10.0869 53.2982 7.76322 53.3622 5.05584 53.3622C0.515115 53.3835 0.15271 53.4048 0.174028 53.7459C0.259299 54.8544 -0.209696 54.7905 6.33492 54.7265L12.4105 54.6626L12.9648 54.087C13.4125 53.6606 13.5404 53.2982 13.5617 52.5521C13.6043 50.6548 12.645 50.335 6.82523 50.3137C5.46088 50.2924 4.09653 50.2071 3.8194 50.1005C3.15854 49.8447 2.92404 49.2478 3.32909 48.8215C3.60622 48.5656 4.50157 48.5017 8.40276 48.4804C11.0249 48.4591 13.2206 48.4164 13.3272 48.4164C13.4125 48.3951 13.4764 48.0753 13.4764 47.7342V47.0947L7.78454 47.116C4.52289 47.116 1.83683 47.2226 1.53838 47.3292Z" />
    <path d="M16.6742 54.7691H30.1045V54.1509C30.1045 53.7885 29.9766 53.49 29.7635 53.4048C29.5929 53.3408 27.3119 53.2982 24.7111 53.2982L19.9785 53.3408L19.9146 52.4454L19.8506 51.5714H29.465V50.2923H19.8506L19.9146 49.3757L19.9785 48.4803L24.8816 48.4377C27.589 48.4164 29.8487 48.395 29.9553 48.395C30.0406 48.3737 30.1045 48.0753 30.1045 47.7342V47.0946H16.6742V54.7691Z" />
    <path d="M34.9011 47.4144C33.6007 48.0753 33.5154 48.3311 33.5154 51.6993V54.7691H34.7945C35.498 54.7691 36.0949 54.7052 36.0949 54.5986C36.1162 54.5133 36.1375 53.9164 36.1588 53.2769C36.2015 51.8912 35.7538 52.0191 40.593 51.9551C42.8527 51.9125 44.1957 51.9765 44.2383 52.1044C44.281 52.211 44.3023 52.8718 44.281 53.5327V54.7691H46.9457V47.0946H41.2538C36.5639 47.0946 35.434 47.1586 34.9011 47.4144ZM44.1957 48.9493C44.217 49.1625 44.2597 49.5888 44.3023 49.9086L44.3663 50.5055H36.0949L36.3081 49.802C36.6278 48.6509 36.8837 48.5869 40.6996 48.5869C43.8546 48.5869 44.1744 48.6295 44.1957 48.9493Z" />
    <path d="M50.3566 48.8001H55.6861V54.7904L58.564 54.6625L58.5853 51.7846L58.628 48.9067L64.0001 48.7788V47.0947H50.3566V48.8001Z" />
  </IconBox>
);
