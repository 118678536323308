import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { pageContextActions } from 'src/state/pageContext/pageContextActions';
import { useLocation } from '@reach/router';

export const useStorePageUrl = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      pageContextActions.setPageUrl({
        url: location.href,
      })
    );
  }, []);
};
