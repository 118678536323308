import React, { useRef } from 'react';
import { AsideMainMenuLayout } from 'src/common/components/AsideMainManuLayout';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled, { css } from 'styled-components';
import { CleanUnorderedList } from 'src/common/components/List';
import { colors } from 'src/config/colors';
import { ContactDepartmentInfo } from 'src/public/components/contact/ContactDepartmentInfo';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import { contactDepartments } from 'src/public/data/contactDepartments';
import { ContactForm } from 'src/public/components/contact/ContactForm';
import { media } from 'src/config/breakpoints';
import { ContactFormSSRPlaceholder } from 'src/public/components/contact/ContactFormSSRPlaceholder';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.white};
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 'form' 'info';
  grid-row-gap: 4vh;

  ${media.w.min.px768(css`
    grid-template-areas: 'form info';
    grid-template-columns: minmax(min-content, 25rem) minmax(min-content, 18rem);
    justify-content: space-between;
    grid-column-gap: 3rem;
    grid-row-gap: 0;
  `)}
`;

const FormContainer = styled.div`
  grid-area: form;
`;

const ContactDepartmentSupportInfoList = styled(CleanUnorderedList)`
  grid-area: info;
`;

const ContactDepartmentSupportInfoListItem = styled.li`
  margin-bottom: 3vh;
`;

const ContactPage = () => {
  useStorePageUrl();

  const scrollRef = useRef<OverlayScrollbarsComponent>(null);

  return (
    <AsideMainMenuLayout>
      <Seo title="Kontakt" />
      <Container>
        <Title level={1} variant="condensed">
          Pomoc i kontakt
        </Title>
        <FlexScrollContainer ref={scrollRef}>
          <GridLayout>
            <FormContainer>
              {isPlatformBrowser() ? (
                <ContactForm
                  onSuccess={() => scrollRef.current?.osInstance()?.scroll(0)}
                />
              ) : (
                <ContactFormSSRPlaceholder />
              )}
            </FormContainer>
            <ContactDepartmentSupportInfoList>
              {contactDepartments.map((props, index) => (
                <ContactDepartmentSupportInfoListItem key={index}>
                  <ContactDepartmentInfo {...props} />
                </ContactDepartmentSupportInfoListItem>
              ))}
            </ContactDepartmentSupportInfoList>
          </GridLayout>
        </FlexScrollContainer>
      </Container>
    </AsideMainMenuLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default ContactPage;
