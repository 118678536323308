import React, { memo, useMemo } from 'react';
import { FilterStateKindMap, OptionFilter } from 'src/app/common/models/filter';
import { FilterStatusSpan } from 'src/app/search/components/FilterStatusInfo/FilterStatusSpan';
import { FilterStatusList } from 'src/app/search/components/FilterStatusInfo/FilterStatusList';
import { flatten } from 'ramda';
import { rem } from 'polished';
import { StyleProps } from '@summer/react-kit';
import { FilterStatusItem } from 'src/app/search/components/FilterStatusInfo/FilterStatusItem';
import styled from 'styled-components';

export interface EquipmentFilterStatusListProps extends StyleProps {
  state?: FilterStateKindMap[OptionFilter.EquipmentItemGroup];
  length: number;
}

const StyledFilterStatusList = styled(FilterStatusList)`
  min-width: 4.5rem;
  padding-right: 0.25rem;
  max-width: 15.75rem;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  white-space: nowrap;
`;

export const EquipmentFilterStatusList = memo<EquipmentFilterStatusListProps>(
  ({ state, length, className, style }) => {
    const value = state?.value ?? [];

    const options = useMemo(
      () => flatten(state?.groups?.map((_) => _.options) ?? []),
      [state?.groups]
    );

    const activeOptions = useMemo(
      () => value.slice(0, length).map((a) => options.find((b) => a === b.id)),
      [state?.value, options, length]
    );

    return (
      <StyledFilterStatusList className={className} style={style}>
        {value.length > 0 &&
          activeOptions.map(
            (_) =>
              _ && (
                <FilterStatusItem key={_?.id}>
                  <FilterStatusSpan>{_?.label}</FilterStatusSpan>
                </FilterStatusItem>
              )
          )}
      </StyledFilterStatusList>
    );
  }
);
