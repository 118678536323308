import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 14 64 36" {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M31.9968 48.5238C49.6705 48.5238 64 41.0153 64 31.7685 64 22.5085 49.6705 15 31.9968 15 14.3295 15 0 22.5085 0 31.7685c0 9.2468 14.3295 16.7553 31.9968 16.7553zm6.0267-8.2248c-.5346 1.26-1.4529 3.9664-3.5606 5.0412-.6419.3315-1.4321.4777-2.135.5041h-.3242c-5.9046 0-11.4047-.8356-16.0053-2.2683-.0338-.0132-.1415-.0533-.1752-.0797-.4255-.1325-.6419-.3447-.6419-.5838 0-.2122.1157-.3848.2631-.5305l.2904-.2656c1.0678-.9021 4.2636-3.2765 10.2903-5.6382 2.1142-.8224 4.762-1.8571 7.5323-2.4541 1.6213-.3447 7.648-1.2869 4.4661 6.2749zm16.1662-16.901c.1157-.199.2631-.3717.5411-.3849.1479-.0132.3514.0264.6617.2255 3.7567 2.3612 5.9865 5.3199 5.9865 8.5167 0 5.7708-7.2628 10.7455-17.6543 12.9214-.6617.1325-1.108.1193-1.2559-.0396-.1092-.1062-.1351-.292 0-.5306.0595-.1061.135-.2122.2765-.3848 5.6554-6.8186 9.9646-16.8878 11.1878-19.7932.0948-.199.1826-.398.2566-.5305zm-28.1184-.2122c.5346-1.2601 1.4529-3.9665 3.5606-5.0281.6419-.3315 1.4321-.4909 2.135-.5041.1624-.0132.2765 0 .3177 0 5.9111 0 11.4033.8357 16.0108 2.2683l.1763.0665c.4254.1457.6408.3447.6408.597 0 .2122-.1142.3717-.2621.5306-.0685.0665-.1633.1457-.2919.2518-1.0598.9153-4.2625 3.2765-10.2888 5.6382-2.1206.8356-4.7629 1.8571-7.5259 2.4541-1.6277.3584-7.6544 1.3007-4.4725-6.2743zm-5.8034-4.3386c.6691-.1325 1.108-.1325 1.2698.0396.0948.1061.1221.2919-.004.5173-.0685.1193-.1425.2254-.2775.3848-5.6544 6.8323-9.9647 16.8746-11.1942 19.7932-.08839.199-.1827.398-.25666.5305-.10921.1991-.2631.3717-.53462.3849-.14793.0132-.35791-.0264-.66816-.2254-3.75673-2.3612-5.98655-5.32-5.98655-8.5168 0-5.7707 7.2618-10.7454 17.65189-12.9081z"
    />
  </IconBox>
);
