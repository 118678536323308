import {
  ExoticFilter,
  FilterKind,
  FilterStateKindMap,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';
import { SearchState } from 'src/app/search/state';
import { createSearchFilterStateSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { AnyAction } from 'redux';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { isFilterPristine } from 'src/app/common/models/filterConfig';

export const useFilterControl = <TKind extends FilterKind>(
  kind: TKind,
  setValueActionCreator: (payload: {
    kind: TKind;
    value: FilterStateKindMap[TKind]['value'];
  }) => AnyAction
) => {
  const dispatch = useDispatch();
  const stateSelector = useMemo(() => createSearchFilterStateSelector(), []);

  const state = useSelector((state: SearchState) => stateSelector(state, kind));
  const pristine = useMemo(() => isFilterPristine(kind, state.value), [
    state.value,
  ]);

  const setValue = useCallback(
    (value: FilterStateKindMap[TKind]['value']) => {
      dispatch(setValueActionCreator({ kind, value }));
    },
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(searchFiltersActions.resetFilterValue({ kind })),
    [dispatch]
  );

  return {
    state: state as FilterStateKindMap[TKind],
    pristine,
    setValue,
    reset,
  } as const;
};

export const useOptionFilterControl = <TKind extends OptionFilter>(
  kind: TKind
) => useFilterControl(kind, searchFiltersActions.setOptionFilterValue);

export const useRangeFilterControl = <TKind extends RangeFilter>(kind: TKind) =>
  useFilterControl(kind, searchFiltersActions.setRangeFilterValue);

export const useModelFilterControl = () =>
  useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );

export const useNewProductOffersPaginationFilterControl = () =>
  useFilterControl(
    ExoticFilter.NewProductOffersPagination,
    searchFiltersActions.setNewProductOffersPaginationFilterValue
  );

export const useUsedProductOffersPaginationFilterControl = () =>
  useFilterControl(
    ExoticFilter.UsedProductOffersPagination,
    searchFiltersActions.setUsedProductOffersPaginationFilterValue
  );
