import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  useFilterControl,
  useOptionFilterControl,
  useRangeFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import {
  ExoticFilter,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { Box, Typography } from '@mui/material';
import { IconButton } from '@material-ui/core';
import {
  brandsListSelector,
  modelsLookupSelector,
} from 'src/app/state/commonFilters/commonFiltersSelectors';
import CloseIcon from '@mui/icons-material/Close';
import { MOTORCYCLE_ID } from 'src/common/constants';

export const SelectedOptions = () => {
  //Brand
  const { state: brandState, setValue: setBrandValue } = useOptionFilterControl(
    OptionFilter.Brand
  );

  const handleRemoveBrand = (valueToRemove: string) => {
    const newValue = brandState.value.filter(
      (value) => value !== valueToRemove
    );
    setBrandValue(newValue);

    const newModelStateValue = { ...modelState.value };
    delete newModelStateValue[valueToRemove];
    setModelValue(newModelStateValue);
  };

  //Model
  const { state: modelState, setValue: setModelValue } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );

  const handleRemoveModel = (valueToRemove: string) => {
    const updatedObject: {
      [key: string]: string[];
    } = {};

    for (const key in modelState.value) {
      if (modelState.value.hasOwnProperty(key)) {
        const filteredValues = modelState.value[key].filter(
          (value) => value !== valueToRemove
        );
        if (filteredValues.length > 0) {
          updatedObject[key] = filteredValues;
        }
      }
    }
    setModelValue(updatedObject);
  };

  //Body
  const { state: bodyState, setValue: setBodyValue } = useOptionFilterControl(
    OptionFilter.Body
  );

  const handleRemoveBody = (valueToRemove: string) => {
    const newValue = bodyState.value.filter((value) => value !== valueToRemove);
    setBodyValue(newValue);
  };

  //Engine
  const {
    state: engineState,
    setValue: setEngineValue,
  } = useOptionFilterControl(OptionFilter.Engine);

  const handleRemoveEngine = (valueToRemove: string) => {
    const newValue = engineState.value.filter(
      (value) => value !== valueToRemove
    );
    setEngineValue(newValue);
  };

  //Gearbox
  const {
    state: gearboxState,
    setValue: setGearboxValue,
  } = useOptionFilterControl(OptionFilter.Gearbox);

  const handleRemoveGearbox = (valueToRemove: string) => {
    const updatedGearbox = { ...gearboxState };

    updatedGearbox.value = updatedGearbox.value.filter(
      (value) => value !== valueToRemove
    );
    setGearboxValue(updatedGearbox.value);
  };

  //Drive
  const { state: driveState, setValue: setDriveValue } = useOptionFilterControl(
    OptionFilter.Drive
  );

  const handleRemoveDrive = (valueToRemove: string) => {
    const updatedDrive = { ...driveState };

    updatedDrive.value = updatedDrive.value.filter(
      (value) => value !== valueToRemove
    );

    setDriveValue(updatedDrive.value);
  };

  //TrunkSpace
  const {
    state: trunkSpaceState,
    setValue: setTrunkSpaceValue,
  } = useOptionFilterControl(OptionFilter.TrunkSpace);

  const handleRemoveTrunkSpace = (valueToRemove: string) => {
    const updatedTrunkSpace = { ...trunkSpaceState };

    updatedTrunkSpace.value = updatedTrunkSpace.value.filter(
      (value) => value !== valueToRemove
    );

    setTrunkSpaceValue(updatedTrunkSpace.value);
  };

  //CabinSpace
  const {
    state: cabinSpaceState,
    setValue: setCabinSpaceValue,
  } = useOptionFilterControl(OptionFilter.CabinSpace);

  const handleRemoveCabinSpace = (valueToRemove: string) => {
    const updatedCabinSpace = { ...cabinSpaceState };

    updatedCabinSpace.value = updatedCabinSpace.value.filter(
      (value) => value !== valueToRemove
    );

    setCabinSpaceValue(updatedCabinSpace.value);
  };

  //Paint
  const { state: paintState, setValue: setPaintValue } = useOptionFilterControl(
    OptionFilter.PaintColorGroup
  );

  const handleRemovePaint = (valueToRemove: string) => {
    const updatedPaint = { ...paintState };

    updatedPaint.value = updatedPaint.value.filter(
      (value) => value !== valueToRemove
    );
    setPaintValue(updatedPaint.value);
  };

  //Upholstery
  const {
    state: upholsteryState,
    setValue: setUpholsteryValue,
  } = useOptionFilterControl(OptionFilter.UpholsteryType);

  const handleRemoveUpholstery = (valueToRemove: string) => {
    const updatedUpholstery = { ...upholsteryState };

    updatedUpholstery.value = updatedUpholstery.value.filter(
      (value) => value !== valueToRemove
    );
    setUpholsteryValue(updatedUpholstery.value);
  };

  //Upholstery Color
  const {
    state: upholsteryColorState,
    setValue: setUpholsteryColorValue,
  } = useOptionFilterControl(OptionFilter.UpholsteryColorGroup);

  const handleRemoveUpholsteryColor = (valueToRemove: string) => {
    const updatedUpholsteryColor = { ...upholsteryColorState };

    updatedUpholsteryColor.value = updatedUpholsteryColor.value.filter(
      (value) => value !== valueToRemove
    );
    setUpholsteryColorValue(updatedUpholsteryColor.value);
  };

  //Availability
  const {
    state: availabilityState,
    setValue: setAvailabilityValue,
  } = useOptionFilterControl(OptionFilter.Availability);

  const handleRemoveAvailability = (valueToRemove: string) => {
    const newValue = availabilityState.value.filter(
      (value) => value !== valueToRemove
    );
    setAvailabilityValue(newValue);
  };

  //Financing
  const {
    state: financingState,
    setValue: setFinancingValue,
  } = useOptionFilterControl(OptionFilter.FinancingType);

  const handleRemoveFinancing = (valueToRemove: string) => {
    const updatedFinancing = { ...financingState };

    updatedFinancing.value = updatedFinancing.value.filter(
      (value) => value !== valueToRemove
    );
    setFinancingValue(updatedFinancing.value);
  };

  //Price
  const { state: priceState, setValue: setPriceValue } = useRangeFilterControl(
    RangeFilter.Price
  );

  const handleRemoveMaxPrice = () => {
    setPriceValue([0, 100000]);
  };

  const Item: FC<{
    value: string;
    type: string;
    handleRemove: (value: string) => void;
  }> = ({ value, type, handleRemove }) => {
    const brandsList = useSelector(brandsListSelector);
    const modelsList = useSelector(modelsLookupSelector);

    const translateBrand = (value: string) => {
      if (!brandsList) return;
      const brand = brandsList.find((brand) => brand.id === value);
      if (brand) return brand.label;
    };

    const translateModel = (value: string) => {
      if (!modelsList) return;
      const model = modelsList[value];
      return model ? model.label : '';
    };

    const translateBody = (value: string) => {
      return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const translateEngine = (value: string) => {
      switch (value) {
        case 'petrol':
          return 'Benzyna';
        case 'diesel':
          return 'Diesel';
        case 'electric':
          return 'Elektryczny';
        case 'hybrid':
          return 'Hybryda';
        default:
          return value;
      }
    };

    const translateGearbox = (value: string) => {
      switch (value) {
        case 'MT':
          return 'Manualna';
        case 'AT':
          return 'Automatyczna';
        default:
          return value;
      }
    };

    const translateDrive = (value: string) => {
      switch (value) {
        case 'FWD':
          return 'Przedni';
        case 'RWD':
          return 'Tylny';
        case 'AWD':
          return 'Na cztery koła';
        default:
          return value;
      }
    };

    const translateTrunkSpace = (value: string) => {
      switch (value) {
        case 's':
          return '1 duży, 2 podręczne';
        case 'm':
          return '2 duże, 4 podręczne';
        case 'l':
          return '3 duże, 6 podręcznych';
        case 'xl':
          return '4 duże, 8 podręcznych';
        default:
          return value;
      }
    };

    const translatePaint = (value: string) => {
      if (!paintState.options) return;
      const selectedValue = paintState.options.find(
        (color) => color.id === value
      );
      return selectedValue?.label;
    };

    const translateUpholstery = (value: string) => {
      switch (value) {
        case 'leather':
          return 'Skóra';
        case 'material':
          return 'Materiał';
        case 'alcantaraMaterial':
          return 'Alcantara i materiał';
        case 'materialLeather':
          return 'Materiał i skóra';
        case 'alcantaraLeather':
          return 'Alcantara i skóra';
        default:
          return value;
      }
    };

    const translateAvailability = (value: string) => {
      switch (value) {
        case 'available':
          return 'Dostępne od ręki';
        case 'unavailable':
          return 'Do produkcji';
        default:
          return value;
      }
    };

    const translateFinancing = (value: string) => {
      switch (value) {
        case 'rent':
          return 'Najem';
        case 'leasing':
          return 'Leasing';
        default:
          return value;
      }
    };

    const translatePrice = (value: string) => {
      return `Max ${value} zł`;
    };

    const translateUpholsteryColor = (value: string) => {
      if (!upholsteryColorState.options) return;
      const selectedValue = upholsteryColorState.options.find(
        (paint) => paint.id === value
      );

      switch (selectedValue?.label) {
        case 'Czarny':
          return 'Tapicerka czarna';
        case 'Szary':
          return 'Tapicerka szara';
        case 'Beżowy':
          return 'Tapicerka beżowa';
        case 'Brązowy':
          return 'Tapicerka brązowa';
        case 'Niebieski':
          return 'Tapicerka niebieska';
        case 'Czerwony':
          return 'Tapicerka czerwona';
        case 'Inne':
          return 'Tapicerka inna';
        default:
          return selectedValue?.label;
      }
    };

    const translateCabinSpace = (value: string) => {
      switch (value) {
        case 's':
          return 'Kabina S';
        case 'm':
          return 'Kabina M';
        case 'l':
          return 'Kabina L';
        case 'xl':
          return 'Kabina XL';
        default:
          return value;
      }
    };

    const generateLabel = (value: string, type: string) => {
      switch (type) {
        case 'Brand':
          return translateBrand(value);
        case 'Model':
          return translateModel(value);
        case 'Body':
          return translateBody(value);
        case 'Engine':
          return translateEngine(value);
        case 'Gearbox':
          return translateGearbox(value);
        case 'Drive':
          return translateDrive(value);
        case 'Availability':
          return translateAvailability(value);
        case 'Financing':
          return translateFinancing(value);
        case 'Price':
          return translatePrice(value);
        case 'Paint':
          return translatePaint(value);
        case 'Upholstery':
          return translateUpholstery(value);
        case 'UpholsteryColor':
          return translateUpholsteryColor(value);
        case 'TrunkSpace':
          return translateTrunkSpace(value);
        case 'CabinSpace':
          return translateCabinSpace(value);
        default:
          return value;
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#d8d9db',
          color: '#000',
          borderRadius: '0.5rem',
          padding: '0px 0.5rem',
        }}
      >
        <Typography
          sx={{
            fontSize: '0.75rem',
          }}
        >
          {generateLabel(value, type)}
        </Typography>
        <IconButton
          onClick={() => {
            handleRemove(value);
          }}
          style={{
            height: '24px',
            width: '24px',
            padding: '4px',
          }}
        >
          <CloseIcon
            sx={{
              color: '#000',
              fontSize: '1rem',
            }}
          />
        </IconButton>
      </Box>
    );
  };

  const isAnyStateActive = () => {
    const arrayStates = [
      brandState,
      bodyState,
      engineState,
      gearboxState,
      driveState,
      trunkSpaceState,
      cabinSpaceState,
      paintState,
      upholsteryState,
      upholsteryColorState,
      availabilityState,
      financingState,
    ];

    const isAnyArrayStateActive = arrayStates.some(
      (state) => state.value.length > 0
    );

    const isModelStateActive =
      modelState.value &&
      Object.keys(modelState.value).some(
        (key) => modelState.value[key].length > 0
      );

    const isPriceStateActive = priceState.value[1] !== 100000;

    return isAnyArrayStateActive || isModelStateActive || isPriceStateActive;
  };

  const isAnyStateApartFromBrandActive = () => {
    const arrayStates = [
      bodyState,
      engineState,
      gearboxState,
      driveState,
      trunkSpaceState,
      cabinSpaceState,
      paintState,
      upholsteryState,
      upholsteryColorState,
      availabilityState,
      financingState,
    ];

    const isAnyArrayStateActive = arrayStates.some(
      (state) => state.value.length > 0
    );

    const isModelStateActive =
      modelState.value &&
      Object.keys(modelState.value).some(
        (key) => modelState.value[key].length > 0
      );

    const isPriceStateActive = priceState.value[1] !== 100000;

    return isAnyArrayStateActive || isModelStateActive || isPriceStateActive;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.75rem',
        marginTop:
          !isAnyStateApartFromBrandActive() &&
          brandState.value[0] === MOTORCYCLE_ID &&
          brandState.value.length === 1
            ? '2rem'
            : isAnyStateActive()
            ? '0.5rem'
            : '2rem',
      }}
    >
      {brandState.value
        .filter((x) => x !== MOTORCYCLE_ID)
        .map((v) => (
          <Item
            key={v}
            value={v}
            type={'Brand'}
            handleRemove={handleRemoveBrand}
          />
        ))}
      {modelState.value &&
        Object.keys(modelState.value).map((key) => {
          return modelState.value[key].map((value) => (
            <Item
              key={value}
              value={value}
              type={'Model'}
              handleRemove={handleRemoveModel}
            />
          ));
        })}
      {bodyState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Body'}
          handleRemove={handleRemoveBody}
        />
      ))}
      {engineState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Engine'}
          handleRemove={handleRemoveEngine}
        />
      ))}
      {gearboxState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Gearbox'}
          handleRemove={handleRemoveGearbox}
        />
      ))}
      {driveState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Drive'}
          handleRemove={handleRemoveDrive}
        />
      ))}
      {trunkSpaceState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'TrunkSpace'}
          handleRemove={handleRemoveTrunkSpace}
        />
      ))}
      {cabinSpaceState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'CabinSpace'}
          handleRemove={handleRemoveCabinSpace}
        />
      ))}
      {paintState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Paint'}
          handleRemove={handleRemovePaint}
        />
      ))}
      {upholsteryState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Upholstery'}
          handleRemove={handleRemoveUpholstery}
        />
      ))}
      {upholsteryColorState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'UpholsteryColor'}
          handleRemove={handleRemoveUpholsteryColor}
        />
      ))}
      {availabilityState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Availability'}
          handleRemove={handleRemoveAvailability}
        />
      ))}
      {financingState.value.map((value) => (
        <Item
          key={value}
          value={value}
          type={'Financing'}
          handleRemove={handleRemoveFinancing}
        />
      ))}
      {priceState.value[1] !== 100000 && (
        <Item
          key={priceState.value[1]}
          value={priceState.value[1].toString()}
          type={'Price'}
          handleRemove={handleRemoveMaxPrice}
        />
      )}
    </Box>
  );
};
