import React, { memo } from 'react';
import { BadgesList } from 'src/app/common/components/BadgesList';
import { PartialProduct } from 'src/app/common/models/product';
import { uniq } from 'ramda';
import { Offer } from 'src/common/models/offer';
import { BadgeVariantType } from 'src/app/common/components/BadgeContainer';

export interface BadgesBinderProps {
  product?: PartialProduct;
  offer?: Offer;
  width?: number;
  elements: number;
  variant?: BadgeVariantType;
}

export const BadgesBinder = memo<BadgesBinderProps>(
  ({ product, offer, width, elements, variant }) => {
    const badges = uniq(offer?.badges.concat(product?.badges ?? []) ?? []);

    return (
      <BadgesList
        badges={badges}
        width={width}
        elements={elements}
        variant={variant}
      />
    );
  }
);
