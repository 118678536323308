import styled from 'styled-components';
import { colors } from 'src/config/colors';

export const AuthHeader = styled.div`
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  margin-bottom: 1.125rem;
  max-width: 23.125rem;
  position: relative;
`;
