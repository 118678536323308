import { AuthorizedAxios } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { BusinessPartner } from 'src/common/models/businessPartner';
import { evolve } from 'ramda';
import { imageUrlTransformer } from 'src/common/services/image';

export const fetchBusinessPartner = (registrationPath: string) =>
  AuthorizedAxios.get<BusinessPartner>('business-partners', {
    params: { registrationPath },
  })
    .then(flatten)
    .then(evolve({ logo: imageUrlTransformer }));
