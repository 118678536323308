import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Logo } from 'src/common/components/Logo';
import { deceleratedEasing } from 'src/common/styles/animations';
import { media } from 'src/config/breakpoints';
import { LightDarkVariant } from 'src/common/models/lightDarkVariant';
import { AppHeaderNavigation } from 'src/common/components/AppHeaderNavigation';
import { colors } from 'src/config/colors';

const MobileLogo = styled(Logo)`
  height: 3rem;

  #typo,
  #symbol-bottom {
    transition: fill 2000ms ${deceleratedEasing};
  }

  ${media.w.min.px768(css`
    display: none;
  `)}
`;

const StyledAppHeaderNavigation = styled(AppHeaderNavigation)`
  display: none;
  visibility: hidden;
  color: ${colors.white};

  ${media.w.min.px768(css`
    visibility: initial;
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 0.5rem;
  `)};
`;

export interface HomeAdvertHeaderProps {
  variant: LightDarkVariant;
}

export const HomeAdvertHeader: FC<HomeAdvertHeaderProps> = ({ variant }) => {
  return (
    <div>
      <MobileLogo variant={variant} />
      <StyledAppHeaderNavigation />
    </div>
  );
};
