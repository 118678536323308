import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { typography } from 'src/config/typography';
import { Text } from 'src/common/components/typography/Text';
import { media } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { Tooltip } from 'src/common/components/Tooltip';
import Info from 'src/assets/icons/Info';

const InfoButtonTooltip = styled(Tooltip)`
  & .tooltip {
    max-width: 23.75rem;
  }
`;

const InfoButton = styled(Button)`
  &.btn.btn-box {
    font-family: ${typography.fontFamilyDefault};

    ${media.w.max.px576(css`
      & > .btn__content > .btn__label {
        display: none;
      }
    `)}

    ${media.w.min.px576(css`
      & > .btn__content {
        display: flex;
        flex-direction: row-reverse;

        svg {
          margin-right: 0.375rem;
        }
      }
    `)}
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1.625rem auto;
  grid-gap: 1rem;
  grid-template-areas:
    'configuration configuration'
    'icon title'
    'disclaimer disclaimer';

  ${media.w.min.px576(css`
    min-width: 20rem;
    grid-template-areas:
      'icon title'
      '. configuration'
      'disclaimer disclaimer';
  `)}
`;

const StyledTitle = styled(Text)`
  grid-area: title;
  font-size: ${typography.fontSizeSm};
  max-width: 9.5rem;
`;

const Disclaimer = styled.div`
  grid-area: disclaimer;
  padding-top: 1.188rem;
  font-size: 0.8125rem;
`;

export const ProductSummaryOfferDisclaimerTooltip: FC = () => (
  <InfoButtonTooltip
    placement="bottom-end"
    title={
      <>
        <StyledTitle variant="condensed">
          Informacje szczegółowe o&nbsp;ofercie
        </StyledTitle>
        <Disclaimer>
          Informacje prezentowane w podsumowaniu wybranego modelu nie stanowią
          ofert w rozumieniu kodeksu cywilnego, a więc jako takie nie są prawnie
          wiążące. Ostateczna wycena każdorazowo sporządzona jest indywidulanie
          dla każdego wnioskującego w oparciu o spełnione przez niego kryteria.
          Prezentowane zdjęcia są jedynie zdjęciami podglądowymi, a szczegółowa
          specyfikacja zostanie przedstawiona w odpowiedzi na konkretne
          zapytanie ofertowe.
        </Disclaimer>
      </>
    }
  >
    <InfoButton
      kind="box"
      variant="transparent"
      size="small"
      icon={Info}
      iconProps={{ size: 10 }}
    >
      Informacje szczegółowe o ofercie
    </InfoButton>
  </InfoButtonTooltip>
);
