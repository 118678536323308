import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import ChevronDown from 'src/assets/icons/ChevronDown';
import {
  ProductPageEquipmentModal,
  ProductPageEquipmentModalProps,
} from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentModal';
import { ModalPortal } from 'src/app/common/components/ModalPortal';

export type ProductPageEquipmentModalLauncherProps = Omit<
  ProductPageEquipmentModalProps,
  'onClose'
>;

export const ProductPageEquipmentModalLauncher = memo<ProductPageEquipmentModalLauncherProps>(
  (props) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      props.onModalToggle?.(showModal);
    }, [showModal]);

    const onClose = useCallback(() => setShowModal(false), [setShowModal]);

    return (
      <>
        <Button
          kind="box"
          icon={ChevronDown}
          variant="transparent"
          onClick={() => setShowModal(true)}
        >
          pełna lista wyposażenia
        </Button>

        <ModalPortal
          open={showModal}
          onClose={onClose}
          background={colors.background}
          title="Pełna lista wyposażenia"
        >
          <ProductPageEquipmentModal onClose={onClose} {...props} />
        </ModalPortal>
      </>
    );
  }
);
