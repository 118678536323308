import React, { FC } from 'react';
import styled from 'styled-components';
import { Paragraph } from 'src/common/components/typography/Text';
import { Form, Formik } from 'formik';
import { Title } from 'src/common/components/typography/Title';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { useRequest } from 'src/lib/useRequest';
import { Seo } from 'src/public/components/Seo';
import { pipe } from 'fp-ts/lib/pipeable';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { Button } from 'src/common/components/Button';
import { companyConfirmationActions } from 'src/state/companyConfirmation/companyConfirmationActions';
import {
  CompanyConfirmationFormModel,
  mapToCompanyConfirmationDTO,
} from 'src/common/models/companyConfirmation';
import { Link } from 'gatsby';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import ArrowRight from 'src/assets/icons/ArrowRight';

const InfoParagraph = styled(Paragraph)`
  margin-bottom: 1.375rem;
  margin-top: 0;
`;

const StyledForm = styled(Form)`
  margin-bottom: 4vh;
`;

const StyledTitle = styled(Title)`
  letter-spacing: 0.0625rem;
  padding-right: 2rem;
`;

const validationSchema = object().shape({
  companyEmail: string()
    .required('Pole obowiązkowe')
    .email('Podaj poprawny adres email'),
});

const initialValues: CompanyConfirmationFormModel = {
  companyEmail: '',
};

export interface CompanyConfirmationEmailProps {
  backButtonRoute?: string;
}

export const CompanyConfirmationEmail: FC<CompanyConfirmationEmailProps> = ({
  backButtonRoute,
}) => {
  const dispatch = useDispatch();

  const { isLoading } = useRequest(companyConfirmationActions.request);

  return (
    <>
      <Seo title="Podaj nowy służbowy adres e-mail" />

      <StyledTitle level={1} variant="condensed">
        Podaj nowy służbowy adres e-mail
      </StyledTitle>

      <section>
        <InfoParagraph size="small">
          Z&nbsp;uwagi na zmianę pracy podaj swój nowy służbowy adres e-mail. Na
          jego podstawie zweryfikujemy, czy Twoja firma współpracuje
          z&nbsp;Car&nbsp;Platform.
        </InfoParagraph>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            pipe(
              values,
              mapToCompanyConfirmationDTO,
              companyConfirmationActions.request,
              dispatch
            );
            setSubmitting(false);
          }}
        >
          <StyledForm>
            <FormItem name="companyEmail">
              <TextControl
                type="email"
                name="companyEmail"
                label="Twój służbowy adres e-mail"
              />
            </FormItem>
            <FormActionsContainer
              style={{ margin: '2vh 0' }}
              justifyContent="flex-end"
            >
              {backButtonRoute && (
                <Button
                  kind="box"
                  variant="transparent"
                  icon={ChevronLeft}
                  as={Link}
                  to={backButtonRoute}
                  style={{ marginRight: 'auto' }}
                />
              )}

              <Button
                kind="skew"
                size="small"
                type="submit"
                icon={ArrowRight}
                disabled={isLoading}
              >
                Aktualizuj
              </Button>
            </FormActionsContainer>
          </StyledForm>
        </Formik>
      </section>
    </>
  );
};
