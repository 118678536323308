import { useDispatch, useSelector } from 'react-redux';
import { PackageType } from 'src/common/models/offer';
import {
  activeOfferSelector,
  availableOffersSelector,
  packageTypesListSelector,
  productTypeSelector,
} from 'src/app/product/state/products/productsSelectors';
import { useLocation } from '@reach/router';
import { useEffect, useMemo } from 'react';
import { productsActions } from 'src/app/product/state/products/productsActions';
import { navigate } from 'gatsby';
import {
  createOptionsList,
  findOfferWithParams,
  getPackageTypeFilter,
} from 'src/app/product/services/helpers';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';

export const useInternalCalculatorConfigurations = () => {
  const availableOffers = useSelector(availableOffersSelector);
  const packageTypesList = useSelector(packageTypesListSelector);
  const productType = useSelector(productTypeSelector);
  const location = useLocation();
  const reduxDispatch = useDispatch();
  const activeOffer = useSelector(activeOfferSelector);
  const userCustomerType = useSelector(customerTypeSelector);

  const packageTypeFilter = useMemo(
    () => getPackageTypeFilter(activeOffer, productType),
    [activeOffer, productType]
  );

  const initialPaymentsList = useMemo(
    () =>
      createOptionsList('initialPayment', {
        customerType: userCustomerType,
        financingType: activeOffer?.financingType,
        packageType: packageTypeFilter,
      })(availableOffers),
    [availableOffers, activeOffer, userCustomerType]
  );

  const contractPeriodsList = useMemo(
    () =>
      createOptionsList('contractPeriod', {
        customerType: userCustomerType,
        financingType: activeOffer?.financingType,
        packageType: packageTypeFilter,
      })(availableOffers),
    [availableOffers, activeOffer, userCustomerType]
  );

  const annualMileageList = useMemo(
    () =>
      createOptionsList('annualMileage', {
        customerType: userCustomerType,
        financingType: activeOffer?.financingType,
        packageType: packageTypeFilter,
      })(availableOffers),
    [availableOffers, activeOffer, userCustomerType]
  );

  const buyoutPriceList = useMemo(
    () =>
      createOptionsList('buyoutPrice', {
        customerType: userCustomerType,
        financingType: activeOffer?.financingType,
        packageType: packageTypeFilter,
        initialPayment: activeOffer?.initialPayment,
        contractPeriod: activeOffer?.contractPeriod,
      })(availableOffers),
    [availableOffers, activeOffer, userCustomerType]
  );

  // After offer changes, set the URL properly and push offer to state
  useEffect(() => {
    if (activeOffer == null) {
      return;
    }

    const params = new URLSearchParams(location.search);

    params.delete('offerDetails');
    params.set('offerId', activeOffer.id);

    navigate(`${location.pathname}?${params}`, {
      replace: true,
    });
  }, [activeOffer]);

  return {
    packageTypesList,
    setPackageType: (packageType: PackageType) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              customerType: userCustomerType,
              financingType: activeOffer?.financingType,
              packageType: packageType,
            },
            {
              annualMileage: activeOffer?.annualMileage,
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(availableOffers)
        )
      ),
    initialPaymentsList,
    setInitialPayment: (initialPayment: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              customerType: userCustomerType,
              financingType: activeOffer?.financingType,
              packageType: packageTypeFilter,
            },
            {
              annualMileage: activeOffer?.annualMileage,
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment,
            }
          )(availableOffers)
        )
      ),
    contractPeriodsList,
    setContractPeriod: (contractPeriod: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              customerType: userCustomerType,
              financingType: activeOffer?.financingType,
              packageType: packageTypeFilter,
            },
            {
              annualMileage: activeOffer?.annualMileage,
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(availableOffers)
        )
      ),
    annualMileageList,
    setAnnualMileage: (annualMileage: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              customerType: userCustomerType,
              financingType: activeOffer?.financingType,
              packageType: packageTypeFilter,
            },
            {
              annualMileage,
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(availableOffers)
        )
      ),
    buyoutPriceList,
    setBuyoutPrice: (buyoutPrice: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              customerType: userCustomerType,
              financingType: activeOffer?.financingType,
              packageType: packageTypeFilter,
            },
            {
              annualMileage: activeOffer?.annualMileage,
              buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(availableOffers)
        )
      ),
  };
};
