import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { ProductBenefits } from 'src/app/common/components/ProductBenefits';
import { ProductPageSectionHeading } from 'src/app/product/components/ProductPageSectionHeading';
import { ProductPageSection } from 'src/app/product/models/productPageSection';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { ProductSectionContainer } from 'src/app/product/components/ProductSectionContainer';

const List = styled.div`
  margin: 0 auto;
  max-width: 56.25rem;
  overflow: hidden;
  padding-top: 1.25rem;

  ${media.w.min.px992(css`
    padding: 0;
  `)}
`;

export interface ProductPageBenefitsProps {
  benefits: { content: string }[];
  section: ProductPageSection;
}

const swiperOptions = {
  breakpoints: {
    [widthBreakpoints.px768]: {
      slidesPerColumn: 2,
    },
  },
  slidesPerColumn: 1,
  slidesPerView: 2,
};

export const ProductPageBenefits = memo<ProductPageBenefitsProps>((props) => (
  <ProductSectionContainer>
    <ProductPageSectionHeading
      icon={props.section.icon}
      subtitle={props.section.subtitle}
      title={props.section.title}
    />

    <List>
      <ProductBenefits list={props.benefits} swiperOptions={swiperOptions} />
    </List>
  </ProductSectionContainer>
));
