import styled from 'styled-components';
import { colors } from 'src/config/colors';

export const SimpleLink = styled.a.attrs({ tabIndex: 0 })`
  color: currentColor;
  cursor: pointer;
  padding-top: 0;
  text-decoration: underline;

  @media (hover: hover) {
    &:hover:not(:focus):not(:active):not(:disabled) {
      color: ${colors.primary};
    }
  }

  &:focus {
    outline: solid 1px currentColor;
    outline-offset: 1px;
  }

  &:active {
    outline: none;
  }
`;
