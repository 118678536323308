import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';

const StyledMuiTextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    margin-bottom: 0.75rem;
    border-top: 0.25rem solid transparent;
    display: flex;
  }

  & .MuiInputBase-root {
    border-radius: 0;

    ${media.w.min.px768(css`
      font-size: 0.875rem;
    `)};
  }

  & .MuiInputBase-input {
    ${media.w.min.px768(css`
      font-size: 0.875rem;
    `)};
  }

  & .MuiInputLabel-outlined {
    transform: translate(0.875rem, 0.9375rem) scale(1);

    ${media.w.min.px768(css`
      font-size: 0.875rem;
    `)};
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(0.875rem, -0.375rem) scale(0.875);
  }

  & .MuiOutlinedInput-input {
    padding: 0.875rem;
  }

  & .MuiOutlinedInput-notchedOutline > legend {
    font-size: 0.875rem;

    ${media.w.min.px768(css`
      font-size: 0.775rem;
    `)};
  }

  & .MuiOutlinedInput-input:-webkit-autofill {
    box-shadow: 0 0 0 6.25rem ${darken(0.4, colors.primary)} inset;
  }

  & .MuiOutlinedInput-inputMultiline {
    min-height: 5rem;
    padding: 0;
  }
`;

export const TextField = (props: TextFieldProps) => (
  <StyledMuiTextField variant="outlined" {...props} />
);

TextField.displayName = 'TextField';
