import { IconBox, IconProps } from 'src/lib/IconBox';
import React from 'react';

// eslint-disable-next-line import/no-default-export
export default (props: IconProps) => (
  <IconBox {...props}>
    <g>
      <path d="m53.2853 28.9521h-42.6666c-3.36633 0-6.09526 2.729-6.09526 6.0953v21.3333c0 3.3663 2.72893 6.0953 6.09526 6.0953h42.6666c3.3664 0 6.0953-2.729 6.0953-6.0953v-21.3333c0-3.3663-2.7289-6.0953-6.0953-6.0953z" />
      <path
        clipRule="evenodd"
        d="m10.619 30.4763c-2.52468 0-4.57138 2.0467-4.57138 4.5715v21.3333c0 2.5247 2.0467 4.5714 4.57138 4.5714h42.6667c2.5247 0 4.5714-2.0467 4.5714-4.5714v-21.3333c0-2.5248-2.0467-4.5715-4.5714-4.5715zm-7.619 4.5715c0-4.2079 3.41116-7.6191 7.619-7.6191h42.6667c4.2079 0 7.6191 3.4112 7.6191 7.6191v21.3333c0 4.2079-3.4112 7.619-7.6191 7.619h-42.6667c-4.20784 0-7.619-3.4111-7.619-7.619z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m31.9523 3.04762c-3.6372 0-7.1255 1.44489-9.6974 4.01682-2.572 2.57193-4.0169 6.06026-4.0169 9.69746v12.1905c0 .8416-.6822 1.5238-1.5238 1.5238-.8415 0-1.5238-.6822-1.5238-1.5238v-12.1905c0-4.4455 1.766-8.70898 4.9095-11.85245 3.1434-3.14347 7.4069-4.90945 11.8524-4.90945 4.4456 0 8.709 1.76598 11.8525 4.90945s4.9094 7.40695 4.9094 11.85245v12.1905c0 .8416-.6822 1.5238-1.5238 1.5238-.8415 0-1.5238-.6822-1.5238-1.5238v-12.1905c0-3.6372-1.4449-7.12553-4.0168-9.69746s-6.0602-4.01682-9.6975-4.01682z"
        fillRule="evenodd"
      />
    </g>
    <path
      d="m38.0479 41.1431c0 3.3663-2.7289 6.0952-6.0952 6.0952s-6.0953-2.7289-6.0953-6.0952 2.729-6.0952 6.0953-6.0952 6.0952 2.7289 6.0952 6.0952z"
      fill="#2a2b32"
    />
    <path d="m28.9043 41.1431h6.0952v15.2381h-6.0952z" fill="#2a2b32" />{' '}
  </IconBox>
);
