import { AccordionDetails } from '@material-ui/core';
import styled from 'styled-components';
import { typography } from 'src/config/typography';

export const ExpansionPanelDetails = styled(AccordionDetails)`
  && {
    display: block;
    padding: 0;
    font-family: ${typography.fontFamilyDefault};
    font-size: ${typography.paragraphSize['small']};
    line-height: 1.25;
  }
`;
