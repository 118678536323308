import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 64 64" {...props}>
    <g opacity="0.5" transform="scale(1.8) translate(7 1)" fill="none">
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="33.375"
        rx="9.5"
        stroke="#D4D6DC"
      />
      <path d="M10 16.875V25.625" stroke="#D4D6DC" />
    </g>
  </IconBox>
);
