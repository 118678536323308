import React, { memo } from 'react';
import { ToggleButton } from 'src/common/components/ToggleButton';
import { StyleProps } from '@summer/react-kit';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { Icon } from 'src/common/components/Icon';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { media } from 'src/config/breakpoints';
import { brandIconMapper } from 'src/app/product/services/productMappers';
import { OptionalFold } from 'src/lib/OptionalFold';
import { CommonOptionFilter } from 'src/app/search/components/SearchPanel/CommonOptionFilter';

const StyledCommonOptionFilter = styled(CommonOptionFilter)`
  max-width: none;
`;

const StyledToggleButton = styled(ToggleButton)`
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;

  ${media.w.min.px768(css`
    padding: 0.25rem 0.875rem;
    width: 9.375rem;
  `)};
`;

export const SearchPackageEquipmentFilter = memo<StyleProps>(
  ({ className, style }) => {
    const { state, setValue, pristine, reset } = useOptionFilterControl(
      OptionFilter.EquipmentPackage
    );

    const items =
      state.options?.map((a) => ({
        id: a.id,
        label: (
          <>
            <OptionalFold
              value={brandIconMapper(a.brand?.code)}
              onSome={(a) => (
                <Icon code={a} size={16} style={{ marginRight: rem(8) }} />
              )}
            />
            <span>{a.label}</span>
          </>
        ),
      })) ?? [];

    return (
      <StyledCommonOptionFilter
        kind={OptionFilter.EquipmentPackage}
        className={className}
        style={style}
        columns={{ desktop: 2, mobile: 2 }}
        direction="vertical"
        items={items}
        value={state.value}
        onChange={setValue}
        reset={reset}
        pristine={pristine}
        toggleButtonComponent={StyledToggleButton}
      />
    );
  }
);
