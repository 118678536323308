import React, { ComponentType, memo } from 'react';
import {
  SearchFilterOptionsContainer,
  SearchFilterOptionsGrid,
  SearchFilterOptionsHeader,
} from 'src/app/search/components/SearchPanel/SearchFilterOptions';
import {
  ToggleButton,
  ToggleButtonProps,
} from 'src/common/components/ToggleButton';
import { StyleProps } from '@summer/react-kit';
import { OptionFilter } from 'src/app/common/models/filter';
import styled, { css } from 'styled-components';
import {
  ToggleButtonGroup,
  ToggleButtonGroupItemOptions,
} from 'src/common/components/ToggleButtonGroup';
import { filterConfig } from 'src/app/common/models/filterConfig';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { media } from 'src/config/breakpoints';
import { rem } from 'polished';
import Close from 'src/assets/icons/Close';
import { Button } from 'src/common/components/Button';

const StyledToggleButton = styled(ToggleButton)`
  justify-content: center;
  border-radius: 0.125rem;

  ${media.w.min.px768(css`
    width: 6rem;
  `)};
`;

const StyledButton = styled(Button)<{ hideClear: boolean }>`
  ${({ hideClear }) =>
    !hideClear &&
    css`
      visibility: hidden;
    `}

  &.btn-link > .btn__content > .btn__label {
    margin: 0;
    font-size: 0.625rem;
    opacity: 0.5;
  }

  ${media.w.max.px768(css`
    display: none;
  `)};
`;

const Grid = styled.div<{
  columns: { desktop: number; mobile: number } | undefined;
}>`
  grid-area: grid;
  display: grid;
  grid-gap: 0.375rem;
  grid-template-rows: min-content;
  width: 100%;

  ${(props) =>
    props.columns &&
    css`
      grid-template-columns: repeat(${props.columns.mobile}, 1fr);
      ${media.w.min.px768(css`
        grid-template-columns: repeat(${props.columns.desktop}, 1fr);
      `)};
    `}

  ${(props) =>
    !props.columns &&
    css`
      grid-template-columns: repeat(4, 1fr);
    `}
`;

export interface CommonOptionFilterProps<T extends OptionFilter>
  extends StyleProps {
  kind: T;
  pristine: boolean;
  columns?: { desktop: number; mobile: number };
  direction?: string;
  reset: () => void;
  value: string[];
  items: ToggleButtonGroupItemOptions[];
  onChange: (v: string[]) => void;
  toggleButtonComponent?: ComponentType<ToggleButtonProps>;
}

export const CommonOptionFilter = memo(
  <T extends OptionFilter>({
    kind,
    pristine,
    reset,
    value,
    items,
    columns,
    direction,
    onChange,
    toggleButtonComponent,
    className,
    style,
  }: CommonOptionFilterProps<T>) => {
    const hideClear = !pristine && direction !== 'vertical';

    return (
      <SearchFilterOptionsContainer
        direction={direction}
        className={className}
        style={style}
      >
        <SearchFilterOptionsHeader
          name={filterConfig[kind].name}
          direction={direction}
          onClear={!pristine ? reset : undefined}
        />
        <SearchFilterOptionsGrid>
          <Grid columns={columns}>
            <ToggleButtonGroup
              items={items}
              value={value}
              onChange={onChange}
              toggleButtonComponent={
                toggleButtonComponent ?? StyledToggleButton
              }
            />
          </Grid>
        </SearchFilterOptionsGrid>
        <div>
          <StyledButton
            kind="link"
            variant="transparent"
            size="small"
            hideClear={hideClear}
            style={{ marginTop: rem(6), gridArea: 'clear' }}
            icon={Close}
            iconProps={{ size: 13, align: 'right' }}
            onClick={reset}
          >
            wyczyść{' '}
          </StyledButton>
        </div>
      </SearchFilterOptionsContainer>
    );
  }
);

export const ConnectedCommonOptionFilter = memo(
  <T extends OptionFilter>({
    kind,
    className,
    toggleButtonComponent,
    columns,
    direction,
    style,
  }: StyleProps & {
    columns?: { desktop: number; mobile: number };
    direction?: string;
    kind: T;
    toggleButtonComponent?: ComponentType<ToggleButtonProps>;
  }) => {
    const { state, pristine, setValue, reset } = useOptionFilterControl(kind);

    return (
      <CommonOptionFilter
        kind={kind}
        className={className}
        style={style}
        columns={columns}
        direction={direction}
        items={state.options ?? []}
        value={state.value}
        onChange={setValue}
        reset={reset}
        pristine={pristine}
        toggleButtonComponent={toggleButtonComponent ?? StyledToggleButton}
      />
    );
  }
);
