import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { colors } from 'src/config/colors';
import React, { FC } from 'react';
import logoSimple from 'src/assets/images/logo-simple.png';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1440px;
  margin: 0 auto 1rem;
  padding: 8rem 1rem;
  position: relative;
  z-index: 2;

  ${media.w.min.px768(css`
    height: 792px;
    padding: 0 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  `)};
`;

const LogoWrapper = styled.div`
  ${media.w.min.px992(css`
    padding-left: 9rem;
  `)};

  ${media.w.max.px768(css`
    display: none;
  `)};
`;

const HeadingWrapper = styled.div`
  ${media.w.max.px768(css`
    padding-top: 3rem;
  `)};
`;

const StyledSectionHeading = styled(SectionHeading)`
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.625rem;
  color: ${colors.white};

  & span {
    color: ${colors.primary};
  }

  ${media.w.min.px768(css`
    text-align: left;
    margin-top: 0;
  `)};
`;

const Subtitle = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.lightGray};

  ${media.w.max.px768(css`
    padding: 0 3rem;
  `)};

  ${media.w.min.px768(css`
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.465rem;
    text-align: start;
    max-width: 28rem;
  `)};
`;

export const AboutCarPlatformSection: FC = () => {
  return (
    <Container>
      <Content>
        <LogoWrapper>
          <img src={logoSimple} alt="car-platform-simple-logo" />
        </LogoWrapper>
        <HeadingWrapper>
          <StyledSectionHeading>
            Czym jest Car Platform Business?
          </StyledSectionHeading>
          <Subtitle>
            Car Platform Business to strefa z benefitami dla pracowników firm
            współpracujących, dająca dostęp do wielu korzyści, takich jak zniżki
            na samochody, comiesięczne bonusy i oferty specjalne.
          </Subtitle>
        </HeadingWrapper>
      </Content>
    </Container>
  );
};
