import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.889 38.2627H13.8691V37.4209H46.889V38.2627Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.2487 40.0772L12.7773 40.0772L12.7773 39.2354L47.2487 39.2354L47.2487 40.0772Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.063 41.0489C53.1031 41.0489 54.7569 39.3951 54.7569 37.355C54.7569 35.315 53.1031 33.6612 51.063 33.6612C49.023 33.6612 47.3692 35.315 47.3692 37.355C47.3692 39.3951 49.023 41.0489 51.063 41.0489ZM51.063 41.8907C53.568 41.8907 55.5987 39.86 55.5987 37.355C55.5987 34.85 53.568 32.8193 51.063 32.8193C48.558 32.8193 46.5273 34.85 46.5273 37.355C46.5273 39.86 48.558 41.8907 51.063 41.8907Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33257 41.0489C11.3726 41.0489 13.0264 39.3951 13.0264 37.355C13.0264 35.315 11.3726 33.6612 9.33257 33.6612C7.2925 33.6612 5.6387 35.315 5.6387 37.355C5.6387 39.3951 7.2925 41.0489 9.33257 41.0489ZM9.33257 41.8907C11.8376 41.8907 13.8683 39.86 13.8683 37.355C13.8683 34.85 11.8376 32.8193 9.33257 32.8193C6.82758 32.8193 4.79688 34.85 4.79688 37.355C4.79688 39.86 6.82758 41.8907 9.33257 41.8907Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.0565 39.9606C52.4954 39.9606 53.6618 38.7942 53.6618 37.3553C53.6618 35.9165 52.4954 34.75 51.0565 34.75C49.6176 34.75 48.4512 35.9165 48.4512 37.3553C48.4512 38.7942 49.6176 39.9606 51.0565 39.9606ZM51.0565 40.8025C52.9603 40.8025 54.5036 39.2591 54.5036 37.3553C54.5036 35.4515 52.9603 33.9082 51.0565 33.9082C49.1527 33.9082 47.6094 35.4515 47.6094 37.3553C47.6094 39.2591 49.1527 40.8025 51.0565 40.8025Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33385 39.9606C10.7727 39.9606 11.9392 38.7942 11.9392 37.3553C11.9392 35.9165 10.7727 34.75 9.33385 34.75C7.89498 34.75 6.72854 35.9165 6.72854 37.3553C6.72854 38.7942 7.89498 39.9606 9.33385 39.9606ZM9.33385 40.8025C11.2376 40.8025 12.781 39.2591 12.781 37.3553C12.781 35.4515 11.2376 33.9082 9.33385 33.9082C7.43005 33.9082 5.88672 35.4515 5.88672 37.3553C5.88672 39.2591 7.43005 40.8025 9.33385 40.8025Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1358 26.1892L17.5848 29.0677H14.775C8.53058 29.0677 3.23815 31.4287 1.2126 32.6956L0.884295 38.2767L5.59612 39.1181L5.44814 39.9468L0 38.9739L0.397237 32.2209L0.574067 32.1048C2.56524 30.7981 8.14755 28.2259 14.775 28.2259H17.408L29.3013 23L29.692 23.7434L22.5572 28.155L56.7562 26.6462L61.159 27.1966L63.0929 29.3951V32.6672L64 33.7558V37.1015L62.9365 39.0157L55.6498 39.9499L55.5427 39.1149L62.4073 38.2349L63.1582 36.8834V34.0606L62.251 32.972V29.7127L60.7378 27.9923L56.7223 27.4904L19.3661 29.1385L24.1358 26.1892Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.0754 29.3145H62.3073V30.1563H59.3707L58.9461 30.496L60.486 32.0359H62.3073V32.8777H60.1373L57.6858 30.4262L59.0754 29.3145ZM2.57585 32.0359H6.60895L6.78913 32.8372L3.25646 34.5106H0.803251V33.6687H3.06717L4.73711 32.8777H2.65921L0.885799 33.2324L0.720703 32.4069L2.57585 32.0359Z"
    />
  </IconBox>
);
