import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { SearchEnginePerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchEnginePerformanceFilter';
import { SearchGearboxPerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchGearboxPerformanceFilter';
import { SearchPowerPerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchPowerPerformanceFilter';
import { SearchCapacityPerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchCapacityPerformanceFilter';
import { SearchFuelConsumptionPerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchFuelConsumptionPerformanceFilter';
import { SearchEmissionPerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchEmissionPerformanceFilter';
import { SearchRangePerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchRangePerformanceFilter';
import { SearchFiltersGroupContainer } from 'src/app/search/components/SearchPanel/SearchFiltersGroupContainer';
import { media } from 'src/config/breakpoints';
import { SearchCabinSpaceInteriorFilter } from 'src/app/search/components/SearchPanel/SearchInteriorFilters/SearchCabinSpaceInteriorFilter';
import { SearchDrivePerformanceFilter } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters/SearchDrivePerformanceFilter';
import { isMotorcycles } from 'src/app/search/utils/is-motorcycles';

const Container = styled(SearchFiltersGroupContainer)`
  grid-template-areas: 'general' 'sliders' 'trunk' 'cabin';
  grid-row-gap: 4.375rem;
  width: 100%;

  ${media.w.min.px768(css`
    grid-template-areas: 'general sliders' 'trunk cabin';
    grid-template-columns: max-content 26.25rem;
    justify-content: space-between;
    grid-template-rows: min-content;
    grid-row-gap: 1rem;
  `)};

  ${media.w.min.px1200(css`
    grid-template-areas: 'general sliders trunk' 'general sliders cabin' 'general sliders .';
    grid-template-columns: max-content 26.25rem max-content;
    justify-content: space-between;
    grid-template-rows: min-content;
    grid-row-gap: 1rem;
    max-width: 78.125rem;
  `)};

  ${media.w.max.px768(css`
    padding-bottom: 6rem;
  `)};
`;

const SlidersContainer = styled.div`
  grid-area: sliders;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 22rem;

  ${media.w.max.px768(css`
    grid-row-gap: 3.5rem;
  `)};
`;

const General = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.875rem;

  ${media.w.min.px768(css`
    display: flex;
    grid-row-gap: 0;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  `)};
`;

export const SearchPerformanceFilters = memo(() => (
  <Container>
    <General style={{ gridArea: 'general' }}>
      <SearchEnginePerformanceFilter style={{ alignSelf: 'flex-start' }} />
      <SearchGearboxPerformanceFilter style={{ alignSelf: 'flex-start' }} />
      <SearchDrivePerformanceFilter style={{ alignSelf: 'flex-start' }} />
    </General>
    <SlidersContainer>
      <SearchPowerPerformanceFilter />
      <SearchCapacityPerformanceFilter />
      <SearchFuelConsumptionPerformanceFilter />
      <SearchEmissionPerformanceFilter />
      <SearchRangePerformanceFilter />
    </SlidersContainer>
    {!isMotorcycles() && (
      <SearchCabinSpaceInteriorFilter style={{ gridArea: 'trunk' }} />
    )}
  </Container>
));
