import { AuthorizedAxios } from 'src/common/services/axios';
import { CommonFiltersDTO } from 'src/app/common/models/commonFiltersDTO';
import { flatten } from 'src/lib/axios';
import {
  FilterKind,
  FilterStateKindMap,
  FilterValues,
} from 'src/app/common/models/filter';
import { evolve, mapObjIndexed } from 'ramda';
import { map } from 'fp-ts/Array';
import { imageUrlTransformer } from 'src/common/services/image';
import { navigate } from 'gatsby';
import { SearchPageRoute } from 'src/app/routes';
import { stringify } from 'qs';
import { packFilters } from 'src/app/search/services/filterSerializer';
import { currentSearchParamsVersion } from 'src/app/search/searchParamsVersion';

export const fetchCommonFilters = () =>
  AuthorizedAxios.get<CommonFiltersDTO>('common-filters')
    .then(flatten)
    .then(
      evolve({
        brands: map(
          evolve({
            models: map(
              evolve({
                imageUrl: imageUrlTransformer,
              })
            ),
          })
        ),
      })
    );

export const fetchSrpFilters = () =>
  AuthorizedAxios.get<CommonFiltersDTO>('srp-filters').then(flatten);

export const pickFilterValues = (
  filters: {
    [P in FilterKind]?: FilterStateKindMap[P];
  }
  /**
   * It is probably impossible to be safely typed because of indexed nature of filters.
   */
) => mapObjIndexed((v) => v?.value, filters) as FilterValues;

export const searchWith = (filters: FilterValues) =>
  navigate(
    `${SearchPageRoute}?${stringify({
      f: packFilters(filters),
      v: currentSearchParamsVersion,
    })}`
  );
