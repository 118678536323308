import React from 'react';
import styled from 'styled-components';
import { Button } from 'src/common/components/Button';
import { Tooltip } from 'src/common/components/Tooltip';
import { navigate } from 'gatsby';
import { LoginRoute } from 'src/public/publicRoutes';
import { colors } from 'src/config/colors';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const InfoButtonTooltip = styled(Tooltip)`
  & .tooltip {
    max-width: 16.875rem;
    color: ${colors.primary};
    background-color: ${colors.white};

    & .MuiTooltip-arrow {
      &::before {
        background-color: ${colors.white};
      }
    }
  }
`;

const TooltipContent = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-align: left;
`;

const InfoButton = styled(Button)`
  white-space: normal;
  max-width: 50%;

  .btn__content {
    font-size: 0.8125rem;
  }
`;

export const CarPlatformBusinessTooltipButton = () => (
  <InfoButtonTooltip
    placement="top-start"
    title={
      <TooltipContent>
        Czy wiesz, że pracownicy firm współpracujących z Car Platform mają
        dostęp do specjalnej oferty oraz dodatkowych bonusów? Sprawdź szczegóły
        programu Car Platform Business lub załóż konto, jeśli Twoja firma z nami
        współpracuje
      </TooltipContent>
    }
  >
    <InfoButton
      size="small"
      variant="gray"
      onClick={() => {
        navigate(LoginRoute);
        pushEvent(EventName.ButtonClick, {
          click_category: 'offer_details_cta',
          click_cta: 'car_platform_business',
        });
      }}
    >
      Car Platform Business
    </InfoButton>
  </InfoButtonTooltip>
);
