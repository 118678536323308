import { isNone, none, Option, some } from 'fp-ts/lib/Option';
import { createReducer } from '@reduxjs/toolkit';
import { User } from 'src/common/models/user';
import {
  authActions,
  fetchUserActions,
  loginActions,
  registerByCodeActions,
  registerStep2Actions,
  setUserCustomerTypeActions,
  updateUserActions,
} from 'src/state/auth/authActions';
import { confirmCompanyActions } from 'src/state/companyConfirmation/companyConfirmationActions';

export interface AuthState {
  token: Option<string>;
  user: Option<User>;
  registerByCodeToken: Option<string>;
}

const initialState: AuthState = {
  token: none,
  user: none,
  registerByCodeToken: none,
};

export const authReducer = createReducer<AuthState>(initialState, (builder) =>
  builder
    .addCase(loginActions.requestSuccess, (state, action) => {
      state.token = some(action.payload.jwt);
      state.user = some(action.payload.user);
    })
    .addCase(registerStep2Actions.requestSuccess, (state, action) => {
      state.token = some(action.payload.jwt);
      state.user = some(action.payload.user);
    })
    .addCase(registerByCodeActions.requestSuccess, (state, action) => {
      state.registerByCodeToken = some(action.payload.jwt);
      state.user = some(action.payload.user);
    })
    .addCase(authActions.updateToken, (state, action) => {
      state.token = action.payload;
      state.registerByCodeToken = none;
    })
    .addCase(confirmCompanyActions.requestSuccess, (state, action) => {
      state.token = some(action.payload.jwt);
      state.user = some(action.payload.user);
    })
    .addCase(fetchUserActions.requestSuccess, (state, action) => {
      state.user = some(action.payload);
    })
    .addCase(updateUserActions.requestSuccess, (state, action) => {
      state.user = some(action.payload);
    })
    .addCase(setUserCustomerTypeActions.requestSuccess, (state, action) => {
      state.user = some(action.payload);
    })
    .addCase(authActions.updateUser, (state, action) => {
      if (isNone(state.user)) {
        return;
      }

      state.user = some({
        ...state.user.value,
        ...action.payload,
      });
    })
    .addCase(authActions.logout, () => initialState)
);
