import React, { memo, useState } from 'react';
import { Title } from 'src/common/components/typography/Title';
import styled from 'styled-components';
import { Paragraph } from 'src/common/components/typography/Text';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/common/components/Button';
import { GuestUserOrderFormModel } from 'src/app/product/models/orderFormModel';
import { useRequestEffect } from 'src/lib/useRequestEffect';
import { bookProductGuestActions } from 'src/app/product/state/products/productsActions';
import ArrowRight from 'src/assets/icons/ArrowRight';
import {
  activeOfferSelector,
  activeProductSelector,
} from 'src/app/product/state/products/productsSelectors';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import { Link } from 'gatsby';
import { PrivacyPolicyAndCookiesRoute } from 'src/public/publicRoutes';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { isSome } from 'fp-ts/Option';
import { CustomerType } from 'src/common/models/customerType';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '+48',
  newsletterAgreement: false,
  carPlatformPremiumDeclaration: false,
  company: '',
};

const validationSchema = object().shape({
  firstName: string().required('Pole wymagane'),
  lastName: string().required('Pole wymagane'),
  email: string().required('Pole wymagane').email('Podaj poprawny adres email'),
  phone: string()
    .matches(
      /^(?:[+\d]{0}.[\d\s]*)$/,
      'Numer telefonu może zaczynać się od + oraz musi składać się z samych cyfr i spacji'
    )
    .max(30, 'Numer telefonu powinien składać się z maksymalnie 30 znaków'),
  carPlatformPremiumDeclaration: boolean(),
  company: string().when('carPlatformPremiumDeclaration', {
    is: true,
    then: string().required('Pole wymagane'),
  }),
});

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const OrderPageRequestFormGuestUser = memo<{
  onSubmit: () => void;
}>(({ onSubmit }) => {
  const dispatch = useDispatch();

  const offer = useSelector(activeOfferSelector);
  const product = useSelector(activeProductSelector);

  const [isProcessing, setIsProcessing] = useState(false);

  useRequestEffect(bookProductGuestActions.request, {
    onSuccess: onSubmit,
    onError: () => setIsProcessing(false),
  });

  const handleSubmit = (values: GuestUserOrderFormModel) => {
    setIsProcessing(true);
    dispatch(bookProductGuestActions.request({ user: values, offer: offer! }));
    pushEvent(EventName.ButtonClick, {
      click_category: ' offer_availability_cta',
      click_cta: 'dalej',
    });
    pushEvent(EventName.Purchase, {
      currency: 'PLN',
      transaction_id: Base64.stringify(sha256(values.email + offer?.price)),
      value: offer?.price.toString() ?? '',
      item_list_name: 'order_offer_details',
      items: [
        {
          item_id: offer?.id ?? '',
          item_brand:
            product && isSome(product) ? product?.value.brand?.label ?? '' : '',
          item_variant:
            offer?.customerType === CustomerType.B2C ? 'dla_mnie' : 'dla_firmy',
        },
      ],
    });
  };

  return (
    <section>
      <Paragraph
        variant="condensed"
        style={{ fontSize: '1.125rem', marginBottom: '0.5rem' }}
      >
        Nasz pracownik skontaktuje się z Tobą,
      </Paragraph>
      <Title level={1} variant="condensed" style={{ margin: 0 }}>
        Aby dobrać najlepszą ofertę
      </Title>
      <Paragraph
        variant="condensed"
        style={{
          fontWeight: 500,
          marginTop: '0.5rem',
          marginBottom: '2rem',
        }}
      >
        i potwierdzić dostępność auta.
      </Paragraph>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormItem name="firstName">
              <TextControl
                name="firstName"
                label="Imię"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'imie' }],
                  });
                }}
              />
            </FormItem>
            <FormItem name="lastName">
              <TextControl
                name="lastName"
                label="Nazwisko"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'nazwisko' }],
                  });
                }}
              />
            </FormItem>
            <FormItem name="email">
              <TextControl
                name="email"
                label="Adres e-mail"
                type="email"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'email' }],
                  });
                }}
              />
            </FormItem>
            <FormItem name="phone">
              <TextControl
                name="phone"
                label="Telefon (opcjonalnie)"
                type="tel"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'phone' }],
                  });
                }}
              />
            </FormItem>
            <FormItem name="newsletterAgreement">
              <CheckboxControl
                name="newsletterAgreement"
                label="Chcę otrzymywać od Mobility Benefit oferty usług, produktów i informacje o promocjach na podany przeze mnie adres e-mail lub numer telefonu. W każdej chwili mogę wycofać swoją zgodę."
              />
            </FormItem>
            <FormItem name="carPlatformPremiumDeclaration">
              <CheckboxControl
                name="carPlatformPremiumDeclaration"
                label="Pracuję w korporacji, średnim przedsiębiorstwie i chciałbym otrzymać dostęp do specjalnych zniżek w programie Car Platform Business"
              />
            </FormItem>
            {values.carPlatformPremiumDeclaration && (
              <FormItem
                name="company"
                hintPosition="center"
                hint="Nazwę podajesz do celów weryfikacyjnych. Na jej podstawie przyznawany jest dostęp do Car Platform i wyjątkowych ofert."
              >
                <TextControl name="company" label="Nazwa firmy" />
              </FormItem>
            )}

            <Paragraph size="xSmall">
              Po wysłaniu formularza nasz konsultant skontaktuje się z Tobą
              telefonicznie lub mailowo (zgodnie z Twoją preferencją) w celu
              przedstawienia Ci oferty. W przypadku zaznaczenia pierwszej zgody
              będziemy mogli kontaktować się z Tobą w przyszłości na Twój adres
              e-mail lub numer telefonu - w zależności od danych wprowadzonych
              do formularza. Administratorem Twoich danych jest Mobility Benefit
              Sp. z o.o. z siedzibą w Warszawie. Szczegóły dotyczące
              przetwarzania Twoich danych znajdziesz w{' '}
              <SimpleLink as={Link} to={PrivacyPolicyAndCookiesRoute}>
                Polityce prywatności
              </SimpleLink>
            </Paragraph>

            <Actions>
              <Button
                kind="skew"
                type="submit"
                disabled={offer == null || isProcessing}
                icon={ArrowRight}
                onClick={() => {
                  pushEvent(EventName.Try, {});
                }}
              >
                Dalej
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </section>
  );
});
