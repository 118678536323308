import { createAction } from 'src/lib/createAction';
import { LoginDTO, ValidateTokenDTO } from 'src/common/models/auth';
import { createRequestActions } from 'src/lib/requestActions';
import { CompanyConfirmationViewState } from 'src/common/models/companyConfirmationViewState';
import { CompanyConfirmationDTO } from 'src/common/models/companyConfirmation';

export const companyConfirmationViewActions = {
  resetCompanyConfirmationViewState: createAction(
    'auth/resetCompanyConfirmationViewState'
  )(),
  setCompanyConfirmationViewState: createAction(
    'auth/setCompanyConfirmationViewState'
  )<CompanyConfirmationViewState>(),
} as const;

export const companyConfirmationActions = createRequestActions(
  'auth/companyConfirmation/request',
  'auth/companyConfirmation/requestSuccess',
  'auth/companyConfirmation/requestFailure'
)<CompanyConfirmationDTO, LoginDTO, any>();

export const confirmCompanyActions = createRequestActions(
  'auth/confirmCompany/request',
  'auth/confirmCompany/requestSuccess',
  'auth/confirmCompany/requestFailure'
)<ValidateTokenDTO, LoginDTO, any>();
