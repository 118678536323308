import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      d="m32 3.76471c-15.5939 0-28.23529 12.64139-28.23529 28.23529s12.64139 28.2353 28.23529 28.2353 28.2353-12.6414 28.2353-28.2353-12.6414-28.23529-28.2353-28.23529zm-32 28.23529c0-17.6731 14.3269-32 32-32s32 14.3269 32 32c0 17.3532-14.7333 32.0001-32 32.0001-17.6731 0-32-14.327-32-32.0001z"
      fillRule="evenodd"
    />
    <path d="m29.9434 25.5084h4.4362v21.0721h-4.4362z" />
    <path d="m29.9434 16.6359h4.4362v4.4362h-4.4362z" />
  </IconBox>
);
