import React, { FC } from 'react';
import { Logo } from 'src/common/components/Logo';
import { StyleProps } from '@summer/react-kit';
import styled from 'styled-components';
import { LightDarkVariant } from 'src/common/models/lightDarkVariant';
import { AppHeaderNavigation } from 'src/common/components/AppHeaderNavigation';
import { HomePageRoute } from 'src/app/routes';
import { Link } from 'gatsby';
import { CleanButton } from 'src/common/components/Button';

const Container = styled.header`
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface AppHeaderProps extends StyleProps {
  logoVariant?: LightDarkVariant;
}

export const AppHeader: FC<AppHeaderProps> = ({
  logoVariant = 'dark',
  children,
  className,
  ...rest
}) => (
  <Container {...rest} className={className}>
    <CleanButton as={Link} to={HomePageRoute}>
      <Logo variant={logoVariant} />
    </CleanButton>
    {children}
    <AppHeaderNavigation />
  </Container>
);
