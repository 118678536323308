import { css } from 'styled-components';
import { mapObjIndexed } from 'ramda';

export const widthBreakpoints = {
  px576: 576,
  px768: 768,
  px992: 992,
  px1200: 1200,
  px1366: 1366,
  px1400: 1400,
  px1600: 1600,
} as const;

export const heightBreakpoints = {
  px700: 700,
  px864: 864,
} as const;

export const below = (breakpoint: number) => breakpoint - 1;

const widthMinTemplate = (width: number) => (_: ReturnType<typeof css>) => css`
  @media only screen and (min-width: ${width}px) {
    ${_};
  }
`;

const widthMaxTemplate = (width: number) => (_: ReturnType<typeof css>) => css`
  @media only screen and (max-width: ${below(width)}px) {
    ${_};
  }
`;

const widthBetweenTemplate = (min: number, max: number) => (
  _: ReturnType<typeof css>
) => {
  if (max <= min) {
    throw new Error(
      `widthBetweenTemplate was used with incorrect values. max (${max}) must be greater than min (${min})!`
    );
  }

  return css`
    @media only screen and (min-width: ${min}px) and (max-width: ${below(
        max
      )}px) {
      ${_};
    }
  `;
};

const heightMinTemplate = (height: number) => (
  _: ReturnType<typeof css>
) => css`
  @media only screen and (min-height: ${height}px) {
    ${_};
  }
`;

const heightMaxTemplate = (height: number) => (
  _: ReturnType<typeof css>
) => css`
  @media only screen and (max-height: ${below(height)}px) {
    ${_};
  }
`;

const heightBetweenTemplate = (min: number, max: number) => (
  _: ReturnType<typeof css>
) => {
  if (max <= min) {
    throw new Error(
      `heightBetweenTemplate was used with incorrect values. max (${max}) must be greater than min (${min})!`
    );
  }

  return css`
    @media only screen and (min-height: ${min}px) and (max-height: ${below(
        max
      )}px) {
      ${_};
    }
  `;
};

export const media = {
  w: {
    min: {
      ...mapObjIndexed(widthMinTemplate, widthBreakpoints),
      any: widthMinTemplate,
    },
    max: {
      ...mapObjIndexed(widthMaxTemplate, widthBreakpoints),
      any: widthMaxTemplate,
    },
    between: {
      ...mapObjIndexed(
        (min) =>
          mapObjIndexed(
            (max) => widthBetweenTemplate(min, max),
            widthBreakpoints
          ),
        widthBreakpoints
      ),
      any: widthBetweenTemplate,
    },
  },
  h: {
    min: {
      ...mapObjIndexed(heightMinTemplate, heightBreakpoints),
      any: heightMinTemplate,
    },
    max: {
      ...mapObjIndexed(heightMaxTemplate, heightBreakpoints),
      any: heightMaxTemplate,
    },
    between: {
      ...mapObjIndexed(
        (min) =>
          mapObjIndexed(
            (max) => heightBetweenTemplate(min, max),
            heightBreakpoints
          ),
        heightBreakpoints
      ),
      any: heightBetweenTemplate,
    },
  },
} as const;
