import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Seo } from 'src/public/components/Seo';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { AppRoute } from 'src/app/routes';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import { Title } from 'src/common/components/typography/Title';
import {
  ContactRoute,
  LandingRoute,
  LoginRoute,
} from 'src/public/publicRoutes';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { CenteredLayout } from 'src/public/components/CenteredLayout';
import styled from 'styled-components';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Button } from 'src/common/components/Button';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { Paragraph } from 'src/common/components/typography/Text';
import { BaseAxios } from 'src/common/services/axios';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import { SimpleLink } from 'src/common/components/SimpleLink';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { emailValidationSchema } from 'src/lib/validations';

const StyledSimpleLink = styled(SimpleLink)`
  font-size: 1rem;
`;

const initialValues = {
  email: '',
};

const InfoParagraph = styled(Paragraph)`
  margin-bottom: 1.375rem;
  margin-top: 0;
`;

const ForgotPasswordPage = () => {
  useStorePageUrl();

  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const [success, setSuccess] = useState(false);

  if (isPlatformBrowser() && isLoggedIn) {
    navigate(AppRoute);
  }

  return (
    <CenteredLayout>
      <div>
        <HomeLogoLink />
        <Seo title="Nie pamiętam hasła" />
        <Title level={1} variant="condensed">
          Nie pamiętam hasła
        </Title>

        {!success && (
          <>
            <InfoParagraph size="small">
              Podaj swój <u>prywatny</u> adres e-mail, który został podany
              w&nbsp;momencie zakładania konta (krok po podaniu maila służbowego
              w&nbsp;celu weryfikacji) i&nbsp;który był wykorzystywany do
              logowania się w serwisie. Jeżeli po podaniu go i&nbsp;kliknięciu
              przycisku ZATWIERDŹ mail z&nbsp;instrukcją zresetowania hasła nie
              przychodzi (należy sprawdzić folder SPAM), skontaktuj się
              z&nbsp;nami za pomocą adresu e-mail{' '}
              <SimpleLink href="mailto:hello@carplatform.pl">
                hello@carplatform.pl
              </SimpleLink>{' '}
              lub formularza kontaktowego zamieszczonego na stronie{' '}
              <SimpleLink as={Link} to={ContactRoute}>
                carplatform.pl/contact
              </SimpleLink>
              .
            </InfoParagraph>
            <Formik
              initialValues={initialValues}
              validationSchema={emailValidationSchema}
              onSubmit={(values, { setSubmitting }) =>
                BaseAxios.post('auth/forgot-password', values)
                  .then(() => setSuccess(true))
                  .catch(() => setSubmitting(false))
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormItem name="email">
                    <TextControl
                      type="email"
                      name="email"
                      label="Twój prywatny adres e-mail"
                    />
                  </FormItem>
                  <FormActionsContainer
                    style={{ marginBottom: '4vh' }}
                    justifyContent="space-between"
                  >
                    <Button
                      kind="box-inverted"
                      variant="transparent"
                      icon={ChevronLeft}
                      as={Link}
                      to={LoginRoute}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Wróć
                    </Button>

                    <Button
                      kind="skew"
                      size="small"
                      type="submit"
                      icon={ArrowRight}
                      disabled={isSubmitting}
                    >
                      Zatwierdź
                    </Button>
                  </FormActionsContainer>
                </Form>
              )}
            </Formik>
          </>
        )}

        {success && (
          <>
            <Paragraph style={{ marginTop: '3vh' }}>
              Jeżeli pomyślnie przeszedłeś/-aś proces rejestracji
              w&nbsp;serwisie, to na podany prywatny adres e-mail wysłana
              została instrukcja przypomnienia hasła. Jeżeli nadal masz problem
              z&nbsp;uzyskaniem dostępu do serwisu skontaktuj się z&nbsp;nami
              pisząc na adres{' '}
              <StyledSimpleLink href="mailto:hello@carplatform.pl">
                hello@carplatform.pl
              </StyledSimpleLink>
            </Paragraph>

            <Button
              kind="box-inverted"
              variant="transparent"
              icon={ChevronLeft}
              as={Link}
              to={LandingRoute}
              style={{ textTransform: 'uppercase', marginTop: '2vh' }}
            >
              Wróć
            </Button>
          </>
        )}
      </div>
    </CenteredLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default ForgotPasswordPage;
