import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { CenteredLayoutBackground } from 'src/public/components/CenteredLayoutBackground';
import { StyleProps } from '@summer/react-kit';

const Container = styled.div`
  display: grid;
  height: 100%;
  justify-content: center;
  grid-template-columns: minmax(20rem, 26rem);
  grid-template-rows: 100%;
  overflow: hidden;
  padding: 11vh 3vh 3vh;
  color: ${colors.white};
`;

export const CenteredLayout: FC<StyleProps> = ({ children, ...styleProps }) => (
  <Container {...styleProps}>
    {children}
    <CenteredLayoutBackground />
  </Container>
);
