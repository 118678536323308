import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 64 50" {...props}>
    <g transform="scale(4)">
      <path d="M15.2 0H0.8C0.358188 0 0 0.358156 0 0.8V11.4667C0 11.9085 0.358156 12.2667 0.8 12.2667H15.2C15.6418 12.2667 16 11.9085 16 11.4667V0.8C16 0.358187 15.6418 0 15.2 0ZM15.4667 11.4667C15.4667 11.614 15.3473 11.7333 15.2 11.7333H0.8C0.652719 11.7333 0.533344 11.614 0.533344 11.4667V0.8C0.533344 0.652719 0.652719 0.533344 0.8 0.533344H15.2C15.3473 0.533344 15.4667 0.652719 15.4667 0.8V11.4667Z" />
      <path d="M14.6896 1.06792C14.6192 1.06177 14.5491 1.08386 14.495 1.12936L8.34294 6.29734C8.14454 6.46413 7.85494 6.46413 7.65654 6.29734L1.50457 1.12933C1.4316 1.06808 1.33147 1.05039 1.24194 1.08296C1.15241 1.11552 1.08704 1.19339 1.07047 1.28724C1.05391 1.38108 1.08866 1.47661 1.16163 1.53786L7.31363 6.70559C7.71013 7.03944 8.28938 7.03944 8.68591 6.70559L14.8379 1.53789C14.8921 1.49243 14.9259 1.4273 14.9321 1.35683C14.9383 1.28636 14.9162 1.21633 14.8707 1.16217C14.8252 1.10796 14.7601 1.07408 14.6896 1.06792Z" />
    </g>
  </IconBox>
);
