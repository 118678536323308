import React, { FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import {
  FilterGroupPanel,
  filtersByPanel,
  groupPanelToLabelMap,
} from 'src/app/search/models/filterGroupPanel';
import { useDispatch, useSelector } from 'react-redux';
import { SearchState } from 'src/app/search/state';
import {
  groupPanelToOpenActionMap,
  searchPanelActions,
} from 'src/app/search/state/searchPanel/searchPanelActions';
import { createActiveSearchPanelComparatorSelector } from 'src/app/search/state/searchPanel/searchPanelSelectors';
import { FilterStatusInfo } from 'src/app/search/components/FilterStatusInfo';
import { media } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { FilterKind, FilterStateKindMap } from 'src/app/common/models/filter';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import pickBy from 'lodash/fp/pickBy';
import { isFilterPristine } from 'src/app/common/models/filterConfig';
import { createSearchFilterStateByKindsSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import ChevronDown from 'src/assets/icons/ChevronDown';
import { isEmpty } from 'ramda';

const Container = styled.div`
  position: relative;
  margin-right: 0.5rem;

  ${media.w.max.px1200(css`
    display: none;
  `)};
`;

export interface SearchHeaderFilterButtonProps {
  group: FilterGroupPanel;
  className?: string;
}

const StyledButton = styled(Button)<{
  active?: boolean;
}>`
  ${({ active }) =>
    active &&
    css`
      .btn__content {
        background: ${colors.primary}!important;
        color: ${colors.white};
      }
    `};
`;

export const SearchHeaderFilter: FC<SearchHeaderFilterButtonProps> = ({
  group,
}) => {
  const activeSelector = useMemo(
    () => createActiveSearchPanelComparatorSelector(),
    []
  );
  const isActive = useSelector((state: SearchState) =>
    activeSelector(state, group)
  );

  const dispatch = useDispatch();
  const onClickHandler = useCallback(() => {
    if (!isActive) {
      dispatch(groupPanelToOpenActionMap[group]());
    } else {
      dispatch(searchPanelActions.close());
    }
  }, [isActive, dispatch]);

  const filterSelector = useMemo(
    () => createSearchFilterStateByKindsSelector(),
    []
  );
  const filters: {
    [P in FilterKind]?: FilterStateKindMap[P];
  } = useSelectorTransform(
    (state: SearchState) => filterSelector(state, filtersByPanel[group]),
    pickBy(
      (state: FilterStateKindMap[FilterKind], key) =>
        !isFilterPristine(key as FilterKind, state.value)
    )
  );

  return (
    <Container>
      <StyledButton
        kind="dropdown"
        icon={ChevronDown}
        active={isActive}
        onClick={onClickHandler}
      >
        {groupPanelToLabelMap[group]}
      </StyledButton>
    </Container>
  );
};
