import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { useSelector } from 'react-redux';
import { Button } from 'src/common/components/Button';
import { transparentize } from 'polished';
import { CleanUnorderedList, ListItem } from 'src/common/components/List';
import { AccountPageRoute } from 'src/app/routes';
import { Link } from 'gatsby';
import {
  AboutUsRoute,
  CarPlatformBusinessRoute,
  ContactRoute,
  FaqRoute,
  HowItWorksRoute,
  PrivacyPolicyAndCookiesRoute,
  TosRoute,
} from 'src/public/publicRoutes';
import { userSelector } from 'src/state/auth/authSelectors';
import { isSome } from 'fp-ts/lib/Option';
import { prune } from '@summer/react-kit/functions';
import { MainMenuB2BB2CSwitch } from 'src/common/components/MainMenuB2BB2CSwitch';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const UserMainInfo = styled.div`
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.25;
  text-transform: uppercase;
`;

const UserSecondaryInfo = styled(CleanUnorderedList)`
  margin: 0.5rem 0;
  font-size: 0.75rem;
  line-height: 1.25;
  text-transform: uppercase;
  color: ${transparentize(0.2, colors.lightGray)};
`;

const Navigation = styled.nav`
  text-transform: uppercase;
`;

const EmphasizedListItem = styled(ListItem)`
  font-weight: bold;
  font-size: 1.375rem;
`;

export interface MainMenuProps {
  onNavigate?: () => void;
}

export const MainMenu: FC<MainMenuProps> = ({ onNavigate }) => {
  const user = useSelector(userSelector);

  return (
    <>
      {isSome(user) && (
        <>
          <section>
            <UserMainInfo>
              <span>
                {user.value.firstName} {user.value.lastName}
              </span>
              <br />
              <span>{user.value.businessPartner?.name}</span>
            </UserMainInfo>
            <UserSecondaryInfo>
              {prune(
                [user.value.phone, user.value.email].map((a, i) => (
                  <ListItem key={i}>{a}</ListItem>
                ))
              )}
            </UserSecondaryInfo>
            <CleanUnorderedList>
              <ListItem>
                <Button
                  kind="link"
                  as={Link}
                  to={AccountPageRoute}
                  onClick={onNavigate}
                  variant="secondary"
                >
                  MOJE KONTO
                </Button>
              </ListItem>
            </CleanUnorderedList>
          </section>

          <section>
            <MainMenuB2BB2CSwitch />
          </section>
        </>
      )}

      <Navigation>
        <CleanUnorderedList>
          <EmphasizedListItem>
            <Button
              kind="link"
              as={Link}
              to={AboutUsRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'o_car_platform',
                });
              }}
              variant="secondary"
            >
              O&nbsp;Car&nbsp;Platform
            </Button>
          </EmphasizedListItem>
          <EmphasizedListItem>
            <Button
              kind="link"
              as={Link}
              to={HowItWorksRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'jak_dziala_car_platform',
                });
              }}
              variant="secondary"
            >
              Jak działa Car&nbsp;Platform?
            </Button>
          </EmphasizedListItem>
          <EmphasizedListItem>
            <Button
              kind="link"
              as={Link}
              to={FaqRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'faq',
                });
              }}
              variant="secondary"
            >
              FAQ
            </Button>
          </EmphasizedListItem>
          <EmphasizedListItem>
            <Button
              kind="link"
              as={Link}
              to={ContactRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'pomoc_i_kontakt',
                });
              }}
              variant="secondary"
            >
              Pomoc i kontakt
            </Button>
          </EmphasizedListItem>
          <EmphasizedListItem>
            <Button
              kind="link"
              as={Link}
              to={CarPlatformBusinessRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'car_platform_business',
                });
              }}
              variant="secondary"
            >
              Car Platform Business
            </Button>
          </EmphasizedListItem>
        </CleanUnorderedList>

        <CleanUnorderedList>
          <ListItem>
            <Button
              kind="link"
              as={Link}
              to={TosRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'regulamin_serwisu',
                });
              }}
              variant="secondary"
            >
              Regulamin serwisu
            </Button>
          </ListItem>
          <ListItem>
            <Button
              kind="link"
              as={Link}
              to={PrivacyPolicyAndCookiesRoute}
              onClick={() => {
                onNavigate && onNavigate();
                pushEvent(EventName.TopMenu, {
                  menu_type: 'hamburger',
                  menu_item: 'polityka_prawatnosci_i_cookies',
                });
              }}
              variant="secondary"
            >
              Polityka prywatności i cookies
            </Button>
          </ListItem>
        </CleanUnorderedList>
      </Navigation>
    </>
  );
};
