import { createRequestActions } from 'src/lib/requestActions';
import { PartialProduct } from 'src/app/common/models/product';

export const findUsedProductsRequestActions = createRequestActions(
  'searchUsedProducts/findUsedProducts/request',
  'searchUsedProducts/findUsedProducts/requestSuccess',
  'searchUsedProducts/findUsedProducts/requestFailure'
)<
  void,
  { products: PartialProduct[]; total: number },
  Record<string, unknown>
>();
