import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8541 3.3946c-14.0609 0-25.4595 11.3986-25.4595 25.4595 0 14.0609 11.3986 25.4596 25.4595 25.4596 14.0609 0 25.4596-11.3987 25.4596-25.4596 0-14.0609-11.3987-25.4595-25.4596-25.4595zM0 28.8541C0 12.9184 12.9184 0 28.8541 0c15.9357 0 28.8542 12.9184 28.8542 28.8541 0 15.9357-12.9185 28.8542-28.8542 28.8542C12.9184 57.7083 0 44.7898 0 28.8541z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.2343 46.8315l14.7666 14.7666-2.4004 2.4003L46.834 49.2319l2.4003-2.4004zM43.9575 5.10181l.3441 3.37712-12.2402 1.24692-5.1085 2.21025-3.7013 10.7674-3.5045-.8762.258.2294 1.3741 2.0612-1.6135 2.4201-.2824 7.0614 2.1785 7.4692v1.8797l1.1113.4167h3.8196l1.6778-.5034V40.239h24.3456v3.3946h-20.951v1.7537l-4.5742 1.3723h-4.9334l-3.8903-1.4589v-3.7472l-2.198-7.5358.3428-8.5688.8557-1.2836-2.8865-2.5658-1.2796-5.1184h6.951l1.492.746 2.6852-7.81145 6.9654-3.01373 12.7623-1.30011zM19.205 21.3455v-1.4698h-1.6536l1.6536 1.4698zm35.0702-2.3632l.0764 3.3938-27.7843.6252-.0763-3.3937 27.7842-.6253z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.041 26.1755h13.5317l2.3519 8.232-10.133-1.8424-5.7506-6.3896zm8.2547 3.3946l2.8652.521-.1488-.521h-2.7164z"
    />
  </IconBox>
);
