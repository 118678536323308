import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Portal } from 'src/app/common/components/Portal';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { CSSTransition } from 'react-transition-group';
import {
  acceleratedEasing,
  deceleratedEasing,
} from 'src/common/styles/animations';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { Button } from 'src/common/components/Button';
import CloseBold from 'src/assets/icons/CloseBold';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { yiq } from '@summer/react-kit/functions';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import FocusTrap from 'focus-trap-react';
import { typography } from 'src/config/typography';
import { media } from 'src/config/breakpoints';

const animationDuration = 200;

const Overlay = styled.div<{ background?: string; blur?: number }>`
  background: ${({ background }) => background ?? colors.white};
  color: ${({ background }) =>
    yiq(background ?? colors.white, { dark: colors.grayBasic })};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;

  ${({ blur }) =>
    blur &&
    css`
      backdrop-filter: blur(${blur}px);
    `};

  &.appear {
    opacity: 0;
    transform: translateY(1rem);
  }

  &.appear-active {
    opacity: 1;
    transition: opacity ${animationDuration}ms ${deceleratedEasing},
      transform ${animationDuration}ms ${deceleratedEasing};
    transform: translateY(0);
  }

  &.exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.exit-active {
    opacity: 0;
    transition: opacity ${animationDuration}ms ${acceleratedEasing},
      transform ${animationDuration}ms ${acceleratedEasing};
    transform: translateY(1rem);
  }
`;

const Header = styled(ConstraintContainer).attrs({ as: 'header' })`
  flex: 0 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyCondensed};
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;

  ${media.w.min.px768(css`
    font-size: 1.25rem;
  `)};
`;

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  background?: string;
  blur?: number;
  title?: ReactNode;
}

export const ModalPortal: FC<ModalProps> = ({
  open,
  onClose,
  title,
  background,
  blur,
  children,
}) => {
  const [mounted, setMounted] = useState(open);
  const initialFocusEl = useRef<any>();

  useEffect(() => {
    if (open && !mounted) {
      setMounted(true);

      const handlePress = (event: KeyboardEvent) => {
        if (event.keyCode === KEY_ESCAPE || event.code === CODE_ESCAPE) {
          onClose();
        }
      };

      window.addEventListener('keydown', handlePress);
      return () => {
        window.removeEventListener('keydown', handlePress);
      };
    }
  }, [open, onClose]);

  if (!mounted) {
    return <></>;
  }

  return (
    <Portal>
      <CSSTransition
        in={open}
        appear={true}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
        onExited={() => setMounted(false)}
      >
        <FocusTrap
          focusTrapOptions={{ initialFocus: () => initialFocusEl.current }}
        >
          <Overlay background={background} blur={blur}>
            <Header>
              {title && <Title>{title}</Title>}
              <div
                ref={initialFocusEl}
                style={{ width: 0, height: 0, outline: 'none' }}
                tabIndex={-1}
              />
              <Button
                icon={CloseBold}
                kind="box-inverted"
                variant="transparent"
                onClick={onClose}
              >
                zamknij
              </Button>
            </Header>
            <ScrollContainer style={{ flex: 1 }}>{children}</ScrollContainer>
          </Overlay>
        </FocusTrap>
      </CSSTransition>
    </Portal>
  );
};
