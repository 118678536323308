import React from 'react';
import { StyleProps } from '@summer/react-kit';
import { OptionFilter } from 'src/app/common/models/filter';
import { ConnectedColorOptionFilter } from 'src/app/search/components/SearchPanel/ConnectedColorOptionFilter';

export const SearchBodyColorAppearanceFilter = (props: StyleProps) => (
  <ConnectedColorOptionFilter
    columns={{ desktop: 3, mobile: 2 }}
    kind={OptionFilter.PaintColorGroup}
    {...props}
  />
);
