import React, { Suspense } from 'react';
import { Seo } from 'src/public/components/Seo';
import { App } from 'src/app/App';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const IndexPage = () => {
  useStorePageUrl();

  return typeof window === 'undefined' ? (
    <Seo />
  ) : (
    <>
      <Seo />
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default IndexPage;
