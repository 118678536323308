import { DefaultRootState, useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useSelectorTransform = <
  TState = DefaultRootState,
  TSelected = unknown,
  TOutput = unknown
>(
  selector: (state: TState) => TSelected,
  transformer: (selected: TSelected) => TOutput,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TOutput => {
  const selected = useSelector(selector, equalityFn);
  return useMemo(() => transformer(selected), [selected]);
};
