import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5108 43.7644C13.9905 43.7644 16.0007 41.7542 16.0007 39.2745C16.0007 36.7948 13.9905 34.7846 11.5108 34.7846C9.03111 34.7846 7.02091 36.7948 7.02091 39.2745C7.02091 41.7542 9.03111 43.7644 11.5108 43.7644ZM11.5108 44.6056C14.4551 44.6056 16.842 42.2188 16.842 39.2745C16.842 36.3302 14.4551 33.9434 11.5108 33.9434C8.56652 33.9434 6.17969 36.3302 6.17969 39.2745C6.17969 42.2188 8.56652 44.6056 11.5108 44.6056Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5106 42.4838C13.2837 42.4838 14.7211 41.0464 14.7211 39.2733C14.7211 37.5003 13.2837 36.0629 11.5106 36.0629C9.73757 36.0629 8.30021 37.5003 8.30021 39.2733C8.30021 41.0464 9.73757 42.4838 11.5106 42.4838ZM11.5106 43.325C13.7483 43.325 15.5623 41.511 15.5623 39.2733C15.5623 37.0357 13.7483 35.2217 11.5106 35.2217C9.27298 35.2217 7.45898 37.0357 7.45898 39.2733C7.45898 41.511 9.27298 43.325 11.5106 43.325Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.6436 43.7644C51.1233 43.7644 53.1335 41.7542 53.1335 39.2745C53.1335 36.7948 51.1233 34.7846 48.6436 34.7846C46.1639 34.7846 44.1537 36.7948 44.1537 39.2745C44.1537 41.7542 46.1639 43.7644 48.6436 43.7644ZM48.6436 44.6056C51.5879 44.6056 53.9748 42.2188 53.9748 39.2745C53.9748 36.3302 51.5879 33.9434 48.6436 33.9434C45.6993 33.9434 43.3125 36.3302 43.3125 39.2745C43.3125 42.2188 45.6993 44.6056 48.6436 44.6056Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.6454 42.4838C50.4185 42.4838 51.8558 41.0464 51.8558 39.2733C51.8558 37.5003 50.4185 36.0629 48.6454 36.0629C46.8723 36.0629 45.435 37.5003 45.435 39.2733C45.435 41.0464 46.8723 42.4838 48.6454 42.4838ZM48.6454 43.325C50.8831 43.325 52.6971 41.511 52.6971 39.2733C52.6971 37.0357 50.8831 35.2217 48.6454 35.2217C46.4077 35.2217 44.5938 37.0357 44.5938 39.2733C44.5938 41.511 46.4077 43.325 48.6454 43.325Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8491 19H54.8621L56.0821 20.0167L55.8569 20.2868H56.3288L57.3294 24.4893L58.2533 30.9561L58.5921 35.3611H60.17L58.4839 38.7333L54.0465 39.5051L53.9024 38.6763L57.9214 37.9773L58.8089 36.2023H57.8131L57.4166 31.048L56.5021 24.6465L55.6644 21.128H13.8606L9.14885 28.7394L6.23248 30.431L4.57655 31.7557V35.2206L3.84122 35.9559V38.6593L5.18409 39.4054H6.36193V40.2466H4.96611L3 39.1543V35.6075L3.73533 34.8721V31.3514L5.75604 29.7348L8.5445 28.1174L13.392 20.2868H14.9145L14.8477 19.9262L19.8491 19ZM17.5201 20.2868H55.0922L54.5575 19.8412H19.9264L17.5201 20.2868ZM43.496 38.7759H16.4727V37.9347H43.496V38.7759ZM16.8404 39.773H43.496V40.6143H16.8404V39.773Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5423 29.7673L57.1048 29.2161L57.0921 28.375L20.4893 28.9268L5.57895 30.5835L5.67184 31.4196L20.5423 29.7673ZM57.6586 31.4221L4.53113 32.5251L4.51367 31.684L57.6412 30.581L57.6586 31.4221Z"
    />
  </IconBox>
);
