import styled, { css } from 'styled-components';
import React, { memo, useState } from 'react';
import coveredCar from 'src/assets/images/covered-car.png';
import { media } from 'src/config/breakpoints';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { RequestProductSectionForm } from 'src/app/search/components/RequestProductSection/RequestProductSectionForm';
import { RequestProductSectionFormSummary } from 'src/app/search/components/RequestProductSection/RequestProductSectionFormSummary';
import { StyleProps } from '@summer/react-kit';

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${media.w.min.px992(css`
    grid-template-columns: 50% 50%;
    font-size: 1.25rem;
  `)};
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1rem;

  ${media.w.min.px768(css`
    margin-top: 7.5rem;
    width: calc(100% - 2rem);
    transform: translateX(-1rem);
  `)}
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const RequestProductSection = memo<StyleProps>((props) => {
  const [formState, setFormState] = useState<'form' | 'summary'>('form');

  return (
    <ConstraintContainer {...props}>
      <ContentContainer>
        <Image src={coveredCar} alt="Zakryty samochód" />
        <FormContainer>
          {formState === 'form' ? (
            <RequestProductSectionForm
              onSuccess={() => setFormState('summary')}
            />
          ) : (
            <RequestProductSectionFormSummary />
          )}
        </FormContainer>
      </ContentContainer>
    </ConstraintContainer>
  );
});
