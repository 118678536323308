import { useEffect } from 'react';
import {
  findOfferWithParams,
  getPackageTypeFilter,
} from 'src/app/product/services/helpers';
import { FinancingType, Offer } from 'src/common/models/offer';
import { productsActions } from 'src/app/product/state/products/productsActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOfferSelector,
  availableOffersSelector,
  financingTypesListSelector,
  productTypeSelector,
} from 'src/app/product/state/products/productsSelectors';
import { Product } from 'src/app/common/models/product';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';

export interface UseFindOfferOnCustomerTypeChangeParams {
  initialOffer?: Offer;
  leasingOffers: Offer[];
  product: Product;
}

export const useFindOfferOnCustomerTypeChange = ({
  initialOffer,
  leasingOffers,
  product,
}: UseFindOfferOnCustomerTypeChangeParams) => {
  const dispatch = useDispatch();

  const activeOffer = useSelector(activeOfferSelector);
  const availableOffers = useSelector(availableOffersSelector);
  const financingTypesList = useSelector(financingTypesListSelector);
  const productType = useSelector(productTypeSelector);
  const userCustomerType = useSelector(customerTypeSelector);

  useEffect(() => {
    let offer = activeOffer || initialOffer;

    if (
      product.offers.find(
        (offer) => offer.external?.type === 'artefakta4internal'
      ) != null &&
      leasingOffers.length === 0
    ) {
      return;
    }

    if (
      offer?.financingType &&
      !financingTypesList.includes(offer.financingType)
    ) {
      offer = findOfferWithParams(
        {
          customerType: userCustomerType,
        },
        {
          annualMileage: offer?.annualMileage,
          buyoutPrice: offer?.buyoutPrice,
          contractPeriod: offer?.contractPeriod,
          initialPayment: offer?.initialPayment,
        }
      )(availableOffers);
    }

    const isArtefakta4internal =
      offer?.financingType === FinancingType.Leasing &&
      leasingOffers.length > 0;

    const baseOffer = isArtefakta4internal
      ? product.offers.find(
          (offer) => offer.external?.type === 'artefakta4internal'
        ) || undefined
      : undefined;

    dispatch(
      productsActions.setActiveOffer(
        findOfferWithParams(
          {
            customerType: isArtefakta4internal ? undefined : userCustomerType,
            financingType: offer?.financingType,
            packageType: getPackageTypeFilter(offer, productType),
          },
          {
            annualMileage: offer?.annualMileage,
            buyoutPrice: offer?.buyoutPrice,
            contractPeriod: offer?.contractPeriod,
            initialPayment: offer?.initialPayment,
          }
        )(
          isArtefakta4internal ? leasingOffers : availableOffers,
          baseOffer,
          userCustomerType
        )
      )
    );
  }, [financingTypesList, leasingOffers, userCustomerType]);
};
