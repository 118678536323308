import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { authSagas } from 'src/state/auth/authSagas';
import { createRootReducer, RootState } from 'src/state/reducer';
import {
  createReducerInjector,
  createSagaInjector,
} from 'src/lib/reduxInjectors';
import { companyConfirmationSagas } from 'src/state/companyConfirmation/companyConfirmationSagas';
import { businessPartnerSaga } from 'src/state/businessPartner/businessPartnerSagas';

function* rootSaga() {
  yield all([authSagas(), companyConfirmationSagas(), businessPartnerSaga()]);
}

const rootStateFactory = () => {
  const sagaMiddleware = createSagaMiddleware();
  const { injectSaga, deleteSaga } = createSagaInjector(sagaMiddleware.run);
  const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

  const rootReducer = createRootReducer();

  const store = createStore(combineReducers<RootState>(rootReducer), enhancer);
  const persistor = persistStore(store);
  const { injectReducer, deleteReducer } = createReducerInjector(
    rootReducer,
    store.replaceReducer
  );
  injectSaga('root', rootSaga);

  return {
    store,
    persistor,
    injectReducer,
    deleteReducer,
    injectSaga,
    deleteSaga,
  };
};

export const {
  store,
  persistor,
  injectReducer,
  deleteReducer,
  injectSaga,
  deleteSaga,
} = rootStateFactory();
