import { Seo } from 'src/public/components/Seo';
import { Button } from 'src/common/components/Button';
import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'src/common/components/typography/Text';
import { Title } from 'src/common/components/typography/Title';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'src/lib/useRequest';
import { CompanyConfirmationViewState } from 'src/common/models/companyConfirmationViewState';
import {
  companyConfirmationActions,
  companyConfirmationViewActions,
} from 'src/state/companyConfirmation/companyConfirmationActions';
import {
  userBusinessPartnerNameSelector,
  userCompanyEmailSelector,
} from 'src/state/auth/authSelectors';
import ArrowRight from 'src/assets/icons/ArrowRight';

const ButtonRow = styled.div`
  margin-bottom: 5vh;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ButtonRowText = styled.div`
  margin-bottom: 1rem;
`;

const InfoParagraph = styled(Paragraph)`
  margin-bottom: 2rem;
  margin-top: 0;
`;

const StyledTitle = styled(Title)`
  letter-spacing: 0.0625rem;
  padding-right: 2rem;
`;

export const CompanyConfirmationMain = () => {
  const dispatch = useDispatch();

  const companyEmail = useSelector(userCompanyEmailSelector);
  const companyName = useSelector(userBusinessPartnerNameSelector);

  const { isLoading } = useRequest(companyConfirmationActions.request);

  const companyChangeHandler = () => {
    dispatch(
      companyConfirmationViewActions.setCompanyConfirmationViewState(
        CompanyConfirmationViewState.Email
      )
    );
  };

  const companyConfirmationHandler = () => {
    dispatch(companyConfirmationActions.request({ companyEmail }));
  };

  return (
    <>
      <Seo title="Potwierdź status zatrudnienia" />

      <StyledTitle level={1} variant="condensed">
        Potwierdź status Twojego zatrudnienia
      </StyledTitle>

      <section>
        <InfoParagraph size="small">
          Z&nbsp;uwagi na bezpieczeństwo Twojego konta oraz możliwość dostępu do
          ofert prosimy o&nbsp;potwierdzenie Twojego aktualnego statusu
          zatrudnienia.
        </InfoParagraph>

        <ButtonRow>
          <ButtonRowText>
            <strong>TAK</strong> – nadal pracuję w&nbsp;{companyName}
          </ButtonRowText>

          <Button
            kind="skew"
            size="small"
            icon={ArrowRight}
            disabled={isLoading}
            onClick={companyConfirmationHandler}
          >
            Potwierdź
          </Button>
        </ButtonRow>

        <ButtonRow>
          <ButtonRowText>
            <strong>NIE</strong> – zmieniłem pracę w&nbsp;ostatnim czasie
          </ButtonRowText>

          <Button
            kind="skew"
            size="small"
            icon={ArrowRight}
            disabled={isLoading}
            onClick={companyChangeHandler}
          >
            Aktualizuj
          </Button>
        </ButtonRow>
      </section>
    </>
  );
};
