import { createReducer } from '@reduxjs/toolkit';
import { authActions } from 'src/state/auth/authActions';
import { PartialProduct } from 'src/app/common/models/product';
import { findUsedProductsRequestActions } from 'src/app/search/state/searchProducts/searchUsedProductsActions';

export interface SearchUsedProductsState {
  products: PartialProduct[];
  total: number;
}

const initialState: SearchUsedProductsState = {
  products: [],
  total: 0,
};

export const searchUsedProductsReducer = createReducer<SearchUsedProductsState>(
  initialState,
  (builder) =>
    builder
      .addCase(
        findUsedProductsRequestActions.requestSuccess,
        (state, action) => {
          state.products = action.payload.products;
          state.total = action.payload.total;
        }
      )

      .addCase(authActions.logout, () => initialState)
);
