import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0002 33.1867L2.39533 62.7915L1 61.3962L30.6049 31.7913L1.20886 2.39533L2.60419 1L32.0002 30.396L61.3962 1L62.7915 2.39533L33.3955 31.7913L63.0004 61.3962L61.6051 62.7915L32.0002 33.1867Z"
    />
  </IconBox>
);
