import { createRequestActions } from 'src/lib/requestActions';
import { HomeAdvertDeal } from 'src/app/home/models/homeAdvertDeal';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';

export const homeAdvertDealsActions = createRequestActions(
  `homeAdvert/deals/request`,
  `homeAdvert/deals/requestSuccess`,
  `homeAdvert/deals/requestFailure`
)<void, HomeAdvertDeal[], Record<string, unknown>>();

export const homeAdvertSlidesActions = createRequestActions(
  `homeAdvert/slides/request`,
  `homeAdvert/slides/requestSuccess`,
  `homeAdvert/slides/requestFailure`
)<void, HomeAdvertSlideType[], Record<string, unknown>>();
