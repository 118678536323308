import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const SectionHeading = styled.h2`
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.325rem;
  text-align: center;
  text-transform: uppercase;

  ${media.w.min.px992(css`
    font-size: 1.875rem;
    line-height: 3.625rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  `)}
`;
