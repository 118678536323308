import { HomeState } from 'src/app/home/state';
import { createSelector } from 'reselect';
import { HomeAdvertState } from 'src/app/home/state/homeAdvert/homeAdvertReducer';

export const homeAdvertStateSelector = (state: HomeState) => state.homeAdvert;

export const homeAdvertDealsSelector = createSelector(
  homeAdvertStateSelector,
  (state: HomeAdvertState) => state.deals
);

export const homeAdvertSlidesSelector = createSelector(
  homeAdvertStateSelector,
  (state: HomeAdvertState) => state.slides
);
