import React, { ComponentType, FC, PropsWithChildren } from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { StyleProps } from '@summer/react-kit';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import {
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';
import { settingsActions } from 'src/state/settings/settingsActions';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { fade } from '@material-ui/core';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const newUsedProductToggleButtonItems = [
  { id: 'new', label: 'Nowe' },
  { id: 'used', label: 'Używane' },
];

const StyledHorizontalToggleGroupContainer = styled(
  HorizontalToggleGroupContainer
)`
  border: 0.0625rem solid ${fade(colors.grayBasic, 0.2)};

  & > * :not(:last-of-type) {
    &:after {
      background-color: ${colors.white};
    }
  }
`;

export interface NewUsedProductsPreferenceSwitchProps extends StyleProps {
  variant?: ToggleButtonVariant;
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
}

const StyledHorizontalToggleButton = styled(HorizontalToggleButton)`
  color: ${colors.grayBasic};

  &:enabled {
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.lightGray};
        color: ${colors.grayBasic};
      }
    }

    &:focus {
      border-color: ${colors.white};
    }
  }

  ${({ value }) =>
    value &&
    css`
      color: ${colors.white};
      background-color: ${colors.grayBasic};

      &:enabled {
        @media (hover: hover) {
          &:hover {
            background-color: ${colors.lightGray};
            color: ${colors.grayBasic};
          }
        }

        &:focus {
          border-color: ${colors.white};
        }
      }
    `}
  &:disabled {
    opacity: 0.3;
  }
`;

export const NewUsedProductsPreferenceSwitch: FC<NewUsedProductsPreferenceSwitchProps> = ({
  variant,
  style,
  className,
  toggleButtonComponent,
}) => {
  const dispatch = useDispatch();
  const productsPreference = useSelector(productsPreferenceSelector);

  const handlePreferenceChange = (v: string[]) => {
    dispatch(
      settingsActions.setProductsPreference(
        v[0] ? (v[0] as 'new' | 'used') : productsPreference
      )
    );

    const preference = newUsedProductToggleButtonItems.find(
      (item) => item.id === v[0]
    );

    preference &&
      pushEvent(EventName.SelectCartype, {
        car_type: preference.id === 'new' ? 'nowe' : 'uzywane',
      });
  };

  return (
    <StyledHorizontalToggleGroupContainer style={style} className={className}>
      <ToggleButtonGroup
        variant={variant}
        limit={1}
        value={[productsPreference]}
        onChange={(v) => handlePreferenceChange(v)}
        items={newUsedProductToggleButtonItems}
        toggleButtonComponent={
          toggleButtonComponent
            ? toggleButtonComponent
            : StyledHorizontalToggleButton
        }
      />
    </StyledHorizontalToggleGroupContainer>
  );
};
