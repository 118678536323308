import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Keyboard } from 'swiper';
import { GalleryModalSlider } from 'src/app/product/components/GalleryModal/GalleryModalSlider';
import { GalleryModalThumbnailSlider } from 'src/app/product/components/GalleryModal/GalleryModalThumbnailSlider';
import { GalleryItem } from 'src/app/product/components/GalleryModal/galleryItem';
import { media } from 'src/config/breakpoints';

SwiperCore.use([Keyboard]);

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: minmax(0, 100%);

  ${media.w.min.px1200(css`
    min-height: 30rem;
  `)};
`;

export interface GalleryModalProps {
  items: GalleryItem[];
  initialSlide?: number;
  thumbnails?: boolean;
}

export const GalleryModal = memo<GalleryModalProps>(
  ({ items, initialSlide, thumbnails = true }) => {
    const [sliderInstance, setSliderInstance] = useState<
      SwiperCore | undefined
    >();

    const [thumbsInstance, setThumbsInstance] = useState<
      SwiperCore | undefined
    >();

    const [initialSlideLocal] = useState(initialSlide ?? 0);

    useEffect(() => {
      if (!thumbnails) {
        setThumbsInstance(undefined);
      }
    }, [thumbnails]);

    useLayoutEffect(() => {
      if (sliderInstance && thumbsInstance) {
        sliderInstance.on('slideChange', (s) => {
          thumbsInstance.slideTo(s.realIndex);
        });

        sliderInstance.update();
        thumbsInstance.update();

        return () => {
          sliderInstance?.off('slideChange');
        };
      }
    }, [thumbsInstance, sliderInstance]);

    useLayoutEffect(() => {
      if (thumbsInstance != null) {
        thumbsInstance.slideTo(initialSlideLocal, 0);
      }
    }, [thumbsInstance]);

    useEffect(() => {
      const disableContextMenu = (event: MouseEvent) => event.preventDefault();
      window.addEventListener('contextmenu', disableContextMenu);

      return () => {
        window.removeEventListener('contextmenu', disableContextMenu);
        sliderInstance?.destroy();
        thumbsInstance?.destroy();
      };
    }, []);

    const onThumbClick = useCallback(
      (i: number) => sliderInstance?.slideTo(i),
      [sliderInstance]
    );

    return (
      <Container>
        <GalleryModalSlider
          items={items}
          initialSlide={initialSlideLocal}
          setSliderInstance={setSliderInstance}
          sliderInstance={sliderInstance}
          thumbsInstance={thumbsInstance}
        />

        {thumbnails && (
          <GalleryModalThumbnailSlider
            items={items}
            setThumbsInstance={setThumbsInstance}
            sliderInstance={sliderInstance}
            thumbsInstance={thumbsInstance}
            onThumbClick={onThumbClick}
          />
        )}
      </Container>
    );
  }
);
