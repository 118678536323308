import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.3125rem;
  margin-bottom: 0.1875rem;
`;

const Title = styled.div`
  font-size: 0.75rem;
  color: ${colors.white};
  font-family: ${typography.fontFamilyCondensed};
  line-height: 1.25;
  vertical-align: middle;
`;

const Subtitle = styled.div`
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.625rem;
  line-height: 1.25;
  margin-left: auto;
  color: ${colors.white};
  opacity: 0.6;
  vertical-align: middle;
`;

export interface OfferParameterHeaderProps {
  title: string;
  subtitle?: string;
}

export const OfferParameterHeader: FC<OfferParameterHeaderProps> = ({
  title,
  subtitle,
}) => (
  <Container>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Container>
);
