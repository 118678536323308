import { rem } from 'polished';
import React, { FC, ReactNode, useCallback } from 'react';
import {
  FilterBoxToggleButton,
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { Label } from 'src/app/common/components/typography/Label';
import { SearchFilterOptionsGrid } from 'src/app/search/components/SearchPanel/SearchFilterOptions';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { Icon } from 'src/common/components/Icon';
import { MOTORCYCLE_ID } from 'src/common/constants';
import { OptionFilter } from 'src/app/common/models/filter';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';

const Container = styled.div`
  padding: -0.1875rem;
`;

const ModelsOptionsGrid = styled(SearchFilterOptionsGrid)`
  margin: 0;

  ${media.w.max.px992(css`
    flex-wrap: nowrap;
  `)}
`;

const ModelToggleButton = styled(FilterBoxToggleButton)`
  width: 6.25rem;
  height: 5rem;
  border-radius: 0.125rem;
  padding: 0.25rem;
`;

interface SearchModelToggleGroupProps {
  brand: {
    id: string;
    label: ReactNode;
    options: { id: string; label: ReactNode }[];
  };
  value: string[];
  onChange: (brand: string, value: string[]) => void;
}

export const SearchModelToggleGroup: FC<SearchModelToggleGroupProps> = ({
  brand,
  value,
  onChange,
}) => {
  const onChangeHandler = useCallback(
    (value: string[]) => onChange(brand.id, value),
    [brand.id, value]
  );

  const { state: brandState } = useOptionFilterControl(OptionFilter.Brand);

  let items = brand.options;
  if (brandState.value[0] === MOTORCYCLE_ID && brandState.value.length === 1) {
    items = brand.options.map((option, index) => ({
      id: option.id,
      label: (
        <OptionalFold
          key={index}
          value={brandIconMapper(
            React.isValidElement(option.label) ? option.label.props.label : ''
          )}
          onSome={(code) => (
            <FilterBoxToggleButtonIcon>
              <Icon
                code={code}
                size={Math.round(36 * brandIconResizeRates[code])}
              />
            </FilterBoxToggleButtonIcon>
          )}
          onNone={
            <FilterBoxToggleButtonTitle>
              {React.isValidElement(option.label)
                ? (option.label as React.ReactElement).props.label
                : ''}
            </FilterBoxToggleButtonTitle>
          }
        />
      ),
    }));
  }

  return (
    <Container>
      <ModelsOptionsGrid>
        <ToggleButtonGroup
          items={items}
          value={value}
          toggleButtonComponent={ModelToggleButton}
          onChange={onChangeHandler}
        />
      </ModelsOptionsGrid>
    </Container>
  );
};

const StyledFilterBoxToggleButtonImg = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
`;

const StyledFilterBoxToggleButtonTitle = styled(FilterBoxToggleButtonTitle)`
  flex: 1;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  overflow: visible;
`;

const StyledFilterBoxToggleButtonIcon = styled(FilterBoxToggleButtonIcon)`
  height: 2.25rem;
  flex: 0 0 2.25rem;

  & + ${StyledFilterBoxToggleButtonTitle} {
    margin-top: 0.25rem;
  }
`;

export const SearchModelToggleGroupItemLabel: FC<{
  imageUrl?: string;
  label: string;
}> = ({ imageUrl, label }) => (
  <>
    <StyledFilterBoxToggleButtonIcon>
      <StyledFilterBoxToggleButtonImg
        alt={label}
        src={imageUrl}
        draggable={false}
      />
    </StyledFilterBoxToggleButtonIcon>
    <StyledFilterBoxToggleButtonTitle>{label}</StyledFilterBoxToggleButtonTitle>
  </>
);
