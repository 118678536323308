import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => {
  const id0 = `${props.color?.substring(1)}0_linear`;
  const id1 = `${props.color?.substring(1)}1_linear`;
  const fill0 = `url(#${id0})`;
  const fill1 = `url(#${id1})`;

  return (
    <IconBox {...props}>
      <path
        opacity="0.9"
        d="M44.5009 39L3.78 39L3.78 -1.35453e-06L63 3.8147e-06L44.5009 39Z"
        fill={fill0}
      />
      <path
        d="M47.6475 23.9023L27.7202 23.9023L27.7202 5.03138L56.7002 5.03138L47.6475 23.9023Z"
        fill={fill1}
      />
      <defs>
        <linearGradient
          id={id0}
          x1="63.0003"
          y1="-0.00109719"
          x2="27.3211"
          y2="33.4236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.color} />
          <stop offset="1" stopColor={props.color} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={id1}
          x1="56.7003"
          y1="5.03084"
          x2="42.4625"
          y2="15.6626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.color} />
          <stop offset="1" stopColor={props.color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </IconBox>
  );
};
