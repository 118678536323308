import React, { FC } from 'react';
import { FieldDescription } from 'src/common/components/FieldDescription';

export const PasswordValidationFieldDescription: FC = () => (
  <FieldDescription>
    Hasło musi składać się z&nbsp;przynajmniej 8&nbsp;znaków i&nbsp;zawierać co
    najmniej jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak
    specjalny (@$!%*#?&)
  </FieldDescription>
);
