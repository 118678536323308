import React from 'react';
import { StyleProps } from '@summer/react-kit';
import { ConnectedColorOptionFilter } from 'src/app/search/components/SearchPanel/ConnectedColorOptionFilter';
import { OptionFilter } from 'src/app/common/models/filter';

export const SearchUpholsteryColorInteriorFilter = (props: StyleProps) => (
  <ConnectedColorOptionFilter
    columns={{ desktop: 2, mobile: 2 }}
    kind={OptionFilter.UpholsteryColorGroup}
    {...props}
  />
);
