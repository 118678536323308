import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 85 85" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M29.553 43.834a5.198 5.198 0 00-4.65 2.874l-3.42 6.84a1.334 1.334 0 01-1.193.737h-4.265l-.853 2.56h3.812a1.333 1.333 0 011.265 1.754l-1.238 3.715v9.691c0 1.009.235 2.005.686 2.908m0 .001l.479.957c.093.186.141.39.141.597v5.199h7.785v-2.56h-2.586a1.333 1.333 0 110-2.666h3.92c.736 0 1.333.597 1.333 1.334V83c0 .737-.597 1.334-1.334 1.334H18.984A1.333 1.333 0 0117.65 83v-6.217l-.338-.676v-.001a9.18 9.18 0 01-.968-4.1v-9.909c0-.143.023-.286.068-.422l.722-2.164h-2.07a2.64 2.64 0 01-2.64-2.64c0-.143.024-.285.069-.421l1.008-3.025a2.641 2.641 0 012.505-1.806h3.46l3.052-6.104a7.865 7.865 0 017.035-4.348h25.894a7.865 7.865 0 017.035 4.348l3.052 6.104h3.46c1.135 0 2.145.725 2.504 1.806l1.009 3.025c.045.136.068.278.068.421 0 1.46-1.181 2.64-2.64 2.64h-2.07l.722 2.164c.046.136.069.279.069.422v9.908a9.164 9.164 0 01-.97 4.102l-.337.675V83c0 .737-.597 1.334-1.333 1.334H55.564A1.333 1.333 0 0154.231 83v-5.225c0-.737.597-1.334 1.333-1.334h3.92a1.333 1.333 0 010 2.667h-2.586v2.559h7.785v-5.199c0-.207.048-.41.14-.595l.478-.958v-.002a6.496 6.496 0 00.688-2.908v-9.691l-1.238-3.715a1.334 1.334 0 011.265-1.755h3.811l-.853-2.559H64.71c-.505 0-.967-.285-1.193-.737l-3.42-6.84a5.198 5.198 0 00-4.65-2.874H29.553" />
      <path d="M26.796 77.774c0 .737.597 1.334 1.333 1.334h28.742a1.333 1.333 0 000-2.667H28.13c-.736 0-1.333.597-1.333 1.333zM21.57 66.016c0 .737.596 1.334 1.333 1.334h7.838a1.333 1.333 0 001.193-1.93l-1.306-2.613A1.333 1.333 0 1028.242 64l.342.683h-5.681c-.737 0-1.334.597-1.334 1.333zM53.123 66.717c.243.393.672.633 1.135.633h7.838a1.333 1.333 0 000-2.667h-5.681l.342-.683a1.333 1.333 0 00-2.386-1.193l-1.306 2.613c-.207.413-.184.904.058 1.297zM34.635 71.242c0 .737.596 1.334 1.333 1.334h13.064a1.333 1.333 0 100-2.667H35.968c-.737 0-1.333.597-1.333 1.333zM28.102 55.565c0 .736.597 1.333 1.333 1.333h26.13a1.333 1.333 0 100-2.667h-26.13c-.736 0-1.333.597-1.333 1.334z" />
      <path d="M3.333 84.334V18.59L42.5 3.431l39.166 15.16v65.743h2.667V17.678c0-.55-.338-1.045-.852-1.244L42.981.757c-.31-.12-.653-.12-.962 0l-40.5 15.677a1.333 1.333 0 00-.852 1.244v66.656h2.666z" />
      <path d="M5.957 35.074c.179.549.69.92 1.268.92h70.549a1.333 1.333 0 00.784-2.41L64.187 23.13a1.334 1.334 0 00-.784-.255H21.596c-.282 0-.556.09-.784.255L6.442 33.584c-.468.34-.663.942-.485 1.49zm5.369-1.746l10.704-7.785h40.94l10.704 7.785H11.326z" />
    </g>
  </IconBox>
);
