import { FinancingType, Offer } from 'src/common/models/offer';
import { productsActions } from 'src/app/product/state/products/productsActions';
import { findOfferWithParams } from 'src/app/product/services/helpers';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { financingTypeMapper } from 'src/app/product/services/offerMappers';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOfferSelector,
  availableOffersSelector,
  financingTypesListSelector,
} from 'src/app/product/state/products/productsSelectors';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { Product } from 'src/app/common/models/product';

export interface UseOnFinancingTypeChangeParams {
  leasingOffers: Offer[];
  product: Product;
}

export const useOnFinancingTypeChange = ({
  leasingOffers,
  product,
}: UseOnFinancingTypeChangeParams) => {
  const dispatch = useDispatch();

  const activeOffer = useSelector(activeOfferSelector);
  const availableOffers = useSelector(availableOffersSelector);
  const userCustomerType = useSelector(customerTypeSelector);
  const financingTypesList = useSelector(financingTypesListSelector);

  return (a: string[]) => {
    const financingType = a[0] as FinancingType;

    const isArtefakta4internal =
      financingType === FinancingType.Leasing && leasingOffers.length > 0;

    const baseOffer = isArtefakta4internal
      ? product.offers.find(
          (offer) => offer.external?.type === 'artefakta4internal'
        ) || undefined
      : undefined;

    if (financingType && activeOffer?.financingType !== financingType) {
      dispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              customerType: isArtefakta4internal ? undefined : userCustomerType,
              financingType,
            },
            {
              annualMileage: activeOffer?.annualMileage,
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(
            isArtefakta4internal ? leasingOffers : availableOffers,
            baseOffer,
            userCustomerType
          )
        )
      );

      const type = financingTypesList.find((type) => type === financingType);

      type &&
        pushEvent(EventName.ButtonClick, {
          click_category: 'offer_details_financing',
          click_cta: financingTypeMapper[type].toLowerCase(),
        });
    }
  };
};
