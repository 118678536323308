import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M32.0021 44.6857L38.3927 9.37702C44.4834 9.37702 46.4051 10.0333 46.683 12.7117C46.683 12.7117 50.7695 11.2147 52.8305 8.17469C44.7882 4.51326 36.7076 4.34848 36.7076 4.34848L31.9921 9.99005L32.0028 9.98935L27.2873 4.34778C27.2873 4.34778 19.2074 4.51326 11.1651 8.17399C13.224 11.214 17.3127 12.711 17.3127 12.711C17.592 10.0326 19.5108 9.37632 25.561 9.37213L32.0021 44.6857ZM31.9999 2.71815C38.5007 2.66927 45.9424 3.70612 53.5597 6.96818C54.5781 5.16818 54.8389 4.37222 54.8389 4.37222C46.5124 1.1353 38.7146 0.0279286 31.9999 0C25.2838 0.0279286 17.4861 1.1353 9.16023 4.37222C9.16023 4.37222 9.53193 5.35251 10.4395 6.96818C18.056 3.70612 25.4971 2.66927 31.9999 2.71815Z" />
    <path d="M41.2819 57.4881L40.0247 57.4913V63.9951H45.7904C46.421 63.7253 46.7588 63.2589 46.8894 62.7144H41.2787L41.2819 57.4881ZM50.6904 58.7876H55.4999C56.1681 58.6544 56.6658 58.0597 56.8053 57.4848H49.3843C49.5229 58.0597 50.028 58.6544 50.6904 58.7876ZM35.1149 58.7696C35.783 58.5738 36.346 58.0597 36.4831 57.4897H29.4259V61.3325H35.2022V62.6815L30.6717 62.6856C29.9611 62.8847 29.3606 63.365 29.0595 64L29.4259 63.9934H36.4439V60.0552H30.6717V58.7696H35.1149ZM49.5784 63.9934H50.8291V61.3753H55.3825V63.9934H56.6324V60.0667L49.5784 60.0601V63.9934ZM19.7057 58.7835H24.5144C25.1834 58.6495 25.6803 58.0548 25.8198 57.4798H18.3995C18.5382 58.0548 19.0424 58.6495 19.7057 58.7835ZM7.19531 57.4946C7.33972 58.0638 7.82842 58.6437 8.49824 58.7901H10.5224L10.6252 58.8312V63.9803H11.8898V58.8312L12.004 58.7901H14.0306C14.7069 58.6141 15.185 58.0638 15.3278 57.4946V57.4823H7.19531V57.4946ZM19.7057 63.9942H24.5144C25.1834 63.8593 25.6803 63.2663 25.8198 62.6905H18.3995C18.5382 63.2663 19.0424 63.8593 19.7057 63.9942ZM19.7057 61.3539H24.5144C25.1834 61.2207 25.6803 60.626 25.8198 60.051H18.3995C18.5382 60.626 19.0424 61.2207 19.7057 61.3539Z" />
  </IconBox>
);
