import React, { FC, useEffect } from 'react';
import { StyleProps } from '@summer/react-kit';
import { CleanUnorderedList } from 'src/common/components/List';
import styled, { css } from 'styled-components';
import { Button, ButtonProps, CleanButton } from 'src/common/components/Button';
import { rgba } from 'polished';
import { colors } from 'src/config/colors';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { usePagination } from 'src/lib/usePagination';
import { useMediaQuery } from 'react-responsive';
import { below, widthBreakpoints } from 'src/config/breakpoints';
import ArrowLeft from 'src/assets/icons/ArrowLeft';

export const calculatePagesCount = (itemsCount: number, pageSize: number) =>
  Math.ceil(itemsCount / pageSize);

export interface PaginationProps {
  total: number;
  page?: number;
  pageSize?: number;
  onChange?: (page: number) => void;
}

const Container = styled(CleanUnorderedList)`
  display: grid;
  grid-auto-columns: min-content;
  grid-column-gap: 0.8125rem;
  grid-auto-flow: column;
  align-items: center;
`;

const PageButton = styled(CleanButton)<{ $active: boolean }>`
  border-radius: 50%;
  border: 2px solid transparent;

  display: flex;
  width: 1.5625rem;
  height: 1.5625rem;
  align-items: center;
  justify-content: center;

  ${({ $active }) =>
    $active &&
    css`
      font-weight: bold;
      border-color: ${rgba(colors.white, 0.5)};
    `};

  &:hover {
    background-color: ${rgba(colors.white, 0.2)};
  }

  &:focus {
    background-color: ${rgba(colors.primary, 0.2)};
  }
`;

const mobileNextButtonProps: ButtonProps = {
  kind: 'box',
  variant: 'transparent',
};

const desktopNextButtonProps: ButtonProps = {
  kind: 'skew',
  variant: 'gray',
};

export const Pagination: FC<StyleProps & PaginationProps> = ({
  style,
  className,
  page = 1,
  pageSize = 6,
  total,
  onChange,
}) => {
  const count = calculatePagesCount(total, pageSize);
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });

  useEffect(() => {
    page > count && onChange && onChange(1);
  }, [page, count]);

  const { items } = usePagination({
    page,
    count: count,
    hidePrevButton: page < 3,
    onChange: (event, page) => onChange && onChange(page),
  });

  return (
    <Container style={style} className={className}>
      {count > 1 &&
        items.map(({ page, type, selected, ...itemProps }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <PageButton type="button" $active={selected} {...itemProps}>
                {page}
              </PageButton>
            );
          } else if (type === 'previous') {
            children = (
              <Button
                kind="box"
                variant="transparent"
                icon={ArrowLeft}
                {...itemProps}
              />
            );
          } else if (type === 'next') {
            children = (
              <Button
                icon={ArrowRight}
                {...(isMobile ? mobileNextButtonProps : desktopNextButtonProps)}
                {...itemProps}
              >
                {isMobile ? undefined : 'następna'}
              </Button>
            );
          } else {
            children = (
              <button type="button" {...itemProps}>
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
    </Container>
  );
};
