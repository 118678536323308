import React, { FC } from 'react';
import { useLeasingCalculatorConfigurations } from 'src/app/product/hooks/useLeasingCalculatorConfigurations';
import { ProductSlider } from 'src/app/product/components/ProductSlider';
import { Offer } from 'src/common/models/offer';
import { Product } from 'src/app/common/models/product';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import MediaQuery from 'react-responsive';
import { ProductPrice } from 'src/app/product/components/ProductPrice';
import { ProductPageSummaryListPrice } from 'src/app/product/components/ProductPageSummary/ProductPageSummaryListPrice';
import { ProductPageSummaryOfferOptions } from 'src/app/product/components/ProductPageSummary/ProductPageSummaryOfferOptions';
import { ProductPageSummaryOfferVariants } from 'src/app/product/components/ProductPageSummary/ProductPageSummaryOfferVariants';
import { financingTypeMapper } from 'src/app/product/services/offerMappers';
import { B2BB2CSwitch } from 'src/common/components/B2BB2CSwitch';
import { transparentize } from 'polished';
import { colors } from 'src/config/colors';
import { useSelector } from 'react-redux';
import { financingTypesListSelector } from 'src/app/product/state/products/productsSelectors';

const Configuration = styled.div`
  margin-bottom: 1.25rem;

  ${media.w.min.px1200(css`
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  `)}
`;

const StyledB2BB2CSwitch = styled(B2BB2CSwitch)`
  width: 100%;
  background: ${transparentize(0.9, colors.white)};
  border-color: transparent;
`;

export interface LeasingCalculatorProps {
  activeOffer: Offer;
  leasingOffers: Offer[];
  product: Product;
  onFinancingTypeChange: (financingType: string[]) => void;
}

export const LeasingCalculator: FC<LeasingCalculatorProps> = ({
  activeOffer,
  leasingOffers,
  product,
  onFinancingTypeChange,
}) => {
  const financingTypesList = useSelector(financingTypesListSelector);
  const {
    leasingInitialPaymentsList,
    setLeasingInitialPayment,
    leasingContractPeriodsList,
    setLeasingContractPeriod,
    leasingBuyoutPriceList,
    setLeasingBuyoutPrice,
  } = useLeasingCalculatorConfigurations(activeOffer, leasingOffers);

  if (activeOffer?.external?.type !== 'artefakta4internal') {
    return <></>;
  }

  return (
    <>
      <Configuration>
        <StyledB2BB2CSwitch value={activeOffer.customerType} />

        {financingTypesList.length > 0 && (
          <ProductPageSummaryOfferVariants
            items={financingTypesList.map((id) => ({
              id,
              label: financingTypeMapper[id],
            }))}
            name="Forma finansowania"
            onChange={(a) => onFinancingTypeChange(a)}
            value={activeOffer.financingType}
          />
        )}

        {leasingInitialPaymentsList.length > 0 && (
          <ProductSlider
            min={0}
            label="Wkład własny"
            step={1}
            suffix="zł"
            content={activeOffer.initialPayment}
            value={leasingInitialPaymentsList.findIndex(
              (payment) => payment === activeOffer.initialPayment
            )}
            onChange={(_, v) =>
              typeof v === 'number' &&
              activeOffer.initialPayment !== leasingInitialPaymentsList[v] &&
              setLeasingInitialPayment(leasingInitialPaymentsList[v])
            }
            max={leasingInitialPaymentsList.length - 1}
            disabled={leasingInitialPaymentsList.length === 1}
          />
        )}

        {leasingContractPeriodsList.length > 0 && (
          <ProductSlider
            min={0}
            label="Liczba rat"
            step={1}
            suffix="mc"
            content={activeOffer.contractPeriod}
            value={leasingContractPeriodsList.findIndex(
              (payment) => payment === activeOffer.contractPeriod
            )}
            onChange={(_, v) =>
              typeof v === 'number' &&
              activeOffer.contractPeriod !== leasingContractPeriodsList[v] &&
              setLeasingContractPeriod(leasingContractPeriodsList[v])
            }
            max={leasingContractPeriodsList.length - 1}
            disabled={leasingContractPeriodsList.length === 1}
          />
        )}

        {leasingBuyoutPriceList.length > 0 && (
          <ProductSlider
            min={0}
            label="Kwota wykupu"
            step={1}
            suffix="zł"
            content={activeOffer.buyoutPrice}
            value={leasingBuyoutPriceList.findIndex(
              (payment) => payment === activeOffer.buyoutPrice
            )}
            onChange={(_, v) =>
              typeof v === 'number' &&
              activeOffer.buyoutPrice !== leasingBuyoutPriceList[v] &&
              setLeasingBuyoutPrice(leasingBuyoutPriceList[v])
            }
            max={leasingBuyoutPriceList.length - 1}
            disabled={leasingBuyoutPriceList.length === 1}
          />
        )}

        <ProductPageSummaryOfferOptions
          financingType={activeOffer.financingType}
          productType={product.type}
          packageType={activeOffer.packageType}
        />
      </Configuration>

      {activeOffer && (
        <MediaQuery minWidth={widthBreakpoints.px1200}>
          <>
            <ProductPrice offer={activeOffer} discountBadge />

            {product?.listPrice && (
              <ProductPageSummaryListPrice price={product.listPrice} />
            )}
          </>
        </MediaQuery>
      )}
    </>
  );
};
