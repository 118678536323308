import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import { useRequest } from 'src/lib/useRequest';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { useSelector } from 'react-redux';
import { searchProductsResultsTotalSelector } from 'src/app/search/state/searchProducts/searchProductsSelectors';
import Bulb from 'src/assets/icons/Bulb';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { countLabelFormatter } from '@summer/react-kit/functions';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { searchUsedProductsResultsTotalSelector } from 'src/app/search/state/searchProducts/searchUsedProductsSelectors';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';

const Container = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: -1.5rem;
  z-index: 10;

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

export interface SearchPanelMobileCloseButtonProps {
  onClose: () => void;
}

const offerCountLabelFormatter = countLabelFormatter(
  '0 ofert',
  '1 ofertę',
  (count) => `${localFormatNumber(count)} oferty`,
  (count) => `${localFormatNumber(count)} ofert`
);

export const SearchPanelMobileCloseButton: FC<SearchPanelMobileCloseButtonProps> = ({
  onClose,
}) => {
  const { isLoading } = useRequest(findProductsRequestActions.request);
  const total = useSelector(searchProductsResultsTotalSelector);
  const totalUsed = useSelector(searchUsedProductsResultsTotalSelector);
  const productsPreference = useSelector(productsPreferenceSelector);

  const offerCount = useMemo(
    () => (productsPreference === 'new' ? total : totalUsed),
    [productsPreference, total, totalUsed]
  );

  return (
    <Container>
      <Button
        icon={isLoading ? Bulb : ArrowRight}
        onClick={onClose}
        disabled={isLoading}
        variant="secondary"
        style={{ width: '100%' }}
      >
        {isLoading ? (
          <>Szukamy...</>
        ) : offerCount > 0 ? (
          <>Zobacz {offerCountLabelFormatter(offerCount)}</>
        ) : (
          <>Znajdź auto marzeń</>
        )}
      </Button>
    </Container>
  );
};
