import React, { useEffect, useRef, useState } from 'react';
import { Box, Popper, Skeleton, Typography, styled } from '@mui/material';
import { rem } from 'polished';
import { useSelector } from 'react-redux';
import {
  brandsListSelector,
  modelsLookupSelector,
} from 'src/app/state/commonFilters/commonFiltersSelectors';
import { ExoticFilter, OptionFilter } from 'src/app/common/models/filter';
import {
  StyledAutocomplete,
  StyledTextField,
} from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchModelAutocomplete/styles';
import {
  useFilterControl,
  useOptionFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { Brand } from 'src/app/common/models/product';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import { ModelIconSection } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchModelAutocomplete/model-icon-section';
import { useMediaQuery } from 'react-responsive';
import { widthBreakpoints } from 'src/config/breakpoints';
import { isMotorcycles } from 'src/app/search/utils/is-motorcycles';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { OptionalFold } from 'src/lib/OptionalFold';
import { Icon } from 'src/common/components/Icon';
import {
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';

export const SearchModelAutocomplete = () => {
  const { state: modelState, setValue: setModelValue } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );
  const { state: brandState } = useOptionFilterControl(OptionFilter.Brand);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery({ maxWidth: widthBreakpoints.px992 });
  const modelsList = useSelector(modelsLookupSelector);

  const brands = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: a.label,
      options: a.models.map((b) => ({
        id: b.id,
        label: b.label,
        image: b.imageUrl,
        brandId: a.id,
      })),
    }))
  );

  const visibleBrands = brands
    .filter(
      (a) => brandState.value.includes(a.id) || brandState.value.length === 0
    )
    .filter((a) => a.options.length > 0);

  const combinedOptions = visibleBrands
    .map((item) => item.options)
    .reduce((acc, options) => acc.concat(options), []);

  function filterOptions(options: any, { inputValue }: { inputValue: string }) {
    return options.filter((option: any) =>
      option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }

  const selectedModels = Object.values(modelState.value).reduce(
    (acc, currentValue) => acc.concat(currentValue),
    []
  );

  const [selectedValues, setSelectedValues] = useState<any>([]);

  useEffect(() => {
    const defaultValues = combinedOptions.filter((option: any) =>
      selectedModels.includes(option.id)
    );
    setSelectedValues(defaultValues);
  }, [modelState]);

  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
      backgroundColor: '#52555a',
    },
  });

  if (!modelsList) {
    return <Skeleton style={{ height: rem(47) }} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <StyledAutocomplete
        multiple
        size={isMobile ? 'medium' : 'small'}
        disabled={brandState.value.length === 0}
        filterOptions={filterOptions}
        noOptionsText="Brak wyników"
        options={combinedOptions}
        value={selectedValues}
        getOptionLabel={(brand) => {
          const typedBrand = brand as Brand & { models: BrandModel[] };
          return typedBrand.label;
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.id === value.id;
        }}
        onChange={(_, newValue: any) => {
          const transformedValue = newValue.reduce(
            (acc: any, currentValue: any) => {
              const { brandId, id } = currentValue;
              if (!acc[brandId]) {
                acc[brandId] = [];
              }
              acc[brandId].push(id);
              return acc;
            },
            {}
          );
          setModelValue(transformedValue);
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        {...(!isMobile && { renderTags: () => null })}
        renderOption={(props, option: any) => {
          return (
            <li
              {...props}
              style={{
                color: 'white',
              }}
              id="autocomplete-element"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
                id="autocomplete-element"
              >
                <Box
                  sx={{
                    width: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <OptionalFold
                    key={option.id}
                    value={brandIconMapper(option.label)}
                    onSome={(code) => (
                      <FilterBoxToggleButtonIcon>
                        <Icon
                          code={code}
                          size={Math.round(24 * brandIconResizeRates[code])}
                        />
                      </FilterBoxToggleButtonIcon>
                    )}
                    onNone={
                      <FilterBoxToggleButtonTitle>
                        {option.label}
                      </FilterBoxToggleButtonTitle>
                    }
                  />
                </Box>
                <Typography id="autocomplete-element">
                  {option.label}
                </Typography>
              </Box>
            </li>
          );
        }}
        PopperComponent={StyledPopper}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={isMotorcycles() ? 'MARKA' : 'MODEL'}
            inputRef={inputRef}
          />
        )}
      />
      {!isMobile && brandState.value && brandState.value.length !== 0 && (
        <ModelIconSection style={{ gridArea: 'model' }} />
      )}
    </Box>
  );
};
