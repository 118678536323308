import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8533 3.39451c-14.0605 0-25.45879 11.39829-25.45879 25.45879 0 14.0605 11.39829 25.4588 25.45879 25.4588 14.0605 0 25.4588-11.3983 25.4588-25.4588S42.9138 3.39451 28.8533 3.39451zM0 28.8533C0 12.9181 12.9181 0 28.8533 0c15.9353 0 28.8533 12.9181 28.8533 28.8533 0 7.3569-2.7533 14.0707-7.2859 19.1672L64 61.5997 61.5997 64 48.0205 50.4207c-5.0965 4.5326-11.8103 7.2859-19.1672 7.2859C12.9181 57.7066 0 44.7886 0 28.8533zm27.1561-1.6972V16.9725h3.3945v10.1836h10.1835v3.3945H30.5506v10.1835h-3.3945V30.5506H16.9725v-3.3945h10.1836z"
    />
  </IconBox>
);
