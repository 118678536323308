import React, { memo, useCallback, useMemo, useRef } from 'react';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import {
  ToggleButtonGroup,
  ToggleButtonGroupItemOptions,
} from 'src/common/components/ToggleButtonGroup';
import {
  EquipmentGroup,
  Product,
  UsedProduct,
} from 'src/app/common/models/product';
import styled, { css } from 'styled-components';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { Title } from 'src/common/components/typography/Title';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { useMediaQuery } from 'react-responsive';
import {
  EquipmentCategoryExpansionPanel,
  EquipmentCategoryExpansionPanelDetails,
  EquipmentCategoryExpansionPanelSummary,
} from 'src/app/product/components/ProductPageSectionEquipment/EquipmentCategoryExpansionPanel';
import { CategoryEquipmentList } from 'src/app/product/components/ProductPageSectionEquipment/CategoryEquipmentList';
import { EquipmentItemCode } from 'src/app/product/components/ProductPageSectionEquipment/EquipmentItemCode';
import { indexBy, prop } from 'ramda';
import { isNotNilEmpty } from 'src/lib/isNilEmpty';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';

const Modal = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'footer';
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;

  ${media.w.min.px768(css`
    padding-top: 4rem;
  `)}
`;

const Header = styled.div`
  grid-area: header;
  padding-bottom: 1.875rem;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 67.5rem;
  padding: 0 0.9375rem;

  ${media.w.min.px576(css`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  `)}
`;

const Content = styled.div`
  display: flex;
  grid-area: content;
`;

const Equipment = styled.div`
  display: flex;
  padding: 0.0625rem 0.9375rem;
  margin-bottom: 0.5rem;

  ${media.w.min.px992(css`
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  `)}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 -0.9375rem;

  ${media.w.min.px992(css`
    grid-template-columns: 1fr 1fr;
    padding: 0 3.75rem 0 1.25rem;
  `)}
`;

const Label = styled.div`
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const StyledProductPageToggleButton = styled(HorizontalToggleButton)`
  ${media.w.max.px576(css`
    font-size: 0.75rem;
  `)}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  width: 100%;
`;

const CategoryEquipmentListContainer = styled.div`
  margin-bottom: 2rem;
`;

const CategoryEquipmentListTitle = styled(Title).attrs(() => ({
  level: 4,
  variant: 'condensed',
}))`
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  font-size: 0.625rem;

  ${media.w.min.px576(css`
    font-size: 0.75rem;
  `)};
`;

const StandardEquipmentItemLabel = styled(Label)`
  margin-left: 2.9375rem;
`;

export interface ProductPageEquipmentModalProps {
  product: Product | UsedProduct;
  categories: ToggleButtonGroupItemOptions[];
  activeCategory?: EquipmentGroup;
  onClose: () => void;
  onActiveCategoryChange: (ids: string[]) => void;
  onModalToggle?: (modalOpen: boolean) => void;
}

export const ProductPageEquipmentModal = memo<ProductPageEquipmentModalProps>(
  ({
    product,
    activeCategory,
    categories,
    onClose,
    onActiveCategoryChange,
  }) => {
    const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });
    const scrollRef = useRef<OverlayScrollbarsComponent>(null);

    const equipment = product.equipment;
    const handleActiveCategoryChange = useCallback((ids: string[]) => {
      onActiveCategoryChange(ids);
      scrollRef.current?.osInstance()?.scroll(0);
    }, []);

    const equipmentGroupCategoryLookup = useMemo(
      () => indexBy(prop('id'), equipment),
      [equipment]
    );

    useKeyPress(KEY_ESCAPE, CODE_ESCAPE, onClose);

    const hasFullEquipment = (product.fullEquipment ?? []).length > 0;

    return (
      <Modal>
        {!hasFullEquipment && (
          <Header>
            <Container>
              {!isMobile && (
                <HorizontalToggleGroupContainer style={{ width: '100%' }}>
                  <ToggleButtonGroup
                    limit={1}
                    value={activeCategory ? [activeCategory.id] : undefined}
                    onChange={handleActiveCategoryChange}
                    items={categories}
                    toggleButtonComponent={StyledProductPageToggleButton}
                  />
                </HorizontalToggleGroupContainer>
              )}
            </Container>
          </Header>
        )}

        <Content>
          <StyledScrollContainer ref={scrollRef}>
            <Container>
              {!isMobile && (
                <>
                  {hasFullEquipment && (
                    <CategoryEquipmentListContainer>
                      <Grid>
                        {product.fullEquipment?.map((item) => (
                          <Equipment key={item}>
                            <StandardEquipmentItemLabel>
                              {item}
                            </StandardEquipmentItemLabel>
                          </Equipment>
                        ))}
                      </Grid>
                    </CategoryEquipmentListContainer>
                  )}

                  {!hasFullEquipment && isNotNilEmpty(activeCategory?.options) && (
                    <CategoryEquipmentListContainer>
                      <CategoryEquipmentListTitle>
                        Wyposażenie dodatkowe
                      </CategoryEquipmentListTitle>
                      <Grid>
                        {activeCategory?.options.map((item) => (
                          <Equipment key={item.id}>
                            <EquipmentItemCode item={item} />
                            <Label>{item.label}</Label>
                          </Equipment>
                        ))}
                      </Grid>
                    </CategoryEquipmentListContainer>
                  )}
                  {!hasFullEquipment &&
                    isNotNilEmpty(activeCategory?.standardOptions) && (
                      <CategoryEquipmentListContainer>
                        <CategoryEquipmentListTitle>
                          Pełne wyposażenie
                        </CategoryEquipmentListTitle>
                        <Grid>
                          {activeCategory?.standardOptions.map((item) => (
                            <Equipment key={item}>
                              <StandardEquipmentItemLabel>
                                {item}
                              </StandardEquipmentItemLabel>
                            </Equipment>
                          ))}
                        </Grid>
                      </CategoryEquipmentListContainer>
                    )}
                </>
              )}

              {isMobile && (
                <>
                  {hasFullEquipment && (
                    <EquipmentCategoryExpansionPanel>
                      <EquipmentCategoryExpansionPanelSummary>
                        Wyposażenie standardowe
                      </EquipmentCategoryExpansionPanelSummary>
                      <EquipmentCategoryExpansionPanelDetails>
                        <CategoryEquipmentList
                          flatEquipmentItems={product?.fullEquipment}
                        />
                      </EquipmentCategoryExpansionPanelDetails>
                    </EquipmentCategoryExpansionPanel>
                  )}

                  {!hasFullEquipment &&
                    categories.map(({ id, label }) => (
                      <EquipmentCategoryExpansionPanel key={id}>
                        <EquipmentCategoryExpansionPanelSummary>
                          {label}
                        </EquipmentCategoryExpansionPanelSummary>
                        <EquipmentCategoryExpansionPanelDetails>
                          <CategoryEquipmentList
                            label="Wyposażenie dodatkowe"
                            equipmentItems={
                              equipmentGroupCategoryLookup[id]?.options
                            }
                            displayCodes={true}
                          />
                          <CategoryEquipmentList
                            label="Wyposażenie standardowe"
                            flatEquipmentItems={
                              equipmentGroupCategoryLookup[id]?.standardOptions
                            }
                          />
                        </EquipmentCategoryExpansionPanelDetails>
                      </EquipmentCategoryExpansionPanel>
                    ))}
                </>
              )}
            </Container>
          </StyledScrollContainer>
        </Content>
      </Modal>
    );
  }
);
