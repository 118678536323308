import { ProductPageSection } from 'src/app/product/models/productPageSection';
import React, { FC, RefObject } from 'react';
import { Icon } from 'src/common/components/Icon';
import { CleanButton } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';
import { styledIs } from '@summer/react-kit/functions';
import { deceleratedEasing } from 'src/common/styles/animations';
import {
  invertLightDarkVariant,
  LightDarkVariant,
} from 'src/common/models/lightDarkVariant';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const Container = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 0.75rem;
  margin-left: -0.5rem;
`;

const NavigationItem = styled(CleanButton)<{
  active?: boolean;
  variant: LightDarkVariant;
}>`
  display: flex;
  align-items: center;
  padding: 0.5rem;

  ${({ active }: { active?: boolean }) =>
    active &&
    css`
      color: ${colors.primary};
      opacity: 1;
    `};

  ${({ variant, active }: { active?: boolean; variant: LightDarkVariant }) =>
    !active &&
    css`
      color: ${variant === 'dark' ? colors.background : colors.white};
      opacity: 0.5;
    `};

  ${({ variant }: { variant: LightDarkVariant }) =>
    css`
      &:hover {
        color: ${variant === 'dark' ? colors.background : colors.white};
        opacity: 1;
      }
    `};
`;

const Title = styled.span<{ slim?: boolean }>`
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.875rem;
  margin-left: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 200ms ${deceleratedEasing},
    opacity 200ms ${deceleratedEasing};

  ${styledIs('slim')(css`
    transform: translateX(-2rem);
    opacity: 0;

    ${Container}:hover & {
      transform: translateX(0);
      opacity: 1;
    }
  `)}
`;

export interface ProductPageNavigationDesktopProps {
  active?: string;
  items: ProductPageSection[];
  onClick: (sectionRef: RefObject<HTMLDivElement>) => void;
  slim?: boolean;
}

export const ProductPageNavigationDesktop: FC<ProductPageNavigationDesktopProps> = ({
  active,
  items,
  onClick,
  slim,
}) => {
  const variant = invertLightDarkVariant(
    items.find((a) => a.key === active)?.variant ?? 'light'
  );

  return (
    <Container>
      {items.map((item, index) => (
        <NavigationItem
          key={item.key}
          onClick={() => {
            onClick(item.ref);
            pushEvent(EventName.SelectItem, {
              item_list_name: 'offer_details_features',
              items: [
                {
                  item_id: `${500 + index}`,
                  item_name: item.menuTitle.toLowerCase().replace(/\s/g, '_'),
                },
              ],
            });
          }}
          active={active === item.key}
          variant={variant}
        >
          <Icon code={item.icon} width={22} style={{ flexShrink: 0 }} />
          <Title slim={slim}>{item.menuTitle}</Title>
        </NavigationItem>
      ))}
    </Container>
  );
};
