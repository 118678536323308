import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { CookiesState } from 'src/state/cookies/cookiesReducer';

export const cookiesStateSelector = (state: RootState) => state.cookies;

export const cookiesAdvertInternalSelector = createSelector(
  cookiesStateSelector,
  (state: CookiesState) => state.advertInternal
);
export const cookiesAdvertExternalSelector = createSelector(
  cookiesStateSelector,
  (state: CookiesState) => state.advertExternal
);
export const cookiesAnalyticSelector = createSelector(
  cookiesStateSelector,
  (state: CookiesState) => state.analytic
);
