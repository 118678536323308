import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilterGroupPanel,
  groupPanelToLabelMap,
} from 'src/app/search/models/filterGroupPanel';
import { SearchGeneralFilters } from 'src/app/search/components/SearchPanel/SearchGeneralFilters';
import { SearchPerformanceFilters } from 'src/app/search/components/SearchPanel/SearchPerformanceFilters';
import { media } from 'src/config/breakpoints';
import { SearchAppearanceFilters } from 'src/app/search/components/SearchPanel/SearchAppearanceFilters';
import { SearchEquipmentFilters } from 'src/app/search/components/SearchPanel/SearchEquipmentFilters';
import { searchPanelActions } from 'src/app/search/state/searchPanel/searchPanelActions';
import { activeSearchPanelSelector } from 'src/app/search/state/searchPanel/searchPanelSelectors';
import { SearchPanelCrossroad } from 'src/app/search/components/SearchPanel/SearchPanelCrossroad';
import { Title } from 'src/common/components/typography/Title';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import Sliders from 'src/assets/icons/Sliders';
import { SearchPanelDesktopCloseButton } from 'src/app/search/components/SearchPanel/SearchPanelDesktopCloseButton';
import { SearchPanelMobileCloseButton } from 'src/app/search/components/SearchPanel/SearchPanelMobileCloseButton';
import {
  acceleratedEasing,
  deceleratedEasing,
} from 'src/common/styles/animations';
import { CSSTransition } from 'react-transition-group';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import Close from 'src/assets/icons/Close';
import { colors } from 'src/config/colors';
import { useClickOutside } from 'src/app/common/hooks/useClickOutside';

const animationDuration = 250;

const slideInTop = keyframes`
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOutTop = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50%);
    opacity: 0;
  }
`;

const Container = styled.div<{ isChrome: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 30;

  ${({ isChrome }) =>
    isChrome &&
    css`
      backdrop-filter: blur(16px);
      background: linear-gradient(
        180deg,
        rgba(42, 43, 50, 0.9) 0%,
        rgba(48, 51, 55, 0.71) 81.69%
      );
    `};

  ${({ isChrome }) =>
    !isChrome &&
    css`
      background-color: rgba(42, 43, 50, 0.97);
    `};

  ${media.w.max.px992(css`
    background-color: ${colors.background};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `)};

  ${media.w.min.px992(css`
    position: absolute;
    max-height: 25.5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `)};

  &.enter-active {
    animation: ${slideInTop} ${animationDuration}ms ${deceleratedEasing};
  }

  &.exit-active {
    pointer-events: none;
    animation: ${slideOutTop} ${animationDuration}ms ${acceleratedEasing}
      forwards;
  }
`;

const Panel = styled.section`
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 2vh 1rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.w.min.px768(css`
    padding: 2vh;
  `)};

  ${media.w.max.px768(css`
    padding-top: 1.375rem;
    padding-bottom: 0;
  `)};

  ${media.w.min.px992(css`
    padding-left: 2.625rem;
    padding-right: 2.625rem;
  `)};
`;

const PanelContent = styled.div`
  flex: 1 1 auto;
  height: 1px;
`;

const PanelScrollContainer = styled(ScrollContainer)`
  height: 100%;
  width: 100%;
  padding-right: 1rem;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${media.w.min.px1200(css`
    display: none;
  `)};
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7.75rem;
  background: radial-gradient(
    44.79% 79.17% at 50% 100%,
    #0081ff 0%,
    rgba(0, 129, 255, 0) 100%
  );
  opacity: 0.4;
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const HeaderButton = styled(Button).attrs({
  kind: 'box',
  variant: 'transparent',
})`
  grid-area: button;
  justify-self: flex-start;
`;

const HeaderTitle = styled(Title).attrs({ level: 3, variant: 'condensed' })`
  text-transform: uppercase;
  grid-area: title;
  margin: 0 0 0 0.5rem;
`;

const Footer = styled.div`
  display: none;
  ${media.w.min.px992(css`
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        44.79% 55% at 50% 105%,
        rgba(0, 129, 255, 0.8) 0%,
        rgba(0, 129, 255, 0) 100%
      );
      transition: opacity 0.5s ease-out;
      opacity: 0.5;
      z-index: 2;
    }

    &:hover:after {
      background: radial-gradient(
        44.79% 55% at 50% 105%,
        rgba(0, 129, 255, 0.8) 0%,
        rgba(0, 129, 255, 0) 100%
      );
      opacity: 1;
    }
  `)};
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  height: 100%;

  ${media.w.min.px1200(css`
    display: flex;
    justify-content: center;
  `)};
`;

export const SearchPanel = memo(() => {
  const activePanel = useSelector(activeSearchPanelSelector);
  // this value must be copied to allow exit animation
  const [activePanelState, setActivePanelState] = useState(activePanel);
  const wrapperRef = useRef(null);

  const dispatch = useDispatch();
  const close = useCallback(() => dispatch(searchPanelActions.close()), [
    dispatch,
  ]);
  const openCrossroad = useCallback(
    () => dispatch(searchPanelActions.openCrossroad()),
    [dispatch]
  );

  useEffect(() => {
    if (activePanel == null) {
      setTimeout(() => setActivePanelState(undefined), animationDuration);
    } else {
      setActivePanelState(activePanel);
    }
  }, [activePanel]);

  useKeyPress(KEY_ESCAPE, CODE_ESCAPE, close);
  useClickOutside(
    wrapperRef,
    () => {
      dispatch(searchPanelActions.close());
    },
    'SearchHeaderFilters',
    'autocomplete-element'
  );

  const isChrome =
    window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

  return (
    <CSSTransition in={!!activePanel} timeout={animationDuration} unmountOnExit>
      <Container ref={wrapperRef} isChrome={isChrome}>
        <Panel>
          <Gradient />
          <Header>
            <Filter>
              {activePanelState === FilterGroupPanel.Crossroad ? (
                <HeaderButton onClick={close} icon={Sliders} />
              ) : (
                <HeaderButton onClick={openCrossroad}>
                  <ChevronLeft size={14} />
                </HeaderButton>
              )}

              <HeaderTitle>
                {activePanelState ? groupPanelToLabelMap[activePanelState] : ''}
              </HeaderTitle>
            </Filter>
            <Button
              variant="transparent"
              kind="box"
              icon={Close}
              onClick={close}
              iconProps={{ size: 26 }}
            />
          </Header>
          <PanelContent>
            <PanelScrollContainer>
              <Content>
                {activePanelState === FilterGroupPanel.Crossroad && (
                  <SearchPanelCrossroad />
                )}
                {activePanelState === FilterGroupPanel.General && (
                  <SearchGeneralFilters />
                )}
                {activePanelState === FilterGroupPanel.Performance && (
                  <SearchPerformanceFilters />
                )}
                {activePanelState === FilterGroupPanel.Appearance && (
                  <SearchAppearanceFilters />
                )}
                {activePanelState === FilterGroupPanel.Equipment && (
                  <SearchEquipmentFilters />
                )}
              </Content>
            </PanelScrollContainer>
          </PanelContent>

          <SearchPanelMobileCloseButton onClose={close} />
        </Panel>
        <Footer>
          <SearchPanelDesktopCloseButton onClose={close} />
        </Footer>
      </Container>
    </CSSTransition>
  );
});
