import { createOptionalTransform } from '@summer/react-kit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistState } from 'redux-persist/es/types';
import { authReducer, AuthState } from 'src/state/auth/authReducer';
import {
  requestLoadingReducer,
  RequestLoadingState,
} from 'src/state/requestLoading/requestLoadingReducer';
import {
  requestTimestampReducer,
  RequestTimestampState,
} from 'src/state/requestTimestamp/requestTimestampReducer';
import {
  navigationReducer,
  NavigationState,
} from 'src/state/navigation/navigationReducer';
import {
  notificationReducer,
  NotificationState,
} from 'src/state/notification/notificationReducer';
import {
  requestStatusReducer,
  RequestStatusState,
} from 'src/state/requestStatus/requestStatusReducer';
import { cookiesReducer, CookiesState } from 'src/state/cookies/cookiesReducer';
import {
  companyConfirmationReducer,
  CompanyConfirmationState,
} from 'src/state/companyConfirmation/companyConfirmationReducer';
import {
  settingsReducer,
  SettingsState,
} from 'src/state/settings/settingsReducer';
import {
  pageContextReducer,
  PageContextState,
} from 'src/state/pageContext/pageContextReducer';
import {
  businessPartnerReducer,
  BusinessPartnerState,
} from 'src/state/businessPartner/businessPartnerReducer';

interface PersistPartial {
  _persist: PersistState;
}

export interface RootState {
  auth: AuthState & PersistPartial;
  cookies: CookiesState & PersistPartial;
  businessPartner: BusinessPartnerState;
  companyConfirmation: CompanyConfirmationState;
  navigation: NavigationState;
  notification: NotificationState;
  page: PageContextState;
  requestLoading: RequestLoadingState;
  requestTimestamp: RequestTimestampState;
  requestStatus: RequestStatusState;
  settings: SettingsState;
}

export const createRootReducer = () => ({
  auth: persistReducer(
    {
      storage: storage,
      key: 'auth',
      whitelist: ['token', 'registerByCodeToken', 'user'],
      transforms: [
        createOptionalTransform(['token', 'registerByCodeToken', 'user']),
      ],
    },
    authReducer
  ),
  cookies: persistReducer(
    {
      storage: storage,
      key: 'cookies',
      whitelist: ['advertInternal', 'advertExternal', 'analytic'],
      transforms: [
        createOptionalTransform(['advertInternal']),
        createOptionalTransform(['advertExternal']),
        createOptionalTransform(['analytic']),
      ],
    },
    cookiesReducer
  ),
  businessPartner: businessPartnerReducer,
  companyConfirmation: companyConfirmationReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  page: pageContextReducer,
  requestLoading: requestLoadingReducer,
  requestTimestamp: requestTimestampReducer,
  requestStatus: requestStatusReducer,
  settings: settingsReducer,
});
