import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 24 24" {...props}>
    <path
      d="M13.5 14.75C13.9142 14.75 14.25 14.4142 14.25 14C14.25 13.5858 13.9142 13.25 13.5 13.25V14.75ZM5.18405 14.436L4.84357 13.7677L4.84356 13.7677L5.18405 14.436ZM3.43598 16.184L4.10423 16.5245L4.10423 16.5245L3.43598 16.184ZM3 21H2.25C2.25 21.4142 2.58579 21.75 3 21.75V21ZM13.5 21.75C13.9142 21.75 14.25 21.4142 14.25 21C14.25 20.5858 13.9142 20.25 13.5 20.25V21.75ZM9.5 10.75C11.8472 10.75 13.75 8.84721 13.75 6.5H12.25C12.25 8.01879 11.0188 9.25 9.5 9.25V10.75ZM13.75 6.5C13.75 4.15279 11.8472 2.25 9.5 2.25V3.75C11.0188 3.75 12.25 4.98122 12.25 6.5H13.75ZM9.5 2.25C7.15279 2.25 5.25 4.15279 5.25 6.5H6.75C6.75 4.98122 7.98121 3.75 9.5 3.75V2.25ZM5.25 6.5C5.25 8.84721 7.15279 10.75 9.5 10.75V9.25C7.98121 9.25 6.75 8.01879 6.75 6.5H5.25ZM17.25 14.5V20.5H18.75V14.5H17.25ZM15 18.25H21V16.75H15V18.25ZM13.5 13.25H9.4V14.75H13.5V13.25ZM9.4 13.25C8.29227 13.25 7.41638 13.2494 6.71175 13.307C5.99836 13.3653 5.39474 13.4869 4.84357 13.7677L5.52453 15.1042C5.82901 14.9491 6.21326 14.8527 6.83389 14.802C7.46327 14.7506 8.26752 14.75 9.4 14.75V13.25ZM4.84356 13.7677C3.94977 14.2231 3.22311 14.9498 2.76772 15.8436L4.10423 16.5245C4.41582 15.913 4.91301 15.4158 5.52454 15.1042L4.84356 13.7677ZM2.76772 15.8436C2.48688 16.3947 2.36527 16.9984 2.30699 17.7117C2.24942 18.4164 2.25 19.2923 2.25 20.4H3.75C3.75 19.2675 3.75058 18.4633 3.80201 17.8339C3.85271 17.2133 3.94909 16.829 4.10423 16.5245L2.76772 15.8436ZM2.25 20.4V21H3.75V20.4H2.25ZM3 21.75H13.5V20.25H3V21.75Z"
      fill="white"
    />
  </IconBox>
);
