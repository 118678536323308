import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 70 51" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M6.711 48.845c0 .736.597 1.333 1.333 1.333h12.09c.736 0 1.333-.597 1.333-1.333V42.8c0-.736-.597-1.333-1.334-1.333H15.6a1.333 1.333 0 100 2.667h3.2v3.378H9.378v-6.223c0-.207-.049-.411-.141-.596l-.554-1.107a7.743 7.743 0 01-.816-3.459V24.883l1.442-4.328A1.333 1.333 0 008.044 18.8H3.511a.177.177 0 01-.142-.07l1.062-3.186a.179.179 0 01.17-.121h4.954c.505 0 .967-.286 1.193-.738l3.956-7.912a6.221 6.221 0 015.565-3.44h29.95a6.221 6.221 0 015.566 3.44l3.956 7.912c.226.452.687.738 1.192.738h4.955c.078 0 .145.049.17.12l1.062 3.188a.177.177 0 01-.142.07h-4.533a1.333 1.333 0 00-1.265 1.754l1.442 4.328v11.244c0 1.2-.28 2.384-.817 3.457v.002l-.553 1.108c-.093.185-.14.389-.14.595v6.223h-9.423v-3.378h3.2a1.333 1.333 0 000-2.667h-4.534c-.736 0-1.333.597-1.333 1.333v6.045c0 .736.597 1.333 1.334 1.333h12.088c.737 0 1.334-.597 1.334-1.333v-7.241l.412-.826a10.39 10.39 0 001.099-4.65v-11.46c0-.144-.023-.287-.069-.423l-.926-2.778h2.684a2.843 2.843 0 002.844-2.844 1.33 1.33 0 00-.068-.422l-1.166-3.499a2.843 2.843 0 00-2.7-1.946h-4.13L58.17 5.58A8.888 8.888 0 0050.22.667H20.27a8.888 8.888 0 00-7.951 4.914L8.73 12.756h-4.13A2.845 2.845 0 001.9 14.7l-1.166 3.5a1.333 1.333 0 00-.068.422 2.844 2.844 0 002.844 2.844h2.683l-.926 2.778a1.334 1.334 0 00-.068.422v11.46c0 1.614.376 3.207 1.097 4.65l.414.827v7.24z" />
      <path d="M17.29 42.803c0 .737.598 1.334 1.334 1.334h33.245a1.333 1.333 0 100-2.667H18.623c-.736 0-1.333.597-1.333 1.333zM11.245 29.2c0 .737.597 1.334 1.333 1.334h9.067a1.334 1.334 0 001.192-1.93l-1.51-3.022a1.333 1.333 0 10-2.386 1.192l.546 1.093h-6.909c-.736 0-1.333.597-1.333 1.333zM47.71 29.901c.242.393.672.633 1.134.633h9.066a1.333 1.333 0 100-2.667h-6.909l.546-1.093a1.333 1.333 0 00-2.385-1.192l-1.511 3.022c-.207.413-.185.904.058 1.297zM26.357 35.248c0 .737.597 1.334 1.333 1.334h15.111a1.333 1.333 0 100-2.667h-15.11c-.737 0-1.334.597-1.334 1.334zM18.8 17.114c0 .737.596 1.334 1.333 1.334h30.222a1.333 1.333 0 000-2.667H20.133c-.737 0-1.334.597-1.334 1.333z" />
    </g>
  </IconBox>
);
