import {
  ColorOptionFilterState,
  OptionFilter,
} from 'src/app/common/models/filter';
import { ColorPreview } from 'src/app/common/components/ColorPreview';
import { rem } from 'polished';
import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { TransparentToggleButton } from 'src/common/components/ToggleButton';
import { StyleProps } from '@summer/react-kit';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { CommonOptionFilter } from 'src/app/search/components/SearchPanel/CommonOptionFilter';
import { media } from 'src/config/breakpoints';

const StyledTransparentToggleButton = styled(TransparentToggleButton)`
  padding: 0.25rem;
  border: 1px solid rgba(147, 150, 156, 0.5);
  border-radius: 0.125rem;

  ${media.w.min.px992(css`
    min-width: 6rem;
  `)};
`;

export const ConnectedColorOptionFilter = memo(
  ({
    kind,
    className,
    style,
    columns,
  }: StyleProps & {
    kind: OptionFilter;
    columns?: { desktop: number; mobile: number };
  }) => {
    const { state, pristine, setValue, reset } = useOptionFilterControl(kind);

    const items = useMemo(
      () =>
        ((state.options as ColorOptionFilterState['options']) ?? []).map(
          (_) => ({
            id: _.id,
            label: (
              <>
                <ColorPreview color={_.hex} />
                <span style={{ marginLeft: rem(8) }}>{_.label}</span>
              </>
            ),
          })
        ),
      [state.options]
    );

    return (
      <CommonOptionFilter
        kind={kind}
        className={className}
        columns={columns}
        direction="vertical"
        style={style}
        items={items}
        value={state.value}
        onChange={setValue}
        reset={reset}
        pristine={pristine}
        toggleButtonComponent={StyledTransparentToggleButton}
      />
    );
  }
);
