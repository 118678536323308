import Markdown from 'markdown-to-jsx';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';

SwiperCore.use([Pagination]);

const Item = styled.div<{ marginless?: boolean }>`
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.8125rem;
  line-height: 1rem;
  position: relative;
  padding: 0.625rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    !props.marginless &&
    css`
      padding: 0.625rem 1.25rem;
    `};

  ${media.w.min.px1200(css`
    padding: 1.25rem 1.875rem;
    text-align: center;
  `)}
  &::after {
    border: 0.0625rem solid ${colors.primary};
    height: 1rem;
    margin-top: -0.5rem;
    position: absolute;
    right: 0;
    top: 50%;

    ${media.w.min.px1200(css`
      content: '';
    `)}
  }

  p {
    margin: 0;
  }
`;

const SwiperContainer = styled.div<{ marginless?: boolean }>`
  &.swiper-container {
    position: relative;
  }

  .swiper-wrapper {
    ${(props) =>
      !props.marginless &&
      css`
        margin: 0 -1.25rem;
      `};

    ${media.w.min.px1200(css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 -1.875rem;
      width: auto;
    `)}
  }
`;

const SwiperPagination = styled.div<{ marginless?: boolean }>`
  ${(props) =>
    !props.marginless &&
    css`
      margin-top: 0.625rem;
      text-align: center;
    `};

  .swiper-pagination-bullet {
    background: transparent;
    border-radius: 0;
    opacity: 1;
    padding: 0.625rem 0.25rem;
    margin: 0 !important;
    width: 1.5rem;

    &::after {
      border-top: 0.0625rem solid ${colors.stormGray};
      content: '';
      display: block;
      width: 100%;
    }
  }

  .swiper-pagination-bullet-active {
    &::after {
      border-color: ${colors.primary};
      content: '';
    }
  }
`;

export interface ProductBenefitsProps {
  list: { content?: string }[];
  swiperOptions?: SwiperOptions;
  marginless?: boolean;
}

export const ProductBenefits = memo<ProductBenefitsProps>(
  ({ list, swiperOptions, marginless }) => {
    const [
      swiperContainerElement,
      setSwiperContainerRef,
    ] = useState<HTMLDivElement | null>(null);
    const [
      swiperPaginationElement,
      setSwiperPaginationRef,
    ] = useState<HTMLDivElement | null>(null);

    const swiperInstance = useRef<SwiperCore | null>(null);

    const isDesktop = useMediaQuery({ minWidth: widthBreakpoints.px1200 });

    useEffect(() => {
      if (!swiperContainerElement || !swiperPaginationElement) {
        return;
      }

      if (isDesktop) {
        swiperInstance.current?.destroy(true, true);
        return;
      }

      swiperInstance.current = new SwiperCore(swiperContainerElement, {
        pagination: {
          clickable: true,
          el: swiperPaginationElement,
        },
        slidesPerColumnFill: 'row',
        ...swiperOptions,
      });

      return () => {
        swiperInstance.current?.destroy(true, true);
      };
    }, [
      isDesktop,
      swiperContainerElement,
      swiperOptions,
      swiperPaginationElement,
    ]);

    return (
      <>
        <SwiperContainer
          marginless={marginless}
          className="swiper-container"
          ref={setSwiperContainerRef}
        >
          <div className="swiper-wrapper" hidden={!swiperInstance}>
            {list.map((item, index) => (
              <Item
                marginless={marginless}
                className="swiper-slide"
                key={index}
              >
                <Markdown>{item.content ?? ''}</Markdown>
              </Item>
            ))}
          </div>

          <SwiperPagination
            hidden={isDesktop}
            ref={setSwiperPaginationRef}
            marginless={marginless}
          />
        </SwiperContainer>
      </>
    );
  }
);
