import React, { FC } from 'react';
import { ProductOfferTileInstallment } from 'src/app/product/components/ProductOfferTile/ProductOfferTileInstallment';
import {
  getProductBasicParamList,
  PartialProduct,
} from 'src/app/common/models/product';
// import { ProductOfferTilePrice } from 'src/app/product/components/ProductOfferTile/ProductOfferTilePrice';
import styled from 'styled-components';
import { ProductParamsList } from 'src/app/product/components/ProductParamsList';
import { colors } from 'src/config/colors';
import { getResponsive } from 'src/common/models/image';
import { financingModelAvailabilityLabelMapper } from 'src/app/product/services/offerMappers';
import { deceleratedEasing } from 'src/common/styles/animations';
import { FinancingType } from 'src/common/models/offer';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { useSelector } from 'react-redux';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { CustomerType } from 'src/common/models/customerType';
import { StyleProps } from '@summer/react-kit';
import { ProductOfferTileChanged } from 'src/app/product/components/ProductOfferTileChanged';

export interface NewProductOfferTileProps {
  product: PartialProduct;
}

const StyledProductOfferTile = styled(ProductOfferTileChanged)``;

const PhotoContainer = styled.div`
  height: 11.25rem;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Photo = styled.img`
  object-fit: contain;
  z-index: -99;
  width: calc(100% - 1rem);
  height: 110%;
`;

export const StyledProductOfferTileParams = styled(ProductParamsList)`
  color: ${colors.lightGray};
  font-size: 0.625rem;
  font-weight: 300;
`;

const FooterLeftContentWrapper = styled.div`
  overflow: hidden;
  height: 4.2rem;
`;

const FooterLeftContent = styled.div`
  @media (hover: hover) {
    opacity: 0;
    transform: translateY(50%);
    transition: transform 150ms ${deceleratedEasing},
      opacity 150ms ${deceleratedEasing};

    ${StyledProductOfferTile}:hover & {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const FooterRightContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 1rem;
  z-index: 9999;

  @media screen and (min-width: 768px) and (max-width: 900px) {
    margin-top: 0;
  }

  @media (hover: hover) {
    ${StyledProductOfferTile}:hover & ${Photo} & {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const FinancingInformation = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-right: 1.75rem;

  @media (hover: hover) {
    opacity: 0;
    transform: translateY(50%);
    transition: transform 150ms ${deceleratedEasing},
      opacity 150ms ${deceleratedEasing};

    ${StyledProductOfferTile}:hover & {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export interface ProductOfferTilePriceProps {
  price: number;
}

const Container = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 1rem;
  transition: transform 0.3s ease, font-size 0.3s ease, opacity 0.3s ease;

  @media (hover: hover) {
    ${StyledProductOfferTile}:hover & {
      font-size: 1rem;
    }
  }
`;

const Value = styled.span`
  font-size: 2rem;
  transition: transform 0.3s ease, font-size 0.3s ease, opacity 0.3s ease;
  @media (hover: hover) {
    ${StyledProductOfferTile}:hover & {
      font-size: 3rem;
    }
  }
`;

export const ProductOfferTilePrice: FC<
  StyleProps & ProductOfferTilePriceProps
> = ({ style, className, price }) => (
  <Container style={style} className={className}>
    <span style={{ marginRight: 4 }}>już za</span>
    <Value>{localFormatNumber(price)}</Value>
    <span style={{ marginLeft: 4 }}>{`zł/mc`}</span>
  </Container>
);

export const NewProductOfferTile: FC<NewProductOfferTileProps> = ({
  product,
}) => {
  const userCustomerType = useSelector(customerTypeSelector);

  return (
    <StyledProductOfferTile
      title={product.label}
      tagline={product.version}
      badges={product.badges}
      product={product}
      photo={
        product.mainPhoto && (
          <PhotoContainer>
            <Photo
              src={getResponsive('small', product.mainPhoto)}
              draggable={false}
            />
          </PhotoContainer>
        )
      }
      headerExtras={
        <StyledProductOfferTileParams
          params={getProductBasicParamList(product)}
        />
      }
      footerLeft={
        <FooterLeftContentWrapper>
          <FooterLeftContent>
            {FinancingType.Rent === product.offer.financingType &&
              userCustomerType === CustomerType.B2B && (
                <ProductOfferTileInstallment title="Rata zawiera">
                  OC/ AC/ NNW, Opony, Serwis, Assistance, 0zł wpłaty własnej
                </ProductOfferTileInstallment>
              )}
            {FinancingType.Rent === product.offer.financingType &&
              userCustomerType === CustomerType.B2C && (
                <ProductOfferTileInstallment title="Rata zawiera">
                  OC/ AC, Opony, Serwis, Assistance, 0zł wpłaty własnej
                </ProductOfferTileInstallment>
              )}
            {FinancingType.Leasing === product.offer.financingType && (
              <ProductOfferTileInstallment title="Rata zawiera">
                ubezpieczenie GAP
              </ProductOfferTileInstallment>
            )}
          </FooterLeftContent>
        </FooterLeftContentWrapper>
      }
      footerRight={
        <FooterRightContent>
          <ProductOfferTilePrice price={product.offer.price} />
          <FinancingInformation>
            {product.availableFinancingTypes.length > 0 && (
              <div>
                dostępny w{' '}
                <strong>
                  {financingModelAvailabilityLabelMapper(
                    product.availableFinancingTypes.length
                  )}
                </strong>
              </div>
            )}
            {product.listPrice && (
              <div>
                cena katalogowa:{' '}
                <strong>{localFormatNumber(product.listPrice)}&nbsp;zł</strong>
              </div>
            )}
          </FinancingInformation>
        </FooterRightContent>
      }
    />
  );
};
