import React, { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { homeAdvertDealsSelector } from 'src/app/home/state/homeAdvert/homeAdvertSelectors';
import { homeAdvertDealsActions } from 'src/app/home/state/homeAdvert/homeAdvertActions';
import { useDispatchRequest } from 'src/lib/useDispatchRequest';
import { skewLeft } from 'src/common/styles/skew';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import Bell from 'src/assets/icons/Bell';
import ChevronRight from 'src/assets/icons/ChevronRight';
import { navigate } from 'gatsby';

interface BannerProps {
  active?: boolean;
}

const Text = styled.div`
  min-width: 20rem;
`;

const BannerBackground = styled.div<BannerProps>`
  transition: opacity 1s;
  background-color: ${colors.white};
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;

  ${({ active }) =>
    active === true &&
    css`
      transition: opacity 1s;
      opacity: 1;
    `};
`;

const StyledBell = styled(Bell)<BannerProps>`
  ${({ active }) =>
    active === true &&
    css`
      color: ${colors.primary};
    `};
  margin-right: 1rem;
`;

const StyledChevronRight = styled(ChevronRight)`
  margin-left: 2.125rem;
  color: ${colors.primary};
`;

const Container = styled.div<BannerProps>`
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  bottom: 0;
  z-index: 1;
  height: 3.375rem;
  color: ${colors.background};
  padding: 1rem 0 1rem 2.5rem;
  right: 0;
  max-width: 4.7rem;
  ${skewLeft('1.5rem')};
  transition: max-width 2s, clip-path 500ms, opacity 1s;

  ${({ active }) =>
    active === true &&
    css`
      max-width: 100%;
    `};

  &:hover {
    max-width: 100%;
  }

  &:hover ${BannerBackground} {
    transition: opacity 1s;
    opacity: 1;
  }

  &:hover ${StyledBell} {
    color: ${colors.primary};
  }

  ${media.w.max.px992(css`
    display: none;
  `)}
`;

const Content = styled.div`
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content max-content;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.375rem;
`;

export const HomeDealBanner = memo(() => {
  useDispatchRequest(homeAdvertDealsActions.request);
  const deals = useSelector(homeAdvertDealsSelector);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const showBannerTimer = setTimeout(() => setActive(true), 3000);
    const hideBannerTimer = setTimeout(() => setActive(false), 13000);

    return () => {
      clearTimeout(showBannerTimer);
      clearTimeout(hideBannerTimer);
    };
  }, []);

  if (deals == null) {
    return <></>;
  }

  return (
    <Container active={active} onClick={() => navigate(`${deals[0].link}`)}>
      <BannerBackground active={active} />
      <Content>
        <StyledBell active={active} size={20} />
        <Text>
          {deals[0].label}
          <StyledChevronRight size={7} />
        </Text>
      </Content>
    </Container>
  );
});
