import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 8 64 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.091 45.2146C15.6944 45.4242 14.1354 46.3792 13.6235 47.9282C13.5421 48.1728 13.4839 48.7435 13.4839 49.1977C13.5072 51.131 14.8567 52.3888 17.393 52.8314C20.0572 53.2856 22.9658 52.7964 24.3037 51.6784C25.0017 51.0728 25.3042 50.4089 25.3508 49.2676C25.3973 48.3708 25.3624 48.1844 25.1181 47.6254C24.2455 45.7503 21.7558 44.8885 18.091 45.2146ZM20.7087 46.8567C21.686 47.2178 22.163 48.4407 21.8722 49.8266C21.6395 50.9912 20.8832 51.492 19.3475 51.492C18.0561 51.492 17.3348 51.0262 17.044 49.9896C16.7066 48.8366 17.0556 47.474 17.8235 47.0198C18.4982 46.6238 19.8943 46.5423 20.7087 46.8567Z"
    />
    <path d="M33.7511 46.4721L34.3328 46.5536C34.6469 46.6002 34.961 46.6934 35.0308 46.7749C35.1123 46.8681 35.1355 47.5902 35.1239 48.6966L35.089 50.4785L33.181 47.9512L31.273 45.4356H25.6071V46.4604L26.1888 46.542L26.2021 46.5438C26.5122 46.5865 26.7175 46.6147 26.8534 46.727C27.1196 46.9468 27.1196 47.4889 27.1196 49.0926L27.1196 49.1812C27.1197 50.7063 27.1197 51.2303 26.8631 51.4393C26.7288 51.5487 26.5241 51.5718 26.2123 51.6069L26.2005 51.6082L25.6653 51.6664L25.6304 52.2138L25.5955 52.7728H30.3888L30.3539 52.2138L30.319 51.6664L29.7722 51.6082C29.458 51.5733 29.1555 51.4801 29.0974 51.4219C29.0159 51.3403 28.9927 50.6182 29.0043 49.4769L29.0392 47.66L30.889 50.1058C31.9128 51.4451 32.832 52.5982 32.9483 52.668C33.0646 52.7263 34.0186 52.7728 35.0774 52.7728H37.0086L37.0203 50.001C37.0319 48.4869 37.0668 47.1126 37.1133 46.9496C37.1831 46.705 37.2879 46.6468 37.8579 46.5536L38.5211 46.4488V45.3191H33.7511V46.4721Z" />
    <path d="M0.570076 46.5536L0.0116342 46.4721V45.4355H5.94507V46.4721L5.30519 46.542C4.54897 46.6468 4.43263 46.7865 4.43263 47.6367V48.2307H8.87689L8.83035 47.5203C8.77218 46.7167 8.70238 46.6468 7.89962 46.542L7.34118 46.4604V45.4355H13.2746V46.4604L12.6929 46.542C12.3726 46.586 12.1626 46.615 12.0248 46.7303C11.7622 46.95 11.7622 47.4836 11.7622 49.0343C11.7622 51.4335 11.7854 51.5034 12.6464 51.5965L13.2164 51.6664L13.2513 52.2138L13.2863 52.7728H7.32954L7.36445 52.2138L7.39935 51.6664L7.92289 51.6082C8.69074 51.515 8.73728 51.4568 8.73728 50.6066V49.8612H4.43263V50.5484C4.43263 51.1423 4.46753 51.2704 4.68858 51.4219C4.82819 51.5267 5.09578 51.6082 5.25866 51.6082C5.84037 51.6082 5.94507 51.713 5.94507 52.2604V52.7728H0L0.0349026 52.2255L0.0698052 51.6664L0.593344 51.6082C0.884199 51.5733 1.18669 51.5034 1.25649 51.4568C1.46591 51.317 1.46591 46.9147 1.26813 46.74C1.18669 46.6817 0.872565 46.5886 0.570076 46.5536Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.8704 46.4604L39.4289 46.542C39.7314 46.5886 40.0455 46.6817 40.1269 46.74C40.2898 46.8797 40.3247 51.0958 40.1618 51.3636C40.1037 51.4451 39.7895 51.55 39.4638 51.5849L38.8704 51.6431V52.7728H42.8028C47.0842 52.7612 48.0498 52.668 49.062 52.1556C50.1673 51.5849 50.7839 50.7114 50.9235 49.4885C51.1445 47.5901 50.0276 46.0645 48.0731 45.6219C47.41 45.4705 46.4676 45.4355 43.0704 45.4355H38.8704V46.4604ZM46.6654 47.1243C47.2354 47.4155 47.4216 47.9046 47.4216 49.1275C47.4216 50.0243 47.3867 50.2456 47.1773 50.5251C46.7352 51.1307 46.3047 51.2588 44.6643 51.2588H43.1751V46.9496H44.7457C45.9324 46.9496 46.4094 46.9962 46.6654 47.1243Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.4125 46.5536L52.7145 46.4721V45.4355H55.6812C58.613 45.4355 58.6479 45.4355 58.869 45.6918C58.997 45.8432 59.8812 47.1592 60.8468 48.6383C61.8008 50.1058 62.7083 51.3869 62.8595 51.4568C63.0108 51.5383 63.3249 51.6082 63.5692 51.6082H63.9997V52.7728H57.7172V51.6082H58.1593C58.4036 51.6082 58.7061 51.5383 58.8341 51.4451C59.0435 51.2821 59.0435 51.2588 58.8573 50.9443L58.6596 50.6182L56.4491 50.5833L54.2269 50.56L53.9943 50.9327C53.7034 51.4219 53.8546 51.6082 54.5294 51.6082H55.0413V52.7728H50.2713V52.1905C50.2713 51.8592 50.2713 51.7164 50.342 51.6548C50.3956 51.6082 50.4898 51.6082 50.6552 51.6082C51.4464 51.6082 51.4929 51.55 52.9472 49.1974C54.0059 47.497 54.32 46.8914 54.2269 46.7749C54.1571 46.6934 53.7965 46.5886 53.4125 46.5536ZM57.5776 49.0576C57.5659 49.011 57.2867 48.5685 56.961 48.0793C56.6468 47.5901 56.356 47.1825 56.3211 47.1825C56.2862 47.1825 55.9837 47.6251 55.6579 48.1725L55.0413 49.1624H56.3327C57.0424 49.1624 57.6009 49.1158 57.5776 49.0576Z"
    />
    <path d="M21.7329 17.2859L21.5817 14.9333L21.8841 14.8867C22.047 14.8634 22.6055 14.8285 23.129 14.8169C23.9434 14.8052 24.083 14.8401 24.1412 15.0032C24.1761 15.1197 24.4669 16.3891 24.7811 17.8333C26.596 26.079 27.2126 27.9891 28.3411 28.9907C29.1322 29.6894 29.7023 29.8525 31.5754 29.9224C34.7632 30.0272 36.0197 29.4099 36.8923 27.3019C37.3227 26.2654 38.2767 22.5734 39.2773 18.1245L39.9986 14.8634H41.1736C41.8135 14.8634 42.3836 14.91 42.4301 14.9566C42.5465 15.0731 41.6739 28.7694 41.0457 36.5725L40.906 38.3777L40.5803 38.4593C40.4058 38.5059 39.603 38.5641 38.8003 38.5874L37.3344 38.6223L36.8923 36.9918C35.7056 32.6477 35.3333 32.2051 32.6458 32.077C31.2729 32.0071 29.9583 32.1702 29.3533 32.4846C28.5505 32.8922 27.9688 34.2083 27.1544 37.4227L26.8287 38.7038L25.3744 38.6223C24.5716 38.5874 23.7572 38.5175 23.5595 38.4942L23.2104 38.4243L22.8963 34.2782C22.6055 30.4348 22.0936 22.9112 21.7329 17.2859Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.51 14.1995C19.0108 13.2212 21.6052 12.7437 26.8639 12.4758C29.1326 12.3593 37.2416 12.4292 39.2776 12.5923C42.7329 12.8601 44.9085 13.2794 46.1999 13.9316C47.1307 14.3975 48.1196 15.4107 48.5849 16.3657C49.1899 17.6003 49.3295 18.3456 49.4691 20.9778C49.6553 24.3552 49.4459 28.1636 48.8642 31.9837C47.9102 38.3543 46.2348 39.9266 39.6848 40.5439C36.0084 40.9049 26.224 40.7885 22.9897 40.3692C18.1848 39.7286 16.4862 38.1331 15.5555 33.3347C14.9039 30.0271 14.6247 26.2886 14.7062 21.991C14.7527 19.1493 14.7876 18.5902 14.997 17.8099C15.4507 16.1212 16.1953 15.0613 17.51 14.1995ZM43.175 14.4208C42.1396 14.1645 39.9873 13.9083 37.7651 13.7569C35.6361 13.6172 28.2251 13.6288 26.0728 13.7686C24.0833 13.8967 21.7914 14.1995 20.7792 14.4557C18.6269 15.0031 17.3471 16.2493 16.8934 18.2408C16.5095 19.9296 16.4164 24.7862 16.7072 28.1404C17.2773 34.7206 18.1964 37.1547 20.5349 38.2728C21.8961 38.9134 23.5598 39.1929 27.2362 39.3792C33.0766 39.682 39.8593 39.3909 42.3839 38.727C43.7567 38.3776 44.9434 37.5624 45.5949 36.5258C46.514 35.07 47.0376 32.5777 47.4913 27.5231C47.6775 25.3918 47.5728 19.7083 47.3168 18.6019C46.7933 16.2609 45.5251 14.9798 43.175 14.4208Z"
    />
  </IconBox>
);
