import React, { ComponentType, memo } from 'react';
import { OptionFilter } from 'src/app/common/models/filter';
import { StyleProps } from '@summer/react-kit';
import { ToggleButtonProps } from 'src/common/components/ToggleButton';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { ToggleOffTagButton } from 'src/app/search/components/RequestProductSection/ToggleOffTagButton';

export const ConnectedCommonOptionFilterTags = memo(
  <T extends OptionFilter>({
    kind,
  }: StyleProps & {
    kind: T;
    toggleButtonComponent?: ComponentType<ToggleButtonProps>;
  }) => {
    const { state, setValue } = useOptionFilterControl(kind);

    return (
      <ToggleButtonGroup
        items={state.options ?? []}
        value={state.value}
        onChange={setValue}
        toggleButtonComponent={ToggleOffTagButton}
      />
    );
  }
);
