import {
  AuthorizedAxios,
  BaseAxios,
  throwErrorResponse,
} from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import {
  LoginDTO,
  RegisterByCodeDTO,
  RegisterDTO,
  RegisterStep2DTO,
  ValidateTokenDTO,
} from 'src/common/models/auth';
import { User } from 'src/common/models/user';
import { StorageKeys } from 'src/config/storageKeys';
import { CompanyConfirmationDTO } from 'src/common/models/companyConfirmation';
import TagManager from 'react-gtm-module';

export const login = (identifier: string, password: string) =>
  BaseAxios.post<LoginDTO>('auth/local', { identifier, password })
    .then(flatten)
    .catch(throwErrorResponse);

export const register = (data: RegisterDTO) =>
  BaseAxios.post<void>('auth/local/register', data).catch(throwErrorResponse);

export const registerStep2 = (data: RegisterStep2DTO) =>
  BaseAxios.post<void>('auth/local/register/step-2', data)
    .then(flatten)
    .catch(throwErrorResponse);

export const registerByCode = (data: RegisterByCodeDTO) =>
  BaseAxios.post<void>('auth/local/code-registration', data)
    .then(flatten)
    .catch(throwErrorResponse);

export const validateToken = (data: ValidateTokenDTO) =>
  BaseAxios.post<void>('auth/local/register/validate-token', data).catch(
    throwErrorResponse
  );

export const fetchUser = () =>
  AuthorizedAxios.get<User>('users/me').then(flatten);

export const updateUser = (id: string, data: Partial<User>) =>
  AuthorizedAxios.put<User>(`users/${id}`, data).then(flatten);

export const storeLoginFlag = () => {
  localStorage.setItem(StorageKeys.HasEverLoggedIn, 'true');
};

export const hasEverLoggedIn = () =>
  Boolean(localStorage.getItem(StorageKeys.HasEverLoggedIn));

export const companyConfirmation = (data?: CompanyConfirmationDTO) =>
  AuthorizedAxios.post<void>('company-confirmation', data)
    .then(flatten)
    .catch(throwErrorResponse);

export const sendCompanyConfirmationEmail = (data?: CompanyConfirmationDTO) =>
  AuthorizedAxios.post<void>(
    'company-confirmation/send-confirmation',
    data
  ).catch(throwErrorResponse);

export const confirmCompany = (data: ValidateTokenDTO) =>
  AuthorizedAxios.post<LoginDTO>('company-confirmation/confirm-company', data)
    .then(flatten)
    .catch(throwErrorResponse);

export const pushUserIdEvent = (userId?: string) => {
  TagManager.dataLayer({
    dataLayer: { userId: userId ?? null },
  });
};
