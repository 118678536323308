import styled from 'styled-components';
import { Logo } from 'src/common/components/Logo';
import React, { FC } from 'react';

const LogoContainer = styled.div`
  margin-bottom: 3.6vh;
`;

export const CompanyConfirmationLogo: FC = () => (
  <LogoContainer>
    <Logo variant="light" />
  </LogoContainer>
);
