import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  bookProductActions,
  bookProductGuestActions,
  fetchProductActions,
  productsActions,
} from 'src/app/product/state/products/productsActions';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createProductOfferBookLead,
  createProductOfferGuestBookLead,
  fetchProduct,
} from 'src/app/product/services/product';
import { productMapSelector } from 'src/app/product/state/products/productsSelectors';
import { navigate } from 'gatsby';
import { SearchPageRoute } from 'src/app/routes';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { ProductMapState } from 'src/app/product/state/products/productsReducer';
import { isNone } from 'fp-ts/lib/Option';
import { GuestUserOffer, Offer } from 'src/common/models/offer';
import { pushEvent, EventName } from 'src/common/services/googleTagManager';

function* processBookProductRequest(action: PayloadAction<Offer>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(
      createProductOfferBookLead,
      action.payload.id,
      action.payload
    );
    yield put(bookProductActions.requestSuccess(response));
  } catch (error) {
    yield put(bookProductActions.requestFailure(error));
    yield put(
      snackbarActions.enqueueSnackbar({
        message:
          404 == error.statusCode
            ? 'Nie znaleziono wybranej oferty'
            : 'Wystąpił problem podczas wysyłania zamówienia',
        options: { variant: 'error' },
      })
    );
  }
}

function* bookProductRequestWatcher() {
  yield takeEvery(bookProductActions.request.type, processBookProductRequest);
}

function* processBookProductGuestRequest(
  action: PayloadAction<GuestUserOffer>
) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(
      createProductOfferGuestBookLead,
      action.payload.offer.id,
      action.payload
    );
    pushEvent(EventName.Contact, {});
    yield put(bookProductGuestActions.requestSuccess(response));
  } catch (error) {
    yield put(bookProductGuestActions.requestFailure(error));
    yield put(
      snackbarActions.enqueueSnackbar({
        message:
          404 == error.statusCode
            ? 'Nie znaleziono wybranej oferty'
            : 'Wystąpił problem podczas wysyłania zamówienia',
        options: { variant: 'error' },
      })
    );
  }
}

function* bookProductGuestRequestWatcher() {
  yield takeEvery(
    bookProductGuestActions.request.type,
    processBookProductGuestRequest
  );
}

function* fetchActiveProduct(action: PayloadAction<string>) {
  const productMap: ProductMapState = yield select(productMapSelector);

  if (productMap[action.payload] == null) {
    yield put(fetchProductActions.request(action.payload));
  } else if (isNone(productMap[action.payload])) {
    navigate(SearchPageRoute);
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Nie znaleziono wybranego produktu',
        options: { variant: 'error' },
      })
    );
  }
}

function* setActiveProductWatcher() {
  yield takeEvery(productsActions.setActiveProductId.type, fetchActiveProduct);
}

function* processProductRequest(action: PayloadAction<string>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchProduct, action.payload);
    yield put(fetchProductActions.requestSuccess(response));
  } catch (error) {
    navigate(SearchPageRoute);
    yield put(
      fetchProductActions.requestFailure({
        error,
        productId: action.payload,
      })
    );
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Nie znaleziono wybranego produktu',
        options: { variant: 'error' },
      })
    );
  }
}

function* productRequestWatcher() {
  yield takeEvery(fetchProductActions.request.type, processProductRequest);
}

export function* productSaga() {
  yield all([
    bookProductRequestWatcher(),
    bookProductGuestRequestWatcher(),
    setActiveProductWatcher(),
    productRequestWatcher(),
  ]);
}
