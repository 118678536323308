import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { HomePanelFiltersState } from 'src/app/home/components/HomePanel/HomePanelContent';
import { ReactNode } from 'react';

interface ButtonProps {
  limit: number;
  items: { id: string; label: string }[];
  toggleButtonComponent: any;
}

export const pushEngineChoiceEvent = (
  engineButtonProps: ButtonProps,
  value: string[]
) => {
  const engine = engineButtonProps.items
    .map((item, index) => ({ ...item, customId: `${400 + index}` }))
    .find((item) => item.id === value[0]);

  engine &&
    pushEvent(EventName.SelectItem, {
      item_list_name: 'silnik',
      items: [{ item_id: engine.customId, item_name: engine.label }],
    });
};

export const pushAvailabilityChoiceEvent = (
  availabilityButtonProps: ButtonProps,
  value: string[]
) => {
  const availability = availabilityButtonProps.items.find(
    (item) => item.id === value[0]
  )?.label;

  availability &&
    pushEvent(EventName.SelectAvailability, {
      availability: availability.replace(/\s/g, '_'),
    });
};

export const pushGearboxChoiceEvent = (
  gearboxButtonProps: ButtonProps,
  value: string[]
) => {
  const gearbox = gearboxButtonProps.items.find((item) => item.id === value[0])
    ?.label;

  gearbox &&
    pushEvent(EventName.SelectGearbox, {
      gearbox: gearbox.replace(/\s/g, '_'),
    });
};

export const pushBrandChoiceEvent = (
  brands: { id: string; label: ReactNode; textLabel?: string }[],
  value: string[]
) => {
  const chosenBrands = brands
    .map((b, index) => ({
      id: b.id,
      customId: `${100 + index}`,
      label: b.textLabel,
    }))
    .filter((b) => value.includes(b.id))
    .map((b) => ({
      item_id: b.customId ?? '',
      item_name: b.label?.replace(/\s/g, '_') ?? '',
    }));

  pushEvent(EventName.SelectItem, {
    item_list_name: 'marka',
    items: chosenBrands,
  });
};

export const pushCategoriesChoiceEvent = (
  categories: { id: string; label: ReactNode; textLabel?: string }[],
  value: string[]
) => {
  const chosenCategories = categories
    .map((c, index) => ({
      id: c.id,
      customId: `${200 + index}`,
      label: c.textLabel,
    }))
    .filter((c) => value.includes(c.id))
    .map((c) => ({
      item_id: c.customId ?? '',
      item_name: c.label?.replace(/\s/g, '_') ?? '',
    }));

  pushEvent(EventName.SelectItem, {
    item_list_name: 'potrzeba',
    items: chosenCategories,
  });
};

export const pushBodiesChoiceEvent = (
  bodies: { id: string; label: ReactNode; textLabel?: string }[],
  value: string[]
) => {
  const chosenBodies = bodies
    .map((b, index) => ({
      id: b.id,
      customId: `${300 + index}`,
      label: b.textLabel,
    }))
    .filter((b) => value.includes(b.id))
    .map((b) => ({
      item_id: b.customId ?? '',
      item_name: b.label?.replace(/\s/g, '_') ?? '',
    }));

  pushEvent(EventName.SelectItem, {
    item_list_name: 'nadwozie',
    items: chosenBodies,
  });
};

export const pushMonthlyInstallmentChoiceEvent = (
  v?: [number, number],
  state?: HomePanelFiltersState
) => {
  const value = v ?? state?.price.value;

  value &&
    pushEvent(EventName.MonthlyInstallment, {
      installment: `${value[0]}-${value[1]}`,
    });
};
