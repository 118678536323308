import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M13.2703 12.0142c2.804-1.401 8.1337-2.55476 14.2341-3.11105 1.798-.16483 8.4976-.12362 10.5097.06181 4.7518.45327 8.6046 1.38044 11.6441 2.82264 2.4401 1.1538 3.5746 2.493 4.2809 5.1508.3853 1.4217.4495 9.1273.1071 12.8359-.3211 3.4613-1.1987 8.3237-2.1405 11.8675-.9846 3.7085-2.8254 9.0242-3.3819 9.7659-.8776 1.1538-3.5746 2.5342-6.6355 3.379-3.4247.9477-10.3385 1.298-14.9191.7417-2.8896-.3297-6.5498-1.2362-8.2836-2.0604-2.3973-1.1331-2.7612-1.5246-3.9812-4.3679-2.6756-6.3252-4.3452-13.0006-4.96592-19.8409-.27826-3.1935-.27826-10.5489 0-12.2178.40672-2.3487 1.62672-4.1 3.53182-5.0272zm14.02 32.5944V19.8847h-2.3545l-.0428 12.362-.0642 12.3619h2.4615zm11.9866-19.6761l-1.2842-2.5342-1.2629-2.5136h-6.0147v24.7239h2.1404V32.6587h3.8529v11.9499h2.5685V24.9325zm-15.8394-2.3694v-2.4724h-7.4917v24.5179h2.3546V32.6587h4.0668v-2.4724h-4.0668v-7.6232h5.1371zm24.4013 0v-2.4724h-7.8127v2.4724h2.6756v22.0455h2.5685V22.5631h2.5686z" />
    <path d="M14.1057 6.0394c3.8742-2.493 7.7913-3.99703 12.5431-4.82117 1.1131-.18542 2.9753-.247235 5.9933-.20603 3.7887.06181 4.7091.14422 6.6355.5975 11.109 2.5342 19.8635 10.2604 23.3311 20.5415C63.8502 25.8186 64 26.8693 64 31.8347c-.0214 4.8418-.2355 6.2222-1.6482 10.0956-3.6602 9.9926-12.7357 17.554-23.9304 19.9028-2.6114.5563-10.2314.5563-12.8428 0-11.5799-2.4312-20.63412-10.178-24.1873-20.7269C.149833 37.4594 0 36.3674 0 31.5257c0-4.8418.214047-6.2222 1.54114-9.869C3.87425 15.2285 8.15518 9.89221 14.1057 6.0394zm34.5899 2.43119c-2.804-1.40102-5.9504-2.45179-9.2896-3.1317-2.2261-.45327-3.2535-.55629-7.192-.6181-4.9872-.08241-6.8066.10302-10.3598 1.03016C15.1973 7.50224 8.79732 11.6023 6.69967 15.4757c-1.47693 2.699-2.14047 6.5725-2.14047 12.2384 0 5.398.68495 9.5187 2.44013 14.381 1.47692 4.1825 4.36657 9.0861 6.54987 11.1052 7.3632 6.8815 25.6 7.7056 35.1679 1.6071 2.4615-1.5865 3.6173-2.9875 5.6294-6.8815 3.5104-6.8197 4.8374-12.259 4.8374-19.841 0-7.9322-1.2414-12.3414-4.3451-15.5555-1.4555-1.504-2.9967-2.5136-6.1432-4.05881z" />
    <path d="M33.2408 22.5014c-.3425.0824-.3853.4327-.3853 3.585v3.482h3.8528V27.611c0-1.6276-.0856-2.1839-.5779-3.3377-.2997-.7829-.6422-1.5246-.7706-1.6688-.1926-.2473-1.3485-.3091-2.119-.1031z" />
  </IconBox>
);
