import React, { memo, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { HomePanelContent } from 'src/app/home/components/HomePanel/HomePanelContent';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const Panel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  padding: 0;

  ${media.w.max.px768(css`
    padding: 0 0;
    transform: translateY(100%);

    &.enter {
      transition: transform 600ms ease-in-out;
      transform: translateY(0);
    }

    &.exit {
      transition: transform 600ms ease-in-out;
      transform: translateY(100%);
    }
  `)}

  ${media.w.min.px768(css`
    width: 60%;
    background: linear-gradient(
      90deg,
      rgba(48, 51, 55, 0.89) 48.39%,
      rgba(48, 51, 55, 0.63) 70.79%,
      rgba(48, 51, 55, 0) 99.69%
    );
    backdrop-filter: blur(2px);

    ${media.h.max.any(750)(css`
      background: linear-gradient(
        90deg,
        rgba(48, 51, 55, 0.89) 48.39%,
        rgba(48, 51, 55, 0.63) 80.79%,
        rgba(48, 51, 55, 0) 99.69%
      );
    `)}
  `)}

  ${media.w.min.px992(css`
    width: 32.5rem;
  `)}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  display: block;
  height: 100%;
  width: 100%;
`;

export const HomePanel = memo<{
  className?: string;
  onBackToAdvert: () => void;
}>(({ className, onBackToAdvert }) => {
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);

  const scrollToBottom = useCallback(() => {
    scrollRef.current?.osInstance()?.scroll(
      {
        y: '100%',
      },
      0
    );
  }, []);

  return (
    <div>
      <Panel className={className}>
        <StyledScrollContainer ref={scrollRef}>
          <HomePanelContent
            onBackToAdvert={onBackToAdvert}
            scrollToBottom={scrollToBottom}
          />
        </StyledScrollContainer>
      </Panel>
    </div>
  );
});
