import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 64 64" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74359 0H52.2308C53.971 0 57.1558 0 58.7988 0.00259634L61.2564 0.00648009V64H14.4872V59.0769H56.3333V4.92692C54.8807 4.92634 53.2899 4.92308 52.2308 4.92308H13.6667V15.5897H8.74359V0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7151 13.8494L45.8659 32.0002L27.7151 50.151L24.234 46.6699L38.9037 32.0002L24.234 17.3305L27.7151 13.8494Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 29.5385H42.3846V34.4615H3V29.5385Z"
    />
  </IconBox>
);
