import React, { RefObject, useLayoutEffect } from 'react';

export const useResizeObserver = <T extends HTMLElement>(
  elRef: RefObject<T>,
  callback: ResizeObserverCallback
) => {
  const observerRef = React.useRef(new ResizeObserver(callback));

  useLayoutEffect(() => {
    if (elRef.current) {
      observerRef.current.observe(elRef.current);
    }

    return () => {
      observerRef.current.disconnect();
    };
  }, [observerRef, elRef]);
};
