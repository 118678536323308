import Axios, { AxiosError } from 'axios';
import { fold, fromNullable, mapNullable } from 'fp-ts/lib/Option';
import { identity } from 'ramda';
import { setAuthHeader } from '@summer/react-kit';
import { environment } from 'src/config/environment';
import { tokenSelector } from 'src/state/auth/authSelectors';
import { store } from 'src/state';
import { authActions } from 'src/state/auth/authActions';
import { pipe } from 'fp-ts/lib/pipeable';
import { ErrorMessages, ErrorResponse } from 'src/common/models/error';

// ------------------- http clients

export const BaseAxios = Axios.create({
  baseURL: environment.apiUrl,
});

export const AuthorizedAxios = Axios.create({
  baseURL: environment.apiUrl,
});

// ------------------- error handling

// https://github.com/axios/axios#handling-errors
export const toErrorResponse = (error: AxiosError): ErrorResponse => {
  if (error.isAxiosError) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        error: 'Unexpected request error',
        message: 'Request error',
        statusCode: -1,
        data: error.request,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        error: 'Internal error',
        message: 'Internal error',
        statusCode: -1,
        data: error.message,
      };
    }
  } else {
    return {
      error: 'Unexpected error occurred',
      message: 'Unexpected error',
      statusCode: -1,
      data: error,
    };
  }
};

export const throwErrorResponse = (error: AxiosError) => {
  throw toErrorResponse(error);
};

export const getErrorMessages = (
  error: ErrorResponse,
  messages?: ErrorMessages
): string[] => {
  console.log(error);

  if (error.message === 'ValidationError') {
    return error.data.errors as string[];
  }

  if (messages) {
    return [
      messages[error.message ?? error.statusCode ?? 'default'] ?? error.message,
    ];
  }

  return [error.message];
};

// ------------------- http clients configuration

AuthorizedAxios.interceptors.request.use((config) =>
  pipe(
    store.getState(),
    tokenSelector,
    fold(
      () => config,
      (token) => setAuthHeader(token)(config)
    )
  )
);

AuthorizedAxios.interceptors.response.use(identity, (axiosError: AxiosError) =>
  pipe(
    fromNullable(axiosError),
    mapNullable((_) => _.response),
    fold(
      () => Promise.reject(axiosError),
      (e) => {
        if (e.status === 401) {
          store.dispatch(authActions.logout());
        }
        return Promise.reject(axiosError);
      }
    )
  )
);
