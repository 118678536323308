import React, { memo } from 'react';
import { HomeAdvertHeader } from 'src/app/home/components/HomeAdvert/HomeAdvertHeader';
import { HomeAdvertSlideshow } from 'src/app/home/components/HomeAdvert/HomeAdvertSlideshow';
import { useSelector } from 'react-redux';
import { homeAdvertSlidesSelector } from 'src/app/home/state/homeAdvert/homeAdvertSelectors';
import { homeAdvertSlidesActions } from 'src/app/home/state/homeAdvert/homeAdvertActions';
import { useDispatchRequest } from 'src/lib/useDispatchRequest';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';

export const HomeAdvert = memo(() => {
  useDispatchRequest(homeAdvertSlidesActions.request);
  const slides = useSelector(homeAdvertSlidesSelector);

  const shuffle = (array: HomeAdvertSlideType[] = []) => {
    const shuffledArray = [...array];
    let currentIndex = shuffledArray.length,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
        shuffledArray[randomIndex],
        shuffledArray[currentIndex],
      ];
    }

    return shuffledArray;
  };

  return (
    <HomeAdvertSlideshow
      slides={shuffle(slides) || []}
      header={HomeAdvertHeader}
    />
  );
});
