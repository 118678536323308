import React from 'react';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled, { css } from 'styled-components';
import { CleanUnorderedList } from 'src/common/components/List';
import { TheHeartLogo } from 'src/public/components/TheHeartLogo';
import { Paragraph } from 'src/common/components/typography/Text';
import { AutoFusGroupLogo } from 'src/public/components/AutoFusGroupLogo';
import { BackButton } from 'src/common/components/BackButton';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import { CenteredLayoutBackground } from 'src/public/components/CenteredLayoutBackground';
import { typography } from 'src/config/typography';
import { media } from 'src/config/breakpoints';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { Button } from 'src/common/components/Button';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { Link } from 'gatsby';
import { CarPlatformBusinessRoute } from 'src/public/publicRoutes';

const Layout = styled.div`
  height: 100%;
  display: grid;
  padding: 3vh 2rem;
  grid-template-columns: 100%;
  grid-template-rows: 3.5rem 1fr;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledScrollContainer = styled(ScrollContainer)`
  height: 100%;
`;

const Main = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

const Content = styled.div`
  display: grid;
  max-height: 100%;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  ${media.w.min.px576(css`
    grid-template-columns: 1fr 1fr;
  `)}
`;

const ColumnSection = styled.div`
  max-width: 29.5rem;
`;

const SectionTitle = styled(Title)`
  font-size: ${typography.headingSize[0]};
`;

const Subtitle = styled(Title)`
  font-size: ${typography.headingSize[2]};
`;

const PartnerListItem = styled.li`
  margin-bottom: 2rem;
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const AboutUsPage = () => {
  useStorePageUrl();

  return (
    <Layout>
      <Seo title="O nas" />
      <Header>
        <HomeLogoLink style={{ marginBottom: 0 }} />
        <BackButton size={36} />
      </Header>
      <StyledScrollContainer>
        <Main>
          <Content>
            <ColumnSection>
              <SectionTitle level={2} variant="condensed">
                Idea
              </SectionTitle>
              <Subtitle level={3} variant="condensed">
                Lepiej MIEĆ auto czy BYĆ mobilnym?
              </Subtitle>
              <Paragraph style={{ lineHeight: 1.55 }}>
                Wiemy, że zakup samochodu dostarcza wielu emocji i daje ogromną
                przyjemność, ale nie jest taki łatwy, jak się wydaje. Dlatego
                wykorzystując lata doświadczeń w branży samochodowej,
                zdecydowaliśmy się na otwarcie wirtualnego, multibrandowego
                salonu samochodowego we współpracy z najlepszymi dealerami w
                Polsce. Tak oto stworzyliśmy Car Platform, platformę dzięki
                której upraszczamy proces zakupu samochodu i przenosimy go
                całkowicie online. Zapewniamy dedykowanych doradców, którzy
                pomogą wybrać wymarzony samochód oraz najlepszą formę jego
                finansowania. Dbamy o&nbsp;jasną i szybką komunikację z naszymi
                Klientami.
              </Paragraph>
              <SectionTitle
                level={2}
                variant="condensed"
                style={{ marginTop: '3rem' }}
              >
                Car Platform to:
              </SectionTitle>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <b>#Ponad 30 marek aut</b> - na naszej platformie znajdziesz
                swoje wymarzone auto w najlepszej cenie. Wybierać możesz spośród
                ponad 400 ofert dostępnych na naszej platformie.
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <b>#Dedykowani doradcy</b> - skontaktuj się naszymi doradcami w
                sposób jaki najbardziej lubisz: przez telefon, chat bądź email.
                Możemy również przeprowadzić prezentację samochodu online.
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <b>#Wygoda</b> - intuicyjny wirtualny salon, który umożliwia
                proste i&nbsp;łatwe wyszukanie samochodu oferowanego przez
                najlepszych dealerów w Polsce - według własnych preferencji.
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <b>#Transparentność</b> - udostępniamy oferty bez ukrytych opłat
                i&nbsp;dodatkowych kosztów. Wynajem długoterminowy zawierający
                pełne ubezpieczenie, assistance z samochodem zastępczym, pakiet
                serwisowy oraz dodatkowy komplet opon lub klasyczny leasing na
                prostych zasadach zarówno dla konsumenta jak
                i&nbsp;przedsiębiorcy.
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <b>#Bezpieczeństwo</b> - współpracujemy z wiarygodnymi
                instytucjami finansującymi oraz dealerami, takimi jak Auto Fus
                Group.
              </Paragraph>
              <SectionTitle
                level={2}
                variant="condensed"
                style={{ marginTop: '3rem' }}
              >
                Car Platform Business to:
              </SectionTitle>
              <Paragraph style={{ lineHeight: 1.55 }}>
                Car Platform Business to program benefitowy dla pracowników firm
                współpracujących, dający dostęp do wielu korzyści, takich jak
                zniżki, comiesięczne benefity w ramach programu Mobility 3.0
                i&nbsp;oferty specjalne. Firma dołączająca do programu zyskuje:
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <StyledList>
                  <li>
                    <b>#atrakcyjne rabaty dla pracowników</b> - na zakup aut
                    prywatnych
                  </li>
                  <li>
                    <b>#comiesięczny bonus</b> - z kartą Mobility 3.0
                  </li>
                  <li>
                    <b>#oferty specjalne</b> - dostępne tylko dla zalogowanych
                    użytkowników
                  </li>
                  <li>
                    <b>#dostęp do aut przedkontraktowych</b> - w oczekiwaniu na
                    zamówiony samochód
                  </li>
                </StyledList>
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.55 }}>
                <SimpleLink as={Link} to={CarPlatformBusinessRoute}>
                  Dowiedz się więcej
                </SimpleLink>
              </Paragraph>
            </ColumnSection>
            <ColumnSection>
              <SectionTitle level={2} variant="condensed">
                Partnerzy
              </SectionTitle>
              <CleanUnorderedList>
                <PartnerListItem>
                  <Button
                    kind="box"
                    variant="transparent"
                    as="a"
                    href="https://autofusgroup.pl/"
                    target="_blank"
                  >
                    <AutoFusGroupLogo />
                  </Button>
                  <Paragraph style={{ lineHeight: 1.55 }}>
                    Auto Fus Group to firma z&nbsp;wieloletnimi tradycjami,
                    a&nbsp;obecnie jeden z&nbsp;największych dealerów marki BMW
                    w&nbsp;Polsce. Klientom oferuje nowe i&nbsp;używane auta
                    klasy premium marek, takich jak: BMW i&nbsp;BMW Motorrad,
                    MINI, Rolls-Royce, ALPINA, McLaren, Alfa Romeo oraz Jeep.
                  </Paragraph>
                </PartnerListItem>
                <PartnerListItem>
                  <Button
                    kind="box"
                    variant="transparent"
                    as="a"
                    href="https://theheart.tech"
                    target="_blank"
                  >
                    <TheHeartLogo />
                  </Button>
                  <Paragraph style={{ lineHeight: 1.55 }}>
                    The&nbsp;Heart to korporacyjny “Company Builder”, który we
                    współpracy z&nbsp;międzynarodowymi korporacjami tworzy
                    niezależne startupy, produkty i&nbsp;usługi. W&nbsp;oparciu
                    o&nbsp;autorską metodykę buduje indywidualnie dopasowane
                    rozwiązania. Wspiera korporacje w&nbsp;zwiększaniu
                    przychodów i&nbsp;minimalizowaniu kosztów poprzez
                    wyszukiwanie i&nbsp;wdrażanie technologii dostarczanych
                    przez ekosystem wiodących startupów z&nbsp;całej Europy.
                  </Paragraph>
                </PartnerListItem>
              </CleanUnorderedList>
            </ColumnSection>
          </Content>
        </Main>
      </StyledScrollContainer>
      <CenteredLayoutBackground />
    </Layout>
  );
};

// eslint-disable-next-line import/no-default-export
export default AboutUsPage;
