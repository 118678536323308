import Markdown from 'markdown-to-jsx';
import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { ProductPageSectionHeading } from 'src/app/product/components/ProductPageSectionHeading';
import { ProductPageSection } from 'src/app/product/models/productPageSection';
import { Icon } from 'src/common/components/Icon';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { Product } from 'src/app/common/models/product';
import { ProductPageParameter } from 'src/app/product/models/productPageParameter';
import {
  prepareDimensions,
  prepareDrive,
  prepareEngine,
  prepareFuelUsage,
  prepareInterior,
  preparePerformance,
} from 'src/app/product/components/ProductPageSectionParameters/utils';
import { ProductSectionContainer } from 'src/app/product/components/ProductSectionContainer';

const Container = styled(ProductSectionContainer)`
  max-width: 80rem;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 3.125rem 3.25rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1.25rem auto 0;

  ${media.w.min.px992(css`
    padding-top: 3.25rem;
  `)}

  ${media.w.min.px992(css`
    grid-gap: 3.4375rem 3.75rem;
  `)}
`;

const GridItem = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Label = styled.div`
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-top: 0.125rem;
`;

const Parameter = styled.div<{ background?: boolean; direction?: string }>`
  background: ${(params) =>
    params.background ? colors.primary : 'transparent'};
  padding: 1.9375rem 0.8125rem 0.75rem;

  ${media.w.max.px768(css`
    width: 6.1875rem;
  `)}

  ${({ direction }) =>
    direction === 'vertical' &&
    css`
      display: flex;
      align-items: center;
      padding: 1rem 0.8125rem 0;
    `}
`;

const ParameterIcon = styled.div`
  align-items: center;
  display: flex;
  height: 2.1875rem;
`;

const StyledScrollContainer = styled(ScrollContainer)`
  padding-bottom: 1rem;
`;

const Title = styled.div`
  border-bottom: 0.0625rem solid ${colors.primary};
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 0.125rem;
  padding: 0 0 0.625rem 0.75rem;
`;

const Unit = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 0.25rem;
`;

const Value = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5625rem;
  margin-top: 1rem;
`;

const Values = styled.div<{ direction?: string }>`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }

  ${({ direction }) =>
    direction === 'vertical' &&
    css`
      & > div {
        margin-left: 1rem;
      }
    `}
`;

const Wrapper = styled.div<{ direction?: string }>`
  display: flex;

  ${({ direction }) =>
    direction === 'vertical' &&
    css`
      flex-direction: column;
    `}

  ${Parameter},
  ${Title} {
    flex: 1;
    margin-left: 1.5rem;

    ${({ direction }) =>
      direction === 'vertical' &&
      css`
        margin-left: 0;
      `}

    &:first-child {
      margin-left: 0;
    }
  }
`;

export interface ProductPageSectionParametersProps {
  product?: Product;
  section: ProductPageSection;
}

export const ProductPageSectionParameters = memo<ProductPageSectionParametersProps>(
  (props) => {
    const parameters = useMemo<{
      [key: string]: ProductPageParameter | undefined;
    }>(() => {
      if (!props.product) {
        return {};
      }

      return {
        dimensions: prepareDimensions(props.product),
        drive: prepareDrive(props.product),
        engine: prepareEngine(props.product),
        fuelUsage: prepareFuelUsage(props.product),
        interior: prepareInterior(props.product),
        performance: preparePerformance(props.product),
      };
    }, [props.product]);

    const parametersItems = useMemo(
      () =>
        [
          'engine',
          'performance',
          'fuelUsage',
          'drive',
          'interior',
          'dimensions',
        ].map((key) => parameters[key]),
      [parameters]
    );

    return (
      <Container>
        <ProductPageSectionHeading
          icon={props.section.icon}
          subtitle={props.section.subtitle}
          title={props.section.title}
        />

        <StyledScrollContainer>
          <Grid>
            {parametersItems.map(
              (parameter, index) =>
                parameter && (
                  <GridItem key={index}>
                    <Wrapper>
                      {parameter.titles.map((title, index) => (
                        <Title key={index}>
                          <Markdown>{title}</Markdown>
                        </Title>
                      ))}
                    </Wrapper>
                    <Wrapper direction={parameter.displayDirection}>
                      {parameter.items.map((item, index) => (
                        <Parameter
                          direction={parameter.displayDirection}
                          background={item.background}
                          key={index}
                        >
                          <ParameterIcon>
                            <Icon code={item.icon.code} size={item.icon.size} />
                          </ParameterIcon>

                          <Values direction={parameter.displayDirection}>
                            {item.values.map((value, index) => (
                              <div key={index}>
                                <Value>
                                  {value.value}
                                  <Unit>{value.unit}</Unit>
                                </Value>
                                <Label>
                                  <Markdown>{value.label}</Markdown>
                                </Label>
                              </div>
                            ))}
                          </Values>
                        </Parameter>
                      ))}
                    </Wrapper>
                  </GridItem>
                )
            )}
          </Grid>
        </StyledScrollContainer>
      </Container>
    );
  }
);
