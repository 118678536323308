import { createRequestActions } from 'src/lib/requestActions';
import { PartialProduct } from 'src/app/common/models/product';
import { createAction } from 'src/lib/createAction';

export const findProductsRequestActions = createRequestActions(
  'searchProducts/findProducts/request',
  'searchProducts/findProducts/requestSuccess',
  'searchProducts/findProducts/requestFailure'
)<
  void,
  { products: PartialProduct[]; total: number },
  Record<string, unknown>
>();

export const searchProductsActions = {
  setQueryParamString: createAction(
    'searchProducts/setQueryParamString'
  )<string>(),
};
