import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => {
  const id0 = `${props.color?.substring(1)}0_linear`;
  const id1 = `${props.color?.substring(1)}1_linear`;
  const fill0 = `url(#${id0})`;
  const fill1 = `url(#${id1})`;

  return (
    <IconBox key={props.color} {...props}>
      <path
        opacity="0.9"
        d="M66.7514 58.5L5.67 58.5L5.67 -1.24442e-07L94.5 7.62939e-06L66.7514 58.5Z"
        fill={fill0}
      />
      <path
        d="M71.4707 35.8555L41.5798 35.8555L41.5798 7.54902L85.0498 7.54902L71.4707 35.8555Z"
        fill={fill1}
      />
      <defs>
        <linearGradient
          id={id0}
          x1="94.5004"
          y1="-0.00164387"
          x2="40.9817"
          y2="50.1354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.color} />
          <stop offset="1" stopColor={props.color} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={id1}
          x1="85.05"
          y1="7.54822"
          x2="63.6933"
          y2="23.4958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.color} />
          <stop offset="1" stopColor={props.color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </IconBox>
  );
};
