import React, { FC, useEffect } from 'react';
import { observe } from 'selector-observer';

export const AutoUnfocusObserver: FC = ({ children }) => {
  useEffect(() => {
    observe('button, a, .clickable, input[type="checkbox"]', {
      add: (_) =>
        _.addEventListener(
          'click',
          (e) =>
            (e as MouseEvent).detail > 0 &&
            (e.currentTarget as HTMLElement).blur()
        ),
    });
  });

  return <>{children}</>;
};
