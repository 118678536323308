import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { skewLeft } from 'src/common/styles/skew';
import { media } from 'src/config/breakpoints';

export const HomeMobileDivider = styled.div`
  background: ${colors.background};
  height: 3.375rem;
  left: 60%;
  position: absolute;
  bottom: 0;
  right: 0;
  ${skewLeft()};
  z-index: 3;

  ${media.w.min.px768(css`
    display: none;
  `)};
`;
