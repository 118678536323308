import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.462 39.385a2.462 2.462 0 012.461 2.461v13.128a4.103 4.103 0 004.103 4.103h45.948a4.103 4.103 0 004.103-4.103V41.846a2.462 2.462 0 014.923 0v13.128A9.026 9.026 0 0154.974 64H9.026A9.026 9.026 0 010 54.974V41.846a2.462 2.462 0 012.462-2.461z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.848 23.697a2.461 2.461 0 013.481 0L32 38.367l14.67-14.67a2.461 2.461 0 113.481 3.481l-16.41 16.41a2.462 2.462 0 01-3.481 0l-16.41-16.41a2.462 2.462 0 010-3.481z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 0a2.461 2.461 0 012.462 2.462v39.384a2.461 2.461 0 11-4.924 0V2.462A2.461 2.461 0 0132 0z"
    />
  </IconBox>
);
