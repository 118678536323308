import {
  AuthorizedAxios,
  BaseAxios,
  throwErrorResponse,
} from 'src/common/services/axios';
import { Product, UsedProduct } from 'src/app/common/models/product';
import { flatten } from 'src/lib/axios';
import { evolve } from 'ramda';
import { map } from 'fp-ts/lib/Array';
import { imageUrlTransformer } from 'src/common/services/image';
import { prependApiUrl } from 'src/config/environment';
import { GuestUserOffer, Offer } from 'src/common/models/offer';

export const ourTestsTransformer = evolve({
  fullImage: imageUrlTransformer,
  thumbImage: imageUrlTransformer,
});

export const fetchProductsCount = () =>
  BaseAxios.get<number>('/products/count')
    .then(flatten)
    .catch(throwErrorResponse);

export const createProductOfferBookLead = (offerId: string, offer: Offer) =>
  AuthorizedAxios.post(`/offers/${offerId}/leads`, { offer })
    .then(flatten)
    .catch(throwErrorResponse);

export const createProductOfferGuestBookLead = (
  offerId: string,
  offer: GuestUserOffer
) =>
  BaseAxios.post(`/guest/offers/${offerId}/leads`, offer)
    .then(flatten)
    .catch(throwErrorResponse);

export const fetchProduct = (productId: string) =>
  AuthorizedAxios.get<Product | UsedProduct>(`products/${productId}`)
    .then(flatten)
    .then(
      evolve({
        carSpecification: prependApiUrl,
        insidePhotos: map(imageUrlTransformer),
        mainPhoto: imageUrlTransformer,
        outsidePhotos: map(imageUrlTransformer),
        ourTests: map(ourTestsTransformer),
        backgroundPhoto: imageUrlTransformer,
        resellDetails: (resellDetails) =>
          resellDetails != null
            ? evolve({ conditionAssessment: prependApiUrl })(resellDetails)
            : undefined,
      })
    );
