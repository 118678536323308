import { css } from 'styled-components';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';

export type TextVariant = 'default' | 'condensed';
export type TextSize = 'small' | 'default' | 'large' | 'xLarge';

export interface TypographyBase {
  variant?: TextVariant;
  responsive?: boolean;
}

export const typographyBaseCss = css<TypographyBase>`
  font-family: ${({ variant }) =>
    'condensed' === variant
      ? typography.fontFamilyCondensed
      : typography.fontFamilyDefault};
`;

export const textUnderlineEffect = css`
  position: relative;
  padding-top: 3px;

  &::after {
    content: '';
    display: block;
    height: 1px;
    left: 0;
    right: 0;
    background-color: transparent;
    position: absolute;
    bottom: 1px;
  }

  &:hover,
  &:focus {
    &::after {
      background-color: ${colors.primary};
    }
  }

  &:active {
    color: ${colors.primary};
    &::after {
      background-color: ${colors.primary};
    }
  }
`;

/**
 * Todo: Unify with (text, paragraph and button)
 */
export const typographySizeConfig: Record<TextSize, ReturnType<typeof css>> = {
  ['small']: css`
    font-size: 0.75rem;
  `,
  ['default']: css`
    font-size: 0.875rem;
  `,
  ['large']: css`
    font-size: 1.125rem;
  `,
  ['xLarge']: css`
    font-size: 1.3125rem;
  `,
};
