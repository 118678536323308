/* eslint-disable camelcase */
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
  userEmailSelector,
  userFirstNameSelector,
} from 'src/state/auth/authSelectors';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

declare const LC_API: {
  is_loaded: () => boolean;
  open_chat_window: () => void;
  on_message:
    | ((message: { user_type: 'agent' | 'visitor' }) => void)
    | undefined;
  chat_window_maximized: () => boolean;
  on_chat_started: ((data: any) => void) | undefined;
  set_visitor_name: (name: string) => boolean;
  set_visitor_email: (email: string) => boolean;
};

const livechatScript = `
    window.__lc = window.__lc || {};
    window.__lc.license = 11952573;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
`;

export interface Livechat {
  pending: boolean;
  open: () => void;
}

export const LivechatContext = createContext<Livechat | undefined>(undefined);

export const LivechatProvider: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ready, setReady] = useState(
    typeof LC_API !== 'undefined' && LC_API.is_loaded()
  );
  const [pending, setPending] = useState(false);
  const livechatNotificationDisabledPaths = [
    '/login',
    '/register',
    '/register-step2',
    '/register-step2/',
    '/register-step2-success',
  ];

  const firstName = useSelector(userFirstNameSelector);
  const email = useSelector(userEmailSelector);

  const open = useMemo(
    () => () => {
      LC_API.set_visitor_name(firstName);
      LC_API.set_visitor_email(email);
      LC_API.open_chat_window();
      setPending(false);
    },
    [firstName, email, ready, setPending]
  );

  const context = useMemo<Livechat | undefined>(
    () => (ready ? { open, pending } : undefined),
    [open, pending]
  );

  useEffect(() => {
    eval(livechatScript);
  }, []);

  useEffect(() => {
    if (ready) {
      return;
    }

    const interval = setInterval(() => {
      if (typeof LC_API !== 'undefined' && LC_API.is_loaded()) {
        setReady(true);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [ready]);

  useEffect(() => {
    if (!ready) {
      return;
    }

    LC_API.on_chat_started = () => {
      pushEvent(EventName.Contact, {});
    };

    LC_API.on_message = (a) => {
      const pendingMessage = a?.user_type === 'agent';

      if (
        pendingMessage &&
        !LC_API.chat_window_maximized() &&
        !livechatNotificationDisabledPaths.includes(location.pathname)
      ) {
        setPending(true);
        const key = enqueueSnackbar('Nowa wiadomość od Twojego Doradcy', {
          variant: 'info',
          style: { cursor: 'pointer' },
          onClick: () => {
            open();
            closeSnackbar(key);
          },
        });
      }
    };

    return () => {
      LC_API.on_chat_started = undefined;
      LC_API.on_message = undefined;
    };
  }, [ready]);

  return (
    <LivechatContext.Provider value={context}>
      {children}
    </LivechatContext.Provider>
  );
};
