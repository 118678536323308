import React, { FC } from 'react';
import { CleanButton, CleanButtonProps } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { Icon, IconCode } from 'src/common/components/Icon';

export interface TagButtonProps extends CleanButtonProps {
  active?: boolean;
}

const StyledCleanButton = styled(CleanButton)<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.6875rem;
  border: 1px solid ${colors.grayBasic};
  border-radius: 2px;
  font-size: 0.75rem;
  line-height: 1.25;

  text-transform: uppercase;

  &:not(:disabled) {
    ${({ $active }) =>
      $active
        ? css`
            color: ${colors.darkBlue};
            background: ${colors.white};
          `
        : css`
            color: ${colors.grayBasic};
            background: transparent;
          `}
  }
}
  
  @media (hover: hover) {
    &:hover:not(:focus):not(:active):not(:disabled) {
      color: ${colors.white};
      background: ${colors.primary};
      border-color: ${colors.primary};
    }
  }

  &:focus {
    color: ${colors.primary};
    background: ${colors.darkBlue};
  }  
`;

const StyledIcon = styled(Icon)`
  margin-top: -0.125rem;
  margin-left: 0.5rem;
`;

export const TagButton: FC<TagButtonProps> = ({
  active,
  children,
  ...props
}) => (
  <StyledCleanButton $active={active} {...props}>
    <span>{children}</span>
    <StyledIcon code={IconCode.Close} size={10} />
  </StyledCleanButton>
);
