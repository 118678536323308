import React, { FC } from 'react';
import { Button } from 'src/common/components/Button';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';
import ArrowRight from 'src/assets/icons/ArrowRight';
import Document from 'src/assets/icons/Document';
import Menu from 'src/assets/icons/Menu';
import { SimpleLink } from 'src/common/components/SimpleLink';

const Container = styled.div`
  padding: 0 2rem;
  overflow-y: scroll;
  max-height: 100%;
`;

const ButtonKindsContainer = styled.div`
  margin: 1.5rem 0;
`;

const Divider = styled.span`
  width: 1rem;
  display: inline-block;
`;

const Label = styled.span`
  margin-right: 0.3125rem;
  display: inline-block;
  font-size: 0.75rem;
`;

export const ButtonShowcase: FC<RouteComponentProps> = () => {
  return (
    <Container>
      <h1>SKEW</h1>

      <ButtonKindsContainer>
        <Label>(label default primary)</Label>
        <Button kind="skew">Dołącz</Button>
        <Divider />
        <Label>(label+icon default primary)</Label>
        <Button kind="skew" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default primary)</Label>
        <Button kind="skew" icon={ArrowRight} />
        <Divider />
        <Button kind="skew" icon={Document} />
        <Divider />
        <Button kind="skew" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label small primary)</Label>
        <Button kind="skew" size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon small primary)</Label>
        <Button kind="skew" size="small" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon small primary)</Label>
        <Button kind="skew" size="small" icon={ArrowRight} />
        <Divider />
        <Button kind="skew" size="small" icon={Document} />
        <Divider />
        <Button kind="skew" size="small" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label small primary disabled)</Label>
        <Button kind="skew" size="small" disabled>
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon small primary disabled)</Label>
        <Button kind="skew" size="small" icon={ArrowRight} disabled>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon small primary disabled)</Label>
        <Button kind="skew" size="small" icon={ArrowRight} disabled />
        <Divider />
        <Button kind="skew" size="small" icon={Document} disabled />
        <Divider />
        <Button kind="skew" size="small" icon={Menu} disabled />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default secondary)</Label>
        <Button kind="skew" variant="secondary">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default secondary)</Label>
        <Button kind="skew" variant="secondary" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default secondary)</Label>
        <Button kind="skew" variant="secondary" icon={ArrowRight} />
        <Divider />
        <Button kind="skew" variant="secondary" icon={Document} />
        <Divider />
        <Button kind="skew" variant="secondary" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label small secondary)</Label>
        <Button kind="skew" size="small" variant="secondary">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon small secondary)</Label>
        <Button kind="skew" size="small" variant="secondary" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon small secondary)</Label>
        <Button
          kind="skew"
          size="small"
          variant="secondary"
          icon={ArrowRight}
        />
        <Divider />
        <Button
          kind="skew"
          size="small"
          variant="secondary"
          icon={ArrowRight}
        />
        <Divider />
        <Button kind="skew" size="small" variant="secondary" icon={Document} />
        <Divider />
        <Button kind="skew" size="small" variant="secondary" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label small secondary disabled)</Label>
        <Button kind="skew" size="small" variant="secondary" disabled>
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon small secondary disabled)</Label>
        <Button
          kind="skew"
          size="small"
          variant="secondary"
          icon={ArrowRight}
          disabled
        >
          Dołącz
        </Button>
        <Divider />
        <Label>(icon small secondary disabled)</Label>
        <Button
          kind="skew"
          size="small"
          variant="secondary"
          icon={ArrowRight}
          disabled
        />
        <Divider />
        <Button
          kind="skew"
          size="small"
          variant="secondary"
          icon={Document}
          disabled
        />
        <Divider />
        <Button
          kind="skew"
          size="small"
          variant="secondary"
          icon={Menu}
          disabled
        />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default gray)</Label>
        <Button kind="skew" variant="gray">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default gray)</Label>
        <Button kind="skew" variant="gray" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default gray)</Label>
        <Button kind="skew" variant="gray" icon={ArrowRight} />
        <Divider />
        <Button kind="skew" variant="gray" icon={Document} />
        <Divider />
        <Button kind="skew" variant="gray" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label small gray)</Label>
        <Button kind="skew" size="small" variant="gray">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon small gray)</Label>
        <Button kind="skew" size="small" variant="gray" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon small gray)</Label>
        <Button kind="skew" size="small" variant="gray" icon={ArrowRight} />
        <Divider />
        <Button kind="skew" size="small" variant="gray" icon={Document} />
        <Divider />
        <Button kind="skew" size="small" variant="gray" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label small gray disabled)</Label>
        <Button kind="skew" size="small" variant="gray" disabled>
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon small gray disabled)</Label>
        <Button
          kind="skew"
          size="small"
          variant="gray"
          icon={ArrowRight}
          disabled
        >
          Dołącz
        </Button>
        <Divider />
        <Label>(icon small gray disabled)</Label>
        <Button
          kind="skew"
          size="small"
          variant="gray"
          icon={ArrowRight}
          disabled
        />
        <Divider />
        <Button
          kind="skew"
          size="small"
          variant="gray"
          icon={Document}
          disabled
        />
        <Divider />
        <Button kind="skew" size="small" variant="gray" icon={Menu} disabled />
      </ButtonKindsContainer>

      <h1>BOX</h1>

      <ButtonKindsContainer>
        <Label>(label default primary)</Label>
        <Button kind="box">Dołącz</Button>
        <Divider />
        <Label>(label+icon default primary)</Label>
        <Button kind="box" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default primary)</Label>
        <Button kind="box" icon={ArrowRight} />
        <Divider />
        <Button kind="box" icon={Document} />
        <Divider />
        <Button kind="box" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default primary small)</Label>
        <Button kind="box" size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default primary small)</Label>
        <Button kind="box" icon={ArrowRight} size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default primary small)</Label>
        <Button kind="box" icon={ArrowRight} size="small" />
        <Divider />
        <Button kind="box" icon={Document} size="small" />
        <Divider />
        <Button kind="box" icon={Menu} size="small" />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default secondary)</Label>
        <Button kind="box" variant="secondary">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default secondary)</Label>
        <Button kind="box" variant="secondary" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default secondary)</Label>
        <Button kind="box" variant="secondary" icon={ArrowRight} />
        <Divider />
        <Button kind="box" variant="secondary" icon={Document} />
        <Divider />
        <Button kind="box" variant="secondary" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default secondary small)</Label>
        <Button kind="box" variant="secondary" size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default secondary small)</Label>
        <Button kind="box" variant="secondary" icon={ArrowRight} size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default secondary small)</Label>
        <Button kind="box" variant="secondary" icon={ArrowRight} size="small" />
        <Divider />
        <Button kind="box" variant="secondary" icon={Document} size="small" />
        <Divider />
        <Button kind="box" variant="secondary" icon={Menu} size="small" />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default gray)</Label>
        <Button kind="box" variant="gray">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default gray)</Label>
        <Button kind="box" variant="gray" icon={ArrowRight}>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default gray)</Label>
        <Button kind="box" variant="gray" icon={ArrowRight} />
        <Divider />
        <Button kind="box" variant="gray" icon={Document} />
        <Divider />
        <Button kind="box" variant="gray" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default gray small)</Label>
        <Button kind="box" variant="gray" size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default gray small)</Label>
        <Button kind="box" variant="gray" icon={ArrowRight} size="small">
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default gray small)</Label>
        <Button kind="box" variant="gray" icon={ArrowRight} size="small" />
        <Divider />
        <Button kind="box" variant="gray" icon={Document} size="small" />
        <Divider />
        <Button kind="box" variant="gray" icon={Menu} size="small" />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default transparent)</Label>
        <Button kind="box" variant="transparent">
          pobierz specyfikację
        </Button>
        <Divider />
        <Label>(label+icon default transparent)</Label>
        <Button kind="box" variant="transparent" icon={Document}>
          pobierz specyfikację
        </Button>
        <Divider />
        <Label>(icon default transparent)</Label>
        <Button kind="box" variant="transparent" icon={ArrowRight} />
        <Divider />
        <Button kind="box" variant="transparent" icon={Document} />
        <Divider />
        <Button kind="box" variant="transparent" icon={Menu} />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default transparent small)</Label>
        <Button kind="box" variant="transparent" size="small">
          pobierz specyfikację
        </Button>
        <Divider />
        <Label>(label+icon default transparent small)</Label>
        <Button kind="box" variant="transparent" icon={Document} size="small">
          pobierz specyfikację
        </Button>
        <Divider />
        <Label>(icon default transparent small)</Label>
        <Button
          kind="box"
          variant="transparent"
          icon={ArrowRight}
          size="small"
        />
        <Divider />
        <Button kind="box" variant="transparent" icon={Document} size="small" />
        <Divider />
        <Button kind="box" variant="transparent" icon={Menu} size="small" />
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default transparent disabled)</Label>
        <Button kind="box" variant="transparent" disabled>
          Dołącz
        </Button>
        <Divider />
        <Label>(label+icon default transparent disabled)</Label>
        <Button kind="box" variant="transparent" icon={ArrowRight} disabled>
          Dołącz
        </Button>
        <Divider />
        <Label>(icon default transparent disabled)</Label>
        <Button kind="box" variant="transparent" icon={ArrowRight} disabled />
        <Divider />
        <Button kind="box" variant="transparent" icon={Document} disabled />
        <Divider />
        <Button kind="box" variant="transparent" icon={Menu} disabled />
      </ButtonKindsContainer>

      <h1>LINK</h1>

      <ButtonKindsContainer>
        <Label>(label default primary)</Label>
        <Button kind="link">Zapomniałem hasła</Button>
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default primary disabled)</Label>
        <Button kind="link" disabled>
          Zapomniałem hasła
        </Button>
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default secondary)</Label>
        <Button kind="link" variant="secondary">
          Zapomniałem hasła
        </Button>
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default secondary disabled)</Label>
        <Button kind="link" variant="secondary" disabled>
          Zapomniałem hasła
        </Button>
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default transparent)</Label>
        <Button kind="link" variant="transparent">
          Zapomniałem hasła
        </Button>
      </ButtonKindsContainer>

      <ButtonKindsContainer>
        <Label>(label default transparent disabled)</Label>
        <Button kind="link" variant="transparent" disabled>
          Zapomniałem hasła
        </Button>
      </ButtonKindsContainer>

      <h1>SIMPLE LINK</h1>

      <ButtonKindsContainer>
        <Label>(default)</Label> <SimpleLink>Zapomniałem hasła</SimpleLink>
      </ButtonKindsContainer>
    </Container>
  );
};
