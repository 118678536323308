import React, { FC, useCallback, useEffect, useState } from 'react';
import { Flex, StyleProps } from '@summer/react-kit';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  userBusinessPartnerNameSelector,
  userFirstNameSelector,
} from 'src/state/auth/authSelectors';
import { typography } from 'src/config/typography';
import { LivechatButton } from 'src/common/components/LivechatButton';
import { MainMenuButton } from 'src/common/components/MainMenuButton';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { LightDarkVariant } from 'src/common/models/lightDarkVariant';
import { Button } from 'src/common/components/Button';
import { Link, navigate } from 'gatsby';
import { CarPlatformBusinessRoute, LoginRoute } from 'src/public/publicRoutes';
import { useMediaQuery } from 'react-responsive';
import { skewBoth } from 'src/common/styles/skew';
import { colors } from 'src/config/colors';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { CarPlatformBusinessBenefitsModal } from 'src/common/components/CarPlatformBusinessBenefitsModal';
import { rgba } from 'polished';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const Navigation = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;

  ${media.w.min.px768(css`
    grid-template-columns: auto auto auto;
    grid-column-gap: 0.5rem;
  `)};
`;

const UserInfo = styled.div`
  text-transform: uppercase;
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.75rem;

  display: none;

  ${media.w.min.px768(css`
    display: block;
  `)};
`;

const LoginButton = styled(Button)`
  font-weight: 700;
  text-align: left;
`;

const RegisterButton = styled(Button)`
  font-size: 0.75rem;
  position: relative;
  z-index: 2;
`;

const RegistrationPros = styled.div<{ active?: boolean }>`
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: -0.5rem;
  height: 7.5rem;
  width: 13rem;
  ${skewBoth('3rem')};
  background: rgba(0, 127, 255, 0.7);
  backdrop-filter: blur(12px);

  transition: opacity 1s;
  opacity: 0;
  visibility: hidden;

  color: ${colors.white};
  font-size: 0.5625rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2.5rem 1rem 3.75rem;

  ${({ active }) =>
    active === true &&
    css`
      transition: visibility 0s, opacity 1s;
      opacity: 1;
      visibility: visible;
    `};

  a {
    font-size: 0.75rem;
  }

  &:hover {
    opacity: 1;
  }
`;

const Registration = styled.div`
  position: relative;

  &:hover ${RegistrationPros} {
    transition: opacity 1s;
    opacity: 1;
    visibility: visible;
  }
`;

const RegistrationProsTitle = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
`;

export interface AppHeaderNavigationProps extends StyleProps {
  variant?: LightDarkVariant;
}

export const AppHeaderNavigation: FC<AppHeaderNavigationProps> = ({
  style,
  className,
}) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const firstName = useSelector(userFirstNameSelector);
  const businessPartnerName = useSelector(userBusinessPartnerNameSelector);
  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px768,
  });

  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  const handleLogin = () => {
    navigate(LoginRoute);
    pushEvent(EventName.TopMenu, {
      menu_type: 'text_menu',
      menu_item: 'login',
    });
  };

  const handleRegister = () => {
    navigate(LoginRoute);
    pushEvent(EventName.TopMenu, {
      menu_type: 'text_menu',
      menu_item: 'register',
    });
  };

  useEffect(() => {
    const showRegistrationProsTimer = setTimeout(() => setActive(true), 2000);
    const hideRegistrationProsTimer = setTimeout(() => setActive(false), 7000);

    return () => {
      clearTimeout(showRegistrationProsTimer);
      clearTimeout(hideRegistrationProsTimer);
    };
  }, []);

  return (
    <>
      <Navigation style={style} className={className}>
        {isAuthenticated ? (
          <Flex alignCenter>
            <UserInfo>
              {firstName}&nbsp;-&nbsp;{businessPartnerName}
            </UserInfo>
            {isDesktop && (
              <Button
                size="small"
                kind="skew"
                variant="primary"
                onClick={() => setShowModal(true)}
                style={{ fontSize: '0.75rem' }}
              >
                TWOJE BONUSY
              </Button>
            )}
          </Flex>
        ) : (
          <>
            {isDesktop && (
              <Flex>
                <LoginButton
                  kind="box"
                  size="small"
                  variant="transparent"
                  onClick={handleLogin}
                >
                  {/* {isDesktop ? (
                    <>ZALOGUJ</>
                  ) : (
                    <>
                      ZAREJESTRUJ <br /> / ZALOGUJ
                    </>
                  )} */}
                  ZALOGUJ
                </LoginButton>
                {/* <Registration>
                  <RegisterButton size="small" onClick={handleRegister}>
                    ZAREJESTRUJ SIĘ
                  </RegisterButton>
                  <RegistrationPros active={active}>
                    <RegistrationProsTitle>
                      Otrzymaj dodatkowe benefity i zniżki
                    </RegistrationProsTitle>
                    i dołącz do strefy Car Platform Business
                    <SimpleLink as={Link} to={CarPlatformBusinessRoute}>
                      więcej
                    </SimpleLink>
                  </RegistrationPros>
                </Registration> */}
              </Flex>
            )}
          </>
        )}
        <LivechatButton />
        <MainMenuButton />
      </Navigation>

      <ModalPortal
        open={showModal}
        onClose={onClose}
        background={rgba(colors.background, 0.8)}
        blur={10}
      >
        <CarPlatformBusinessBenefitsModal onClose={onClose} />
      </ModalPortal>
    </>
  );
};
