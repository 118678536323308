import TagManager from 'react-gtm-module';
import { userIdSelector } from 'src/state/auth/authSelectors';
import { store } from 'src/state';

export const pushEvent = (event: EventName, params: EventParams) => {
  const userId = userIdSelector(store.getState());

  TagManager.dataLayer({
    dataLayer: { event, ...params, userId },
  });
};

export enum EventName {
  TopMenu = 'top_menu',
  SalesTools = 'sales_tools',
  SelectCartype = 'select_cartype',
  ButtonClick = 'button_click',
  SelectItem = 'select_item',
  SelectAvailability = 'select_availability',
  SelectGearbox = 'select_gearbox',
  MonthlyInstallment = 'monthly_installment',
  Login = 'login',
  Purchase = 'purchase',
  SignUp = 'sign_up',
  Try = 'try',
  Contact = 'contact',
}

interface EventParams {
  [key: string]: string | { [key: string]: string }[];
}
