import { createSelector } from 'reselect';
import { BusinessPartnerState } from 'src/state/businessPartner/businessPartnerReducer';
import { RootState } from 'src/state/reducer';

export const businessPartnerStateSelector = (state: RootState) =>
  state.businessPartner;

export const businessPartnerSelector = createSelector(
  businessPartnerStateSelector,
  (state: BusinessPartnerState) => state.businessPartner
);
