import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8871 38.2989H13.8672V37.457H46.8871V38.2989Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.2487 40.1133L12.7773 40.1133L12.7773 39.2715L47.2487 39.2715L47.2487 40.1133Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.063 41.0831C53.1031 41.0831 54.7569 39.4293 54.7569 37.3892C54.7569 35.3491 53.1031 33.6953 51.063 33.6953C49.023 33.6953 47.3692 35.3491 47.3692 37.3892C47.3692 39.4293 49.023 41.0831 51.063 41.0831ZM51.063 41.9249C53.568 41.9249 55.5987 39.8942 55.5987 37.3892C55.5987 34.8842 53.568 32.8535 51.063 32.8535C48.558 32.8535 46.5273 34.8842 46.5273 37.3892C46.5273 39.8942 48.558 41.9249 51.063 41.9249Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33257 41.0831C11.3726 41.0831 13.0264 39.4293 13.0264 37.3892C13.0264 35.3491 11.3726 33.6953 9.33257 33.6953C7.2925 33.6953 5.6387 35.3491 5.6387 37.3892C5.6387 39.4293 7.2925 41.0831 9.33257 41.0831ZM9.33257 41.9249C11.8376 41.9249 13.8683 39.8942 13.8683 37.3892C13.8683 34.8842 11.8376 32.8535 9.33257 32.8535C6.82758 32.8535 4.79688 34.8842 4.79688 37.3892C4.79688 39.8942 6.82758 41.9249 9.33257 41.9249Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.0585 39.9968C52.4973 39.9968 53.6638 38.8303 53.6638 37.3915C53.6638 35.9526 52.4973 34.7862 51.0585 34.7862C49.6196 34.7862 48.4532 35.9526 48.4532 37.3915C48.4532 38.8303 49.6196 39.9968 51.0585 39.9968ZM51.0585 40.8386C52.9623 40.8386 54.5056 39.2953 54.5056 37.3915C54.5056 35.4877 52.9623 33.9443 51.0585 33.9443C49.1547 33.9443 47.6113 35.4877 47.6113 37.3915C47.6113 39.2953 49.1547 40.8386 51.0585 40.8386Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33385 39.9968C10.7727 39.9968 11.9392 38.8303 11.9392 37.3915C11.9392 35.9526 10.7727 34.7862 9.33385 34.7862C7.89498 34.7862 6.72854 35.9526 6.72854 37.3915C6.72854 38.8303 7.89498 39.9968 9.33385 39.9968ZM9.33385 40.8386C11.2376 40.8386 12.781 39.2953 12.781 37.3915C12.781 35.4877 11.2376 33.9443 9.33385 33.9443C7.43005 33.9443 5.88672 35.4877 5.88672 37.3915C5.88672 39.2953 7.43005 40.8386 9.33385 40.8386Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.559 23.8418L17.5848 29.1032H14.775C8.53058 29.1032 3.23815 31.4643 1.2126 32.7311L0.884295 38.3122L5.59612 39.1536L5.44814 39.9824L0 39.0095L0.397237 32.2565L0.574067 32.1404C2.56524 30.8337 8.14755 28.2614 14.775 28.2614H17.408L29.3822 23L29.4706 23H40.5377C42.7594 23 45.805 23.6718 48.2635 24.3297C49.4995 24.6604 50.5993 24.991 51.39 25.2389C51.7855 25.3629 52.104 25.4663 52.3239 25.5388C52.4338 25.5751 52.5192 25.6036 52.5772 25.6231L52.6434 25.6455L52.6558 25.6498L61.9852 28.3074L63.0929 29.4151V32.7028L64 33.7913V37.1371L62.9365 39.0513L55.6498 39.9855L55.5427 39.1505L62.4073 38.2704L63.1582 36.9189V34.0961L62.251 33.0076V29.7638L61.5444 29.0572L52.4041 26.4534L52.3936 26.4497L52.3924 26.4494L52.3885 26.448L52.3725 26.4426L52.3086 26.421C52.2522 26.402 52.1685 26.374 52.0603 26.3383C51.8439 26.2669 51.5293 26.1648 51.1382 26.0422C50.3556 25.7969 49.2677 25.4698 48.0459 25.1429C45.5887 24.4854 42.638 23.8419 40.5377 23.8418L29.559 23.8418Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.2565 29.1034L19.8652 29.6477L19.8496 28.806L49.2409 28.2617L49.2565 29.1034Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.0754 29.3506H62.3073V30.1924H59.3707L58.9461 30.5321L60.486 32.072H62.3073V32.9138H60.1373L57.6858 30.4623L59.0754 29.3506ZM2.57585 32.072H6.60895L6.78913 32.8733L3.25646 34.5467H0.803251V33.7049H3.06717L4.73711 32.9138H2.65921L0.885799 33.2685L0.720703 32.443L2.57585 32.072Z"
    />
  </IconBox>
);
