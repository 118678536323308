import React, { FC } from 'react';
import { CleanButton } from 'src/common/components/Button';
import styled from 'styled-components';
import { skewBoth } from 'src/common/styles/skew';
import { colors } from 'src/config/colors';
import { deceleratedEasing } from 'src/common/styles/animations';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { StyleProps } from '@summer/react-kit';

const Container = styled(CleanButton)`
  position: relative;
  white-space: initial;
  display: block;
  z-index: 1;
`;

const HoverBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: -0.5rem;
  right: 0;
  bottom: 0;

  ${skewBoth('8rem')};
  background: linear-gradient(
    239.77deg,
    rgba(224, 227, 232, 0) 0.01%,
    #e0e3e8 48.35%,
    rgba(224, 227, 232, 0) 99.97%
  );
  opacity: 0;
  transition: opacity 200ms ${deceleratedEasing};

  ${Container}:hover &, ${Container}:focus & {
    opacity: 0.1;
  }
`;

const HoverAccentContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const HoverAccent = styled.div`
  position: absolute;
  bottom: calc(0%);
  left: calc(41%);
  right: 0;
  height: 5rem;
  ${skewBoth('1.4rem')};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.5rem;
  z-index: 1111;

  background-color: ${colors.primary};
  transform: translateX(-1rem) scaleX(0.9);
  transform-origin: left center;
  opacity: 0;
  transition: transform 200ms ${deceleratedEasing},
    opacity 200ms ${deceleratedEasing};
  transition-delay: 50ms;

  ${Container}:hover &, ${Container}:focus & {
    transform: translateX(0) scaleX(1);
    opacity: 1;
  }
  // transform: translateX(0) scaleX(1);
  // opacity: 1;
`;

export const ProductOfferButton: FC = ({ children }) => (
  <Container>
    <HoverBackground />
    <HoverAccentContainer>
      <HoverAccent>
        <ArrowRight size={28} />
      </HoverAccent>
    </HoverAccentContainer>
    <div>{children}</div>
  </Container>
);

// todo: Type it better
export const NewProductOfferButtonChanged: FC<
  StyleProps &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<any, any>
> = ({ children, ...props }) => (
  <Container {...props}>
    <HoverBackground />
    <HoverAccentContainer>
      <HoverAccent />
    </HoverAccentContainer>
    <div>{children}</div>
  </Container>
);
