import styled from 'styled-components';
import { CleanUnorderedList } from 'src/common/components/List';
import { Paragraph } from 'src/common/components/typography/Text';
import { colors } from 'src/config/colors';
import { Title } from 'src/common/components/typography/Title';
import React, { FC, ReactNode } from 'react';
import { Button } from 'src/common/components/Button';
import Envelope from 'src/assets/icons/Envelope';
import Phone from 'src/assets/icons/Phone';
import { Contact } from 'src/public/models/contactDepartment';
import { SimpleLink } from 'src/common/components/SimpleLink';

const ContactSupportInfoDescription = styled(Paragraph)`
  color: ${colors.lightGray};
`;

const SupportContactListItem = styled.li`
  display: flex;
  margin-bottom: 0.625rem;
  color: ${colors.lightGray};
`;

export interface ContactSupportInfoProps {
  contactPageTitle: string;
  description: ReactNode;
  contact: Contact;
}

export const ContactDepartmentInfo: FC<ContactSupportInfoProps> = ({
  contactPageTitle,
  description,
  contact,
}) => (
  <>
    <Title level={3} variant="condensed">
      {contactPageTitle}
    </Title>
    <ContactSupportInfoDescription variant="condensed">
      {description}
    </ContactSupportInfoDescription>
    <CleanUnorderedList>
      {contact.label && (
        <SupportContactListItem>{contact.label}</SupportContactListItem>
      )}
      <SupportContactListItem>
        <div style={{ marginRight: '0.625rem' }}>
          <Envelope size={16} />
        </div>

        <Button
          kind="link"
          variant="secondary"
          as="a"
          href={`mailto:${contact.mail}`}
        >
          {contact.mail}
        </Button>
      </SupportContactListItem>
      <SupportContactListItem>
        <div style={{ marginRight: '0.625rem' }}>
          <Phone size={16} />
        </div>
        <SimpleLink href={`tel:${contact.phone}`}>{contact.phone}</SimpleLink>
      </SupportContactListItem>
    </CleanUnorderedList>
  </>
);
