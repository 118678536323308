import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 2.37036C15.636 2.37036 2.37036 15.636 2.37036 32C2.37036 48.364 15.636 61.6296 32 61.6296C48.364 61.6296 61.6296 48.364 61.6296 32C61.6296 15.636 48.364 2.37036 32 2.37036ZM0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 8.90985C19.2475 8.90985 8.90963 19.2478 8.90963 32.0002C8.90963 44.7527 19.2475 55.0906 32 55.0906C44.7525 55.0906 55.0904 44.7527 55.0904 32.0002C55.0904 19.2478 44.7525 8.90985 32 8.90985ZM6.53926 32.0002C6.53926 17.9386 17.9384 6.53948 32 6.53948C46.0616 6.53948 57.4607 17.9386 57.4607 32.0002C57.4607 46.0618 46.0616 57.4609 32 57.4609C17.9384 57.4609 6.53926 46.0618 6.53926 32.0002Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2593 31.9999C27.2593 34.6183 29.3816 36.7406 32 36.7406C34.6184 36.7406 36.7407 34.6183 36.7407 31.9999C36.7407 29.3814 34.6184 27.2591 32 27.2591C29.3816 27.2591 27.2593 29.3814 27.2593 31.9999ZM32 34.3702C30.6907 34.3702 29.6296 33.3091 29.6296 31.9999C29.6296 30.6906 30.6907 29.6295 32 29.6295C33.3093 29.6295 34.3704 30.6906 34.3704 31.9999C34.3704 33.3091 33.3093 34.3702 32 34.3702Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9739 52.0198C34.6252 52.0849 35.206 51.6098 35.2711 50.9584L36.0177 43.4922C36.2065 41.6126 38.1614 40.4657 39.8856 41.2006L42.0589 42.4987C42.6209 42.8343 43.3485 42.6508 43.6841 42.0888C44.0198 41.5269 43.8363 40.7992 43.2743 40.4636L40.9797 39.0932L40.918 39.0653C37.7018 37.6126 34.0114 39.7445 33.6592 43.2556L32.9125 50.7226C32.8474 51.3739 33.3226 51.9547 33.9739 52.0198Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9822 20.5078C27.7913 22.4081 25.7954 23.559 24.0576 22.7741L17.2293 19.6936C16.6327 19.4244 15.9308 19.6899 15.6616 20.2865C15.3924 20.8832 15.6579 21.5851 16.2546 21.8542L23.0819 24.9343C26.298 26.3865 29.9886 24.255 30.3407 20.744L31.0875 13.277C31.1526 12.6257 30.6774 12.0449 30.0261 11.9798C29.3748 11.9147 28.794 12.3899 28.7289 13.0412L27.9822 20.5078Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.0261 52.0198C30.6774 51.9547 31.1526 51.3739 31.0875 50.7226L30.3408 43.2559C29.9886 39.745 26.2985 37.6129 23.0824 39.0651L16.2546 42.1454C15.6579 42.4146 15.3924 43.1164 15.6616 43.7131C15.9308 44.3097 16.6327 44.5752 17.2293 44.3061L24.0572 41.2258C25.7949 40.4409 27.7913 41.5916 27.9822 43.4918L28.7289 50.9584C28.794 51.6098 29.3748 52.0849 30.0261 52.0198Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.3252 40.3006C50.7073 39.7691 50.5862 39.0286 50.0547 38.6465L43.9617 34.266C42.4117 33.152 42.4116 30.8479 43.9616 29.7339L50.0547 25.3533C50.5862 24.9713 50.7073 24.2307 50.3252 23.6992C49.9431 23.1677 49.2026 23.0466 48.6711 23.4287L42.5782 27.8091C39.7124 29.8689 39.7123 34.1308 42.5781 36.1907L48.6711 40.5711C49.2026 40.9532 49.9431 40.8321 50.3252 40.3006Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.0177 20.5075L35.2711 13.0412C35.206 12.3899 34.6252 11.9147 33.9739 11.9798C33.3226 12.0449 32.8474 12.6257 32.9125 13.277L33.6592 20.7437C34.0113 24.2546 37.7015 26.3868 40.9175 24.9345L47.7454 21.8542C48.342 21.5851 48.6075 20.8832 48.3383 20.2865C48.0692 19.6899 47.3673 19.4244 46.7706 19.6936L39.9428 22.7739C38.205 23.5588 36.2086 22.4077 36.0177 20.5075Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6748 40.3006C14.0569 40.8321 14.7974 40.9532 15.3289 40.5711L21.4218 36.1907C24.2876 34.1309 24.2877 29.869 21.4219 27.8092L15.3289 23.4287C14.7974 23.0466 14.0569 23.1677 13.6748 23.6992C13.2927 24.2307 13.4138 24.9713 13.9453 25.3533L20.0383 29.7338C21.5883 30.8478 21.5884 33.1519 20.0384 34.2659L13.9453 38.6465C13.4138 39.0285 13.2927 39.7691 13.6748 40.3006Z"
    />
  </IconBox>
);
