import { IconBox, IconProps } from 'src/lib/IconBox';
import React from 'react';

// eslint-disable-next-line import/no-default-export
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M27.7269 50.1259C27.1083 51.0005 26.5225 51.4317 25.9625 51.4317C25.5892 51.4317 25.3767 51.213 25.3092 50.7792C25.2825 50.6894 25.2825 50.349 25.2825 49.6948V38.3525H22.4932V50.5339C22.4932 51.6228 22.5856 52.357 22.7394 52.8237C23.0176 53.6041 23.6381 53.9694 24.5376 53.9694C25.5634 53.9694 26.6176 53.3499 27.7261 52.077V53.7579H30.5198V38.3534H27.7269V50.1259Z" />
    <path d="M12.3545 35.9937H15.638V53.7582H18.742V35.9937H22.086V33.0835H12.3545V35.9937Z" />
    <path d="M31.3603 22.5249C32.2697 22.5249 32.7052 21.8049 32.7052 20.364V13.8146C32.7052 12.3755 32.2697 11.6582 31.3603 11.6582C30.4501 11.6582 30.0137 12.3755 30.0137 13.8146V20.364C30.0146 21.804 30.4501 22.5249 31.3603 22.5249Z" />
    <path d="M38.6053 38.1653C37.5796 38.1653 36.6231 38.7271 35.7227 39.8364V33.0835H32.9307V53.7582H35.7227V52.2631C36.6542 53.4177 37.6133 53.9697 38.6053 53.9697C39.7173 53.9697 40.464 53.3848 40.8373 52.2391C41.0231 51.5857 41.1182 50.5599 41.1182 49.1351V43.0008C41.1182 41.5431 41.0231 40.5244 40.8373 39.9048C40.4631 38.7502 39.7173 38.1653 38.6053 38.1653ZM38.3271 49.3537C38.3271 50.7448 37.9173 51.4311 37.1129 51.4311C36.6542 51.4311 36.1884 51.2124 35.7218 50.7457V41.3555C36.1884 40.8959 36.6533 40.6773 37.1129 40.6773C37.9182 40.6773 38.3271 41.3902 38.3271 42.7795V49.3537Z" />
    <path d="M47.4973 38.165C46.0733 38.165 44.9604 38.6904 44.1551 39.7455C43.5613 40.5233 43.292 41.7268 43.292 43.3721V48.7695C43.292 50.405 43.596 51.6219 44.1889 52.3908C44.9951 53.4424 46.1062 53.9686 47.5675 53.9686C49.0253 53.9686 50.1693 53.4166 50.9427 52.3001C51.2836 51.7987 51.5064 51.2264 51.5942 50.6264C51.6191 50.3464 51.6538 49.7259 51.6538 48.8281V48.4193H48.8049C48.8049 49.5393 48.7702 50.1588 48.7444 50.3108C48.5853 51.0566 48.1835 51.4299 47.4973 51.4299C46.5391 51.4299 46.0733 50.7188 46.0733 49.293V46.5624H51.6547V43.3713C51.6547 41.725 51.3747 40.5224 50.7907 39.7446C50.0093 38.6895 48.8982 38.165 47.4973 38.165ZM48.8635 44.2397H46.0733V42.8148C46.0733 41.3908 46.5391 40.6779 47.4724 40.6779C48.396 40.6779 48.8635 41.3908 48.8635 42.8148V44.2397Z" />
    <path d="M57.7778 0H6.22222C2.8 0 0 2.8 0 6.22222V57.7778C0 61.2 2.8 64 6.22222 64H57.7778C61.2 64 64 61.2 64 57.7778V6.22222C64 2.8 61.2 0 57.7778 0ZM37.8489 9.30667H40.6604V20.7662C40.6604 21.4293 40.6604 21.7733 40.6951 21.864C40.7573 22.3013 40.9778 22.5244 41.3564 22.5244C41.92 22.5244 42.5129 22.0871 43.1378 21.2036V9.30667H45.9591V24.8711H43.1378V23.1733C42.0151 24.4613 40.944 25.0871 39.9156 25.0871C39.0071 25.0871 38.3804 24.7182 38.0987 23.9289C37.9431 23.4587 37.8489 22.7129 37.8489 21.6142V9.30667ZM27.1938 14.3804C27.1938 12.7173 27.4773 11.5004 28.0773 10.7138C28.8578 9.65067 29.9564 9.11822 31.3609 9.11822C32.7742 9.11822 33.8711 9.65067 34.6524 10.7138C35.2427 11.5004 35.528 12.7173 35.528 14.3804V19.8338C35.528 21.4871 35.2436 22.7138 34.6524 23.4951C33.8711 24.5556 32.7751 25.088 31.3609 25.088C29.9556 25.088 28.8569 24.5556 28.0773 23.4951C27.4773 22.7138 27.1938 21.4871 27.1938 19.8338V14.3804ZM19.6507 3.984L21.872 12.1858L24.0044 3.984H27.1671L23.4053 16.4124V24.8711H20.2764V16.4124C19.9938 14.912 19.368 12.7164 18.3653 9.80356C17.7058 7.86578 17.0187 5.91911 16.36 3.984H19.6507ZM54.2871 54.6827C53.7236 57.1387 51.7129 58.9502 49.2951 59.2196C43.5671 59.8596 37.7707 59.8631 32 59.8596C26.2284 59.8631 20.432 59.8596 14.7049 59.2196C12.2862 58.9493 10.2791 57.1378 9.71467 54.6827C8.91022 51.184 8.91022 47.3653 8.91022 43.7653C8.91022 40.1618 8.91911 36.3458 9.72445 32.8462C10.288 30.3911 12.296 28.5804 14.7147 28.3102C20.4418 27.6684 26.2382 27.6667 32.0098 27.6684C37.7804 27.6667 43.5769 27.6684 49.3049 28.3102C51.7218 28.5804 53.7316 30.3911 54.296 32.8462C55.1004 36.3467 55.096 40.1618 55.096 43.7653C55.0969 47.3653 55.0924 51.184 54.2871 54.6827Z" />
  </IconBox>
);
