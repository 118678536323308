import {
  ProductTest,
  ProductTestGroup,
  ProductTestThumb,
  ProductTestType,
} from 'src/app/product/models/productTest';
import { slice } from 'ramda';
import SwiperCore from 'swiper';

const prepareGroup = (tmpGroup: ProductTest[]) => {
  const half = Math.ceil(tmpGroup.length / 2);

  return {
    rows: [slice(0, half, tmpGroup), slice(half, tmpGroup.length, tmpGroup)],
    type: ProductTestType.Regular,
  };
};

export const getThumb = (productTest: ProductTest): ProductTestThumb => ({
  url: productTest.thumbImage
    ? productTest.thumbImage.original
    : productTest.fullImage.small ||
      productTest.fullImage.thumbnail ||
      productTest.fullImage.original,
  width: productTest.thumbImage
    ? productTest.imageDetails.thumb.width
    : productTest.imageDetails.full.width,
});

export const prepareProductTests = (productTests: ProductTest[]) => {
  const groups: ProductTestGroup[] = [];

  let tmpGroup: ProductTest[] = [];

  productTests.forEach((productTest) => {
    if (productTest.type === ProductTestType.Promoted) {
      if (tmpGroup.length > 0) {
        groups.push(prepareGroup(tmpGroup));
        tmpGroup = [];
      }

      groups.push({
        rows: [[productTest]],
        type: ProductTestType.Promoted,
      });
      return;
    }

    tmpGroup.push(productTest);
  });

  groups.push(prepareGroup(tmpGroup));

  return groups;
};

export const shouldLoop = (swiper: SwiperCore) =>
  swiper.width != null && (!swiper.isBeginning || !swiper.isEnd);
