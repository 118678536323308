import { injectReducer, injectSaga } from 'src/state';
import {
  homeAdvertReducer,
  HomeAdvertState,
} from 'src/app/home/state/homeAdvert/homeAdvertReducer';
import { homeAdvertSaga } from 'src/app/home/state/homeAdvert/homeAdvertSagas';
import { AppState } from 'src/app/state';

export const injectHomeAdvertState = () => {
  injectReducer('homeAdvert', homeAdvertReducer);
  injectSaga('homeAdvert', homeAdvertSaga);
};

export interface HomeState extends AppState {
  homeAdvert: HomeAdvertState;
}
