import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M37.7018 45.7231C36.9829 45.4437 36.1827 45.8004 35.9047 46.5166C35.2822 48.1252 34.5036 50.0009 33.4538 51.7194C33.0536 52.3745 33.2612 53.229 33.915 53.6292C34.1415 53.7676 34.391 53.8327 34.6379 53.8327C35.1058 53.8327 35.5629 53.5966 35.8247 53.1681C36.9885 51.2611 37.828 49.2429 38.4953 47.5203C38.772 46.8054 38.4166 46.0011 37.7018 45.7231Z" />
    <path d="M29.8687 55.859C28.477 56.9617 26.8956 57.7918 25.032 58.3981C24.3023 58.6354 23.9034 59.4194 24.1408 60.1491C24.3321 60.7351 24.876 61.1081 25.4619 61.1081C25.6044 61.1081 25.7494 61.0864 25.8919 61.0403C28.0443 60.3404 29.9622 59.33 31.5939 58.0373C32.1948 57.5598 32.2965 56.6863 31.8205 56.0854C31.3403 55.4861 30.4682 55.3789 29.8687 55.859Z" />
    <path d="M42.5154 35.6958C40.2232 36.9965 38.9497 39.2548 38.19 40.839C37.8578 41.5308 38.1493 42.3609 38.8411 42.6917C39.0351 42.7854 39.2399 42.8287 39.4406 42.8287C39.9587 42.8287 40.4551 42.5385 40.6938 42.0407C41.6581 40.0292 42.6429 38.818 43.888 38.11C44.554 37.7316 44.7886 36.8838 44.4088 36.2165C44.0318 35.5493 43.1868 35.3146 42.5154 35.6958Z" />
    <path d="M55.0419 32.2581C54.4112 31.8309 53.5445 31.9949 53.1131 32.6297C52.6561 33.3052 51.7202 34.2343 48.8895 34.4879C48.1258 34.5571 47.5616 35.2312 47.6307 35.9961C47.6958 36.7178 48.3021 37.2602 49.0129 37.2602C49.0549 37.2602 49.097 37.2589 49.1391 37.2547C52.1461 36.9848 54.1982 35.9812 55.4135 34.1866C55.8436 33.552 55.6767 32.688 55.0419 32.2581Z" />
    <path d="M63.9993 12C63.9993 5.83217 59.3136 1 53.3327 1C47.3518 1 42.666 5.83217 42.666 12C42.666 18.0134 48.6393 26.8134 53.3327 30.3333C58.026 26.8134 63.9993 18.0134 63.9993 12Z" />
    <path
      d="M53.4437 9C51.9125 9 50.666 10.2465 50.666 11.7777C50.666 13.309 51.9125 14.5555 53.4437 14.5555C54.975 14.5555 56.2215 13.3091 56.2215 11.7777C56.2215 10.2465 54.975 9 53.4437 9Z"
      fill="#1E1F22"
    />
    <path d="M33.3333 34.5568C33.3333 25.2116 26.0118 17.8901 16.6667 17.8901C7.32148 17.8901 0 25.2116 0 34.5568C0 43.6681 9.33333 57.0015 16.6667 62.3347C24 57.0015 33.3333 43.6681 33.3333 34.5568Z" />
    <path
      d="M16.6673 26.2234C12.072 26.2234 8.33398 29.9615 8.33398 34.5568C8.33398 39.1521 12.072 42.8901 16.6673 42.8901C21.2626 42.8901 25.0007 39.1521 25.0007 34.5568C25.0007 29.9615 21.2626 26.2234 16.6673 26.2234ZM16.6673 40.1122C13.6034 40.1122 11.1117 37.6207 11.1117 34.5566C11.1117 31.4926 13.6034 29.0012 16.6673 29.0012C19.7313 29.0012 22.2229 31.4927 22.2229 34.5568C22.2229 37.6208 19.7313 40.1122 16.6673 40.1122Z"
      fill="#1E1F22"
    />
  </IconBox>
);
