import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M27.1751 0h11.4814v3.83847h6.9894v3.66617h10.4554l-.1067 9.00406V64H9.99414V7.50464H20.3429V3.83847h6.8322V0zm-6.8322 10.5046h-7.3488V61h40.0005V16.4911l.071-5.9865h-7.4197v3.7509h-25.303v-3.7509zM30.1751 3v3.83847h-6.8322v4.41703h19.303V6.83847h-6.9894V3h-5.4814zm15.819 24h-19v-3h19v3zm-23 0h-3v-3h3v3zm0 10.5h-3v-3h3v3zm23 0h-19v-3h19v3zm-23 10.5h-3v-3h3v3zm23 0h-19v-3h19v3z"
    />
  </IconBox>
);
