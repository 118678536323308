import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { useSelector } from 'react-redux';
import { userFirstNameSelector } from 'src/state/auth/authSelectors';

const Title = styled.h1`
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  font-size: 1.375rem;

  ${media.w.min.px768(css`
    font-size: 1.875rem;
  `)}

  ${media.w.min.px992(css`
    font-size: 1.75rem;
  `)}
`;

const Container = styled.div`
  margin-bottom: 0.5rem;

  ${media.w.min.px576(css`
    ${media.h.min.any(750)(
      css`
        margin-bottom: 2.5rem;
      `
    )}
  `)}

  ${media.w.min.px768(css`
    margin-top: 1rem;
  `)}
`;

export const HomeWelcomeTitle: FC = () => {
  const firstName = useSelector(userFirstNameSelector);

  return (
    <Container>
      <Title>
        Cześć {firstName}!
        <br />
        ZNAJDŹ IDEALNE AUTO DLA SIEBIE
      </Title>
    </Container>
  );
};
