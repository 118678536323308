import React, { FC, ReactNode } from 'react';
import { CleanButton } from 'src/common/components/Button';
import styled from 'styled-components';
import { skewLeft } from 'src/common/styles/skew';
import { colors } from 'src/config/colors';
import { deceleratedEasing } from 'src/common/styles/animations';
import ArrowRight from 'src/assets/icons/ArrowRight';

const Container = styled(CleanButton)`
  position: relative;
  white-space: initial;
  display: block;
`;

const HoverAccentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: -0.5rem;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
`;

const HoverAccent = styled.div`
  position: absolute;
  color: ${colors.white};
  top: 11.5rem;
  width: 12.75rem;
  height: 3.375rem;
  right: 0;
  ${skewLeft('1.4rem')};

  display: grid;
  align-items: center;
  grid-template-columns: min-content min-content;
  grid-column-gap: 1rem;
  padding-left: 1.5rem;

  background-color: ${colors.primary};
  transform: translateX(100%);
  opacity: 0;
  transition: transform 200ms ${deceleratedEasing},
    opacity 200ms ${deceleratedEasing};
  transition-delay: 50ms;

  ${Container}:hover &, ${Container}:focus & {
    transform: translateX(0);
    opacity: 1;
  }
`;

// todo: Type it better
export interface UsedProductOfferButtonProps {
  tabIndex?: number;
  hoverAccentLabel?: ReactNode;
}
export const UsedProductOfferButton: FC<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UsedProductOfferButtonProps & Record<any, any>
> = ({ hoverAccentLabel, children, ...props }) => (
  <Container {...props}>
    <HoverAccentContainer>
      <HoverAccent>
        <ArrowRight size={28} />
        <div>{hoverAccentLabel}</div>
      </HoverAccent>
    </HoverAccentContainer>
    <div>{children}</div>
  </Container>
);
