import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useRequest } from 'src/lib/useRequest';
import { RequestStatus } from 'src/state/requestStatus/requestStatus';

export const useDispatchRequest = (
  actionCreator: ActionCreatorWithoutPayload
) => {
  const dispatch = useDispatch();
  const { isLoading, timestamp, status } = useRequest(actionCreator);

  useEffect(() => {
    if (status !== RequestStatus.Ok && !isLoading) {
      dispatch(actionCreator());
    }
  }, []);

  return { isLoading, timestamp, status } as const;
};
