import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.0329 32.2047L45.8117 19.9836L48.7953 17L64 32.2047L48.7953 47.4094L45.8117 44.4258L58.0329 32.2047Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 30.1406H49.4256V34.36H0V30.1406Z"
    />
  </IconBox>
);
