import { HomePageRoute } from 'src/app/routes';
import { Logo } from 'src/common/components/Logo';
import React, { FC } from 'react';
import styled from 'styled-components';
import { StyleProps } from '@summer/react-kit';
import { Link } from 'gatsby';

const Container = styled.div`
  margin-bottom: 3.6vh;
`;

export const HomeLogoLink: FC<StyleProps> = (props) => (
  <Container>
    <Link to={HomePageRoute} {...props}>
      <Logo variant="light" />
    </Link>
  </Container>
);
