import React, { memo } from 'react';
import { FilterGroupPanel } from 'src/app/search/models/filterGroupPanel';
import { CrossroadFilterPanelRoute } from 'src/app/search/components/SearchPanel/CrossroadFilterPanelRoute';
import styled, { css } from 'styled-components';
import { PriceRangeFilter } from 'src/app/search/components/PriceRangeFilter';
import { SearchFilterSliderContainer } from 'src/app/search/components/SearchPanel/SearchFilterSlider';
import { media } from 'src/config/breakpoints';
import { FinancingTypeSwitch } from 'src/common/components/FinancingTypeSwitch';
import { B2BB2CSwitch } from 'src/common/components/B2BB2CSwitch';
import { colors } from 'src/config/colors';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import { AvailabilitySwitch } from 'src/common/components/AvailabilitySwitch';
import { NewUsedProductsPreferenceSwitch } from 'src/common/components/NewUsedProductsPreferenceSwitch';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(8, 3.125rem);
  grid-row-gap: 0.625rem;

  ${media.w.min.px768(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
  `)};

  ${media.w.min.px768(css`
    grid-template-rows: repeat(4, 3.125rem);
    grid-column-gap: 2rem;
  `)};

  ${media.w.min.px992(css`
    grid-template-rows: repeat(2, 3.125rem);
  `)};
`;

const StyledSearchFilterSliderContainer = styled(SearchFilterSliderContainer)`
  display: grid;
  grid-template-columns: min-content 1fr;

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const PriceRangeTitle = styled.div`
  color: ${colors.white};
  font-size: 0.813rem;
  margin-right: 1.438rem;
  text-transform: uppercase;
  font-weight: 700;
`;

const StyledAvailabilitySwitch = styled(AvailabilitySwitch)`
  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const StyledFinancingTypeSwitch = styled(FinancingTypeSwitch)`
  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const StyledB2BB2CSwitch = styled(B2BB2CSwitch)`
  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const StyledNewUsedProductsSwitch = styled(NewUsedProductsPreferenceSwitch)`
  ${media.w.min.px768(css`
    display: none;
  `)};
`;

const StyledHorizontalToggleButton = styled(HorizontalToggleButton)<{
  value: boolean;
}>`
  color: rgba(255, 255, 255, 0.4);

  &:enabled {
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.lightGray};
        color: ${colors.grayBasic};
      }
    }

    &:focus {
      border-color: ${colors.white};
    }
  }

  ${({ value }) =>
    value &&
    css`
      color: ${colors.white};
      background-color: rgba(255, 255, 255, 0.1);

      &:enabled {
        @media (hover: hover) {
          &:hover {
            background-color: ${colors.lightGray};
            color: ${colors.grayBasic};
          }
        }

        &:focus {
          border-color: ${colors.white};
        }
      }
    `}
  &:disabled {
    opacity: 0.3;
  }
`;

export const SearchPanelCrossroad = memo(() => {
  return (
    <Container>
      <CrossroadFilterPanelRoute group={FilterGroupPanel.General} />
      <CrossroadFilterPanelRoute group={FilterGroupPanel.Performance} />
      <CrossroadFilterPanelRoute group={FilterGroupPanel.Appearance} />
      <CrossroadFilterPanelRoute group={FilterGroupPanel.Equipment} />
      <StyledNewUsedProductsSwitch
        toggleButtonComponent={StyledHorizontalToggleButton}
      />
      <StyledB2BB2CSwitch
        toggleButtonComponent={StyledHorizontalToggleButton}
      />
      <StyledAvailabilitySwitch
        toggleButtonComponent={StyledHorizontalToggleButton}
      />
      <StyledFinancingTypeSwitch
        toggleButtonComponent={StyledHorizontalToggleButton}
      />
      <StyledSearchFilterSliderContainer>
        <PriceRangeTitle>miesięczna rata</PriceRangeTitle>
        <PriceRangeFilter variant="dark" />
      </StyledSearchFilterSliderContainer>
    </Container>
  );
});
