import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 14 64 36" {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32.1348 45.0944c-16.1627 0-29.26267-5.6349-29.26267-12.5867 0-6.9507 13.09997-12.5867 29.26267-12.5867 16.1622 0 29.2638 5.636 29.2638 12.5867 0 6.9518-13.1016 12.5867-29.2638 12.5867zM32 16C14.3261 16 0 23.3547 0 32.427c0 9.0724 14.3261 16.4276 32 16.4276s32-7.3552 32-16.4276C64 23.3547 49.6739 16 32 16z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M42.5803 25.9002c.0191-.0377.0377-.1045.0377-.1496 0-.1832-.1486-.3323-.3291-.3323l.0021-.0032-.4442.0032c-.0372 0-.0669-.0292-.0669-.0653v-.7819c0-.0377.0297-.0663.0669-.0663l1.6751-.0016h.3748l3.9454-.0032c.1794 0 .3519.0377.4113.2213l5.2024 15.1839c.0053.0266.0106.0579.0106.0855 0 .1619-.128.2914-.2877.2914l.0074.0021-3.7543.0027c-.1794 0-.3731-.1513-.4321-.3355l-3.2155-9.7968c-.0223-.0839-.0743-.1556-.1646-.1556-.0913 0-.1438.0637-.1645.1556l-3.214 9.7968c-.0605.1842-.2542.3355-.4342.3355l-3.7415-.0037c-.1609 0-.292-.1306-.292-.2925 0-.0276.0022-.0541.0101-.0812l4.7968-14.0093z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M30.0853 25.887v-.1455c0-.1831-.1555-.3259-.3365-.3259l-.4973-.0005c-.0367 0-.0669-.0297-.0669-.0669v-.7824c0-.0345.0302-.0658.0669-.0658h4.9671c.18 0 .3281.1486.3281.3323v15.1212c0 .1821-.1481.3307-.3281.3307h-3.8068c-.181 0-.3281-.1486-.3281-.3307V26.2161l.0016-.3291z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M26.8616 40.2833l-4.3823-.0011c-.1815 0-.4363-.1496-.5684-.3328l-4.3754-6.0123c-.0743-.069-.0796-.0913-.13-.1337-.0319-.0218-.0812-.0356-.1232-.0356-.112 0-.1804.0855-.1804.2012v.0812-.0075l.0026 5.911c0 .182-.1465.3307-.328.3307h-3.6917c-.181 0-.3275-.1487-.3275-.3307l.0032-14.212v-.0016c0-.1832-.1444-.3307-.3254-.3307h.009l-.517.0037c-.036 0-.0658-.0303-.0658-.0669v-.7803c0-.0366.0298-.0674.0658-.0674h1.1577l3.6917.0016c.1815 0 .328.1486.328.3323l-.001 3.9189v.2855l.001.7421v-.0075.0802c0 .1152.068.2017.1805.2017.0424 0 .0913-.0143.1253-.0361.0483-.0409.052-.0642.1273-.1348l.6051-.8339 3.0224-4.2134c.1338-.1842.388-.3328.5701-.3328h4.1959c.1592 0 .2818.1305.2818.2919 0 .06-.0138.1162-.0435.1635l-4.6412 6.7469c-.0505.0685-.077.1258-.077.2044 0 .0806.0196.1258.0716.1942l5.5537 7.6944c.051.0542.0696.1141.0696.1932 0 .1619-.1258.2925-.2845.2925z"
    />
  </IconBox>
);
