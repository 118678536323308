import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66016 15.0468C3.66016 13.4401 4.96247 12.1377 6.56896 12.1377H58.9274C60.5338 12.1377 61.8362 13.4401 61.8362 15.0468C61.8362 16.6534 60.5338 17.9559 58.9274 17.9559H6.56896C4.96247 17.9559 3.66016 16.6534 3.66016 15.0468Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9318 6.31818C26.1603 6.31818 25.4205 6.62467 24.875 7.17023C24.3295 7.7158 24.023 8.45574 24.023 9.22727V12.1364H41.4758V9.22727C41.4758 8.45574 41.1693 7.7158 40.6238 7.17023C40.0783 6.62467 39.3385 6.31818 38.567 6.31818H26.9318ZM47.2934 12.1364V9.22727C47.2934 6.91265 46.374 4.69284 44.7375 3.05616C43.101 1.41948 40.8814 0.5 38.567 0.5H26.9318C24.6174 0.5 22.3978 1.41948 20.7613 3.05616C19.1248 4.69284 18.2054 6.91265 18.2054 9.22727V12.1364H12.3878C10.7813 12.1364 9.479 13.4388 9.479 15.0455V55.7727C9.479 58.0873 10.3984 60.3072 12.0349 61.9438C13.6714 63.5805 15.891 64.5 18.2054 64.5H47.2934C49.6078 64.5 51.8274 63.5805 53.4639 61.9438C55.1004 60.3072 56.0198 58.0873 56.0198 55.7727V15.0455C56.0198 13.4388 54.7175 12.1364 53.111 12.1364H47.2934ZM15.2966 17.9545V55.7727C15.2966 56.5443 15.6031 57.2842 16.1486 57.8298C16.6941 58.3753 17.4339 58.6818 18.2054 58.6818H47.2934C48.0649 58.6818 48.8047 58.3753 49.3502 57.8298C49.8957 57.2842 50.2022 56.5443 50.2022 55.7727V17.9545H15.2966Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9308 26.6807C28.5373 26.6807 29.8396 27.9831 29.8396 29.5898V47.0443C29.8396 48.651 28.5373 49.9534 26.9308 49.9534C25.3243 49.9534 24.022 48.651 24.022 47.0443V29.5898C24.022 27.9831 25.3243 26.6807 26.9308 26.6807Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.566 26.6807C40.1725 26.6807 41.4748 27.9831 41.4748 29.5898V47.0443C41.4748 48.651 40.1725 49.9534 38.566 49.9534C36.9595 49.9534 35.6572 48.651 35.6572 47.0443V29.5898C35.6572 27.9831 36.9595 26.6807 38.566 26.6807Z"
      />
    </g>
  </IconBox>
);
