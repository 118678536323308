import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M0 11H64V18.1111H0V11Z" />
    <path d="M0 28.7773H64V35.8885H0V28.7773Z" />
    <path d="M0 46.5557H64V53.6668H0V46.5557Z" />
  </IconBox>
);
