import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconCode } from 'src/common/components/Icon';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';

const Heading = styled.div`
  align-items: center;
  color: ${(props) =>
    props.theme.variant === 'light' ? colors.background : colors.lightGray};
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.875rem;
  padding-bottom: 0.3125rem;

  ${media.w.min.px576(css`
    flex-wrap: nowrap;
    font-size: 1.375rem;
  `)}
`;

const StyledIcon = styled(Icon)`
  color: ${(props) =>
    props.theme.variant === 'light' ? colors.background : colors.lightManatee};
  position: relative;
  top: -0.125rem;
`;

const Subtitle = styled.div`
  font-weight: 300;

  ${media.w.max.px576(css`
    font-size: 0.875rem;
    line-height: 1;
    margin-top: 0.5rem;
    text-align: center;
    width: 100%;
  `)}
`;

const Title = styled.div`
  margin: 0 2rem 0 0.75rem;
  text-transform: uppercase;
`;

export interface ProductPageSectionHeadingProps {
  icon: IconCode;
  subtitle: string;
  title: string;
}

export const ProductPageSectionHeading = memo<ProductPageSectionHeadingProps>(
  (props) => (
    <Heading>
      <StyledIcon code={props.icon} height={20} />
      <Title>{props.title}</Title>
      <Subtitle>{props.subtitle}</Subtitle>
    </Heading>
  )
);
