import { prependApiUrl } from 'src/config/environment';
import { evolve } from 'ramda';

export const imageUrlTransformer = evolve({
  original: prependApiUrl,
  small: prependApiUrl,
  thumbnail: prependApiUrl,
  large: prependApiUrl,
  medium: prependApiUrl,
});
