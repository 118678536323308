import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';

const portalRoot = isPlatformBrowser()
  ? document?.getElementById('portal') ?? null
  : null;

export const Portal = memo(({ children }) => {
  const elementRef = useRef(document?.createElement('div') ?? null);

  useEffect(() => {
    portalRoot?.appendChild(elementRef.current);

    return () => {
      portalRoot?.removeChild(elementRef.current);
    };
  }, []);

  return elementRef.current
    ? ReactDOM.createPortal(children, elementRef.current)
    : null;
});
