import React, { CSSProperties, memo } from 'react';
import styled, { keyframes } from 'styled-components';
import { range } from 'ramda';
import { colors } from 'src/config/colors';
import { darken } from 'polished';

const defaultBaseColor = darken(0.08, colors.background);
const defaultHighlightColor = darken(0.16, colors.background);

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const StyledSkeleton = styled.div`
  background-color: ${defaultBaseColor};
  background-image: linear-gradient(
    90deg,
    ${defaultBaseColor},
    ${defaultHighlightColor},
    ${defaultBaseColor}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: 100%;
  vertical-align: middle;

  animation: ${skeletonKeyframes} 1.2s ease-in-out infinite;
`;

export interface SkeletonProps {
  count?: number;
  style?: CSSProperties;
  className?: string;
}

export const Skeleton = memo<SkeletonProps>(
  ({ count = 1, style, className }) => (
    <>
      {range(0, count).map((_) => (
        <StyledSkeleton key={_} className={className} style={style}>
          &zwnj;
        </StyledSkeleton>
      ))}
    </>
  )
);
