import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M37.3647 23.1765L40.9655 23.3176V20H37.2253L37.3647 23.1765Z" />
    <path d="M42.1269 40H45.6115V20.0001H42.1269V40Z" />
    <path d="M46.773 23.2942H50.4899V20.0001H46.773V23.2942Z" />
    <path d="M19.6862 24.9648C19.3146 25.0824 18.5944 25.553 18.1298 26.0236L17.2703 26.8942V25.4118H14.018V44H17.5026V41.1765C17.5026 39.6235 17.5955 38.353 17.6884 38.353C17.8046 38.353 18.0833 38.5883 18.3389 38.8471C19.0126 39.6235 20.1276 40 21.6841 40C24.0768 40 25.7959 38.9177 26.9109 36.6824C28.5603 33.4118 27.8401 28.1177 25.4474 26.1177C23.9606 24.8707 21.6144 24.4001 19.6862 24.9648ZM22.0325 28.0942C23.287 28.6354 23.891 30.0236 23.891 32.4706C23.891 34.8471 23.4031 36.0471 22.1254 36.753C21.173 37.2941 19.8024 37.2 18.9196 36.5177C18.0136 35.8589 17.4793 34.0942 17.5723 32.0942C17.6884 30.0001 18.2692 28.7295 19.361 28.1883C20.3367 27.6706 20.9871 27.6706 22.0325 28.0942Z" />
    <path d="M4.4935 25.247C2.84414 25.7176 1.79876 26.4941 1.26446 27.6235C0.985696 28.1647 0.776622 28.847 0.776622 29.1294C0.776622 29.6235 0.892774 29.647 2.40276 29.647C3.93597 29.647 4.05212 29.6 4.2612 29.0823C4.63288 28.0705 5.49241 27.6941 7.07208 27.8117C8.69822 27.9294 9.1396 28.3058 9.1396 29.5764C9.1396 30.5882 8.81437 30.7294 5.93379 31.0117C1.77553 31.4352 0.312012 32.6117 0.312012 35.4588C0.312012 37.8352 1.24123 39.247 3.30874 39.9529C4.86519 40.494 7.30439 40.1882 8.51237 39.294C9.06991 38.8705 9.23252 38.8235 9.39513 39.0823C9.51129 39.2705 9.60421 39.5529 9.60421 39.694C9.60421 39.9058 10.1153 39.9999 11.3233 39.9999H13.0656L12.8332 39.247C12.7171 38.8235 12.6242 36.0705 12.6242 33.1529C12.6242 28.0235 12.6009 27.8117 12.0899 27.0353C11.3 25.8353 9.81328 25.1764 7.49023 25.0588C6.25902 24.9882 5.14395 25.0588 4.4935 25.247ZM9.04668 35.4352C8.81437 36.6352 7.95484 37.247 6.35194 37.3646C4.60965 37.4823 3.79659 37.0588 3.79659 35.9764C3.79659 35.5764 3.91274 35.0588 4.05212 34.7764C4.35412 34.2117 5.98025 33.4352 6.9327 33.4117C7.32762 33.3882 7.95484 33.247 8.32653 33.0823L9.02345 32.7764L9.09314 33.7411C9.1396 34.2588 9.11637 35.0352 9.04668 35.4352Z" />
    <path d="M55.8329 25.2706C55.2289 25.4118 54.3926 25.7883 53.928 26.0706C53.0452 26.6353 52.116 28.2118 52.116 29.1294C52.116 29.6236 52.2322 29.6471 53.8118 29.6471C55.4612 29.6471 55.4844 29.6236 55.8329 28.8706C56.0884 28.3295 56.4601 28.0236 57.1338 27.7883C58.9458 27.153 61.0597 28.4236 60.5951 29.8824C60.3628 30.5883 59.8982 30.753 57.1338 31.0353C53.1149 31.4353 51.8372 32.4236 51.6979 35.2471C51.6282 36.5647 51.6979 36.9882 52.1625 37.8824C53.4866 40.3529 57.5055 41.0118 59.9447 39.1529L60.6648 38.5882L60.9204 39.2941C61.1527 39.9765 61.1759 40 62.7323 40H64.312L64.2423 34.0706C64.1726 27.4353 64.0797 26.9648 62.593 26C61.1294 25.0353 57.9469 24.6824 55.8329 25.2706ZM60.479 35.2941C60.1073 36.7059 59.1084 37.4118 57.4823 37.4118C56.042 37.4118 55.5077 37.1294 55.2986 36.2588C55.0663 35.2706 55.6703 34.2353 56.7389 33.8118C57.2732 33.5765 57.9236 33.4118 58.2256 33.3883C58.5044 33.3883 59.1084 33.2471 59.5498 33.1059C60.3628 32.8236 60.3628 32.8236 60.5487 33.6236C60.6416 34.0471 60.6184 34.8 60.479 35.2941Z" />
    <path d="M33.8801 25.5295C33.5084 25.7412 32.9044 26.2354 32.5559 26.6354L31.9055 27.3883V25.4118H28.6532V40H32.0913L32.1842 35.8118C32.2539 32.4706 32.3469 31.5059 32.6489 30.9177C33.2064 29.8824 34.275 29.0354 35.2507 28.8471L36.087 28.6824V25.1765H35.3436C34.9254 25.1765 34.275 25.3412 33.8801 25.5295Z" />
    <path d="M37.2485 40H40.9654V25.4118H37.2485V40Z" />
    <path d="M46.773 40H50.4899V25.4118H46.773V40Z" />
  </IconBox>
);
