import { injectReducer, injectSaga } from 'src/state';
import { RootState } from 'src/state/reducer';
import {
  commonFiltersReducer,
  CommonFiltersState,
} from 'src/app/state/commonFilters/commonFiltersReducer';
import { commonFiltersSaga } from 'src/app/state/commonFilters/commonFiltersSagas';
import {
  customerTypeReducer,
  CustomerTypeState,
} from 'src/app/state/customerType/customerTypeReducer';

export const injectAppState = () => {
  injectReducer('customerType', customerTypeReducer);
  injectReducer('commonFilters', commonFiltersReducer);
  injectSaga('commonFilters', commonFiltersSaga);
};

export interface AppState extends RootState {
  commonFilters: CommonFiltersState;
  customerType: CustomerTypeState;
}
