import { Typography } from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-tag': {
    backgroundColor: 'white',
    color: 'black',
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: 'white',
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: 'white',
  },
}));
export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  //if focused
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: 'white',
  },
}));

// const Title = styled.h1`
//   font-weight: bold;
//   line-height: 1;
//   margin: 0;
//   font-size: 1.375rem;
// `;

export const StyledBrandTypography = styled(Typography)(() => ({
  marginBottom: '0.5rem',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.875rem',
}));
