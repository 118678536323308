import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { confirmCompanyActions } from 'src/state/companyConfirmation/companyConfirmationActions';
import { useRequestEffect } from 'src/lib/useRequestEffect';
import { navigate } from 'gatsby';
import { AppRoute, HomePageRoute } from 'src/app/routes';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const ConfirmCompanyPage: FC = () => {
  useStorePageUrl();

  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = useSelector(isAuthenticatedSelector);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    dispatch(
      confirmCompanyActions.request({
        token: searchParams.get('token') || undefined,
      })
    );
  }, []);

  useRequestEffect(confirmCompanyActions.request, {
    onSuccess: () => navigate(AppRoute),
    onError: () => (isLoggedIn ? navigate(AppRoute) : navigate(HomePageRoute)),
  });

  return <></>;
};

// eslint-disable-next-line import/no-default-export
export default ConfirmCompanyPage;
