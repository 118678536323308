import React, { FC } from 'react';
import { CleanButton } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { useRequest } from 'src/lib/useRequest';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { useSelector } from 'react-redux';
import { searchProductsResultsTotalSelector } from 'src/app/search/state/searchProducts/searchProductsSelectors';
import { countLabelFormatter } from '@summer/react-kit/functions';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import ArrowRight from 'src/assets/icons/ArrowRight';

const Container = styled(CleanButton)`
  display: none;
  width: 100%;
  height: 4rem;
  text-transform: uppercase;

  ${media.w.min.px768(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    font-weight: bold;
    z-index: 10;
  `)};
`;

export interface SearchPanelDesktopCloseButtonProps {
  onClose: () => void;
}

const offerCountLabelFormatter = countLabelFormatter(
  '0 ofert',
  '1 ofertę',
  (count) => `${localFormatNumber(count)} oferty`,
  (count) => `${localFormatNumber(count)} ofert`
);

const StyledArrowRight = styled(ArrowRight)`
  transform: rotate(90deg);
`;

export const SearchPanelDesktopCloseButton: FC<SearchPanelDesktopCloseButtonProps> = ({
  onClose,
}) => {
  const { isLoading } = useRequest(findProductsRequestActions.request);
  const total = useSelector(searchProductsResultsTotalSelector);

  return (
    <Container onClick={onClose}>
      {isLoading ? (
        <>Szukamy...</>
      ) : total ? (
        <>Zobacz {offerCountLabelFormatter(total)}</>
      ) : (
        <>Znajdź auto marzeń</>
      )}
      <StyledArrowRight size={22} />
    </Container>
  );
};
