import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M62.0469 6.96435C61.9775 6.99922 60.6844 7.54858 59.1831 8.1677C57.6818 8.78681 55.6684 9.62392 54.7138 10.0163C44.5689 14.228 39.2231 16.4516 33.0181 19.024C26.8479 21.5789 8.86653 29.0432 4.74436 30.761C3.45997 31.2929 2.24502 31.851 2.02806 32.008C0.509364 33.098 -0.124149 35.5395 0.63086 37.3533C1.07345 38.4084 1.4119 38.7921 5.73368 43.1084C9.65625 47.015 10.0121 47.3987 10.2464 47.9131L10.498 48.4712H15.2277L14.7504 48.009C14.49 47.7562 12.2077 45.6198 9.67361 43.248C7.14824 40.8849 4.79643 38.6613 4.45797 38.3038C2.86985 36.6383 2.88721 35.3216 4.51004 34.1269C4.93528 33.813 4.88321 33.8304 15.922 29.985C22.0748 27.8399 25.4594 26.6627 28.5488 25.5814C30.2411 24.9884 33.1136 23.9944 34.9274 23.3578C36.7411 22.73 38.5983 22.0847 39.0495 21.919C39.5008 21.7621 40.9501 21.2563 42.2605 20.7942C43.5709 20.3407 46.5649 19.303 48.8994 18.4921C51.2425 17.6724 53.6898 16.8266 54.3493 16.6086C58.4628 15.2396 60.5369 12.8939 61.9168 8.07178C62.0903 7.45266 62.2205 6.93819 62.2031 6.92075C62.1858 6.90331 62.1164 6.92075 62.0469 6.96435Z" />
    <path d="M53.6725 19.0501C50.071 20.2447 41.0803 23.2182 34.9274 25.2412C33.4001 25.747 32.0549 26.1917 31.9334 26.2353C31.7078 26.3138 29.2258 27.1334 19.0462 30.4906C15.8526 31.5457 11.4961 32.9845 9.38731 33.6821C7.26982 34.3797 5.39531 35.0337 5.21307 35.1296C4.78783 35.3738 4.51013 35.801 4.51013 36.2196C4.51013 36.8823 5.05686 37.6409 5.76848 37.981C6.05486 38.1205 6.14164 38.1031 9.73444 37.1788C11.7565 36.6643 14.2819 36.019 15.358 35.7487L17.3106 35.2517L17.3366 34.8506C17.354 34.6238 17.406 34.4058 17.4668 34.3622C17.5536 34.2925 23.0035 32.7927 28.5055 31.3364C29.5209 31.0661 33.1397 30.0895 37.2706 28.982C39.7092 28.3281 43.9615 27.1857 46.7299 26.4446C49.4983 25.7034 51.9976 24.997 52.284 24.8837C53.4122 24.4128 54.7226 23.1659 55.7987 21.544C56.6752 20.236 57.8467 17.7682 57.5951 17.7857C57.5343 17.7857 55.7726 18.3525 53.6725 19.0501Z" />
    <path d="M47.5976 28.4677C44.9247 29.1478 40.0042 30.3948 36.663 31.2406C33.3219 32.0952 29.382 33.098 27.898 33.4729C26.4227 33.8479 22.8646 34.7548 20.0007 35.4785C17.1369 36.211 13.0408 37.2487 10.8973 37.7893C8.75372 38.3386 6.966 38.8182 6.91393 38.8706C6.87053 38.9142 6.86186 38.9926 6.89657 39.045C7.00939 39.2455 9.29177 41.2337 9.39591 41.2337C9.45666 41.2337 10.9667 40.9546 12.7544 40.6233C14.5421 40.2919 17.5101 39.7425 19.3499 39.4025C21.1897 39.0624 22.7778 38.7485 22.8906 38.7049C23.0555 38.6351 23.0815 38.5566 23.0815 38.1904C23.0815 37.7631 23.0815 37.7631 23.4113 37.7108C23.5849 37.6759 24.5308 37.4928 25.5114 37.3097C26.4921 37.1179 29.165 36.6121 31.4561 36.1761C33.7471 35.7401 35.9948 35.3128 36.4461 35.2169C36.8973 35.1297 39.2839 34.6763 41.7398 34.2141C44.1958 33.752 46.5302 33.2724 46.9294 33.1503C48.37 32.7056 49.9408 31.5109 51.0342 30.046C51.5376 29.3658 52.5529 27.4562 52.4835 27.3079C52.4748 27.2643 50.2705 27.7875 47.5976 28.4677Z" />
    <path d="M43.3887 35.8011C41.7919 36.0976 36.4201 37.0916 31.4561 38.0072C26.4921 38.9228 21.398 39.8646 20.1309 40.1C18.8639 40.3355 16.2084 40.8238 14.2297 41.1813C9.9253 41.9748 10.2377 41.905 10.2377 42.0533C10.2377 42.123 10.7758 42.655 11.4353 43.2479C12.0862 43.8321 12.7457 44.4251 12.8933 44.5646L13.1623 44.8175L21.181 43.806C25.5896 43.2566 29.3039 42.777 29.4254 42.7509C29.5989 42.716 29.677 42.6201 29.7725 42.3062L29.894 41.8963L31.8032 41.6086C41.3927 40.1785 41.1671 40.2221 42.1737 39.7076C43.6838 38.9403 45.4628 37.2224 46.1397 35.8883C46.2525 35.6529 46.3393 35.4174 46.322 35.3564C46.3046 35.2866 45.4715 35.4087 43.3887 35.8011Z" />
    <path d="M29.0695 43.8584C28.3753 43.9456 27.4987 44.0502 27.1169 44.0938C26.7351 44.1374 23.6543 44.5036 20.2697 44.9048L14.1255 45.6372L15.5835 47.0499L17.0501 48.4625L38.8239 48.4712L37.7391 47.791C37.149 47.4161 35.4307 46.3348 33.9207 45.3931C30.7705 43.4224 31.2651 43.5793 29.0695 43.8584Z" />
    <path d="M18.265 49.2646C16.2256 49.5262 14.8284 50.3284 14.2036 51.6015C13.9433 52.1422 13.9259 52.2294 13.9259 53.1363C13.9259 53.9908 13.9519 54.1478 14.1602 54.5925C14.8892 56.1621 16.5033 56.9469 19.2196 57.0689C22.2917 57.2085 24.47 56.3801 25.2857 54.7669C25.98 53.3891 25.7023 51.6539 24.6175 50.6249C23.4286 49.4913 20.8512 48.942 18.265 49.2646ZM20.9206 50.7034C21.5281 50.8865 22.0488 51.401 22.2223 52.0114C22.3699 52.5433 22.3785 53.703 22.2223 54.235C22.0835 54.7494 21.6929 55.2203 21.233 55.4645C20.9466 55.6127 20.6689 55.665 19.9746 55.6912C18.5601 55.7522 17.8485 55.447 17.4319 54.6099C17.2497 54.235 17.2236 54.0606 17.2236 53.1363C17.2236 52.1509 17.241 52.0637 17.4666 51.6451C17.753 51.1394 18.0915 50.8516 18.6035 50.686C19.1155 50.529 20.3565 50.5377 20.9206 50.7034Z" />
    <path d="M0.431217 50.4505L1.05605 50.529C1.60278 50.5987 1.69824 50.6336 1.7937 50.8255C1.88049 50.9911 1.90652 51.5928 1.88917 53.2322C1.86313 55.2552 1.84577 55.447 1.69824 55.6127C1.5941 55.726 1.42922 55.7958 1.25565 55.7958C1.09944 55.7958 0.847774 55.822 0.700243 55.8481C0.439895 55.9004 0.431217 55.9179 0.431217 56.3713V56.8422L3.32108 56.8248L6.20226 56.7986V56.3626C6.20226 55.8917 6.11548 55.822 5.46461 55.8045C5.29104 55.7958 5.06541 55.7348 4.96127 55.6563C4.7877 55.5342 4.77035 55.4557 4.77035 54.6971V53.8774H9.19626L9.21362 54.1129C9.24833 54.9064 9.19626 55.4819 9.07477 55.604C8.98798 55.6912 8.72764 55.7609 8.37183 55.7784L7.80774 55.8132V56.8422H13.5441L13.492 55.8394L12.9453 55.7871C12.0774 55.6999 12.1035 55.7784 12.0774 53.3978C12.0427 50.9562 12.0948 50.7034 12.5634 50.5639C12.737 50.5203 13.0321 50.4767 13.2143 50.4767H13.5354V49.4303H7.80774V49.9011C7.80774 50.4331 7.79906 50.4243 8.56275 50.5203C8.81442 50.5551 9.04873 50.6249 9.1008 50.6859C9.14419 50.7383 9.19626 51.1394 9.21362 51.5666L9.23965 52.3514L7.00934 52.3776L4.77035 52.395V51.6277C4.77035 50.6859 4.83977 50.5987 5.63817 50.5028L6.20226 50.4331V49.4739L3.32108 49.4477L0.431217 49.4303V50.4505Z" />
    <path d="M26.3792 50.4505L26.8826 50.5028C27.1603 50.5377 27.4553 50.5813 27.5334 50.6162C27.8025 50.7208 27.8632 51.2963 27.8372 53.4153C27.8025 55.7958 27.8285 55.726 26.9086 55.8307L26.3792 55.8917V56.8422H30.9787V56.3713C30.9787 55.9179 30.97 55.9004 30.7097 55.8481C30.5621 55.822 30.3105 55.7958 30.1543 55.7958C29.9807 55.7958 29.8158 55.726 29.7117 55.6127C29.5641 55.447 29.5468 55.2552 29.5208 53.4327C29.5034 52.334 29.5294 51.4358 29.5641 51.4358C29.6075 51.4358 30.484 52.5782 31.5081 53.9733C32.5321 55.3685 33.4607 56.5893 33.5735 56.6765C33.7644 56.8335 33.9293 56.8422 35.6997 56.8248L37.6089 56.7986L37.6176 53.8251C37.6176 51.4882 37.6436 50.8255 37.7304 50.7121C37.8085 50.6249 38.0602 50.5551 38.4507 50.5115L39.0495 50.4505V49.4303H34.45V50.3807L34.7798 50.4418C35.6303 50.59 35.7084 50.6162 35.8125 50.8255C35.9514 51.0958 35.9774 54.8715 35.8385 54.8192C35.7951 54.8017 34.9533 53.6943 33.9727 52.3514C32.9921 51.0086 32.0722 49.8052 31.9246 49.6657L31.673 49.4303H26.3792V50.4505Z" />
    <path d="M39.3098 50.4592L39.8392 50.4941C40.7157 50.5551 40.6984 50.5028 40.6984 53.1885C40.6984 55.8219 40.7244 55.7347 39.8392 55.8394L39.3098 55.9004V56.8509L43.5448 56.8247C47.2244 56.7899 47.8406 56.7724 48.2918 56.6416C49.9581 56.1533 50.878 55.1767 51.1123 53.6332C51.3553 52.0985 50.661 50.7382 49.2985 50.0493C48.2398 49.5087 48.1009 49.4913 43.5014 49.4564L39.3098 49.4215V50.4592ZM46.8426 51.0434C47.5716 51.3486 47.8406 51.9328 47.8406 53.2234C47.8406 53.8251 47.8059 54.0954 47.667 54.4006C47.2852 55.2551 46.7732 55.447 44.8032 55.447H43.4754V50.7993L44.9767 50.8428C46.131 50.8777 46.5562 50.9213 46.8426 51.0434Z" />
    <path d="M53.065 50.4331L53.7853 50.529C54.4882 50.6162 54.6618 50.7121 54.6705 50.9824C54.6705 51.1568 52.093 55.4644 51.8761 55.6388C51.7806 55.726 51.5723 55.7958 51.4161 55.7958C51.2599 55.7958 51.0082 55.822 50.8607 55.8481C50.6004 55.9004 50.5917 55.9179 50.5917 56.3713V56.8422H55.1912V56.3713C55.1912 55.8656 55.1217 55.7958 54.575 55.7958C54.4448 55.7958 54.2539 55.726 54.1411 55.6301L53.9415 55.4732L54.3928 54.6186L56.6578 54.5925L58.9228 54.575L59.1398 54.9238C59.2613 55.1157 59.3567 55.3249 59.3567 55.3947C59.3567 55.5865 59.0096 55.7958 58.7059 55.7958C58.1244 55.7958 58.055 55.8568 58.055 56.3713V56.8422H64.312L64.2599 55.8394L63.6785 55.7871C63.288 55.7522 63.0363 55.6824 62.9148 55.5778C62.8194 55.4906 61.9342 54.1477 60.9448 52.5956C59.9642 51.0434 59.0617 49.7006 58.9402 49.6047C58.7406 49.439 58.5757 49.4303 55.8681 49.4303H53.0129L53.065 50.4331ZM57.3954 52.1596C57.7599 52.7264 58.055 53.206 58.055 53.2322C58.055 53.2496 57.4302 53.267 56.6665 53.267C55.9028 53.267 55.2779 53.2496 55.2779 53.2234C55.2779 53.0839 56.6231 51.0086 56.6751 51.0609C56.7099 51.1045 57.0396 51.5928 57.3954 52.1596Z" />
  </IconBox>
);
