import React, { memo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import DiscountBadge from 'src/assets/images/discount-badge.svg';
import GiftCard from 'src/assets/images/gift-card.svg';
import Phone from 'src/assets/images/phone.svg';
import Flame from 'src/assets/images/flame.svg';
import CarDiscount from 'src/assets/images/car-discount.svg';
import Umbrella from 'src/assets/images/umbrella-dollar.svg';
import Serviceman from 'src/assets/images/serviceman.svg';
import Insurance from 'src/assets/images/car-with-loop.svg';
import Towing from 'src/assets/images/towing.svg';
import Tyre from 'src/assets/images/tyre.svg';
import { useMediaQuery } from 'react-responsive';

const firstCardBenefits = [
  {
    id: 1,
    icon: <DiscountBadge />,
    name: (
      <>
        Atrakcyjne rabaty
        <br />
        dla pracowników
      </>
    ),
    description: 'na zakup aut prywatnych',
  },
  {
    id: 2,
    icon: <GiftCard />,
    name: 'Comiesięczny bonus',
    description: 'w programie Mobility 3.0',
  },
  {
    id: 3,
    icon: <Phone />,
    name: (
      <>
        Wsparcie Doradcy
        <br />w uzyskaniu dotacji
      </>
    ),
    description: 'w programie “Mój Elektryk”',
  },
  {
    id: 4,
    icon: <Flame />,
    name: 'Oferty specjalne',
    description: 'Auto miesiąca',
  },
  {
    id: 5,
    icon: <CarDiscount />,
    name: (
      <>
        Dostęp do aut
        <br />
        przedkontraktowych
      </>
    ),
    description: 'w oczekiwaniu na samochód',
  },
];

const secondCardBenefits = [
  {
    id: 1,
    icon: <Umbrella />,
    name: '0 zł wpłaty własnej',
    description: '',
  },
  {
    id: 2,
    icon: <Serviceman />,
    name: 'Pakiet Serwisowy',
    description: '',
  },
  {
    id: 3,
    icon: <Insurance />,
    name: (
      <>
        Pełne ubezpieczenie
        <br />
        OC /AC / NNW
      </>
    ),
    description: '',
  },
  {
    id: 4,
    icon: <Towing />,
    name: 'Assistance',
    description: 'w razie awarii plus zastępcze auto',
  },
  {
    id: 5,
    icon: <Tyre />,
    name: 'Pakiet opon',
    description: 'na każdy sezon i pogodę',
  },
];

const Modal = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
`;

const Header = styled.div`
  grid-area: header;
  padding-bottom: 2.5rem;
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyCondensed};
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 1px;

  ${media.w.min.px768(css`
    font-size: 1.875rem;
    line-height: 2.34375rem;
  `)}
`;

const Content = styled.div`
  display: flex;
  grid-area: content;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 67.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${media.w.min.px576(css`
    padding: 0 2.5rem;
  `)}

  ${media.w.min.px768(css`
    flex-direction: row;
  `)}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  width: 100%;
`;

const BenefitsWrapper = styled.div`
  position: relative;
  z-index: 0;
  background: ${colors.background};

  &:before {
    content: '';
    position: absolute;
    background: linear-gradient(
      330.75deg,
      rgba(0, 127, 255, 0) 31.8%,
      rgba(0, 127, 255, 0.3) 100%
    );
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const Benefits = styled.div`
  position: relative;
  padding: 1.5rem 2.6875rem;

  ${media.w.min.px768(css`
    padding: 1.5rem 4.6875rem;
  `)}
`;

const BenefitRowTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2.09375rem;
  color: ${colors.white};

  &:after {
    content: '';
    background-color: ${colors.primary};
    display: block;
    height: 1px;
    width: 11.38845rem;
    margin: 1.25rem auto 2.5rem;
  }

  ${media.w.min.px768(css`
    font-size: 1.25rem;
  `)}
`;

const Benefit = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 2.5rem;
`;

const Icon = styled.div`
  svg {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

const BenefitDescription = styled.div`
  color: ${colors.lightGray};
  font-size: 0.75rem;
  margin-left: 1.875rem;
`;

const BenefitName = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  margin: 0.3125rem 0;
`;

export interface CarPlatformBusinessModalProps {
  onClose: () => void;
}

export const CarPlatformBusinessBenefitsModal = memo<CarPlatformBusinessModalProps>(
  ({ onClose }) => {
    const scrollRef = useRef<OverlayScrollbarsComponent>(null);
    const isMobile = useMediaQuery({
      maxWidth: below(widthBreakpoints.px768),
    });

    useKeyPress(KEY_ESCAPE, CODE_ESCAPE, onClose);

    return (
      <Modal>
        <Header>
          {isMobile ? (
            <>
              Twoje bonusy <br /> w Car Platform Business
            </>
          ) : (
            <>Twoje bonusy w Car Platform Business:</>
          )}
        </Header>
        <Content>
          <StyledScrollContainer ref={scrollRef}>
            <Container>
              <BenefitsWrapper>
                <Benefits>
                  <BenefitRowTitle>Pełen dostęp do platformy</BenefitRowTitle>
                  {firstCardBenefits.map((benefit) => (
                    <Benefit key={benefit.id}>
                      <Icon>{benefit.icon}</Icon>
                      <BenefitDescription>
                        <BenefitName>{benefit.name}</BenefitName>
                        {benefit.description}
                      </BenefitDescription>
                    </Benefit>
                  ))}
                </Benefits>
              </BenefitsWrapper>
              <BenefitsWrapper>
                <Benefits>
                  <BenefitRowTitle>
                    Miesięczna rata ze wszystkim
                  </BenefitRowTitle>
                  {secondCardBenefits.map((benefit) => (
                    <Benefit key={benefit.id}>
                      <Icon>{benefit.icon}</Icon>
                      <BenefitDescription>
                        <BenefitName>{benefit.name}</BenefitName>
                        {benefit.description}
                      </BenefitDescription>
                    </Benefit>
                  ))}
                </Benefits>
              </BenefitsWrapper>
            </Container>
          </StyledScrollContainer>
        </Content>
      </Modal>
    );
  }
);
