import { createSelector } from 'reselect';
import { ProductPageState } from 'src/app/product/state/index';
import {
  ProductMapState,
  ProductsState,
} from 'src/app/product/state/products/productsReducer';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { getOrElse, map, toUndefined } from 'fp-ts/lib/Option';
import { Product } from 'src/app/common/models/product';
import { none } from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { FinancingType, Offer, PackageType } from 'src/common/models/offer';

const financingTypesOrder = [
  FinancingType.Rent,
  FinancingType.Leasing,
  FinancingType.Loan,
];

const packageTypesOrder = [PackageType.Basic, PackageType.Premium];

export const productsStateSelector = (state: ProductPageState) =>
  state.products;

export const productIdSelector = createSelector(
  productsStateSelector,
  (state: ProductsState) => state.productId
);

export const productMapSelector = createSelector(
  productsStateSelector,
  (state: ProductsState) => state.productMap
);

export const activeProductSelector = createSelector(
  productMapSelector,
  productIdSelector,
  (productMap: ProductMapState, productId) =>
    productId ? productMap[productId] : undefined
);

export const availableOffersSelector = createSelector(
  activeProductSelector,
  (product) =>
    pipe(
      map((a: Product) => {
        return a.offers;
      })(product ?? none),
      getOrElse(() => [] as Offer[])
    )
);

export const customerTypeOffersSelector = createSelector(
  activeProductSelector,
  customerTypeSelector,
  (product, customerType) =>
    pipe(
      map((a: Product) => {
        return customerType
          ? a.offers.filter((offer) => offer.customerType === customerType)
          : a.offers;
      })(product ?? none),
      getOrElse(() => [] as Offer[])
    )
);

export const financingTypesListSelector = createSelector(
  availableOffersSelector,
  customerTypeSelector,
  (availableOffers, customerType) =>
    financingTypesOrder.filter(
      (a) =>
        availableOffers.filter(
          (b) => b.customerType === customerType && b.financingType === a
        ).length > 0
    )
);

export const packageTypesListSelector = createSelector(
  availableOffersSelector,
  (availableOffers) =>
    packageTypesOrder.filter(
      (a) => availableOffers.filter((b) => b.packageType === a).length > 0
    )
);

export const productTypeSelector = createSelector(
  activeProductSelector,
  (product) => pipe(map((a: Product) => a.type)(product ?? none), toUndefined)
);

export const activeOfferSelector = createSelector(
  productsStateSelector,
  (state: ProductsState) => state.offer
);

export const createProductSelector = () =>
  createSelector(
    [productMapSelector, (_: ProductPageState, productId: string) => productId],
    (productMap: ProductMapState, productId: string) => productMap[productId]
  );
