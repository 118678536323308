export interface Image {
  original: string;
  thumbnail?: string;
  small?: string;
  medium?: string;
  large?: string;
}

export const getResponsive = (
  version: keyof Image,
  image?: Image
): string | undefined =>
  image != null ? image[version] ?? image.original : undefined;
