import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { fade } from '@material-ui/core';
import { media } from 'src/config/breakpoints';

export const HorizontalToggleGroupContainer = styled.div`
  border: 0.0625rem solid ${fade(colors.white, 0.2)};
  border-radius: 0.125rem;
  padding: 0.125rem;
  display: inline-flex;

  & > * :not(:last-of-type) {
    position: relative;
    margin-right: 0.5625rem;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(7px, -50%);
      height: 50%;
      right: 0;
      width: 1px;
      background-color: ${colors.white};
      opacity: 0.3;
    }

    &:disabled:after {
      opacity: 1; //disabled toggle button applies opacity: 0.3
    }
  }
`;
