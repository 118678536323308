import { localFormatNumber } from 'src/lib/localFormatNumber';
import { IconCode } from 'src/common/components/Icon';
import { EngineType, Product } from 'src/app/common/models/product';
import {
  driveIconMapper,
  driveLabelMapper,
  engineTypeFuelIconMapper,
  engineTypeIconMapper,
  engineTypeLabelMapper,
  engineTypeUnitMapper,
  engineTypeValueMapper,
  gearboxTypeLabelMapper,
} from 'src/app/product/services/productMappers';

export const prepareDimensions = ({
  carHeight,
  carLength,
  carWidth,
}: Product) => {
  if (!carHeight && !carLength && !carWidth) {
    return;
  }

  const widthHeightValues = [];
  const lenghtValues = [];

  if (carHeight) {
    widthHeightValues.push({
      label: 'wysokość',
      unit: 'm',
      value: localFormatNumber(carHeight),
    });
  }

  if (carLength) {
    lenghtValues.push({
      label: 'długość',
      unit: 'm',
      value: localFormatNumber(carLength),
    });
  }

  if (carWidth) {
    widthHeightValues.push({
      label: 'szerokość',
      unit: 'm',
      value: localFormatNumber(carWidth),
    });
  }

  return {
    items: [
      {
        background: false,
        icon: {
          code: IconCode.CarWidthHeight,
          size: 60,
        },
        values: widthHeightValues,
      },
      {
        background: false,
        icon: {
          code: IconCode.CarLength,
          size: 60,
        },
        values: lenghtValues,
      },
    ],
    titles: ['wymiary'],
    displayDirection: 'vertical',
  };
};

export const prepareDrive = ({ drive, gearbox, gearboxType }: Product) => {
  const items = [];
  const titles = [];

  if (drive) {
    items.push({
      background: true,
      icon: {
        code: driveIconMapper(drive),
        size: 30,
      },
      values: [{ label: '', unit: '', value: driveLabelMapper(drive) }],
    });

    titles.push('napęd');
  }

  items.push({
    background: false,
    icon: {
      code: IconCode.Gearbox,
      size: 38,
    },
    values: [
      {
        label: `skrzynia ${gearboxTypeLabelMapper(gearboxType).toLowerCase()}`,
        unit: '',
        value: gearbox || '',
      },
    ],
  });

  titles.push('skrzynia');

  return { items, titles };
};

export const prepareEngine = ({
  batteryCapacity,
  capacity,
  engineType,
  power,
}: Product) => {
  const items = [];

  if (power) {
    items.push({
      background: true,
      icon: {
        code: engineTypeIconMapper(engineType),
        size: 30,
      },
      values: [
        {
          label: 'moc<br />silnika',
          unit: 'KM',
          value: localFormatNumber(power),
        },
      ],
    });
  }

  items.push({
    background: false,
    icon: {
      code: engineTypeFuelIconMapper(engineType),
      size: 30,
    },
    values: [
      {
        label: engineTypeLabelMapper(engineType).toLowerCase(),
        unit: engineTypeUnitMapper(engineType),
        value: localFormatNumber(
          engineTypeValueMapper(engineType, capacity, batteryCapacity)
        ),
      },
    ],
  });

  return {
    items,
    titles: ['silnik'],
  };
};

export const prepareFuelUsage = ({
  engineType,
  emission,
  fuelConsumption,
  range,
}: Product) => {
  const items = [];

  if (engineType === EngineType.Electric) {
    range &&
      items.push({
        background: !!emission,
        icon: {
          code: IconCode.Range,
          size: 32,
        },
        values: [
          {
            label: 'zasięg',
            unit: 'km',
            value: localFormatNumber(range),
          },
        ],
      });
  } else {
    items.push({
      background: !!emission,
      icon: {
        code: IconCode.Pump,
        size: 26,
      },
      values: [
        {
          label: 'zużycie średnie',
          unit: 'l/100km',
          value: localFormatNumber(fuelConsumption),
        },
      ],
    });
  }

  if (emission) {
    items.push({
      background: false,
      icon: {
        code: IconCode.ExhaustEmission,
        size: 30,
      },
      values: [
        {
          label: 'emisja CO2',
          unit: 'g/km',
          value: localFormatNumber(emission),
        },
      ],
    });
  }

  if (items.length > 0) {
    return {
      items,
      titles: ['spalanie'],
    };
  }
};

export const prepareInterior = ({ cabinSpace, trunkSpace }: Product) => {
  const items = [
    {
      background: !!trunkSpace,
      icon: {
        code: IconCode.CarWithSeats,
        size: 64,
      },
      values: [
        {
          label: 'ilość miejsca<br />na nogi',
          unit: '',
          value: cabinSpace.toUpperCase(),
        },
      ],
    },
  ];

  if (trunkSpace) {
    items.push({
      background: false,
      icon: {
        code: IconCode.Suitcase,
        size: 28,
      },
      values: [
        {
          label: 'pojemność bagażnika',
          unit: 'l',
          value: localFormatNumber(trunkSpace),
        },
      ],
    });
  }

  return {
    items,
    titles: ['wnętrze i&nbsp;bagażnik'],
  };
};

export const preparePerformance = ({ maxSpeed, to100Time }: Product) => {
  if (!to100Time && !maxSpeed) {
    return;
  }

  const items = [];

  if (to100Time) {
    items.push({
      background: !!maxSpeed,
      icon: {
        code: IconCode.Rabbit,
        size: 37,
      },
      values: [
        {
          label: '0-100km/h',
          unit: 's',
          value: localFormatNumber(to100Time),
        },
      ],
    });
  }

  if (maxSpeed) {
    items.push({
      background: false,
      icon: {
        code: IconCode.Speedometer,
        size: 27,
      },
      values: [
        {
          label: 'prędkość maksymalna',
          unit: 'km/h',
          value: localFormatNumber(maxSpeed),
        },
      ],
    });
  }

  return {
    items,
    titles: ['osiągi'],
  };
};
