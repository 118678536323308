import { createSelector } from 'reselect';
import { SearchState } from 'src/app/search/state/index';

export const searchUsedProductsStateSelector = (state: SearchState) =>
  state.searchUsedProducts;

export const searchUsedProductsResultsSelector = createSelector(
  searchUsedProductsStateSelector,
  (state) => state.products
);

export const searchUsedProductsResultsTotalSelector = createSelector(
  searchUsedProductsStateSelector,
  (state) => state.total
);
