import React, { useEffect, useRef, useState } from 'react';
import { Box, Popper, Skeleton, Typography, styled } from '@mui/material';
import { Brand } from 'src/app/common/models/product';
import { rem } from 'polished';
import { OptionalFold } from 'src/lib/OptionalFold';
import { bodyIconMapper } from 'src/app/product/services/productMappers';
import { useSelector } from 'react-redux';
import { bodiesListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import {
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { Icon } from 'src/common/components/Icon';
import { OptionFilter } from 'src/app/common/models/filter';
import {
  StyledAutocomplete,
  StyledTextField,
} from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBrandAutocomplete/styles';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { BodyIconsSection } from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchBodyAutocomplete/body-icons-section';
import { widthBreakpoints } from 'src/config/breakpoints';
import { useMediaQuery } from 'react-responsive';
import { isMotorcycles } from 'src/app/search/utils/is-motorcycles';

export const SearchBodyAutocomplete = () => {
  const { state: bodyState, setValue: setBodyValue } = useOptionFilterControl(
    OptionFilter.Body
  );
  const bodiesList = useSelector(bodiesListSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery({ maxWidth: widthBreakpoints.px992 });

  if (!bodiesList) {
    return <Skeleton style={{ height: rem(47) }} />;
  }

  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
      backgroundColor: '#52555a',
    },
  });

  const sortedBodiesList = [...bodiesList].sort((a, b) => {
    return a.id.localeCompare(b.id);
  });
  function filterOptions(options: any, { inputValue }: { inputValue: string }) {
    return options.filter((option: any) =>
      option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }

  const [selectedValues, setSelectedValues] = useState<any>([]);

  useEffect(() => {
    const defaultValues = sortedBodiesList.filter((option: any) =>
      bodyState.value.includes(option.id)
    );
    setSelectedValues(defaultValues);
  }, [bodyState]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ...(isMotorcycles() && { visibility: 'hidden' }),
      }}
    >
      <StyledAutocomplete
        multiple
        size={isMobile ? 'medium' : 'small'}
        filterOptions={filterOptions}
        noOptionsText="Brak wyników"
        options={sortedBodiesList}
        value={selectedValues}
        onChange={(_, newValue: any) => {
          const ids = newValue.map((v: any) => v.id);
          setBodyValue(ids);
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        {...(!isMobile && { renderTags: () => null })}
        getOptionLabel={(brand) => {
          const typedBrand = brand as Brand & { models: BrandModel[] };
          return typedBrand.label;
        }}
        renderOption={(props, option: any) => {
          return (
            <li
              {...props}
              style={{
                color: 'white',
              }}
              id="autocomplete-element"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
                id="autocomplete-element"
              >
                <Box
                  sx={{
                    width: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  id="autocomplete-element"
                >
                  <OptionalFold
                    key={option.id}
                    value={bodyIconMapper(option.id)}
                    onSome={(code) => (
                      <FilterBoxToggleButtonIcon>
                        <Icon code={code} width={50} />
                      </FilterBoxToggleButtonIcon>
                    )}
                    onNone={
                      <FilterBoxToggleButtonTitle id="autocomplete-element">
                        {option.label}
                      </FilterBoxToggleButtonTitle>
                    }
                  />
                </Box>
                <Typography id="autocomplete-element">
                  {option.label}
                </Typography>
              </Box>
            </li>
          );
        }}
        PopperComponent={StyledPopper}
        renderInput={(params) => (
          <StyledTextField {...params} label="NADWOZIE" inputRef={inputRef} />
        )}
      />

      {!isMobile && !isMotorcycles() && (
        <BodyIconsSection style={{ gridArea: 'body' }} />
      )}
    </Box>
  );
};
