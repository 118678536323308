import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import React from 'react';
import { ToggleOffTagButton } from 'src/app/search/components/RequestProductSection/ToggleOffTagButton';

export const SearchBrandFilterTags = () => {
  const { state, setValue } = useOptionFilterControl(OptionFilter.Brand);
  const items = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: a.label,
    }))
  );

  return (
    <ToggleButtonGroup
      items={items ?? []}
      value={state.value}
      onChange={setValue}
      toggleButtonComponent={ToggleOffTagButton}
    />
  );
};
