import { createRootRoute, createRoute } from '@summer/react-kit';

/*
 * For some reason using empty createRootRoute causes gatsby to throw warning form its Link component:
 * "External link  was detected in a Link component. Use the Link component only for internal links. See: https://gatsby.dev/internal-links ..."
 */
export const LandingRoute = '/';
export const LoginRoute = createRootRoute('login');
export const RegisterRoute = createRootRoute('register');
export const RegisterSuccessRoute = createRootRoute('register-success');
export const RegisterStep2SuccessRoute = createRootRoute(
  'register-step2-success'
);
export const ForgotPasswordRoute = createRootRoute('forgot-password');
export const AboutUsRoute = createRootRoute('about-us');
export const HowItWorksRoute = createRootRoute('how-it-works');
export const FaqRoute = createRootRoute('faq');
export const ContactRoute = createRootRoute('contact');
export const ContactThankYouRoute = createRoute(ContactRoute, 'thank-you');
export const TosRoute = createRootRoute('tos');
export const PrivacyPolicyAndCookiesRoute = createRootRoute(
  'privacy-policy-and-cookies'
);
export const CompanyConfirmationRoute = createRootRoute('company-confirmation');
export const ChangeCompanyRoute = createRootRoute('change-company');
export const CarPlatformBusinessRoute = createRootRoute(
  'car-platform-business'
);
export const FuelSpecialOfferRoute = createRootRoute('fuel-special-offer');
