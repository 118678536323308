import React, { FC, useEffect, useRef, useState } from 'react';
import { OptionFilter, RangeFilter } from 'src/app/common/models/filter';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';
import { ConnectedCommonOptionFilterTags } from 'src/app/search/components/RequestProductSection/ConnectedCommonOptionFilterTags';
import { Button } from 'src/common/components/Button';
import { useResizeObserver } from 'src/app/common/hooks/useResizeObserver';
import { useMediaQuery } from 'react-responsive';
import { below, widthBreakpoints } from 'src/config/breakpoints';
import { StyleProps } from '@summer/react-kit';
import { ConnectedCommonRangeFilterTags } from 'src/app/search/components/RequestProductSection/ConnectedCommonRangeFilterTags';
import { SearchEquipmentFilterTags } from 'src/app/search/components/RequestProductSection/SearchEquipmentFilterTags';
import { SearchModelFilterTags } from 'src/app/search/components/RequestProductSection/SearchModelFilterTags';
import { SearchBodyFilterTags } from 'src/app/search/components/RequestProductSection/SearchBodyFilterTags';
import { SearchBrandFilterTags } from 'src/app/search/components/RequestProductSection/SearchBrandFilterTags';
import { SearchCategoryFilterTags } from 'src/app/search/components/RequestProductSection/SearchCategoryFilterTags';

const FilterTagsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 0.375rem 0.375rem 0;
  }
`;

const CollapseButtonContainer = styled.div`
  height: 1rem;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.25;
`;

const rowHeight = 39; // 33 button + 6 flex grid gap

export const RequestProductSectionFormSearchFilterTags: FC<StyleProps> = ({
  className,
  style,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [collapsedHeight, setCollapsedHeight] = useState(0);

  const isMobile = useMediaQuery({
    maxWidth: below(widthBreakpoints.px768),
  });
  const isMobileRef = useRef(isMobile);

  useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile]);

  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useResizeObserver(contentRef, (entries) => {
    if (entries?.[0].contentRect) {
      const contentHeight = entries?.[0].contentRect.height;
      const newCollapsedHeight =
        isMobileRef.current && contentHeight >= 2 * (rowHeight - 1)
          ? 2 * rowHeight // mobile with more than 2 rows
          : contentHeight > 0
          ? rowHeight // 1 row mobile or desktop
          : 0;

      setCollapsedHeight(newCollapsedHeight);
      setIsExpandable(contentHeight > newCollapsedHeight);
    }
  });

  return (
    <div className={className} style={style}>
      {collapsedHeight === 0 && 'Brak wybranych potrzeb'}
      <Collapse collapsedHeight={`${collapsedHeight}px`} in={isExpanded}>
        <FilterTagsGrid ref={contentRef}>
          <SearchBrandFilterTags />
          <SearchModelFilterTags />
          <SearchCategoryFilterTags />
          <SearchBodyFilterTags />

          <ConnectedCommonOptionFilterTags kind={OptionFilter.Engine} />
          <ConnectedCommonOptionFilterTags kind={OptionFilter.Gearbox} />
          <ConnectedCommonOptionFilterTags kind={OptionFilter.FinancingType} />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.Power} />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.Capacity} />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.FuelConsumption} />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.Emission} />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.Range} />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.Price} />

          <ConnectedCommonOptionFilterTags
            kind={OptionFilter.PaintColorGroup}
          />
          <ConnectedCommonRangeFilterTags kind={RangeFilter.RimSize} />

          <ConnectedCommonOptionFilterTags kind={OptionFilter.CabinSpace} />
          <ConnectedCommonOptionFilterTags kind={OptionFilter.UpholsteryType} />
          <ConnectedCommonOptionFilterTags
            kind={OptionFilter.UpholsteryColorGroup}
          />

          <ConnectedCommonOptionFilterTags
            kind={OptionFilter.EquipmentPackage}
          />
          <SearchEquipmentFilterTags />
        </FilterTagsGrid>
      </Collapse>
      <CollapseButtonContainer>
        {isExpandable && (
          <Button
            kind="link"
            variant="transparent"
            onClick={() => setIsExpanded((x) => !x)}
          >
            {isExpanded ? 'Zwiń parametry' : 'Pokaż wszystkie parametry'}
          </Button>
        )}
      </CollapseButtonContainer>
    </div>
  );
};
