import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { Offer } from 'src/common/models/offer';
import { useEffect, useMemo } from 'react';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { navigate } from 'gatsby';
import {
  createOptionsList,
  findOfferWithParams,
} from 'src/app/product/services/helpers';
import { productsActions } from 'src/app/product/state/products/productsActions';

export const useLeasingCalculatorConfigurations = (
  activeOffer: Offer,
  leasingOffers: Offer[]
) => {
  const location = useLocation();
  const reduxDispatch = useDispatch();
  const userCustomerType = useSelector(customerTypeSelector);

  const leasingBuyoutPriceList = useMemo(
    () =>
      createOptionsList('buyoutPrice', {
        contractPeriod: activeOffer.contractPeriod,
        initialPayment: activeOffer.initialPayment,
      })(leasingOffers),
    [activeOffer, leasingOffers]
  );

  const leasingInitialPaymentsList = useMemo(
    () =>
      createOptionsList('initialPayment', {
        financingType: activeOffer.financingType,
      })(leasingOffers),
    [activeOffer, leasingOffers]
  );

  const leasingContractPeriodsList = useMemo(
    () =>
      createOptionsList('contractPeriod', {
        financingType: activeOffer.financingType,
      })(leasingOffers),
    [activeOffer, leasingOffers]
  );

  useEffect(() => {
    if (activeOffer == null) {
      return;
    }

    const params = new URLSearchParams(location.search);
    params.set('offerId', activeOffer.id);
    params.set(
      'offerDetails',
      btoa(
        JSON.stringify({
          buyoutPrice: Number(activeOffer?.external?.details.purchase),
          contractPeriod: Number(activeOffer?.external?.details.month),
          customerType: activeOffer.customerType,
          financingType: activeOffer?.financingType,
          initialPayment: Number(activeOffer?.external?.details.down),
          price: activeOffer.external?.priceB2C,
          priceNetto: activeOffer.external?.priceB2B,
        })
      )
    );
    navigate(`${location.pathname}?${params}`, {
      replace: true,
    });
  }, [activeOffer, userCustomerType]);

  return {
    leasingInitialPaymentsList,
    setLeasingInitialPayment: (initialPayment: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              financingType: activeOffer?.financingType,
            },
            {
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment,
            }
          )(leasingOffers, activeOffer)
        )
      ),
    leasingContractPeriodsList,
    setLeasingContractPeriod: (contractPeriod: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              financingType: activeOffer?.financingType,
            },
            {
              buyoutPrice: activeOffer?.buyoutPrice,
              contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(leasingOffers, activeOffer)
        )
      ),
    leasingBuyoutPriceList,
    setLeasingBuyoutPrice: (buyoutPrice: number) =>
      reduxDispatch(
        productsActions.setActiveOffer(
          findOfferWithParams(
            {
              financingType: activeOffer?.financingType,
            },
            {
              buyoutPrice,
              contractPeriod: activeOffer?.contractPeriod,
              initialPayment: activeOffer?.initialPayment,
            }
          )(leasingOffers, activeOffer)
        )
      ),
  };
};
