import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { typography } from 'src/config/typography';

const Container = styled.div`
  color: ${colors.white};
  font-family: ${typography.fontFamilyCondensed};
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.25;
  opacity: 0.6;
`;

const Value = styled.span`
  font-weight: bold;
`;

export interface ProductPageSummaryListPriceProps {
  price: number;
}

export const ProductPageSummaryListPrice: FC<ProductPageSummaryListPriceProps> = ({
  price,
}) => (
  <Container>
    cena katalogowa:{' '}
    <Value>
      {localFormatNumber(price, { maximumFractionDigits: 0 })}
      &nbsp;zł&nbsp;brutto
    </Value>
  </Container>
);
