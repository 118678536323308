import { createReducer } from '@reduxjs/toolkit';
import { authActions } from 'src/state/auth/authActions';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { PartialProduct } from 'src/app/common/models/product';

export interface SearchProductsState {
  products: PartialProduct[];
  total: number;
}

const initialState: SearchProductsState = {
  products: [],
  total: 0,
};

export const searchProductsReducer = createReducer<SearchProductsState>(
  initialState,
  (builder) =>
    builder
      .addCase(findProductsRequestActions.requestSuccess, (state, action) => {
        state.products = action.payload.products;
        state.total = action.payload.total;
      })
      .addCase(authActions.logout, () => initialState)
);
