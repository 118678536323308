import React, { FC } from 'react';
import styled from 'styled-components';
import { ProductPrice } from 'src/app/product/components/ProductPrice';
import { StyleProps } from '@summer/react-kit';
import { Title } from 'src/common/components/typography/Title';
import { Text } from 'src/common/components/typography/Text';
import { OfferConfigurationList } from 'src/app/product/components/OfferConfigurationList';
import { Separator } from 'src/common/components/Separator';
import {
  activeProductSelector,
  activeOfferSelector,
} from 'src/app/product/state/products/productsSelectors';
import { useSelector } from 'react-redux';
import { BadgesBinder } from 'src/app/common/components/BadgesBinder';
import { isNone } from 'fp-ts/lib/Option';
import { getResponsive } from 'src/common/models/image';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Image = styled.div`
  margin: 0.5rem -1.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledSeparator = styled(Separator)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 1.25rem;
`;

export const OrderPageSummary: FC<StyleProps> = (props) => {
  const product = useSelector(activeProductSelector);
  const offer = useSelector(activeOfferSelector);

  if (product == null || isNone(product) || offer == null) {
    return <></>;
  }

  return (
    <Container {...props}>
      <Header>
        <Title level={2} style={{ margin: 0 }}>
          {product.value.label}
        </Title>
        <BadgesBinder
          product={product.value}
          offer={offer}
          elements={1}
          variant="small"
        />
      </Header>
      <Text size="small" variant="condensed" style={{ fontWeight: 700 }}>
        {product.value.version}
      </Text>
      <Image>
        {product.value.mainPhoto && (
          <img
            src={getResponsive('small', product.value.mainPhoto)}
            alt="Twój nowy samochód"
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </Image>

      <ProductPrice offer={offer} />
      <StyledSeparator />
      <OfferConfigurationList offer={offer} />
    </Container>
  );
};
