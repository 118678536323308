import { createReducer } from '@reduxjs/toolkit';
import { navigationActions } from 'src/state/navigation/navigationActions';

export interface NavigationState {
  isOpen: boolean;
}

const initialState: NavigationState = {
  isOpen: false,
};

export const navigationReducer = createReducer<NavigationState>(
  initialState,
  (builder) =>
    builder
      .addCase(navigationActions.open, (state) => {
        state.isOpen = true;
      })
      .addCase(navigationActions.close, (state) => {
        state.isOpen = false;
      })
);
