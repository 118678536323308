import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import {
  acceleratedEasing,
  deceleratedEasing,
  slideInTop,
  slideOutDown,
} from 'src/common/styles/animations';
import { homeAdvertAnimation } from 'src/app/home/components/HomeAdvert/homeAdvertAnimation';
import { navigate } from 'gatsby';
import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';
import { HomeAdvertSlideStep } from 'src/app/home/components/HomeAdvert/utils';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerType } from 'src/common/models/customerType';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { useMediaQuery } from 'react-responsive';
import { settingsActions } from 'src/state/settings/settingsActions';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';

const Container = styled.div`
  cursor: pointer;

  grid-area: slide-content;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  color: #fff;
  position: relative;
  opacity: 0;
  align-self: flex-end;
  z-index: 3;

  padding-left: 0.9375rem;
  margin-left: -0.9375rem;
  padding-right: 0.9375rem;
  margin-right: -0.9375rem;

  &.animate-in {
    animation: ${slideInTop} ${homeAdvertAnimation.contentIn.duration}ms
      ${deceleratedEasing} forwards;
  }

  &.animate-out {
    opacity: 1;
    animation: ${slideOutDown} ${homeAdvertAnimation.contentOut.duration}ms
      ${acceleratedEasing} forwards;
  }

  &.static {
    opacity: 1;
  }

  &.hidden {
    display: none;
  }

  &:not(.static) {
    pointer-events: none;
  }

  margin-bottom: 1rem;

  ${media.w.min.px768(css`
    justify-self: flex-end;
  `)};

  ${media.w.min.px1366(css`
    padding-bottom: 0;
    align-self: center;
    margin-right: calc(5% - 0.9375rem);
    margin-top: 10%;
  `)};
`;

const Content = styled.div`
  ${media.w.min.px768(css`
    width: 17rem;
    margin-left: auto;
  `)};
`;

const Text = styled.div`
  font-size: 2rem;
  line-height: 1.5625rem;
`;

const PriceText = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;

const Price = styled.span`
  font-size: 2.5rem;
  margin-top: 0.5rem;
  line-height: 1.25rem;
`;

export interface HomeAdvertSlideHeroProps {
  slide: HomeAdvertSlideType;
  step: HomeAdvertSlideStep;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const HomeAdvertSlideHero: FC<HomeAdvertSlideHeroProps> = ({
  slide,
  className,
  onMouseEnter,
  onMouseLeave,
  children,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px768,
  });
  const customerType = useSelector(customerTypeSelector);
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate(slide.link!).then(() =>
      dispatch(settingsActions.setProductsPreference('new'))
    );
  };

  return (
    <Container
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    >
      <Content>
        <Text>{children}</Text>
        {slide.price != null && (
          <PriceText>
            Już za <br />
            <Price>
              {Intl.NumberFormat(undefined, {
                maximumFractionDigits: 0,
              }).format(
                customerType === CustomerType.B2B
                  ? slide.price.net
                  : slide.price.gross
              )}
            </Price>{' '}
            zł/msc
            {!isDesktop && <ArrowRight size={26} />}
          </PriceText>
        )}
        {!isDesktop && !slide.price && <ArrowRight size={26} />}
      </Content>
      {isDesktop && slide.link != null && <ArrowRight size={26} />}
    </Container>
  );
};
