import React, {
  ComponentType,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useDerivedState } from '@summer/react-kit/hooks';
import {
  ToggleButton,
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { ID, idToggleEnhancer } from '@summer/react-kit';

export interface ToggleButtonGroupManyItemOptions {
  id: string;
  label: ReactNode;
  textLabel?: string;
}

export interface ToggleButtonGroupManyProps {
  limit?: number;
  items: ToggleButtonGroupManyItemOptions[];
  initialValue?: string[];
  value?: string[];
  onChange?: (v: string[]) => void;
  disabled?: boolean;
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
  variant?: ToggleButtonVariant;
}

export const ToggleButtonGroupMany = memo<ToggleButtonGroupManyProps>(
  ({
    variant = 'primary',
    limit,
    items,
    initialValue,
    value,
    onChange,
    toggleButtonComponent = ToggleButton,
    disabled,
  }) => {
    const [active, setActive] = useDerivedState(initialValue ?? [], {
      value,
      onChange,
    });

    const onChangeActive = useCallback(
      (value: boolean, id: ID) => {
        setActive(() => {
          return [id as string];
        });
      },
      [limit, setActive]
    );

    const Component = useMemo(
      () =>
        idToggleEnhancer<boolean, PropsWithChildren<ToggleButtonProps>>(
          toggleButtonComponent
        ),
      [toggleButtonComponent]
    );

    return (
      <>
        {items.map((item) => (
          <Component
            id={item.id}
            key={item.id}
            variant={variant}
            value={!!active.find((_) => _ === item.id)}
            onChange={onChangeActive}
            disabled={disabled}
          >
            {item.label}
          </Component>
        ))}
      </>
    );
  }
);
