import styled from 'styled-components';
import { TransparentToggleButton } from 'src/common/components/ToggleButton';
import { ellipsis } from 'polished';
import { typography } from 'src/config/typography';

export const FilterBoxToggleButton = styled(TransparentToggleButton)`
  width: 4.375rem;
  height: 4.375rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const FilterBoxToggleButtonIcon = styled.span`
  min-height: 1.875rem;
  display: flex;
  align-items: flex-end;
`;

export const FilterBoxToggleButtonTitle = styled.span`
  display: block;
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.75rem;
  max-width: 100%;
  ${ellipsis('100%')};

  ${FilterBoxToggleButtonIcon} + & {
    margin-top: 0.5rem;
    min-height: 0.875rem;
  }
`;
