import { AppState } from 'src/app/state';
import { createSelector } from 'reselect';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';

export const commonFiltersStateSelector = (state: AppState) =>
  state.commonFilters;

export const brandsListSelector = createSelector(
  commonFiltersStateSelector,
  (state) => state.brands
);

export const modelsLookupSelector = createSelector(
  commonFiltersStateSelector,
  (state) =>
    state.brands?.reduce((acc, brand) => {
      brand.models.forEach((model) => (acc[model.id] = model));
      return acc;
    }, {} as { [id: string]: BrandModel })
);

export const createBrandSelector = () =>
  createSelector(
    commonFiltersStateSelector,
    (_: AppState, id: string) => id,
    (state, id) => state.brands?.find((_) => _.id === id)
  );

export const bodiesListSelector = createSelector(
  commonFiltersStateSelector,
  (state) => state.bodies
);

export const categoriesListSelector = createSelector(
  commonFiltersStateSelector,
  (state) => state.categories
);
