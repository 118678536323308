import React, { forwardRef, lazy, memo, Suspense } from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';
import { mapObjIndexed } from 'ramda';

export enum IconCode {
  Menu = 'Menu',
  Enter = 'Enter',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ChevronUp = 'ChevronUp',
  ChevronRight = 'ChevronRight',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronUpBold = 'ChevronUpBold',
  ChevronRightBold = 'ChevronRightBold',
  ChevronDownBold = 'ChevronDownBold',
  ChevronLeftBold = 'ChevronLeftBold',
  Copy = 'Copy',
  Download = 'Download',
  AudiLogo = 'AudiLogo',
  ApriliaLogo = 'ApriliaLogo',
  BMWMotorradLogo = 'BMWMotorradLogo',
  DucatiLogo = 'DucatiLogo',
  HondaMotorcycleLogo = 'HondaMotorcycleLogo',
  HarleyDavidsonLogo = 'HarleyDavidsonLogo',
  KawasakiLogo = 'KawasakiLogo',
  KTMLogo = 'KTMLogo',
  PiaggioLogo = 'PiaggioLogo',
  SuzukiLogo = 'SuzukiLogo',
  VespaLogo = 'VespaLogo',
  YamahaLogo = 'YamahaLogo',
  SignIn = 'SignIn',
  BMWLogo = 'BMWLogo',
  MiniLogo = 'MiniLogo',
  JeepLogo = 'JeepLogo',
  MercedesLogo = 'MercedesLogo',
  AlfaRomeoLogo = 'AlfaRomeoLogo',
  AstonMartinLogo = 'AstonMartinLogo',
  AlpinaLogo = 'AlpinaLogo',
  McLarenLogo = 'McLarenLogo',
  MotorcycleLogo = 'MotorcycleLogo',
  RollsRoyceLogo = 'RollsRoyceLogo',
  GrenadierLogo = 'GrenadierLogo',
  MitsubishiLogo = 'MitsubishiLogo',
  VoyahLogo = 'VoyahLogo',
  TeslaLogo = 'TeslaLogo',
  OpelLogo = 'OpelLogo',
  CupraLogo = 'CupraLogo',
  FiatLogo = 'FiatLogo',
  CitroenLogo = 'CitroenLogo',
  DSLogo = 'DSLogo',
  DaciaLogo = 'DaciaLogo',
  PeugeotLogo = 'PeugeotLogo',
  RenaultLogo = 'RenaultLogo',
  FordLogo = 'FordLogo',
  HyundaiLogo = 'HyundaiLogo',
  JaguarLogo = 'JaguarLogo',
  KiaLogo = 'KiaLogo',
  LandRoverLogo = 'LandRoverLogo',
  MazdaLogo = 'MazdaLogo',
  NissanLogo = 'NissanLogo',
  VolvoLogo = 'VolvoLogo',
  LexusLogo = 'LexusLogo',
  ToyotaLogo = 'ToyotaLogo',
  PorscheLogo = 'PorscheLogo',
  VolkswagenLogo = 'VolkswagenLogo',
  HondaLogo = 'HondaLogo',
  SeatLogo = 'SeatLogo',
  SkodaLogo = 'SkodaLogo',
  Gadget = 'Gadget',
  Compass = 'Compass',
  Deposit = 'Deposit',
  Diamond = 'Diamond',
  EcoDriving = 'EcoDriving',
  Person = 'Person',
  SportsCar = 'SportsCar',
  Gift = 'Gift',
  Mountains = 'Mountains',
  HatchbackBody = 'HatchbackBody',
  SedanBody = 'SedanBody',
  LiftbackBody = 'LiftbackBody',
  CombiBody = 'CombiBody',
  CoupeBody = 'CoupeBody',
  SuvBody = 'SuvBody',
  VanBody = 'VanBody',
  CabrioBody = 'CabrioBody',
  CrossoverBody = 'CrossoverBody',
  MotorcycleBody = 'MotorcycleBody',
  Close = 'Close',
  CloseBold = 'CloseBold',
  Megaphone = 'Megaphone',
  Engine = 'Engine',
  ElectricEngine = 'ElectricEngine',
  Pump = 'Pump',
  PetrolPump = 'PetrolPump',
  DieselPump = 'DieselPump',
  Gearbox = 'Gearbox',
  Speedometer = 'Speedometer',
  Seats = 'Seats',
  FoldedSeats = 'FoldedSeats',
  Luggage = 'Luggage',
  Suitcase = 'Suitcase',
  ExhaustEmission = 'ExhaustEmission',
  RearDrive = 'RearDrive',
  FrontWheelDrive = 'FrontWheelDrive',
  FourWheelDrive = 'FourWheelDrive',
  CarDimensions = 'CarDimensions',
  CarWidthHeight = 'CarWidthHeight',
  CarLength = 'CarLength',
  Cylinder = 'Cylinder',
  Battery = 'Battery',
  Bell = 'Bell',
  Bin = 'Bin',
  Range = 'Range',
  Rabbit = 'Rabbit',
  CarWithSeats = 'CarWithSeats',
  Rim = 'Rim',
  ProductAdvice = 'ProductAdvice',
  ProductBenefits = 'ProductBenefits',
  ProductEquipment = 'ProductEquipment',
  ProductGallery = 'ProductGallery',
  ProductParameters = 'ProductParameters',
  AvailableImmediately = 'AvailableImmediately',
  Assistance = 'Assistance',
  CheckCircle = 'CheckCircle',
  HomeDelivery = 'HomeDelivery',
  AdditionalFees = 'AdditionalFees',
  Insurance = 'Insurance',
  Percent = 'Percent',
  ServicePackage = 'ServicePackage',
  WeekDelivery = 'WeekDelivery',
  SearchPlus = 'SearchPlus',
  Info = 'Info',
  Envelope = 'Envelope',
  Phone = 'Phone',
  Sliders = 'Sliders',
  Register = 'Register',
  Login = 'Login',
  Scroll = 'Scroll',
  YouTube = 'YouTube',
  LinkedIn = 'LinkedIn',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Bulb = 'Bulb',
  Livechat = 'Livechat',
  Lock = 'Lock',
  Document = 'Document',
  List = 'List',
  ThumbnailPlay = 'ThumbnailPlay',
  ChevronLeftLight = 'ChevronLeftLight',
  ChevronRightLight = 'ChevronRightLight',
  Home = 'Home',
  FuelCard = 'FuelCard',
  ReplacementCar = 'ReplacementCar',
  Warranty = 'Warranty',
  Garage = 'Garage',
  CarHealth = 'CarHealth',
  CheckList = 'CheckList',
  LongCheckList = 'LongCheckList',
  CashUmbrella = 'CashUmbrella',
  CarFront = 'CarFront',
  DiscountTag = 'DiscountTag',
  BadgeBig = 'BadgeBig',
  BadgeSmall = 'BadgeSmall',
  FuelCanister = 'FuelCanister',
}

const ComponentPromiseMap = {
  [IconCode.Menu]: () => import('src/assets/icons/Menu'),
  [IconCode.Enter]: () => import('src/assets/icons/Enter'),
  [IconCode.ArrowLeft]: () => import('src/assets/icons/ArrowLeft'),
  [IconCode.ArrowRight]: () => import('src/assets/icons/ArrowRight'),
  [IconCode.ChevronUp]: () => import('src/assets/icons/ChevronUp'),
  [IconCode.ChevronRight]: () => import('src/assets/icons/ChevronRight'),
  [IconCode.ChevronDown]: () => import('src/assets/icons/ChevronDown'),
  [IconCode.ChevronLeft]: () => import('src/assets/icons/ChevronLeft'),
  [IconCode.ChevronUpBold]: () => import('src/assets/icons/ChevronUpBold'),
  [IconCode.ChevronRightBold]: () =>
    import('src/assets/icons/ChevronRightBold'),
  [IconCode.ChevronDownBold]: () => import('src/assets/icons/ChevronDownBold'),
  [IconCode.ChevronLeftBold]: () => import('src/assets/icons/ChevronLeftBold'),
  [IconCode.Copy]: () => import('src/assets/icons/Copy'),
  [IconCode.Download]: () => import('src/assets/icons/Download'),
  [IconCode.AudiLogo]: () => import('src/assets/icons/AudiLogo'),
  [IconCode.ApriliaLogo]: () => import('src/assets/icons/ApriliaLogo'),
  [IconCode.BMWMotorradLogo]: () => import('src/assets/icons/BMWMotorradLogo'),
  [IconCode.DucatiLogo]: () => import('src/assets/icons/DucatiLogo'),
  [IconCode.HondaMotorcycleLogo]: () =>
    import('src/assets/icons/HondaMotorcycleLogo'),
  [IconCode.HarleyDavidsonLogo]: () =>
    import('src/assets/icons/HarleyDavidsonLogo'),
  [IconCode.KawasakiLogo]: () => import('src/assets/icons/KawasakiLogo'),
  [IconCode.KTMLogo]: () => import('src/assets/icons/KTMLogo'),
  [IconCode.PiaggioLogo]: () => import('src/assets/icons/PiaggioLogo'),
  [IconCode.SuzukiLogo]: () => import('src/assets/icons/SuzukiLogo'),
  [IconCode.VespaLogo]: () => import('src/assets/icons/VespaLogo'),
  [IconCode.YamahaLogo]: () => import('src/assets/icons/YamahaLogo'),
  [IconCode.SignIn]: () => import('src/assets/icons/SignIn'),
  [IconCode.BMWLogo]: () => import('src/assets/icons/BMWLogo'),
  [IconCode.MiniLogo]: () => import('src/assets/icons/MiniLogo'),
  [IconCode.JeepLogo]: () => import('src/assets/icons/JeepLogo'),
  [IconCode.MercedesLogo]: () => import('src/assets/icons/MercedesLogo'),
  [IconCode.MitsubishiLogo]: () => import('src/assets/icons/MitsubishiLogo'),
  [IconCode.GrenadierLogo]: () => import('src/assets/icons/GrenadierLogo'),
  [IconCode.VoyahLogo]: () => import('src/assets/icons/VoyahLogo'),
  [IconCode.AlfaRomeoLogo]: () => import('src/assets/icons/AlfaRomeoLogo'),
  [IconCode.AlpinaLogo]: () => import('src/assets/icons/AlpinaLogo'),
  [IconCode.AstonMartinLogo]: () => import('src/assets/icons/AstonMartinLogo'),
  [IconCode.McLarenLogo]: () => import('src/assets/icons/McLarenLogo'),
  [IconCode.MotorcycleLogo]: () => import('src/assets/icons/MotorcycleBody'),
  [IconCode.RollsRoyceLogo]: () => import('src/assets/icons/RollsRoyceLogo'),
  [IconCode.TeslaLogo]: () => import('src/assets/icons/TeslaLogo'),
  [IconCode.OpelLogo]: () => import('src/assets/icons/OpelLogo'),
  [IconCode.CupraLogo]: () => import('src/assets/icons/CupraLogo'),
  [IconCode.FiatLogo]: () => import('src/assets/icons/FiatLogo'),
  [IconCode.CitroenLogo]: () => import('src/assets/icons/CitroenLogo'),
  [IconCode.DSLogo]: () => import('src/assets/icons/DSLogo'),
  [IconCode.DaciaLogo]: () => import('src/assets/icons/DaciaLogo'),
  [IconCode.PeugeotLogo]: () => import('src/assets/icons/PeugeotLogo'),
  [IconCode.RenaultLogo]: () => import('src/assets/icons/RenaultLogo'),
  [IconCode.FordLogo]: () => import('src/assets/icons/FordLogo'),
  [IconCode.HyundaiLogo]: () => import('src/assets/icons/HyundaiLogo'),
  [IconCode.JaguarLogo]: () => import('src/assets/icons/JaguarLogo'),
  [IconCode.KiaLogo]: () => import('src/assets/icons/KiaLogo'),
  [IconCode.LandRoverLogo]: () => import('src/assets/icons/LandRoverLogo'),
  [IconCode.MazdaLogo]: () => import('src/assets/icons/MazdaLogo'),
  [IconCode.NissanLogo]: () => import('src/assets/icons/NissanLogo'),
  [IconCode.VolvoLogo]: () => import('src/assets/icons/VolvoLogo'),
  [IconCode.ToyotaLogo]: () => import('src/assets/icons/ToyotaLogo'),
  [IconCode.LexusLogo]: () => import('src/assets/icons/LexusLogo'),
  [IconCode.PorscheLogo]: () => import('src/assets/icons/PorscheLogo'),
  [IconCode.VolkswagenLogo]: () => import('src/assets/icons/VolkswagenLogo'),
  [IconCode.HondaLogo]: () => import('src/assets/icons/HondaLogo'),
  [IconCode.SeatLogo]: () => import('src/assets/icons/SeatLogo'),
  [IconCode.SkodaLogo]: () => import('src/assets/icons/SkodaLogo'),
  [IconCode.Gadget]: () => import('src/assets/icons/Gadget'),
  [IconCode.Compass]: () => import('src/assets/icons/Compass'),
  [IconCode.Diamond]: () => import('src/assets/icons/Diamond'),
  [IconCode.Deposit]: () => import('src/assets/icons/Deposit'),
  [IconCode.EcoDriving]: () => import('src/assets/icons/EcoDriving'),
  [IconCode.Person]: () => import('src/assets/icons/Person'),
  [IconCode.SportsCar]: () => import('src/assets/icons/SportsCar'),
  [IconCode.Gift]: () => import('src/assets/icons/Gift'),
  [IconCode.Mountains]: () => import('src/assets/icons/Mountains'),
  [IconCode.HatchbackBody]: () => import('src/assets/icons/HatchbackBody'),
  [IconCode.SedanBody]: () => import('src/assets/icons/SedanBody'),
  [IconCode.LiftbackBody]: () => import('src/assets/icons/LiftbackBody'),
  [IconCode.CombiBody]: () => import('src/assets/icons/CombiBody'),
  [IconCode.CoupeBody]: () => import('src/assets/icons/CoupeBody'),
  [IconCode.SuvBody]: () => import('src/assets/icons/SuvBody'),
  [IconCode.VanBody]: () => import('src/assets/icons/VanBody'),
  [IconCode.CabrioBody]: () => import('src/assets/icons/CabrioBody'),
  [IconCode.CrossoverBody]: () => import('src/assets/icons/CrossoverBody'),
  [IconCode.MotorcycleBody]: () => import('src/assets/icons/MotorcycleBody'),
  [IconCode.Close]: () => import('src/assets/icons/Close'),
  [IconCode.CloseBold]: () => import('src/assets/icons/CloseBold'),
  [IconCode.Megaphone]: () => import('src/assets/icons/Megaphone'),
  [IconCode.Engine]: () => import('src/assets/icons/Engine'),
  [IconCode.ElectricEngine]: () => import('src/assets/icons/ElectricEngine'),
  [IconCode.Pump]: () => import('src/assets/icons/Pump'),
  [IconCode.PetrolPump]: () => import('src/assets/icons/PetrolPump'),
  [IconCode.DieselPump]: () => import('src/assets/icons/DieselPump'),
  [IconCode.Gearbox]: () => import('src/assets/icons/Gearbox'),
  [IconCode.Speedometer]: () => import('src/assets/icons/Speedometer'),
  [IconCode.Seats]: () => import('src/assets/icons/Seats'),
  [IconCode.FoldedSeats]: () => import('src/assets/icons/FoldedSeats'),
  [IconCode.Luggage]: () => import('src/assets/icons/Luggage'),
  [IconCode.Suitcase]: () => import('src/assets/icons/Suitcase'),
  [IconCode.ExhaustEmission]: () => import('src/assets/icons/ExhaustEmission'),
  [IconCode.RearDrive]: () => import('src/assets/icons/RearDrive'),
  [IconCode.FrontWheelDrive]: () => import('src/assets/icons/FrontWheelDrive'),
  [IconCode.FourWheelDrive]: () => import('src/assets/icons/FourWheelDrive'),
  [IconCode.CarDimensions]: () => import('src/assets/icons/CarDimensions'),
  [IconCode.CarWidthHeight]: () => import('src/assets/icons/CarWidthHeight'),
  [IconCode.CarLength]: () => import('src/assets/icons/CarLength'),
  [IconCode.Cylinder]: () => import('src/assets/icons/Cylinder'),
  [IconCode.Battery]: () => import('src/assets/icons/Battery'),
  [IconCode.Bell]: () => import('src/assets/icons/Bell'),
  [IconCode.Bin]: () => import('src/assets/icons/Bin'),
  [IconCode.Range]: () => import('src/assets/icons/Range'),
  [IconCode.Rabbit]: () => import('src/assets/icons/Rabbit'),
  [IconCode.CarWithSeats]: () => import('src/assets/icons/CarWithSeats'),
  [IconCode.Rim]: () => import('src/assets/icons/Rim'),
  [IconCode.ProductAdvice]: () => import('src/assets/icons/ProductAdvice'),
  [IconCode.ProductBenefits]: () => import('src/assets/icons/ProductBenefits'),
  [IconCode.ProductEquipment]: () =>
    import('src/assets/icons/ProductEquipment'),
  [IconCode.ProductGallery]: () => import('src/assets/icons/ProductGallery'),
  [IconCode.ProductParameters]: () =>
    import('src/assets/icons/ProductParameters'),
  [IconCode.AvailableImmediately]: () =>
    import('src/assets/icons/AvailableImmediately'),
  [IconCode.Assistance]: () => import('src/assets/icons/Assistance'),
  [IconCode.CheckCircle]: () => import('src/assets/icons/CheckCircle'),
  [IconCode.HomeDelivery]: () => import('src/assets/icons/HomeDelivery'),
  [IconCode.AdditionalFees]: () => import('src/assets/icons/AdditionalFees'),
  [IconCode.Insurance]: () => import('src/assets/icons/Insurance'),
  [IconCode.Percent]: () => import('src/assets/icons/Percent'),
  [IconCode.ServicePackage]: () => import('src/assets/icons/ServicePackage'),
  [IconCode.WeekDelivery]: () => import('src/assets/icons/WeekDelivery'),
  [IconCode.SearchPlus]: () => import('src/assets/icons/SearchPlus'),
  [IconCode.Info]: () => import('src/assets/icons/Info'),
  [IconCode.Envelope]: () => import('src/assets/icons/Envelope'),
  [IconCode.Phone]: () => import('src/assets/icons/Phone'),
  [IconCode.Sliders]: () => import('src/assets/icons/Sliders'),
  [IconCode.Register]: () => import('src/assets/icons/Register'),
  [IconCode.Login]: () => import('src/assets/icons/Login'),
  [IconCode.Scroll]: () => import('src/assets/icons/Scroll'),
  [IconCode.YouTube]: () => import('src/assets/icons/YouTube'),
  [IconCode.LinkedIn]: () => import('src/assets/icons/LinkedIn'),
  [IconCode.Instagram]: () => import('src/assets/icons/Instagram'),
  [IconCode.Facebook]: () => import('src/assets/icons/Facebook'),
  [IconCode.Bulb]: () => import('src/assets/icons/Bulb'),
  [IconCode.Livechat]: () => import('src/assets/icons/Livechat'),
  [IconCode.Lock]: () => import('src/assets/icons/Lock'),
  [IconCode.Document]: () => import('src/assets/icons/Document'),
  [IconCode.List]: () => import('src/assets/icons/List'),
  [IconCode.ThumbnailPlay]: () => import('src/assets/icons/ThumbnailPlay'),
  [IconCode.ChevronLeftLight]: () =>
    import('src/assets/icons/ChevronLeftLight'),
  [IconCode.ChevronRightLight]: () =>
    import('src/assets/icons/ChevronRightLight'),
  [IconCode.Home]: () => import('src/assets/icons/Home'),
  [IconCode.FuelCard]: () => import('src/assets/icons/FuelCard'),
  [IconCode.ReplacementCar]: () => import('src/assets/icons/ReplacementCar'),
  [IconCode.Warranty]: () => import('src/assets/icons/Warranty'),
  [IconCode.Garage]: () => import('src/assets/icons/Garage'),
  [IconCode.CarHealth]: () => import('src/assets/icons/CarHealth'),
  [IconCode.CheckList]: () => import('src/assets/icons/CheckList'),
  [IconCode.LongCheckList]: () => import('src/assets/icons/LongCheckList'),
  [IconCode.CarFront]: () => import('src/assets/icons/CarFront'),
  [IconCode.CashUmbrella]: () => import('src/assets/icons/CashUmbrella'),
  [IconCode.DiscountTag]: () => import('src/assets/icons/DiscountTag'),
  [IconCode.BadgeBig]: () => import('src/assets/icons/BadgeBig'),
  [IconCode.BadgeSmall]: () => import('src/assets/icons/BadgeSmall'),
  [IconCode.FuelCanister]: () => import('src/assets/icons/FuelCanister'),
} as const;

export const preloadIcons = (...codes: IconCode[]) =>
  Promise.all(codes.map((_) => ComponentPromiseMap[_]()));

const ComponentLazyMap = mapObjIndexed(lazy, ComponentPromiseMap);

export const Icon = memo(
  forwardRef<SVGSVGElement, IconProps & { code: IconCode | string }>(
    ({ code, ...props }, ref) => {
      const iconCode = Object.keys(IconCode).find(
        (_) => _.toLowerCase() === `${code}`.toLowerCase()
      );

      if (iconCode == null) {
        console.error(`Icon with code ${code} was not defined!`);
        return <></>;
      }

      const Component = ComponentLazyMap[iconCode as IconCode];

      if (Component == null) {
        console.error(`Icon with code ${code} was not provided!`);
        return <></>;
      }

      return (
        <Suspense fallback={<IconBox {...props} />}>
          <Component {...props} ref={ref} id="autocomplete-element" />
        </Suspense>
      );
    }
  )
);
