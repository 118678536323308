import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 0 64 56" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M26.773 51.818c-1.174 1.174-3.063 1.178-4.22.021L4.207 33.534c-1.164-1.164-1.165-2.97 0-4.136L29.894 3.876c.4-.401 1.001-.543 2.128-.543h14l1.217 1.03 1.723-2.035L46.997.667H32.021c-1.175 0-2.787.097-4.013 1.323l-.003.003-25.68 25.515-.003.003a5.55 5.55 0 000 7.91l18.345 18.304c2.215 2.214 5.793 2.176 7.99-.02L54.415 28.01c1.087-1.085 1.63-2.551 1.63-4.026V8.896l-2.44-2.162-1.768 1.996 1.542 1.366v13.888c0 .83-.304 1.597-.847 2.139L26.773 51.818z" />
      <path d="M50.338 11.693L45.02 6.376 43.135 8.26l5.317 5.317 1.886-1.885z" />
      <path d="M53.75 5.906a7.023 7.023 0 00-6.399 4.125l-2.429-1.1a9.689 9.689 0 018.829-5.692c5.35 0 9.689 4.338 9.689 9.69a9.688 9.688 0 01-9.69 9.688v-2.666a7.022 7.022 0 007.022-7.022 7.023 7.023 0 00-7.021-7.023zM18.817 23.264a3.991 3.991 0 003.992 3.992h1.329a3.991 3.991 0 003.992-3.992v-1.33a3.991 3.991 0 00-3.992-3.991h-1.33a3.991 3.991 0 00-3.991 3.992v1.33zm3.992 1.325a1.325 1.325 0 01-1.325-1.325v-1.33c0-.732.592-1.324 1.325-1.324h1.329c.732 0 1.325.592 1.325 1.325v1.33c0 .732-.593 1.324-1.325 1.324h-1.33zM30.782 32.57a3.991 3.991 0 003.992 3.993h1.329a3.991 3.991 0 003.992-3.992v-1.33a3.991 3.991 0 00-3.992-3.991h-1.33a3.991 3.991 0 00-3.991 3.991v1.33zm3.992 1.326a1.324 1.324 0 01-1.326-1.325v-1.33c0-.732.593-1.325 1.326-1.325h1.329c.732 0 1.325.593 1.325 1.325v1.33c0 .732-.593 1.325-1.325 1.325h-1.33zM33.613 17.292l-10.634 18.61 2.315 1.323 10.634-18.61-2.315-1.323z" />
    </g>
  </IconBox>
);
