import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M39.3788 32.5001L21.9395 15.0608l2.1213-2.1213 19.5606 19.5606-19.5606 19.5607-2.1213-2.1213 17.4393-17.4394z"
    />
  </IconBox>
);
