import { useEffect, useState } from 'react';
import axios from 'axios';
import { FinancingType, Offer } from 'src/common/models/offer';
import { CustomerType } from 'src/common/models/customerType';
import { Product } from 'src/app/common/models/product';
import { useSelector } from 'react-redux';
import {
  activeOfferSelector,
  availableOffersSelector,
} from 'src/app/product/state/products/productsSelectors';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';

const artefaktaOrigin = 'https://kalkulator.radosczjazdy.pl';
const artefaktaWidgetUrl = `${artefaktaOrigin}/API/BMW_CARPLATFORM_NEW/GET/json`;

interface ArtefaktaDetails {
  down?: number;
  month?: number;
  purchase?: number;
  product?: string;
  mileage?: number;
  rate?: number;
  rate_netto?: number;
}

export interface UseGetArtefaktaLeasingOffersParams {
  initialOffer?: Offer;
  product: Product;
}

export const useGetArtefaktaLeasingOffers = ({
  initialOffer,
  product,
}: UseGetArtefaktaLeasingOffersParams) => {
  const activeOffer = useSelector(activeOfferSelector);
  const availableOffers = useSelector(availableOffersSelector);
  const [leasingOffers, setLeasingOffers] = useState<Offer[]>([]);
  const userCustomerType = useSelector(customerTypeSelector);

  useEffect(() => {
    if (
      product.offers.find(
        (offer) => offer.external?.type === 'artefakta4internal'
      ) == null
    ) {
      return;
    }

    const leasingOffer = availableOffers.find(
      (offer) => offer.fullPrice && offer.external
    );

    const carDetails = {
      total: initialOffer?.fullPrice
        ? initialOffer.fullPrice
        : leasingOffer?.fullPrice,
      type: product.type,
    };

    const widgetUrl = `${artefaktaWidgetUrl}?total=${carDetails.total}&type=${
      carDetails.type
    }&FP=${JSON.stringify(
      activeOffer?.external
        ? activeOffer?.external?.details
        : leasingOffer?.external?.details
    )}`;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(widgetUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        const mappedResponse = response.data.map((offer: ArtefaktaDetails) => ({
          initialPayment: Number(offer.down),
          buyoutPrice: Number(offer.purchase),
          contractPeriod: Number(offer.month),
          financingType: FinancingType.Leasing,
          external: {
            details: {
              down: Number(offer.down),
              month: Number(offer.month),
              purchase: Number(offer.purchase),
            },
            priceB2B: offer.rate_netto,
            priceB2C: offer.rate,
            type: 'artefakta4internal',
          },
          price:
            userCustomerType === CustomerType.B2B
              ? offer.rate_netto
              : offer.rate,
        }));
        setLeasingOffers(mappedResponse);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  return leasingOffers;
};
