import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.068 11.0903l.6064 2.9458-12.0724 2.4855-4.3472 12.6464-3.99655-.9991v-2.1436l-.37035-.1852H3.85196l.17979.7192 3.21559 2.8583 1.42307 2.1346-1.84075 2.7611-.35307 8.8267 2.64985 9.0852v2.5907l1.80716.6777h4.8235l2.5148-.7544v-3.0434h14.796v3.0075H21.2794v2.2736l-5.0809 1.5242h-5.8103l-4.26929-1.6009v-4.2453l-2.66707-9.1443.40649-10.1622 1.19749-1.7962-.09605-.1441-3.62044-3.2182L0 22.8326h7.59788L9.863 23.9652l3.4524-10.0435 13.7526-2.8314zM19.7556 21.101l9.4085-1.7423-.5476-2.9572-11.0996 2.0555-4.1157 11.3182 20.8979-1.2794-.1838-3.0019-16.3204.9992 1.9607-5.3921z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.58008 34.613H24.3449l2.4951 8.7328-11.2396-2.0436-6.02032-6.6892zm6.75302 3.0076l.8157.9063 5.4707.9947-.5432-1.901h-5.7432zM41.1191 0h13.5175v3.00752H44.1267V16.6343h13.6268V9.82093h3.0075v9.82097H41.1191V0z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.9811 2.56731L51.8282 14.7202l-4.7598-4.75984 2.1266-2.12664 2.6332 2.63318L61.8544.440674l2.1267 2.126636zM41.1191 22.1746h13.5175v3.0075H44.1267v13.6268h13.6268v-6.8134h3.0075v9.8209H41.1191V22.1746z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.9811 24.7416L51.8282 36.8945l-4.7598-4.7598 2.1266-2.1267 2.6332 2.6332L61.8544 22.615l2.1267 2.1266zM41.1191 44.3579h13.5175v3.0075H44.1267v13.6268h13.6268v-6.8134h3.0075v9.821H41.1191V44.3579z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.9811 46.9159L51.8282 59.0688l-4.7598-4.7598 2.1266-2.1267 2.6332 2.6333 10.0262-10.0263 2.1267 2.1266z"
    />
  </IconBox>
);
