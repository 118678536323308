import React, { FC } from 'react';
import { UsedProduct } from 'src/app/common/models/product';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { StyleProps } from '@summer/react-kit';
import Copy from 'src/assets/icons/Copy';
import { UsedProductParameter } from 'src/app/product/components/ProductPageSectionUsedProductParameters/UsedProductParameter';
import { media } from 'src/config/breakpoints';
import { ProductSectionContainer } from 'src/app/product/components/ProductSectionContainer';
import { localFormatNumber } from 'src/lib/localFormatNumber';

export interface ProductPageSectionUsedProductParametersProps {
  product: UsedProduct;
}

const Container = styled(ProductSectionContainer)`
  display: grid;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 1rem;
  padding-top: 3.75rem;
  padding-bottom: 0;

  ${media.w.min.px576(css`
    grid-template-columns: 11rem 16.5rem;
  `)}

  ${media.w.min.px768(css`
    grid-template-columns: unset;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
  `)}

  ${media.w.min.px1200(css`
    padding: 2.5rem 19rem 0 10rem;
  `)}
`;

const StyledButton = styled(Button)`
  margin-left: 0.5rem;
`;

export const ProductPageSectionUsedProductParameters: FC<
  StyleProps & ProductPageSectionUsedProductParametersProps
> = ({ className, style, product }) => {
  return (
    <Container className={className} style={style}>
      <UsedProductParameter
        label={
          <>
            data pierwszej
            <br />
            rejestracji
          </>
        }
      >
        {product.resellDetails?.firstRegistrationDate}
      </UsedProductParameter>
      <UsedProductParameter
        label={
          <>
            numer <br /> vin
          </>
        }
      >
        <>
          {product.vins?.[0]}
          <StyledButton
            kind="box"
            variant="transparent"
            size="small"
            icon={Copy}
            onClick={() => {
              navigator?.clipboard?.writeText(product.vins?.[0]);
            }}
          />
        </>
      </UsedProductParameter>
      <UsedProductParameter
        label={
          <>
            aktualny
            <br />
            przebieg
          </>
        }
      >
        {localFormatNumber(product.resellDetails.mileage)} km
      </UsedProductParameter>
    </Container>
  );
};
