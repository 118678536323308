import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GalleryModalSlideItem } from 'src/app/product/components/GalleryModal/GalleryModalSlideItem';
import { SliderArrowControls } from 'src/common/components/SliderArrowControls';
import { GalleryModalContainer } from 'src/app/product/components/GalleryModal/GalleryModalContainer';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { NavigationOptions } from 'swiper/types/components/navigation';
import { GalleryItem } from 'src/app/product/components/GalleryModal/galleryItem';
import { deceleratedEasing, fadeIn } from 'src/common/styles/animations';

const Slider = styled(Swiper)`
  height: 100%;
  opacity: 0;
  animation: ${fadeIn(0, 1)} 250ms 100ms ${deceleratedEasing} forwards;

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export interface GalleryModalSliderProps {
  items: GalleryItem[];
  initialSlide?: number;
  setSliderInstance: (instance: SwiperCore) => void;
  sliderInstance?: SwiperCore;
  thumbsInstance?: SwiperCore;
}

export const GalleryModalSlider: FC<GalleryModalSliderProps> = ({
  items,
  initialSlide,
  setSliderInstance,
  thumbsInstance,
}) => {
  const [navigation, setNavigation] = useState<NavigationOptions>({
    prevEl: null,
    nextEl: null,
  });
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const slideChangeHandler = useCallback(
    (swiper) => setActiveSlide(swiper.realIndex),
    [setActiveSlide]
  );

  const swiperOptions = useMemo<SwiperOptions>(
    () => ({
      keyboard: { enabled: true },
      slidesPerView: 1,
      onSlideChange: slideChangeHandler,
      onSwiper: setSliderInstance,
      spaceBetween: 18,
      navigation,
      initialSlide,
    }),
    [setSliderInstance, navigation]
  );

  return (
    <SliderArrowControls onNavigation={setNavigation} enabled>
      <GalleryModalContainer style={{ height: '100%' }}>
        {items.length > 0 && (
          <Slider {...swiperOptions}>
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <GalleryModalSlideItem
                  active={index === activeSlide}
                  item={item}
                />
              </SwiperSlide>
            ))}
          </Slider>
        )}
      </GalleryModalContainer>
    </SliderArrowControls>
  );
};
