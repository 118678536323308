import React, { memo, useEffect, useMemo } from 'react';
import { OrderLayout } from 'src/app/product/components/OrderLayout';
import { RouteComponentProps, navigate } from '@reach/router';
import { OrderRequest } from 'src/app/product/components/OrderRequest';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOfferSelector,
  activeProductSelector,
} from 'src/app/product/state/products/productsSelectors';
import { setParam } from '@summer/react-kit';
import {
  ProductItemPageRoute,
  ProductOrderThankYouPageRoute,
  SearchPageRoute,
} from 'src/app/routes';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { currentPageUrlSelector } from 'src/state/pageContext/pageContextSelectors';

export type OrderPageProps = RouteComponentProps<{
  offerId: string;
  productId: string;
}>;

export const OrderPage = memo<OrderPageProps>(({ productId, offerId }) => {
  useStorePageUrl();

  const dispatch = useDispatch();

  const activeProduct = useSelector(activeProductSelector);
  const activeOffer = useSelector(activeOfferSelector);
  const currentPageUrl = useSelector(currentPageUrlSelector);

  const backPath = useMemo(() => {
    const basePath = setParam(':productId')(ProductItemPageRoute)(productId!);

    const params =
      offerId != null ? new URLSearchParams({ offerId }) : undefined;

    return `${basePath}${params ? `?${params}` : ''}`;
  }, [productId, offerId]);

  const thankYouPagePath = useMemo(() => {
    return setParam(':offerId')(
      setParam(':productId')(ProductOrderThankYouPageRoute)(productId ?? '')
    )(activeOffer?.id ?? '');
  }, [productId, activeOffer]);

  useEffect(() => {
    if (activeProduct == null) {
      navigate(SearchPageRoute, {
        replace: true,
      });
    } else if (activeOffer?.id !== offerId) {
      navigate(backPath, {
        replace: true,
      }).then(() =>
        dispatch(
          snackbarActions.enqueueSnackbar({
            message:
              'W celu złożenia zapytania o ofertę należy wcześniej skonfigurować ją przy użyciu kalkulatora',
            options: { variant: 'warning' },
          })
        )
      );
    }
  }, [activeOffer, backPath, activeProduct]);

  return (
    <OrderLayout backPath={backPath}>
      <OrderRequest
        onSubmit={() =>
          navigate(thankYouPagePath, {
            state: { orderFormPageUrl: currentPageUrl },
          })
        }
      />
    </OrderLayout>
  );
});
