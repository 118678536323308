import IframeResizer from 'iframe-resizer-react';
import React, { FC } from 'react';
import { Product, UsedProduct } from 'src/app/common/models/product';
import styled from 'styled-components';
import { useArtefaktaCalculatorConfigurations } from 'src/app/product/hooks/useArtefaktaCalculatorConfigurations';

const StyledIframeResizer = styled(IframeResizer)`
  width: 1px;
  min-width: 100%;
  border: 0;
`;

export interface ArtefaktaCalculatorProps {
  product: Product;
}

export const ArtefaktaCalculator: FC<ArtefaktaCalculatorProps> = ({
  product,
}) => {
  const { widgetUrl } = useArtefaktaCalculatorConfigurations(
    product as UsedProduct
  );

  return (
    <>
      {widgetUrl != null && (
        <StyledIframeResizer id="radosczjazdy-iframe" src={widgetUrl} />
      )}
    </>
  );
};
