import React from 'react';
import { Seo } from 'src/public/components/Seo';
import { Footer } from 'src/public/components/Footer';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { LandingRoute } from 'src/public/publicRoutes';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Paragraph } from 'src/common/components/typography/Text';
import { Title } from 'src/common/components/typography/Title';
import { Logo } from 'src/common/components/Logo';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';

// todo: For now this duplicates some content from index page, should be refactored when design will be final
const FullPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const Main = styled.main`
  display: grid;
  grid-template-rows: 1fr min-content;
  height: 100%;
`;

const Section = styled.section`
  padding-top: 5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  overflow: hidden;
  & > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
`;

const NotFoundPage = () => (
  <FullPageLayout>
    <Main>
      <Section>
        <Seo title="404" />
        <HomeLogoLink />
        <Title level={1}>Strona, której szukasz, nie została znaleziona</Title>
        <Paragraph>
          Prosimy upewnić się, że adres jest poprawny lub powróćić na
          <SimpleLink
            as={Link}
            to={LandingRoute}
            style={{ marginLeft: '0.2rem', fontSize: '1rem' }}
          >
            stronę główną
          </SimpleLink>
        </Paragraph>
      </Section>
      <Section>
        <Footer />
      </Section>
    </Main>
  </FullPageLayout>
);

// eslint-disable-next-line import/no-default-export
export default NotFoundPage;
