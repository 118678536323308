import React, { FC } from 'react';
import styled from 'styled-components';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { StyleProps } from '@summer/react-kit';
import { colors } from 'src/config/colors';
import { navigate } from 'gatsby';
import Close from 'src/assets/icons/Close';
import { Button } from 'src/common/components/Button';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';

const Layout = styled(ConstraintContainer)`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 3.4rem auto;
  padding: 0;
`;

const Header = styled(ConstraintContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
  background: ${colors.background};
`;

export interface OrderLayoutProps extends StyleProps {
  backPath: string;
}

export const OrderLayout: FC<OrderLayoutProps> = ({
  backPath,
  children,
  ...styleProps
}) => {
  useKeyPress(KEY_ESCAPE, CODE_ESCAPE, () => navigate(backPath));

  return (
    <Layout {...styleProps}>
      <Header>
        <Button
          kind="box"
          variant="transparent"
          icon={Close}
          onClick={() => navigate(backPath)}
        >
          zamknij
        </Button>
      </Header>

      <ScrollContainer style={{ height: '100%', width: '100%' }}>
        {children}
      </ScrollContainer>
    </Layout>
  );
};
