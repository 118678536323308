import {
  searchFiltersActions,
  srpFiltersActions,
} from 'src/app/search/state/searchFilters/searchFiltersActions';
import {
  ExoticFilter,
  FiltersState,
  OptionFilter,
} from 'src/app/common/models/filter';
import { createReducer } from '@reduxjs/toolkit';
import {
  defaultFiltersState,
  filterConfig,
} from 'src/app/common/models/filterConfig';
import { keys } from 'ramda';
import { authActions } from 'src/state/auth/authActions';

export type SearchFiltersState = Required<FiltersState>;

const initialState: SearchFiltersState = defaultFiltersState;

const resetPagination = (state: SearchFiltersState) => {
  state[ExoticFilter.NewProductOffersPagination].value =
    filterConfig[ExoticFilter.NewProductOffersPagination].initialValue;
  state[ExoticFilter.UsedProductOffersPagination].value =
    filterConfig[ExoticFilter.UsedProductOffersPagination].initialValue;
};

export const searchFiltersReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(searchFiltersActions.resetFilterValue, (state, action) => {
      state[action.payload.kind].value =
        filterConfig[action.payload.kind].initialValue;
      resetPagination(state);
    })
    .addCase(searchFiltersActions.setOptionFilterValue, (state, action) => {
      state[action.payload.kind].value = action.payload.value;
      resetPagination(state);
    })
    .addCase(searchFiltersActions.setRangeFilterValue, (state, action) => {
      state[action.payload.kind].value = action.payload.value;
      resetPagination(state);
    })
    .addCase(searchFiltersActions.setModelFilterValue, (state, action) => {
      state[ExoticFilter.Model].value = action.payload.value;
      resetPagination(state);
    })
    .addCase(
      searchFiltersActions.setNewProductOffersPaginationFilterValue,
      (state, action) => {
        state[ExoticFilter.NewProductOffersPagination].value =
          action.payload.value;
      }
    )
    .addCase(
      searchFiltersActions.setUsedProductOffersPaginationFilterValue,
      (state, action) => {
        state[ExoticFilter.UsedProductOffersPagination].value =
          action.payload.value;
      }
    )
    .addCase(searchFiltersActions.loadQueryParamsSuccess, (state, action) => {
      if (keys(action.payload).length < 1) {
        return;
      }

      keys(state).forEach((_) => {
        state[_].value = action.payload[_] ?? filterConfig[_].initialValue;
      });
    })
    .addCase(srpFiltersActions.requestSuccess, (state, action) => {
      state[OptionFilter.EquipmentPackage].options =
        action.payload.equipment.packages;
      state[OptionFilter.EquipmentItemGroup].groups =
        action.payload.equipment.groups;
      state[OptionFilter.PaintColorGroup].options =
        action.payload.paintColorGroups;
      state[OptionFilter.UpholsteryColorGroup].options =
        action.payload.upholsteryColorGroups;
    })
    .addCase(searchFiltersActions.clearFiltersValues, (state) => {
      keys(state).forEach((_) => {
        state[_].value = filterConfig[_].initialValue;
      });
    })
    .addCase(authActions.logout, () => initialState)
);
