import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M 47.710938 28.238281 C 47.710938 16.039062 41.148438 11.886719 35.011719 10.960938 C 35.011719 10.898438 35.023438 10.835938 35.023438 10.761719 C 35.023438 9.226562 33.664062 8 32 8 C 30.335938 8 29.023438 9.226562 29.023438 10.761719 C 29.023438 10.835938 29.023438 10.898438 29.039062 10.960938 C 22.886719 11.898438 16.289062 16.0625 16.289062 28.261719 C 16.289062 42.488281 12.75 44.011719 8 48.011719 L 56 48.011719 C 51.273438 44 47.710938 42.460938 47.710938 28.238281 Z M 13.398438 46 C 14.476562 44.875 15.449219 43.699219 16.238281 42.023438 C 17.738281 38.863281 18.414062 34.625 18.414062 28.25 C 18.414062 22.449219 19.976562 18.199219 23.050781 15.601562 C 25.914062 13.1875 29.511719 12.6875 32.023438 12.6875 C 34.539062 12.6875 38.136719 13.1875 40.976562 15.601562 C 44.039062 18.1875 45.601562 22.414062 45.601562 28.210938 C 45.601562 38.6875 47.460938 42.875 50.636719 46 Z M 13.398438 46 " />
    <path d="M 32.023438 56 C 35.375 56 38.125 53.511719 38.488281 50.625 L 25.5625 50.625 C 25.914062 53.511719 28.675781 56 32.023438 56 Z M 32.023438 56 " />
  </IconBox>
);
