import React, { FC, SVGProps } from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export interface IconSizesProps {
  size?: number | string;
  width?: number | string;
  height?: number | string;
}

export type IconProps = SVGProps<SVGSVGElement> & IconSizesProps;

const StyledSvg = styled.svg<{
  cssWidth?: number | string;
  cssHeight?: number | string;
}>`
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;

  ${({ cssWidth }) =>
    cssWidth != null &&
    css`
      width: ${cssWidth};
    `};

  ${({ cssHeight }) =>
    cssHeight != null &&
    css`
      height: ${cssHeight};
    `};
`;

export type IconComponent = FC<IconProps>;

export const IconBox: IconComponent = ({ size, width, height, ...props }) => {
  const sizes =
    width == null && height == null
      ? {
          cssWidth: rem(size != null ? size : 64),
          cssHeight: rem(size != null ? size : 64),
        }
      : {
          cssWidth: width ? rem(width) : undefined,
          cssHeight: height ? rem(height) : undefined,
        };

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 64 64"
      {...props}
      {...sizes}
      ref={undefined}
    >
      {props.children}
    </StyledSvg>
  );
};
