import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import Livechat from 'src/assets/icons/Livechat';
import { colors } from 'src/config/colors';
import { styledIs } from '@summer/react-kit/functions';
import { StyleProps } from '@summer/react-kit';
import { LivechatContext } from 'src/common/components/LivechatProvider';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const StyledLivechat = styled(Livechat)<{ pending?: boolean }>`
  ${styledIs('pending')(css`
    & > #indicator {
      fill: ${colors.primary} !important;
    }
  `)};
`;

export const LivechatButton: FC<StyleProps> = (props) => {
  const livechat = useContext(LivechatContext);

  return (
    <Button
      kind="box"
      variant="transparent"
      disabled={livechat == null}
      onClick={() => {
        livechat?.open();
        pushEvent(EventName.SalesTools, { help_type: 'chat' });
      }}
      icon={StyledLivechat}
      iconProps={{
        pending: livechat?.pending,
      }}
      {...props}
    />
  );
};
