import React, { FC } from 'react';
import { Badge } from 'src/app/common/models/badge';
import { IconSizesProps } from 'src/lib/IconBox';
import {
  BadgeContainer,
  BadgeVariantType,
} from 'src/app/common/components/BadgeContainer';

export interface BadgesListProps extends IconSizesProps {
  badges: Badge[];
  elements: number;
  variant?: BadgeVariantType;
}

export const BadgesList: FC<BadgesListProps> = ({
  badges,
  elements,
  variant,
}) => {
  return (
    <>
      {badges.map((a, index) => (
        <span key={a}>
          {index < elements && (
            <BadgeContainer badge={a} key={a} variant={variant} />
          )}
        </span>
      ))}
    </>
  );
};
