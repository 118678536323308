import React, { FC } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { colors } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import { StyleProps } from '@summer/react-kit';
import SearchPlus from 'src/assets/icons/SearchPlus';

const CTA = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 50%;
  background: ${transparentize(0.5, colors.grayBasic)};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(CleanButton)`
  position: relative;
  width: 100%;
  height: 100%;

  @media (hover: hover) {
    & > ${CTA} {
      opacity: 0;
    }

    &:hover > ${CTA}, &:focus > ${CTA}, &:active > ${CTA} {
      opacity: 1;
    }
  }
`;

export interface EnlargeWrapperProps extends StyleProps {
  onClick: () => void;
}

export const EnlargeWrapper: FC<EnlargeWrapperProps> = ({
  onClick,
  className,
  style,
  children,
}) => (
  <Container className={className} onClick={onClick} style={style}>
    {children}
    <CTA>
      <SearchPlus size={20} />
    </CTA>
  </Container>
);
