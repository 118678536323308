import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { SimpleLink } from 'src/common/components/SimpleLink';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { TosRoute } from 'src/public/publicRoutes';
import { Title } from 'src/common/components/typography/Title';
import { boolean, ref, string } from 'yup';
import styled, { css } from 'styled-components';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { media } from 'src/config/breakpoints';
import { skewBoth } from 'src/common/styles/skew';
import { Button } from 'src/common/components/Button';
import { Link } from 'gatsby';
import { pipe } from 'fp-ts/pipeable';
import {
  injectRegistrationPath,
  mapToRegisterByCodeDTO,
} from 'src/common/models/auth';
import { registerByCodeActions } from 'src/state/auth/authActions';
import { useDispatch } from 'react-redux';
import { useRequest } from 'src/lib/useRequest';
import { registerBaseValidationSchema } from 'src/lib/validations';
import { PasswordValidationFieldDescription } from 'src/common/components/PasswordValidationFieldDescription';

export interface BusinessPartnerRegisterFormModel {
  firstName: string;
  lastName: string;
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirmation: string;
  code: string;
  tosAgreement: boolean;
  newsletterAgreement: boolean;
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  emailConfirm: '',
  password: '',
  passwordConfirmation: '',
  code: '',
  tosAgreement: false,
  newsletterAgreement: false,
};

const validationSchema = registerBaseValidationSchema.shape({
  passwordConfirmation: string()
    .required('Pole wymagane')
    .oneOf([ref('password'), ''], 'Hasła muszą być takie same'),
  code: string().required('Pole wymagane'),
  tosAgreement: boolean().oneOf([true], 'Pole obowiązkowe'),
  newsletterAgreement: boolean(),
});

const FormContainer = styled(ConstraintContainer)`
  padding: 3.75rem 2rem;

  ${media.w.min.px768(css`
    padding: 3.75rem 5rem;
  `)};
`;

const Wrapper = styled.div`
  ${media.w.min.px1200(css`
    margin-left: 13.875rem;
    max-width: 36.625rem;
    position: relative;

    &:after {
      content: '';
      ${skewBoth('13.5rem')};
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.2) -20.36%,
        rgba(255, 255, 255, 0) 63.77%
      );
      position: absolute;
      z-index: -1;
      left: -10rem;
      top: 10.9375rem;
      height: 44.25rem;
      width: 74.6875rem;
      opacity: 0.3;
    }
  `)};
`;

const Grid = styled.div`
  ${media.w.min.px768(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.875rem;
  `)};
`;

const Actions = styled.div`
  margin-top: 2rem;
`;

const StyledParagraph = styled(Paragraph)`
  font-weight: 700;
`;

export const FormSection: FC<{ path: string }> = ({ path }) => {
  const dispatch = useDispatch();
  const { isLoading } = useRequest(registerByCodeActions.request);

  return (
    <FormContainer>
      <Wrapper>
        <Title level={1} variant="condensed" style={{ margin: 0 }}>
          DOŁĄCZ DO CAR PLATFORM BUSINESS
        </Title>
        <StyledParagraph variant="condensed">
          ZAŁÓŻ KONTO I KORZYSTAJ Z WIELU BENEFITÓW
        </StyledParagraph>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            pipe(
              values,
              injectRegistrationPath(path),
              mapToRegisterByCodeDTO,
              registerByCodeActions.request,
              dispatch
            );
            setSubmitting(false);
          }}
        >
          <Form>
            <Grid>
              <FormItem name="firstName">
                <TextControl name="firstName" label="Twoje imię" />
              </FormItem>
              <FormItem name="lastName">
                <TextControl
                  name="lastName"
                  label="Twoje nazwisko (opcjonalnie)"
                />
              </FormItem>

              <FormItem name="email">
                <TextControl
                  name="email"
                  label="Twój prywatny adres e-mail"
                  type="email"
                />
              </FormItem>
              <FormItem name="emailConfirm">
                <TextControl
                  name="emailConfirm"
                  label="Powtórz adres e-mail"
                  type="email"
                />
              </FormItem>

              <FormItem name="password">
                <div>
                  <TextControl type="password" name="password" label="Hasło" />
                  <PasswordValidationFieldDescription />
                </div>
              </FormItem>
              <FormItem name="passwordConfirmation">
                <TextControl
                  type="password"
                  name="passwordConfirmation"
                  label="Powtórz hasło"
                />
              </FormItem>

              <FormItem name="code">
                <TextControl name="code" label="ID (unikatowy identyfikator)" />
              </FormItem>
            </Grid>

            <FormItem name="tosAgreement">
              <CheckboxControl
                name="tosAgreement"
                label={
                  <Text style={{ fontSize: '0.8125rem' }}>
                    Akceptuję{' '}
                    <SimpleLink as={Link} to={TosRoute}>
                      Regulamin świadczenia usług
                    </SimpleLink>
                    , żeby utworzyć i korzystać z bezpłatnego konta w serwisie
                    Car Platform.
                  </Text>
                }
              />
            </FormItem>

            <Paragraph size="xSmall">
              Udzielenie poniższej zgody jest dobrowolne. Możesz ją wycofać w
              dowolnym momencie, kontaktując się z nami. Wycofanie zgody nie
              wpłynie na zgodność z prawem przetwarzania dokonanego na podstawie
              zgody przed jej wycofaniem.
            </Paragraph>

            <FormItem name="newsletterAgreement">
              <CheckboxControl
                name="newsletterAgreement"
                label="Chcę otrzymywać od Mobility Benefit informacje mailowe i tekstowe o oferowanych usługach, produktach oraz organizowanych promocjach. Aby jak najlepiej dopasować takie informacje do moich potrzeb, Mobility Benefit oceni moje preferencje i aktywności w serwisie."
              />
            </FormItem>

            <Actions>
              <Button
                kind="skew"
                type="submit"
                disabled={isLoading}
                icon={ArrowRight}
              >
                ZAŁÓŻ KONTO
              </Button>
            </Actions>
          </Form>
        </Formik>
      </Wrapper>
    </FormContainer>
  );
};
