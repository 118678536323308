import React, { memo, useMemo, useState } from 'react';
import { Title } from 'src/common/components/typography/Title';
import styled from 'styled-components';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/common/components/Button';
import { unsafeUserSelector } from 'src/state/auth/authSelectors';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { OrderFormModel } from 'src/app/product/models/orderFormModel';
import { User } from 'src/common/models/user';
import { equals, pick } from 'ramda';
import { updateUserActions } from 'src/state/auth/authActions';
import { useRequestEffect } from 'src/lib/useRequestEffect';
import { bookProductActions } from 'src/app/product/state/products/productsActions';
import ArrowRight from 'src/assets/icons/ArrowRight';
import {
  activeOfferSelector,
  activeProductSelector,
} from 'src/app/product/state/products/productsSelectors';
import { capitalize } from 'lodash';
import { CustomerType } from 'src/common/models/customerType';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { isSome } from 'fp-ts/lib/Option';

const getInitialValues = ({
  firstName,
  lastName,
  phone,
  nip,
  carCashAllowanceAgreement,
}: User) => ({
  firstName: firstName ?? '',
  lastName: lastName ?? '',
  phone: phone || '+48',
  nip: nip ?? '',
  companyEmploymentDeclaration: false,
  carCashAllowanceAgreement: carCashAllowanceAgreement ?? false,
  marketingAndAnalyticsAgreement: false,
});

const validationSchema = object().shape({
  firstName: string().required('Pole wymagane'),
  lastName: string().required('Pole wymagane'),
  phone: string()
    .required('Pole wymagane')
    .matches(
      /^(?:[+\d]{0}.[\d\s]*)$/,
      'Numer telefonu może zaczynać się od + oraz musi składać się z samych cyfr i spacji'
    )
    .max(30, 'Numer telefonu powinien składać się z maksymalnie 30 znaków'),
  companyEmploymentDeclaration: boolean().test(
    'isTrue',
    'Oświadczenie jest wymagane w celu złożenia zamówienia',
    (v) => v === true
  ),
});

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const hasUserDataChanged = (
  initialValues: OrderFormModel,
  newValues: OrderFormModel
) => {
  const pickValuesToCompare = pick([
    'firstName',
    'lastName',
    'phone',
    'nip',
    'carCashAllowanceAgreement',
  ]);
  return !equals(
    pickValuesToCompare(initialValues),
    pickValuesToCompare(newValues)
  );
};

export const OrderPageRequestForm = memo<{ onSubmit: () => void }>(
  ({ onSubmit }) => {
    const dispatch = useDispatch();

    const offer = useSelector(activeOfferSelector);
    const product = useSelector(activeProductSelector);
    const user = useSelector(unsafeUserSelector);
    const userCustomerType = useSelector(customerTypeSelector);

    const initialValues = useMemo(() => getInitialValues(user), [user]);
    const [isProcessing, setIsProcessing] = useState(false);

    const bookProductCallback = () =>
      dispatch(bookProductActions.request(offer!));

    useRequestEffect(bookProductActions.request, {
      onSuccess: onSubmit,
      onError: () => setIsProcessing(false),
    });

    useRequestEffect(updateUserActions.request, {
      onSuccess: bookProductCallback,
      onError: () => setIsProcessing(false),
    });

    const handleSubmit = (values: OrderFormModel) => {
      setIsProcessing(true);

      if (hasUserDataChanged(initialValues, values)) {
        dispatch(
          updateUserActions.request({
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            nip: values.nip,
            carCashAllowanceAgreement: values.carCashAllowanceAgreement,
          })
        );
      } else {
        bookProductCallback();
      }

      pushEvent(EventName.ButtonClick, {
        click_category: 'offer_availability_cta',
        click_cta: 'dalej',
      });
      pushEvent(EventName.Purchase, {
        currency: 'PLN',
        transaction_id: Base64.stringify(sha256(user.email + offer?.price)),
        value: offer?.price.toString() ?? '',
        item_list_name: 'order_offer_details',
        items: [
          {
            item_id: offer?.id ?? '',
            item_brand:
              product && isSome(product)
                ? product?.value.brand?.label ?? ''
                : '',
            item_variant:
              userCustomerType === CustomerType.B2C ? 'dla_mnie' : 'dla_firmy',
          },
        ],
      });
    };

    return (
      <section>
        <Paragraph
          variant="condensed"
          style={{ fontSize: '1.125rem', marginBottom: '0.5rem' }}
        >
          {capitalize(user.firstName)}, Twój opiekun skontaktuje się z Tobą,
        </Paragraph>
        <Title level={1} variant="condensed" style={{ margin: 0 }}>
          Aby dobrać najlepszą ofertę
        </Title>
        <Paragraph
          variant="condensed"
          style={{
            fontWeight: 500,
            marginTop: '0.5rem',
            marginBottom: '2rem',
          }}
        >
          i potwierdzić dostępność auta.
        </Paragraph>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormItem name="firstName">
              <TextControl
                name="firstName"
                label="Imię"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'imie' }],
                  });
                }}
              />
            </FormItem>
            <FormItem name="lastName">
              <TextControl
                name="lastName"
                label="Nazwisko"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'nazwisko' }],
                  });
                }}
              />
            </FormItem>
            <FormItem name="phone">
              <TextControl
                name="phone"
                label="Telefon"
                type="tel"
                onClick={() => {
                  pushEvent(EventName.SelectItem, {
                    item_list_name: 'offer_availability_form',
                    items: [{ item_id: 'form_input', item_name: 'phone' }],
                  });
                }}
              />
            </FormItem>
            {userCustomerType === CustomerType.B2B && (
              <FormItem name="nip">
                <TextControl name="nip" label="NIP" />
              </FormItem>
            )}

            <FormItem name="companyEmploymentDeclaration">
              <CheckboxControl
                name="companyEmploymentDeclaration"
                label={`Jestem pracownikiem firmy ${user.businessPartner?.name}`}
              />
            </FormItem>
            <FormItem name="carCashAllowanceAgreement">
              <CheckboxControl
                name="carCashAllowanceAgreement"
                label={
                  <Text style={{ fontSize: '0.8125rem' }}>
                    Chcę otrzymywać benefit w formie{' '}
                    <SimpleLink
                      target="_blank"
                      href="/car-platform-business#mobility"
                    >
                      Karty Mobility 3.0.
                    </SimpleLink>
                    <br />
                    Oświadczam, że zapoznałem się z{' '}
                    <SimpleLink
                      target="_blank"
                      href="/221023_Regulamin_Program_Mobility30.pdf"
                    >
                      Regulaminem Programu Mobility 3.0
                    </SimpleLink>{' '}
                    oraz akceptuję jego treść. Promocja nie dotyczy uczestników
                    innych promocji oferowanych przez partnerów Mobility
                    Benefit.
                  </Text>
                }
              />
            </FormItem>

            <Paragraph size="xSmall">
              Po wysłaniu formularza dostaniesz wiadomość e-mail
              z&nbsp;potwierdzeniem.
              <br />
              Jeżeli go nie otrzymasz sprawdź folder spam lub zignoruj to. Nasz
              konsultant na pewno otrzyma wszelkie niezbędne informacje do
              skontaktowania się z&nbsp;Tobą i&nbsp;porozmawiania na temat
              oferty.
            </Paragraph>

            <Actions>
              <Button
                kind="skew"
                type="submit"
                disabled={offer == null || isProcessing}
                icon={ArrowRight}
              >
                Dalej
              </Button>
            </Actions>
          </Form>
        </Formik>
      </section>
    );
  }
);
