import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { Title } from 'src/common/components/typography/Title';
import { colors } from 'src/config/colors';
import { Button } from 'src/common/components/Button';
import React, { FC } from 'react';
import { ContactRoute } from 'src/public/publicRoutes';
import { lighten } from 'polished';
import { navigate } from 'gatsby';

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-areas:
    'hero-title'
    'hero-button';
  grid-row-gap: 3.75rem;

  ${media.w.min.px992(css`
    grid-row-gap: 6.75rem;
  `)};
`;

const HeroTitle = styled(Title)`
  grid-area: hero-title;
  color: ${colors.white};
  text-transform: uppercase;
  width: 100%;
  max-width: 20rem;
  margin: 37vh 0 0;

  ${media.w.min.px768(css`
    margin: 3.75rem 0 0 3.75rem;
    max-width: 30rem;
  `)};

  ${media.w.between.px768.px992(css`
    margin-top: 8.75rem;
  `)}

  ${media.w.min.px992(css`
    margin-top: 5.75rem;
  `)};
`;

const ButtonContainer = styled.div`
  grid-area: hero-button;
  display: flex;

  ${media.w.max.px768(css`
    flex-direction: column;
  `)};

  & > .btn {
    width: 100%;

    ${media.w.min.px576(css`
      width: auto;
      margin-left: 1rem;
    `)};

    ${media.w.min.px768(css`
      margin-top: 1rem;
      margin-bottom: 2.5rem;
    `)};

    & > .btn__content {
      justify-content: center;
    }

    &:first-child {
      @media (hover: hover) {
        &:hover:not(:focus):not(:active):not(:disabled) {
          filter: none;

          & > .btn__content {
            background: linear-gradient(
              to right,
              ${colors.primary},
              ${lighten(0.2, colors.primary)}
            );
          }
        }
      }
    }
  }
`;

export const HeroSection: FC<{ onPrimaryButtonClick: () => void }> = ({
  onPrimaryButtonClick,
}) => (
  <Container>
    <HeroTitle level={1} responsive={true} variant="condensed">
      DOŁĄCZ DO strefy CAR PLATFORM BUSINESS I ZAOFERUJ PRACOWNIKOM MOBILNOŚĆ
      3.0
    </HeroTitle>
    <ButtonContainer>
      <Button kind="skew" onClick={onPrimaryButtonClick}>
        DOWIEDZ SIĘ WIĘCEJ
      </Button>
      <Button
        kind="skew"
        variant="transparent"
        onClick={() =>
          navigate(ContactRoute, { state: { carCashAllowance: true } })
        }
      >
        DOŁĄCZ SWOJĄ FIRMĘ
      </Button>
    </ButtonContainer>
  </Container>
);
