import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="15 0 34 64" {...props}>
    <path d="M39.267 12.7715C39.267 11.7285 38.4753 10.8824 37.4995 10.8824H26.5194C25.5439 10.8824 24.752 11.7285 24.752 12.7715V13.7147H39.267V12.7715Z" />
    <path d="M39.267 14.3034H24.752V16.461H39.267V14.3034Z" />
    <path d="M32.0096 23.4031C31.4523 23.4031 30.9999 22.9199 30.9999 22.3243C30.9999 21.7287 31.4523 21.243 32.0096 21.243C32.5666 21.243 33.0191 21.7287 33.0191 22.3243C33.0191 22.9199 32.5666 23.4031 32.0096 23.4031ZM39.267 24.8372V17.0864H24.752V24.8372C24.752 25.3587 24.9496 25.8316 25.2691 26.1741L25.355 26.2585C25.8006 26.6751 26.4414 26.7493 26.9846 26.4962C28.0992 25.9773 29.9405 25.328 32.2622 25.3765C34.3341 25.42 35.9839 26.008 37.0384 26.4988C37.6136 26.7672 38.2935 26.6522 38.7409 26.1818C39.0653 25.8393 39.267 25.3638 39.267 24.8372Z" />
    <path d="M45.9743 48.628L46.5265 48.4389L46.7852 47.9123L46.0541 45.7829L43.8344 46.5447C44.2767 48.2344 43.348 50.0059 41.6685 50.5811C39.989 51.1588 38.1676 50.3306 37.4794 48.7252L35.2598 49.487L35.9906 51.6164L36.518 51.8746L37.0712 51.6854C37.5344 51.5243 38.0387 51.7723 38.1985 52.235L38.7256 52.4932L45.1655 50.2845L45.4232 49.7554C45.2644 49.2927 45.5109 48.7891 45.9743 48.628Z" />
    <path d="M35.1648 26.3579C34.2261 26.1202 33.2636 25.9898 32.2858 25.9694C31.9065 25.9617 31.5261 25.9744 31.1465 26C31.9954 28.1933 33.3643 32.015 33.8917 33.5488C35.2916 37.6236 36.1584 41.2945 36.7085 44.3135L36.0135 44.5512L35.755 45.0804C35.9148 45.5431 35.6681 46.0492 35.2039 46.2077L34.6515 46.3969L34.394 46.926L35.1239 49.0555L37.3438 48.2911C36.9013 46.604 37.831 44.8324 39.5105 44.2547C41.19 43.677 43.0106 44.5052 43.6995 46.1106L45.9192 45.3488L45.1883 43.2219L44.6602 42.9612L44.108 43.1529C43.6446 43.3114 43.1392 43.0635 42.9805 42.6008L42.4523 42.3426L41.5366 42.657C40.1166 39.9371 38.547 36.5065 37.15 32.4317C36.7336 31.2149 35.8499 28.5385 35.1648 26.3579Z" />
    <path d="M32.019 42.6987C33.37 42.6987 34.5792 43.3046 35.3921 44.2581L35.8106 43.9845L36.0181 43.913C35.401 40.6588 34.5513 37.4173 33.4917 34.2577C33.0086 34.2041 32.5173 34.1734 32.019 34.1734C24.8624 34.1734 19.0605 39.9762 19.0605 47.1314C19.0605 54.2891 24.8624 60.0894 32.019 60.0894C37.7516 60.0894 42.6147 56.3674 44.3223 51.2061L38.9249 53.0595L38.5975 53.1131C38.1769 53.1131 37.7835 52.8499 37.6399 52.4306L37.2705 52.2491L36.7173 52.4383C36.1899 52.6198 35.6127 52.3386 35.432 51.812L34.9626 50.4444C34.1799 51.1422 33.1494 51.564 32.019 51.564C29.5706 51.564 27.5864 49.5803 27.5864 47.1314C27.5864 44.685 29.5706 42.6987 32.019 42.6987Z" />
    <path d="M38.0357 48.4974C38.604 49.8242 40.1179 50.4939 41.4827 50.0261C42.8478 49.5557 43.6308 48.0986 43.2662 46.7029L43.2182 46.5214L43.1443 46.3501C42.5763 45.0234 41.0614 44.3511 39.6974 44.8215C38.3313 45.2893 37.5485 46.7489 37.9138 48.1447L37.9619 48.3262L38.0357 48.4974Z" />
    <path d="M42.2403 41.7776C42.7687 41.5961 43.3447 41.8799 43.5264 42.4065L43.6734 42.5727L43.896 42.588L44.1177 42.5113C43.1611 40.0112 41.4525 37.8793 39.2676 36.3992C40.0416 38.2806 40.8903 40.1288 41.8109 41.9259L42.2403 41.7776Z" />
    <path d="M41.3981 32.961V2.955C41.3981 1.81488 41.083 -0.000122325 39.0294 0.053552L38.108 0.388375L37.376 0.81713L38.108 1.34699C40.0252 1.347 40.0082 2.40028 40.0082 2.955V4.93103H38.1058C37.7024 4.93103 37.376 5.2557 37.376 5.6596C37.376 6.0635 37.7024 6.39067 38.1058 6.39067H40.0082V32.1456L39.3361 31.8132C38.9885 31.6496 38.5751 31.7979 38.4123 32.1481C38.2497 32.4958 38.3995 32.9074 38.7469 33.071C39.2822 33.3215 39.8083 33.6052 40.3137 33.9145L40.3277 33.9222H40.3288C44.8312 36.8032 47.5192 41.7088 47.5192 47.0438C47.5192 55.6279 40.5374 62.6092 31.9548 62.6092C23.3719 62.6092 16.3901 55.6279 16.3901 47.0438C16.3901 41.7088 19.0783 36.8032 23.5808 33.9222L23.8584 33.7433C24.3157 33.4723 24.7889 33.2192 25.2692 32.9943C25.6179 32.8307 25.7677 32.4165 25.6049 32.0689C25.442 31.7212 25.0287 31.573 24.6803 31.734C24.4185 31.8567 24.159 31.9896 23.9014 32.1251V6.39067H25.9114C26.3138 6.39067 26.6415 6.0635 26.6415 5.6596C26.6415 5.2557 26.3138 4.93103 25.9114 4.93103H23.9014V2.955C23.9014 2.39261 23.9014 1.34699 25.4929 1.30107L38.108 1.34699L39.0294 0.053552L24.9937 -0.000122325C22.98 0.053552 22.5112 1.78676 22.5112 2.955V32.961C17.8002 36.1232 15 41.3586 15 47.0438C15 56.3923 22.6061 63.9999 31.9548 63.9999C41.3032 63.9999 48.9093 56.3923 48.9093 47.0438C48.9093 41.3586 46.1083 36.1232 41.3981 32.961Z" />
  </IconBox>
);
