import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0001 56.9204c13.7636 0 24.9212-11.1577 24.9212-24.9213 0-13.7636-11.1576-24.92117-24.9212-24.92117-13.7636 0-24.92119 11.15757-24.92119 24.92117S18.2365 56.9204 32.0001 56.9204zm0 3.2c15.5309 0 28.1212-12.5903 28.1212-28.1213 0-15.5309-12.5903-28.12117-28.1212-28.12117-15.5309 0-28.12119 12.59027-28.12119 28.12117 0 15.531 12.59029 28.1213 28.12119 28.1213z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 60.8c15.9058 0 28.8-12.8942 28.8-28.8C60.8 16.0942 47.9058 3.2 32 3.2 16.0942 3.2 3.2 16.0942 3.2 32c0 15.9058 12.8942 28.8 28.8 28.8zm0 3.2c17.6731 0 32-14.3269 32-32C64 14.3269 49.6731 0 32 0 14.3269 0 0 14.3269 0 32c0 17.6731 14.3269 32 32 32z"
    />
    <path d="M42.5173 26.3371v-4.6848H24.1621v5.0688h12.7488c-2.56 3.0976-4.6336 6.5024-6.2208 10.2144-1.5616 3.712-2.496 7.5776-2.8032 11.5968h5.8368c.0256-1.792.2304-3.7248.6144-5.7984.4096-2.0736.9728-4.1216 1.6896-6.144.7424-2.0224 1.6512-3.9296 2.7264-5.7216 1.1008-1.792 2.3552-3.3024 3.7632-4.5312z" />
  </IconBox>
);
