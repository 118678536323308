import { createReducer } from '@reduxjs/toolkit';
import { businessPartnerActions } from 'src/state/businessPartner/businessPartnerActions';
import { BusinessPartner } from 'src/common/models/businessPartner';

export interface BusinessPartnerState {
  businessPartner?: BusinessPartner;
}

const initialState: BusinessPartnerState = {};

export const businessPartnerReducer = createReducer<BusinessPartnerState>(
  initialState,
  (builder) =>
    builder.addCase(businessPartnerActions.requestSuccess, (state, action) => {
      state.businessPartner = action.payload;
    })
);
