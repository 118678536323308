import React, { FC } from 'react';
import styled from 'styled-components';
import { CleanUnorderedList } from 'src/common/components/List';
import { Button } from 'src/common/components/Button';
import { StyleProps } from '@summer/react-kit';
import {
  AboutUsRoute,
  ContactRoute,
  FaqRoute,
  HowItWorksRoute,
  LoginRoute,
  PrivacyPolicyAndCookiesRoute,
  TosRoute,
} from 'src/public/publicRoutes';
import { Link } from 'gatsby';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';

interface FooterMenuItem {
  label: string;
  url: string;
}

const footerMenus: FooterMenuItem[][] = [
  [
    { label: 'Jak działa CarPlatform?', url: HowItWorksRoute },
    { label: 'Pomoc i kontakt', url: ContactRoute },
    { label: 'FAQ', url: FaqRoute },
  ],
  [
    { label: 'Moje konto', url: LoginRoute },
    { label: 'O Car Platform', url: AboutUsRoute },
  ],
  [
    { label: 'Polityka prywatności', url: PrivacyPolicyAndCookiesRoute },
    { label: 'Polityka cookies', url: PrivacyPolicyAndCookiesRoute },
    { label: 'Regulamin', url: TosRoute },
  ],
];

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3vw;
`;

const MenuList = styled(CleanUnorderedList)`
  margin: 0;
  font-size: 0.875rem;
  color: ${colors.grayBasic};
  font-family: ${typography.fontFamilyDefault};
`;

export const FooterNav: FC<StyleProps> = (props) => (
  <Nav {...props}>
    {footerMenus.map((subMenu, index) => (
      <MenuList key={index}>
        {subMenu.map(({ label, url }) => (
          <li key={label}>
            <Button kind="link" variant="transparent" as={Link} to={url}>
              {label}
            </Button>
          </li>
        ))}
      </MenuList>
    ))}
  </Nav>
);
