import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Seo } from 'src/public/components/Seo';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { AppHeader } from 'src/common/components/AppHeader';
import { HeroSection } from 'src/public/components/car-platform-business/HeroSection';
import { HowToStartSection } from 'src/public/components/car-platform-business/HowToStartSection';
import { ContactSection } from 'src/public/components/car-platform-business/ContactSection';
import { Footer } from 'src/public/components/Footer';
import { ScrollContext } from 'src/common/contexts/ScrollContext';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import styled, { css } from 'styled-components';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import heroSectionImageMobile from 'src/assets/images/new-hero-section-image-mobile.jpg';
import heroSectionImage from 'src/assets/images/new-hero-section-image.jpg';
import { CompanyBenefitsSection } from 'src/public/components/car-platform-business/CompanyBenefitsSection';
import { MobilityCardSection } from 'src/public/components/car-platform-business/MobilityCardSection';
import { GeneralBenefitsSection } from 'src/public/components/car-platform-business/GeneralBenefitsSection';
import { AboutCarPlatformSection } from 'src/public/components/car-platform-business/AboutCarPlatformSection';
import { Button, CleanButton } from 'src/common/components/Button';
import ArrowsDown from 'src/assets/images/doubled-chevron-down.svg';
import { Link, navigate } from 'gatsby';
import { HomePageRoute } from 'src/app/routes';
import { useMediaQuery } from 'react-responsive';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { LoginRoute } from 'src/public/publicRoutes';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { rgba } from 'polished';
import { CarPlatformBusinessBenefitsModal } from 'src/common/components/CarPlatformBusinessBenefitsModal';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

const FullPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.section`
  padding-top: 1.125rem;
  padding-bottom: 0.5rem;
  width: 100%;
  color: ${colors.white};

  ${media.w.min.px768(css`
    padding-top: 2.5rem;
  `)};
`;

const BackContainer = styled.div`
  display: flex;
`;

const BackButtonContent = styled.span`
  font-size: 0.625rem;
`;

const StyledChevronLeft = styled(ChevronLeft)`
  margin-right: 0.5rem;
`;

const DarkBg = styled.div`
  background-color: ${colors.background};
  position: relative;
  max-height: 80vh;
`;

const HeroSectionContainer = styled(ConstraintContainer)`
  height: 100%;
  position: relative;
  z-index: 1;

  background: url(${heroSectionImageMobile}) no-repeat center;
  background-size: cover;

  ${media.w.min.px768(css`
    background: url(${heroSectionImage}) right top no-repeat;
    background-size: auto 85%;
  `)};

  ${media.w.min.px1200(css`
    background-size: auto 110%;
  `)};
`;

const HeroSectionBackgroundGradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(42, 43, 50, 0) 6.53%,
    #2a2b32 47.38%
  );
  position: absolute;
  top: auto;
  bottom: 0;
  z-index: 1;
  height: 60%;
  width: 100%;

  ${media.w.max.px768(css`
    left: 0;
  `)};

  ${media.w.min.px768(css`
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(42, 43, 50, 0) 0%,
      #2a2b32 100%
    );
    right: 0;
    bottom: -1px;
    height: 45.5rem;
    width: 45.5rem;
  `)}

  ${media.w.min.px1200(css`
    height: 62.5rem;
    width: 62.5rem;
  `)};
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
`;

const AboutCarPlatformContainer = styled.div`
  position: relative;
`;

const GradientContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10rem;

  ${media.w.min.px992(css`
    left: 18rem;
  `)};

  ${media.w.min.px1200(css`
    top: -2.5rem;
  `)};
`;

const GradientWrapper = styled.div`
  position: relative;
`;

const GradientMiddleTile = styled.div`
  clip-path: polygon(8rem 0, 100% 9.2rem, 3rem 100%, 0 calc(100% - 9.2rem));
  height: 25.5625rem;
  width: 12.27375rem;
  background: linear-gradient(
    -70deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
  opacity: 0.5;
  margin-top: -9.2rem;
  margin-left: 10rem;

  ${media.w.min.px768(css`
    clip-path: polygon(
      13.25rem 0,
      100% 16.875rem,
      11.5rem 100%,
      0 calc(100% - 16.875rem)
    );
    height: 47rem;
    width: 22.5625rem;
    margin-top: -16.875rem;
    margin-left: 20rem;
  `)};
`;

const GradientTopTile = styled.div`
  clip-path: polygon(4.5rem 0, 100% 0, calc(100% - 4.5rem) 100%, 0 100%);
  height: 9.2rem;
  width: 18.068125rem;
  background: linear-gradient(
    -30deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
  opacity: 0.5;

  ${media.w.min.px768(css`
    clip-path: polygon(11.5rem 0, 100% 0, calc(100% - 11.5rem) 100%, 0 100%);
    height: 16.875rem;
    width: 33.25rem;
  `)};
`;

const GradientLowTile = styled.div`
  clip-path: polygon(0 0, calc(100% - 3rem) 0, 100% 100%, 3rem 100%);
  height: 9.2rem;
  width: 22.025rem;
  background: linear-gradient(
    -30deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
  opacity: 0.5;
  position: absolute;
  left: -9rem;
  bottom: 0;

  ${media.w.min.px768(css`
    clip-path: polygon(0 0, calc(100% - 11.5rem) 0, 100% 100%, 11.5rem 100%);
    height: 16.875rem;
    width: 40.5rem;
  `)};
`;

const HowToStartContainer = styled.div`
  position: relative;
`;

const HowToStartGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(
    357.91deg,
    #ffffff 45.25%,
    rgba(255, 255, 255, 0.555558) 59.04%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.1;

  ${media.w.min.px992(css`
    background: linear-gradient(
      357.91deg,
      #ffffff 45.25%,
      rgba(255, 255, 255, 0.555558) 59.04%,
      rgba(255, 255, 255, 0) 87.07%
    );
  `)};
`;

const ScrollButton = styled(Button)`
  ${media.w.max.px992(css`
    display: none;
  `)};

  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: -20%;
  margin-left: auto;
  margin-right: auto;
  display: block;

  & > .btn__content {
    border: none;
    border-radius: 50%;
    height: 2.5625rem;
    width: 2.5625rem;
    justify-content: center;
  }
`;

const HeaderChildrenWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
`;

const LoginButton = styled(Button)`
  font-weight: 700;
  text-align: left;
`;

const CarPlatformBusinessPage = () => {
  useStorePageUrl();

  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });
  const aboutCarPlatformRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  const [scrollRef, setScrollRef] = useState<OverlayScrollbarsComponent | null>(
    null
  );

  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLogin = () => {
    navigate(LoginRoute);
    pushEvent(EventName.TopMenu, {
      menu_type: 'text_menu',
      menu_item: 'login',
    });
  };

  useEffect(() => {
    const elements = scrollRef?.osInstance()?.getElements();

    if (elements) {
      elements.viewport.tabIndex = -1;
      elements.viewport.focus();
    }
  }, [scrollRef]);

  return (
    <>
      <ScrollContext.Provider value={scrollRef}>
        <FullPageLayout>
          <Seo title="Car Platform Business" />

          <ScrollContainer ref={setScrollRef} style={{ height: '100%' }}>
            <div>
              <main>
                <DarkBg>
                  <HeroSectionContainer>
                    <HeroSectionBackgroundGradient />
                    <HeroContent>
                      <HeaderWrapper>
                        <AppHeader logoVariant={'light'}>
                          <HeaderChildrenWrapper>
                            {isMobile && (
                              <>
                                {isLoggedIn ? (
                                  <Button
                                    size="small"
                                    kind="box"
                                    variant="transparent"
                                    onClick={() => setShowModal(true)}
                                    style={{ fontSize: '0.75rem' }}
                                  >
                                    TWOJE <br /> BONUSY
                                  </Button>
                                ) : (
                                  <LoginButton
                                    kind="box"
                                    size="small"
                                    variant="transparent"
                                    onClick={handleLogin}
                                  >
                                    {/* ZAREJESTRUJ <br /> / ZALOGUJ */}
                                    ZALOGUJ
                                  </LoginButton>
                                )}
                              </>
                            )}
                          </HeaderChildrenWrapper>
                        </AppHeader>
                        {!isMobile && (
                          <BackContainer>
                            <CleanButton as={Link} to={HomePageRoute}>
                              <Button kind="box" variant="transparent">
                                <StyledChevronLeft size={8} />
                                <BackButtonContent>
                                  STRONA GŁÓWNA
                                </BackButtonContent>
                              </Button>
                            </CleanButton>
                          </BackContainer>
                        )}
                      </HeaderWrapper>
                      <HeroSection
                        onPrimaryButtonClick={() =>
                          scrollToSection(aboutCarPlatformRef)
                        }
                      />
                    </HeroContent>
                  </HeroSectionContainer>
                  <ScrollButton
                    kind="box"
                    variant="gray"
                    icon={ArrowsDown}
                    onClick={() => scrollToSection(aboutCarPlatformRef)}
                  />
                </DarkBg>
                <AboutCarPlatformContainer ref={aboutCarPlatformRef}>
                  <GradientContainer>
                    <GradientWrapper>
                      <GradientTopTile />
                      <GradientMiddleTile />
                      <GradientLowTile />
                    </GradientWrapper>
                  </GradientContainer>
                  <AboutCarPlatformSection />
                </AboutCarPlatformContainer>
                <GeneralBenefitsSection />
                <div id="mobility">
                  <MobilityCardSection />
                </div>
                <CompanyBenefitsSection />
                <HowToStartContainer>
                  <HowToStartGradient />
                  <HowToStartSection />
                </HowToStartContainer>
                <ContactSection />
                <Footer />
              </main>
            </div>
          </ScrollContainer>
        </FullPageLayout>
      </ScrollContext.Provider>

      <ModalPortal
        open={showModal}
        onClose={onClose}
        background={rgba(colors.background, 0.8)}
        blur={10}
      >
        <CarPlatformBusinessBenefitsModal onClose={onClose} />
      </ModalPortal>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default CarPlatformBusinessPage;
