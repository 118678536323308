import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Button } from 'src/common/components/Button';
import { AboutUsRoute } from 'src/public/publicRoutes';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { skewLeft } from 'src/common/styles/skew';
import { PorscheInterAuto } from 'src/common/components/PorscheInterAuto';
import { LexusPartner } from 'src/common/components/LexusPartner';
import { HondaPartner } from 'src/common/components/HondaPartner';
import { media } from 'src/config/breakpoints';
import { AutoFusGroupPartner } from 'src/common/components/AutoFusGroupPartner';
import { ATPartner } from 'src/common/components/ATPartner';
import BemoGroupPartner from 'src/assets/images/bemo-group.png';
import IgmarPartner from 'src/assets/images/igmar.png';
import BACPartner from 'src/assets/images/bac-partner.png';
import ToyotaPartner from 'src/assets/images/toyota-partner.png';
import VolvoPartner from 'src/assets/images/volvo-partner.png';
import { StyleProps } from '@summer/react-kit';

const partners = [
  { content: <AutoFusGroupPartner /> },
  { content: <PorscheInterAuto /> },
  { content: <ATPartner /> },
  { content: <img src={BACPartner} /> },
  { content: <LexusPartner /> },
  { content: <img src={BemoGroupPartner} /> },
  { content: <img src={IgmarPartner} /> },
  { content: <HondaPartner /> },
  { content: <img src={ToyotaPartner} /> },
  { content: <img src={VolvoPartner} /> },
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'title' 'partners';

  ${media.w.min.px1200(css`
    grid-template-columns: minmax(min-content, 8.125rem) 1fr;
    grid-template-areas: 'title partners';
    background-color: ${colors.background};
    align-items: center;
    height: 100%;
  `)};

  ${media.w.min.px1366(css`
    padding-left: 3rem;
    ${skewLeft('2.5rem')}
  `)};
`;

const Title = styled.div`
  grid-area: title;
  padding: 0.825rem 0;
  text-align: center;

  ${media.w.min.px1200(css`
    padding: 0;
  `)};
`;

export const PartnersList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.w.min.px576(css`
    display: grid;
    grid-template-columns: repeat(5, min-content);
  `)};

  ${media.w.min.px1200(css`
    grid-template-columns: repeat(10, min-content);
  `)};
`;

export const Partners: FC<StyleProps> = ({ children, className }) => (
  <Container className={className}>
    <Title>{children}</Title>
    <PartnersList>
      {partners.map((partner, index) => (
        <Button
          key={index}
          kind="box"
          variant="transparent"
          as={Link}
          to={AboutUsRoute}
        >
          {partner.content}
        </Button>
      ))}
    </PartnersList>
  </Container>
);
