import {
  getOrElse,
  isSome,
  map,
  mapNullable,
  Option,
  Some,
  toNullable,
} from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { AuthState } from 'src/state/auth/authReducer';
import { RootState } from 'src/state/reducer';
import { User } from 'src/common/models/user';
import { pipe } from 'fp-ts/lib/pipeable';

export const authStateSelector = (state: RootState) => state.auth;

export const tokenSelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.token
);

export const isAuthenticatedSelector = createSelector(
  tokenSelector,
  (token: Option<string>) => isSome(token)
);

export const registerByCodeTokenSelector = createSelector(
  authStateSelector,
  (state) => state.registerByCodeToken
);

export const userSelector = createSelector(
  authStateSelector,
  (state) => state.user
);

export const unsafeUserSelector = createSelector(
  userSelector,
  (state) => (state as Some<User>).value
);

export const userFirstNameSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    map((a) => a.firstName),
    getOrElse(() => '')
  )
);

export const userPhoneSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    mapNullable((a) => a.phone),
    getOrElse(() => '')
  )
);

export const userCompanyEmailSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    map((a) => a.companyEmail),
    getOrElse(() => '')
  )
);

export const userEmailSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    map((a) => a.email),
    getOrElse(() => '')
  )
);

export const userBusinessPartnerNameSelector = createSelector(
  userSelector,
  (state) =>
    pipe(
      state,
      mapNullable((a) => a.businessPartner),
      map((a) => a.name),
      getOrElse(() => '')
    )
);

export const userIdSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    map((a) => a.id),
    getOrElse(() => 'undefined')
  )
);

export const userCustomerTypeSelector = createSelector(userSelector, (state) =>
  pipe(
    state,
    map((a) => a.customerType),
    toNullable
  )
);
