import React, { FC } from 'react';
import { CleanButton } from 'src/common/components/Button';
import { useDerivedState } from '@summer/react-kit/hooks';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { StyleProps } from '@summer/react-kit';
import { typography } from 'src/config/typography';

export type ToggleButtonVariant = 'primary' | 'secondary';

const StyledToggleButton = styled(CleanButton)<{
  variant?: ToggleButtonVariant;
  active?: boolean;
  disabled?: boolean;
}>`
  border: 1px solid ${colors.darkGray};
  padding: 0.4375rem 0.875rem;
  font-family: ${typography.fontFamilyDefault};
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  min-height: 2.25rem;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        @media (hover: hover) and (pointer: fine) {
          background: ${colors.stormGray};
          color: ${colors.white};
        }
      }

      &:active {
        color: ${colors.background};
        background: ${colors.white};
      }
    `};

  ${({ active }) =>
    active &&
    css`
      color: ${colors.background};
      background: ${colors.white};
    `};

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.6;
    `};
`;

export interface ToggleButtonProps extends StyleProps {
  initialValue?: boolean;
  value?: boolean;
  onChange?: (v: boolean) => void;
  disabled?: boolean;
  variant?: ToggleButtonVariant;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  initialValue,
  value,
  onChange,
  className,
  style,
  variant = 'primary',
  disabled,
  children,
}) => {
  const [active, setActive] = useDerivedState(initialValue ?? false, {
    value,
    onChange,
  });

  return (
    <StyledToggleButton
      className={className}
      style={style}
      active={active}
      variant={variant}
      onClick={() => setActive((currentValue: boolean) => !currentValue)}
      disabled={disabled}
    >
      {children}
    </StyledToggleButton>
  );
};

export const TransparentToggleButton = styled(ToggleButton)`
  border: 3px solid transparent;
`;
