import React, { FC } from 'react';
import styled from 'styled-components';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { StyleProps } from '@summer/react-kit';

export interface ProductOfferTilePriceProps {
  price: number;
}

const Container = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
`;

const Value = styled.span`
  font-size: 2rem;
`;

export const ProductOfferTilePrice: FC<
  StyleProps & ProductOfferTilePriceProps
> = ({ style, className, price }) => (
  <Container style={style} className={className}>
    <span style={{ marginRight: 4 }}>już za</span>
    <Value>{localFormatNumber(price)}</Value>
    <span style={{ marginLeft: 4 }}>{`zł/mc`}</span>
  </Container>
);
