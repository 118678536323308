import React, { memo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { ProductPrice } from 'src/app/product/components/ProductPrice';
import { Button } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { useSelector } from 'react-redux';
import { activeOfferSelector } from 'src/app/product/state/products/productsSelectors';
import { ProductPageNoOffers } from 'src/app/product/components/ProductPageNoOffers';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { skewLeft } from 'src/common/styles/skew';
import { ProductSummaryOfferDisclaimerTooltip } from 'src/app/product/components/ProductPageSummary/ProductSummaryOfferTooltip';
import { Flex } from '@summer/react-kit';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { CarPlatformBusinessModal } from 'src/app/product/components/ProductPageSummary/CarPlatformBusinessModal';

const Container = styled.div`
  bottom: 0;
  order: 4;
  position: sticky;
  z-index: 1000;
`;

const SummaryContainer = styled(ConstraintContainer)`
  height: 4rem;
  display: flex;
  align-items: center;
  background: ${colors.primary};
  color: ${colors.white};
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;

  ${media.w.min.px768(css`
    flex-direction: row;
  `)}
`;

const Label = styled.div`
  color: ${colors.white};
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.15;
  margin-right: 0.5rem;

  ${media.w.min.px768(css`
    font-size: 0.75rem;
    line-height: 1.875rem;
    margin-right: 0.9375rem;
  `)}
`;

const StyledProductPrice = styled(ProductPrice)`
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: -1rem;

  &.btn-skew.btn-small > .btn__content {
    ${skewLeft('1.4375rem')};
    padding-right: 1.1875rem;
    font-size: 0.8125rem;
  }
`;

const CarPlatformBusinessButtonContainer = styled(ConstraintContainer)`
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.background};
`;

const CarPlatformBusinessButton = styled(Button)`
  .btn__content {
    font-size: 0.8125rem;
    font-weight: bold;
  }
`;

export interface ProductPageMobileBarProps {
  isSummaryVisible: boolean;
  onButtonClick: () => void;
  setModalOpen: () => void;
}

export const ProductPageMobileBar = memo<ProductPageMobileBarProps>(
  ({ isSummaryVisible, onButtonClick, setModalOpen }) => {
    const selectedOffer = useSelector(activeOfferSelector);

    const [showModal, setShowModal] = useState(false);
    const onClose = useCallback(() => setShowModal(false), [setShowModal]);

    return (
      <Container>
        <SummaryContainer>
          {selectedOffer && (
            <Flex onClick={() => setModalOpen()}>
              <StyledFlex>
                <Label>Twoja rata</Label>
                <StyledProductPrice offer={selectedOffer} discountBadge />
              </StyledFlex>
              <ProductSummaryOfferDisclaimerTooltip />
            </Flex>
          )}

          {!selectedOffer && <ProductPageNoOffers />}

          <StyledButton
            disabled={!selectedOffer && isSummaryVisible}
            onClick={onButtonClick}
            kind="skew"
            size="small"
          >
            SPRAWDŹ DOSTĘPNOŚĆ
          </StyledButton>
        </SummaryContainer>

        <CarPlatformBusinessButtonContainer>
          <CarPlatformBusinessButton
            kind="link"
            variant="secondary"
            onClick={() => setShowModal(true)}
          >
            CAR PLATFORM BUSINESS
          </CarPlatformBusinessButton>

          <ModalPortal
            open={showModal}
            onClose={onClose}
            background={colors.background}
          >
            <CarPlatformBusinessModal onClose={onClose} />
          </ModalPortal>
        </CarPlatformBusinessButtonContainer>
      </Container>
    );
  }
);
