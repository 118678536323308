import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { LabelRoboto } from 'src/app/common/components/typography/Label';
import { Divider } from 'src/app/common/components/Divider';
import { HomePanelFilterSlider } from 'src/app/home/components/HomePanel/HomePanelFilterSlider';
import styled, { css } from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { rem } from 'polished';
import { colors } from 'src/config/colors';
import { acceleratedEasing } from 'src/common/styles/animations';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import {
  bodiesListSelector,
  brandsListSelector,
  categoriesListSelector,
} from 'src/app/state/commonFilters/commonFiltersSelectors';
import { Icon } from 'src/common/components/Icon';
import { ExoticFilter, OptionFilter } from 'src/app/common/models/filter';
import {
  FilterBoxToggleButton,
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  bodyIconMapper,
  brandIconMapper,
  brandIconResizeRates,
  categoryIconMapper,
} from 'src/app/product/services/productMappers';
import { Skeleton } from 'src/common/components/Skeleton';
import { useMediaQuery } from 'react-responsive';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import CloseBold from 'src/assets/icons/CloseBold';
import { HomePanelFiltersState } from 'src/app/home/components/HomePanel/HomePanelContent';
import {
  pushBodiesChoiceEvent,
  pushCategoriesChoiceEvent,
} from 'src/app/home/services/homeGoogleTagManagerEvents';
import { StyledBrandTypography } from 'src/app/home/components/HomePanel/HomePanelFiltersTab/styles';
import { BrandAutocomplete } from 'src/app/home/components/HomePanel/HomePanelFiltersTab/BrandAutocomplete';
import { useSelector } from 'react-redux';
import { BrandMotorcycleAutocomplete } from 'src/app/home/components/HomePanel/HomePanelFiltersTab/BrandMotorcycleAutocomplete';

const FilterTabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  ${media.w.min.px768(css`
    margin-bottom: 1rem;
  `)}
`;

const Tab = styled(CleanButton)<{ active?: boolean }>`
  text-align: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-grow: 1;
  color: ${colors.white};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: all 200ms ${acceleratedEasing};

  &:hover,
  &:focus {
    color: ${colors.primary};
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  max-width: 100%;
  margin-bottom: 0.625rem;
`;

const TabItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, max-content);
  width: 100%;
  grid-column-gap: 0.125rem;
  grid-row-gap: 0.125rem;
  justify-content: space-between;

  ${media.w.min.px992(css`
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
  `)}
`;

const DesktopTabs = styled.div`
  ${media.w.max.px768(css`
    display: none;
  `)}
`;
const MobileTabs = styled.div`
  ${media.w.min.px768(css`
    display: none;
  `)}
`;

const StyledLabelRoboto = styled(LabelRoboto)`
  font-size: 0.75rem;
  margin-bottom: 0;
`;

const ToggleButton = styled(FilterBoxToggleButton)`
  width: 4.375rem;
  height: 4.375rem;
  min-width: 4.375rem;
  border-radius: 2px;
`;

// const SmallToggleButton = styled(FilterBoxToggleButton)`
//   width: 3.5rem;
//   height: 3.5rem;
//   min-width: 3.5rem;
//   border-radius: 2px;
// `;

const ResetButtonContainer = styled.div<{
  visible?: boolean;
}>`
  ${({ visible }) =>
    !visible &&
    css`
      visibility: hidden;
    `};

  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  display: flex;
  height: 1.25rem;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${colors.primary};
  }
`;

const ResetButton: FC<{ onClick: () => void; visible: boolean }> = ({
  onClick,
  visible,
}) => (
  <ResetButtonContainer
    className="clickable"
    onClick={onClick}
    visible={visible}
    tabIndex={0}
  >
    <CloseBold size={12} />
  </ResetButtonContainer>
);

type FilterTab = OptionFilter.Category | OptionFilter.Body | OptionFilter.Brand;

interface HomePanelFiltersTabsProps {
  onTabChange?: () => void;
  state: HomePanelFiltersState;
  changeValue: (
    value: string[] | any,
    kind: OptionFilter | ExoticFilter
  ) => void;
  reset: (kind: OptionFilter) => void;
}

export const HomePanelFiltersTabs = memo<HomePanelFiltersTabsProps>(
  ({ onTabChange, state, changeValue, reset }) => {
    const [activeFilter, setActiveTab] = useState<FilterTab>(
      OptionFilter.Brand
    );
    const isDesktop = useMediaQuery({ minWidth: widthBreakpoints.px768 });
    useEffect(() => {
      if (!isDesktop) setActiveTab(OptionFilter.Body);
    }, [isDesktop]);

    let isMotorcycle = false;
    const brandsList = useSelector(brandsListSelector);
    const motorcycleId = brandsList
      ? brandsList.find((brand) => brand.code === 'motorcycle')?.id
      : null;

    if (motorcycleId) {
      if (state.brand.value.includes(motorcycleId)) {
        isMotorcycle = true;
      }
    }

    return (
      <Container>
        <DesktopTabs>
          <StyledBrandTypography>Wybierz markę</StyledBrandTypography>
          {!isMotorcycle ? (
            <BrandAutocomplete changeValue={changeValue} state={state} />
          ) : (
            <BrandMotorcycleAutocomplete
              changeValue={changeValue}
              state={state}
            />
          )}
        </DesktopTabs>
        {!isDesktop && (
          <MobileTabs>
            <StyledBrandTypography>Wybierz markę</StyledBrandTypography>
            {!isMotorcycle ? (
              <BrandAutocomplete changeValue={changeValue} state={state} />
            ) : (
              <BrandMotorcycleAutocomplete
                changeValue={changeValue}
                state={state}
              />
            )}
          </MobileTabs>
        )}
      </Container>
    );
  }
);
