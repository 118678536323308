import React, { FC } from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import { FieldValidator } from 'formik/dist/types';
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from 'src/common/components/ToggleButtonGroup';

export interface ToggleButtonGroupControlProps extends ToggleButtonGroupProps {
  name: string;
  validate?: FieldValidator;
}

export const ToggleButtonGroupControl: FC<ToggleButtonGroupControlProps> = ({
  name,
  validate,
  onChange: $onChange,
  ...restProps
}: ToggleButtonGroupControlProps) => (
  <Field name={name} validate={validate}>
    {({
      field: { name, value },
      form: { setFieldValue, setFieldTouched },
    }: FieldProps<string[]>) => (
      <ToggleButtonGroup
        value={value}
        onChange={(v) => {
          setFieldTouched(name);
          setFieldValue(name, v);
          $onChange?.(v);
        }}
        {...restProps}
      />
    )}
  </Field>
);
