import { dissoc, keys, pick } from 'ramda';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import {
  SearchModelToggleGroup,
  SearchModelToggleGroupItemLabel,
} from 'src/app/search/components/SearchPanel/SearchGeneralFilters/SearchModelToggleGroup';
import { ExoticFilter, OptionFilter } from 'src/app/common/models/filter';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import {
  useFilterControl,
  useOptionFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { BlockScrollContainer } from 'src/common/components/ScrollContainer';
import { getResponsive } from 'src/common/models/image';
import { StyleProps } from '@summer/react-kit';

const Content = styled.div`
  ${media.w.max.px992(css`
    display: flex;
    flex-wrap: nowrap;
  `)}
`;

const Container = styled.div`
  height: 11.25rem;
  max-height: 11.25rem;
  margin-top: 0.75rem;

  ${media.w.min.px1200(css`
    height: 15rem;
    max-height: 15rem;
  `)}
`;

const ScrollContainer = styled(BlockScrollContainer)`
  ${media.w.max.px992(css`
    height: 11.25rem;
  `)}

  ${media.w.min.px1200(css`
    height: 15rem;
  `)}
`;

export const ModelIconSection = memo<StyleProps>(({ style }) => {
  const brands = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: a.label,
      options: a.models.map((b) => ({
        id: b.id,
        label: (
          <SearchModelToggleGroupItemLabel
            label={b.label}
            imageUrl={getResponsive('thumbnail', b.imageUrl)}
          />
        ),
      })),
    }))
  );

  const { state, setValue } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );

  const { state: brandFilterState } = useOptionFilterControl(
    OptionFilter.Brand
  );

  const visibleBrands = useMemo(
    () =>
      brands
        .filter(
          (a) =>
            brandFilterState.value.includes(a.id) ||
            brandFilterState.value.length === 0
        )
        .filter((a) => a.options.length > 0),
    [brandFilterState.value, brands]
  );

  // FIXME: Move this effect to saga
  useEffect(() => {
    if (brandFilterState.value.length === 0) {
      return;
    }

    const newValue = pick(brandFilterState.value, state.value);
    if (keys(state.value).length !== keys(newValue).length) {
      setValue(newValue);
    }
  }, [brandFilterState.value, state.value]);

  const onChangeHandler = useCallback(
    (brand: string, value: string[]) => {
      if (value.length > 0) {
        setValue({
          ...state.value,
          [brand]: value,
        });
      } else {
        const value: { [brand: string]: string[] } = dissoc(brand, state.value);
        setValue(value);
      }
    },
    [setValue, state.value]
  );

  if (visibleBrands.length < 1) {
    return <></>;
  }

  return (
    <Container>
      <ScrollContainer style={style} options={{ sizeAutoCapable: false }}>
        <Content>
          {visibleBrands.map((_) => (
            <SearchModelToggleGroup
              brand={_}
              value={state.value[_.id] ?? []}
              onChange={onChangeHandler}
              key={_.id}
            />
          ))}
        </Content>
      </ScrollContainer>
    </Container>
  );
});
