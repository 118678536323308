import styled from 'styled-components';

export interface HiddenContentProps {
  isVisible?: boolean;
}

export const HiddenContent = styled.div<HiddenContentProps>`
  display: block;
  ${({ isVisible }) => false === isVisible && { display: 'none' }};
`;
