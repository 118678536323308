import { countLabelFormatter } from '@summer/react-kit/functions';
import { BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { PublicOffer } from 'src/app/product/models/publicOffer';
import { evolve, isNil, map, pipe } from 'ramda';
import { imageUrlTransformer } from 'src/common/services/image';
import { Offer } from 'src/common/models/offer';
import { CustomerType } from 'src/common/models/customerType';

export const offerCountLabelFormatter = countLabelFormatter(
  'Brak ofert',
  '1 oferta',
  (count) => `${count} oferty`,
  (count) => `${count} ofert`
);

export const fetchPublicOffers: (type: string) => Promise<PublicOffer[]> = (
  type: string
) =>
  BaseAxios.get<PublicOffer[]>(`/public-offers/${type}`)
    .then(
      pipe(
        flatten,
        map(
          evolve({
            product: {
              mainPhoto: imageUrlTransformer,
            },
          })
        )
      )
    )
    .catch(throwErrorResponse);

/**
 * @param list
 * @param customerType - needed to set artefakta offer price
 * @param baseOffer - default values for the cheapest offer (in case the cheapest offer does not contain all required fields)
 */
export const findCheapest = (
  list: Offer[],
  customerType?: CustomerType,
  baseOffer?: Offer
) => {
  if (list.length === 0) {
    return undefined;
  }

  let cheapest = list[0];
  list.forEach((item) => {
    if (item.price < cheapest.price) {
      cheapest = item;
    }
  });

  const offer = {
    ...baseOffer,
    ...cheapest,
  };

  const userCustomerType = customerType || cheapest.customerType;

  if (cheapest.external?.type === 'artefakta4internal') {
    if (!isNil(cheapest.external.details.down)) {
      offer.initialPayment = cheapest.external.details.down;
    }

    if (!isNil(cheapest.external.details.purchase)) {
      offer.buyoutPrice = cheapest.external.details.purchase;
    }

    if (!isNil(cheapest.external.details.month)) {
      offer.contractPeriod = cheapest.external.details.month;
    }

    if (userCustomerType === CustomerType.B2B && cheapest.external.priceB2B) {
      offer.price = cheapest.external.priceB2B;
    }

    if (userCustomerType === CustomerType.B2C && cheapest.external.priceB2C) {
      offer.price = cheapest.external.priceB2C;
    }
  }

  offer.customerType =
    cheapest.customerType || customerType || baseOffer?.customerType;

  return offer;
};
