import React from 'react';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Slider } from '@material-ui/core';
import { colors } from 'src/config/colors';
import { styledIs } from '@summer/react-kit/functions';
import { acceleratedEasing, slideInBottom } from 'src/common/styles/animations';

const Container = styled.div`
  display: grid;
  margin: 0;
  grid-template-columns: 1fr 4.125rem;
  grid-column-gap: 1rem;
  align-items: center;
  grid-template-areas:
    'label sufix'
    'slider content';
`;

const StyledSlider = styled(Slider)`
  grid-area: slider;
  margin: 0 0.375rem;
`;

const Content = styled.div`
  grid-area: content;
  justify-self: flex-end;
  animation: ${slideInBottom} 100ms ${acceleratedEasing};
`;

const Label = styled.div`
  grid-area: label;
  font-size: 0.75rem;
  padding: 0.125rem 0.313rem;
  color: ${colors.white};
`;

const Suffix = styled.div`
  grid-area: sufix;
  font-size: 0.625rem;
  opacity: 0.6;
  justify-self: flex-end;
`;

export interface ProductSliderProps {
  min: number;
  max: number;
  step: number;
  label: string;
  value: number;
  content: string | number | null | undefined;
  suffix?: string;
  onChange?: (
    event: React.ChangeEvent<unknown>,
    value: number | number[]
  ) => void;
  disabled?: boolean;
}

export const ProductSlider: FC<ProductSliderProps> = ({
  min,
  max,
  step,
  value,
  suffix,
  label,
  content,
  onChange,
  disabled,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Suffix>{suffix}</Suffix>
      <StyledSlider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <Content key={content}>{content}</Content>
    </Container>
  );
};
