import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { SearchFiltersGroupContainer } from 'src/app/search/components/SearchPanel/SearchFiltersGroupContainer';
import { SearchBodyColorAppearanceFilter } from 'src/app/search/components/SearchPanel/SearchAppearanceFilters/SearchBodyColorAppearanceFilter';
import { SearchRimSizeAppearanceFilter } from 'src/app/search/components/SearchPanel/SearchAppearanceFilters/SearchRimSizeAppearanceFilter';
import { media } from 'src/config/breakpoints';
import { SearchUpholsteryTypeInteriorFilter } from 'src/app/search/components/SearchPanel/SearchInteriorFilters/SearchUpholsteryTypeInteriorFilter';
import { SearchUpholsteryColorInteriorFilter } from 'src/app/search/components/SearchPanel/SearchInteriorFilters/SearchUpholsteryColorInteriorFilter';

const Container = styled(SearchFiltersGroupContainer)`
  grid-template-areas: 'color' 'rim' 'upholster-type' 'upholster-color';
  grid-row-gap: 4.375rem;
  width: 100%;

  ${media.w.min.px768(css`
    grid-template-areas: 'color upholster-type' 'upholster-color rim';
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
    justify-content: space-between;
    grid-row-gap: 2fr;
  `)};

  ${media.w.min.px992(css`
    grid-template-areas: 'color upholster-type upholster-color' 'rim . .';
    grid-template-columns: 25rem max-content max-content;
    justify-content: space-between;
    grid-row-gap: 0;
    max-width: 78.125rem;
  `)};

  ${media.w.max.px768(css`
    padding-bottom: 6rem;
  `)};
`;

const StyledSearchRimSizeAppearanceFilter = styled(
  SearchRimSizeAppearanceFilter
)`
  grid-area: rim;
`;

export const SearchAppearanceFilters = memo(() => (
  <Container>
    <SearchBodyColorAppearanceFilter
      style={{ gridArea: 'color', maxWidth: '28rem' }}
    />
    <StyledSearchRimSizeAppearanceFilter />
    <SearchUpholsteryTypeInteriorFilter
      style={{ gridArea: 'upholster-type' }}
    />
    <SearchUpholsteryColorInteriorFilter
      style={{ gridArea: 'upholster-color' }}
    />
  </Container>
));
