import React, { useEffect, useState } from 'react';
import { Seo } from 'src/public/components/Seo';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { Footer } from 'src/public/components/Footer';
import { ScrollContext } from 'src/common/contexts/ScrollContext';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import styled, { css } from 'styled-components';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { Link, navigate } from 'gatsby';
import { HomePageRoute } from 'src/app/routes';
import { Logo } from 'src/common/components/Logo';
import { Button, CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { SectionHeading } from 'src/common/components/SectionHeading';
import fuelCard from 'src/assets/images/fuel-special-offer-card.png';
import fuelCardDesktop from 'src/assets/images/fuel-special-offer-card-desktop.png';
import { useMediaQuery } from 'react-responsive';
import Fuel from 'src/assets/images/fuel.svg';
import CarWash from 'src/assets/images/car-wash.svg';
import Sprinkler from 'src/assets/images/sprinkler.svg';
import { skewBoth } from 'src/common/styles/skew';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { SimpleLink } from 'src/common/components/SimpleLink';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { useSelector } from 'react-redux';
import { previousPageUrlSelector } from 'src/state/pageContext/pageContextSelectors';

const FullPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const Container = styled(ConstraintContainer)`
  overflow: hidden;

  ${media.w.min.px1200(css`
    padding: 0 10rem;
  `)};
`;

const HeaderWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.125rem;
  padding-bottom: 0.5rem;
  position: relative;

  ${media.w.min.px992(css`
    padding-top: 2.5rem;
    align-items: flex-start;
  `)};

  &:after {
    ${media.w.max.px992(css`
      content: '';
      background: radial-gradient(
        35.35% 35.46% at 50% 50%,
        #ffffff 0%,
        rgba(251, 251, 251, 0) 100%
      );
      position: absolute;
      z-index: -1;
      bottom: -10rem;
      width: 30rem;
      height: 30rem;
      opacity: 0.2;
    `)};
  }
`;

const BackButtonContent = styled.span`
  font-size: 0.625rem;
`;

const StyledChevronLeft = styled(ChevronLeft)`
  margin-right: 0.5rem;
`;

const HeadingWrapper = styled.div`
  max-width: 44rem;
  color: ${colors.lightGray};
`;

const StyledSectionHeading = styled(SectionHeading)`
  text-transform: none;
  text-align: left;
  margin-bottom: 0;

  ${media.w.max.px768(css`
    text-align: center;
  `)}

  ${media.w.max.px992(css`
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 1px;
    margin: 2rem 0;
  `)}
`;

const Subtitle = styled.div`
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  color: ${colors.white};

  ${media.w.min.px768(css`
    text-align: left;
  `)};

  ${media.w.min.px992(css`
    font-weight: bold;
    line-height: 1.25rem;
  `)};
`;

const ColumnTitle = styled.div`
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  max-width: 25rem;

  ${media.w.min.px768(css`
    text-align: left;
  `)};
`;

const Column = styled.div`
  padding-top: 3.75rem;
`;

const CardSection = styled.div`
  display: grid;
  justify-content: center;

  ${media.w.min.px768(css`
    grid-template-columns: 60% 40%;
  `)};
`;

const MobilityCard = styled.div`
  position: relative;

  &:before {
    content: '';
    background: radial-gradient(
      35.35% 35.46% at 50% 50%,
      #007fff 0%,
      rgba(0, 127, 255, 0) 100%
    );
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -35%;
    width: 35.8625rem;
    height: 35.8625rem;
    opacity: 0.5;

    ${media.w.min.px1200(css`
      top: -150%;
      left: -20%;
      width: 60rem;
      height: 60rem;
    `)};
  }
`;

const Image = styled.img`
  display: block;
  margin: 1.5rem auto 0;
  position: relative;
  z-index: 2;

  ${media.w.min.px992(css`
    margin: 1.5rem 0 0;
  `)}
`;

const Gradient = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 6.75rem;
  ${skewBoth('3rem')};
  background: linear-gradient(
    164.68deg,
    #37383f 9.99%,
    rgba(55, 56, 63, 0) 46.64%
  );
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  color: ${colors.lightGray};
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 1.1875rem 0;
`;

const Benefits = styled.div`
  padding: 1.1875rem 0 5rem;
`;

const Icon = styled.div`
  padding-left: 1.125rem;
  margin-right: 0.75rem;

  svg {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

const Note = styled.div`
  text-align: center;
  color: ${colors.lightGray};
  font-weight: 300;
  font-size: 0.825rem;
  line-height: 1.1;

  a {
    font-weight: 400;
  }

  ${media.w.min.px1200(css`
    margin-top: 4.375rem;
  `)}
`;

const FuelSpecialOfferPage = () => {
  useStorePageUrl();

  const previousPageUrl = useSelector(previousPageUrlSelector);
  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px992,
  });

  const [scrollRef, setScrollRef] = useState<OverlayScrollbarsComponent | null>(
    null
  );

  useEffect(() => {
    const elements = scrollRef?.osInstance()?.getElements();

    if (elements) {
      elements.viewport.tabIndex = -1;
      elements.viewport.focus();
    }
  }, [scrollRef]);

  return (
    <ScrollContext.Provider value={scrollRef}>
      <FullPageLayout>
        <Seo title="2 baki w prezencie!" />

        <ScrollContainer ref={setScrollRef} style={{ height: '100%' }}>
          <Container>
            <HeaderWrapper>
              <CleanButton as={Link} to={HomePageRoute}>
                <Logo variant={'light'} />
              </CleanButton>
              <CleanButton
                onClick={() =>
                  navigate(
                    previousPageUrl ? previousPageUrl.url : HomePageRoute
                  )
                }
              >
                <Button kind="box" variant="transparent">
                  <StyledChevronLeft size={8} />
                  <BackButtonContent>POWRÓT</BackButtonContent>
                </Button>
              </CleanButton>
            </HeaderWrapper>
            <HeadingWrapper>
              <StyledSectionHeading>
                Prezent na start - 2 baki paliwa od Car Platform!
              </StyledSectionHeading>
              <Subtitle>
                Rozpocznij swoją przygodę z Car Platform i nie martw się o ceny
                paliwa. Wybierz swój wymarzony samochód z ponad 30 marek
                dostępnych na naszej stronie, a my zajmiemy się resztą.
              </Subtitle>
            </HeadingWrapper>
            <CardSection>
              <Column>
                <ColumnTitle>
                  Do każdego auta, otrzymasz w prezencie kartę zakupową na start
                  o równowartości dwóch pełnych baków paliwa. <br />
                  Maksymalna kwota doładowania karty wynosi 1000 zł.
                </ColumnTitle>
                <MobilityCard>
                  <Image
                    src={isDesktop ? fuelCardDesktop : fuelCard}
                    alt="karta paliwowa"
                  />
                </MobilityCard>
              </Column>
              <Column>
                <ColumnTitle>
                  Kartę podarunkową wykorzystasz <br /> na stacjach ORLEN:
                </ColumnTitle>
                <Benefits>
                  <Benefit>
                    <Gradient />
                    <Icon>
                      <Fuel />
                    </Icon>
                    <div>
                      zatankuj samochód <br /> sprawdzonym paliwem
                    </div>
                  </Benefit>

                  <Benefit>
                    <Gradient />
                    <Icon>
                      <CarWash />
                    </Icon>
                    <div>skorzystaj z myjni</div>
                  </Benefit>

                  <Benefit>
                    <Gradient />
                    <Icon>
                      <Sprinkler />
                    </Icon>
                    <div>kup niezbędne akcesoria</div>
                  </Benefit>
                </Benefits>
              </Column>
            </CardSection>
            <Note>
              Promocja nie może być łączona z innymi promocjami oferowanymi
              przez Car Platform lub partnerów Car Platform. Więcej szczegółów
              znajdziesz w{' '}
              <SimpleLink
                target="_blank"
                href="/CarPlatform_Regulamin_Promocji_2_baki_w_prezencie.pdf"
              >
                regulaminie promocji.
              </SimpleLink>
            </Note>
          </Container>
          <Footer />
        </ScrollContainer>
      </FullPageLayout>
    </ScrollContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export
export default FuelSpecialOfferPage;
