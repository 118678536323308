import React, { ReactChild } from 'react';
import { AsideMainMenuLayout } from 'src/common/components/AsideMainManuLayout';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import { typography } from 'src/config/typography';
import { ExpansionPanel } from 'src/common/components/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelSummary } from 'src/common/components/ExpansionPanel/ExpansionPanelSummary';
import { ExpansionPanelDetails } from 'src/common/components/ExpansionPanel/ExpansionPanelDetails';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

// todo: Extract to AsideMainMenuLayout ?
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.875rem;
`;

// todo: Extract to AsideMainMenuLayout ?
const ScrollContent = styled.div`
  max-width: 49rem;
`;

const Header = styled.header`
  color: ${colors.white};
`;

const Subtitle = styled(Title).attrs({ level: 2, variant: 'condensed' })`
  font-size: 0.875rem;
  margin-top: 3vh;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 2;
`;

const faqItems: { title: ReactChild; content: ReactChild }[] = [
  {
    title: (
      <>
        Jaka jest gwarancja bezpieczeństwa podczas korzystania z&nbsp;usług Car
        Platform Business?
      </>
    ),
    content: (
      <p>
        Nasza platforma została zaakceptowana przez Twojego pracodawcę.
        Współpracujemy wyłącznie z&nbsp;autoryzowanymi dealerami samochodów,
        którzy oferują nowe i&nbsp;używane samochody. Ponad to jesteśmy
        wspierani przez zaufanych partnerów finansujących zapewniając bezpieczne
        zawarcie kontraktu.
      </p>
    ),
  },
  {
    title: (
      <>
        Czy ceny widoczne po zalogowaniu do strefy Car Platform Business
        uwzględniają już przysługujący mi rabat?
      </>
    ),
    content: (
      <p>
        Tak. Ceny widoczne po zalogowaniu uwzględniają rabat przysługujący
        pracownikowi zgodnie z&nbsp;polityką danej marki.
      </p>
    ),
  },
  {
    title: (
      <>
        Jaka forma zatrudnienia jest wymagana, aby móc skorzystać z&nbsp;oferty
        Car Platform Business?
      </>
    ),
    content: (
      <p>
        Forma zatrudnienia nie ma dla nas znaczenia. Warunkiem skorzystania z
        finansowania Car Platform Business jest posiadanie służbowego adresu
        e-mail w domenie należącej do współpracującego z&nbsp;nami pracodawcy
        oraz możliwość otrzymania zaświadczenia o zatrudnieniu od wskazanego
        przez Państwa pracodawcy lub świadczeniu usług albo wykonywania zlecenia
        na jego rzecz.
      </p>
    ),
  },
  {
    title: <>Czy podpisuję z&nbsp;Wami jakąś umowę?</>,
    content: (
      <p>
        Korzystanie z&nbsp;usług Car Platform wiąże się jedynie
        z&nbsp;koniecznością akceptacji Regulaminu i&nbsp;Polityki Prywatności.
        Konto na platformie jest całkowicie bezpłatne. Umowę na finansowanie
        samochodu podpiszesz bezpośrednio z&nbsp;finansującym.
      </p>
    ),
  },
  {
    title: (
      <>
        Czy w&nbsp;Car Platform mogę wybrać tylko gotowy model samochodu, czy
        istnieje możliwość indywidualnej konfiguracji auta?
      </>
    ),
    content: (
      <p>
        Na stronie Car Platform przedstawione są samochody gotowe do odbioru.
        Jeśli potrzebują Państwo auta dopasowanego do indywidualnych potrzeb,
        prosimy o&nbsp;kontakt z&nbsp;naszym doradcą, który pomoże Państwu
        skonfigurować pojazd i&nbsp;przedstawi ofertę finansowania.
      </p>
    ),
  },
  {
    title: <>Czy poprzez Car Platform mogę kupić auto za gotówkę?</>,
    content: (
      <p>
        Istnieje taka możliwość, jednak należy wziąć pod uwagę, iż osoba
        kupująca auto z&nbsp;własnych środków nie ma możliwości skorzystania z
        dodatkowych usług oferowanych na korzystnych warunkach finansowych,
        takich jak ubezpieczenie OC/AC, assistance, wymiana opon, serwis czy
        okresowe przeglądy. W przypadku umowy najmu długoterminowego użytkownik
        płaci zawsze stały miesięczny czynsz i&nbsp;nie musi się martwić
        niespodziewanymi wydatkami.
      </p>
    ),
  },
  {
    title: (
      <>
        Czy mogę skorzystać z&nbsp;oferty Car Platform Business będąc na
        wypowiedzeniu?
      </>
    ),
    content: (
      <p>
        Istnieje taka możliwość, jednak każdy taki przypadek rozpatrujemy
        indywidualnie. W takiej sytuacji prosimy o kontakt z&nbsp;Car Platform,
        aby omówić ewentualne możliwości.
      </p>
    ),
  },
  {
    title: <>Czy jest możliwe umówienie jazdy próbnej?</>,
    content: (
      <p>
        Tak. Na prośbę klienta umożliwiamy jazdę próbną. W zależności od wyboru
        auta, jazdy testowe organizować i&nbsp;realizować będzie Car Platform
        lub dedykowane autoryzowane przedstawicielstwo danej marki.
      </p>
    ),
  },
  {
    title: <>Czy mogę obejrzeć samochód, który planuję zamówić?</>,
    content: (
      <p>
        Istnieje możliwość prezentacji auta, jeśli wybrany model znajduje się
        aktualnie w salonie dealera. W zależności od wyboru samochodu,
        prezentacja realizowana będzie przez Car Platform lub dedykowane
        autoryzowane przedstawicielstwo danej marki.
      </p>
    ),
  },
  {
    title: <>Jaki jest czas oczekiwania na odbiór auta?</>,
    content: (
      <p>
        Czas oczekiwania zależy od aktualnej dostępności wybranego auta. Jeśli
        wybrany samochód został już wyprodukowany i&nbsp;dostarczony do dealera,
        wszystkie formalności trwają zwykle od 7 do 14 dni.
      </p>
    ),
  },
  {
    title: <>Jakie formy finansowania są dostępne na platformie?</>,
    content: (
      <p>
        Za pośrednictwem Car Platform mogą Państwo skorzystać z&nbsp;oferty
        najmu długoterminowego, leasingu operacyjnego i&nbsp;konsumenckiego oraz
        kredytu. Wszystkie formy finansowania są dostępne zarówno dla konsumenta
        jak i przedsiębiorców.
      </p>
    ),
  },
  {
    title: <>Czy mogę podpisać więcej niż jedną umowę na finansowanie auta?</>,
    content: (
      <p>
        Decyzję w tej sprawie podejmuje podmiot finansujący samochód, który
        dokonuje weryfikacji zdolności kredytowej klienta.
      </p>
    ),
  },
  {
    title: <>Jakie produkty finansowe dostępne są dla osoby fizycznej?</>,
    content: (
      <>
        <p>
          Dla klientów indywidualnych proponujemy finansowanie w formie leasingu
          konsumenckiego lub w formie tradycyjnego kredytu.
        </p>

        <p>
          <b>Leasing konsumencki</b> cechuje:
        </p>

        <ol>
          <li>Wyjątkowa niska miesięczna rata</li>
          <li>Opłata wstępna już od 0%.</li>
          <li>Jedna opłata za pakiet usług (koszty najmu, polisy, przegląd)</li>
          <li>
            Elastyczność w&nbsp;zakończeniu kontraktu - użytkownik może oddać
            auto po zakończonym kontrakcie lub je wykupić na własność.
          </li>
          <li>
            Brak problemów związanych ze sprzedażą auta (w umowie określona jest
            gwarantowana wartość odkupu samochodu){' '}
          </li>
          <li>
            Łatwość wymiany auta na nowe, w&nbsp;ramach kolejnej umowy już po 2
            latach
          </li>
          <li>Brak obciążenia zdolności kredytowej</li>
          <li>
            Kompleksowy pakiet ubezpieczeń komunikacyjnych OC, AC, NNW,
            Assistance w atrakcyjnej cenie.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: (
      <>Czy można uzyskać decyzję odmowną na udzielenie finansowania pojazdu?</>
    ),
    content: (
      <p>
        Tak. Instytucja finansująca pojazd dokonuje analizy zdolności kredytowej
        klienta zainteresowanego ofertą i&nbsp;to ona wydaje decyzję.
      </p>
    ),
  },
  {
    title: (
      <>
        Czy samochód może być użytkowany przez członka rodziny, np. żonę lub
        syna?
      </>
    ),
    content: (
      <p>
        Podczas realizacji zamówienia można wskazać, kto będzie korzystał z
        auta. Może nim być małżonek wynajmującego lub inne osoby z&nbsp;rodziny
        zamieszkujące wspólne gospodarstwo domowe. Należy przy tym pamiętać, że
        odpowiedzialność za auto zawsze ponosi osoba podpisująca umowę.
      </p>
    ),
  },
  {
    title: (
      <>
        Czy mogę dokonać cesji finansowanego pojazdu przed wygaśnięciem umowy?
      </>
    ),
    content: (
      <p>
        Tak, istnieje możliwość cesji umowy na inny podmiot / osobę, jeśli
        umożliwia ą instytucja finansująca i&nbsp;na warunkach wskazanych w
        umowie. W tym celu wynajmujący lub osoba leasingująca pojazd, powinna
        zapoznać się z&nbsp;formalnościami i&nbsp;opłatą wynikającą
        z&nbsp;zawarcia umowy cesji. Podmiot finansujący dokonuje analizy
        zdolności kredytowej cesjonariusza, oceniając jego wiarygodność
        finansową. Zazwyczaj pozytywna weryfikacja oznacza przeniesienie praw
        i&nbsp;zobowiązań z&nbsp;umowy przez cedenta na rzecz trzeciej strony.
      </p>
    ),
  },
  {
    title: (
      <>Co należy zrobić, gdy umowa najmu długoterminowego dobiega końca?</>
    ),
    content: (
      <>
        <p>
          Koniec umowy oznacza konieczność oceny stanu pojazdu zgonie
          z&nbsp;wytycznymi z&nbsp;„Przewodnika Zwrotu Pojazdów”. Aktualny
          dokument znajduje się na stronie{' '}
          <SimpleLink href="https://pzwlp.pl/">https://pzwlp.pl/</SimpleLink>.
        </p>
        <p>
          Przed zwrotem prosimy upewnić się, że wszystkie szkody zostały
          zgłoszone do ubezpieczyciela i&nbsp;są naprawione, a&nbsp;pojazd
          posiada ważne badanie techniczne.
        </p>

        <p>
          Prosimy również, aby zadbać o&nbsp;odpowiednie przygotowanie
          samochodu, w&nbsp;szczególności:
        </p>

        <ol>
          <li>Pozostawienie czystego i&nbsp;suchego nadwozia</li>
          <li>Posprzątanie wnętrza pojazdu</li>
          <li>
            Demontaż wyposażenia zainstalowanego przez Państwa indywidualnie
          </li>
          <li>
            Usunięcie wszystkich rzeczy osobistych oraz dokumentów.
            Przygotowanie wszystkich dokumentów pojazdu (książka serwisowa,
            dowód rejestracyjny, instrukcja obsługi) oraz wszystkich kompletów
            kluczy
          </li>
        </ol>

        <p>
          Termin i&nbsp;miejsce zwrotu pojazdu jest ustalane z&nbsp;podmiotem
          finansującym.
        </p>
      </>
    ),
  },
  {
    title: <>Czy jest możliwość wykupu auta z&nbsp;wynajmu długoterminowego?</>,
    content: (
      <p>
        Tak, każdy użytkownik ma prawo pierwokupu użytkowanego przez siebie auta
        - po cenie zagwarantowanej w umowie, lub w przypadku gdy takiej wartości
        w umowie brakuje - wg wyceny rynkowej.
      </p>
    ),
  },
  {
    title: <>Na kogo wystawiana jest co miesiąc faktura za najem auta?</>,
    content: (
      <p>
        Fakturę otrzymuje i&nbsp;opłaca osoba, która podpisała umowę na
        finansowanie pojazdu, czyli pracownik lub osoba współpracująca
        z&nbsp;Pracodawcą na podstawie innej umowy cywilnoprawnej. Pracodawca
        nie ma żadnych zobowiązań wobec Car Platform.
      </p>
    ),
  },
  {
    title: <>Jak długo może trwać wynajem długoterminowy?</>,
    content: (
      <p>
        Umowa wynajmu zawierana poprzez Car Platform może trwać od 24 do 60
        miesięcy.
      </p>
    ),
  },
  {
    title: <>Co jest zawarte w&nbsp;miesięcznej opłacie za wynajem auta?</>,
    content: (
      <p>
        Zależnie od ustaleń z doradcą w miesięcznej racie, oprócz należności za
        finansowanie auta, może być zawarta również opłata za ubezpieczenie OC,
        AC, NNW (tylko w przypadku umowy na działalność gospodarczą),
        assistance, wymiana opon na zimowe, samochód zastępczy oraz przeglądy z
        naprawami.
      </p>
    ),
  },
  {
    title: <>Czy mogę złożyć zamówienie na pojazd bez zakładania konta?</>,
    content: (
      <p>
        Tak, możliwość złożenia zamówienia jest dostępna dla wszystkich
        i&nbsp;nie wymaga założenia konta.
      </p>
    ),
  },
  {
    title: (
      <>
        Czym jest Car Platform Business i&nbsp;jak mogę dołączyć do tej strefy?
      </>
    ),
    content: (
      <p>
        Car Platform Business to program benefitowy dający dostęp do wielu
        korzyści, takich jak zniżki, comiesięczne benefity i&nbsp;oferty
        specjalne. Dołączyć do programu możesz tylko jeśli Twój pracodawca
        współpracuje z Car Platform.
      </p>
    ),
  },
];

const FaqPage = () => {
  useStorePageUrl();

  return (
    <AsideMainMenuLayout>
      <Seo title="FAQ - Pytania i&nbsp;odpowiedzi" />
      <Container>
        <Header>
          <Title level={1} variant="condensed">
            FAQ - Pytania i&nbsp;odpowiedzi
          </Title>
          <Subtitle>Data ostatniej aktualizacji 26.04.2022 r.</Subtitle>
        </Header>
        <FlexScrollContainer>
          <ScrollContent>
            <section>
              {faqItems.map(({ content, title }, i) => (
                <ExpansionPanel key={i} defaultExpanded={i === 0}>
                  <ExpansionPanelSummary>
                    {i + 1}. {title}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </section>
          </ScrollContent>
        </FlexScrollContainer>
      </Container>
    </AsideMainMenuLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default FaqPage;
