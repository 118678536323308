import React, { FC } from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import styled from 'styled-components';
import { EngineType } from 'src/app/common/models/product';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import { RouteComponentProps } from '@reach/router';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #434449;
`;

const HorizontalToggleGroupShowcase = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-template-rows: 1fr 1fr;
  width: min-content;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const toggleButtonGroupProps = {
  limit: 1,
  value: [EngineType.Diesel],
  items: [
    { id: EngineType.Diesel, label: 'DIESEL' },
    { id: EngineType.Petrol, label: 'BENZYNA' },
    { id: EngineType.Hybrid, label: 'HYBRYDA' },
    { id: EngineType.Electric, label: 'ELEKTRYCZNY' },
  ],
  toggleButtonComponent: HorizontalToggleButton,
};

export const ToggleButtonGroupShowcase: FC<RouteComponentProps> = () => {
  return (
    <Container>
      <h3>Toggle</h3>
      <HorizontalToggleGroupShowcase>
        <div>Normal</div>
        <HorizontalToggleGroupContainer>
          <ToggleButtonGroup {...toggleButtonGroupProps} />
        </HorizontalToggleGroupContainer>
        <HorizontalToggleGroupContainer>
          <ToggleButtonGroup {...toggleButtonGroupProps} variant="secondary" />
        </HorizontalToggleGroupContainer>

        <div>Disabled</div>
        <HorizontalToggleGroupContainer>
          <ToggleButtonGroup {...toggleButtonGroupProps} disabled={true} />
        </HorizontalToggleGroupContainer>
        <HorizontalToggleGroupContainer>
          <ToggleButtonGroup
            {...toggleButtonGroupProps}
            variant="secondary"
            disabled={true}
          />
        </HorizontalToggleGroupContainer>
      </HorizontalToggleGroupShowcase>
    </Container>
  );
};
