import React, { FC, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { lighten, rgba } from 'polished';
import { colors } from 'src/config/colors';
import { styledIs } from '@summer/react-kit/functions';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { Product } from 'src/app/common/models/product';
import { AppHeader } from 'src/common/components/AppHeader';
import { useMediaQuery } from 'react-responsive';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { deceleratedEasing, slideInBottom } from 'src/common/styles/animations';
import { getResponsive } from 'src/common/models/image';
import { Button } from 'src/common/components/Button';
import { navigate } from 'gatsby';
import { LoginRoute } from 'src/public/publicRoutes';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { CarPlatformBusinessBenefitsModal } from 'src/common/components/CarPlatformBusinessBenefitsModal';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { GeneralSearchAutocomplete } from 'src/app/search/components/SearchHeader/GeneralSearchAutocomplete';

const HeaderWrapper = styled.div<{ showTitle?: boolean }>`
  background-color: white;
  height: 4rem;
  transition: height 250ms ${deceleratedEasing};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  color: ${colors.grayBasic};

  &:before {
    content: '';
    display: block;
    opacity: 0;
    transition: opacity 250ms ${deceleratedEasing};
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      white 0%,
      white 60%,
      ${lighten(0.3, colors.grayBasic)} 100%
    );
  }

  ${styledIs('showTitle')(css`
    height: 6rem;

    ${media.w.min.px1200(css`
      height: 5.5rem;
    `)}
    &:before {
      opacity: 1;
    }
  `)};
`;

const ProductTitle = styled.div`
  color: ${colors.background};
  font-size: 1.375rem;
  font-weight: bold;
  animation: ${slideInBottom} 250ms ${deceleratedEasing} forwards;
  margin-top: -0.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;

  ${media.w.min.px1200(css`
    margin-top: -1.5rem;
  `)}
`;

const ProductThumbnail = styled.img`
  height: 100%;
  margin-right: 1rem;
`;

const HeaderChildrenWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
  display: flex;
`;

const LoginButton = styled(Button)`
  font-weight: 700;
  text-align: left;
`;

export interface ProductDetailsHeaderProps {
  showTitle?: boolean;
  product: Product;
}

export const ProductDetailsHeader: FC<ProductDetailsHeaderProps> = ({
  showTitle,
  product,
}) => {
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const isMobile = useMediaQuery({
    maxWidth: below(widthBreakpoints.px768),
  });
  const isSmallMobile = useMediaQuery({
    maxWidth: below(widthBreakpoints.px576),
  });

  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  const handleLogin = () => {
    navigate(LoginRoute);
    pushEvent(EventName.TopMenu, {
      menu_type: 'text_menu',
      menu_item: 'login',
    });
  };

  return (
    <>
      <HeaderWrapper showTitle={showTitle && !isMobile}>
        <ConstraintContainer>
          <AppHeader>
            <HeaderChildrenWrapper>
              {!isSmallMobile && <GeneralSearchAutocomplete />}
              {isMobile && (
                <>
                  {isLoggedIn ? (
                    <Button
                      size="small"
                      kind="box"
                      variant="transparent"
                      onClick={() => setShowModal(true)}
                      style={{ fontSize: '0.75rem' }}
                    >
                      TWOJE <br /> BONUSY
                    </Button>
                  ) : (
                    <LoginButton
                      kind="box"
                      size="small"
                      variant="transparent"
                      onClick={handleLogin}
                    >
                      {/* ZAREJESTRUJ <br /> / ZALOGUJ */}
                      ZALOGUJ
                    </LoginButton>
                  )}
                </>
              )}
            </HeaderChildrenWrapper>
          </AppHeader>
          {showTitle && !isMobile && (
            <ProductTitle>
              <ProductThumbnail
                src={getResponsive('small', product.mainPhoto)}
              />
              <span>
                {product.label}{' '}
                {product.version && (
                  <small style={{ marginLeft: '0.5rem', fontSize: '70%' }}>
                    {product.version}
                  </small>
                )}
              </span>
            </ProductTitle>
          )}
        </ConstraintContainer>
      </HeaderWrapper>

      <ModalPortal
        open={showModal}
        onClose={onClose}
        background={rgba(colors.background, 0.8)}
        blur={10}
      >
        <CarPlatformBusinessBenefitsModal onClose={onClose} />
      </ModalPortal>
    </>
  );
};
