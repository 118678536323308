import { FilterValues, OptionFilter } from 'src/app/common/models/filter';
import { evolve } from 'ramda';

const trunkSpaceValueMapper: { [a: string]: number | string } = {
  s: 50,
  m: 250,
  l: 550,
  xl: 4000,
} as const;

const trunkSpaceSortOrder = ['s', 'm', 'l', 'xl'];

const trunkSpaceTransformer = (
  value: FilterValues[OptionFilter.TrunkSpace]
) => {
  if (value == null) {
    return value;
  }

  const sortArray = [...value];

  const sortedArray = sortArray.sort(
    (a, b) => trunkSpaceSortOrder.indexOf(a) - trunkSpaceSortOrder.indexOf(b)
  );

  if (sortedArray.length === 1) {
    if (trunkSpaceSortOrder.indexOf(sortedArray[0]) === 0) {
      return [
        trunkSpaceValueMapper[trunkSpaceSortOrder[0]],
        trunkSpaceValueMapper[trunkSpaceSortOrder[1]],
      ] as [string, string];
    }
    if (
      trunkSpaceSortOrder.indexOf(sortedArray[0]) ===
      trunkSpaceSortOrder.length - 1
    ) {
      return [
        trunkSpaceValueMapper[
          trunkSpaceSortOrder[trunkSpaceSortOrder.length - 2]
        ],
        trunkSpaceValueMapper[
          trunkSpaceSortOrder[trunkSpaceSortOrder.length - 1]
        ],
      ] as [string, string];
    }
  } else if (sortedArray.length > 1) {
    return [
      trunkSpaceValueMapper[sortedArray[0]],
      trunkSpaceValueMapper[sortedArray[sortedArray.length - 1]],
    ] as [string, string];
  }
};

export const transformFiltersForAPI = (filters: FilterValues): FilterValues => {
  return evolve(
    {
      [OptionFilter.TrunkSpace]: trunkSpaceTransformer,
    },
    filters
  );
};
