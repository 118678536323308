import React, { memo, useMemo } from 'react';
import { CleanUnorderedList } from 'src/common/components/List';
import styled from 'styled-components';
import { Text } from 'src/common/components/typography/Text';
import {
  Artefakta4DekraOffer,
  isArtefakta4DekraOffer,
  Offer,
} from 'src/common/models/offer';
import { CustomerType } from 'src/common/models/customerType';
import { StyleProps } from '@summer/react-kit';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { financingTypeMapper } from 'src/app/product/services/offerMappers';

const List = styled(CleanUnorderedList)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
`;

const getArtefakta4DekraOfferParams = (offer: Artefakta4DekraOffer) => {
  const params = [];

  if (offer.external.details.month != null) {
    params.push({
      value: <>{offer.external.details.month}&nbsp;msc</>,
      name: (
        <>
          okres
          <br />
          najmu
        </>
      ),
    });
  }

  if (offer.external.details.mileage != null) {
    params.push({
      value: <>{localFormatNumber(offer.external.details.mileage)}&nbsp;</>,
      name: (
        <>
          roczny limit
          <br />
          kilometrów
        </>
      ),
    });
  }

  if (offer.external.details.purchase != null) {
    params.push({
      value: <>{localFormatNumber(offer.external.details.purchase)}&nbsp;zł</>,
      name: (
        <>
          kwota
          <br />
          wykupu
        </>
      ),
    });
  }

  if (offer.external.details.down != null) {
    params.push({
      value: <>{localFormatNumber(offer.external.details.down)}&nbsp;zł</>,
      name: (
        <>
          wkład
          <br />
          własny
        </>
      ),
    });
  }

  if (offer.external.details.product != null) {
    params.push({
      value: offer.external.details.product,
      name: (
        <>
          typ
          <br />
          oferty
        </>
      ),
    });
  }

  params.push({
    value:
      offer.customerType === CustomerType.B2B ? (
        <>
          jednoosobowa działalność
          <br />
          gospodarcza/ spółka
        </>
      ) : (
        'indywidualna'
      ),
    name: (
      <>
        rodzaj
        <br />
        umowy
      </>
    ),
  });

  return params;
};

const getOfferParams = (offer: Offer) => {
  if (isArtefakta4DekraOffer(offer)) {
    return getArtefakta4DekraOfferParams(offer);
  }

  const params = [];
  if (offer.contractPeriod != null) {
    params.push({
      value: <>{offer.contractPeriod}&nbsp;msc</>,
      name: (
        <>
          okres
          <br />
          najmu
        </>
      ),
    });
  }

  if (offer.annualMileage != null) {
    params.push({
      value: localFormatNumber(offer.annualMileage),
      name: (
        <>
          roczny limit
          <br />
          kilometrów
        </>
      ),
    });
  }

  if (offer.buyoutPrice != null) {
    params.push({
      value: (
        <>
          {localFormatNumber(offer.buyoutPrice)}
          &nbsp;zł
        </>
      ),
      name: (
        <>
          kwota
          <br />
          wykupu
        </>
      ),
    });
  }

  if (offer.initialPayment != null) {
    params.push({
      value: (
        <>
          {localFormatNumber(offer.initialPayment)}
          &nbsp;zł
        </>
      ),
      name: (
        <>
          wkład
          <br />
          własny
        </>
      ),
    });
  }

  params.push({
    value: financingTypeMapper[offer.financingType].toLowerCase(),
    name: (
      <>
        forma
        <br />
        finansowania
      </>
    ),
  });

  params.push({
    value:
      offer.customerType === CustomerType.B2B ? (
        <>
          jednoosobowa działalność
          <br />
          gospodarcza/ spółka
        </>
      ) : (
        'indywidualna'
      ),
    name: (
      <>
        rodzaj
        <br />
        umowy
      </>
    ),
  });

  params.push({
    value: <>nawet w&nbsp;7&nbsp;dni</>,
    name: <>formalności i&nbsp;odbiór auta</>,
  });

  params.push({
    value: 'do domu',
    name: (
      <>
        możliwa
        <br />
        dostawa
      </>
    ),
  });

  return params;
};

export interface OfferConfigurationListProps extends StyleProps {
  offer: Offer;
}

export const OfferConfigurationList = memo<OfferConfigurationListProps>(
  ({ offer, ...styleProps }) => {
    const config = useMemo(() => getOfferParams(offer), [offer]);

    return (
      <List {...styleProps}>
        {config.map(
          ({ value, name }, i) =>
            value != null && (
              <li key={i}>
                <Text
                  as="div"
                  size="small"
                  variant="condensed"
                  style={{ marginBottom: '0.25rem' }}
                >
                  <b>{value}</b>
                </Text>
                <Text as="div" size="xSmall" variant="condensed">
                  {name}
                </Text>
              </li>
            )
        )}
      </List>
    );
  }
);
