import React, {
  ComponentType,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useDerivedState } from '@summer/react-kit/hooks';
import {
  ToggleButton,
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { takeLast } from 'ramda';
import { ID, idToggleEnhancer } from '@summer/react-kit';

export interface ToggleButtonGroupItemOptions {
  id: string;
  label: ReactNode;
  textLabel?: string;
}

export interface ToggleButtonGroupProps {
  limit?: number;
  items: ToggleButtonGroupItemOptions[];
  initialValue?: string[];
  value?: string[];
  onChange?: (v: string[]) => void;
  disabled?: boolean;
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
  variant?: ToggleButtonVariant;
}

export const ToggleButtonGroup = memo<ToggleButtonGroupProps>(
  ({
    variant = 'primary',
    limit,
    items,
    initialValue,
    value,
    onChange,
    toggleButtonComponent = ToggleButton,
    disabled,
  }) => {
    const [active, setActive] = useDerivedState(initialValue ?? [], {
      value,
      onChange,
    });

    const onChangeActive = useCallback(
      (value: boolean, id: ID) => {
        setActive((currentValue) => {
          const prep = (x: string[]) => (limit ? takeLast(limit - 1, x) : x);
          return value
            ? prep(currentValue).concat([id as string])
            : prep(currentValue).filter((_) => _ !== id);
        });
      },
      [limit, setActive]
    );

    const Component = useMemo(
      () =>
        idToggleEnhancer<boolean, PropsWithChildren<ToggleButtonProps>>(
          toggleButtonComponent
        ),
      [toggleButtonComponent]
    );

    return (
      <>
        {items.map((item) => (
          <Component
            id={item.id}
            key={item.id}
            variant={variant}
            value={!!active.find((_) => _ === item.id)}
            onChange={onChangeActive}
            disabled={disabled}
          >
            {item.label}
          </Component>
        ))}
      </>
    );
  }
);
