import styled, { css } from 'styled-components';
import React, { memo } from 'react';
import { Flex } from '@summer/react-kit';
import { rem } from 'polished';
import { Label } from 'src/app/common/components/typography/Label';
import { Button } from 'src/common/components/Button';
import { StyleProps } from '@summer/react-kit';
import { mergeRight } from 'ramda';
import Close from 'src/assets/icons/Close';
import { media } from 'src/config/breakpoints';
import Bin from 'src/assets/icons/Bin';

export const SearchFilterOptionsContainer = styled.div<{ direction?: string }>`
  padding: 0.25rem;
  display: grid;
  grid-column-gap: 0.625rem;
  width: 100%;

  ${(props) =>
    props.direction === 'vertical' &&
    css`
      grid-template-areas: 'label' 'content';
      grid-template-columns: auto;

      ${media.w.min.px768(css`
        grid-template-columns: max-content;
      `)}
    `}

  ${(props) =>
    (!props.direction || props.direction === 'horizontal') &&
    css`
      grid-template-areas: 'label clear' 'content content';
      grid-template-columns: auto auto;
      align-items: flex-start;

      ${media.w.min.px768(css`
        grid-template-areas: 'label content' 'label clear';
        grid-template-columns: min-content 1fr;
        align-items: flex-start;
      `)}
    `}
`;

const DesktopButton = styled(Button)`
  &.btn-link > .btn__content > .btn__label {
    margin: 0;
    font-size: 0.625rem;
    opacity: 0.5;
  }

  ${media.w.max.px768(css`
    display: none;
  `)}
`;

const MobileButton = styled(Button)<{ visible?: boolean }>`
  ${media.w.min.px768(css`
    display: none;
  `)}
`;

const StyledFlex = styled(Flex)`
  grid-area: label;
  display: flex;

  ${media.w.min.px768(css`
    justify-content: space-between;
  `)}
`;

export interface SearchFilterOptionsHeaderProps extends StyleProps {
  name?: string;
  onClear?: () => void;
  direction?: string;
}

const StyledLabel = styled(Label)<{ direction?: string }>`
  font-size: 0.8125rem !important;

  ${media.w.min.px768(css`
    text-align: right;
  `)}

  ${(props) =>
    (!props.direction || props.direction === 'horizontal') &&
    css`
      min-width: 5.25rem;
    `}
`;

export const SearchFilterOptionsHeader = memo<SearchFilterOptionsHeaderProps>(
  ({ name, direction, onClear, className, style }) => (
    <StyledFlex
      alignCenter
      className={className}
      style={mergeRight(
        { marginBottom: rem(8), minHeight: rem(24) },
        style ?? {}
      )}
    >
      <StyledLabel style={{ margin: 0 }} direction={direction}>
        {name}
      </StyledLabel>
      {onClear && (
        <div>
          {direction === 'vertical' && (
            <DesktopButton
              kind="link"
              variant="transparent"
              size="small"
              style={{ marginLeft: rem(8) }}
              icon={Close}
              iconProps={{ size: 13, align: 'right' }}
              onClick={onClear}
            >
              wyczyść{' '}
            </DesktopButton>
          )}

          <MobileButton
            kind="box"
            variant="transparent"
            size="small"
            style={{ marginLeft: rem(8), zIndex: 5 }}
            icon={Bin}
            iconProps={{ size: 12, align: 'right' }}
            onClick={onClear}
          />
        </div>
      )}
    </StyledFlex>
  )
);

export const SearchFilterOptionsGrid = styled.div`
  grid-area: content;
  display: flex;
  flex-wrap: wrap;
  margin: -0.1875rem;

  & > * {
    margin: 0.1875rem !important;
  }
`;
