import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M32 0C14.3275 0 0 14.3275 0 32C0 49.6725 14.3275 64 32 64C49.6725 64 64 49.6725 64 32C64 14.3275 49.6725 0 32 0ZM61.919 32C61.919 48.523 48.525 61.919 32 61.919C15.475 61.919 2.081 48.523 2.081 32C2.081 15.477 15.477 2.081 32 2.081C48.523 2.081 61.919 15.477 61.919 32Z" />
    <path d="M32.0021 13.0391V14.0788C41.8841 14.0788 49.9253 22.1183 49.9253 32.0021H50.9651C50.9651 21.5298 42.4743 13.0391 32.0021 13.0391ZM32.0021 49.9253C22.1201 49.9253 14.0788 41.8858 14.0788 32.0021H13.0391C13.0391 42.4743 21.5298 50.9651 32.0021 50.9651" />
    <path d="M47.3235 18.4966C47.7578 18.9596 48.3835 19.7064 48.735 20.1599L55.209 16.0791C54.8855 15.6591 54.3705 15.0206 53.9853 14.5709L49.8855 17.2626L49.6055 17.5016L49.814 17.1991L51.6243 13.6114L50.3443 12.3401L46.7295 14.1376L46.425 14.3449L46.6675 14.0669L49.3798 9.99712C48.9025 9.59412 48.4438 9.22437 47.8593 8.77637L43.75 15.1999C44.2708 15.5976 44.9568 16.1819 45.3948 16.5794L49.2633 14.5859L49.5115 14.4046L49.329 14.6511L47.3235 18.4966Z" />
    <path d="M32.6352 10.3562L34.4042 6.42092L34.517 6.07192L34.485 6.43592L34.671 11.6947C35.2915 11.7524 35.9325 11.8384 36.564 11.9429L36.2762 4.15467C35.393 4.05767 34.5132 3.98867 33.6335 3.95117L31.891 8.24667L31.829 8.56592L31.767 8.24667L30.0262 3.95117C29.1465 3.98867 28.2667 4.05767 27.3835 4.15467L27.0957 11.9429C27.7272 11.8384 28.3682 11.7544 28.9887 11.6947L29.1747 6.43592L29.1427 6.07192L29.2555 6.42092L31.0245 10.3562H32.6352Z" />
    <path d="M18.3246 16.5636C19.3471 15.4941 19.9261 14.2581 18.9036 13.0783C18.3471 12.4381 17.4128 12.2981 16.6383 12.6136L16.5613 12.6433L16.5858 12.5761C16.7006 12.2718 16.7738 11.3011 15.9411 10.6311C15.5351 10.3043 15.0256 10.1831 14.5106 10.2353C13.5501 10.3323 12.8113 10.9838 10.8001 13.2108C10.1928 13.8828 9.3093 14.9433 8.7793 15.6303L14.3038 20.8348C16.1346 18.8223 16.8808 18.0738 18.3246 16.5636ZM11.1158 15.2906C12.2286 13.9388 13.4108 12.7126 13.9466 12.2271C14.1158 12.0741 14.3001 11.9211 14.5238 11.8613C14.8866 11.7623 15.2606 12.0181 15.3451 12.3876C15.4298 12.7591 15.1948 13.1121 14.9336 13.4031C14.3451 14.0621 12.1873 16.3041 12.1873 16.3041L11.1158 15.2906ZM13.2793 17.3291C13.2793 17.3291 15.3808 15.1226 16.0671 14.4168C16.3396 14.1368 16.5143 13.9668 16.7023 13.8756C16.9448 13.7578 17.2098 13.7336 17.4468 13.8903C17.6798 14.0453 17.7868 14.3123 17.7286 14.5773C17.6591 14.8928 17.3998 15.1933 17.1853 15.4231C16.8961 15.7311 14.4033 18.3801 14.3996 18.3838L13.2793 17.3291Z" />
    <path d="M32.0021 13.0391V32.0021H13.0391C13.0391 21.5223 21.5223 13.0391 32.0021 13.0391Z" />
    <path d="M50.963 32C50.963 42.4798 42.4798 50.963 32 50.963V32H50.963Z" />
  </IconBox>
);
