import React from 'react';
import { StyleProps } from '@summer/react-kit';
import { OptionFilter } from 'src/app/common/models/filter';
import { ConnectedCommonOptionFilter } from 'src/app/search/components/SearchPanel/CommonOptionFilter';
import styled, { css } from 'styled-components';
import { ToggleButton } from 'src/common/components/ToggleButton';
import { media } from 'src/config/breakpoints';

const StyledToggleButton = styled(ToggleButton)`
  justify-content: center;
  border-radius: 0.125rem;

  ${media.w.min.px768(css`
    width: 7.5rem;
  `)};
`;

export const SearchUpholsteryTypeInteriorFilter = (props: StyleProps) => (
  <ConnectedCommonOptionFilter
    columns={{ desktop: 2, mobile: 2 }}
    direction="vertical"
    kind={OptionFilter.UpholsteryType}
    toggleButtonComponent={StyledToggleButton}
    {...props}
  />
);
