import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="12 0 41 64" {...props}>
    <path d="M51.6031 20.5565C51.6031 19.3024 50.5557 18.2857 49.2635 18.2857H15.3396C14.0475 18.2857 13 19.3024 13 20.5565V54.6181C13 55.8722 14.0475 56.8889 15.3396 56.8889H49.2635C49.2635 56.8889 51.6031 56.8889 51.6032 54.6181C51.6032 47.305 51.6031 31.5466 51.6031 20.5565ZM18.849 52.3473C18.849 52.9744 18.3252 53.4827 17.6792 53.4827C17.0331 53.4827 16.5094 52.9744 16.5094 52.3473V22.8273C16.5094 22.2002 17.0331 21.6919 17.6792 21.6919C18.3252 21.6919 18.849 22.2002 18.849 22.8273V52.3473ZM24.6979 52.3473C24.6979 52.9744 24.1742 53.4827 23.5281 53.4827C22.8821 53.4827 22.3583 52.9744 22.3583 52.3473V22.8273C22.3583 22.2002 22.8821 21.6919 23.5281 21.6919C24.1742 21.6919 24.6979 22.2002 24.6979 22.8273V52.3473ZM30.5469 52.3473C30.5469 52.9744 30.0231 53.4827 29.3771 53.4827C28.731 53.4827 28.2073 52.9744 28.2073 52.3473V22.8273C28.2073 22.2002 28.731 21.6919 29.3771 21.6919C30.0231 21.6919 30.5469 22.2002 30.5469 22.8273V52.3473ZM36.3958 52.3473C36.3958 52.9744 35.8721 53.4827 35.226 53.4827C34.58 53.4827 34.0563 52.9744 34.0563 52.3473V22.8273C34.0563 22.2002 34.58 21.6919 35.226 21.6919C35.8721 21.6919 36.3958 22.2002 36.3958 22.8273V52.3473Z" />
    <path
      d="M41.4436 53.8413C42.0047 53.8413 42.4595 53.3215 42.4595 52.6803V22.4943C42.4595 21.8531 42.0047 21.3333 41.4436 21.3333C40.8826 21.3333 40.4277 21.8531 40.4277 22.4943V52.6803C40.4277 53.3215 40.8826 53.8413 41.4436 53.8413Z"
      fill="#202229"
    />
    <path
      d="M47.5393 53.8413C48.1004 53.8413 48.5552 53.3215 48.5552 52.6803V22.4943C48.5552 21.8531 48.1004 21.3333 47.5393 21.3333C46.9783 21.3333 46.5234 21.8531 46.5234 22.4943V52.6803C46.5234 53.3215 46.9783 53.8413 47.5393 53.8413Z"
      fill="#202229"
    />
    <path d="M23.1582 10.1588H25.1899V16.254H23.1582V10.1588Z" />
    <path d="M40.4277 10.1588H42.4595V16.254H40.4277V10.1588Z" />
    <path d="M23.2654 8.12698H25.511C26.1311 8.12698 26.6338 7.60719 26.6338 6.96599V4.64399C26.6338 4.00279 27.1365 3.48299 27.7566 3.48299H37.8619C38.482 3.48299 38.9847 4.00279 38.9847 4.64399V6.96599C38.9847 7.60719 39.4874 8.12698 40.1075 8.12698H42.3531C42.9732 8.12698 43.4759 7.60719 43.4759 6.96599V1.161C43.4759 0.519796 42.9732 0 42.3531 0H23.2654C22.6453 0 22.1426 0.519796 22.1426 1.161V6.96599C22.1426 7.60719 22.6453 8.12698 23.2654 8.12698Z" />
    <path d="M21.127 62.7301C21.127 63.4315 21.5818 64 22.1428 64H24.1746C24.7356 64 25.1904 63.4315 25.1904 62.7301V58.9206H21.127V62.7301Z" />
    <path d="M39.4121 62.7301C39.4121 63.4315 39.8669 64 40.428 64H42.4597C43.0208 64 43.4756 63.4315 43.4756 62.7301V58.9206H39.4121V62.7301Z" />
  </IconBox>
);
