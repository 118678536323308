import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M43 10h-5v5h12v6h5v-6h9v33h-9v-5h-5v12H31l-4-4H15V41H6v-7H2v13H0V18h2v11h4v-7h8l7-7h10v-5h-5V8h17v2zM22 35l14-14v10h7L29 45V35h-7z"
    />
  </IconBox>
);
