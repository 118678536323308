import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.8861 22.3632C55.2823 23.4368 53.4129 24.1447 51.3975 24.3675V24.3677C53.4129 24.1449 55.2822 23.437 56.8861 22.3635V22.3632ZM10.2329 5.23291H36.5774C35.7222 6.90594 35.17 8.7596 34.9932 10.7215H10.2329C9.50507 10.7215 8.80705 11.0106 8.29239 11.5253C7.77774 12.04 7.48861 12.738 7.48861 13.4658V50.7493L14.5848 43.6532H48.6532C49.381 43.6532 50.079 43.364 50.5937 42.8494C51.1083 42.3347 51.3975 41.6367 51.3975 40.9089V27.1258C53.3594 26.949 55.213 26.3968 56.8861 25.5415V40.9089C56.8861 43.0924 56.0187 45.1864 54.4747 46.7304C52.9307 48.2744 50.8367 49.1418 48.6532 49.1418H16.8582L2 64V13.4658C2 11.2823 2.86739 9.18824 4.41136 7.64427C5.95533 6.1003 8.04941 5.23291 10.2329 5.23291Z"
    />
    <path
      d="M62 12C62 18.6274 56.6274 24 50 24C43.3726 24 38 18.6274 38 12C38 5.37258 43.3726 0 50 0C56.6274 0 62 5.37258 62 12Z"
      id="indicator"
    />
  </IconBox>
);
