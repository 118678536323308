import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { NavigationState } from 'src/state/navigation/navigationReducer';

export const navigationStateSelector = (state: RootState) => state.navigation;

export const isMenuOpenSelector = createSelector(
  navigationStateSelector,
  (state: NavigationState) => state.isOpen
);
