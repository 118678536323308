import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M0.312012 36.0333H19.5648V25.3487L19.9428 25.3136C20.145 25.296 21.0417 25.2873 21.9384 25.3048C23.6175 25.3311 25.4109 25.5241 27.6527 25.9013C28.356 26.0241 29.0856 26.1382 29.279 26.1645C30.4834 26.3399 33.8065 27.1908 34.9494 27.6119C35.3098 27.7523 35.7054 27.8839 35.8285 27.9189C36.6725 28.1558 39.6087 29.805 39.7318 30.1208C39.7582 30.191 39.8373 30.2436 39.9164 30.2436C39.9867 30.2436 40.0483 30.2699 40.0483 30.305C40.0483 30.3489 40.2153 30.5506 40.4087 30.7699C41.112 31.5507 41.5252 32.5507 41.4109 33.2525C41.2263 34.4017 40.4439 35.2701 39.1076 35.8228L38.5977 36.0333H64.312V35.7C64.312 35.5158 64.1977 35.0157 64.0483 34.5859C63.4065 32.6911 61.4988 30.4629 59.0812 28.7962C58.7472 28.5593 58.4571 28.34 58.4395 28.2962C58.4219 28.2611 58.3692 28.226 58.3252 28.226C58.2812 28.226 57.9823 28.0593 57.6571 27.8575C57.1296 27.5417 55.1604 26.5592 55.0373 26.5592C55.0109 26.5592 54.8263 26.4803 54.6153 26.3926C53.8417 26.0504 51.6263 25.4188 51.1867 25.4188C50.9845 25.4188 50.8703 25.3837 50.9054 25.3311C50.9318 25.2785 51.1428 25.2434 51.3714 25.2434C52.0219 25.2434 54.4043 24.6469 55.4417 24.2258C57.1823 23.5152 59.512 22.1117 60.5318 21.1555C61.956 19.8046 62.5889 19.1028 63.1164 18.2782C63.8021 17.208 64.312 15.9272 64.312 15.2605V14.9798L51.4593 14.9886C44.3823 14.9886 38.6944 15.0237 38.8175 15.0587C41.1208 15.7693 41.3845 18.015 39.3889 19.8747C38.5098 20.6906 37.4637 21.2959 35.4329 22.1731C34.967 22.3748 34.0878 22.6994 33.8944 22.7345C33.7714 22.7608 33.4812 22.8398 33.2351 22.91C31.8637 23.3135 31.1164 23.5065 30.501 23.6205C30.123 23.6907 29.3933 23.8223 28.8834 23.9188C28.0659 24.0767 25.9911 24.4188 25.6307 24.4451C25.5604 24.4539 25.1648 24.4977 24.7516 24.5416C24.3384 24.5855 23.0021 24.6469 21.7889 24.682L19.5648 24.7434V14.6289H0.312012V36.0333Z" />
    <path d="M61.8505 41.4721H64.312V39.63H61.8505V41.4721Z" />
    <path d="M53.9384 49.1917H56.312V48.3759C56.312 47.5864 56.3208 47.5601 56.6021 47.2443C56.7516 47.0688 56.9714 46.8144 57.0768 46.6828C57.1911 46.5512 57.2878 46.4547 57.3142 46.4811C57.3318 46.4986 57.5955 47.0688 57.8944 47.7443C58.1933 48.4197 58.4834 49.025 58.5274 49.0777C58.6329 49.2093 61.4109 49.2443 61.4109 49.1128C61.4109 49.0689 60.901 48.0162 60.2768 46.7706L59.134 44.5161L60.0746 43.4108C60.5845 42.8055 61.0593 42.2353 61.1296 42.1476C61.2527 42.0072 61.1735 41.9984 59.6439 41.9984H58.0175L57.4725 42.7178C57.1735 43.1213 56.7955 43.6038 56.6285 43.7967L56.3208 44.1476L56.312 42.016V39.8931H53.9384V49.1917Z" />
    <path d="M0.312012 49.1918H3.03729V46.6215L3.54718 46.0864C3.8197 45.7881 4.06586 45.5425 4.09223 45.525C4.1186 45.5162 4.54937 46.332 5.05926 47.3496L5.97355 49.1918H7.62629C8.53179 49.1918 9.27904 49.1655 9.27904 49.1304C9.27904 49.0953 8.90102 48.3759 8.44388 47.5163C7.98673 46.6653 7.60871 45.9285 7.60871 45.8758C7.60871 45.8232 7.57355 45.7706 7.52959 45.753C7.48564 45.7355 7.23069 45.2881 6.95816 44.7618C6.68564 44.2267 6.40432 43.6915 6.3252 43.5687L6.17575 43.3407L7.2219 42.1213C7.79333 41.4458 8.44388 40.6914 8.64607 40.437L9.03289 39.9809H5.36695L4.19772 41.5336L3.03729 43.0863V39.9809H0.312012V49.1918Z" />
    <path d="M39.9603 41.823C38.5449 41.9633 37.8065 42.3581 37.4021 43.2002C37.235 43.5423 37.2174 43.6915 37.2526 44.3494C37.3405 45.8407 37.9032 46.2179 40.5581 46.6039C41.3054 46.7091 41.4988 46.7705 41.6306 46.9284C41.7889 47.1214 41.7889 47.139 41.6218 47.3144C41.446 47.5074 41.3317 47.5337 40.8394 47.5337C40.4262 47.5337 40.1361 47.3583 40.0746 47.0688L40.0306 46.8232H37.0768L37.1295 47.2355C37.1999 47.9373 37.6658 48.639 38.2988 49.0075C38.8614 49.3408 40.9713 49.4812 42.3603 49.2794C43.9515 49.0513 44.7515 47.8759 44.4438 46.2354C44.3471 45.7354 44.2768 45.5863 44.0043 45.3231C43.6262 44.9634 43.1515 44.8231 41.4548 44.5862C40.4174 44.4371 39.8812 44.3143 39.8636 44.209C39.8636 44.1739 39.846 44.0862 39.8284 44.0073C39.7845 43.7441 39.9427 43.6388 40.4174 43.6037C40.9889 43.5511 41.3405 43.6827 41.4548 43.9722L41.5427 44.1915H44.2944L44.2328 43.7441C44.0922 42.7265 43.1867 42.016 41.8504 41.8756C40.8306 41.7616 40.6109 41.7616 39.9603 41.823Z" />
    <path d="M11.6966 41.9546C10.8174 42.1125 10.3691 42.323 10.0262 42.7266C9.73611 43.0599 9.63941 43.288 9.49875 43.9898L9.43721 44.2793H10.7207C11.7229 44.2793 12.0042 44.2529 12.0042 44.1652C12.0042 43.7617 12.9009 43.4897 13.5955 43.6915C14.0526 43.8231 14.1845 44.0073 14.0526 44.3056C13.9647 44.4898 13.8592 44.5424 13.3493 44.6214C13.024 44.674 12.3998 44.7354 11.9603 44.7617C10.8614 44.8407 10.7735 44.8495 10.4218 44.9284C10.035 45.0249 9.56029 45.3319 9.48996 45.5249C9.46359 45.6126 9.41084 45.6828 9.36688 45.6828C9.32293 45.6828 9.22622 45.8583 9.1471 46.0776C9.03282 46.3758 9.00644 46.6478 9.03282 47.2092C9.06798 47.8408 9.11194 48.0075 9.32293 48.3321C9.90315 49.2005 10.5185 49.4286 12.0922 49.3409C13.0592 49.2882 13.3581 49.1742 13.9031 48.6479L14.2636 48.3057L14.3251 48.7444L14.3867 49.1917H15.6526C16.8218 49.1917 16.9273 49.183 16.9273 49.0338C16.9273 48.9461 16.8834 48.8321 16.8306 48.7794C16.7603 48.7093 16.7251 47.911 16.7075 46.2706C16.69 44.2442 16.6636 43.8231 16.5493 43.5775C16.4614 43.4196 16.3999 43.2529 16.3999 43.209C16.3999 43.1652 16.3471 43.0774 16.2944 43.016C16.2328 42.9546 16.057 42.7616 15.9075 42.595C15.3185 41.937 13.3757 41.6476 11.6966 41.9546ZM14.202 46.3495C14.0702 46.9285 13.9471 47.0952 13.4812 47.332C12.5757 47.7794 11.6086 47.4197 11.7933 46.7004C11.8548 46.446 12.1537 46.332 13.0592 46.2004C13.4724 46.1478 13.9031 46.0513 14.0262 45.9899C14.1493 45.9284 14.2548 45.8846 14.2724 45.9021C14.2812 45.9109 14.2548 46.1127 14.202 46.3495Z" />
    <path d="M31.3977 41.9195C30.8087 42.016 30.0263 42.3143 29.7626 42.5511C29.4109 42.8582 29.1736 43.3231 29.1032 43.8144L29.0417 44.2793H30.2812C31.5208 44.2793 31.5296 44.2793 31.6087 44.0688C31.723 43.7617 31.9779 43.6565 32.6021 43.6477C33.4285 43.6301 33.9032 43.9459 33.6483 44.3407C33.5252 44.5337 33.0856 44.6302 31.9692 44.7267C31.3626 44.7705 30.7999 44.8232 30.7296 44.8495C30.6593 44.867 30.4043 44.9021 30.1582 44.9372C29.6571 44.9986 29.3845 45.1477 29.0329 45.5425C28.7076 45.9197 28.5845 46.3232 28.5757 47.0426C28.5757 47.6478 28.8043 48.49 28.9714 48.49C29.0153 48.49 29.0768 48.5689 29.1032 48.6654C29.1296 48.7619 29.3406 48.9461 29.5692 49.0777C29.9648 49.3146 30.0263 49.3234 31.2131 49.3234C32.3296 49.3234 32.479 49.3058 32.8395 49.1216C33.0593 49.0075 33.3582 48.797 33.5164 48.6479L33.7977 48.3759L33.8505 48.6303C33.9911 49.2356 33.8768 49.1918 35.2659 49.1918C35.9604 49.1918 36.5318 49.1655 36.5318 49.1391C36.5318 49.1128 36.4878 49.0076 36.4263 48.9023C36.356 48.7707 36.312 48.0426 36.2944 46.7355C36.2681 44.4109 36.2241 43.8144 36.0834 43.4547C35.9076 43.0248 35.3889 42.4371 35.0109 42.2529C34.3164 41.8932 32.523 41.7265 31.3977 41.9195ZM33.7714 46.3583C33.6746 47.1127 32.901 47.6128 32.0395 47.4987C31.5911 47.4373 31.345 47.2268 31.345 46.8934C31.345 46.446 31.6439 46.3057 32.8922 46.1653C33.1823 46.1302 33.4812 46.0688 33.5516 46.025C33.7889 45.8758 33.8329 45.946 33.7714 46.3583Z" />
    <path d="M47.7934 41.9195C46.7032 42.1301 46.4043 42.288 45.8769 42.902C45.701 43.1126 45.4109 43.8933 45.4109 44.1652C45.4109 44.253 45.6835 44.2793 46.6681 44.2793H47.934L48.0307 44.06C48.1538 43.7968 48.4791 43.6564 49.0153 43.6477C49.6483 43.6301 49.9824 43.7442 50.0791 43.9898C50.123 44.1038 50.1318 44.2442 50.1054 44.2968C49.9824 44.4986 49.3934 44.6389 48.3384 44.7267C46.6944 44.867 46.6241 44.8758 46.3164 44.9723C45.3406 45.2705 44.8395 46.2969 45.0593 47.5513C45.2966 48.876 45.9999 49.3584 47.6966 49.3672C48.567 49.3672 49.2351 49.2181 49.5428 48.9549C50.2549 48.3409 50.1934 48.3759 50.2637 48.5514C50.2901 48.6391 50.3428 48.8146 50.3692 48.9461L50.4219 49.1918H51.6967C52.9978 49.1918 53.156 49.1479 52.8659 48.8584C52.778 48.7707 52.734 48.104 52.6989 46.4022C52.6549 44.5074 52.6197 43.9635 52.4967 43.56C52.1802 42.5775 51.5208 42.095 50.2109 41.9195C49.4285 41.8055 48.3472 41.8055 47.7934 41.9195ZM50.1494 46.5864C49.8945 47.1917 49.3406 47.525 48.567 47.525C48.2241 47.525 48.1186 47.4899 47.9692 47.297C47.767 47.0426 47.7406 46.8145 47.8725 46.5601C47.9604 46.3934 48.4263 46.2706 49.3846 46.1566C49.6571 46.1302 49.956 46.0513 50.0439 45.9899C50.1846 45.8846 50.2021 45.8934 50.2285 46.0951C50.2461 46.218 50.2109 46.4373 50.1494 46.5864Z" />
    <path d="M17.2264 42.13C17.2527 42.209 17.3846 42.6915 17.5077 43.209C17.6395 43.7266 17.9209 44.8407 18.1406 45.6828C18.3516 46.525 18.545 47.332 18.5714 47.4811C18.589 47.6215 18.6945 48.0601 18.8088 48.4461L19.011 49.1479L21.7538 49.2005L21.8945 48.6917C22.2989 47.2969 22.7736 45.8056 22.8352 45.7267C22.8703 45.6828 22.9055 45.7267 22.9055 45.8407C22.9055 45.9372 22.9406 46.1127 22.9846 46.2267C23.0286 46.3407 23.2571 47.0513 23.4857 47.8057L23.8989 49.1917H26.6681L26.756 48.8145C26.8088 48.6128 26.967 47.9899 27.1253 47.4373C27.2747 46.8846 27.4154 46.332 27.433 46.2092C27.4505 46.0863 27.6351 45.3933 27.8286 44.674C28.0308 43.9459 28.2681 43.0511 28.3472 42.6739L28.5055 41.9985H25.8242L25.7626 42.323C25.7187 42.5072 25.6835 42.7792 25.6659 42.9371C25.6483 43.095 25.5165 43.709 25.3758 44.2968C25.2352 44.8933 25.0857 45.5249 25.0505 45.7091C24.9802 46.139 24.9011 46.0425 24.7165 45.2881C24.6286 44.9459 24.4088 44.1828 24.2242 43.5775C24.0395 42.9722 23.8637 42.3669 23.8286 42.2353L23.7758 41.9897L21.9209 42.0423L21.5692 43.2266C21.3846 43.8757 21.0945 44.8582 20.9362 45.4109L20.6461 46.4021L20.5846 45.9109C20.5231 45.3758 20.0483 42.8845 19.9077 42.323L19.8198 41.9985H18.5011C17.3494 41.9985 17.1824 42.016 17.2264 42.13Z" />
    <path d="M61.8505 49.1918H64.312V41.9985H61.8505V49.1918Z" />
  </IconBox>
);
