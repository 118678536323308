import styled, { css } from 'styled-components';

export interface FormActionsContainerProps {
  justifyContent?: string;
}
export const FormActionsContainer = styled.div<FormActionsContainerProps>`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
`;
