import { transparentize } from 'polished';
import React, { memo, useMemo, useRef, useState } from 'react';
import MediaQuery from 'react-responsive';
import styled, { css } from 'styled-components';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { Product } from 'src/app/common/models/product';
import { ProductSummaryOfferDisclaimerTooltip } from 'src/app/product/components/ProductPageSummary/ProductSummaryOfferTooltip';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { Title } from 'src/common/components/typography/Title';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useSelector } from 'react-redux';
import {
  activeOfferSelector,
  availableOffersSelector,
} from 'src/app/product/state/products/productsSelectors';
import { ArtefaktaCalculator } from 'src/app/product/components/ProductPageSummary/ArtefaktaCalculator';
import {
  isArtefakta4DekraOffer,
  isInternalOffer,
} from 'src/common/models/offer';
import { navigate } from 'gatsby';
import { Button } from 'src/common/components/Button';
import { setParams } from '@summer/react-kit';
import { ProductOrderItemPageRoute } from 'src/app/routes';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { CarPlatformBusinessTooltipButton } from 'src/app/product/components/ProductPageSummary/CarPlatformBusinessTooltipButton';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { BaseInternalCalculator } from 'src/app/product/components/ProductPageSummary/BaseInternalCalculator';
import { Box } from '@mui/material';

const Container = styled.div<{ background?: boolean; scrollVisible: boolean }>`
  align-items: center;
  background: ${colors.background};
  display: flex;
  justify-content: center;
  right: 0;
  transition: background 0.2s ease;
  z-index: 1000;
  height: 100%;

  ${(props) =>
    props.scrollVisible &&
    css`
      ${media.w.max.any(1456)(
        css`
          margin-right: 0.875rem;

          &:after {
            position: absolute;
            right: 0;
            margin-right: -0.875rem;
            content: '';
            width: 0.875rem;
            height: 100%;
            background: ${colors.background};
            opacity: 0.95;
          }
        `
      )}
    `}

  ${(props) =>
    media.w.min.px1200(css`
      position: sticky;
      float: right;
      top: 0;
      align-items: flex-start;
      background: ${props.background
        ? colors.background
        : transparentize(0.4, colors.background)};
      padding-bottom: 0;
      padding-top: 0;
      width: 16rem;
    `)}
`;

const Wrapper = styled(ConstraintContainer)`
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  max-width: 30rem;
  min-width: 20rem;

  ${media.w.min.px1200(css`
    min-width: 0;
    padding: 0;
    max-height: calc(100vh - 6.875rem);
  `)}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  width: 100%;
  overflow: visible !important;

  ${media.w.min.px1200(css`
    padding: 0 1.25rem;
    position: sticky;
    top: 2.75rem;
  `)}

  .os-padding {
    overflow: visible !important;
  }

  .os-viewport {
    padding-left: 100px;
    margin-left: -100px;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.grayBasic};

  & > strong {
    color: ${colors.white};
  }

  & > small {
    color: ${colors.white};
    font-weight: 300;
  }
`;

const SummaryButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: calc(-3.1875rem - 1.4375rem);
`;

const SummaryButton = styled(Button)<{ $isLoggedIn: boolean }>`
  ${({ $isLoggedIn }) =>
    !$isLoggedIn &&
    css`
      white-space: normal;
      max-width: 50%;

      .btn__content {
        font-size: 0.8125rem;
      }
    `}
`;

const TooltipContainer = styled.div<{ scrollVisible: boolean }>`
  display: none;

  ${media.w.min.px1200(css`
    display: block;
    text-align: left;
  `)};

  ${(props) =>
    props.scrollVisible &&
    css`
      ${media.w.min.px1200(css`
        padding-bottom: 0.5rem;
      `)};
    `}
`;

export interface ProductPageSummaryProps {
  isPageTop: boolean;
  product: Product;
  onButtonClick?: () => void;
}

export const ProductPageSummary = memo<ProductPageSummaryProps>(
  ({ isPageTop, product, onButtonClick }) => {
    const scrollRef = useRef<OverlayScrollbarsComponent>(null);
    const [scrollVisible, setScrollVisible] = useState<boolean>(false);
    const activeOffer = useSelector(activeOfferSelector);
    const availableOffers = useSelector(availableOffersSelector);

    const isLoggedIn = useSelector(isAuthenticatedSelector);

    window.addEventListener('resize', () => {
      scrollRef?.current?.osInstance()?.update();
    });

    const scrollOptions = useMemo(
      () => ({
        overflowBehavior: {
          x: 'v-s',
        } as const,
        resize: 'none' as const,
        autoUpdate: true,
        callbacks: {
          onUpdated: () => {
            const lengthRatioY = scrollRef?.current?.osInstance()?.scroll()
              ?.handleLengthRatio?.y;
            lengthRatioY && lengthRatioY < 1
              ? setScrollVisible(true)
              : setScrollVisible(false);
          },
        },
      }),
      [setScrollVisible]
    );

    const noOffers = availableOffers?.length === 0;

    const isArtefakta4Dekra =
      availableOffers?.[0] != null &&
      isArtefakta4DekraOffer(availableOffers?.[0]);

    const isInternal =
      availableOffers?.[0] != null && isInternalOffer(availableOffers?.[0]);

    const handleCheckAvailability = () => {
      if (activeOffer) {
        navigate(
          setParams(':productId', ':offerId')(ProductOrderItemPageRoute)(
            product?.id,
            activeOffer.id
          )
        );

        pushEvent(EventName.ButtonClick, {
          click_category: 'offer_details_cta',
          click_cta: 'sprawdz_dostepnosc',
        });
      }
    };

    return (
      <Container background={!isPageTop} scrollVisible={scrollVisible}>
        <StyledScrollContainer options={scrollOptions} ref={scrollRef}>
          <Wrapper>
            <MediaQuery maxWidth={below(widthBreakpoints.px1200)}>
              <StyledTitle level={2} variant="condensed">
                CHCĘ <strong>{product.label}</strong>
                <br />
                <small>{product.version}</small>
              </StyledTitle>
            </MediaQuery>

            {noOffers && <>Brak ofert</>}

            {isArtefakta4Dekra && <ArtefaktaCalculator product={product} />}

            {isInternal && <BaseInternalCalculator product={product} />}

            {activeOffer != null && (
              <>
                <MediaQuery minWidth={widthBreakpoints.px1200}>
                  <SummaryButtonContainer>
                    <SummaryButton
                      $isLoggedIn={isLoggedIn}
                      kind="skew"
                      size={isLoggedIn ? 'default' : 'small'}
                      onClick={handleCheckAvailability}
                    >
                      Sprawdź dostępność
                    </SummaryButton>
                    {!isLoggedIn && <CarPlatformBusinessTooltipButton />}
                  </SummaryButtonContainer>
                </MediaQuery>

                <TooltipContainer scrollVisible={scrollVisible}>
                  <ProductSummaryOfferDisclaimerTooltip />
                </TooltipContainer>
              </>
            )}

            {onButtonClick && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button onClick={onButtonClick}>Sprawdź dostępność</Button>
              </Box>
            )}
          </Wrapper>
        </StyledScrollContainer>
      </Container>
    );
  }
);
