import React, { ComponentType, memo } from 'react';
import { RangeFilter } from 'src/app/common/models/filter';
import { StyleProps } from '@summer/react-kit';
import { ToggleButtonProps } from 'src/common/components/ToggleButton';
import { useRangeFilterControl } from 'src/app/search/hooks/useFilterControl';
import { TagButton } from 'src/common/components/TagButton';
import { filterConfig } from 'src/app/common/models/filterConfig';

export const ConnectedCommonRangeFilterTags = memo(
  <T extends RangeFilter>({
    kind,
  }: StyleProps & {
    kind: T;
    toggleButtonComponent?: ComponentType<ToggleButtonProps>;
  }) => {
    const { state, pristine, reset } = useRangeFilterControl(kind);

    if (pristine) {
      return null;
    }

    return (
      <TagButton active={true} onClick={() => reset()}>
        {`${state.value[0]} - ${state.value[1]} ${filterConfig[kind].unit}`}
      </TagButton>
    );
  }
);
