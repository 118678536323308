import React, { memo, SetStateAction, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { Link } from 'gatsby';
import { PrivacyPolicyAndCookiesRoute } from 'src/public/publicRoutes';
import { typography } from 'src/config/typography';
import { Button } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import Close from 'src/assets/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  cookiesAdvertExternalSelector,
  cookiesAdvertInternalSelector,
  cookiesAnalyticSelector,
} from 'src/state/cookies/cookiesSelectors';
import { cookiesActions } from 'src/state/cookies/cookiesActions';
import { toNullable } from 'fp-ts/lib/Option';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { ExpansionPanel } from 'src/common/components/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelSummary } from 'src/common/components/ExpansionPanel/ExpansionPanelSummary';
import { ExpansionPanelDetails } from 'src/common/components/ExpansionPanel/ExpansionPanelDetails';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import { FormItem } from 'src/common/components/FormItem';
import { Form, Formik } from 'formik';
import ChevronRight from 'src/assets/icons/ChevronRight';

interface CookiesFormModel {
  analytic: boolean;
  marketing: boolean;
  advertInternal: boolean;
  advertExternal: boolean;
}

const initialValues: CookiesFormModel = {
  analytic: false,
  marketing: false,
  advertInternal: false,
  advertExternal: false,
};

const AcceptButtonContainer = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  flex-direction: column;

  .btn {
    font-weight: bold;
    text-transform: uppercase;
  }

  margin: 1rem 0 0.5rem;

  ${media.w.min.px768(css`
    margin: 0 2rem;
  `)};
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0.3125rem;
  top: 0.3125rem;
`;

const Container = styled(ConstraintContainer)`
  align-items: flex-end;
  position: relative;
  display: grid;
  grid-template-areas:
    'text text'
    'settings settings'
    'buttons buttons';
  padding-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;

  ${media.w.min.px768(css`
    text-align: left;
    grid-template-areas: 'text buttons' 'settings buttons';
  `)};
`;

const Text = styled.div`
  grid-area: text;
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.875rem;
  line-height: 1.0625rem;

  a {
    color: ${colors.lightGray};
  }
`;

const Wrapper = styled.div`
  background-color: rgba(42, 43, 50, 0.9);
  bottom: 0;
  left: 0;
  max-height: 100%;
  position: fixed;
  right: 0;
  z-index: 9999;

  ${media.w.max.px576(css`
    overflow: scroll;
  `)};
`;

const StyledExpansionPanel = styled(ExpansionPanel)`
  grid-area: settings;
  border: none;
  text-align: left;

  & .MuiAccordionSummary-content {
    flex-grow: 0;
  }
`;

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  && {
    border-bottom: none;

    ${media.w.min.px768(css`
      justify-content: flex-start;
    `)};
  }
`;

const SettingsTitle = styled.div`
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SettingsSubtitle = styled.div`
  margin-bottom: 1.875rem;
`;

const CookieName = styled.span`
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 0.625rem;
`;

const StyledCookieName = styled(CookieName)`
  margin-left: 1.875rem;
  display: block;
`;

const ConsentDetails = styled.div<{ expanded: boolean }>`
  margin-left: 4.125rem;

  ${({ expanded }) =>
    expanded
      ? null
      : css`
          display: none;
        `}
`;

const Dots = styled.span<{ expanded: boolean }>`
  margin-right: 0.625rem;

  ${({ expanded }) =>
    expanded
      ? css`
          display: none;
        `
      : null}
`;

const More = styled.span<{ expanded: boolean }>`
  ${({ expanded }) =>
    expanded
      ? null
      : css`
          display: none;
        `}
`;

const StyledSimpleLink = styled(SimpleLink)`
  margin-left: 1.875rem;
`;

const OptionsSelectedButton = styled(Button)`
  margin-top: 1.375rem;

  ${media.w.min.px768(css`
    margin-top: 1.875rem;
  `)};
`;

export const CookiesBanner = memo(() => {
  const cookiesAdvertInternal = useSelector(cookiesAdvertInternalSelector);
  const cookiesAdvertExternal = useSelector(cookiesAdvertExternalSelector);
  const cookiesAnalytic = useSelector(cookiesAnalyticSelector);
  const dispatch = useDispatch();
  const [analyticsExpanded, setAnalyticsExpanded] = useState(false);
  const [marketingExpanded, setMarketingExpanded] = useState(false);
  const [customSelect, setCustomSelect] = useState(false);

  if (
    toNullable(cookiesAnalytic) &&
    toNullable(cookiesAdvertInternal) &&
    toNullable(cookiesAdvertExternal)
  ) {
    return null;
  }

  const selectAll = (
    values: CookiesFormModel,
    setValues: (values: SetStateAction<CookiesFormModel>) => void
  ) => {
    if (values.advertInternal && values.advertExternal) {
      setValues({
        ...values,
        advertExternal: false,
        advertInternal: false,
        marketing: false,
      });
      if (values.analytic) {
        setCustomSelect(true);
      } else {
        setCustomSelect(false);
      }
    } else if (!values.advertInternal || !values.advertExternal) {
      setValues({
        ...values,
        advertExternal: true,
        advertInternal: true,
        marketing: true,
      });
      setCustomSelect(true);
    }
  };

  const onExternalChange = (
    values: CookiesFormModel,
    setValues: (values: React.SetStateAction<CookiesFormModel>) => void
  ) => {
    if (values.advertExternal) {
      setValues({ ...values, marketing: false, advertExternal: false });
    } else if (!values.advertExternal && values.advertInternal) {
      setValues({ ...values, marketing: true, advertExternal: true });
    }
    if (!values.advertExternal || values.advertInternal || values.analytic) {
      setCustomSelect(true);
    } else {
      setCustomSelect(false);
    }
  };

  const onInternalChange = (
    values: CookiesFormModel,
    setValues: (values: React.SetStateAction<CookiesFormModel>) => void
  ) => {
    if (values.advertInternal) {
      setValues({ ...values, marketing: false, advertInternal: false });
    } else if (values.advertExternal && !values.advertInternal) {
      setValues({ ...values, marketing: true, advertInternal: true });
    }
    if (!values.advertInternal || values.advertExternal || values.analytic) {
      setCustomSelect(true);
    } else {
      setCustomSelect(false);
    }
  };

  const customSelectCheck = (values: CookiesFormModel) => {
    if (values.advertInternal || values.advertExternal || !values.analytic) {
      setCustomSelect(true);
    } else {
      setCustomSelect(false);
    }
  };

  const handleSubmit = (values: CookiesFormModel) => {
    values.advertInternal
      ? dispatch(cookiesActions.acceptAdvertInternal())
      : dispatch(cookiesActions.declineAdvertInternal());
    values.advertExternal
      ? dispatch(cookiesActions.acceptAdvertExternal())
      : dispatch(cookiesActions.declineAdvertExternal());
    values.analytic
      ? dispatch(cookiesActions.acceptAnalytic())
      : dispatch(cookiesActions.declineAnalytic());
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, setValues }) => (
          <Form>
            <Container>
              <Text>
                W&nbsp;celu zapewnienia dostępu do naszej strony internetowej,
                analizy ruchu na stronie, dopasowywania prezentowanych
                informacji do Twoich preferencji i&nbsp;zainteresowań używamy
                własnych i&nbsp;zewnętrznych plików cookies. Ze względu na Twoją
                wygodę oraz dla dostarczenia Ci pozytywnych doświadczeń
                w&nbsp;korzystaniu z&nbsp;naszej strony i&nbsp;wysokiej jakości
                informacji zalecamy, abyś udzielił zgody na stosowanie plików
                cookies w&nbsp;pełnym zakresie, Twoja zgoda pozwoli nam również
                na przekazywanie określonych informacji dotyczących użytkowania
                przez Ciebie naszej strony podmiotom, które świadczą nam usługi
                w&nbsp;zakresie komunikacji w&nbsp;mediach społecznościowych,
                reklamy oraz analiz. Wybierając przycisk „Akceptuję wszystkie”
                i&nbsp;kontynuując korzystanie ze strony bez zmiany ustawień
                swojej przeglądarki, wyrażasz zgodę na stosowanie przez Mobility
                Benefit sp. z&nbsp;o.o. plików cookies w&nbsp;zakresie,
                w&nbsp;jakim nie są one niezbędne dla prawidłowego działania
                strony lub poszczególnych jej funkcjonalności, tj. w celu
                analitycznym i&nbsp;marketingowym. Zgodę możesz cofnąć
                w&nbsp;dowolnym momencie, zmieniając ustawienia swojej
                przeglądarki. Zamknięcie tego okna przyciskiem „X” w&nbsp;jego
                prawym, górnym rogu będzie równoznaczne z&nbsp;brakiem zgody na
                używanie plików cookies w&nbsp;wyżej wskazanych celach. Możesz
                też zarządzać swoimi preferencjami w&nbsp;zakresie korzystania
                przez nas z&nbsp;plików cookies klikając w&nbsp;przycisk
                „Zarządzaj ustawieniami”. Dowiedz się więcej o&nbsp;zasadach, na
                jakich przetwarzamy pliki cookies, z&nbsp;naszej{' '}
                <SimpleLink as={Link} to={PrivacyPolicyAndCookiesRoute}>
                  Polityki prywatności i&nbsp;cookies
                </SimpleLink>
                .
              </Text>
              <AcceptButtonContainer>
                <Button
                  kind="skew"
                  variant="secondary"
                  onClick={() => dispatch(cookiesActions.accept())}
                >
                  Akceptuję wszystko
                </Button>
                {customSelect && (
                  <div>
                    <OptionsSelectedButton
                      kind="box"
                      variant="transparent"
                      icon={ChevronRight}
                      type="submit"
                    >
                      Akceptuję wybrane
                    </OptionsSelectedButton>
                  </div>
                )}
              </AcceptButtonContainer>

              <CloseButtonContainer>
                <Button
                  kind="box"
                  variant="transparent"
                  icon={Close}
                  onClick={() => dispatch(cookiesActions.decline())}
                />
              </CloseButtonContainer>
              <StyledExpansionPanel>
                <StyledExpansionPanelSummary>
                  ZARZĄDZAJ USTAWIENIAMI
                </StyledExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <SettingsTitle>USTAWIENIA PLIKÓW COOKIES</SettingsTitle>
                  <SettingsSubtitle>
                    Tutaj możesz dokonać ustawień plików cookies, innych niż
                    konieczne do zapewnienia Ci dostępu do naszej strony
                    internetowej i&nbsp;korzystania z&nbsp;jej funkcjonalności.{' '}
                  </SettingsSubtitle>

                  <div>
                    <FormItem name="analytic">
                      <CheckboxControl
                        name="analytic"
                        label={
                          <span>
                            <CookieName>Analityczne</CookieName> Dzięki nim
                            mierzymy, w&nbsp;jaki sposób użyt
                            <Dots expanded={analyticsExpanded}>...</Dots>
                            <More expanded={analyticsExpanded}>
                              kownicy korzystają z&nbsp;naszej strony.
                              W&nbsp;oparciu o&nbsp;anonimowe zachowanie
                              użytkowników tworzone są statystyki, które
                              pomagają nam zrozumieć potrzeby odwiedzających
                              i&nbsp;doskonalić naszą stronę internetową.
                              W&nbsp;tym celu wykorzystujemy narzędzia Google
                              Analytics oraz LiveChat.
                            </More>{' '}
                            <SimpleLink
                              onClick={(event) => {
                                setAnalyticsExpanded(!analyticsExpanded);
                                event.preventDefault();
                              }}
                            >
                              {analyticsExpanded && 'Zwiń'}
                              {!analyticsExpanded && 'Rozwiń'}
                            </SimpleLink>
                          </span>
                        }
                        onChange={() => customSelectCheck(values)}
                      />
                    </FormItem>
                  </div>
                  <div>
                    <FormItem name="marketing">
                      <CheckboxControl
                        name="marketing"
                        label={
                          <span>
                            <CookieName>Reklamowe</CookieName>Dzięki nim
                            będziemy mogli kierować do Ciebie reklamę opartą na
                            Twoich preferencjach i&nbsp;ujawnionym wcześniej
                            zapot
                            <Dots expanded={marketingExpanded}>...</Dots>
                            <More expanded={marketingExpanded}>
                              rzebowaniu lub zainteresowaniu konkretną usługą
                              lub produktem, o&nbsp;których informujemy na
                              naszej stronie. Pozwolą nam i&nbsp;naszym
                              zewnętrznym dostawcom wykorzystać informacje
                              pochodzące z&nbsp;naszych i&nbsp;zewnętrznych
                              plików cookies umieszczonych w&nbsp;Twoim
                              urządzeniu na naszej i&nbsp;innych stronach
                              internetowych.
                            </More>
                            {!marketingExpanded && (
                              <SimpleLink
                                onClick={(event) => {
                                  setMarketingExpanded(true);
                                  event.preventDefault();
                                }}
                              >
                                Rozwiń
                              </SimpleLink>
                            )}
                          </span>
                        }
                        onChange={() => selectAll(values, setValues)}
                      />
                    </FormItem>
                  </div>
                  <ConsentDetails expanded={marketingExpanded}>
                    <StyledCookieName>
                      PERSONALIZACJA I&nbsp;WYŚWIETLANIE REKLAM NA STRONIE
                      MOBILITY BENEFIT SP. Z O.O.
                    </StyledCookieName>
                    <FormItem name="advertInternal">
                      <CheckboxControl
                        name="advertInternal"
                        label={
                          <div>
                            Dzięki niżej wymienionym narzędziom będziemy mogli
                            udostępniać Ci na naszej stronie internetowej treści
                            uwzględniające Twoje osobiste preferencje,
                            gromadzone na podstawie naszych własnych plików
                            cookies: Google Analitycs, Google Ads oraz Facebook
                            Ads i&nbsp;Facebook Audience Network.
                          </div>
                        }
                        onChange={() => onInternalChange(values, setValues)}
                      />
                    </FormItem>
                  </ConsentDetails>
                  <ConsentDetails expanded={marketingExpanded}>
                    <StyledCookieName>
                      PREZENTACJA SPERSONALIZOWANYCH REKLAM NA STRONACH
                      ZEWNĘTRZNYCH
                    </StyledCookieName>
                    <FormItem name="advertExternal">
                      <CheckboxControl
                        name="advertExternal"
                        label={
                          <div>
                            Nasze reklamy będą wyświetlane przez dostawców
                            zewnętrznych na ich stronach internetowych
                            i&nbsp;stronach internetowych innych podmiotów,
                            w&nbsp;oparciu o&nbsp;zewnętrzne pliki cookies,
                            gromadzące lub obierające informacje z&nbsp;naszej
                            strony internetowej i&nbsp;innych miejsc w
                            Internecie. W&nbsp;tym celu współpracujemy
                            z&nbsp;następującymi pomiotami i&nbsp;korzystamy
                            z&nbsp;następujących narzędzi: Google Analitycs,
                            Google Ads oraz Facebook Ads i&nbsp;Facebook
                            Audience Network.
                          </div>
                        }
                        onChange={() => onExternalChange(values, setValues)}
                      />
                    </FormItem>
                  </ConsentDetails>
                  {marketingExpanded && (
                    <StyledSimpleLink
                      onClick={() => setMarketingExpanded(false)}
                    >
                      Zwiń
                    </StyledSimpleLink>
                  )}
                </ExpansionPanelDetails>
              </StyledExpansionPanel>
            </Container>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
});
