import React, { CSSProperties, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { useDerivedState } from '@summer/react-kit/hooks';
import { media } from 'src/config/breakpoints';
import { rem } from 'polished';
import { typography } from 'src/config/typography';

const InvisibleCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

const LabelText = styled.span`
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.8125rem;
  line-height: 1.25;
  margin-top: 0.125rem;
`;

const Label = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: flex-start;
  vertical-align: middle;

  ${(props) =>
    !props.disabled &&
    media.w.min.px1200(css`
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    `)};

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};
`;

// todo: Exported for external styling in CheckboxField, change if there is a better solution.
export const InputWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  padding: 0 0.75rem 0 0;
  display: flex;

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `}
`;

const CheckboxInput = styled(InvisibleCheckboxInput)`
  ${(props) =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        & + svg #not-checked,
        & + svg #border {
          stroke: ${colors.primary};
        }
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      & + svg #not-checked,
      & + svg #border {
        stroke: ${colors.darkGray};
      }
    `}
`;

export interface CheckboxProps {
  initialValue?: boolean;
  value?: boolean;
  onChange?: (v: boolean) => void;
  name?: string;
  className?: string;
  label?: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  initialValue,
  value,
  onChange,
  name,
  className,
  label,
  style,
  disabled,
}) => {
  const [active, setActive] = useDerivedState(initialValue ?? false, {
    value,
    onChange,
  });

  return (
    <Label className={className} style={style} disabled={disabled}>
      <InputWrapper disabled={disabled}>
        <CheckboxInput
          name={name}
          checked={active}
          onChange={() => setActive((currentValue: boolean) => !currentValue)}
          disabled={disabled}
        />
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!active && (
            <rect
              x="1"
              y="1"
              width="16"
              height="16"
              stroke={colors.lightGray}
              id="not-checked"
            />
          )}
          {active && (
            <>
              <path
                d="M14.5 1H1V17H17V9"
                stroke={colors.lightGray}
                id="border"
              />
              <path
                d="M5 8.91994L8.45997 12.3799L18.8399 2"
                stroke={colors.primary}
                id="checkmark"
              />
            </>
          )}
        </svg>
      </InputWrapper>

      {typeof label === 'string' ? (
        <LabelText style={{ paddingRight: rem(8) }}>{label}</LabelText>
      ) : (
        label != null && label
      )}
    </Label>
  );
};

const SwitchLabel = styled(Label)`
  justify-content: space-between;
  align-items: center;
`;

const SwitchInputWrapper = styled(InputWrapper)`
  padding: 0.5rem;
`;

const SwitchCheckboxInput = styled(InvisibleCheckboxInput)`
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        & + svg #background-circle,
        & + svg #not-checked {
          @media (hover: hover) and (pointer: fine) {
            fill: ${colors.white};
          }
        }
      }

      &:focus {
        & + svg #background-circle,
        & + svg #not-checked {
          fill: ${colors.white};
        }
      }
    `}
`;

export const Switch: FC<CheckboxProps> = ({
  initialValue,
  value,
  onChange,
  className,
  label,
  style,
  disabled,
}) => {
  const [active, setActive] = useDerivedState(initialValue ?? false, {
    value,
    onChange,
  });

  return (
    <SwitchLabel className={className} style={style} disabled={disabled}>
      {typeof label === 'string' ? (
        <LabelText style={{ paddingLeft: rem(8) }}>{label}</LabelText>
      ) : (
        label != null && { label }
      )}

      <SwitchInputWrapper disabled={disabled}>
        <SwitchCheckboxInput
          checked={active}
          onChange={() => setActive((currentValue: boolean) => !currentValue)}
          disabled={disabled}
        />
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!active && (
            <path
              d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
              fill={colors.darkGray}
              id="not-checked"
            />
          )}
          {active && (
            <>
              <path
                d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
                fill={colors.primary}
                id="background-circle"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.75 6.00791L6.85776 14.625L3.375 11.25L4.93106 9.74209L6.85776 11.6092L14.1939 4.5L15.75 6.00791Z"
                fill={colors.background}
              />
            </>
          )}
        </svg>
      </SwitchInputWrapper>
    </SwitchLabel>
  );
};
