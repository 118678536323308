import styled, { css } from 'styled-components';
import React, { memo } from 'react';
import { media } from 'src/config/breakpoints';
import { useSelector } from 'react-redux';
import { userCustomerTypeSelector } from 'src/state/auth/authSelectors';
import { CustomerType } from 'src/common/models/customerType';

const Container = styled.div`
  padding-top: 0.1875rem;

  ${media.w.min.px1200(css`
    margin: 1.0625rem 0 1.25rem;
    padding-top: 0;
  `)}
`;

const Description = styled.div`
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 0.1875rem;
`;

const Text = styled.div`
  font-size: 1.475rem;
  font-weight: 700;
  line-height: 1;
`;

export const ProductPageNoOffers = memo(() => {
  const customerType = useSelector(userCustomerTypeSelector);

  return (
    <Container>
      <Text>Brak oferty</Text>

      {/*{offerCount > 0 && <Description>dla wybranych parametrów</Description>}*/}

      {/*{offerCount === 0 && (*/}
      {/*  <Description>*/}
      {/*    {customerType === CustomerType.B2B*/}
      {/*      ? 'dla klientów biznesowych'*/}
      {/*      : 'dla klientów indywidualnych'}*/}
      {/*  </Description>*/}
      {/*)}*/}
    </Container>
  );
});
