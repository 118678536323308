import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { colors } from 'src/config/colors';
import Car24h from 'src/assets/images/car-24h.svg';
import GiftCard from 'src/assets/images/gift-card.svg';
import DiscountBadge from 'src/assets/images/discount-badge.svg';
import Phone from 'src/assets/images/phone.svg';
import CarDiscount from 'src/assets/images/car-discount.svg';
import { skewBoth } from 'src/common/styles/skew';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1440px;
  margin: 5rem auto 1rem;
  padding: 0 1rem;
  position: relative;

  ${media.w.min.px768(css`
    margin: 0 auto;
    padding: 6.5625rem 5rem 0;
    display: flex;
    justify-content: space-between;
  `)};
`;

const HeadingWrapper = styled.div`
  ${media.w.min.px768(css`
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
  `)};
`;

const StyledSectionHeading = styled(SectionHeading)`
  text-align: left;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0.625rem;
  color: ${colors.lightGray};

  & span {
    color: ${colors.primary};
  }
`;

const Subtitle = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 3rem;
  color: ${colors.lightGray};

  ${media.w.min.px768(css`
    display: none;
    text-align: start;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.465rem;
  `)};
`;

const TilesWrapper = styled.div`
  ${media.w.min.px768(css`
    display: none;
  `)};
`;

const GradientTile = styled.div`
  position: absolute;
  width: 9.75rem;
  height: 4.375rem;
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  background: linear-gradient(
    127.68deg,
    #007fff 9.99%,
    rgba(0, 127, 255, 0) 46.64%
  );
`;

const Tile1 = styled(GradientTile)`
  top: -4.875rem;
  right: -2.75rem;
  z-index: 0;

  ${media.w.min.px992(css`
    top: -1.875rem;
    right: -0.75rem;
  `)};
`;

const Tile2 = styled(GradientTile)`
  top: -1.9rem;
  right: -4.25rem;
  z-index: 1;
  opacity: 0.9;

  ${media.w.between.px576.px768(css`
    top: 0;
    right: -5.25rem;
  `)};
`;

const Tile3 = styled(GradientTile)`
  top: 1.325rem;
  right: -6rem;
  z-index: 3;

  ${media.w.between.px576.px768(css`
    top: 5.75rem;
    right: -8.25rem;
  `)};
`;

const Tile4 = styled(GradientTile)`
  top: 2.75rem;
  right: -2.3rem;
  z-index: 2;

  ${media.w.between.px576.px768(css`
    top: 8.2rem;
    right: -1.75rem;
  `)};
`;

const Tile5 = styled(GradientTile)`
  top: 0;
  right: 4.75rem;
  z-index: 0;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.2) 8.04%,
    rgba(255, 255, 255, 0) 72.32%
  );
  opacity: 0.3;
`;

const BenefitsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  ${media.w.max.px768(css`
    &:before {
      content: '';
      ${skewBoth('13.5rem')};
      background: linear-gradient(
        255.91deg,
        #007fff -4.11%,
        rgba(0, 127, 255, 0) 30.48%
      );
      position: absolute;
      right: 11rem;
      top: -1.875rem;
      height: 100%;
      width: 40.1875rem;
      opacity: 0.7;
    }

    &:after {
      content: '';
      ${skewBoth('13.5rem')};
      background: linear-gradient(
        317.96deg,
        rgba(255, 255, 255, 0.2) 26.04%,
        rgba(255, 255, 255, 0) 82.36%
      );
      position: absolute;
      left: -10rem;
      height: 100%;
      width: 36.1875rem;
      opacity: 0.7;
    }
  `)};

  ${media.w.between.px576.px768(css`
    &:before {
      content: '';
      ${skewBoth('13.5rem')};
      background: linear-gradient(
        255.91deg,
        #007fff -4.11%,
        rgba(0, 127, 255, 0) 30.48%
      );
      position: absolute;
      top: -1.875rem;
      right: 15rem;
      height: 100%;
      width: 40.1875rem;
      opacity: 0.7;
    }

    &:after {
      content: '';
      ${skewBoth('13.5rem')};
      background: linear-gradient(
        317.96deg,
        rgba(255, 255, 255, 0.2) 26.04%,
        rgba(255, 255, 255, 0) 82.36%
      );
      position: absolute;
      left: 0;
      height: 100%;
      width: 36.1875rem;
      opacity: 0.7;
    }
  `)}

  ${media.w.min.px768(css`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    padding-bottom: 5rem;
  `)}

  ${media.w.min.px992(css`
    grid-template-columns: 1.5fr 1fr;
    grid-row-gap: 1rem;
  `)}
`;

const BenefitWrapper = styled.div`
  display: flex;
  font-size: 0.875rem;
  font-weight: 300;
  padding-top: 2rem;

  ${media.w.min.px992(css`
    padding-top: 0;

    &:nth-child(odd) {
      margin-top: 3.375rem;
    }
  `)};
`;

const Benefit1Wrapper = styled(BenefitWrapper)`
  ${media.w.max.px768(css`
    justify-content: flex-end;
  `)};
`;

const Benefit2Wrapper = styled(BenefitWrapper)`
  ${media.w.max.px576(css`
    justify-content: flex-end;
    margin-right: 2.5rem;
  `)};

  ${media.w.between.px576.px768(css`
    justify-content: flex-end;
    margin-right: 6rem;
  `)}

  ${media.w.between.px768.px992(css`
    margin-left: -4rem;
  `)}

  ${media.w.min.px992(css`
    margin-left: -8rem;
  `)};
`;

const Benefit3Wrapper = styled(BenefitWrapper)`
  ${media.w.max.px768(css`
    justify-content: center;
    margin-left: 0;
  `)};

  ${media.w.between.px768.px992(css`
    margin-left: -8rem;
  `)}

  ${media.w.min.px992(css`
    margin-left: 6rem;
  `)};
`;

const Benefit4Wrapper = styled(BenefitWrapper)`
  ${media.w.max.px768(css`
    justify-content: flex-start;
  `)};

  ${media.w.between.px576.px768(css`
    margin-left: 6rem;
  `)}

  ${media.w.between.px768.px992(css`
    margin-left: -12rem;
  `)}

  margin-left: 2.5rem;
`;

const Benefit5Wrapper = styled(BenefitWrapper)`
  ${media.w.max.px768(css`
    justify-content: flex-start;
    margin-left: 0;
  `)};

  ${media.w.between.px768.px992(css`
    margin-left: -16rem;
  `)}

  ${media.w.min.px992(css`
    margin-left: 12rem;
  `)};
`;

const Gradient = styled.div`
  ${media.w.min.px768(css`
    position: absolute;
    width: 22.723125rem;
    height: 9.251875rem;
    ${skewBoth('4rem')};
    background: linear-gradient(
      157.68deg,
      #007fff 9.99%,
      rgba(0, 127, 255, 0) 46.64%
    );
    opacity: 0.5;
  `)};
`;

const Benefit = styled.div`
  display: flex;
  z-index: 1;
  color: ${colors.lightGray};

  ${media.w.min.px768(css`
    font-size: 1rem;
    margin-top: 1.6875rem;
    margin-left: -1rem;
  `)};
`;

const BenefitName = styled.div`
  font-weight: 700;
  font-size: 0.9375rem;
  text-transform: uppercase;
  margin-bottom: 0.3125rem;

  ${media.w.min.px768(css`
    font-size: 1.2rem;
  `)};
`;

const Icon = styled.div`
  margin-right: 0.75rem;
  opacity: 0.5;

  svg {
    width: 1.625rem;
    height: 1.625rem;
  }

  ${media.w.min.px768(css`
    margin-left: 2.5rem;
    opacity: 1;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  `)};
`;

export const GeneralBenefitsSection: FC = () => {
  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px768,
  });

  return (
    <Container>
      <Content>
        <HeadingWrapper>
          <StyledSectionHeading>
            co zyskujesz dołączając <br /> do strefy <br />
            <span>car platform business?</span>
          </StyledSectionHeading>
          <Subtitle>
            otrzymaj dostęp do
            <br />
            niepowtarzalnych korzyści
          </Subtitle>

          <TilesWrapper>
            <Tile1 />
            <Tile2 />
            <Tile3 />
            <Tile4 />
            <Tile5 />
          </TilesWrapper>
        </HeadingWrapper>

        <BenefitsContainer>
          <Benefit1Wrapper>
            <Gradient />
            <Benefit>
              <Icon>
                <DiscountBadge />
              </Icon>
              <div>
                <BenefitName>
                  atrakcyjne rabaty <br />
                </BenefitName>
                na zakup aut prywatnych
              </div>
            </Benefit>
          </Benefit1Wrapper>
          <Benefit2Wrapper>
            <Gradient />
            <Benefit>
              <Icon>
                <GiftCard />
              </Icon>
              <div>
                <BenefitName>comiesięczny bonus</BenefitName>w programie
                Mobility 3.0
              </div>
            </Benefit>
          </Benefit2Wrapper>
          <Benefit3Wrapper>
            <Gradient />
            <Benefit>
              <Icon>
                <CarDiscount />
              </Icon>
              <div>
                <BenefitName>oferty specjalne</BenefitName>
                Auto miesiąca
              </div>
            </Benefit>
          </Benefit3Wrapper>
          <Benefit4Wrapper>
            <Gradient />
            <Benefit>
              <Icon>
                <Phone />
              </Icon>
              {isDesktop ? (
                <div>
                  <BenefitName>
                    wsparcie doradcy <br /> w uzyskaniu dotacji
                  </BenefitName>
                  w programie <br />
                  &quot;Mój Elektryk&quot;
                </div>
              ) : (
                <div>
                  <BenefitName>
                    wsparcie doradcy w uzyskaniu dotacji
                  </BenefitName>
                  w programie &quot;Mój Elektryk&quot;
                </div>
              )}
            </Benefit>
          </Benefit4Wrapper>
          <Benefit5Wrapper>
            <Gradient />
            <Benefit>
              <Icon>
                <Car24h />
              </Icon>
              {isDesktop ? (
                <div>
                  <BenefitName>
                    dostęp do aut <br /> przedkontraktowych
                  </BenefitName>
                  w oczekiwaniu <br /> na samochód
                </div>
              ) : (
                <div>
                  <BenefitName>dostęp do aut przedkontraktowych</BenefitName>w
                  oczekiwaniu na samochód
                </div>
              )}
            </Benefit>
          </Benefit5Wrapper>
        </BenefitsContainer>
      </Content>
    </Container>
  );
};
