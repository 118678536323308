import React, { useEffect, useState } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Seo } from 'src/public/components/Seo';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { AppRoute } from 'src/app/routes';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import { Title } from 'src/common/components/typography/Title';
import { LoginRoute } from 'src/public/publicRoutes';
import { CenteredLayout } from 'src/public/components/CenteredLayout';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Button } from 'src/common/components/Button';
import { Paragraph } from 'src/common/components/typography/Text';
import { BaseAxios } from 'src/common/services/axios';
import { parse } from 'qs';
import { object, ref, string } from 'yup';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = object().shape({
  password: string()
    .required('Pole wymagane')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Hasło musi mieć co najmniej 8 znaków, w tym co najmniej jedna wielka litera, jedna mała litera, jedna cyfra i jeden znak specjalny (@$!%*#?&)'
    ),
  passwordConfirmation: string()
    .required('Pole wymagane')
    .oneOf([ref('password'), ''], 'Hasła muszą być takie same'),
});

const ResetPasswordPage = ({ location: { search } }: PageProps) => {
  useStorePageUrl();

  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const [token, setToken] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const queryParams = parse(search.substr(1));

    if (!queryParams.hasOwnProperty('token')) {
      navigate(LoginRoute);
    }

    setToken(`${queryParams.token}`);
  }, []);

  if (isPlatformBrowser() && isLoggedIn) {
    navigate(AppRoute);
    return null;
  }

  return (
    <CenteredLayout>
      <div>
        <HomeLogoLink />
        <Seo title="Resetowanie hasła" />

        {!success && (
          <>
            <Title level={1} variant="condensed">
              Podaj nowe hasło
            </Title>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) =>
                BaseAxios.post('auth/reset-password', {
                  ...values,
                  code: token,
                })
                  .then(() => setSuccess(true))
                  .catch(() => setSubmitting(false))
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormItem name="password">
                    <TextControl
                      type="password"
                      name="password"
                      label="Nowe hasło"
                    />
                  </FormItem>
                  <FormItem name="passwordConfirmation">
                    <TextControl
                      type="password"
                      name="passwordConfirmation"
                      label="Potwierdź nowe hasło"
                    />
                  </FormItem>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      kind="skew"
                      size="small"
                      type="submit"
                      icon={ArrowRight}
                      disabled={isSubmitting}
                    >
                      Aktualizuj
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}

        {success && (
          <>
            <Title level={1} variant="condensed">
              Super, udało się!
            </Title>

            <Paragraph>
              Twoje hasło zostało zaktualizowane.
              <br />
              Teraz spokojnie możesz zalogować się do Car Platform.
            </Paragraph>

            <Button
              kind="skew"
              size="small"
              icon={ArrowRight}
              as={Link}
              to={LoginRoute}
              style={{ marginTop: '2vh' }}
            >
              Zaloguj się
            </Button>
          </>
        )}
      </div>
    </CenteredLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default ResetPasswordPage;
