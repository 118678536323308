import React from 'react';
import { fold, fromNullable, isNone, isSome, Option } from 'fp-ts/lib/Option';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOption = (a: any): a is Option<any> =>
  typeof a === 'object' && a.hasOwnProperty('_tag') && (isSome(a) || isNone(a));

export interface OptionalFoldProps<T> {
  value: Option<T> | T | null | undefined;
  onSome: (a: T) => JSX.Element;
  onNone?: JSX.Element;
}

export function OptionalFold<T = unknown>({
  value,
  onSome,
  onNone = <></>,
}: OptionalFoldProps<T>) {
  return fold<T, JSX.Element>(
    () => onNone,
    onSome
  )(isOption(value) ? (value as Option<T>) : fromNullable(value));
}
