import React, { FC, useEffect, useState } from 'react';
import { Button } from 'src/common/components/Button';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { TextControl } from 'src/common/components/TextControl';
import { Form, Formik } from 'formik';
import { HiddenContent } from 'src/common/components/HiddenContent';
import { FormItem } from 'src/common/components/FormItem';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { unsafeUserSelector } from 'src/state/auth/authSelectors';
import { updateUserActions } from 'src/state/auth/authActions';
import { useRequest } from 'src/lib/useRequest';

interface PersonalDataFormModel {
  firstName: string;
  lastName?: string;
  phone?: string;
}

const validationSchema = object().shape({
  firstName: string().required('Pole wymagane'),
  phone: string()
    .required('Pole wymagane')
    .matches(
      /^\+?[\d\s]*$/,
      'Numer telefonu może zaczynać się od + oraz musi składać się z samych cyfr i spacji'
    )
    .max(30, 'Numer telefonu powinien składać się z maksymalnie 30 znaków'),
});

export const ChangePersonalDataForm: FC = () => {
  const [isEditable, setIsEditable] = useState(false);
  const initialValues = useSelector(unsafeUserSelector);
  const dispatch = useDispatch();
  const { isLoading, timestamp } = useRequest(updateUserActions.request);

  useEffect(() => {
    if (timestamp != null) {
      setIsEditable(false);
    }
  }, [timestamp]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(v: PersonalDataFormModel) => {
          dispatch(updateUserActions.request(v));
        }}
      >
        <Form>
          <FormItem name="firstName">
            <TextControl
              name="firstName"
              label="Imię"
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </FormItem>
          <FormItem name="lastName">
            <TextControl
              name="lastName"
              label="Nazwisko"
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </FormItem>
          <FormItem name="phone">
            <TextControl
              name="phone"
              type="tel"
              label="Nr tel."
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </FormItem>

          <HiddenContent isVisible={isEditable}>
            <FormActionsContainer>
              <Button kind="box" type="submit" disabled={isLoading}>
                Zatwierdź
              </Button>

              <Button
                kind="box"
                variant="transparent"
                onClick={() => setIsEditable(false)}
                disabled={isLoading}
                style={{ marginLeft: 8 }}
              >
                Anuluj
              </Button>
            </FormActionsContainer>
          </HiddenContent>
          {!isEditable && (
            <Button
              kind="box"
              variant="gray"
              size="small"
              onClick={() => setIsEditable(true)}
              style={{ marginTop: -10 }}
            >
              Aktualizuj dane
            </Button>
          )}
        </Form>
      </Formik>
    </div>
  );
};
