import React, { FC } from 'react';
import { ToggleButtonProps } from 'src/common/components/ToggleButton';
import { useDerivedState } from '@summer/react-kit/hooks';
import { TagButton } from 'src/common/components/TagButton';

export const ToggleOffTagButton: FC<ToggleButtonProps> = ({
  initialValue,
  value,
  onChange,
  children,
}) => {
  const [active, setActive] = useDerivedState(initialValue ?? false, {
    value,
    onChange,
  });
  if (active) {
    return (
      <TagButton
        active
        onClick={() => setActive((currentValue: boolean) => !currentValue)}
      >
        {children}
      </TagButton>
    );
  }

  return null;
};
