import React, { FC } from 'react';
import { MainMenuAside } from 'src/common/components/MainMenuAside';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { MainMenuButton } from 'src/common/components/MainMenuButton';
import { Logo } from 'src/common/components/Logo';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { navigationActions } from 'src/state/navigation/navigationActions';
import { Link } from 'gatsby';
import { HomePageRoute } from 'src/app/routes';
import { BackButton } from 'src/common/components/BackButton';
import { rem } from 'polished';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  grid-template-rows: 100%;

  ${media.w.min.px992(css`
    grid-template-columns: min-content 1fr;
  `)}

  ${media.w.min.px1200(css`
    grid-template-columns: minmax(min-content, 25rem) 1fr;
  `)}
`;

const Aside = styled.aside`
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.05));
  padding: 3vh 2rem;
  display: none;

  ${media.w.min.px992(css`
    display: block;
    height: 100%;
  `)}
`;

const headerBreakpoint = media.w.min.px992;

const Header = styled.div`
  height: 5vh;
  min-height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  ${headerBreakpoint(css`
    justify-content: flex-end;
    margin-bottom: 0;
  `)};
`;

const HeaderMobile = styled.div`
  display: contents;

  ${headerBreakpoint(css`
    display: none;
  `)}
`;

const HeaderDesktop = styled.div`
  display: none;

  ${headerBreakpoint(css`
    display: contents;
    align-items: flex-end;
  `)};
`;

const MainBlock = styled.div`
  padding: 3vh 2rem;
  display: grid;
  grid-template-rows: min-content auto;
`;

const Main = styled.main`
  overflow: hidden;
`;

export const AsideMainMenuLayout: FC = ({ children }) => {
  const dispatch = useDispatch();
  useMediaQuery(
    { minWidth: widthBreakpoints.px992 },
    undefined,
    (matches) => matches && dispatch(navigationActions.close())
  );

  return (
    <Container>
      <Aside>
        <MainMenuAside />
      </Aside>
      <MainBlock>
        <Header>
          <HeaderMobile>
            <Link to={HomePageRoute}>
              <Logo variant="light" />
            </Link>
            <div>
              <BackButton />
              <MainMenuButton style={{ marginLeft: rem(6) }} />
            </div>
          </HeaderMobile>
          <HeaderDesktop>
            <BackButton size={36} />
          </HeaderDesktop>
        </Header>
        <Main>{children}</Main>
      </MainBlock>
    </Container>
  );
};
