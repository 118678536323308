import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.3882 37.9116H16.1621V37.0703H46.3882V37.9116Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5222 39.8013H14.2734V38.96H47.5222V39.8013Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.1115 40.8485C53.2552 40.8485 54.993 39.1107 54.993 36.967C54.993 34.8233 53.2552 33.0854 51.1115 33.0854C48.9678 33.0854 47.23 34.8233 47.23 36.967C47.23 39.1107 48.9678 40.8485 51.1115 40.8485ZM51.1115 41.6898C53.7199 41.6898 55.8343 39.5753 55.8343 36.967C55.8343 34.3586 53.7199 32.2441 51.1115 32.2441C48.5032 32.2441 46.3887 34.3586 46.3887 36.967C46.3887 39.5753 48.5032 41.6898 51.1115 41.6898Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4396 40.8485C13.5833 40.8485 15.3212 39.1107 15.3212 36.967C15.3212 34.8233 13.5833 33.0854 11.4396 33.0854C9.29591 33.0854 7.55809 34.8233 7.55809 36.967C7.55809 39.1107 9.29591 40.8485 11.4396 40.8485ZM11.4396 41.6898C14.048 41.6898 16.1625 39.5753 16.1625 36.967C16.1625 34.3586 14.048 32.2441 11.4396 32.2441C8.83128 32.2441 6.7168 34.3586 6.7168 36.967C6.7168 39.5753 8.83128 41.6898 11.4396 41.6898Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.1108 39.7153C52.6285 39.7153 53.8589 38.485 53.8589 36.9673C53.8589 35.4496 52.6285 34.2192 51.1108 34.2192C49.5931 34.2192 48.3628 35.4496 48.3628 36.9673C48.3628 38.485 49.5931 39.7153 51.1108 39.7153ZM51.1108 40.5566C53.0932 40.5566 54.7002 38.9496 54.7002 36.9673C54.7002 34.9849 53.0932 33.3779 51.1108 33.3779C49.1285 33.3779 47.5215 34.9849 47.5215 36.9673C47.5215 38.9496 49.1285 40.5566 51.1108 40.5566Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.439 39.7153C12.9567 39.7153 14.187 38.485 14.187 36.9673C14.187 35.4496 12.9567 34.2192 11.439 34.2192C9.92125 34.2192 8.6909 35.4496 8.6909 36.9673C8.6909 38.485 9.92125 39.7153 11.439 39.7153ZM11.439 40.5566C13.4213 40.5566 15.0283 38.9496 15.0283 36.9673C15.0283 34.9849 13.4213 33.3779 11.439 33.3779C9.45662 33.3779 7.84961 34.9849 7.84961 36.9673C7.84961 38.9496 9.45662 40.5566 11.439 40.5566Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.497 22.8413L20.0287 28.3198H17.1067C13.7517 28.3198 10.1357 28.9263 7.10382 29.6999C4.29957 30.4155 2.04599 31.2608 0.96734 31.8745L2.52261 37.9228L7.54606 38.8198L7.39816 39.648L1.84247 38.6559L0 31.4908L0.251528 31.3257C1.32133 30.6236 3.83563 29.6656 6.89583 28.8848C9.97212 28.0998 13.6608 27.4785 17.1067 27.4785H19.8514L32.3203 22L32.4087 22H43.9324C47.5679 22 50.8169 22.6216 53.1553 23.242C54.3253 23.5524 55.2694 23.8631 55.9225 24.0967C56.2491 24.2136 56.5031 24.3112 56.6763 24.3801C56.7629 24.4145 56.8293 24.4417 56.8746 24.4605L56.9264 24.4823L56.9402 24.4881L56.944 24.4898L56.9451 24.4903C56.9452 24.4903 56.9457 24.4905 56.7785 24.8765L56.9451 24.4903L56.9711 24.5015L59.8554 26.2321L63.2444 29.023V32.4946L64 33.6281V37.5028L62.4565 39.2668L55.1004 39.654L55.0561 38.8138L62.0574 38.4454L63.1587 37.1867V33.8828L62.4031 32.7493V29.42L59.3689 26.9213L56.5872 25.2523L56.5516 25.2373C56.5103 25.2202 56.448 25.1946 56.3656 25.1619C56.2008 25.0964 55.956 25.0022 55.6391 24.8888C55.0052 24.662 54.0838 24.3587 52.9396 24.0552C50.6497 23.4477 47.4755 22.8413 43.9324 22.8413L32.497 22.8413Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.8602 28.8862L22.5888 29.264L22.5801 28.4227L58.8514 28.0449L58.8602 28.8862Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.4638 29.1787H62.8228V30.02H59.7589L59.2965 30.3899L60.919 32.0124H62.8228V32.8537H60.5706L58.037 30.3201L59.4638 29.1787ZM2.88556 31.4457H7.09343L7.27351 32.2465L3.59867 33.9872H1.04821V33.1459H3.40949L5.22278 32.287H2.98912L0.772403 32.8411L0.568359 32.025L2.88556 31.4457Z"
    />
  </IconBox>
);
