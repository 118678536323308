import React, { FC, ReactNode } from 'react';
import { Icon, IconCode } from 'src/common/components/Icon';
import styled, { css } from 'styled-components';
import { StyleProps } from '@summer/react-kit';
import { media } from 'src/config/breakpoints';

export interface OfferInformationIconProps {
  iconCode: IconCode;
  label: ReactNode;
}

const Container = styled.div`
  position: relative;

  height: 5.625rem;
  width: 7.8125rem;
`;

const StyledIcon = styled(Icon)`
  opacity: 0.2;

  height: 3rem;
  width: 3rem;

  ${media.w.min.px768(css`
    width: 3.75rem;
    height: 3.75rem;
  `)}
`;

const LabelContainer = styled.div`
  position: absolute;
  width: 6.3125rem;
  left: 1.5rem;
  top: 1.875rem;

  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;

  font-size: 0.9375rem;

  ${media.w.min.px768(css`
    font-size: 1rem;
  `)}
`;

export const OfferInformationIcon: FC<
  StyleProps & OfferInformationIconProps
> = ({ className, style, iconCode, label }) => (
  <Container className={className} style={style}>
    <StyledIcon code={iconCode} />
    <LabelContainer>{label}</LabelContainer>
  </Container>
);
