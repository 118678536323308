import {
  ExoticFilter,
  FilterKind,
  FiltersState,
  FilterStateKindMap,
  FilterValues,
  isFilterKind,
  isOptionFilter,
  isRangeFilter,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';
import { equals, keys, mapObjIndexed } from 'ramda';
import { filterConfig } from 'src/app/common/models/filterConfig';
import { fold, none, Option, some } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { parse, stringify } from 'qs';
import { deflateRaw, inflateRaw } from 'pako';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { pickFilterValues } from 'src/app/search/services/filters';
import { transformFiltersForAPI } from 'src/app/search/services/filterTransformers';

export const isDefaultFilterValue = (
  kind: FilterKind,
  value?: FilterStateKindMap[FilterKind]['value']
) => {
  return equals(value, filterConfig[kind].initialValue);
};

export const serializeFilterValueToQueryParam = <T extends FilterKind>(
  kind: T,
  value: FilterStateKindMap[T]['value']
) => {
  if (isDefaultFilterValue(kind, value)) {
    return none;
  }

  if (kind === ExoticFilter.Model) {
    return some(
      JSON.stringify(value as FilterStateKindMap[ExoticFilter.Model]['value'])
    );
  }

  if (kind === ExoticFilter.NewProductOffersPagination) {
    return some(
      JSON.stringify(
        value as FilterStateKindMap[ExoticFilter.NewProductOffersPagination]['value']
      )
    );
  }
  if (kind === ExoticFilter.UsedProductOffersPagination) {
    return some(
      JSON.stringify(
        value as FilterStateKindMap[ExoticFilter.UsedProductOffersPagination]['value']
      )
    );
  }

  if (isOptionFilter(kind) || isRangeFilter(kind)) {
    return some(
      (value as FilterStateKindMap[RangeFilter | OptionFilter]['value']).join(
        ','
      )
    );
  }

  return none;
};

export const packFilters = (filters: FilterValues) => {
  const queryParamsMap: { [name: string]: string } = {};
  keys(filters).forEach((kind) =>
    pipe(
      serializeFilterValueToQueryParam(
        kind,
        filters[kind] as FilterStateKindMap[typeof kind]['value']
      ),
      fold(
        () => null,
        (v) => {
          queryParamsMap[kind] = v;
        }
      )
    )
  );

  return keys(queryParamsMap).length > 0
    ? pipe(
        queryParamsMap,
        stringify,
        (_) => deflateRaw(_, { to: 'string' }),
        btoa
      )
    : '';
};

export const validateRangeFilterValue = (
  value: number[]
): value is [number, number] => {
  return value.length === 2 && value.every((_) => !Number.isNaN(_));
};

export const parseFilterValueFromQueryParam = <T extends FilterKind>(
  kind: T,
  value: unknown
): Option<FilterStateKindMap[T]['value']> => {
  if (typeof value !== 'string') {
    return none;
  }

  if (kind === ExoticFilter.Model) {
    return some(JSON.parse(value));
  }

  if (
    kind === ExoticFilter.NewProductOffersPagination ||
    kind === ExoticFilter.UsedProductOffersPagination
  ) {
    return some(JSON.parse(value));
  }

  if (isOptionFilter(kind)) {
    return some(value.split(','));
  }

  if (isRangeFilter(kind)) {
    const parsedValue = value.split(',').map(parseFloat);

    if (!validateRangeFilterValue(parsedValue)) {
      return none;
    }

    if (
      parsedValue[0] < filterConfig[kind].min ||
      parsedValue[1] > filterConfig[kind].max
    ) {
      return none;
    }

    return some(parsedValue);
  }

  return none;
};

export const unpackFilters = (str: string) => {
  const filters: ReturnType<
    typeof searchFiltersActions.loadQueryParamsSuccess
  >['payload'] = {};

  if (str.length > 0) {
    const queryParamsMap = pipe(
      str,
      atob,
      (_) => inflateRaw(_, { to: 'string' }),
      parse
    );

    keys(queryParamsMap).forEach((kind) => {
      if (!isFilterKind(kind)) {
        return;
      }

      pipe(
        parseFilterValueFromQueryParam(kind, queryParamsMap[kind]),
        fold(
          () => null,
          (v) => {
            filters[kind] = v;
          }
        )
      );
    });
  }

  return filters;
};

export const formattedSearchFilter = (filtersState: FiltersState) =>
  pipe(
    mapObjIndexed(
      (a, k) =>
        !isRangeFilter(k) &&
        k !== ExoticFilter.NewProductOffersPagination &&
        k !== ExoticFilter.UsedProductOffersPagination &&
        isDefaultFilterValue(k as FilterKind, a)
          ? undefined
          : a,
      pickFilterValues(filtersState)
    ) as FilterValues,
    transformFiltersForAPI
  );
