import { createAction } from 'src/lib/createAction';
import {
  LoginDTO,
  RegisterByCodeDTO,
  RegisterDTO,
  RegisterStep2DTO,
} from 'src/common/models/auth';
import { createRequestActions } from 'src/lib/requestActions';
import { User } from 'src/common/models/user';
import { CustomerType } from 'src/common/models/customerType';
import { Some } from 'fp-ts/lib/Option';

export const loginActions = createRequestActions(
  'auth/login/request',
  'auth/login/requestSuccess',
  'auth/login/requestFailure'
)<
  {
    identifier: string;
    password: string;
  },
  LoginDTO,
  any
>();

export const registerActions = createRequestActions(
  'auth/register/request',
  'auth/register/requestSuccess',
  'auth/register/requestFailure'
)<RegisterDTO, void, any>();

export const registerStep2Actions = createRequestActions(
  'auth/registerStep2/request',
  'auth/registerStep2/requestSuccess',
  'auth/registerStep2/requestFailure'
)<RegisterStep2DTO, LoginDTO, any>();

export const registerByCodeActions = createRequestActions(
  'auth/registerByCode/request',
  'auth/registerByCode/requestSuccess',
  'auth/registerByCode/requestFailure'
)<RegisterByCodeDTO, LoginDTO, any>();

export const authActions = {
  updateUser: createAction('auth/updateUser')<Partial<User>>(),
  updateToken: createAction('auth/updateToken')<Some<string>>(),
  logout: createAction('auth/logout')(),
} as const;

export const fetchUserActions = createRequestActions(
  'auth/fetchUser/request',
  'auth/fetchUser/requestSuccess',
  'auth/fetchUser/requestFailure'
)<void, User, any>();

export const updateUserActions = createRequestActions(
  'auth/updateUser/request',
  'auth/updateUser/requestSuccess',
  'auth/updateUser/requestFailure'
)<Partial<User>, User, any>();

export const setUserCustomerTypeActions = createRequestActions(
  'auth/setUserCustomerType/request',
  'auth/setUserCustomerType/requestSuccess',
  'auth/setUserCustomerType/requestFailure'
)<CustomerType, User, any>();
