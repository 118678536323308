import React, {
  ComponentType,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useDerivedState } from '@summer/react-kit/hooks';
import {
  ToggleButton,
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { takeLast } from 'ramda';
import { ID, idToggleEnhancer } from '@summer/react-kit';
import { useFilterControl } from 'src/app/search/hooks/useFilterControl';
import { ExoticFilter, ModelFilter } from 'src/app/common/models/filter';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';

export interface ToggleButtonGroupBrandIconsItemOptions {
  id: string;
  label: ReactNode;
  textLabel?: string;
}

export interface ToggleButtonGroupBrandIconsProps {
  limit?: number;
  items: ToggleButtonGroupBrandIconsItemOptions[];
  initialValue?: string[];
  value?: string[];
  onChange?: (v: string[]) => void;
  disabled?: boolean;
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
  variant?: ToggleButtonVariant;
}

export const ToggleButtonGroupBrandIcons = memo<ToggleButtonGroupBrandIconsProps>(
  ({
    variant = 'primary',
    limit,
    items,
    initialValue,
    value,
    onChange,
    toggleButtonComponent = ToggleButton,
    disabled,
  }) => {
    const [active, setActive] = useDerivedState(initialValue ?? [], {
      value,
      onChange,
    });

    const { state: modelState, setValue: setModelState } = useFilterControl(
      ExoticFilter.Model,
      searchFiltersActions.setModelFilterValue
    );

    const onChangeActive = useCallback(
      (value: boolean, id: ID) => {
        const prep = (x: string[]) => (limit ? takeLast(limit - 1, x) : x);

        const newActive = value
          ? prep(active).concat([id as string])
          : prep(active).filter((_) => _ !== id);

        const filteredModelState: ModelFilter = Object.keys(modelState.value)
          .filter((key) => newActive.includes(key))
          .reduce((obj: ModelFilter, key) => {
            obj[key] = modelState.value[key];
            return obj;
          }, {} as ModelFilter);

        setModelState(filteredModelState);
        setActive(newActive);
      },
      [limit, setActive, active, modelState]
    );

    const Component = useMemo(
      () =>
        idToggleEnhancer<boolean, PropsWithChildren<ToggleButtonProps>>(
          toggleButtonComponent
        ),
      [toggleButtonComponent]
    );

    return (
      <>
        {items.map((item) => (
          <Component
            id={item.id}
            key={item.id}
            variant={variant}
            value={!!active.find((_) => _ === item.id)}
            onChange={onChangeActive}
            disabled={disabled}
          >
            {item.label}
          </Component>
        ))}
      </>
    );
  }
);
