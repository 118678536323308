import React, { FC } from 'react';
import { Field } from 'formik';
import { TextField } from 'src/common/components/TextField';
import { FieldProps } from 'formik/dist/Field';
import { FieldValidator } from 'formik/dist/types';
import { TextFieldProps } from '@material-ui/core';

export type TextControlProps = {
  name: string;
  validate?: FieldValidator;
} & TextFieldProps;

export const TextControl: FC<TextControlProps> = ({
  name,
  validate,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: TextControlProps) => (
  <Field name={name} validate={validate}>
    {({ field: { name, value, onBlur, onChange } }: FieldProps<string>) => (
      <TextField
        name={name}
        value={value}
        onChange={(v) => {
          onChange(v);
          $onChange?.(v);
        }}
        onBlur={(e) => {
          onBlur(e);
          $onBlur?.(e);
        }}
        {...restProps}
      />
    )}
  </Field>
);

TextControl.displayName = 'TextControl';
