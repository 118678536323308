import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { PagesInfographicTitle } from 'src/common/components/PagesInfographicTitle';
import { PagesInfographicText } from 'src/common/components/PagesInfographicText';
import { HowItWorksInfographicContainer } from 'src/public/components/how-it-works/HowItWorksInfographicContainer';
import HowItWorksInfographicSlide5Svg from 'src/assets/images/how-it-works-infographic-slide-5.svg';
import { HowItWorksInfographicCounterWrapper } from 'src/public/components/how-it-works/HowItWorksInfographicCounter';

const StyledHowItWorksInfographicContainer = styled(
  HowItWorksInfographicContainer
)`
  flex-direction: column;

  ${media.w.min.px768(css`
    flex-direction: row;
    padding-top: 2.5rem;
  `)};
`;

const Content = styled.div`
  padding-right: 3rem;
  padding-top: 3rem;

  ${media.w.max.px768(css`
    padding-right: 0;
    padding-top: 2.5rem;
    order: 1;
  `)};
`;

const StyledPagesInfographicTitle = styled(PagesInfographicTitle)`
  ${media.w.min.px768(css`
    text-align: right;
  `)};
`;

const StyledPagesInfographicText = styled(PagesInfographicText)`
  ${media.w.min.px768(css`
    text-align: right;
    max-width: 15rem;
  `)};
`;

export const HowItWorksInfographicSlide3 = memo(() => {
  return (
    <StyledHowItWorksInfographicContainer>
      <Content>
        <StyledPagesInfographicTitle>
          PODPISUJESZ UMOWĘ
        </StyledPagesInfographicTitle>
        <StyledPagesInfographicText>
          W przypadku najmu lub leasingu auta, po otrzymaniu pozytywnej decyzji
          kredytowej podpiszesz umowę
        </StyledPagesInfographicText>
      </Content>
      <HowItWorksInfographicCounterWrapper
        number={3}
        position={{ left: '-2rem', top: '-2.5rem' }}
        responsive={{ position: { right: '15%', top: '-1rem' } }}
      >
        <HowItWorksInfographicSlide5Svg />
      </HowItWorksInfographicCounterWrapper>
    </StyledHowItWorksInfographicContainer>
  );
});
