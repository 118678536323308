import React, { memo } from 'react';
import {
  FilterBoxToggleButton,
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';
import { StyleProps } from '@summer/react-kit';
import { OptionFilter } from 'src/app/common/models/filter';
import { Icon } from 'src/common/components/Icon';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { bodiesListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { OptionalFold } from 'src/lib/OptionalFold';
import { bodyIconMapper } from 'src/app/product/services/productMappers';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { BlockScrollContainer } from 'src/common/components/ScrollContainer';
import styled, { css } from 'styled-components';
import { SearchFilterOptionsGrid } from 'src/app/search/components/SearchPanel/SearchFilterOptions';
import { media } from 'src/config/breakpoints';

const Grid = styled(SearchFilterOptionsGrid)`
  align-content: flex-start;
  margin: 0;

  ${media.w.max.px992(css`
    flex-wrap: nowrap;
  `)}
`;

const ScrollContainer = styled(BlockScrollContainer)`
  margin: -0.1875rem;

  ${media.w.max.px992(css`
    height: 5rem;
  `)}
`;

const BodyToggleButton = styled(FilterBoxToggleButton)`
  width: 3.75rem;
  height: 3.75rem;
  min-width: 3.5rem;
  border-radius: 0.125rem;

  ${media.w.min.px1200(css`
    width: 5.2rem;
    height: 5.2rem;
  `)}
`;

const Container = styled.div`
  height: 11.25rem;
  max-height: 11.25rem;
  margin-top: 0.75rem;

  ${media.w.min.px1200(css`
    height: 15rem;
    max-height: 15rem;
    margin-top: 0.75rem;
  `)}
`;

export const BodyIconsSection = memo<StyleProps>(({ className, style }) => {
  const { state, setValue } = useOptionFilterControl(OptionFilter.Body);

  const items = useSelectorTransform(bodiesListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: (
        <>
          <OptionalFold
            value={bodyIconMapper(a.id)}
            onSome={(code) => (
              <FilterBoxToggleButtonIcon>
                <Icon code={code} width={50} />
              </FilterBoxToggleButtonIcon>
            )}
          />
          <FilterBoxToggleButtonTitle>{a.label}</FilterBoxToggleButtonTitle>
        </>
      ),
    }))
  );

  return (
    <Container className={className}>
      <ScrollContainer style={style} options={{ sizeAutoCapable: false }}>
        <Grid>
          <ToggleButtonGroup
            items={items}
            toggleButtonComponent={BodyToggleButton}
            value={state.value}
            onChange={setValue}
          />
        </Grid>
      </ScrollContainer>
    </Container>
  );
});
