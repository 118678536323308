import { SearchState } from 'src/app/search/state';
import { createSelector } from 'reselect';
import { SearchFiltersState } from 'src/app/search/state/searchFilters/searchFiltersReducer';
import pick from 'lodash/fp/pick';
import { FilterKind } from 'src/app/common/models/filter';
import { formattedSearchFilter } from 'src/app/search/services/filterSerializer';

export const searchFiltersStateSelector = (state: SearchState) =>
  state.searchFilters;

export const apiFormattedSearchFiltersSelector = createSelector(
  searchFiltersStateSelector,
  (filtersState) => formattedSearchFilter(filtersState)
);

export const createSearchFilterStateSelector = () =>
  createSelector(
    [searchFiltersStateSelector, (_: SearchState, kind: FilterKind) => kind],
    (state: SearchFiltersState, kind) => state[kind]
  );

export const createSearchFilterStateByKindsSelector = () =>
  createSelector(
    [
      searchFiltersStateSelector,
      (_: SearchState, kinds: FilterKind[]) => kinds,
    ],
    (state, kinds) => pick(kinds, state)
  );
