import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M62.3206 49.3738L49.3668 40.7345C47.7229 39.647 45.5193 40.0048 44.304 41.5568L40.5307 46.4087C40.0458 47.0481 39.1643 47.2336 38.4628 46.8439L37.745 46.4482C35.3656 45.1512 32.405 43.5364 26.44 37.5689C20.4751 31.6013 18.8572 28.6383 17.5602 26.2631L17.1667 25.5453C16.7716 24.8438 16.9536 23.9585 17.5932 23.4697L22.4415 19.6972C23.9928 18.4817 24.3511 16.2786 23.2649 14.6341L14.6262 1.67938C13.513 0.00475705 11.2795 -0.497954 9.55617 0.538091L4.13935 3.79222C2.43735 4.79289 1.18866 6.41351 0.655113 8.31449C-1.29547 15.4222 0.171934 27.689 18.2408 45.7612C32.6141 60.1345 43.3144 63.9998 50.6691 63.9998C52.3617 64.0071 54.0477 63.7868 55.6817 63.345C57.5829 62.812 59.2037 61.5631 60.2037 59.8605L63.4608 54.4466C64.4983 52.7227 63.9959 50.4877 62.3206 49.3738ZM61.6231 53.3521L58.3734 58.7694C57.6554 59.9983 56.4897 60.9018 55.1206 61.2908C48.5594 63.0923 37.0966 61.6001 19.7498 44.2521C2.40298 26.9041 0.911075 15.4414 2.71243 8.87882C3.10188 7.50793 4.00649 6.34064 5.23681 5.62145L10.6536 2.37157C11.4014 1.92161 12.3708 2.13972 12.8538 2.86641L21.4852 15.82C21.9568 16.5334 21.8019 17.4894 21.129 18.0172L16.2796 21.7897C14.8038 22.9182 14.3823 24.9602 15.2909 26.5808L15.676 27.2805C17.0391 29.7806 18.7337 32.8907 24.9205 39.077C31.1074 45.2631 34.2163 46.958 36.715 48.321L37.4157 48.7071C39.0361 49.6157 41.078 49.1943 42.2065 47.7185L45.9787 42.8687C46.5066 42.1961 47.4622 42.0413 48.1757 42.5125L61.1284 51.1518C61.8556 51.6345 62.0737 52.6045 61.6231 53.3521Z" />
    <path d="M36.2696 10.6698C46.2782 10.681 54.389 18.7925 54.4003 28.8018C54.4003 29.3909 54.8777 29.8684 55.4668 29.8684C56.0558 29.8684 56.5332 29.3909 56.5332 28.8018C56.5209 17.6148 47.4558 8.54899 36.2696 8.53674C35.6806 8.53674 35.2031 9.01421 35.2031 9.60329C35.2031 10.1924 35.6806 10.6698 36.2696 10.6698Z" />
    <path d="M36.2696 17.0697C42.7456 17.0773 47.9936 22.3257 48.0012 28.8022C48.0012 29.3912 48.4788 29.8687 49.0677 29.8687C49.6567 29.8687 50.1342 29.3912 50.1342 28.8022C50.1253 21.148 43.9231 14.9454 36.2696 14.9366C35.6806 14.9366 35.2031 15.4141 35.2031 16.0032C35.2031 16.5922 35.6806 17.0697 36.2696 17.0697Z" />
    <path d="M36.2696 23.4696C39.2132 23.4731 41.5986 25.8586 41.6022 28.8025C41.6022 29.3916 42.0797 29.8691 42.6687 29.8691C43.2576 29.8691 43.7351 29.3916 43.7351 28.8025C43.7304 24.6811 40.3907 21.3411 36.2696 21.3365C35.6806 21.3365 35.2031 21.8139 35.2031 22.403C35.2031 22.9921 35.6806 23.4696 36.2696 23.4696Z" />
  </IconBox>
);
