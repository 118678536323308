import {
  FilterKind,
  FilterStateKindMap,
  isOptionFilter,
  isRangeFilter,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';
import { FilterStatusSpan } from 'src/app/search/components/FilterStatusInfo/FilterStatusSpan';
import { filterConfig } from 'src/app/common/models/filterConfig';
import React, { FC, memo } from 'react';
import { FilterStatusLabel } from 'src/app/search/components/FilterStatusInfo/FilterStatusLabel';
import { FilterStatusItem } from 'src/app/search/components/FilterStatusInfo/FilterStatusItem';

export interface FilterStatusPrinterProps<T extends FilterKind = FilterKind> {
  kind: T;
  state: FilterStateKindMap[T];
}

const OptionFilterStatusPrinter: FC<FilterStatusPrinterProps<OptionFilter>> = ({
  state,
}) => (
  <FilterStatusItem>
    <FilterStatusSpan>
      {state.value
        .map((a) => state.options?.find((b) => b.id === a)?.label ?? a)
        .join(', ')}
    </FilterStatusSpan>
  </FilterStatusItem>
);

const RangeFilterStatusPrinter: FC<FilterStatusPrinterProps<RangeFilter>> = ({
  kind,
  state,
}) => (
  <FilterStatusItem>
    <FilterStatusLabel>{filterConfig[kind].shortName}: </FilterStatusLabel>
    <FilterStatusSpan>
      {state.value[0]}-{state.value[1]} {filterConfig[kind].unit}
    </FilterStatusSpan>
  </FilterStatusItem>
);

export const FilterStatusPrinter = memo(
  ({ kind, state }: FilterStatusPrinterProps) => {
    if (isOptionFilter(kind)) {
      return (
        <OptionFilterStatusPrinter
          kind={kind}
          state={state as FilterStateKindMap[OptionFilter]}
        />
      );
    }

    if (isRangeFilter(kind)) {
      return (
        <RangeFilterStatusPrinter
          kind={kind}
          state={state as FilterStateKindMap[RangeFilter]}
        />
      );
    }

    return <></>;
  }
);
