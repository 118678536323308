import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96714 32.2047L18.1883 19.9836L15.2047 17L-1.82748e-06 32.2047L15.2047 47.4094L18.1883 44.4258L5.96714 32.2047Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 30.1406H14.5744V34.36H64V30.1406Z"
    />
  </IconBox>
);
