import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.3696 4.8028c.5426.01843 1.2699.1897 1.9534.68091.8418.60494 1.7629 1.80137 2.0918 4.18606l2.7752-.38278c-.415-3.00898-1.6638-4.95111-3.2321-6.07819-1.5269-1.09729-3.221-1.32225-4.3894-1.16292l-.4675.06376-5.7414 5.74134 5.8122 5.81212-15.6993 15.6993-5.1933 1.2219 1.5721-5.5021 11.1392-11.4249H22.2072l-6.1599-6.15983-6.15983 6.15983H0v33.9546h44.1839V30.6346h-2.8014v14.1758H2.80143V16.4588h8.24647l4.9994-4.9995 4.9995 4.9995h13.2997l-6.9946 7.174-3.0777 10.7718 10.6157-2.4978 18.2437-18.2437-5.8121-5.81212 3.0481-3.04818z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2006 24.8327H50.977v-2.8015h13.025v33.0246h-9.19l-5.6949 5.6949-5.6949-5.6949H20.748v-8.8402h2.8015v6.0388h21.0331l4.5345 4.5345 4.5345-4.5345h7.549V24.8327z"
    />
    <path d="M12.8523 31.8031c0 .7704-.6245 1.3949-1.3949 1.3949s-1.3949-.6245-1.3949-1.3949.6245-1.3949 1.3949-1.3949 1.3949.6245 1.3949 1.3949zM20.7039 31.8031c0 .7704-.6245 1.3949-1.3949 1.3949s-1.3949-.6245-1.3949-1.3949.6245-1.3949 1.3949-1.3949 1.3949.6245 1.3949 1.3949z" />
  </IconBox>
);
