import { createReducer } from '@reduxjs/toolkit';
import { none, Option, some } from 'fp-ts/lib/Option';
import { cookiesActions } from 'src/state/cookies/cookiesActions';

export interface CookiesState {
  advertInternal: Option<CookiesAnswer>;
  advertExternal: Option<CookiesAnswer>;
  analytic: Option<CookiesAnswer>;
}

export interface CookiesAnswer {
  accepted: boolean;
  date: Date;
}

const initialState: CookiesState = {
  advertExternal: none,
  advertInternal: none,
  analytic: none,
};

export const cookiesReducer = createReducer<CookiesState>(
  initialState,
  (builder) =>
    builder
      .addCase(cookiesActions.accept, (state) => {
        state.advertInternal = some({ accepted: true, date: new Date() });
        state.advertExternal = some({ accepted: true, date: new Date() });
        state.analytic = some({ accepted: true, date: new Date() });
      })
      .addCase(cookiesActions.decline, (state) => {
        state.advertInternal = some({ accepted: false, date: new Date() });
        state.advertExternal = some({ accepted: false, date: new Date() });
        state.analytic = some({ accepted: false, date: new Date() });
      })
      .addCase(cookiesActions.acceptAdvertInternal, (state) => {
        state.advertInternal = some({ accepted: true, date: new Date() });
      })
      .addCase(cookiesActions.declineAdvertInternal, (state) => {
        state.advertInternal = some({ accepted: false, date: new Date() });
      })
      .addCase(cookiesActions.acceptAdvertExternal, (state) => {
        state.advertExternal = some({ accepted: true, date: new Date() });
      })
      .addCase(cookiesActions.declineAdvertExternal, (state) => {
        state.advertExternal = some({ accepted: false, date: new Date() });
      })
      .addCase(cookiesActions.acceptAnalytic, (state) => {
        state.analytic = some({ accepted: true, date: new Date() });
      })
      .addCase(cookiesActions.declineAnalytic, (state) => {
        state.analytic = some({ accepted: false, date: new Date() });
      })
);
