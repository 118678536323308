import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { SearchHeader } from 'src/app/search/components/SearchHeader';
import { injectSearchState } from 'src/app/search/state';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SearchPanel } from 'src/app/search/components/SearchPanel';
import { activeSearchPanelSelector } from 'src/app/search/state/searchPanel/searchPanelSelectors';
import {
  searchFiltersActions,
  srpFiltersActions,
} from 'src/app/search/state/searchFilters/searchFiltersActions';
import { searchPanelActions } from 'src/app/search/state/searchPanel/searchPanelActions';
import { useDispatchRequest } from 'src/lib/useDispatchRequest';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { styledIs } from '@summer/react-kit/functions';
import { SearchPageSecondaryResultsSectionDecorator } from 'src/app/search/components/SearchPageSecondaryResultsSectionDecorator';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { UsedProductOfferIconography } from 'src/app/search/components/UsedProductOfferIconography';
import { UsedProductOffers } from 'src/app/search/components/UsedProductOffers';
import { NewProductOffers } from 'src/app/search/components/NewProductOffers';
import { GetNewCarInstructionsStepSection } from 'src/app/common/components/GetNewCarInstructionsStepSection';
import { RequestProductSection } from 'src/app/search/components/RequestProductSection';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { Collapse } from '@material-ui/core';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { searchProductsResultsSelector } from 'src/app/search/state/searchProducts/searchProductsSelectors';
import { searchUsedProductsResultsSelector } from 'src/app/search/state/searchProducts/searchUsedProductsSelectors';
import { isEmpty } from 'ramda';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';
import { useRequest } from 'src/lib/useRequest';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { findUsedProductsRequestActions } from 'src/app/search/state/searchProducts/searchUsedProductsActions';
import { isInitialRequest } from 'src/state/requestStatus/requestStatus';
import { LoadingIndicator } from 'src/app/search/components/LoadingIndicator';
import { colors } from 'src/config/colors';
import { NewProductOfferIconography } from 'src/app/search/components/NewProductOfferIconography';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { useMediaQuery } from 'react-responsive';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';

import('src/app/product/ProductPage');

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`;

const Main = styled.main<{ searchPanel?: boolean }>`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #d4d6dc;
  height: 1px;

  ${styledIs('searchPanel')(css`
    z-index: 3;
  `)};

  ${media.w.min.px768(css`
    z-index: 1;
  `)};
`;

const Section = styled.section`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${media.w.min.px1200(css`
    padding-top: 3rem;
  `)};
`;

const EmptyResultTitle = styled.h3<{ $checkPullUp?: boolean }>`
  padding-top: 1rem; //required for pullup and z-index issue with section items
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: ${colors.lightGray};
  text-transform: uppercase;
  margin-bottom: 0;

  ${({ $checkPullUp }) =>
    $checkPullUp &&
    media.w.min.px1200(css`
      margin-bottom: -2rem;
    `)}
`;

const StyledSearchPageSecondaryResultsSection = styled(
  SearchPageSecondaryResultsSectionDecorator
)`
  padding-bottom: 2rem;
`;

const StyledGetNewCarInstructionsStepSection = styled(
  GetNewCarInstructionsStepSection
)`
  flex-shrink: 0;
`;

injectSearchState();

const SearchPage = memo<RouteComponentProps>(() => {
  useStorePageUrl();

  const dispatch = useDispatch();
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);
  useDispatchRequest(srpFiltersActions.request);
  const activePanel = useSelector(activeSearchPanelSelector);

  useEffect(() => {
    setTimeout(() => {
      dispatch(searchFiltersActions.loadQueryParams());
    });

    return () => {
      dispatch(searchPanelActions.close());
    };
  }, []);

  const newProducts = useSelector(searchProductsResultsSelector);
  const usedProducts = useSelector(searchUsedProductsResultsSelector);
  const [
    requestDreamCarModalVisible,
    setRequestDreamCarModalVisible,
  ] = useState(false);

  const productsPreference = useSelector(productsPreferenceSelector);

  const {
    isLoading: isNewProductsLoading,
    status: newProductsReqStatus,
  } = useRequest(findProductsRequestActions.request);
  const {
    isLoading: isUsedProductsLoading,
    status: usedProductsReqStatus,
  } = useRequest(findUsedProductsRequestActions.request);

  const hasNewProducts = !isEmpty(newProducts);
  const hasUsedProducts = !isEmpty(usedProducts);
  const hasEitherNewOrUsedProducts = hasNewProducts != hasUsedProducts; // XOR
  const noProducts = !hasNewProducts && !hasUsedProducts;
  const hasBothProductsTypes = hasNewProducts && hasUsedProducts;

  const isInitial =
    isInitialRequest(newProductsReqStatus) ||
    isInitialRequest(usedProductsReqStatus);

  const handleDreamCarClick = useCallback(() => {
    setRequestDreamCarModalVisible(true);
  }, []);

  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px992) });

  const hideScroll = activePanel && isMobile;

  useEffect(() => {
    const elements = scrollRef.current?.osInstance()?.getElements();

    if (elements) {
      elements.viewport.tabIndex = -1;
      elements.viewport.focus();
    }
  }, []);

  return (
    <>
      <ScrollContainer
        style={{ height: '100%' }}
        ref={scrollRef}
        options={{
          overflowBehavior: { y: hideScroll ? 'hidden' : 'scroll' },
        }}
      >
        <Container>
          <SearchHeader />
          <Main searchPanel={activePanel != null}>
            <SearchPanel />

            {/* {!isInitial && (!hasNewProducts || !hasUsedProducts) && (
              <ConstraintContainer>
                <EmptyResultTitle $checkPullUp={hasEitherNewOrUsedProducts}>
                  {noProducts
                    ? 'Brak wyników wyszukiwania'
                    : hasNewProducts
                    ? 'Brak wyników dla aut używanych'
                    : 'Brak wyników dla aut nowych'}
                </EmptyResultTitle>
              </ConstraintContainer>
            )} */}

            <div>
              <Collapse collapsedHeight={0} in={!isInitial && noProducts}>
                <Section>
                  <LoadingIndicator
                    isLoading={isNewProductsLoading || isUsedProductsLoading}
                    showBackdrop
                  />
                  <RequestProductSection />
                </Section>
              </Collapse>
            </div>

            {!isInitial && hasEitherNewOrUsedProducts && (
              <StyledSearchPageSecondaryResultsSection
                offerKindTitle={hasNewProducts ? 'aut nowych' : 'aut używanych'}
                // headerIconography={
                //   hasNewProducts ? (
                //     <NewProductOfferIconography />
                //   ) : (
                //     <UsedProductOfferIconography />
                //   )
                // }
              >
                {hasNewProducts ? (
                  <NewProductOffers onDreamCarClick={handleDreamCarClick} />
                ) : (
                  <UsedProductOffers onDreamCarClick={handleDreamCarClick} />
                )}
              </StyledSearchPageSecondaryResultsSection>
            )}

            {(isInitial || hasBothProductsTypes) &&
              productsPreference === 'new' && (
                <>
                  <Section>
                    <NewProductOffers onDreamCarClick={handleDreamCarClick} />
                  </Section>

                  <StyledSearchPageSecondaryResultsSection
                    offerKindTitle="aut używanych"
                    headerIconography={<UsedProductOfferIconography />}
                  >
                    <UsedProductOffers onDreamCarClick={handleDreamCarClick} />
                  </StyledSearchPageSecondaryResultsSection>
                </>
              )}

            {(isInitial || hasBothProductsTypes) &&
              productsPreference === 'used' && (
                <>
                  <Section>
                    <UsedProductOffers onDreamCarClick={handleDreamCarClick} />
                  </Section>

                  <StyledSearchPageSecondaryResultsSection
                    offerKindTitle="aut nowych"
                    headerIconography={<NewProductOfferIconography />}
                  >
                    <NewProductOffers onDreamCarClick={handleDreamCarClick} />
                  </StyledSearchPageSecondaryResultsSection>
                </>
              )}

            <StyledGetNewCarInstructionsStepSection />
          </Main>
        </Container>
      </ScrollContainer>

      <ModalPortal
        open={requestDreamCarModalVisible}
        onClose={() => setRequestDreamCarModalVisible(false)}
        background={colors.background}
        title="Auto Twoich marzeń"
      >
        <RequestProductSection style={{ paddingTop: '4rem' }} />
      </ModalPortal>
    </>
  );
});

// eslint-disable-next-line import/no-default-export
export default SearchPage;
