import styled, { css } from 'styled-components';
import { Title } from 'src/common/components/typography/Title';
import { media } from 'src/config/breakpoints';

export const CategoryEquipmentListTitle = styled(Title).attrs(() => ({
  level: 4,
  variant: 'condensed',
}))`
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  font-size: 0.6875rem;

  ${media.w.min.px576(css`
    font-size: 0.75rem;
  `)};
`;
