import { User } from 'src/common/models/user';
import { BusinessPartnerRegisterFormModel } from 'src/public/components/business-partner-registration/FormSection';

export interface LoginDTO {
  jwt: string;
  user: User;
}

export interface RegisterDTO {
  companyEmail: string;
  tosAgreement: boolean;
}

export interface RegisterStep2DTO {
  email: string;
  emailConfirm: string;
  firstName: string;
  newsletterAgreement: boolean;
  password: string;
  token?: string;
}

export interface RegisterByCodeDTO {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  code: string;
  path: string;
  tosAgreement: boolean;
  newsletterAgreement: boolean;
}

export interface ValidateTokenDTO {
  token?: string;
}

export interface RegisterFormModel {
  companyEmail: string;
  tosAgreement: boolean;
}

export interface RegisterStep2FormModel {
  firstName: string;
  email: string;
  emailConfirm: string;
  password: string;
  newsletterAgreement: boolean;
  token?: string;
}

export const injectToken = (token?: string) => (
  values: RegisterStep2FormModel
): RegisterStep2FormModel => ({
  ...values,
  token,
});

export const mapToRegisterDTO = ({
  companyEmail,
  tosAgreement,
}: RegisterFormModel): RegisterDTO => ({
  companyEmail,
  tosAgreement,
});

export const mapToRegisterStep2DTO = ({
  email,
  emailConfirm,
  firstName,
  newsletterAgreement,
  password,
  token,
}: RegisterStep2FormModel): RegisterStep2DTO => ({
  email,
  emailConfirm,
  firstName,
  newsletterAgreement,
  password,
  token,
});

export const injectRegistrationPath = (path: string) => (
  values: BusinessPartnerRegisterFormModel
): BusinessPartnerRegisterFormModel & { path: string } => ({
  ...values,
  path,
});

export const mapToRegisterByCodeDTO = ({
  email,
  firstName,
  lastName,
  password,
  code,
  path,
  tosAgreement,
  newsletterAgreement,
}: BusinessPartnerRegisterFormModel & { path: string }): RegisterByCodeDTO => ({
  email,
  firstName,
  lastName,
  password,
  code: code.toUpperCase(),
  path,
  tosAgreement,
  newsletterAgreement,
});

export interface ConfirmEmailsPageState {
  email?: string;
  registrationByCodeEmail?: string;
}

export interface RegisterStep2SuccessState {
  token?: string;
}

export function isConfirmEmailsPageState(
  state: any
): state is ConfirmEmailsPageState {
  return (
    state &&
    (state.hasOwnProperty('email') ||
      state.hasOwnProperty('registrationByCodeEmail'))
  );
}

export const mapToConfirmEmailsPageState = (
  email: string,
  registrationByCode?: boolean
): ConfirmEmailsPageState => {
  return registrationByCode ? { registrationByCodeEmail: email } : { email };
};

export const mapToRegisterStep2SuccessState = ({
  token,
}: RegisterStep2DTO): RegisterStep2SuccessState => ({
  token,
});
