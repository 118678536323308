import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="0 16 64 32" {...props}>
    <path d="M6.43156 40.709L9.23191 40.2931C9.36496 38.0089 9.26692 35.2108 8.5835 32.1116C7.9547 29.2575 6.99259 26.8893 6.07052 25.0631C5.71005 24.3489 4.99175 23.8867 4.19251 23.8531C3.87292 23.8405 3.55348 23.8279 3.23404 23.8153C1.78428 23.7565 0.67484 25.0911 0.997646 26.5056L4.63939 42.4568C4.7975 41.5675 5.51524 40.8449 6.43156 40.709Z" />
    <path d="M2.76279 23.4551L2.9828 23.3697C4.49488 22.7815 4.9314 21.3894 4.77105 19.933C4.60747 18.4429 3.84002 17.2511 2.1928 17.0396L2.01172 17.0158C0.890239 16.8716 -0.102548 17.7258 0.00850839 18.7412L0.390839 22.2213C0.504136 23.252 1.6934 23.871 2.76279 23.4551Z" />
    <path d="M6.84616 43.609C6.43568 43.609 6.10363 43.2771 6.10363 42.8681C6.10363 42.4578 6.43568 42.1259 6.84616 42.1259C7.25509 42.1259 7.587 42.4578 7.587 42.8681C7.587 43.2771 7.25509 43.609 6.84616 43.609ZM22.5761 38.6584L6.47237 41.0517C5.59387 41.182 4.94336 41.9354 4.94336 42.8247C4.94336 43.8134 5.74596 44.6159 6.73552 44.6159H25.6137C26.6052 44.6159 27.4077 43.8134 27.4077 42.8247C27.4077 40.2507 25.1207 38.2802 22.5761 38.6584Z" />
    <path d="M22.6576 45.975C21.984 46.4805 21.1199 46.7634 20.2194 46.7634H11.2397C10.3378 46.7634 9.47233 46.4791 8.80011 45.975L8.86874 45.7775H22.5904L22.6576 45.975ZM23.6702 45.0241H7.79879C7.36605 45.0241 7.13357 45.5324 7.41786 45.8588L7.53691 45.996C8.32816 46.9105 9.47934 47.4357 10.6879 47.4357H20.7698C21.935 47.4357 23.0456 46.9483 23.8354 46.0926L24.0413 45.87C24.3396 45.5478 24.1099 45.0241 23.6702 45.0241Z" />
    <path d="M6.84678 42.4067C6.5912 42.4067 6.38281 42.614 6.38281 42.8703C6.38281 43.1252 6.5912 43.3338 6.84678 43.3338C7.10167 43.3338 7.31033 43.1252 7.31033 42.8703C7.31033 42.614 7.10167 42.4067 6.84678 42.4067Z" />
    <path d="M43.0234 40.709L45.8237 40.2931C45.9568 38.0089 45.8587 35.2108 45.1753 32.1116C44.5465 29.2575 43.5844 26.8893 42.6623 25.0631C42.3018 24.3489 41.5835 23.8867 40.7843 23.8531C40.4647 23.8405 40.1453 23.8279 39.8258 23.8153C38.3761 23.7565 37.2666 25.0911 37.5894 26.5056L41.2312 42.4568C41.3893 41.5675 42.107 40.8449 43.0234 40.709Z" />
    <path d="M39.3546 23.4551L39.5746 23.3697C41.0867 22.7815 41.5232 21.3894 41.3628 19.933C41.1993 18.4429 40.4318 17.2511 38.7846 17.0396L38.6035 17.0158C37.482 16.8716 36.4892 17.7258 36.6003 18.7412L36.9826 22.2213C37.0959 23.252 38.2852 23.871 39.3546 23.4551Z" />
    <path d="M43.438 43.609C43.0275 43.609 42.6954 43.2771 42.6954 42.8681C42.6954 42.4578 43.0275 42.1259 43.438 42.1259C43.8469 42.1259 44.1788 42.4578 44.1788 42.8681C44.1788 43.2771 43.8469 43.609 43.438 43.609ZM59.1679 38.6584L43.0642 41.0517C42.1857 41.182 41.5352 41.9354 41.5352 42.8247C41.5352 43.8134 42.3378 44.6159 43.3273 44.6159H62.2055C63.197 44.6159 63.9995 43.8134 63.9995 42.8247C63.9995 40.2507 61.7125 38.2802 59.1679 38.6584Z" />
    <path d="M59.2494 45.975C58.5758 46.4805 57.7117 46.7634 56.8112 46.7634H47.8315C46.9296 46.7634 46.0641 46.4791 45.3919 45.975L45.4605 45.7775H59.1822L59.2494 45.975ZM60.262 45.0241H44.3906C43.9578 45.0241 43.7254 45.5324 44.0097 45.8588L44.1287 45.996C44.92 46.9105 46.0711 47.4357 47.2797 47.4357H57.3616C58.5268 47.4357 59.6374 46.9483 60.4272 46.0926L60.6331 45.87C60.9314 45.5478 60.7017 45.0241 60.262 45.0241Z" />
    <path d="M43.4386 42.4067C43.183 42.4067 42.9746 42.614 42.9746 42.8703C42.9746 43.1252 43.183 43.3338 43.4386 43.3338C43.6935 43.3338 43.9021 43.1252 43.9021 42.8703C43.9021 42.614 43.6935 42.4067 43.4386 42.4067Z" />
  </IconBox>
);
