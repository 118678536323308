import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import BadgeBig from 'src/assets/icons/BadgeBig';
import BadgeSmall from 'src/assets/icons/BadgeSmall';
import { Badge } from 'src/app/common/models/badge';

export interface BadgeContainerProps {
  variant?: BadgeVariantType;
  badge: string;
}

export type BadgeVariantType = 'small' | 'big';

const badgeToColorMapper: { [badge: string]: string } = {
  superDeal: '#FB2736',
  electric: '#01D159',
  hybrid: '#007FFF',
  theNew: '#AD00FF',
  available: '#FFF500',
};

export const badgeTypeLabelMapper = (badge?: Badge | string) => {
  switch (badge) {
    case Badge.TheNew:
      return <>Nowość</>;
    case Badge.SuperDeal:
      return (
        <>
          Super <br /> Promo
        </>
      );
    case Badge.Hybrid:
      return <>Hybryda</>;
    case Badge.Electric:
      return <>Elektryk</>;
    case Badge.Available:
      return (
        <>
          Dostępny <br /> od ręki
        </>
      );
    default:
      return '';
  }
};

const Container = styled.div<{ variant?: string }>`
  position: relative;
  display: flex;
  text-align: right;
  justify-content: flex-end;

  ${({ variant }) =>
    variant === 'big'
      ? css`
          height: 3.75rem;

          ${Content} {
            margin-right: 1.875rem;
            margin-top: 0.75rem;
          }
        `
      : css`
          height: 2.5rem;

          ${Content} {
            margin-right: 1.25rem;
            margin-top: 0.5rem;
          }
        `}
`;

const Content = styled.div<{ variant: BadgeVariantType }>`
  color: ${colors.white};
  z-index: 3;
  font-family: ${typography.fontFamilyRacingSansOne};
  text-transform: uppercase;

  ${({ variant }) =>
    variant === 'big'
      ? css`
          font-size: 1.375rem;
          line-height: 1.188rem;
        `
      : css`
          font-size: 0.875rem;
          line-height: 0.75rem;
        `}
`;

const StyledBadgeBig = styled(BadgeBig)`
  position: absolute;
`;

const StyledBadgeSmall = styled(BadgeSmall)`
  position: absolute;
`;

export const BadgeContainer: FC<BadgeContainerProps> = ({
  variant = 'big',
  badge,
}) => (
  <Container variant={variant}>
    <Content variant={variant}>{badgeTypeLabelMapper(badge)}</Content>
    {variant === 'big' && (
      <StyledBadgeBig
        color={badgeToColorMapper[badge]}
        width={100}
        height={60}
        viewBox="0 0 89 59"
      />
    )}
    {variant === 'small' && (
      <StyledBadgeSmall
        color={badgeToColorMapper[badge]}
        width={65}
        height={40}
        viewBox="0 0 65 40"
      />
    )}
  </Container>
);
