import React, { useRef } from 'react';
import { Box, Popper, Skeleton, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { Brand } from 'src/app/common/models/product';
import { rem } from 'polished';
import { useSelector } from 'react-redux';
import {
  brandsListSelector,
  modelsLookupSelector,
} from 'src/app/state/commonFilters/commonFiltersSelectors';
import { ExoticFilter, OptionFilter } from 'src/app/common/models/filter';
import {
  StyledAutocomplete,
  StyledTextField,
} from 'src/app/home/components/HomePanel/HomePanelFiltersTab/BrandMotorcycleAutocomplete/styles';
import { useSelectorTransform } from 'src/lib/useSelectorTransform';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import { HomePanelFiltersState } from 'src/app/home/components/HomePanel/HomePanelContent';
import { MOTORCYCLE_ID } from 'src/common/constants';
import { OptionalFold } from 'src/lib/OptionalFold';
import {
  brandIconMapper,
  brandIconResizeRates,
} from 'src/app/product/services/productMappers';
import { Icon } from 'src/common/components/Icon';
import {
  FilterBoxToggleButtonIcon,
  FilterBoxToggleButtonTitle,
} from 'src/app/common/components/FilterBoxToggleButton';

interface BrandMotorcycleAutocompleteProps {
  id?: string;
  changeValue: (
    value: string[] | any,
    kind: OptionFilter | ExoticFilter
  ) => void;
  state: HomePanelFiltersState;
}

export const BrandMotorcycleAutocomplete: FC<BrandMotorcycleAutocompleteProps> = ({
  changeValue,
}) => {
  const modelsList = useSelector(modelsLookupSelector);

  const brands = useSelectorTransform(brandsListSelector, (_) =>
    (_ ?? []).map((a) => ({
      id: a.id,
      label: a.label,
      options: a.models.map((b) => ({
        id: b.id,
        label: b.label,
        image: b.imageUrl,
        brandId: a.id,
      })),
    }))
  );

  const visibleBrands = brands.filter((a) => a.id === MOTORCYCLE_ID);

  const combinedOptions = visibleBrands
    .map((item) => item.options)
    .reduce((acc, options) => acc.concat(options), []);

  function filterOptions(options: any, { inputValue }: { inputValue: string }) {
    return options.filter((option: any) =>
      option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }

  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
      backgroundColor: 'rgb(255,255,255,0.98)',
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  if (!modelsList) {
    return <Skeleton style={{ height: rem(47) }} />;
  }

  return (
    <StyledAutocomplete
      multiple
      filterOptions={filterOptions}
      noOptionsText="Brak wyników"
      id="tags-outlined"
      options={combinedOptions}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.id === value.id;
      }}
      onChange={(_, newValue: any) => {
        const transformedValue = newValue.reduce(
          (acc: any, currentValue: any) => {
            const { brandId, id } = currentValue;
            if (!acc[brandId]) {
              acc[brandId] = [];
            }
            acc[brandId].push(id);
            return acc;
          },
          {}
        );
        if (inputRef.current) {
          inputRef.current.blur();
        }
        changeValue(transformedValue, ExoticFilter.Model);
      }}
      getOptionLabel={(brand) => {
        const typedBrand = brand as Brand & { models: BrandModel[] };
        return typedBrand.label;
      }}
      renderInput={(params) => (
        <StyledTextField {...params} label="MARKA" inputRef={inputRef} />
      )}
      renderOption={(props, option: any) => {
        return (
          <li
            {...props}
            style={{
              color: 'black',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <OptionalFold
                  key={option.id}
                  value={brandIconMapper(option.label)}
                  onSome={(code) => (
                    <FilterBoxToggleButtonIcon>
                      <Icon
                        code={code}
                        size={Math.round(24 * brandIconResizeRates[code])}
                      />
                    </FilterBoxToggleButtonIcon>
                  )}
                  onNone={
                    <FilterBoxToggleButtonTitle>
                      {option.label}
                    </FilterBoxToggleButtonTitle>
                  }
                />
              </Box>
              <Typography>{option.label}</Typography>
            </Box>
          </li>
        );
      }}
      PopperComponent={StyledPopper}
    />
  );
};
