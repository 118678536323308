import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { StyleProps } from '@summer/react-kit';
import { Logo } from 'src/common/components/Logo';
import { FooterNav } from 'src/public/components/Footer/FooterNav';
import { Text } from 'src/common/components/typography/Text';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { Partners } from 'src/common/components/PartnersBlock/Partners';

export const FooterSection = styled.footer`
  display: grid;
  align-items: start;
  margin-bottom: 2rem;
  margin-top: 1.875rem;
  grid-template-areas:
    'logo'
    'footer-menu-1'
    'footer-menu-2'
    'footer-menu-3'
    'partners'
    'copyright';
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-align: center;

  ${media.w.min.px768(css`
    text-align: left;
    grid-template-areas:
      'logo footer-menu footer-menu footer-menu .'
      'partners partners partners partners partners'
      '. . . copyright copyright';
    grid-row-gap: 2rem;
    grid-column-gap: 5vw;
    padding: 0 1.875rem;
    margin-top: 4rem;
    margin-bottom: 0;
    grid-template-columns: auto 1fr auto;
  `)};
`;

const StyledLogo = styled(Logo)`
  grid-area: logo;
  height: 2.375rem;

  ${media.w.max.px768(css`
    margin: 0 auto;
  `)};
`;

const StyledFooterNav = styled(FooterNav)`
  color: ${colors.grayBasic};
  display: contents;

  & > ul {
    &:nth-child(1) {
      grid-area: footer-menu-1;
    }

    &:nth-child(2) {
      grid-area: footer-menu-2;
    }

    &:nth-child(3) {
      grid-area: footer-menu-3;
    }
  }

  ${media.w.min.px768(css`
    grid-area: footer-menu;
    display: flex;
    justify-content: space-around;

    & > ul > li {
      margin-bottom: 0.4rem;
    }
  `)};
`;

const StyledCopyright = styled.div`
  align-self: center;
  grid-area: copyright;
  color: ${colors.grayBasic};
  margin-bottom: 4.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.w.min.px992(css`
    margin-bottom: 2.625rem;
    align-items: flex-end;
  `)};
`;

const PartnersText = styled.div`
  font-size: 0.75rem;
  margin-right: 1rem;
`;

const PartnersContainer = styled.div`
  grid-area: partners;

  ${media.w.min.px992(css`
    margin-top: 2.75rem;
  `)};
`;

export const Footer: FC<StyleProps> = (props) => (
  <ConstraintContainer>
    <FooterSection {...props}>
      <StyledLogo variant="light" />
      <StyledFooterNav />
      <StyledCopyright>
        <Text size="xSmall">Mobility Benefit Sp. z.o.o.</Text>
      </StyledCopyright>
      <PartnersContainer>
        <Partners>
          <PartnersText>Partnerzy motoryzacyjni</PartnersText>
        </Partners>
      </PartnersContainer>
    </FooterSection>
  </ConstraintContainer>
);
