import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';

export const PagesInfographicTitle = styled.div`
  color: ${colors.lightGray};
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.0625rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  max-width: 13rem;

  ${media.w.min.px768(css`
    max-width: none;
    font-size: 1.125rem;
  `)}
`;
