import { OneSideRangeSlider } from 'src/app/common/components/OneSideRangeSlider';
import { filterConfig } from 'src/app/common/models/filterConfig';
import React, { memo, useCallback } from 'react';
import { FiltersState, RangeFilter } from 'src/app/common/models/filter';
import { pushMonthlyInstallmentChoiceEvent } from 'src/app/home/services/homeGoogleTagManagerEvents';

interface HomePriceRangeFilterProps {
  state: FiltersState;
  changeValue: (value: [number, number], kind: RangeFilter) => void;
}

export const HomePriceRangeFilter = memo<HomePriceRangeFilterProps>(
  ({ state, changeValue }) => {
    const onAfterChange = useCallback(
      (v) => {
        // Assuming the first value remains constant and only the second value changes
        const newValue: [number, number] = [state.price?.value[0] || 0, v];
        changeValue(newValue, RangeFilter.Price);
        pushMonthlyInstallmentChoiceEvent(newValue);
      },
      [changeValue]
    );

    return (
      <OneSideRangeSlider
        onAfterChange={onAfterChange}
        value={state.price?.value}
        reactSliderOptions={{
          min: filterConfig[RangeFilter.Price].min + 500,
          max: filterConfig[RangeFilter.Price].max,
          step: filterConfig[RangeFilter.Price].step,
        }}
      />
    );
  }
);
