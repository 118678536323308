import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M64 32c0 17.6731-14.3269 32-32 32C14.3269 64 0 49.6731 0 32 0 14.3269 14.3269 0 32 0c17.6731 0 32 14.3269 32 32z" />
    <path
      fill="#2A2B32"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56 21.3615L24.3832 52 12 40l5.5327-5.3615 6.8505 6.6386L50.4674 16 56 21.3615z"
    />
  </IconBox>
);
