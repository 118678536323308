import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Icon, IconCode } from 'src/common/components/Icon';
import styled from 'styled-components';

const Container = styled.div`
  margin: 1rem;
  max-width: 80rem;
  max-height: 40rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
`;

const IconWrapper = styled.div<{ color: string }>`
  margin: 1rem;
  color: ${({ color }) => color};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 8rem;
  min-height: 8rem;
  padding: 0.75rem;
  border: 2px dashed white;
`;

const Inputs = styled.div`
  display: flex;
  margin: 1rem 4rem;
`;

const Input = styled.div`
  margin: 1rem;
  color: white;

  & > h4 {
    margin-bottom: 0.25rem;
  }
`;

const IconTitle = styled.div`
  font-size: 0.75rem;
  text-align: center;
  word-break: break-all;
  margin-top: 1rem;
  color: white;
`;

const StyledIcon = styled(Icon)`
  border: 1px dashed rgba(255, 255, 255, 0.5);
`;

export const IconShowcase: FC<RouteComponentProps> = () => {
  const [size, setSize] = useState('64');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [color, setColor] = useState('white');

  return (
    <div>
      <Inputs>
        <Input>
          <h4>Size</h4>
          <input
            type="number"
            min={1}
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
        </Input>
        <Input>
          <h4>Width</h4>
          <input
            type="number"
            min={1}
            value={width}
            onChange={(e) => setWidth(e.target.value)}
          />
        </Input>
        <Input>
          <h4>Height</h4>
          <input
            type="number"
            min={1}
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </Input>
        <Input>
          <h4>Color</h4>
          <input
            type="string"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </Input>
      </Inputs>
      <Container>
        {Object.keys(IconCode).map((key) => (
          <IconWrapper key={key} color={color}>
            <div />
            <StyledIcon
              code={key}
              size={+size > 0 ? +size : undefined}
              width={+width > 0 ? +width : undefined}
              height={+height > 0 ? +height : undefined}
            />
            <IconTitle>{key}</IconTitle>
          </IconWrapper>
        ))}
      </Container>
    </div>
  );
};
