import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  homeAdvertDealsActions,
  homeAdvertSlidesActions,
} from 'src/app/home/state/homeAdvert/homeAdvertActions';
import {
  fetchHomeAdvertDeals,
  fetchHomeAdvertSlides,
} from 'src/app/home/services/homeAdvert';

function* processDealsRequest() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchHomeAdvertDeals);
    yield put(homeAdvertDealsActions.requestSuccess(response));
  } catch (error) {
    yield put(homeAdvertDealsActions.requestFailure(error));
  }
}

function* dealsRequestWatcher() {
  yield takeEvery(homeAdvertDealsActions.request.type, processDealsRequest);
}

function* processSlidesRequest() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchHomeAdvertSlides);
    yield put(homeAdvertSlidesActions.requestSuccess(response));
  } catch (error) {
    yield put(homeAdvertSlidesActions.requestFailure(error));
  }
}

function* slidesRequestWatcher() {
  yield takeEvery(homeAdvertSlidesActions.request.type, processSlidesRequest);
}

export function* homeAdvertSaga() {
  yield all([dealsRequestWatcher(), slidesRequestWatcher()]);
}
