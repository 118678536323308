import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import React, { forwardRef, memo, ReactNode } from 'react';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { InstructionSteps } from 'src/common/components/InstructionSteps';
import getNewCarImage1 from 'src/assets/images/get-new-car-image-1.jpg';
import getNewCarImage2 from 'src/assets/images/get-new-car-image-2.jpg';
import getNewCarImage3 from 'src/assets/images/get-new-car-image-3.jpg';
import getNewCarImage3Mobile from 'src/assets/images/get-new-car-image-3-mobile.jpg';
import { InstructionStep } from 'src/common/models/instructionStep';
import { colors } from 'src/config/colors';
import { skewBoth } from 'src/common/styles/skew';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { StyleProps } from '@summer/react-kit';

const Container = styled.div`
  padding: 2rem 0 3.5rem;
  position: relative;
  z-index: 0;
  overflow: hidden;
`;

const GradientOverlay = styled.div`
  position: absolute;

  background: linear-gradient(0deg, #93969c 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas: 'im1 im2' 'im3 im3';
  align-items: center;
  justify-content: center;

  ${media.w.max.px768(css`
    margin-left: -9rem;
  `)};

  ${media.w.min.px992(css`
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;
  `)};
`;

const Image1 = styled.img`
  ${media.w.max.px992(css`
    ${skewBoth('3.5rem')};
    grid-area: im1;
    max-width: 24.25rem;
    margin-right: -7rem;
    opacity: 0.9;
    position: relative;
    z-index: 2;
  `)}

  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
`;

const Image2 = styled.img`
  ${skewBoth('6rem')};

  ${media.w.max.px992(css`
    opacity: 0.9;
    grid-area: im2;
  `)}

  ${media.w.min.px992(css`
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    margin: 0 -5rem;
  `)}
`;

const Image3 = styled.img`
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);

  ${media.w.max.px992(css`
    display: none;
  `)}
`;

const Image3MobileWrapper = styled.div`
  text-align: center;
  grid-area: im3;
  margin-top: -3rem;
  margin-left: 11rem;

  ${media.w.min.px992(css`
    display: none;
  `)}
`;

const Image3Mobile = styled.img`
  ${skewBoth('3.5rem')};
  opacity: 0.9;
  grid-area: im3;
  display: inline;
`;

const StyledSectionHeading = styled(SectionHeading)`
  position: relative;
  z-index: 1;
  margin-top: 3rem;
  margin-bottom: 5.875rem;
  color: ${colors.lightGray};
  font-size: 1.375rem;
  line-height: 1.625rem;

  ${media.w.min.px992(css`
    margin-bottom: 4.875rem;
  `)}
`;

const StyledConstraintContainer = styled(ConstraintContainer)`
  position: relative;
  z-index: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5.375rem;
`;

const getNewCarInstructions: InstructionStep[] = [
  {
    title: 'WYBIERASZ SWÓJ WYMARZONY SAMOCHÓD',
    content: (
      <>
        Wybierasz swoje auto, a&nbsp;nasz doradca przedstawi Ci
        najkorzystniejsze możliwości finansowania
      </>
    ),
  },
  {
    title: 'WYPEŁNIASZ WNIOSEK',
    content:
      'Na tym etapie  przeprowadzona zostanie analiza kredytowa niezbędna do zawarcia umow',
  },
  {
    title: 'PODPISUJESZ UMOWĘ ONLINE',
    content: (
      <>
        W&nbsp;przypadku najmu lub leasingu auta, po otrzymaniu pozytywnej
        decyzji kredytowej podpiszesz umowę
      </>
    ),
  },
  {
    title: 'ODBIERASZ SWOJE AUTO',
    content:
      'Samochód odbierzesz od dealera lub w wybranej przez siebie lokalizacji',
  },
];

export const GetNewCarInstructionsStepSection = memo(
  forwardRef<HTMLDivElement, StyleProps & { cta?: ReactNode }>(
    ({ style, className, cta }, ref) => (
      <Container ref={ref} style={style} className={className}>
        <GradientOverlay />

        <Images>
          <Image1 src={getNewCarImage1} />
          <Image2 src={getNewCarImage2} />
          <Image3 src={getNewCarImage3} />
          <Image3MobileWrapper>
            <Image3Mobile src={getNewCarImage3Mobile} />
          </Image3MobileWrapper>
        </Images>

        <StyledConstraintContainer>
          <StyledSectionHeading>
            Lepiej MIEĆ marzenia czy BYĆ kierowcą nowego auta?
          </StyledSectionHeading>

          <InstructionSteps steps={getNewCarInstructions} />
          {cta && <ButtonContainer>{cta}</ButtonContainer>}
        </StyledConstraintContainer>
      </Container>
    )
  )
);
