import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <g clipPath="url(#NissanLogoClip)">
      <path d="M37.2957 33.914c-.0508.0127-.254.0127-.3429.0127h-6.5524v1.5238h6.6667c.0508 0 .4445 0 .5206-.0127 1.3588-.1269 1.981-1.2571 1.981-2.2476 0-1.0159-.6476-2.1079-1.8794-2.2222-.2412-.0254-.4444-.0254-.5333-.0254h-4.3556c-.1904 0-.4063-.0127-.4825-.0381-.3429-.0889-.4698-.3937-.4698-.6476 0-.2286.1269-.5334.4825-.6349.1016-.0254.2159-.0381.4571-.0381h6.2858v-1.4985h-6.3873c-.2667 0-.4699.0127-.635.0381-1.092.1524-1.8539 1.0286-1.8539 2.1461 0 .9143.5714 1.9809 1.8285 2.1587.2286.0254.5461.0254.6858.0254h4.2412c.0762 0 .2667 0 .3048.0127.4825.0635.6476.4191.6476.7365 0 .3048-.1905.6349-.6095.7111z" />
      <path d="M24.8758 33.914c-.0508.0127-.254.0127-.3302.0127h-6.5651v1.5238h6.6667c.0508 0 .4445 0 .5206-.0127 1.3588-.1269 1.981-1.2571 1.981-2.2476 0-1.0159-.6476-2.1079-1.8794-2.2222-.2412-.0254-.4444-.0254-.5333-.0254h-4.3556c-.1904 0-.4063-.0127-.4825-.0381-.3429-.0889-.4699-.3937-.4699-.6476 0-.2286.127-.5334.4826-.6349.1016-.0254.2159-.0381.4571-.0381h6.2858v-1.4985h-6.3874c-.2666 0-.4698.0127-.6349.0381-1.092.1524-1.854 1.0286-1.854 2.1461 0 .9143.5715 1.9809 1.8286 2.1587.2286.0254.5461.0254.6857.0254h4.2413c.0762 0 .2667 0 .3048.0127.4825.0635.6476.4191.6476.7365 0 .3048-.1778.6349-.6095.7111z" />
      <path d="M14.5658 28.0352H12.915v7.4539h1.6508v-7.4539z" />
      <path d="M9.26988 35.4891v-7.4539h-1.6508v5.5619l-5.49844-5.5619H0v7.4539h1.6508v-5.5873l5.53653 5.5873h2.08255z" />
      <path d="M62.3622 28.0352v5.5619l-5.4984-5.5619h-2.1206v7.4539h1.6508v-5.5873l5.5238 5.5873h2.0825v-7.4539h-1.6381z" />
      <path d="M46.1467 28.0352l-4.6477 7.4539h2.0064l.8254-1.3333h5.4222l.8254 1.3333h1.9937l-4.6477-7.4539h-1.7777zm2.7555 4.7111h-3.7206l1.8666-2.9969 1.854 2.9969z" />
      <path d="M9.18052 23.8064C12.5075 14.0921 21.6758 7.56509 32.0124 7.56509c10.3365 0 19.5175 6.52701 22.8318 16.24131l.0254.0762h7.2762v-.8762l-3.0222-.3555c-1.8667-.2159-2.2604-1.0413-2.7683-2.0826l-.127-.254C51.86 11.0191 42.3489 5 32.0124 5 21.6631 5 12.1647 11.0191 7.79639 20.3397l-.12699.254c-.50793 1.0413-.90159 1.8667-2.76826 2.0826l-3.02223.3555v.8762h7.26352l.03809-.1016z" />
      <path d="M54.8706 39.6538l-.0254.0762c-3.327 9.7143-12.4953 16.2286-22.8319 16.2286-10.3365 0-19.5175-6.527-22.83181-16.2413l-.02539-.0762H1.89258v.8762l3.02223.3556c1.86668.2158 2.26033 1.0412 2.76827 2.0825l.12698.254C12.1783 52.53 21.6895 58.5491 32.026 58.5491c10.3366 0 19.8477-6.0191 24.216-15.327l.127-.254c.5079-1.0413.9016-1.8667 2.7682-2.0825l3.0223-.3556v-.8762h-7.2889z" />
    </g>
    <defs>
      <clipPath id="NissanLogoClip">
        <path d="M0 0h64v53.5494H0z" transform="translate(0 5)" />
      </clipPath>
    </defs>
  </IconBox>
);
