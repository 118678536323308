import React, { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SearchFiltersGroupContainer } from 'src/app/search/components/SearchPanel/SearchFiltersGroupContainer';
import { SearchPackageEquipmentFilter } from 'src/app/search/components/SearchPanel/SearchEquipmentFilters/SearchPackageEquipmentFilter';
import { SearchElementEquipmentFilter } from 'src/app/search/components/SearchPanel/SearchEquipmentFilters/SearchElementEquipmentFilter';
import { media } from 'src/config/breakpoints';
import { SearchEquipmentFilterToggle } from 'src/app/search/components/SearchPanel/SearchEquipmentFilters/SearchEquipmentFilterToggle';

const Container = styled(SearchFiltersGroupContainer)`
  grid-template-areas: 'toggle' 'content';
  grid-row-gap: 2rem;
  width: 100%;

  ${media.w.min.px768(css`
    grid-row-gap: 0;
    grid-template-areas: 'elements packages';
    grid-template-columns: 2fr 1fr;
  `)};

  ${media.w.min.px992(css`
    grid-template-columns: 50% 50%;
    max-width: 78.125rem;
  `)};
`;

const StyledSearchElementEquipmentFilter = styled(
  SearchElementEquipmentFilter
)<{ state: string }>`
  grid-area: content;

  ${media.w.min.px768(css`
    grid-area: elements;
  `)};

  ${({ state }) =>
    state === 'packages' &&
    css`
      ${media.w.max.px768(css`
        display: none;
      `)};
    `}
`;
const StyledSearchPackageEquipmentFilter = styled(
  SearchPackageEquipmentFilter
)<{ state: string }>`
  grid-area: content;

  ${media.w.min.px768(css`
    grid-area: packages;
  `)};

  ${({ state }) =>
    state === 'options' &&
    css`
      ${media.w.max.px768(css`
        display: none;
      `)};
    `}
`;

const StyledSearchEquipmentFilterToggle = styled(SearchEquipmentFilterToggle)`
  grid-area: toggle;

  ${media.w.min.px768(css`
    display: none;
  `)};
`;

export const SearchEquipmentFilters = memo(() => {
  const [currentState, setCurrentState] = useState<string[]>([]);

  const changeState = (state: string[]) => {
    setCurrentState(state);
  };

  useEffect(() => {
    changeState(['packages']);
  }, []);

  return (
    <Container>
      <StyledSearchEquipmentFilterToggle
        value={currentState}
        setValue={changeState}
        variant="secondary"
      />
      <StyledSearchElementEquipmentFilter state={currentState[0]} />
      <StyledSearchPackageEquipmentFilter state={currentState[0]} />
    </Container>
  );
});
