import { RefObject, useEffect } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  closestExternalId?: string,
  excludeElementId?: string
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      const isInsideMainRef = ref.current && ref.current.contains(element);
      const isInsideExcludedElement =
        excludeElementId && element.closest(`#${excludeElementId}`);
      const isInsideClosestExternal =
        closestExternalId && element.closest(`#${closestExternalId}`);

      if (
        !isInsideMainRef &&
        !isInsideExcludedElement &&
        !isInsideClosestExternal
      ) {
        callback();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
