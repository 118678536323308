import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const ColorPreview = styled.div<{ color: string }>`
  width: 2.5rem;
  height: 2.5rem;
  background: linear-gradient(
      140.55deg,
      rgba(255, 255, 255, 0.3) 3.29%,
      rgba(255, 255, 255, 0) 95.3%
    ),
    linear-gradient(
      22.97deg,
      rgba(0, 0, 0, 0.15) 46.67%,
      rgba(0, 0, 0, 0) 53.42%
    ),
    ${({ color }) => color};

  ${media.w.min.px992(css`
    width: 1.875rem;
    height: 1.875rem;
  `)};
`;
