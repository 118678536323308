import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const HowItWorksInfographicContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 4rem;

  ${media.w.min.px768(css`
    padding-bottom: 12.5rem;
  `)};

  &:last-child {
    padding-bottom: 2rem;

    ${media.w.min.px768(css`
      padding-bottom: 6rem;
    `)};
  }
`;
