import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { typography } from 'src/config/typography';
import {
  TypographyBase,
  typographyBaseCss,
} from 'src/common/styles/typography';
import { media } from 'src/config/breakpoints';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4';

export interface TitleProps
  extends TypographyBase,
    HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4;
}

const TitleBase: FC<TitleProps> = ({ level, children, ...props }) => {
  const Header = `h${level}` as HeadingTag;

  // if responsive is passed to header, react will throw warning
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { responsive, ...headerProps } = props;

  return <Header {...headerProps}>{children}</Header>;
};

export const Title = styled(TitleBase)`
  ${typographyBaseCss};
  font-size: ${({ level }) => typography.headingSize[level]};
  line-height: ${({ level }) => typography.lineHeight[level]};
  ${({ level, responsive }) =>
    responsive &&
    media.w.max.px576(css`
      font-size: ${typography.headingSize[level + 1]};
    `)};

  font-weight: ${typography.headingWeight};
  margin-top: ${(props) => typography.headingMargin[props.level][0]};
  margin-bottom: ${(props) => typography.headingMargin[props.level][1]};
`;
