import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M4.0565 43.5063c-.72711 0-1.3155-.5884-1.3155-1.3107v-20.139c0-.7224.58839-1.3108 1.3155-1.3108l52.1127.0001c.7224 0 1.3107.5883 1.3107 1.3107v20.139c0 .7223-.5883 1.3107-1.3107 1.3107H4.0565zm56.1692-1.3107v-5.1424h2.521c.6888 0 1.2533-.5597 1.2533-1.2533v-7.008c0-.6937-.5645-1.2533-1.2533-1.2533h-2.521v-5.482c0-2.2388-1.8225-4.0566-4.0565-4.0566H4.0565C1.81777 18 9.8e-7 19.8178 8.8e-7 22.0566L0 42.1956c-1e-7 2.2339 1.81776 4.0565 4.0565 4.0565h52.1127c2.234 0 4.0565-1.8226 4.0565-4.0565z" />
    <path d="M54.9381 40.0036V24.2464c0-.6697-.5405-1.2102-1.2102-1.2102h-8.8545v18.1777h8.8545c.6697 0 1.2102-.5406 1.2102-1.2103zM31.6214 23.0362v18.1777H41.691V23.0362H31.6214zM18.3733 23.0362v18.1777h10.0695V23.0362H18.3733zM6.16697 23.0362c-.57404 0-1.03807.464-1.03807 1.0332v16.1112c0 .5693.46403 1.0333 1.03807 1.0333h9.03143V23.0362H6.16697z" />
  </IconBox>
);
