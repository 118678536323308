import React, { memo } from 'react';
import styled from 'styled-components';
import { ProductPageTestsImage } from 'src/app/product/components/ProductPageTests/ProductPageTestsImage';
import { ProductTestGroup } from 'src/app/product/models/productTest';

const Group = styled.div`
  display: flex;
`;

export interface ProductPageTestsSlideProps {
  group: ProductTestGroup;
  onSlideClick: (id: string) => void;
}

export const ProductPageTestsSlide = memo<ProductPageTestsSlideProps>(
  ({ group, onSlideClick }) => (
    <>
      {group.rows.map((row, index) => (
        <Group key={index}>
          {row.map((productTest, index) => (
            <ProductPageTestsImage
              productTest={productTest}
              key={index}
              onSlideClick={onSlideClick}
            />
          ))}
        </Group>
      ))}
    </>
  )
);
