import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 42.8962C2.44832 42.8962 2 42.4479 2 41.8962V30.6222C2 30.2617 2.0646 29.9051 2.1909 29.5695L6.47632 18.1414C7.20801 16.1896 9.07395 14.8962 11.158 14.8962H25V41.8962C25 42.4479 24.5517 42.8962 24 42.8962H21C20.4477 42.8962 20 43.344 20 43.8962C20 44.4485 20.4477 44.8962 21 44.8962H24C25.6563 44.8962 27 43.5524 27 41.8962V13.8962C27 13.344 26.5523 12.8962 26 12.8962H11.158C8.24008 12.8962 5.62799 14.7069 4.60365 17.4391C4.60366 17.4391 4.60365 17.4392 4.60365 17.4391L0.319105 28.865C0.319004 28.8652 0.318904 28.8655 0.318804 28.8658C0.107307 29.428 0 30.0231 0 30.6222V41.8962C0 43.5524 1.34368 44.8962 3 44.8962C3.55228 44.8962 4 44.4485 4 43.8962C4 43.344 3.55228 42.8962 3 42.8962Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 36.8961C15 37.4484 15.4477 37.8961 16 37.8961H20C20.5523 37.8961 21 37.4484 21 36.8961C21 36.3438 20.5523 35.8961 20 35.8961H16C15.4477 35.8961 15 36.3438 15 36.8961Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00003 29.8964C7.00003 30.4486 7.44774 30.8964 8.00003 30.8964H14.299C14.9798 30.8964 15.6401 30.6653 16.1727 30.2392L19.8737 27.2782C19.8737 27.2782 19.8737 27.2782 19.8737 27.2782C20.586 26.7084 21 25.8461 21 24.9354V19.8964C21 19.3441 20.5523 18.8964 20 18.8964C19.4477 18.8964 19 19.3441 19 19.8964V24.9354C19 25.2387 18.8621 25.5263 18.6244 25.7165L14.9233 28.6775C14.9233 28.6775 14.9233 28.6775 14.9233 28.6775C14.7459 28.8194 14.5263 28.8964 14.299 28.8964H8.00003C7.44774 28.8964 7.00003 29.3441 7.00003 29.8964Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.3049 32.6152C36.702 32.9991 37.3351 32.9884 37.7189 32.5913L57.8809 11.7363C57.8948 11.7219 57.9083 11.7072 57.9213 11.692C59.2977 10.0859 59.2067 7.69032 57.7115 6.19364L57.7101 6.19215L57.707 6.18904L57.7041 6.18614C56.2075 4.68954 53.8108 4.59806 52.2042 5.97495L52.1957 5.98234L30.3407 25.1443C29.9255 25.5084 29.884 26.1402 30.2481 26.5555C30.6122 26.9708 31.244 27.0123 31.6592 26.6482L53.5095 7.49026C54.3225 6.79694 55.5325 6.84411 56.2888 7.59923L56.2899 7.60036L56.293 7.60347L56.2958 7.60636C57.0469 8.3574 57.0989 9.55671 56.4201 10.3699L36.281 31.2012C35.8972 31.5982 35.9079 32.2313 36.3049 32.6152Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 30.8962C58.8953 30.8962 58 30.001 58 28.8962V16.8962C58 16.344 57.5523 15.8962 57 15.8962C56.4477 15.8962 56 16.344 56 16.8962V28.8962C56 31.1055 57.7907 32.8962 60 32.8962C62.2093 32.8962 64 31.1055 64 28.8962V27.8962C64 27.344 63.5523 26.8962 63 26.8962C62.4477 26.8962 62 27.344 62 27.8962V28.8962C62 30.001 61.1047 30.8962 60 30.8962Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.74 37.5688C38.1115 37.1601 38.0813 36.5277 37.6727 36.1562L26.6727 26.1562C26.264 25.7847 25.6316 25.8148 25.2601 26.2234C24.8886 26.6321 24.9187 27.2646 25.3273 27.6361L36.3273 37.6361C36.736 38.0076 37.3684 37.9774 37.74 37.5688Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61 50.8964C61 51.4486 61.4477 51.8964 62 51.8964H63C63.5523 51.8964 64 51.4486 64 50.8964V43.8964C64 43.608 63.8755 43.3337 63.6585 43.1438L55.6585 36.1438C55.4762 35.9843 55.2422 35.8964 55 35.8964H26C25.4477 35.8964 25 36.3441 25 36.8964C25 37.4486 25.4477 37.8964 26 37.8964H54.6243L62 44.3501V49.8964C61.4477 49.8964 61 50.3441 61 50.8964Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00003 52.3962C7.00003 56.5383 10.3577 59.8962 14.5 59.8962C18.6423 59.8962 22 56.5383 22 52.3962C22 48.2542 18.6423 44.8962 14.5 44.8962C10.3577 44.8962 7.00003 48.2542 7.00003 52.3962ZM14.5 57.8962C11.4623 57.8962 9.00003 55.4338 9.00003 52.3962C9.00003 49.3587 11.4623 46.8962 14.5 46.8962C17.5377 46.8962 20 49.3587 20 52.3962C20 55.4338 17.5377 57.8962 14.5 57.8962Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 52.3962C44 56.5383 47.3577 59.8962 51.5 59.8962C55.6423 59.8962 59 56.5383 59 52.3962C59 48.2542 55.6423 44.8962 51.5 44.8962C47.3577 44.8962 44 48.2542 44 52.3962ZM51.5 57.8962C48.4623 57.8962 46 55.4338 46 52.3962C46 49.3587 48.4623 46.8962 51.5 46.8962C54.5377 46.8962 57 49.3587 57 52.3962C57 55.4338 54.5377 57.8962 51.5 57.8962Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 50.8964C24 51.4486 24.4477 51.8964 25 51.8964H41C41.5523 51.8964 42 51.4486 42 50.8964C42 50.3441 41.5523 49.8964 41 49.8964H25C24.4477 49.8964 24 50.3441 24 50.8964Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96441 51.8863C4.51113 51.9645 5.01773 51.5847 5.09594 51.038C5.75447 46.4345 9.7146 42.8964 14.5 42.8964C19.2854 42.8964 23.2455 46.4345 23.9041 51.038C23.9823 51.5847 24.4889 51.9645 25.0356 51.8863C25.5823 51.8081 25.9621 51.3015 25.8839 50.7547C25.0865 45.1801 20.2946 40.8964 14.5 40.8964C8.70542 40.8964 3.91356 45.1801 3.11609 50.7547C3.03789 51.3015 3.41769 51.8081 3.96441 51.8863Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 49.8964C0 51.0018 0.89478 51.8964 2 51.8964H4C4.55228 51.8964 5 51.4486 5 50.8964C5 50.3441 4.55228 49.8964 4 49.8964H2V44.8964L3 44.8962C3.55228 44.8962 4 44.4485 4 43.8962C4 43.344 3.55228 42.8962 3 42.8962L2 42.8964C0.89478 42.8964 0 43.7909 0 44.8964V49.8964Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 43.8962C22 44.4485 22.4477 44.8962 23 44.8962H44C44.5523 44.8962 45 44.4485 45 43.8962C45 43.344 44.5523 42.8962 44 42.8962H23C22.4477 42.8962 22 43.344 22 43.8962Z"
    />
  </IconBox>
);
