import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { media } from 'src/config/breakpoints';
import { CleanButton } from 'src/common/components/Button';
import { skewBoth } from 'src/common/styles/skew';
import { transparentize } from 'polished';
import { colors } from 'src/config/colors';
import ArrowLeft from 'src/assets/icons/ArrowLeft';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { styledIs } from '@summer/react-kit/functions';
import { deceleratedEasing } from 'src/common/styles/animations';
import { StyleProps } from '@summer/react-kit';
import { NavigationOptions } from 'swiper/types/components/navigation';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

const OverflowHidden = styled.div`
  overflow: hidden;
`;

const Container = styled(ConstraintContainer).attrs({
  className: 'sac__container',
})<{ enabled?: boolean }>`
  position: relative;
  height: 100%;

  ${styledIs('enabled')(css`
    padding: 0;

    ${media.w.min.px768(css`
      padding: 0 4.5rem;
    `)};
  `)};
`;

const ArrowButton = styled(CleanButton).attrs({ className: 'sac__arrow' })`
  ${skewBoth('1.5rem')};
  height: 3rem;
  width: 6rem;
  padding: 0 1.5rem;
  transition: all 150ms ${deceleratedEasing};
  color: ${colors.grayBasic};
  background-color: ${colors.lightGray};

  position: absolute;
  top: 50%;
  z-index: 100;

  &.swiper-button-disabled {
    opacity: 0.2;
    cursor: default;
  }

  & > svg {
    transition: transform 150ms ${deceleratedEasing};
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${transparentize(0.25, colors.lightGray)};
    }
  }

  &:not(:disabled):focus {
    background-color: ${colors.darkBlue};
    color: ${colors.primary};
  }

  &:not(:disabled):active {
    background-color: ${colors.primary};
    color: ${colors.white};
  }

  ${media.w.min.px768(css`
    ${skewBoth('2.7rem')};
    height: 6.5rem;
    width: 10rem;
    padding: 0 2.7rem;
    background-color: ${transparentize(0.5, colors.lightGray)};

    &:not(:disabled):active {
      background-color: ${transparentize(0.6, colors.primary)};
      color: ${colors.primary};
    }
  `)};
`;

const ArrowLeftButton = styled(ArrowButton)`
  left: 0;
  transform: translate(-2rem, -50%);
  text-align: right;

  ${media.w.min.px768(css`
    transform: translate(-4rem, -50%);
  `)};

  @media (hover: hover) {
    &:not(:disabled):hover {
      & > svg {
        transform: translateX(-0.5rem);
      }
    }
  }

  &:not(:disabled):focus {
    & > svg {
      transform: translateX(-0.5rem);
    }
  }
`;

const ArrowRightButton = styled(ArrowButton)`
  right: 0;
  transform: translate(2rem, -50%);
  text-align: left;

  ${media.w.min.px768(css`
    transform: translate(4rem, -50%);
  `)};

  @media (hover: hover) {
    &:not(:disabled):hover {
      & > svg {
        transform: translateX(0.5rem);
      }
    }
  }

  &:not(:disabled):focus {
    & > svg {
      transform: translateX(0.5rem);
    }
  }
`;

export interface SliderArrowControlsProps extends StyleProps {
  enabled: boolean;
  onNavigation: (navigation: NavigationOptions) => void;
}

export const SliderArrowControls: FC<SliderArrowControlsProps> = ({
  enabled,
  onNavigation,
  className,
  style,
  children,
}) => {
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    onNavigation({ prevEl, nextEl });
  }, [prevEl, nextEl, onNavigation]);

  useEffect(() => {
    if (!enabled) {
      onNavigation({ prevEl: null, nextEl: null });
    }
  }, [enabled]);

  return (
    <OverflowHidden className={className} style={style}>
      <Container enabled={enabled}>
        {enabled && (
          <ArrowLeftButton ref={setPrevEl}>
            <ArrowLeft size={28} />
          </ArrowLeftButton>
        )}
        {children}
        {enabled && (
          <ArrowRightButton ref={setNextEl}>
            <ArrowRight size={28} />
          </ArrowRightButton>
        )}
      </Container>
    </OverflowHidden>
  );
};
