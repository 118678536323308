import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { SectionHeading } from 'src/common/components/SectionHeading';
import { colors } from 'src/config/colors';
import Fuel from 'src/assets/images/fuel.svg';
import CarWash from 'src/assets/images/car-wash.svg';
import Bus from 'src/assets/images/bus.svg';
import Sprinkler from 'src/assets/images/hr-sprinkler.svg';
import Bicycle from 'src/assets/images/bicycle.svg';
import Speedometer from 'src/assets/images/speedometer.svg';
import mobilityCard from 'src/assets/images/mobility-card.png';
import { skewBoth } from 'src/common/styles/skew';
import { useMediaQuery } from 'react-responsive';
import BlueRing from 'src/assets/images/blue-ring.svg';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1440px;
  margin: 0 auto 1rem;
  padding: 5rem 1rem;
  position: relative;

  ${media.w.min.px768(css`
    padding: 3rem 1rem;
  `)};

  ${media.w.between.px768.px992(css`
    padding: 3rem 1rem 7rem;
  `)}
`;

const HeadingWrapper = styled.div`
  background-color: ${colors.background};
`;

const StyledSectionHeading = styled(SectionHeading)`
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0.625rem;
  color: ${colors.lightGray};

  & span {
    color: ${colors.primary};
  }
`;

const Subtitle = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  max-width: 16rem;
  margin: 0 auto 1rem;

  ${media.w.min.px768(css`
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.465rem;
    max-width: 25rem;
  `)};
`;

const MobilityCardWrapper = styled.div`
  ${media.w.min.px768(css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 37rem;
    min-height: 37rem;
    margin: -6.5rem auto 2rem;
  `)};

  ${media.w.between.px768.px1200(css`
    max-width: 32rem;
    min-height: 32rem;
  `)}
`;

const StyledBlueRing = styled(BlueRing)`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const MobilityCard = styled.div`
  ${media.w.min.px768(css`
    position: relative;
  `)};

  &:before {
    content: '';
    background: radial-gradient(
      35.35% 35.46% at 50% 50%,
      #007fff 0%,
      rgba(0, 127, 255, 0) 100%
    );
    position: absolute;
    z-index: 1;
    top: 10%;
    left: -35%;
    width: 30rem;
    height: 30rem;
    opacity: 0.5;

    ${media.w.min.px768(css`
      top: -200%;
      left: -85%;
      width: 45rem;
      height: 45rem;
    `)};
  }

  &:after {
    content: '';
    background: radial-gradient(
      35.35% 35.46% at 50% 50%,
      #ffffff 0%,
      rgba(251, 251, 251, 0) 100%
    );
    position: absolute;
    z-index: 1;
    top: 18%;
    right: -35%;
    width: 30rem;
    height: 30rem;
    opacity: 0.2;

    ${media.w.min.px768(css`
      top: -100%;
      right: -80%;
      width: 45rem;
      height: 45rem;
    `)};
  }
`;

const Image = styled.img`
  display: block;
  margin: 1.5rem auto 0;
  position: relative;
  z-index: 2;
  width: 16.6875rem;
  border-radius: 24px;

  ${media.w.min.px768(css`
    margin: -4rem auto 0;
    width: 21.9375rem;
  `)}
`;

const CardBenefits = styled.div`
  ${media.w.max.px768(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    justify-items: center;
    padding: 2.5rem 0;
  `)}
`;

const Benefit = styled.div`
  z-index: 1;
  display: flex;
  max-width: 8rem;
  font-size: 0.875rem;
  font-weight: 300;
  align-items: center;

  ${media.w.max.px768(css`
    &:nth-child(even) {
      margin-top: 1rem;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 11.6875rem;
      height: 4.1825rem;
      ${skewBoth('2rem')};
      background: linear-gradient(
        157.68deg,
        #007fff 9.99%,
        rgba(0, 127, 255, 0) 46.64%
      );
      opacity: 0.5;
    }
  `)}

  ${media.w.min.px768(css`
    position: absolute;
    padding: 1rem 0;
    max-width: 11rem;
    font-size: 1rem;
  `)}

  ${media.w.min.px1200(css`
    min-width: 18rem;
  `)}
`;

const Icon = styled.div`
  margin: 0 0.5rem;
  opacity: 0.5;

  svg {
    width: 1.5625rem;
    height: 1.5625rem;
  }

  ${media.w.min.px768(css`
    margin: 0 1rem;
    opacity: 1;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  `)};
`;

const Benefit1 = styled(Benefit)`
  ${media.w.min.px768(css`
    flex-direction: row-reverse;
    text-align: right;
    top: 18%;
    left: -24%;
  `)}

  ${media.w.min.px1200(css`
    top: 18%;
    left: -40%;
  `)}
`;

const Benefit2 = styled(Benefit)`
  ${media.w.min.px768(css`
    flex-direction: row-reverse;
    text-align: right;
    top: 54%;
    left: -21%;
  `)}

  ${media.w.min.px1200(css`
    top: 55%;
    left: -39%;
  `)}
`;

const Benefit3 = styled(Benefit)`
  ${media.w.min.px768(css`
    display: block;
    text-align: center;
    top: 80%;
    left: 12%;

    ${media.w.min.px1200(css`
      top: 81%;
      left: 4%;
    `)}
    ${Icon} {
      margin-bottom: 1rem;
    }
  `)}
`;

const Benefit4 = styled(Benefit)`
  ${media.w.min.px768(css`
    display: block;
    text-align: center;
    top: 80%;
    right: 11%;

    ${media.w.min.px1200(css`
      top: 81%;
      right: 3%;
    `)}
    ${Icon} {
      margin-bottom: 1rem;
    }
  `)}
`;

const Benefit5 = styled(Benefit)`
  ${media.w.min.px768(css`
    top: 50%;
    right: -23%;
  `)}

  ${media.w.min.px1200(css`
    top: 54%;
    right: -39%;
  `)}
`;

const Benefit6 = styled(Benefit)`
  ${media.w.min.px768(css`
    top: 18%;
    right: -24%;
  `)}

  ${media.w.min.px1200(css`
    top: 18%;
    right: -40%;
  `)}
`;

const BenefitName = styled.div`
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${colors.lightGray};
  align-self: center;

  ${media.w.max.px768(css`
    font-size: 0.9375rem;
    margin-bottom: 0.3125rem;
  `)}

  ${media.w.between.px768.px992(css`
    font-size: 1rem;
  `)}
`;

const Note = styled.div`
  text-align: center;
  color: ${colors.lightGray};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.825rem;

  ${media.w.max.px992(css`
    text-transform: uppercase;
  `)}

  ${media.w.min.px768(css`
    margin-top: 7.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
  `)}
`;

export const MobilityCardSection: FC = () => {
  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px768,
  });

  return (
    <Container>
      <Content>
        <HeadingWrapper>
          <StyledSectionHeading>
            Jedna karta <br />
            <span>wiele możliwości</span>
          </StyledSectionHeading>
          <Subtitle>
            W ramach programu MOBILITY 3.0 Twoi pracownicy otrzymują
            comiesięczny bonus*, który mogą wykorzystać na:
          </Subtitle>
        </HeadingWrapper>
        <MobilityCardWrapper>
          <MobilityCard>
            <Image src={mobilityCard} alt="karta mobility" />
          </MobilityCard>
          {isDesktop && <StyledBlueRing />}
          <CardBenefits>
            <Benefit1>
              <Icon>
                <Fuel />
              </Icon>
              <BenefitName>zakup paliwa</BenefitName>
            </Benefit1>
            <Benefit2>
              <Icon>
                <CarWash />
              </Icon>
              <BenefitName>myjnia</BenefitName>
            </Benefit2>
            <Benefit3>
              <Icon>
                <Bus />
              </Icon>
              <BenefitName>transport publiczny</BenefitName>
            </Benefit3>
            <Benefit4>
              <Icon>
                <Speedometer />
              </Icon>
              <BenefitName>opłaty drogowe i parkingowe</BenefitName>
            </Benefit4>
            <Benefit5>
              <Icon>
                <Bicycle />
              </Icon>
              <BenefitName>
                {isDesktop
                  ? 'wynajem aut, hulajnóg i rowerów miejskich'
                  : 'wynajem aut, hulajnóg i rowerów'}
              </BenefitName>
            </Benefit5>
            <Benefit6>
              <Icon>
                <Sprinkler />
              </Icon>
              <BenefitName>akcesoria samochodowe</BenefitName>
            </Benefit6>
          </CardBenefits>
        </MobilityCardWrapper>
        <Note>*Wysokość bonusu ustalana jest indywidualnie z partnerem.</Note>
      </Content>
    </Container>
  );
};
