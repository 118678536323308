import React, { FC, useMemo } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GalleryModalThumbnailSliderItem } from 'src/app/product/components/GalleryModal/GalleryModalThumbnailSliderItem';
import { GalleryModalContainer } from 'src/app/product/components/GalleryModal/GalleryModalContainer';
import styled from 'styled-components';
import { GalleryItem } from 'src/app/product/components/GalleryModal/galleryItem';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { deceleratedEasing, fadeIn } from 'src/common/styles/animations';

const StyledGalleryModalContainer = styled(GalleryModalContainer)`
  padding: 2rem 0;
`;

const Slider = styled(Swiper)`
  opacity: 0;
  animation: ${fadeIn(0, 1)} 250ms 300ms ${deceleratedEasing} forwards;

  .swiper-slide {
    width: auto;
    height: 5.75rem;
  }
`;

export interface GalleryModalThumbnailSliderProps {
  items: GalleryItem[];
  initialSlide?: number;
  setThumbsInstance: (instance: SwiperCore) => void;
  sliderInstance?: SwiperCore;
  thumbsInstance?: SwiperCore;
  onThumbClick: (i: number) => void;
}

export const GalleryModalThumbnailSlider: FC<GalleryModalThumbnailSliderProps> = ({
  items,
  initialSlide,
  setThumbsInstance,
  thumbsInstance,
  onThumbClick,
}) => {
  const swiperOptions = useMemo<SwiperOptions>(
    () => ({
      slidesPerView: 'auto',
      spaceBetween: 18,
      onSwiper: setThumbsInstance,
      initialSlide,
      mousewheel: true,
      centerInsufficientSlides: true,
    }),
    [setThumbsInstance]
  );

  const slides = useMemo(
    () =>
      items.map((item, index) => (
        <SwiperSlide key={index}>
          <GalleryModalThumbnailSliderItem
            item={item}
            onClick={() => onThumbClick(index)}
          />
        </SwiperSlide>
      )),
    [items, onThumbClick]
  );

  return (
    <StyledGalleryModalContainer>
      {slides?.length > 0 && <Slider {...swiperOptions}>{slides}</Slider>}
    </StyledGalleryModalContainer>
  );
};
