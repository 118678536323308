import styled from 'styled-components';
import { ellipsis } from 'polished';
import { typography } from 'src/config/typography';

export const FilterStatusItem = styled.span`
  flex-shrink: 0;
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.75rem;
  display: inline !important;
  line-height: 1.5;
  ${ellipsis('100%')};
`;
