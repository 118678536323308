export const colors = {
  primary: '#007FFF',
  background: '#2A2B32',
  darkText: '#252E3A',
  gray1: '#333333',
  lightGray: '#e0e3e8',
  darkGray: '#3D3E44',
  white: '#FFFFFF',
  black: '#000000',
  stormGray: '#6E6F76',
  manatee: '#8E8E93',
  lightManatee: '#94969C',
  shark: '#202229',
  lightShark: '#2A2B2F',
  red: '#EC2B39',
  tuna: '#3B3C41',
  woodsmoke: '#0B0B0E',
  abbey: '#48494F',
  grayBasic: '#93969C',
  darkBlue: '#223B5B',
  yellow: '#FFE500',
} as const;
