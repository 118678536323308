import { injectReducer, injectSaga } from 'src/state';
import { AppState } from 'src/app/state';
import {
  productsReducer,
  ProductsState,
} from 'src/app/product/state/products/productsReducer';
import { productSaga } from 'src/app/product/state/products/productsSagas';
import {
  SearchFiltersState,
  searchFiltersReducer,
} from 'src/app/search/state/searchFilters/searchFiltersReducer';
import { searchFiltersSaga } from 'src/app/search/state/searchFilters/searchFiltersSagas';

export const injectProductPageState = () => {
  injectReducer('products', productsReducer);
  injectSaga('products', productSaga);

  injectReducer('searchFilters', searchFiltersReducer);
  injectSaga('searchFilters', searchFiltersSaga);
};

export interface ProductPageState extends AppState {
  products: ProductsState;
  searchFilters: SearchFiltersState;
}
