import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Title } from 'src/common/components/typography/Title';
import { colors } from 'src/config/colors';
import { rgba } from 'polished';
import { skewRight } from 'src/common/styles/skew';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { media } from 'src/config/breakpoints';
import { StyleProps } from '@summer/react-kit';

export interface SearchPageSecondaryResultsSectionProps {
  offerKindTitle?: ReactNode;
  headerIconography?: ReactNode;
  loader?: ReactNode;
}

const Container = styled.div`
  position: relative;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;

  background: linear-gradient(315deg, #2a2b32 30%, #3d3e44 100%) no-repeat;
  background-size: 100% 80%;

  padding-top: 1.1875rem;
`;

const Content = styled.div`
  margin-top: 4.4375rem;
  min-height: 27.5rem;
`;

const Header = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 1.375rem;

  ${media.w.min.px1200(css`
    margin-bottom: 0;
  `)}
`;

const Heading = styled.div`
  ${media.w.min.px1200(css`
    padding-top: 2rem;
    margin-bottom: -2rem;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 2rem;
      z-index: -1;
      width: 50%;
      height: 4.8125rem;
      ${skewRight('2.5rem')};

      background-color: ${colors.background};
    }
  `)}
`;

const StyledTitle = styled(Title)`
  color: ${rgba(colors.white, 0.5)};
  text-transform: uppercase;
  margin: 0;
`;
const TitleAccent = styled.span`
  color: ${colors.white};
  white-space: nowrap;
`;

const IconographyContainer = styled.div`
  ${media.w.min.px1200(css`
    width: 50%;
    margin-left: auto;
    margin-top: -3.2rem;
  `)}
`;

export const SearchPageSecondaryResultsSectionDecorator: FC<
  StyleProps & SearchPageSecondaryResultsSectionProps
> = ({ style, className, offerKindTitle, headerIconography, children }) => {
  return (
    <Container style={style} className={className}>
      {/* <Header>
        <ConstraintContainer>
          <Heading>
            <StyledTitle level={1} variant="condensed">
              Sprawdź oferty <TitleAccent>{offerKindTitle}</TitleAccent>
            </StyledTitle>
          </Heading>
        </ConstraintContainer>
      </Header> */}

      <ContentContainer>
        <ConstraintContainer>
          <IconographyContainer>{headerIconography}</IconographyContainer>
        </ConstraintContainer>
        <Content>{children}</Content>
      </ContentContainer>
    </Container>
  );
};
