import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { media } from 'src/config/breakpoints';

export const PagesInfographicText = styled.div`
  color: ${colors.lightGray};
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin-top: 0.75rem;
  font-family: ${typography.fontFamilyCondensed};
  font-weight: 400;

  ${media.w.min.px768(css`
    line-height: 1.5rem;
    font-size: 1.125rem;
  `)}
`;
