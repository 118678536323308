import { all, call, put, takeEvery } from 'redux-saga/effects';
import { commonFiltersActions } from 'src/app/state/commonFilters/commonFiltersActions';
import { fetchCommonFilters } from 'src/app/search/services/filters';
import { authActions } from 'src/state/auth/authActions';

function* processCommonFilters() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchCommonFilters);
    yield put(commonFiltersActions.requestSuccess(response));
  } catch (error) {
    yield put(commonFiltersActions.requestFailure(error));
  }
}

function* fetchCommonFiltersWatcher() {
  yield takeEvery(
    [commonFiltersActions.request.type, authActions.logout],
    processCommonFilters
  );
}

export function* commonFiltersSaga() {
  yield all([fetchCommonFiltersWatcher()]);
}
