import styled from 'styled-components';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import React, { forwardRef, PropsWithChildren } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { StyleProps } from '@summer/react-kit';

const ScrollContainerWrapper = styled.div`
  flex: 1 0 1px;
  height: 1px;
  overflow: hidden;
`;

const ScrollContent = styled.div`
  padding-right: 2rem;
`;

export const FlexScrollContainer = forwardRef<
  OverlayScrollbarsComponent,
  PropsWithChildren<StyleProps>
>(({ children, ...styleProps }, ref) => (
  <ScrollContainerWrapper>
    <ScrollContainer
      style={{ height: '100%' }}
      options={{ paddingAbsolute: true }}
      ref={ref}
    >
      <ScrollContent {...styleProps}>{children}</ScrollContent>
    </ScrollContainer>
  </ScrollContainerWrapper>
));
