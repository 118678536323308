import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { injectProductPageState } from 'src/app/product/state';
import { useDispatch, useSelector } from 'react-redux';
import { productsActions } from 'src/app/product/state/products/productsActions';
import { activeProductSelector } from 'src/app/product/state/products/productsSelectors';
import { ProductPage } from 'src/app/product/ProductPage';
import { FullSizeContainer, reduceRoute, setParam } from '@summer/react-kit';
import {
  ProductItemPageRoute,
  ProductOrderItemPageRoute,
  ProductOrderPageRoute,
  ProductOrderThankYouPageRoute,
  SearchPageRoute,
} from 'src/app/routes';
import { Loader } from 'src/common/Loader';
import { OrderPage } from 'src/app/product/OrderPage';
import { isNone, isSome } from 'fp-ts/lib/Option';
import { navigate } from 'gatsby';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { OrderThankYouPage } from 'src/app/product/OrderThankYouPage';

export type ProductRouterProps = RouteComponentProps<{
  productId: string;
}>;

injectProductPageState();

const ProductRouter: FC<ProductRouterProps> = ({ productId }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if (productId != null) {
      dispatch(productsActions.setActiveProductId(productId));
    }
  }, [productId, isLoggedIn]);

  const activeProduct = useSelector(activeProductSelector);

  useEffect(() => {
    return () => {
      dispatch(productsActions.clear());
    };
  }, []);

  const reduce = reduceRoute(ProductItemPageRoute);

  useEffect(() => {
    if (activeProduct != null && isNone(activeProduct)) {
      navigate(SearchPageRoute);
    }
  }, [activeProduct]);

  return (
    <>
      {activeProduct == null && (
        <FullSizeContainer>
          <Loader />
        </FullSizeContainer>
      )}

      {activeProduct != null && isSome(activeProduct) && (
        <Router style={{ height: '100%', overflow: 'hidden' }}>
          <ProductPage
            path={reduce(ProductItemPageRoute)}
            product={activeProduct.value}
          />

          <OrderPage path={reduce(ProductOrderItemPageRoute)} />
          <OrderThankYouPage path={reduce(ProductOrderThankYouPageRoute)} />

          <Redirect
            noThrow
            from={reduce(ProductOrderPageRoute)}
            to={setParam(':productId')(ProductItemPageRoute)(
              activeProduct.value.id
            )}
          />
        </Router>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductRouter;
