import { createReducer } from '@reduxjs/toolkit';
import { FilterGroupPanel } from 'src/app/search/models/filterGroupPanel';
import { searchPanelActions } from 'src/app/search/state/searchPanel/searchPanelActions';

export interface SearchPanelState {
  activePanel?: FilterGroupPanel;
}

const initialState: SearchPanelState = {};

export const searchPanelReducer = createReducer<SearchPanelState>(
  initialState,
  (builder) =>
    builder
      .addCase(searchPanelActions.openCrossroad, (state) => {
        state.activePanel = FilterGroupPanel.Crossroad;
      })
      .addCase(searchPanelActions.openGeneral, (state) => {
        state.activePanel = FilterGroupPanel.General;
      })
      .addCase(searchPanelActions.openPerformance, (state) => {
        state.activePanel = FilterGroupPanel.Performance;
      })
      .addCase(searchPanelActions.openAppearance, (state) => {
        state.activePanel = FilterGroupPanel.Appearance;
      })
      .addCase(searchPanelActions.openInterior, (state) => {
        state.activePanel = FilterGroupPanel.Interior;
      })
      .addCase(searchPanelActions.openEquipment, (state) => {
        state.activePanel = FilterGroupPanel.Equipment;
      })
      .addCase(searchPanelActions.close, (state) => {
        state.activePanel = undefined;
      })
);
