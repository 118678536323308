import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M62.3384 41.3618L64.0004 40.0114L50.0813 22.8803L50.0809 22.8806C49.9797 22.7556 49.8516 22.6551 49.7061 22.5865C49.5607 22.5179 49.4016 22.483 49.2408 22.4844C49.08 22.4858 48.9216 22.5234 48.7773 22.5945C48.6331 22.6656 48.5067 22.7683 48.4076 22.895L17.3574 62.5108L19.0428 63.8318L45.8099 29.6807L41.8236 40.3107C41.7299 40.5606 41.7329 40.8365 41.832 41.0843L43.7499 45.8791L37.6868 53.9633C37.5789 54.1071 37.509 54.2758 37.4835 54.4538C37.458 54.6318 37.4777 54.8133 37.5408 54.9816L40.7529 63.5472L42.758 62.7953L39.7518 54.7789L45.8241 46.6825C45.9343 46.5355 46.0049 46.3626 46.029 46.1805C46.0531 45.9983 46.0299 45.813 45.9616 45.6425L43.9743 40.6741L49.5971 25.6802L62.3384 41.3618Z" />
    <path d="M20.6569 27.5238L22.171 26.0097L10.3934 14.232L10.3916 14.2338C10.2928 14.1336 10.1752 14.054 10.0454 13.9996C9.91568 13.9452 9.77643 13.9172 9.63575 13.917C9.49508 13.9168 9.35577 13.9446 9.22589 13.9987C9.09603 14.0527 8.97818 14.132 8.87919 14.232L0.313615 22.7976L1.82778 24.3117L8.56558 17.5739V23.1112L4.5964 27.0804C4.43749 27.2393 4.33261 27.4441 4.29661 27.6659C4.26062 27.8878 4.29533 28.1153 4.39584 28.3163L5.76245 31.0495H0V33.1909H7.49488C7.67739 33.1909 7.85687 33.1443 8.01627 33.0554C8.17568 32.9665 8.30973 32.8384 8.40567 32.6832C8.50162 32.5279 8.55629 32.3507 8.56449 32.1684C8.57269 31.9861 8.53415 31.8047 8.45252 31.6414L6.65624 28.0489L10.3934 24.3117C10.4928 24.2123 10.5717 24.0943 10.6255 23.9644C10.6793 23.8345 10.707 23.6953 10.707 23.5547V17.5739L20.6569 27.5238Z" />
    <path d="M24.6281 42.8273V39.6152H27.8401C28.0789 39.6152 28.3108 39.5354 28.4991 39.3884C28.6873 39.2415 28.821 39.0358 28.8789 38.8042L29.9496 34.5214C29.9832 34.387 29.9904 34.2474 29.9708 34.1103L29.3543 29.7952L34.5779 35.0188L36.0921 33.5046L28.5972 26.0098L28.5957 26.0113C28.4968 25.9113 28.379 25.8319 28.2493 25.7777C28.1195 25.7235 27.9803 25.6955 27.8397 25.6953C27.6991 25.6952 27.5598 25.7229 27.4299 25.7768C27.3001 25.8308 27.1822 25.91 27.0831 26.0098L11.0226 42.0702C10.8729 42.2199 10.7709 42.4107 10.7296 42.6184C10.6882 42.8261 10.7094 43.0414 10.7905 43.237C10.8715 43.4327 11.0088 43.5999 11.1848 43.7176C11.3609 43.8352 11.5679 43.898 11.7797 43.898H23.5574C23.698 43.898 23.8372 43.8703 23.9671 43.8165C24.097 43.7627 24.215 43.6838 24.3145 43.5844C24.4139 43.485 24.4928 43.3669 24.5466 43.237C24.6004 43.1071 24.6281 42.9679 24.6281 42.8273ZM22.4867 38.5445V41.7566H14.3646L27.0831 29.0381L27.8213 34.2055L27.0042 37.4738H23.5574C23.4168 37.4738 23.2775 37.5015 23.1476 37.5553C23.0177 37.6091 22.8997 37.688 22.8003 37.7874C22.7008 37.8868 22.622 38.0049 22.5682 38.1348C22.5144 38.2647 22.4867 38.4039 22.4867 38.5445Z" />
    <path d="M36.9375 4.19082L35.4445 13.1492L30.6504 15.0668C30.493 15.1298 30.3529 15.2292 30.2414 15.3569C30.1299 15.4845 30.0504 15.6368 30.0093 15.8013L29.1089 19.403L23.3771 20.3583L23.7292 22.4706L30.1534 21.3999C30.3591 21.3656 30.5504 21.2719 30.7036 21.1305C30.8569 20.989 30.9655 20.8058 31.0161 20.6034L31.954 16.8518L36.7992 14.9137C36.97 14.8454 37.1203 14.7342 37.2356 14.5909C37.3509 14.4476 37.4274 14.277 37.4576 14.0956L39.257 3.29944L62.4119 26.4543L63.926 24.9401L39.3071 0.321175L39.3061 0.320228L39.3 0.314117L39.2996 0.314542C39.2003 0.214884 39.0824 0.135799 38.9525 0.0818219C38.8227 0.0278451 38.6834 3.95838e-05 38.5428 4.22324e-08C38.4022 -3.94993e-05 38.2629 0.0276879 38.133 0.0815916C38.0031 0.135495 37.8851 0.214515 37.7858 0.314117L19.584 18.516L21.0981 20.0301L36.9375 4.19082Z" />
  </IconBox>
);
