import React, { FC } from 'react';
import { useRequest } from 'src/lib/useRequest';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import styled, { css } from 'styled-components';
import { typography } from 'src/config/typography';
import { Loader } from 'src/common/Loader';
import { findUsedProductsRequestActions } from 'src/app/search/state/searchProducts/searchUsedProductsActions';

const Container = styled.div<{ $showBackdrop?: boolean }>`
  position: absolute;
  left: -99999px;
  top: 0;
  right: -99999px;
  bottom: 0;
  ${({ $showBackdrop }) =>
    $showBackdrop &&
    css`
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 20%,
        rgba(0, 0, 0, 0.75) 50%,
        rgba(0, 0, 0, 0.5) 80%,
        rgba(0, 0, 0, 0) 100%
      );
      backdrop-filter: blur(2px);
    `}
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${typography.fontFamilyCondensed};
  z-index: 20;
`;

export const LoadingIndicatorLimiter = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const LoadingIndicator: FC<{
  isLoading: boolean;
  showBackdrop?: boolean;
}> = ({ isLoading, showBackdrop }) => {
  if (!isLoading) {
    return <></>;
  }

  return (
    <Container $showBackdrop={showBackdrop}>
      <Loader />
    </Container>
  );
};

export const ProductsLoadingIndicator: FC = () => {
  const { isLoading, status } = useRequest(findProductsRequestActions.request);
  return (
    <LoadingIndicator
      isLoading={isLoading}
      showBackdrop={status != undefined}
    />
  );
};

export const UsedProductOffersLoadingIndicator: FC = () => {
  const { isLoading, status } = useRequest(
    findUsedProductsRequestActions.request
  );
  return (
    <LoadingIndicator
      isLoading={isLoading}
      showBackdrop={status != undefined}
    />
  );
};
