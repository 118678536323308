import React, { FC } from 'react';
import { EquipmentItem } from 'src/app/common/models/product';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';

const Code = styled.div<{ noBackground?: boolean }>`
  border-radius: 100%;
  flex: 0 0 auto;
  height: 1.8125rem;
  line-height: 1.8125rem;
  margin-right: 1.125rem;
  text-align: center;
  width: 1.8125rem;

  ${(props) =>
    !props.noBackground &&
    css`
      background-image: linear-gradient(
        336deg,
        ${colors.tuna} 0%,
        ${colors.background} 78%
      );
    `}
`;

const CodeInner = styled.div`
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.8125rem;
  font-weight: 700;
`;

export interface EquipmentItemCodeProps {
  item: EquipmentItem;
}

export const EquipmentItemCode: FC<EquipmentItemCodeProps> = ({ item }) => {
  return (
    <Code noBackground={!item.code}>
      <CodeInner>{item.code}</CodeInner>
    </Code>
  );
};
