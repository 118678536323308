import React, { FC } from 'react';
import { Product } from 'src/app/common/models/product';
import { Text } from 'src/common/components/typography/Text';
import { LeasingCalculator } from 'src/app/product/components/ProductPageSummary/LeasingCalculator';
import { useSelector } from 'react-redux';
import { activeOfferSelector } from 'src/app/product/state/products/productsSelectors';
import { InternalCalculator } from 'src/app/product/components/ProductPageSummary/InternalCalculator';
import { useCalculatorInitialOffer } from 'src/app/product/hooks/useCalculatorInitialOffer';
import { useGetArtefaktaLeasingOffers } from 'src/app/product/hooks/useGetArtefaktaLeasingOffers';
import { useFindOfferOnCustomerTypeChange } from 'src/app/product/hooks/useFindOfferOnCustomerTypeChange';
import { useOnFinancingTypeChange } from 'src/app/product/hooks/useOnFinancingTypeChange';

export interface BaseInternalCalculatorProps {
  product: Product;
}

export const BaseInternalCalculator: FC<BaseInternalCalculatorProps> = ({
  product,
}) => {
  const activeOffer = useSelector(activeOfferSelector);

  // Initially get offer from URL
  const initialOffer = useCalculatorInitialOffer();
  // Get artefakta leasing offers if there is an artefakta4internal offer available
  const leasingOffers = useGetArtefaktaLeasingOffers({ initialOffer, product });

  useFindOfferOnCustomerTypeChange({ initialOffer, leasingOffers, product });

  const handleFinancingTypeButtonChange = useOnFinancingTypeChange({
    leasingOffers,
    product,
  });

  const offer = activeOffer || initialOffer;

  if (offer == null) {
    return <Text>Brak ofert</Text>;
  }

  const isLeasing = offer.external?.type === 'artefakta4internal';

  return (
    <>
      {isLeasing ? (
        <LeasingCalculator
          activeOffer={offer}
          leasingOffers={leasingOffers}
          onFinancingTypeChange={handleFinancingTypeButtonChange}
          product={product}
        />
      ) : (
        <InternalCalculator
          activeOffer={offer}
          onFinancingTypeChange={handleFinancingTypeButtonChange}
          product={product}
        />
      )}
    </>
  );
};
