import React, { FC } from 'react';
import { CleanUnorderedList } from 'src/common/components/List';
import styled, { css } from 'styled-components';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { Title } from 'src/common/components/typography/Title';
import { media } from 'src/config/breakpoints';
import { StyleProps } from '@summer/react-kit';
import { Button } from 'src/common/components/Button';
import { ContactDepartment } from 'src/public/models/contactDepartment';
import { contactDepartments } from 'src/public/data/contactDepartments';
import { skewBoth } from 'src/common/styles/skew';
import { colors } from 'src/config/colors';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { typography } from 'src/config/typography';

const Header = styled.header`
  padding: 2.125rem 1.875rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  ${media.w.min.px768(css`
    padding: 6.25rem 1.875rem 2.625rem;
    flex-direction: row;
  `)}
`;

// todo: add hero modifier to font size and unify it !
const StyledTitle = styled(Title)`
  margin: 0;
  font-size: 1.375rem;
  line-height: 2.125rem;
  text-transform: uppercase;
  font-family: ${typography.fontFamilyCondensed};

  ${media.w.min.px576(css`
    font-size: 1.875rem;
  `)};

  & span {
    ${media.w.max.px768(css`
      color: ${colors.primary};
    `)};
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: 1rem 0 0 0;

  ${media.w.min.px768(css`
    margin: 0 0 0 4.375rem;
  `)};
`;

const StyledContactDepartmentList = styled(CleanUnorderedList)`
  display: grid;
  gap: 2px;

  ${media.w.min.px768(css`
    grid-auto-flow: column;
  `)};
`;

const ContactDepartmentTitle = styled(Title).attrs({ level: 4 })`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family: ${typography.fontFamilyCondensed};
  letter-spacing: 1px;

  ${media.w.min.px576(css`
    font-size: 1.125rem;
    margin-top: 1rem;
  `)};
`;

const ContactDepartmentParagraph = styled.div`
  font-size: 0.75rem;
  font-family: ${typography.fontFamilyDefault};

  ${media.w.min.px768(css`
    font-size: 0.875rem;
    min-height: calc(2rem * 1.25);
    margin-top: 1rem;
  `)};
`;

const ContactDepartmentExcerpt = styled(Text).attrs({ as: 'div' })`
  font-size: 0.75rem;

  ${media.w.min.px768(css`
    font-size: 0.875rem;
    min-height: calc(2rem * 1.25);
  `)};
`;

interface ContactDepartmentListItemProps {
  item: ContactDepartment;
  className?: string;
}

const ContactDepartmentListItem: FC<ContactDepartmentListItemProps> = ({
  className,
  item,
}) => (
  <li className={className}>
    <ContactDepartmentTitle>{item.title}</ContactDepartmentTitle>
    <ContactDepartmentExcerpt size="small">
      {item.excerpt}
    </ContactDepartmentExcerpt>

    <ContactDepartmentParagraph>
      <Text as="div" size="small">
        {item.contact.label ? item.contact.label : <>&nbsp;</>}
      </Text>
      <Button
        kind="link"
        variant="transparent"
        as="a"
        href={`mailto:${item.contact.mail}`}
      >
        {item.contact.mail}
      </Button>
      <Text as="div" size="small">
        {item.contact.phone}
      </Text>
    </ContactDepartmentParagraph>
    <ContactDepartmentListItemGradient />
  </li>
);

const StyledContactDepartmentListItem = styled(ContactDepartmentListItem)`
  position: relative;
  z-index: 1;
  padding: 0 1.5rem 1.5rem;
  text-align: center;

  ${media.w.min.px768(css`
    height: 17rem;
    padding: 4.25rem 3vh;
    text-align: left;
  `)};
}
`;

const ContactDepartmentListItemGradient = styled.div`
  height: 100%;
  width: 130%;
  top: 0;
  right: 0;
  z-index: -1;
  position: absolute;
  opacity: 0.3;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.2) 8.04%,
    rgba(255, 255, 255, 0) 72.32%
  );
  ${skewBoth('8vw')};

  ${media.w.max.px768(css`
    display: none;
  `)};

  ${media.w.min.px992(css`
    width: 36.75rem;
    ${skewBoth('7.5rem')};
  `)};
`;

export const ContactSection: FC<StyleProps> = (props) => (
  <ConstraintContainer {...props}>
    <Header>
      <StyledTitle level={2}>
        <span>Skontaktuj się</span> z nami
      </StyledTitle>
      <StyledParagraph>
        Potrzebujesz wsparcia? Zadzwoń lub napisz do naszych doradców
      </StyledParagraph>
    </Header>
    <StyledContactDepartmentList>
      {contactDepartments.map((item, index) => (
        <StyledContactDepartmentListItem key={index} item={item} />
      ))}
    </StyledContactDepartmentList>
  </ConstraintContainer>
);
