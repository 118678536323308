import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="10 0 44 64" {...props}>
    <path d="M10.0478 24.6646H6.93751C6.41975 24.6646 6 25.0843 6 25.6021C6 26.1198 6.41975 26.5396 6.93751 26.5396H10.0478C10.5655 26.5396 10.9853 26.1198 10.9853 25.6021C10.9853 25.0843 10.5655 24.6646 10.0478 24.6646Z" />
    <path d="M56.2656 24.6646H53.1553C52.6374 24.6646 52.2178 25.0843 52.2178 25.6021C52.2178 26.1198 52.6374 26.5396 53.1553 26.5396H56.2656C56.7834 26.5396 57.2031 26.1198 57.2031 25.6021C57.2031 25.0843 56.7833 24.6646 56.2656 24.6646Z" />
    <path d="M17.0253 40.1789C16.6593 39.8128 16.0657 39.8128 15.6995 40.1789L13.5002 42.3782C13.1341 42.7443 13.1341 43.3379 13.5002 43.7041C13.8663 44.0702 14.4599 44.0702 14.8261 43.7041L17.0253 41.5048C17.3915 41.1387 17.3915 40.545 17.0253 40.1789Z" />
    <path d="M49.7052 7.49774C49.3389 7.13161 48.7454 7.13161 48.3791 7.49774L46.1799 9.69701C45.8137 10.0631 45.8137 10.6568 46.1799 11.0229C46.5461 11.389 47.1396 11.3889 47.5059 11.0229L49.7052 8.82363C50.0713 8.45738 50.0713 7.86374 49.7052 7.49774Z" />
    <path d="M31.5996 0C31.0817 0 30.6621 0.419754 30.6621 0.937509V4.04779C30.6621 4.56554 31.0817 4.9853 31.5996 4.9853C32.1175 4.9853 32.5371 4.56554 32.5371 4.04779V0.937509C32.5371 0.419754 32.1175 0 31.5996 0Z" />
    <path d="M49.7052 42.3782L47.5059 40.1789C47.1396 39.8128 46.5461 39.8128 46.1799 40.1789C45.8137 40.545 45.8137 41.1388 46.1799 41.5048L48.3791 43.7041C48.7454 44.0702 49.3389 44.0702 49.7052 43.7041C50.0713 43.3379 50.0713 42.7443 49.7052 42.3782Z" />
    <path d="M17.0253 9.69701L14.8261 7.49774C14.4599 7.13161 13.8663 7.13161 13.5002 7.49774C13.1341 7.86387 13.1341 8.4575 13.5002 8.82363L15.6995 11.0229C16.0656 11.389 16.6592 11.389 17.0253 11.0229C17.3915 10.6566 17.3915 10.063 17.0253 9.69701Z" />
    <path d="M50.3035 23.3052C49.2946 14.6957 42.4699 7.87043 33.9008 6.82592C33.3877 6.7628 32.9193 7.12917 32.8566 7.64305C32.794 8.15706 33.1598 8.62456 33.6738 8.68731C41.456 9.63582 47.5286 15.7368 48.441 23.5235C48.4969 24.0006 48.902 24.3518 49.3709 24.3518C49.4074 24.3518 49.4441 24.3497 49.4812 24.3455C49.9958 24.2853 50.3638 23.8196 50.3035 23.3052Z" />
    <path d="M44.395 24.8358C43.6554 18.5504 38.6522 13.5675 32.3703 12.805C31.9941 12.7589 31.6507 13.0264 31.6047 13.4015C31.5588 13.7768 31.827 14.1181 32.2039 14.1639C37.909 14.8564 42.3607 19.3104 43.0296 24.9952C43.0706 25.3435 43.3676 25.5999 43.7113 25.5999C43.7381 25.5999 43.765 25.5984 43.7922 25.5953C44.1694 25.5514 44.4392 25.2114 44.395 24.8358Z" />
    <path d="M49.4723 26.858C48.9578 26.7925 48.4894 27.1567 48.4244 27.6704C47.7853 32.719 44.9358 37.1736 40.6065 39.8918C38.7401 41.0637 37.6259 43.0692 37.6259 45.2565V49.1149H25.578V45.1711C25.578 43.0531 24.4711 41.0521 22.6894 39.9492C17.6511 36.8304 14.6431 31.4357 14.643 25.5183C14.643 16.9742 21.0428 9.73837 29.5295 8.68711C30.0434 8.62349 30.4082 8.15523 30.3446 7.64135C30.281 7.1276 29.813 6.76172 29.2989 6.82622C19.9219 7.98786 12.7675 16.0517 12.7676 25.5183C12.7676 28.7849 13.6172 32.0035 15.2249 34.826C16.7832 37.5623 19.0231 39.885 21.7022 41.5434C22.9361 42.3072 23.7024 43.6972 23.7024 45.171V49.2514C22.7492 49.5826 22.0618 50.4879 22.0618 51.5525V52.889C22.0618 53.4549 22.2573 53.9751 22.5819 54.3892C22.2572 54.8033 22.0618 55.3233 22.0618 55.8893V57.2257C22.0618 58.5699 23.1554 59.6634 24.4994 59.6634H27.447V61.7828C27.447 63.0054 28.4416 64 29.6641 64H33.5388C34.7614 64 35.7559 63.0054 35.7559 61.7828V59.6634H38.7035C40.0476 59.6634 41.1411 58.5697 41.1411 57.2257V55.8893C41.1411 55.3234 40.9456 54.8033 40.6209 54.3892C40.9456 53.9751 41.1411 53.4551 41.1411 52.889V51.5525C41.1411 50.4878 40.4538 49.5826 39.5005 49.2514V45.2563C39.5005 43.7182 40.2863 42.3064 41.603 41.4798C46.4098 38.4618 49.5741 33.5142 50.2839 27.9059C50.3496 27.3922 49.9859 26.923 49.4723 26.858ZM33.8812 61.7826C33.8812 61.968 33.7245 62.1248 33.5392 62.1248H29.6645C29.4791 62.1248 29.3225 61.9681 29.3225 61.7826V59.6632H33.8812V61.7826ZM39.2665 57.2256C39.2665 57.5305 39.0088 57.7881 38.704 57.7881C37.0489 57.7881 26.0616 57.7881 24.4998 57.7881C24.1949 57.7881 23.9373 57.5305 23.9373 57.2256V55.8892C23.9373 55.5843 24.1949 55.3267 24.4998 55.3267H38.704C39.0088 55.3267 39.2665 55.5843 39.2665 55.8892V57.2256ZM39.2665 52.8889C39.2665 53.1938 39.0088 53.4514 38.704 53.4514H24.4998C24.1949 53.4514 23.9373 53.1938 23.9373 52.8889V51.5524C23.9373 51.2475 24.1949 50.9899 24.4998 50.9899H38.704C39.0088 50.9899 39.2665 51.2475 39.2665 51.5524V52.8889Z" />
  </IconBox>
);
