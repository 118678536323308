import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox viewBox="11 0 42 64" {...props}>
    <path
      opacity="0.5"
      d="M17.2383 0.000349839H13.4972C12.6963 0.000349839 12.0508 0.649225 12.0508 1.44679V14.0118C12.0508 14.8127 12.6963 15.4582 13.4972 15.4582H17.2383C18.0358 15.4582 18.6847 14.8127 18.6847 14.0118V1.44679C18.6847 0.649225 18.0358 0.000349839 17.2383 0.000349839Z"
    />
    <path
      opacity="0.5"
      d="M50.8341 0.000349839H47.0929C46.2954 0.000349839 45.6465 0.649225 45.6465 1.44679V14.0118C45.6465 14.8127 46.2954 15.4582 47.0929 15.4582H50.8341C51.635 15.4582 52.2805 14.8127 52.2805 14.0118L52.2839 1.44679C52.2805 0.649225 51.635 0.000349839 50.8341 0.000349839Z"
    />
    <path d="M17.1876 48.5391H13.4464C12.6455 48.5391 12 49.188 12 49.9856V62.5539C12 63.3515 12.6455 64.0004 13.4464 64.0004H17.1876C17.9851 64.0004 18.634 63.3515 18.634 62.5539V49.9856C18.634 49.188 17.9851 48.5391 17.1876 48.5391Z" />
    <path d="M50.7793 48.5391H47.0382C46.2407 48.5391 45.5918 49.188 45.5918 49.9856V62.5539C45.5918 63.3515 46.2407 64.0004 47.0382 64.0004H50.7793C51.5802 64.0004 52.2291 63.3515 52.2291 62.5539V49.9856C52.2291 49.188 51.5802 48.5391 50.7793 48.5391Z" />
    <path
      opacity="0.5"
      d="M38.2578 7.77644V8.29352H45.1351V6.67134H38.2578V7.77644Z"
    />
    <path opacity="0.5" d="M25.9712 8.29352V6.67134H19.1953V8.29352H25.9712Z" />
    <path d="M40.7477 55.1653C38.3854 55.1653 36.0536 54.5333 34.0732 53.2458C33.7454 53.0328 33.3838 52.874 32.9985 52.7794V37.3249V32.8031V32.3942C32.9985 28.8187 33.5156 25.2702 34.4517 21.8197C34.9147 20.1097 35.1952 18.2138 35.1952 15.7839V15.081H34.7559V14.7464H35.736L36.2665 14.2192V14.0232L36.4964 13.7934H37.5203L37.7501 13.5602V11.7928L37.5203 11.5629H36.4964L36.2665 11.3298V11.1304L36.4964 10.9006H37.5203L37.7501 10.6674V8.89989L37.5203 8.67011H36.4964L36.2665 8.44028V8.23752L36.4964 8.0077H37.5203L37.7501 7.77453V6.01044L37.5203 5.77723H36.4964L36.2665 5.54745V5.34803L36.4964 5.11486H37.5203L37.7501 4.88504V3.11757L37.5203 2.8844H36.6044C36.2226 2.8844 35.8576 2.72216 35.6041 2.4349L35.4656 2.27945C35.0465 1.80633 34.4483 1.53936 33.8197 1.53936H32.1131H30.4099C29.7813 1.53936 29.1831 1.80633 28.764 2.27945L28.6255 2.4349C28.372 2.72216 28.007 2.8844 27.6252 2.8844H26.7092L26.4795 3.11757V4.88504L26.7092 5.11486H27.7332L27.9665 5.34803V5.54745L27.7332 5.77723H26.7092L26.4795 6.01044V7.77453L26.7092 8.0077H27.7332L27.9665 8.23752V8.44028L27.7332 8.67011H26.7092L26.4795 8.89989V10.6674L26.7092 10.9006H27.7332L27.9665 11.1304V11.3298L27.7332 11.5629H26.7092L26.4795 11.7928V13.5602L26.7092 13.7934H27.7332L27.9665 14.0232V14.2192L28.4936 14.7464H29.4737V15.081H29.0344V15.7839C29.0344 18.2138 29.3149 20.1097 29.7779 21.8197C30.714 25.2702 31.2311 28.8187 31.2311 32.3942V32.6578V37.3249V38.0785V52.7794C30.8458 52.874 30.4842 53.0328 30.1564 53.2458C28.176 54.5333 25.8442 55.1653 23.4819 55.1653H19.1426V57.3721H23.5224C25.905 57.3721 28.2098 58.0987 30.2409 59.3423C30.785 59.6769 31.427 59.8696 32.1131 59.8696C32.8025 59.8696 33.4446 59.6769 33.9887 59.3423C36.0232 58.0987 38.3247 57.3721 40.7072 57.3721H45.087V55.1653H40.7477Z" />
    <path d="M45.087 56.8628L44.2692 55.1663H45.087V56.8628ZM19.1426 56.8594V55.1663H19.9604L19.1426 56.8594ZM32.1131 53.4596C31.3527 53.4596 30.6025 53.4495 29.8657 53.4292C29.9637 53.3718 30.0618 53.311 30.1564 53.2467C30.4842 53.0338 30.8458 52.875 31.2311 52.7804V38.0795V37.3259V32.6588V32.3952C31.2311 29.6476 30.9269 26.9136 30.3591 24.2303C30.937 24.2134 31.525 24.2033 32.1131 24.2033C32.7045 24.2033 33.2926 24.2134 33.8705 24.2303C33.3027 26.9136 32.9985 29.6476 32.9985 32.3952V32.8041V37.3259V52.7804C33.3838 52.875 33.7454 53.0338 34.0732 53.2467C34.1712 53.311 34.2659 53.3718 34.3639 53.4292C33.6271 53.4495 32.8768 53.4596 32.1131 53.4596ZM26.4795 10.2662V8.90087L26.7092 8.67109H27.7332L27.9665 8.44127V8.23851L27.7332 8.00868H26.7092L26.4795 7.77552V6.01142L26.7092 5.77822H27.7332L27.9665 5.54844V5.34901L27.7332 5.11585H26.7092L26.4795 4.88603V3.11855L26.7092 2.88538H27.6252C28.007 2.88538 28.372 2.72314 28.6255 2.43588L28.764 2.28044C29.1831 1.80731 29.7813 1.54035 30.4099 1.54035H32.1131H33.8197C34.4483 1.54035 35.0465 1.80731 35.4656 2.28044L35.6041 2.43588C35.8576 2.72314 36.2226 2.88538 36.6044 2.88538H37.5203L37.7501 3.11855V4.88603L37.5203 5.11585H36.4964L36.2665 5.34901V5.54844L36.4964 5.77822H37.5203L37.7501 6.01142V7.77552L37.5203 8.00868H36.4964L36.2665 8.23851V8.44127L36.4964 8.67109H37.5203L37.7501 8.90087V10.2662C35.9151 10.0736 34.0326 9.97219 32.1131 9.97219C30.1935 9.97219 28.3112 10.0736 26.4795 10.2662Z" />
  </IconBox>
);
