import { styledIs } from '@summer/react-kit/functions';
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { StyleProps } from '@summer/react-kit';
import { CustomerType } from 'src/common/models/customerType';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';
import { skewBoth } from 'src/common/styles/skew';
import { FinancingType } from 'src/common/models/offer';

const Container = styled.div<{ vertical?: boolean }>`
  align-items: center;
  color: inherit;
  display: grid;
  grid-template-areas:
    'price badge'
    'price label';
  justify-content: start;
  grid-row-gap: 0.25rem;
  grid-column-gap: 0.25rem;
  margin-bottom: 0.3125rem;

  ${styledIs('vertical')(css`
    grid-template-areas: 'full' 'price';
    justify-items: flex-end;
  `)}
`;

const Price = styled.div<{ small?: boolean }>`
  font-size: 1.875rem;
  font-weight: 700;
  grid-area: price;
  line-height: 0.75;
  padding-right: 0.125rem;
  white-space: nowrap;
  align-self: flex-end;

  ${media.w.min.px1200(css`
    font-size: 2.5rem;
  `)};

  ${styledIs('small')(css`
    ${media.w.between.px992.px1200(css`
      font-size: 1rem;
    `)};

    ${media.w.min.px1200(css`
      font-size: 1.375rem;
    `)};
  `)};
`;

const DiscountBadge = styled.div`
  align-self: end;
  grid-area: badge;
  text-align: left;

  font-family: ${typography.fontFamilyCondensed};
  font-weight: bold;
  font-size: 0.5rem;
  color: ${colors.white};
  text-transform: uppercase;

  ${media.w.min.px1200(css`
    font-size: 0.625rem;
    padding: 0.25rem 0.75rem;
    ${skewBoth('0.5rem')};
    background: ${colors.primary};
    text-align: center;
  `)};
`;

const PriceLabel = styled.div`
  font-size: 0.625rem;
  font-weight: 700;
  grid-area: label;
  line-height: 1;

  ${media.w.min.px768(css`
    font-size: 0.875rem;
  `)};
`;

export interface ProductPrice extends StyleProps {
  offer: {
    price: number;
    fullPrice?: number;
    customerType?: CustomerType;
    financingType?: FinancingType;
  };
  vertical?: boolean;
  small?: boolean;
  labelFormat?: string;
  discountBadge?: boolean;
}

export const ProductPrice = ({
  offer,
  labelFormat,
  vertical,
  discountBadge,
  small,
  className,
  style,
}: ProductPrice) => {
  const isLeasing = offer?.financingType === FinancingType.Leasing;
  const label = (labelFormat ?? 'CUR TYPE / miesiąc')
    .replace('CUR', 'zł')
    .replace(
      'TYPE',
      offer.customerType === CustomerType.B2B ? 'netto' : 'brutto'
    );

  return (
    <Container vertical={vertical} className={className} style={style}>
      {!isLeasing && (
        <Price small={small}>
          {localFormatNumber(offer.price)}
          {small && ' ' + label}
        </Price>
      )}
      {isLeasing && (
        <Price small={small}>
          {localFormatNumber(offer.price)}
          {small && ' ' + label}
        </Price>
      )}
      {discountBadge && <DiscountBadge>Rata</DiscountBadge>}
      {!small && <PriceLabel>{' ' + label}</PriceLabel>}
    </Container>
  );
};
