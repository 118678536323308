import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';

export const FieldDescription = styled.div`
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-top: -0.1875rem;
  padding: 0 0.875rem 0 0.875rem;
`;
