import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const SearchFiltersGroupContainer = styled.div`
  display: grid;
  align-items: flex-start;
  height: auto;

  ${media.w.min.px768(css`
    height: 100%;
  `)};
`;
