import { FinancingType, Offer } from 'src/common/models/offer';
import { Image } from 'src/common/models/image';
import { Badge } from 'src/app/common/models/badge';
import { ProductTest } from 'src/app/product/models/productTest';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { engineTypeLabelMapper } from 'src/app/product/services/productMappers';
import { purge } from 'src/lib/purge';
import { ProductParam } from 'src/app/product/components/ProductParamsList';

export interface Brand {
  id: string;
  code: string;
  label: string;
}

export interface Model {
  id: string;
  label: string;
  photo: string;
  brand?: Brand;
}

export interface Category {
  id: string;
  code: string;
  label: string;
}

export interface ColorGroup {
  id: string;
  label: string;
  hex: string;
}

export interface PaintColor {
  id: string;
  label: string;
  code: string;
  paintColorGroups: ColorGroup[];
}

export interface UpholsteryColor {
  id: string;
  label: string;
  code: string;
  upholsteryColorGroups: ColorGroup[];
}

export interface EquipmentGroup {
  id: string;
  label: string;
  shortLabel: string;
  standardOptions?: string[];
  options: EquipmentItem[];
}

export interface EquipmentItem {
  code: string;
  id: string;
  label: string;
}

export const availableFromDateTimeFormat = new Intl.DateTimeFormat('pl', {
  month: 'long',
});

export enum Availability {
  Available = 'available',
  Unavailable = 'unavailable',
}

export enum Body {
  Hatchback = 'hatchback',
  Sedan = 'sedan',
  Liftback = 'liftback',
  Combi = 'combi',
  Suv = 'suv',
  Van = 'van',
  Cabrio = 'cabrio',
  Coupe = 'coupe',
  Crossover = 'crossover',
  Motorcycle = 'motorcycle',
}

export enum EngineType {
  Petrol = 'petrol',
  Diesel = 'diesel',
  Hybrid = 'hybrid',
  Electric = 'electric',
}

export enum GearboxType {
  MT = 'MT',
  AT = 'AT',
}

export enum UpholsteryType {
  Material = 'material',
  Leather = 'leather',
  MaterialLeather = 'materialLeather',
  AlcantaraMaterial = 'alcantaraMaterial',
  AlcantaraLeather = 'alcantaraLeather',
}

export enum CabinSpace {
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export enum Drive {
  FWD = 'FWD',
  RWD = 'RWD',
  AWD = 'AWD',
}

export enum TrunkSpace {
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export enum PaintType {
  Metallic = 'metallic',
  Mat = 'mat',
  Uni = 'uni',
}

export enum ProductType {
  NewCar = 'NEW_CAR',
  NewLuxuryCar = 'NEW_LUXURY_CAR',
  NewMoto = 'NEW_MOTO',
}

export const luxuryCarTypes: ProductType[] = [ProductType.NewLuxuryCar];

export interface ResellDetails {
  mileage: number;
  firstRegistrationDate: string;
  conditionAssessment: string;
}

export interface PartialProduct {
  id: string;
  label: string;
  version?: string;

  brand?: Brand;
  model?: Model;

  mainPhoto?: Image;

  badges: Badge[];
  categories: Category[];
  availableFrom?: string;
  benefits: { content: string }[];
  gearbox?: string;

  carHeight?: number;
  carWidth?: number;
  carLength?: number;

  fuelConsumption: number;
  power?: number;
  capacity?: number;
  to100Time?: number;
  productionYear?: number;

  body: Body;
  engineType: EngineType;
  gearboxType: GearboxType;
  cabinSpace: CabinSpace;
  drive?: Drive;

  offer: Offer;

  upholsteryType: UpholsteryType;
  paintType: PaintType;

  listPrice?: number;
  availableFinancingTypes: FinancingType[];

  resellDetails?: ResellDetails;

  vins?: string[];
}

export interface Product extends PartialProduct {
  emission?: number;
  range?: number;
  rimSize?: number;
  trunkSpace?: number;
  maxSpeed?: number;

  batteryCapacity?: number;
  carSpecification?: string;

  equipment: EquipmentGroup[];

  insidePhotos: Image[];
  outsidePhotos: Image[];
  ourTests: ProductTest[];
  backgroundPhoto?: Image;

  upholsteryColor?: UpholsteryColor;
  paintColor?: PaintColor;

  offers: Offer[];
  type: ProductType;

  fullEquipment?: string[];
}

export interface UsedProduct extends Product {
  resellDetails: ResellDetails;
  vins: string[];
}

export const isUsedProduct = (product: Product): product is UsedProduct =>
  (product as UsedProduct).resellDetails != null;

export const getProductBasicParamList = ({
  capacity,
  engineType,
  power,
  to100Time,
}: PartialProduct): ProductParam[] =>
  purge([
    capacity &&
      engineType && {
        value: localFormatNumber((capacity ?? 0) / 1000, {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
          suffix: 'l',
        }),
        label: engineTypeLabelMapper(engineType),
      },
    power && {
      value: localFormatNumber(power),
      label: 'KM',
    },
    to100Time && {
      value: localFormatNumber(to100Time, {
        suffix: 's',
        maximumFractionDigits: 1,
      }),
      label: '(0-100kmh)',
    },
  ]);
