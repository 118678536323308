import React, { memo } from 'react';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import ThumbnailPlay from 'src/assets/icons/ThumbnailPlay';
import { media } from 'src/config/breakpoints';
import { getThumb } from 'src/app/product/components/ProductPageTests/utils';
import {
  ProductTest,
  ProductTestType,
} from 'src/app/product/models/productTest';

const Image = styled.div`
  background-position: center;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.5;
  transition: opacity 0.2s ease;
`;

const Container = styled.div<{ type: ProductTestType }>`
  cursor: pointer;
  display: flex;
  margin-bottom: 0.8125rem;
  margin-right: 0.8125rem;
  position: relative;

  &:hover {
    ${Image} {
      opacity: 1;
    }
  }

  img {
    height: ${(props) =>
      props.type === ProductTestType.Promoted ? '349px' : '168px'};
    max-width: 100%;
    visibility: hidden;

    ${(props) => css`
      ${media.w.min.px992(css`
        height: ${props.type === ProductTestType.Promoted ? '393px' : '190px'};
      `)}
    `}
  }
`;

const Details = styled.div`
  bottom: 1.25rem;
  left: 1.5625rem;
  position: absolute;
  right: 1.5625rem;
`;

const Icon = styled(ThumbnailPlay)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const TestDate = styled.div`
  color: ${colors.white};
  font-size: 0.8125rem;
  line-height: 1rem;

  span:not(:last-child) {
    &::after {
      content: '|';
      margin: 0 0.25rem;
    }
  }
`;

const Text = styled.div`
  color: ${colors.white};
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 1.4375rem;
  margin-bottom: 0.25rem;
`;

export interface ProductPageTestsImageProps {
  productTest: ProductTest;
  onSlideClick: (id: string) => void;
}

export const ProductPageTestsImage = memo<ProductPageTestsImageProps>(
  ({ productTest, onSlideClick }) => {
    const productTestDate = productTest.date
      ? new Date(productTest.date)
      : null;
    const productTestThumb = getThumb(productTest);

    return (
      <Container
        onClick={() => onSlideClick(productTest.id)}
        style={{ flexGrow: productTestThumb.width }}
        type={productTest.type}
      >
        <img alt="" src={productTestThumb.url} />

        <Image style={{ backgroundImage: `url('${productTestThumb.url}')` }} />

        {productTest.ytLink && <Icon size={72} />}

        <Details>
          {productTest.text && <Text>{productTest.text}</Text>}

          {productTestDate && (
            <TestDate>
              <span>{format(productTestDate, 'dd')}</span>
              <span>{format(productTestDate, 'MM')}</span>
              <span>{format(productTestDate, 'yyyy')}</span>
            </TestDate>
          )}
        </Details>
      </Container>
    );
  }
);
