import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M6.53695 25.2479C4.15303 25.4628 2.05249 26.8955 1.00896 29.0311C0.329772 30.4101 0.140858 31.9593 0.469209 33.4412C0.914506 35.4784 2.25939 37.1887 4.13504 38.0975C7.24762 39.6109 10.9584 38.5766 12.8566 35.6664C14.2959 33.4681 14.2959 30.5489 12.8566 28.3506C11.6781 26.5418 9.82945 25.4404 7.65694 25.2479C7.10369 25.1986 7.09919 25.1986 6.53695 25.2479ZM8.47556 25.821C10.6976 26.3358 12.4608 27.9253 13.1714 30.0609C13.5627 31.2205 13.5627 32.7965 13.1714 33.9561C12.5372 35.8589 10.9809 37.4125 9.10078 38.0214C8.3991 38.2498 8.06175 38.299 7.12618 38.299C5.94322 38.299 5.318 38.1602 4.35994 37.6946C3.65826 37.3543 3.23995 37.0544 2.65072 36.4634C1.71514 35.5366 1.17089 34.5471 0.892016 33.2577C0.770572 32.7204 0.770572 31.2966 0.892016 30.7594C1.07193 29.9311 1.40928 29.1431 1.87257 28.4491C2.16494 28.0148 2.84413 27.2984 3.27593 26.9761C4.13054 26.3358 5.15158 25.906 6.2041 25.7448C6.65839 25.6732 8.03477 25.7225 8.47556 25.821Z" />
    <path d="M6.37921 26.0403L6.19029 26.0582L6.16331 26.2911C6.14981 26.4209 6.13182 26.797 6.12732 27.1283L6.11383 27.7327L6.29375 27.7148C6.3927 27.7103 6.48716 27.6924 6.50965 27.679C6.52764 27.6701 6.54113 27.4731 6.54113 27.2492C6.54113 26.5955 6.56812 26.5642 6.76603 27.0029L6.93246 27.3746H7.31928L7.48121 27.0074C7.56667 26.8059 7.64763 26.6492 7.66112 26.6627C7.67462 26.6761 7.69711 26.9089 7.7106 27.1865L7.73309 27.688L7.9265 27.7148C8.03445 27.7283 8.12441 27.7283 8.13341 27.7148C8.1514 27.6745 8.08393 26.0985 8.06594 26.0806C8.05694 26.0717 7.9265 26.0538 7.77357 26.0359L7.4992 26.0045L7.32378 26.4567C7.22932 26.703 7.13936 26.9044 7.12587 26.9044C7.11237 26.9044 7.02241 26.703 6.92346 26.4567C6.77503 26.0896 6.72555 26.009 6.65358 26.018C6.6041 26.0224 6.47816 26.0314 6.37921 26.0403Z" />
    <path d="M10.0993 27.7281C9.87442 28.0818 9.69001 28.3907 9.69001 28.4176C9.69001 28.4445 9.76647 28.525 9.85643 28.6012L10.0229 28.74L10.4007 28.5385C10.6121 28.4266 10.7875 28.3415 10.7965 28.3504C10.8055 28.3594 10.72 28.534 10.6121 28.7444L10.4097 29.1295L10.5716 29.313L10.729 29.4966L11.4127 29.0668C11.7906 28.8295 12.1054 28.6325 12.1099 28.6235C12.1189 28.6191 12.0604 28.5385 11.975 28.4534L11.8265 28.2922L11.4217 28.5564C11.2013 28.7041 11.0124 28.8205 11.0034 28.8116C10.9944 28.8026 11.0709 28.6325 11.1698 28.44L11.3498 28.0818L11.0709 27.8042L10.72 27.9833C10.5266 28.0773 10.3557 28.1579 10.3422 28.1579C10.3287 28.1579 10.4412 27.9743 10.5941 27.7505L10.8685 27.3431L10.7425 27.2132C10.6751 27.1416 10.5941 27.0834 10.5671 27.0834C10.5356 27.0834 10.3287 27.3744 10.0993 27.7281Z" />
    <path d="M3.23555 27.4329C3.09611 27.518 2.52937 28.1045 2.32246 28.3731L2.20551 28.5253L2.79025 29.076C3.1096 29.3805 3.37948 29.6267 3.39297 29.6223C3.40647 29.6178 3.64486 29.3715 3.92373 29.076C4.24309 28.7358 4.44549 28.485 4.47248 28.391C4.55345 28.0955 4.36003 27.8448 4.04518 27.8448C3.90124 27.8448 3.88775 27.8358 3.88775 27.715C3.88775 27.6254 3.84727 27.5493 3.74831 27.4687C3.58639 27.3299 3.42446 27.321 3.23555 27.4329ZM3.57289 27.7776C3.65385 27.8761 3.60438 27.9612 3.24004 28.3373L2.93418 28.6641L2.82173 28.5522L2.71378 28.4403L3.06462 28.0776C3.25803 27.8761 3.43795 27.7105 3.46494 27.7105C3.49193 27.7105 3.54141 27.7418 3.57289 27.7776ZM4.10365 28.2567C4.11265 28.297 3.96871 28.494 3.7708 28.7134L3.42446 29.1029L3.31651 28.9999C3.25803 28.9462 3.21755 28.888 3.22205 28.8701C3.23105 28.8522 3.38398 28.682 3.5639 28.494C3.80679 28.2433 3.91923 28.1582 3.9912 28.1672C4.04518 28.1761 4.09465 28.2119 4.10365 28.2567Z" />
    <path d="M6.74357 28.0239C6.27579 28.0686 6.01041 28.1313 5.60559 28.297C4.40464 28.7716 3.45557 29.9043 3.1812 31.1758C3.07324 31.6773 3.08674 32.5772 3.20818 33.016C3.63549 34.592 4.93989 35.756 6.54566 35.9933C6.94148 36.0515 7.74212 35.9978 8.18292 35.8814C8.50227 35.8008 9.1095 35.5188 9.41086 35.3128C9.96411 34.9367 10.4544 34.3547 10.7647 33.7055C10.9986 33.2264 11.0751 32.9175 11.1336 32.2817C11.237 31.1132 10.8322 30.0117 9.9821 29.1656C9.12299 28.3059 7.96702 27.8985 6.74357 28.0239ZM8.04798 28.3417C9.61776 28.7178 10.7917 30.1058 10.9177 31.7265L10.9402 32.0086H7.1259V35.8142L6.84703 35.7874C4.94889 35.6173 3.50055 34.1756 3.32963 32.2862L3.30264 32.0086H7.1259V28.2029L7.40927 28.2298C7.5622 28.2432 7.85007 28.2925 8.04798 28.3417Z" />
    <path d="M18.686 30.9337H20.1208C20.9125 30.9337 21.7131 30.9113 21.9065 30.8845C22.9545 30.7412 23.4808 30.2039 23.4718 29.2995C23.4718 29.0712 23.4403 28.9279 23.3683 28.7757C23.2559 28.5474 22.959 28.225 22.8196 28.1802C22.6982 28.1399 22.7116 28.1086 22.8916 27.9653C22.977 27.8982 23.103 27.737 23.1749 27.6071C23.2829 27.4012 23.2964 27.3251 23.2964 26.949C23.2964 26.5953 23.2784 26.4923 23.1929 26.3356C23.058 26.0759 22.8241 25.8521 22.5452 25.7133C22.1269 25.5028 21.8525 25.4715 20.2063 25.4715H18.686V30.9337ZM21.6816 26.8326C21.8525 27.0027 21.8795 27.1773 21.7671 27.3967C21.6366 27.643 21.5647 27.6654 20.8135 27.6654H20.1253V26.6804H21.5287L21.6816 26.8326ZM21.6726 28.8026C21.875 28.8876 21.9695 29.0354 21.9695 29.2637C21.9695 29.398 21.938 29.4876 21.8615 29.5771L21.7536 29.7025L20.1253 29.7294V28.7399H20.8225C21.3218 28.7399 21.5647 28.7578 21.6726 28.8026Z" />
    <path d="M24.1282 25.4804C24.1192 25.6416 23.9033 30.7993 23.9033 30.8531C23.9033 30.9247 23.9662 30.9337 24.5959 30.9337H25.2886L25.3156 30.5397C25.3291 30.3248 25.3516 29.7248 25.3651 29.2099C25.3786 28.6906 25.4011 28.0906 25.4146 27.8712L25.4416 27.4728L25.5945 27.9608C25.6755 28.2294 25.9228 29.004 26.1432 29.68L26.5436 30.9113L27.9469 30.9381L28.0864 30.4994C28.1628 30.2576 28.3877 29.5681 28.5811 28.9637C28.7745 28.3592 28.9635 27.7683 28.9994 27.6429L29.0624 27.419L29.0894 27.9787C29.1074 28.2876 29.1299 28.9726 29.1434 29.5009C29.1569 30.0293 29.1794 30.571 29.1929 30.6964L29.2153 30.9337H29.8855C30.2544 30.9337 30.5647 30.9202 30.5737 30.8978C30.5872 30.8799 30.5467 29.7472 30.4883 28.3816C30.4298 27.0161 30.3848 25.8027 30.3803 25.6819V25.4714H28.3697L27.812 27.3295C27.5061 28.3548 27.2452 29.1652 27.2317 29.1338C27.2227 29.1025 26.9664 28.2697 26.6695 27.2847L26.1297 25.4938L25.1312 25.4804C24.578 25.4759 24.1282 25.4759 24.1282 25.4804Z" />
    <path d="M30.8751 25.5252C30.8751 25.6864 31.7657 28.9817 32.1705 30.3293L32.346 30.9113L33.232 30.9248C33.7178 30.9293 34.1136 30.9293 34.1136 30.9248C34.1226 30.8621 34.8693 27.5579 34.8738 27.5803C34.9053 27.7683 35.6025 30.8576 35.6205 30.889C35.6385 30.9158 35.9938 30.9337 36.5021 30.9337H37.3522L37.4016 30.8084C37.5726 30.3606 38.751 26.0759 38.8185 25.6372L38.85 25.4715H37.3117L37.2667 25.6372C37.2397 25.7312 37.1812 25.9953 37.1273 26.2282C37.0238 26.6983 36.5695 28.5742 36.4886 28.8518C36.4346 29.0443 36.4706 29.1831 35.8184 26.1297L35.6744 25.4715L34.8603 25.4805L34.0462 25.4939L33.6773 27.1952C33.4749 28.131 33.304 28.9279 33.295 28.9638C33.277 29.022 32.8857 27.4728 32.5484 26.0312L32.4224 25.4939L31.6488 25.4805C31.046 25.4715 30.8751 25.4805 30.8751 25.5252Z" />
    <path d="M27.7402 33.0787C26.7237 33.3563 25.995 34.0055 25.6757 34.9412C25.5677 35.2501 25.5542 35.371 25.5497 35.8143C25.5497 36.253 25.5677 36.3829 25.6712 36.6873C26.2514 38.42 28.3879 39.1767 30.1332 38.2633C30.5425 38.0484 31.0912 37.4843 31.2981 37.0679C32.0628 35.5098 31.2891 33.7189 29.6114 33.1503C29.1031 32.9802 28.2395 32.9443 27.7402 33.0787ZM29.1886 34.4442C29.6609 34.6591 29.9802 35.0666 30.0567 35.5546C30.1422 36.0963 29.9712 36.5888 29.5709 36.947C28.8332 37.6096 27.6908 37.4037 27.2095 36.5261C27.0431 36.2172 26.9981 35.6799 27.115 35.3397C27.268 34.8785 27.7312 34.4487 28.1945 34.3412C28.4734 34.2741 28.9187 34.3189 29.1886 34.4442Z" />
    <path d="M38.4319 33.0877C37.8427 33.2355 37.4693 33.4504 37.0375 33.8847C36.4618 34.4622 36.2279 35.0129 36.2279 35.8144C36.2279 36.9158 36.7946 37.8157 37.8022 38.3216C39.0436 38.944 40.6809 38.6574 41.5805 37.659C41.8368 37.3769 42.1247 36.8173 42.2102 36.4457C42.2866 36.1188 42.2776 35.4472 42.1877 35.1204C41.9178 34.0996 41.1127 33.3385 40.0017 33.0653C39.5924 32.9668 38.8637 32.9758 38.4319 33.0877ZM39.8802 34.4443C40.1906 34.5876 40.4695 34.8697 40.6269 35.1831C40.7303 35.398 40.7483 35.4875 40.7483 35.8368C40.7483 36.2128 40.7393 36.2621 40.5954 36.5218C40.4245 36.8352 40.1951 37.0546 39.8757 37.2113C39.7048 37.2963 39.5969 37.3142 39.2415 37.3142C38.8412 37.3142 38.7962 37.3053 38.5308 37.162C38.216 36.9919 37.9911 36.7501 37.8382 36.4143C37.6987 36.1143 37.6987 35.5323 37.8382 35.2234C38.189 34.4488 39.1291 34.0906 39.8802 34.4443Z" />
    <path d="M18.6861 33.1144C18.6861 33.2443 18.5061 37.5962 18.4837 38.0215L18.4522 38.5453H19.8465L19.8735 38.2633C19.887 38.111 19.9095 37.5648 19.923 37.0455C19.9365 36.5261 19.959 35.8769 19.9725 35.5948L20.004 35.0844L20.1524 35.5724C20.2334 35.8411 20.4807 36.6156 20.7011 37.2917L21.1015 38.5229L22.5048 38.5498L22.9546 37.1708C23.202 36.4097 23.4494 35.6307 23.5124 35.4337L23.6203 35.0755L23.6473 35.5456C23.6653 35.8053 23.6878 36.4366 23.7013 36.9559C23.7148 37.4753 23.7373 38.0439 23.7508 38.2185L23.7777 38.5453H25.1676L25.1406 38.129C25.1271 37.9051 25.0732 36.6873 25.0237 35.4337C24.9742 34.1755 24.9157 33.1368 24.8977 33.1144C24.8797 33.0965 24.4299 33.0876 23.8992 33.0921L22.9366 33.1055L22.8152 33.4861C22.7477 33.6965 22.4958 34.5293 22.2574 35.3396C22.0145 36.15 21.8076 36.7813 21.7941 36.75C21.7807 36.7141 21.5288 35.8814 21.2274 34.8964L20.6876 33.1055L19.6891 33.0921C19.1359 33.0876 18.6861 33.0965 18.6861 33.1144Z" />
    <path d="M31.5952 34.3368H33.1694V38.5454L33.9026 38.5365L34.6313 38.523L34.6448 36.4277L34.6538 34.3368H36.2325L36.2056 33.1056L33.9026 33.0921L31.5952 33.0832V34.3368Z" />
    <path d="M42.7947 38.5454L43.5279 38.5365L44.2566 38.523L44.2701 37.7037L44.2791 36.8844L45.1922 36.9112L45.4081 37.2873C45.525 37.4933 45.7004 37.8604 45.7949 38.1022L45.9658 38.5454H46.7439C47.3692 38.5454 47.5176 38.532 47.5176 38.4827C47.5176 38.3081 46.9688 37.1261 46.681 36.6739C46.5955 36.5441 46.528 36.4277 46.528 36.4142C46.528 36.4008 46.636 36.2889 46.7664 36.159C46.9014 36.0292 47.0633 35.8053 47.1398 35.6576C47.2612 35.4113 47.2702 35.3487 47.2702 34.8965C47.2702 34.4577 47.2567 34.3771 47.1488 34.1488C46.8969 33.607 46.4201 33.2712 45.7364 33.1503C45.489 33.1056 44.9987 33.0877 44.0902 33.0832H42.7947V38.5454ZM45.4935 34.3905C45.9388 34.6189 45.9793 35.2636 45.57 35.5636C45.4575 35.6442 45.3631 35.6576 44.8593 35.6755L44.2791 35.6889V34.292H44.7873C45.2236 34.292 45.3226 34.3055 45.4935 34.3905Z" />
    <path d="M48.0796 33.1369C48.0661 33.1682 48.0616 34.395 48.0661 35.8591L48.0796 38.523L48.8127 38.5365L49.5414 38.5454V36.8888H50.441L50.5939 37.1216C50.7918 37.4306 50.9358 37.7261 51.1157 38.1783L51.2641 38.5454H52.8294L52.8024 38.4424C52.672 38.026 52.2492 37.1306 51.9658 36.6829L51.7904 36.4053L52.0737 36.1232C52.501 35.6979 52.6675 35.1696 52.5595 34.5875C52.4291 33.8756 52.0153 33.4369 51.2506 33.1906C51.0392 33.1235 50.7828 33.11 49.5549 33.0921C48.3674 33.0787 48.0975 33.0877 48.0796 33.1369ZM50.6749 34.3368C50.7648 34.3637 50.8998 34.4532 50.9762 34.5428C51.0977 34.6816 51.1157 34.7308 51.1157 34.9681C51.1157 35.5188 50.8818 35.68 50.0676 35.68H49.5414V34.292H50.0272C50.2925 34.292 50.5849 34.3144 50.6749 34.3368Z" />
    <path d="M54.8495 33.3608C54.3817 34.395 53.6171 36.7545 53.2123 38.42L53.1853 38.5454H54.6606L54.7326 38.241C54.7776 38.0753 54.8405 37.8335 54.8765 37.7037L54.944 37.4709H56.9546L57.085 37.9947L57.2155 38.523L57.9621 38.5365L58.7088 38.5454L58.6638 38.3574C58.3714 37.0276 57.5123 34.3726 57.0535 33.3608L56.9231 33.0832H54.98L54.8495 33.3608ZM56.2574 35.1203C56.3698 35.4651 56.4958 35.8546 56.5318 35.98L56.5992 36.2172H55.947C55.5917 36.2172 55.2993 36.2038 55.2993 36.1859C55.2993 36.1143 55.6682 34.9457 55.7986 34.6054C55.965 34.1711 55.929 34.1264 56.2574 35.1203Z" />
    <path d="M59.1451 33.1323C59.1316 33.1681 59.1271 34.3949 59.1316 35.8589L59.1451 38.5229L60.517 38.5184C61.8799 38.514 61.8934 38.514 62.2937 38.3931C63.2742 38.0976 63.9084 37.5155 64.1873 36.6514C64.3537 36.1365 64.3537 35.5008 64.1873 34.9814C63.9039 34.1083 63.1978 33.4815 62.1857 33.2084C61.8889 33.1278 61.6775 33.1144 60.508 33.092C59.406 33.0786 59.1631 33.0831 59.1451 33.1323ZM62.0103 34.5292C62.5636 34.7978 62.8424 35.2948 62.8019 35.9216C62.766 36.4858 62.5006 36.8753 62.0058 37.1081C61.7854 37.2066 61.682 37.2245 61.1827 37.2379L60.6069 37.2558V34.3725L61.1827 34.3859C61.7 34.4038 61.7764 34.4173 62.0103 34.5292Z" />
  </IconBox>
);
