import styled from 'styled-components';

export const CategoryEquipmentListContainer = styled.div`
  position: relative;
  padding: 0.75rem;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #e0e3e8 0%, rgba(224, 227, 232, 0) 100%);
    opacity: 0.2;
    transition: height 150ms ease-in-out;
    z-index: -1;
  }
`;
