import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M31.2253 17.1154C31.2244 17.5447 31.4884 17.9301 31.8891 18.0844L41.6341 21.8324L45.3822 31.5773C45.4352 31.7155 45.5159 31.8373 45.6163 31.9376C45.8073 32.1286 46.0698 32.2417 46.3511 32.2411C46.7805 32.2401 47.1647 31.9742 47.317 31.5727L55.9263 8.87569C56.0708 8.49471 55.9784 8.06433 55.6903 7.77621C55.4022 7.48809 54.9718 7.39573 54.5908 7.54023L31.8936 16.1495C31.4921 16.3018 31.2263 16.686 31.2253 17.1154ZM43.6436 21.2874L52.2786 12.6524L46.342 28.3034L43.6436 21.2874ZM50.8141 11.1879L42.1791 19.8229L35.1632 17.1244L50.8141 11.1879Z" />
    <path d="M39.3543 27.3443L35.2898 31.4088L36.4158 23.5271C36.4788 23.0857 36.2523 22.6536 35.8537 22.4543C35.4549 22.255 34.9735 22.3332 34.6583 22.6483L28.397 28.9096C27.9926 29.314 27.9926 29.9697 28.397 30.3741C28.8014 30.7785 29.4572 30.7785 29.8616 30.3741L33.926 26.3097L32.8001 34.1914C32.7531 34.5203 32.867 34.8441 33.0931 35.0702C33.1703 35.1474 33.2607 35.2134 33.3622 35.2641C33.7609 35.4635 34.2424 35.3853 34.5575 35.0701L40.8188 28.8088C41.2232 28.4044 41.2232 27.7487 40.8188 27.3443C40.4144 26.9399 39.7587 26.9399 39.3543 27.3443Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3003 11.6808C16.505 6.73183 23.5419 3.69649 31.2894 3.69649C36.576 3.69649 41.5291 5.10936 45.7955 7.57733L46.935 5.60756C42.3316 2.94463 36.9867 1.4209 31.2894 1.4209C22.9139 1.4209 15.3056 4.71301 9.69078 10.0712L7.06324 7.44368L5.45416 9.05277L10.5742 14.1729L12.1833 12.5638L11.3003 11.6808ZM2.2756 32.7103C2.2756 25.7998 4.69016 19.4563 8.72265 14.473L6.95367 13.0415C2.60535 18.4151 0 25.26 0 32.7103C0 40.7967 3.06752 48.1666 8.10238 53.72L6.02198 55.8004L7.63107 57.4094L9.69092 55.3496C15.3068 60.7089 22.9138 63.9998 31.2894 63.9998C39.5195 63.9998 47.0089 60.8209 52.594 55.6265L54.3769 57.4094L55.986 55.8004L50.8659 50.6803L49.2568 52.2894L50.9838 54.0163C45.8096 58.8016 38.8917 61.7242 31.2894 61.7242C23.5422 61.7242 16.5042 58.6877 11.3005 53.74L12.7512 52.2894L11.1421 50.6803L9.71357 52.1088C5.08951 46.969 2.2756 40.1683 2.2756 32.7103ZM56.1039 17.667C58.7685 22.0526 60.3033 27.2005 60.3033 32.7103C60.3033 39.69 57.8401 46.0914 53.7345 51.0971L55.494 52.5402C59.921 47.1426 62.5789 40.2353 62.5789 32.7103C62.5789 26.7721 60.9236 21.2171 58.0486 16.4853L56.1039 17.667Z"
    />
  </IconBox>
);
