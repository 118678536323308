import React from 'react';
import { Link, navigate } from 'gatsby';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { mapToRegisterDTO, RegisterFormModel } from 'src/common/models/auth';
import { Seo } from 'src/public/components/Seo';
import { CenteredLayout } from 'src/public/components/CenteredLayout';
import { Title } from 'src/common/components/typography/Title';
import { boolean, object, string } from 'yup';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { TextControl } from 'src/common/components/TextControl';
import { FormItem } from 'src/common/components/FormItem';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import { SimpleLink } from 'src/common/components/SimpleLink';
import {
  ContactRoute,
  LandingRoute,
  LoginRoute,
  PrivacyPolicyAndCookiesRoute,
  TosRoute,
} from 'src/public/publicRoutes';
import { FormActionsContainer } from 'src/common/components/FormActionsContainer';
import { Button } from 'src/common/components/Button';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import styled, { css } from 'styled-components';
import { HomeLogoLink } from 'src/public/components/HomeLogoLink';
import { registerActions } from 'src/state/auth/authActions';
import { useRequest } from 'src/lib/useRequest';
import { media } from 'src/config/breakpoints';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { Flex } from '@summer/react-kit';
import Login from 'src/assets/icons/Login';
import ChevronLeft from 'src/assets/icons/ChevronLeft';
import { AppRoute } from 'src/app/routes';
import { pipe } from 'fp-ts/lib/pipeable';
import { colors } from 'src/config/colors';
import { rgba } from 'polished';
import {
  RegistrationHeader,
  RegistrationHeaderSteps,
} from 'src/public/components/auth/RegistrationHeader';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { pushEvent, EventName } from 'src/common/services/googleTagManager';

const validationSchema = object().shape({
  companyEmail: string()
    .required('Pole obowiązkowe')
    .email('Podaj poprawny adres email'),
  tosAgreement: boolean().oneOf([true], 'Pole obowiązkowe'),
});

const initialValues: RegisterFormModel = {
  companyEmail: '',
  tosAgreement: false,
};

const InfoParagraph = styled(Paragraph)`
  margin-bottom: 1.375rem;
  margin-top: 0;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoginLinkWrapper = styled.div`
  border-top: 0.0625rem solid ${rgba(colors.white, 0.5)};
  display: grid;
  grid-row-gap: 3vh;
  grid-template-columns: 1fr;
  margin-top: 4.2vh;
  padding-top: 1.125rem;

  ${media.w.min.px576(css`
    grid-template-columns: 2fr 3fr;
  `)};
`;

const StyledForm = styled(Form)`
  margin-bottom: 4vh;
`;

const StyledTitle = styled(Title)`
  letter-spacing: 0.0625rem;
  padding-right: 2rem;
`;

const RegisterPage = () => {
  useStorePageUrl();

  const dispatch = useDispatch();
  const { isLoading } = useRequest(registerActions.request);
  const isLoggedIn = useSelector(isAuthenticatedSelector);

  if (isPlatformBrowser() && isLoggedIn) {
    navigate(AppRoute);
    return null;
  }

  return (
    <CenteredLayout>
      <Main>
        <HomeLogoLink />

        <Seo title="Rejestracja" />

        <RegistrationHeader active={RegistrationHeaderSteps.Step1} />

        <StyledTitle level={1} variant="condensed">
          Podaj służbowy adres&nbsp;e-mail
        </StyledTitle>

        <FlexScrollContainer>
          <section>
            <InfoParagraph size="small">
              Adres służbowy podajesz tylko raz, wyłącznie do celów
              weryfikacyjnych. To na jego podstawie możemy przyznać Ci dostęp do
              serwisu Car Platform. Podczas logowania będziesz używać prywatnego
              adresu e-mail.
            </InfoParagraph>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                pipe(
                  values,
                  mapToRegisterDTO,
                  registerActions.request,
                  dispatch
                );
                setSubmitting(false);
              }}
            >
              <StyledForm>
                <FormItem name="companyEmail">
                  <TextControl
                    type="email"
                    name="companyEmail"
                    label="Twój służbowy adres e-mail"
                  />
                </FormItem>
                <FormItem name="tosAgreement">
                  <CheckboxControl
                    name="tosAgreement"
                    label={
                      <Text size="xSmall">
                        Akceptuję
                        <SimpleLink
                          as={Link}
                          to={TosRoute}
                          style={{ marginLeft: '0.2rem' }}
                        >
                          Regulamin świadczenia usług
                        </SimpleLink>{' '}
                        na potrzeby utworzenia i&nbsp;korzystania
                        z&nbsp;bezpłatnego konta w&nbsp;serwisie Car Platform
                      </Text>
                    }
                  />
                </FormItem>
                <FormActionsContainer
                  style={{ margin: '2vh 0' }}
                  justifyContent="space-between"
                >
                  <Button
                    kind="box-inverted"
                    variant="transparent"
                    icon={ChevronLeft}
                    as={Link}
                    to={LandingRoute}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Wróć
                  </Button>

                  <Button
                    kind="skew"
                    size="small"
                    type="submit"
                    icon={ArrowRight}
                    disabled={isLoading}
                    onClick={() => {
                      pushEvent(EventName.Try, {});
                    }}
                  >
                    Załóż konto
                  </Button>
                </FormActionsContainer>
              </StyledForm>
            </Formik>

            <Paragraph size="xSmall">
              Administratorem Twoich danych jest Mobility Benefit sp.
              z&nbsp;o.o. Twoje dane zostaną użyte do celów weryfikacyjnych oraz
              założenia i&nbsp;obsługi konta w&nbsp;serwisie. Masz prawo żądać
              dostępu do danych osobowych, ich sprostowania lub usunięcia,
              ograniczenia przetwarzania lub sprzeciwu wobec przetwarzania,
              a&nbsp;także prawa do przenoszenia danych. Więcej informacji
              o&nbsp;przetwarzaniu Twoich danych znajdziesz w naszej{' '}
              <SimpleLink
                as={Link}
                style={{ paddingTop: 0 }}
                to={PrivacyPolicyAndCookiesRoute}
              >
                Polityce prywatności i cookies
              </SimpleLink>
              .
            </Paragraph>

            <LoginLinkWrapper>
              <Flex alignCenter>
                <Login size={27} />
                <Text as="div" size="xSmall" style={{ marginLeft: '0.75rem' }}>
                  Masz już konto?
                  <br />
                  <SimpleLink as={Link} to={LoginRoute}>
                    Zaloguj się
                  </SimpleLink>
                </Text>
              </Flex>

              <Text size="xSmall" style={{ fontWeight: 700 }}>
                Jeśli nie masz pewności, czy Twoja firma posiada dostęp,
                <SimpleLink as={Link} to={ContactRoute}>
                  napisz do nas.
                </SimpleLink>
              </Text>
            </LoginLinkWrapper>
          </section>
        </FlexScrollContainer>
      </Main>
    </CenteredLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegisterPage;
