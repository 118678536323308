import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { ClientSideOnly } from 'src/common/components/ClientSideOnly';
import { Badge } from 'src/app/common/models/badge';
import { media } from 'src/config/breakpoints';
import { StyleProps } from '@summer/react-kit';
import { isNotNilEmpty } from 'src/lib/isNilEmpty';
import { PartialProduct } from 'src/app/common/models/product';
import { BadgesBinder } from 'src/app/common/components/BadgesBinder';

export const productOfferTileMaxWidth = '24rem';

const Container = styled.div`
  color: ${colors.lightGray};
  text-align: left;
  min-width: 19.5rem;
  max-width: ${productOfferTileMaxWidth};

  ${media.w.min.px768(css`
    padding-bottom: 2rem;
  `)};
`;

const Header = styled.div`
  min-height: 2.5rem;
  margin-bottom: 0.5rem;
`;

const HeadingContainer = styled.div`
  display: grid;
  grid-template-areas: 'title badge' 'tag badge';
  grid-template-columns: minmax(0, min-content) min-content;
  grid-column-gap: 1.25rem;
  align-items: center;
`;

const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 700;
  grid-area: title;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tagline = styled.div`
  height: calc(0.75rem * 1.15);
  font-size: 0.75rem;
  font-weight: 700;
  grid-area: tag;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 40%) minmax(0, 60%);
  grid-column-gap: 1rem;
  margin-top: -0.5rem; // not quite sure about this pull-up
  position: relative;
  margin-bottom: 0.2rem;
`;

const FooterLeftContent = styled.div`
  position: relative;
`;

const FooterRightContent = styled.div`
  position: relative;
  text-align: right;
  min-height: 2rem;
`;

const AbsoluteContainer = styled.div`
  ${media.w.min.px768(css`
    position: absolute;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  `)};
  position: absolute;

  ${media.w.max.px768(css`
    top: 1rem;
  `)};
`;

const AbsoluteContainerRight = styled.div`
  @media (hover: hover) {
    ${Container}:hover & {
      top: -2rem;
    }
  }

  ${media.w.min.px768(css`
    position: absolute;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  `)};
`;

const BadgeContainer = styled.div`
  grid-area: badge;
`;

export interface ProductOfferTileChangedProps {
  title: ReactNode;
  badges?: Badge[];
  tagline?: ReactNode;
  headerExtras?: ReactNode;
  photo?: ReactNode;
  footerLeft?: ReactNode;
  footerRight?: ReactNode;
  product?: PartialProduct;
}

export const ProductOfferTileChanged: FC<
  StyleProps & ProductOfferTileChangedProps
> = ({
  style,
  className,
  title,
  badges,
  tagline,
  headerExtras,
  photo,
  footerLeft,
  footerRight,
  product,
}) => {
  return (
    <Container style={style} className={className}>
      <Header>
        <HeadingContainer>
          <Title>{title}</Title>
          <BadgeContainer>
            <ClientSideOnly>
              {isNotNilEmpty(badges) && (
                <BadgesBinder
                  elements={1}
                  offer={product?.offer}
                  product={product}
                  variant="small"
                />
              )}
            </ClientSideOnly>
          </BadgeContainer>
          <Tagline>{tagline}</Tagline>
        </HeadingContainer>
        {headerExtras}
      </Header>
      {photo}
      <Footer>
        <FooterLeftContent>
          <AbsoluteContainer style={{ marginTop: 16 }}>
            {footerLeft}
          </AbsoluteContainer>
        </FooterLeftContent>
        <FooterRightContent>
          <AbsoluteContainerRight style={{ right: 0 }}>
            {footerRight}
          </AbsoluteContainerRight>
        </FooterRightContent>
      </Footer>
    </Container>
  );
};
