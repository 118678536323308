import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';
import { ErrorMessage } from 'formik';
import { isFunctionComponentElement } from 'src/lib/typeGuards';

type ChildType = 'TextControl' | 'CheckboxControl' | string;

const childTypeSpacing: Record<ChildType, ReturnType<typeof css>> = {
  ['TextField']: css`
    margin-top: -0.75rem;
    padding: 0.25rem 0.875rem 0 0.875rem;
  `,
  ['TextControl']: css`
    margin-top: -0.75rem;
    padding: 0.25rem 0.875rem 0 0.875rem;
  `,
  ['CheckboxControl']: css`
    margin-top: -0.75rem;
    padding: 0.25rem 1.25rem 0 1.85rem;
  `,
};

const MetaContainer = styled.div<{
  childType?: ChildType;
}>`
  font-size: ${typography.fontSizeSm};
  color: ${colors.red};
  letter-spacing: 1px;
  min-height: 1.25rem;
  padding: 0.25rem 0.875rem 0 0.875rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${({ childType }) => (childType ? childTypeSpacing[childType] : '')};
`;

const FormItemHint = styled.div<{ position?: string }>`
  text-align: ${({ position }) => position ?? 'right'};
  font-size: 0.75rem;
  color: ${colors.lightGray};
  margin-left: 0.5rem;
  margin-bottom: 0.75rem;
`;

export interface FormItemProps {
  name: string;
  hint?: ReactNode;
  hintPosition?: string;
}

const getDisplayName = (a: ReactNode) => {
  if (!isFunctionComponentElement(a)) {
    return '';
  }
  return a.type.displayName;
};

export const FormItem: FC<FormItemProps> = ({
  name,
  hint,
  hintPosition,
  children,
}) => (
  <div>
    {children}
    <MetaContainer childType={getDisplayName(children)}>
      <div>
        <ErrorMessage name={name} component="div" />
      </div>
      {hint && <FormItemHint position={hintPosition}>{hint}</FormItemHint>}
    </MetaContainer>
  </div>
);

export const UnboundFormItem: FC = ({ children }) => (
  <>
    {children}
    <MetaContainer childType={getDisplayName(children)}>
      <div />
    </MetaContainer>
  </>
);
