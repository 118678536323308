import React, {
  ComponentType,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { useSelector } from 'react-redux';
import { StyleProps } from '@summer/react-kit';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import {
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { fade } from '@material-ui/core';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { OptionFilter, ExoticFilter } from 'src/app/common/models/filter';
import { MOTORCYCLE_ID } from 'src/common/constants';
import {
  useFilterControl,
  useOptionFilterControl,
} from 'src/app/search/hooks/useFilterControl';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';

const StyledHorizontalToggleGroupContainer = styled(
  HorizontalToggleGroupContainer
)`
  border: 0.0625rem solid ${fade(colors.grayBasic, 0.2)};

  & > * :not(:last-of-type) {
    &:after {
      background-color: ${colors.white};
    }
  }
`;

export interface CarsMotorcyclesSwitchProps extends StyleProps {
  variant?: ToggleButtonVariant;
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
}

const StyledHorizontalToggleButton = styled(HorizontalToggleButton)`
  color: ${colors.grayBasic};

  &:enabled {
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.lightGray};
        color: ${colors.grayBasic};
      }
    }

    &:focus {
      border-color: ${colors.white};
    }
  }

  ${({ value }) =>
    value &&
    css`
      color: ${colors.white};
      background-color: ${colors.grayBasic};

      &:enabled {
        @media (hover: hover) {
          &:hover {
            background-color: ${colors.lightGray};
            color: ${colors.grayBasic};
          }
        }

        &:focus {
          border-color: ${colors.white};
        }
      }
    `}
  &:disabled {
    opacity: 0.3;
  }
`;

export const CarsMotorcyclesSwitch: FC<CarsMotorcyclesSwitchProps> = ({
  variant,
  style,
  className,
  toggleButtonComponent,
}) => {
  const brandsList = useSelector(brandsListSelector);
  const { state: brandState, setValue: setBrandValue } = useOptionFilterControl(
    OptionFilter.Brand
  );
  const { setValue: setModelValue } = useFilterControl(
    ExoticFilter.Model,
    searchFiltersActions.setModelFilterValue
  );

  const VALUES = [
    { id: 'cars', label: 'Samochody' },
    { id: 'motorcycle', label: 'Motocykle' },
  ];

  const generateInitialValue = () => {
    if (brandState.value.includes(MOTORCYCLE_ID)) {
      return ['motorcycle'];
    }
    return ['cars'];

    // const motorcycleId = brandsList?.find((item) => item.code === 'motorcycle')
    //   ?.id;
    // if (motorcycleId) {
    //   if (brandState.value.includes(motorcycleId)) {
    //     return ['motorcycle'];
    //   }
    // }
    // return ['cars'];
  };
  const [value, setValue] = useState<string[]>();
  useEffect(() => {
    setValue(generateInitialValue());
  }, [brandState.value]);

  const handlePreferenceChange = (v: string[]) => {
    if (v[0] === 'motorcycle') {
      const motorcycleId = brandsList?.find(
        (item) => item.code === 'motorcycle'
      )?.id;
      if (motorcycleId) {
        setValue(['motorcycle']);
        setBrandValue([motorcycleId]);
      }
    } else {
      setValue(['cars']);
      setBrandValue([]);
      const updatedObject: {
        [key: string]: string[];
      } = {};
      setModelValue(updatedObject);
    }
  };

  return (
    <StyledHorizontalToggleGroupContainer style={style} className={className}>
      <ToggleButtonGroup
        variant={variant}
        limit={1}
        value={value}
        onChange={(v) => handlePreferenceChange(v)}
        items={VALUES}
        toggleButtonComponent={
          toggleButtonComponent
            ? toggleButtonComponent
            : StyledHorizontalToggleButton
        }
      />
    </StyledHorizontalToggleGroupContainer>
  );
};
