import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M52.0961 10.7619V3.2836C52.0961 3.25826 52.0884 3.2333 52.074 3.21256C52.0598 3.19183 52.0393 3.17544 52.0157 3.16648C51.9918 3.15752 51.9658 3.15573 51.9412 3.16162C51.9167 3.16751 51.8941 3.18107 51.8771 3.19976L38.0052 18.6454C37.0113 19.752 36.4425 21.1753 36.4001 22.6622L35.5476 52.4344C35.547 52.4625 35.5553 52.4899 35.572 52.5124C35.5886 52.5349 35.6122 52.5512 35.6389 52.5589C35.6659 52.5665 35.6943 52.5651 35.7203 52.5544C35.7463 52.544 35.7678 52.5253 35.7816 52.501L37.5126 49.5006C37.7161 49.148 37.8488 48.7594 37.904 48.3559L40.3775 30.22C40.7373 27.5822 41.926 25.1268 43.772 23.2085L49.4632 17.2937C51.1521 15.5386 52.0954 13.1975 52.0954 10.7619H52.0961Z" />
    <path d="M11.9041 10.7619V3.2836C11.9041 3.25826 11.9117 3.2333 11.9262 3.21256C11.9404 3.19183 11.9609 3.17544 11.9848 3.16648C12.0084 3.15752 12.0344 3.15573 12.0591 3.16162C12.0838 3.16751 12.1062 3.18107 12.1232 3.19976L25.9949 18.6454C26.9889 19.752 27.5577 21.1753 27.6001 22.6622L28.4525 52.4344C28.4532 52.4625 28.4449 52.4899 28.4282 52.5124C28.4116 52.5349 28.388 52.5512 28.3613 52.5589C28.3343 52.5665 28.3058 52.5651 28.2799 52.5544C28.2539 52.544 28.2324 52.5253 28.2185 52.501L26.4876 49.5006C26.2841 49.148 26.1513 48.7594 26.0962 48.3559L23.6223 30.22C23.2625 27.5822 22.0738 25.1268 20.2278 23.2085L14.5365 17.2937C12.8473 15.5388 11.9041 13.1978 11.9041 10.7619Z" />
    <path d="M33.7828 19.9688L32.1646 18.5672C32.1189 18.5276 32.0605 18.5059 32.0002 18.5059C31.9399 18.5059 31.8813 18.5277 31.8359 18.5672L30.218 19.9688C30.0772 20.0908 29.9655 20.2423 29.8904 20.4125C29.8155 20.583 29.7794 20.7679 29.7847 20.954L30.8694 58.8576C30.8724 58.9617 30.9012 59.0636 30.9532 59.1537L31.8913 60.7785C31.9024 60.798 31.9183 60.8136 31.9374 60.8246C31.9565 60.8356 31.9783 60.8416 32.0001 60.8416C32.0219 60.8416 32.0438 60.8357 32.0628 60.8246C32.0819 60.8134 32.0979 60.7978 32.1089 60.7785L33.0477 59.153C33.0996 59.0629 33.1284 58.961 33.1315 58.857L34.2162 20.954C34.2214 20.7679 34.1853 20.583 34.1104 20.4125C34.0356 20.2419 33.9236 20.0905 33.7829 19.9685L33.7828 19.9688Z" />
  </IconBox>
);
