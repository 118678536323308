import { createSelector } from 'reselect';
import { SearchState } from 'src/app/search/state/index';

export const searchProductsStateSelector = (state: SearchState) =>
  state.searchProducts;

export const searchProductsResultsSelector = createSelector(
  searchProductsStateSelector,
  (state) => state.products
);

export const searchProductsResultsTotalSelector = createSelector(
  searchProductsStateSelector,
  (state) => state.total
);

export const searchProductsResultsLengthSelector = createSelector(
  searchProductsResultsSelector,
  (state) => state.length
);
