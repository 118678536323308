import { AccordionSummary } from '@material-ui/core';
import styled from 'styled-components';
import { ExpandMore } from 'src/common/components/ExpansionPanel/ExpandMore';
import React from 'react';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';

export const ExpansionPanelSummary = styled(AccordionSummary).attrs({
  expandIcon: <ExpandMore />,
})`
  && {
    margin-bottom: 1rem;
    padding: 0 1rem 0 0;
    font-family: ${typography.fontFamilyCondensed};
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${typography.headingSize[4]};
    letter-spacing: 1px;
    border-bottom: 0.5px solid ${colors.lightGray};
  }
`;
