import { Accordion } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'src/config/colors';

export const ExpansionPanel = styled(Accordion).attrs({ elevation: 0 })`
  && {
    color: ${colors.lightGray};
    background: none;

    &:before {
      display: none;
    }

    & .MuiIconButton-root {
      color: ${colors.lightGray};
    }
  }
`;
