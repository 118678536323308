import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { InstructionStep } from 'src/common/models/instructionStep';
import { rgba } from 'polished';

interface InstructionStepsProps {
  steps: InstructionStep[];
}

const Content = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  counter-reset: steps;
  position: relative;
  z-index: 2;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 0 0;

  ${media.w.min.px768(css`
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3rem;
  `)}

  ${media.w.between.px768.px992(css`
    padding: 5rem 10rem;
  `)}
`;

const Step = styled.div`
  ${media.w.max.px576(css`
    &:nth-child(even) {
      margin-top: 7rem;
    }

    &:nth-child(odd) {
      margin-top: 2rem;
    }
  `)}

  ${media.w.between.px576.px992(css`
    &:nth-child(even) {
      margin-top: 5rem;
    }

    &:first-child {
      margin-bottom: 4rem;
    }
  `)}
`;

const StepContent = styled.div`
  position: relative;
  padding-left: 2.125rem;

  &::before {
    // color: ${(props) =>
      props.color ? props.color : rgba(135, 135, 135, 0.2)};
    // opacity: 0.9;
    color: #e0e3e7;
    opacity: 0.3;
    content: counter(steps);
    counter-increment: steps;
    font-size: 9rem;
    font-style: italic;
    font-weight: 900;
    line-height: 11.25rem;
    position: absolute;
    text-align: center;
    z-index: -1;
    left: 0;
    top: -4.5rem;

    ${media.w.min.px992(css`
      top: -5.5rem;
    `)}
  }

  ${media.w.min.px992(css`
    padding-left: 2.75rem;
  `)}

  ${media.w.max.px992(css`
    &::before {
      font-size: 7.5rem;
      font-weight: 900;
      line-height: 9.375rem;
    }
  `)}
`;

const StepTitle = styled.h3`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: ${colors.lightGray};
  max-width: 11.75rem;
  height: auto;

  ${media.w.max.px992(css`
    font-size: 0.938rem;
    line-height: 1.25rem;
    margin-bottom: 0.375rem;
  `)}
`;

const StepText = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: ${colors.lightGray};

  ${media.w.max.px992(css`
    font-size: 0.75rem;
    line-height: 0.938rem;
  `)}
`;

export const InstructionSteps: FC<InstructionStepsProps> = ({ steps }) => (
  <Content>
    {steps.map((step, index) => (
      <Step key={index}>
        <StepContent color={step.customCounterColor}>
          <StepTitle>{step.title}</StepTitle>
          {step.content && <StepText>{step.content}</StepText>}
        </StepContent>
      </Step>
    ))}
  </Content>
);
