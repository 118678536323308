import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { PagesInfographicTitle } from 'src/common/components/PagesInfographicTitle';
import { PagesInfographicText } from 'src/common/components/PagesInfographicText';
import { HowItWorksInfographicContainer } from 'src/public/components/how-it-works/HowItWorksInfographicContainer';
import HowItWorksInfographicSlide4Svg from 'src/assets/images/how-it-works-infographic-slide-4.svg';
import { HowItWorksInfographicCounterWrapper } from 'src/public/components/how-it-works/HowItWorksInfographicCounter';

const Box = styled.div`
  align-items: flex-start;
  display: flex;

  ${media.w.max.px768(css`
    align-items: center;
    flex-direction: column;
  `)};
`;

const Content = styled.div`
  max-width: 20rem;
  margin-top: -2rem;
  padding-bottom: 2rem;

  ${media.w.min.px768(css`
    padding-left: 3rem;
    padding-top: 4.5rem;
    margin-top: 0;
    padding-bottom: 0;
  `)};
`;

const StyledHowItWorksInfographicSlide4 = styled(
  HowItWorksInfographicSlide4Svg
)`
  height: 14.6875rem;
  flex: 0 0 auto;
  margin-right: 1.5rem;

  ${media.w.max.px768(css`
    height: 12.5rem;
    margin-right: 0;
  `)};
`;

const StyledHowItWorksInfographicCounterWrapper = styled(
  HowItWorksInfographicCounterWrapper
)`
  ${media.w.max.px768(css`
    align-self: flex-end;
  `)};
`;

const StyledPagesInfographicTitle = styled(PagesInfographicTitle)`
  ${media.w.max.px768(css`
    max-width: 40%;
  `)};
`;

export const HowItWorksInfographicSlide2 = memo(() => (
  <HowItWorksInfographicContainer>
    <Box>
      <StyledHowItWorksInfographicCounterWrapper
        number={2}
        position={{ right: '-1rem', top: '-1rem' }}
        sendToBack
        responsive={{ position: { left: '-4rem', top: '20%' } }}
      >
        <StyledHowItWorksInfographicSlide4 />
      </StyledHowItWorksInfographicCounterWrapper>

      <Content>
        <StyledPagesInfographicTitle>
          WYPEŁNIASZ WNIOSEK
        </StyledPagesInfographicTitle>
        <PagesInfographicText>
          Na tym etapie przeprowadzona zostanie analiza kredytowa niezbędna do
          zawarcia umowy
        </PagesInfographicText>
      </Content>
    </Box>
  </HowItWorksInfographicContainer>
));
