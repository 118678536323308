import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M30.0401 29.9302h-1.3905l-.0006-.9158 5.5251.0009v.9149h-1.376v4.133h1.276c1.1818 0 1.9576-.7804 1.9576-1.8403h.9115v2.7678l-8.2942.0003v-.9264h1.3931l-.002-4.1344zm6.7528.0024h-1.3714l.0014-.9182h5.9914l.0011.9176-1.45.0006 2.0211 3.6877 1.995-3.6883h-1.1931v-.9176h4.1301v.9182l-1.3731-.0006-2.7389 5.0661-3.2269-.0023-2.7867-5.0632zm13.8165-1.0443c2.7744 0 4.2631 1.3844 4.2631 3.1214 0 1.7609-1.4029 3.1638-4.2483 3.1638-2.8449 0-4.2635-1.4029-4.2635-3.1638 0-1.737 1.4735-3.1214 4.2487-3.1214zm-35.2019 4.732l1.9943-3.6883H16.209v-.9176h4.1298v.9182l-1.3725-.0006-2.7395 5.0661-3.2266-.0023-2.7869-5.0632H8.84198l.00124-.9182h5.99188l.0005.9176-1.4498.0006 2.0217 3.6877zm8.6315-4.732c2.7751 0 4.2644 1.3844 4.2644 3.1214 0 1.7609-1.4037 3.1638-4.2485 3.1638s-4.264-1.4029-4.264-3.1638c0-1.737 1.473-3.1214 4.2481-3.1214zm-1.47 3.0842c.0199-1.1527.5715-2.1572 1.5209-2.141.9492.0169 1.45 1.041 1.4297 2.1927-.0191 1.0867-.468 2.2178-1.5114 2.1989-1.0128-.0183-1.4583-1.138-1.4392-2.2506zm26.5694 0c.0205-1.1527.5726-2.1572 1.5212-2.141.9493.0169 1.4502 1.041 1.4299 2.1927-.0192 1.0867-.4684 2.2178-1.5104 2.1989-1.0147-.0183-1.4592-1.138-1.4407-2.2506zm-40.5435-5.765C11.1872 15.696 20.6771 7.90033 31.9891 7.90033c11.3121 0 20.8021 7.79567 23.3943 18.30717H8.5949zm46.7885 11.5771c-2.5922 10.5115-12.0822 18.3072-23.3943 18.3072-11.312 0-20.8019-7.7957-23.3942-18.3072h46.7885zm2.2636-24.9069l1.5157-1.5156c1.0776 1.4172 2.0483 2.9205 2.897 4.4992h1.197V.755245H48.1506V1.93941c1.5688.84544 3.0632 1.81119 4.4727 2.88305l-1.5157 1.51546C45.7736 2.35716 39.1568 0 31.9891 0 14.3182 0-.00683594 14.3252-.00683594 31.9961c0 17.6708 14.32503594 31.996 31.99593594 31.996 17.671 0 31.9962-14.3252 31.9962-31.996 0-7.1676-2.3572-13.7845-6.3383-19.1184z"
    />
  </IconBox>
);
