import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  SearchFilterOptionsContainer,
  SearchFilterOptionsGrid,
  SearchFilterOptionsHeader,
} from 'src/app/search/components/SearchPanel/SearchFilterOptions';
import { ToggleButton } from 'src/common/components/ToggleButton';
import { StyleProps } from '@summer/react-kit';
import styled, { css } from 'styled-components';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { pipe } from 'fp-ts/lib/pipeable';
import { fold, fromNullable, mapNullable } from 'fp-ts/lib/Option';
import { CheckboxList } from 'src/common/components/CheckboxList';
import { filterConfig } from 'src/app/common/models/filterConfig';
import { sortWith } from 'ramda';
import { media } from 'src/config/breakpoints';

const StyledSearchFilterOptionsContainer = styled(SearchFilterOptionsContainer)`
  height: 100%;
  display: grid;
  grid-template-areas: 'header' 'groups' 'options';
  grid-template-rows: auto 3.5rem 20rem;

  ${media.w.min.px768(css`
    grid-template-columns: 3fr 5fr;
    grid-column-gap: 1rem;
    grid-template-areas: 'header header' 'groups options';
    grid-template-rows: auto 1fr;
  `)};
`;

const GroupsGrid = styled(SearchFilterOptionsGrid)`
  align-content: flex-start;
  margin: 0;

  ${media.w.max.px768(css`
    flex-wrap: nowrap;
  `)};

  ${media.w.min.px768(css`
    padding-right: 1rem;
    border-right: 2px solid #3d3e44;
  `)};
`;

const OptionsContainer = styled.div`
  grid-area: options;
  height: 100%;

  ${media.w.max.px768(css`
    margin-top: 1rem;
  `)};
`;

const StyledScrollContainer = styled(ScrollContainer)`
  display: block;
  height: 100%;
  width: 100%;
`;

const StyledToggleButton = styled(ToggleButton)`
  flex: 1;
  max-width: 100%;
  text-align: right;
  white-space: normal;
  justify-content: flex-end;
  text-transform: uppercase;
  border-radius: 0.125rem;
`;

export const SearchElementEquipmentFilter = memo<StyleProps>(
  ({ className, style }) => {
    const { state, pristine, setValue, reset } = useOptionFilterControl(
      OptionFilter.EquipmentItemGroup
    );

    const [activeGroupId, setActiveGroupId] = useState<string | undefined>(
      undefined
    );

    useEffect(() => {
      if (activeGroupId !== undefined) {
        return;
      }

      pipe(
        fromNullable(state.groups),
        mapNullable((_) => _[0]),
        fold(
          () => null,
          (_) => setActiveGroupId(_.id)
        )
      );
    }, [state]);

    const scrollRef = useRef<OverlayScrollbarsComponent | null>(null);

    const onActiveGroupChange = useCallback(([id]: string[]) => {
      setActiveGroupId(id);
      scrollRef.current?.osInstance()?.scroll({ y: 0 });
    }, []);

    const activeGroupToggleGroupValue = useMemo(
      () => (activeGroupId ? [activeGroupId] : []),
      [activeGroupId]
    );

    const activeGroupOptions = useMemo(
      () =>
        sortWith(
          [
            (a, b) =>
              (state.value.includes(a.id) ? -1 : 0) +
              (state.value.includes(b.id) ? 1 : 0),
            (a, b) => a.label.localeCompare(b.label),
          ],
          state.groups?.find((_) => _.id === activeGroupId)?.options ?? []
        ),
      [state.groups, activeGroupId]
    );

    return (
      <StyledSearchFilterOptionsContainer
        direction="vertical"
        className={className}
        style={style}
      >
        <SearchFilterOptionsHeader
          name={filterConfig[OptionFilter.EquipmentItemGroup].name}
          onClear={!pristine ? reset : undefined}
          direction="vertical"
          style={{ gridArea: 'header' }}
        />
        <div style={{ gridArea: 'groups', height: '100%' }}>
          <StyledScrollContainer options={{ sizeAutoCapable: false }}>
            <GroupsGrid>
              <ToggleButtonGroup
                limit={1}
                value={activeGroupToggleGroupValue}
                onChange={onActiveGroupChange}
                items={state.groups ?? []}
                toggleButtonComponent={StyledToggleButton}
              />
            </GroupsGrid>
          </StyledScrollContainer>
        </div>

        <OptionsContainer>
          <StyledScrollContainer
            ref={scrollRef}
            options={{ sizeAutoCapable: false }}
            style={{ paddingRight: '0.75rem' }}
          >
            <CheckboxList
              items={activeGroupOptions}
              value={state.value}
              onChange={setValue}
            />
          </StyledScrollContainer>
        </OptionsContainer>
      </StyledSearchFilterOptionsContainer>
    );
  }
);
