import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'src/config/colors';

const Trapezoid = styled.div`
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
  background-color: ${colors.grayBasic};
  width: 2.5rem;
  height: 0.625rem;
  margin: 0.375rem 0;
`;

const animation = (offset: string) => keyframes`
  0% {
    background-color: ${colors.grayBasic};
    transform: translateX(0);
  }
  ${offset} {
    background-color: ${colors.primary};
    transform: translateX(1.25rem);
  }
  100% {
    background-color: ${colors.grayBasic};
    transform: translateX(0);
  }
`;

const Trapezoid1 = styled(Trapezoid)`
  animation: ${animation('35%')} 1000ms infinite;
`;

const Trapezoid2 = styled(Trapezoid)`
  animation: ${animation('45%')} 900ms infinite;
`;

const Trapezoid3 = styled(Trapezoid)`
  animation: ${animation('40%')} 1100ms 100ms infinite;
`;

export const Loader: FC = () => (
  <div>
    <Trapezoid1 />
    <Trapezoid2 />
    <Trapezoid3 />
  </div>
);
