import { css } from 'styled-components';

const defaultSkewLength = '2.5rem';

export const skewBoth = (skewLength = defaultSkewLength) =>
  css`
    clip-path: polygon(
      ${skewLength} 0,
      100% 0,
      calc(100% - ${skewLength}) 100%,
      0 100%
    );
  `;

export const skewLeft = (skewLength = defaultSkewLength) =>
  css`
    clip-path: polygon(${skewLength} 0, 100% 0, 100% 100%, 0 100%);
  `;

export const skewRight = (skewLength = defaultSkewLength) =>
  css`
    clip-path: polygon(0 0, 100% 0, calc(100% - ${skewLength}) 100%, 0 100%);
  `;
