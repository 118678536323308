import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.1058 32.3119h-6.2555l-6.2994 1.3018-1.5643 4.5678-1.0263-.5151h-3.4427l.6068 2.4365 1.6405 1.4637.0435.0655-.5426.8169-.1842 4.6219 1.2085 4.1588v1.9308l1.9345.7281h2.6327l2.3022-.6932v-1.034h4.852l6.7054.0007v1.034l2.3021.6932h2.6327l1.9345-.7281v-1.9308l1.2085-4.1588-.1842-4.6219-.5426-.8169.0435-.0655 1.6405-1.4637.6068-2.4365h-3.4426l-1.0264.5151-1.5643-4.5678-6.2196-1.2853v-.0172z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.979 0l22.9791 23.065V64H9V23.065L31.979 0zM12.0066 24.3151v36.667h39.9449v-36.667L31.979 4.26789 12.0066 24.3151z"
    />
    <path
      fill="#2A2B32"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8633 43.0257h6.6901l1.1305 3.9717-5.0928-.9294-2.7278-3.0423zm3.0598 1.3678l.3696.4123 2.4789.4523-.2462-.8646h-2.6023zM42.1816 43.0257h-6.69l-1.1306 3.9717 5.0928-.9294 2.7278-3.0423zm-3.0598 1.3678l-.3696.4123-2.4788.4523.2461-.8646h2.6023z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.8782 40.9627H23.5254v-1.3678h16.3528v1.3678z"
    />
    <path
      fill="#2A2B32"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5254 39.5949h17.0342v1.3678H23.5254v-1.3678z"
    />
  </IconBox>
);
