import { Seo } from 'src/public/components/Seo';
import React, { useEffect } from 'react';
import { Paragraph, Text } from 'src/common/components/typography/Text';
import { Title } from 'src/common/components/typography/Title';
import { authActions } from 'src/state/auth/authActions';
import { useDispatch } from 'react-redux';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Link } from 'gatsby';
import { ContactRoute } from 'src/public/publicRoutes';

export const CompanyConfirmationFailure = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.logout());
  }, []);

  return (
    <>
      <Seo title="Twoja firma nie ma dostępu do Car Platform" />

      <Title level={1} variant="condensed">
        Twoja firma nie ma dostępu do Car Platform
      </Title>

      <Paragraph size="small">
        Podany przez Ciebie adres służbowy nie przeszedł pomyślnie weryfikacji.
        Wygląda na to, że Twoja firma nie korzysta jeszcze z&nbsp;usługi Car
        Platform.
      </Paragraph>

      <Paragraph size="small">
        Jeżeli chcesz wynająć długoterminowo samochód korzystając
        z&nbsp;atrakcyjnych zniżek korporacyjnych poinformuj swojego
        przełożonego, aby się z&nbsp;nami skontaktował i&nbsp;rozpoczął
        współpracę z&nbsp;Car Platform
      </Paragraph>

      <Text as="div" size="xSmall" style={{ marginTop: '3.6vh' }}>
        <SimpleLink
          href="mailto:hello@carplatform.pl"
          style={{ marginLeft: '0.2rem' }}
        >
          hello@carplatform.pl
        </SimpleLink>
        <br />
        <SimpleLink href="tel:+48720811206" style={{ marginLeft: '0.2rem' }}>
          +48 720 811 206
        </SimpleLink>
        <br />
        <SimpleLink
          as={Link}
          to={ContactRoute}
          style={{ marginLeft: '0.2rem' }}
        >
          carplatform.pl/contact
        </SimpleLink>
      </Text>
    </>
  );
};
