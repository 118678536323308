import { useEffect, useMemo } from 'react';
import { CustomerType } from 'src/common/models/customerType';
import { findCheapest } from 'src/app/product/services/offer';
import { useDispatch, useSelector } from 'react-redux';
import { availableOffersSelector } from 'src/app/product/state/products/productsSelectors';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { customerTypeActions } from 'src/app/state/customerType/customerTypeActions';
import { productsActions } from 'src/app/product/state/products/productsActions';

export const useCalculatorInitialOffer = () => {
  const availableOffers = useSelector(availableOffersSelector);
  const dispatch = useDispatch();
  const userCustomerType = useSelector(customerTypeSelector);

  // Initially get offer from URL
  const initialOffer = useMemo(() => {
    const query = new URLSearchParams(location.search);

    const offerDetails = query.get('offerDetails');
    const offerId = query.get('offerId');

    let offer =
      offerId != null
        ? availableOffers.find((a) => a.id === offerId)
        : undefined;

    if (offer && offerDetails) {
      const details = JSON.parse(atob(offerDetails));

      offer = {
        ...offer,
        buyoutPrice: Number(details.buyoutPrice),
        contractPeriod: Number(details.contractPeriod),
        customerType: userCustomerType,
        external: {
          details: {
            ...offer.external?.details,
            down: details.initialPayment,
            month: details.contractPeriod,
            purchase: details.buyoutPrice,
          },
          priceB2B: details.priceNetto,
          priceB2C: details.price,
          type: 'artefakta4internal',
        },
        initialPayment: Number(details.initialPayment),
        price:
          userCustomerType === CustomerType.B2B
            ? details.priceNetto
            : details.price,
      };
    } else if (offer?.external?.type === 'artefakta4internal') {
      return {
        ...offer,
        buyoutPrice: Number(offer.external.details.purchase),
        contractPeriod: Number(offer.external.details.month),
        customerType: userCustomerType,
        initialPayment: Number(offer.external.details.down),
        price:
          userCustomerType === CustomerType.B2B
            ? offer.external.priceB2B
            : offer.external.priceB2C,
      };
    }

    if (!offer) {
      offer = findCheapest(availableOffers, userCustomerType);
    }

    return offer;
  }, []);

  // Set initial offer
  useEffect(() => {
    if (initialOffer) {
      dispatch(customerTypeActions.setCustomerType(initialOffer.customerType));
    }

    dispatch(productsActions.setActiveOffer(initialOffer));
  }, []);

  return initialOffer;
};
