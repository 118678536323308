import React, { ComponentType, FC, PropsWithChildren } from 'react';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { setUserCustomerTypeActions } from 'src/state/auth/authActions';
import { CustomerType } from 'src/common/models/customerType';
import { StyleProps } from '@summer/react-kit';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import {
  ToggleButtonProps,
  ToggleButtonVariant,
} from 'src/common/components/ToggleButton';
import { customerTypeActions } from 'src/app/state/customerType/customerTypeActions';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';

export interface B2BB2CSwitchProps extends StyleProps {
  variant?: ToggleButtonVariant;
  toggleButtonComponent?: ComponentType<PropsWithChildren<ToggleButtonProps>>;
  labels?: [string, string];
  value?: CustomerType;
}

export const B2BB2CSwitch: FC<B2BB2CSwitchProps> = ({
  variant,
  labels = ['Dla mnie', 'Dla firmy'],
  style,
  className,
  toggleButtonComponent,
  value,
}) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const customerType = useSelector(customerTypeSelector);

  const b2bb2cSwitchButtonItems = [
    { id: CustomerType.B2C, label: labels[0] },
    { id: CustomerType.B2B, label: labels[1] },
  ];

  return (
    <HorizontalToggleGroupContainer style={style} className={className}>
      <ToggleButtonGroup
        variant={variant ?? 'primary'}
        limit={1}
        value={[value || customerType]}
        onChange={(v) => {
          if (isLoggedIn) {
            dispatch(setUserCustomerTypeActions.request(v[0] as CustomerType));
          }

          dispatch(
            customerTypeActions.setCustomerType(
              v[0] ? (v[0] as CustomerType) : customerType
            )
          );

          const type = b2bb2cSwitchButtonItems.find((item) => item.id === v[0]);

          type &&
            pushEvent(EventName.ButtonClick, {
              click_category: 'offer_details_purpose',
              click_cta: type.label.toLowerCase().replace(/\s/g, '_'),
            });
        }}
        items={b2bb2cSwitchButtonItems}
        toggleButtonComponent={
          toggleButtonComponent ? toggleButtonComponent : HorizontalToggleButton
        }
      />
    </HorizontalToggleGroupContainer>
  );
};
