import { RouteComponentProps } from '@reach/router';
import React, { FC, memo, useState } from 'react';
import { HomeAdvert } from 'src/app/home/components/HomeAdvert';
import { HomeMobileEntrance } from 'src/app/home/components/HomeMobileEntrance';
import { HomePanel } from 'src/app/home/components/HomePanel';
import { injectHomeAdvertState } from 'src/app/home/state';
import { media } from 'src/config/breakpoints';
import { triStateMapper } from '@summer/react-kit/functions';
import styled, { css } from 'styled-components';
import { HomeDealBanner } from 'src/app/home/components/HomeDealBanner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  ${media.w.max.px768(css`
    &.enter {
      transform: translateY(0);
      transition: transform 600ms ease-in-out;
    }

    &.exit {
      transform: translateY(-100%);
      transition: transform 600ms ease-in-out;
    }
  `)};
`;

injectHomeAdvertState();
import('src/app/search/SearchPage');

const HomePage: FC<RouteComponentProps> = memo(() => {
  const [panelOpen, setPanelOpen] = useState<boolean | null>(null);

  const containerClassName = triStateMapper(
    'exit',
    'enter',
    undefined
  )(panelOpen);
  const panelClassName = triStateMapper('enter', 'exit', undefined)(panelOpen);

  return (
    <>
      <Container className={containerClassName}>
        <HomeAdvert />
        <HomeMobileEntrance onClick={() => setPanelOpen(true)} />
        <HomeDealBanner />
      </Container>

      <HomePanel
        className={panelClassName}
        onBackToAdvert={() => setPanelOpen(false)}
      />
    </>
  );
});

// eslint-disable-next-line import/no-default-export
export default HomePage;
