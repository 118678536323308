import React, { memo } from 'react';
import { Title } from 'src/common/components/typography/Title';
import { Paragraph } from 'src/common/components/typography/Text';
import { Button } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import SuccessImageSvg from 'src/assets/images/couch-chill.svg';

const SuccessContainer = styled.div`
  position: relative;

  ${media.w.max.px768(css`
    border: 1px solid ${colors.primary};
    padding: 0.5rem 0.75rem;
  `)}
`;

const SuccessImage = styled(SuccessImageSvg)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;

  ${media.w.max.px768(css`
    display: none;
  `)}
`;

export interface FormSuccessInfoProps {
  onClick: () => void;
}

export const FormSuccessInfo = memo<FormSuccessInfoProps>(({ onClick }) => (
  <SuccessContainer>
    <SuccessImage />

    <Title level={2} variant="condensed">
      Wiadomość wysłana
    </Title>
    <Paragraph>
      Dziękujemy za wiadomość. Skontaktujemy się z&nbsp;Tobą niezwłocznie.
    </Paragraph>
    <Button kind="link" onClick={onClick}>
      Wyślij kolejną wiadomość
    </Button>
  </SuccessContainer>
));
