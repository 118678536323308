import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { PagesInfographicTitle } from 'src/common/components/PagesInfographicTitle';
import { PagesInfographicText } from 'src/common/components/PagesInfographicText';
import { HowItWorksInfographicContainer } from 'src/public/components/how-it-works/HowItWorksInfographicContainer';
import HowItWorksInfographicSlide6Svg from 'src/assets/images/how-it-works-infographic-slide-6.svg';
import { HowItWorksInfographicCounterWrapper } from 'src/public/components/how-it-works/HowItWorksInfographicCounter';

const Content = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  max-width: 10.625rem;

  ${media.w.min.px768(css`
    bottom: auto;
    left: 55%;
    top: 2rem;
    max-width: 15rem;
  `)};
`;

const StyledHowItWorksInfographicSlide6 = styled(
  HowItWorksInfographicSlide6Svg
)`
  max-width: 100%;
`;

export const HowItWorksInfographicSlide4 = memo(() => (
  <HowItWorksInfographicContainer>
    <HowItWorksInfographicCounterWrapper
      number={4}
      position={{
        left: '15%',
        top: '0',
      }}
      responsive={{
        position: {
          left: '20%',
          top: '2.5rem',
        },
      }}
    >
      <StyledHowItWorksInfographicSlide6 />
    </HowItWorksInfographicCounterWrapper>

    <Content>
      <PagesInfographicTitle>ODBIERASZ SWOJE AUTO</PagesInfographicTitle>
      <PagesInfographicText>
        Samochód odbierzesz od dealera lub w wybranej przez siebie lokalizacji
      </PagesInfographicText>
    </Content>
  </HowItWorksInfographicContainer>
));
