import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4089 0.831092C32.0769 0.332344 32.9934 0.332344 33.6613 0.831092L61.8966 21.9134C62.3725 22.2688 62.6528 22.8278 62.6528 23.4217V56.5512C62.6528 58.6733 61.7739 60.6922 60.2362 62.1683C58.7012 63.642 56.635 64.457 54.4959 64.457H10.5743C8.43524 64.457 6.36906 63.642 4.83401 62.1683C3.29634 60.6922 2.41748 58.6733 2.41748 56.5512V23.4217C2.41748 22.8278 2.69776 22.2688 3.17364 21.9134L31.4089 0.831092ZM6.18219 24.3654V56.5512C6.18219 57.6241 6.62545 58.6694 7.44118 59.4525C8.25953 60.2381 9.38524 60.6923 10.5743 60.6923H54.4959C55.685 60.6923 56.8107 60.2381 57.6291 59.4525C58.4448 58.6694 58.8881 57.6241 58.8881 56.5512V24.3654L32.5351 4.68857L6.18219 24.3654Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2407 32.456C21.2407 31.4164 22.0834 30.5737 23.123 30.5737H41.9466C42.9862 30.5737 43.8289 31.4164 43.8289 32.456V62.5737C43.8289 63.6133 42.9862 64.456 41.9466 64.456C40.907 64.456 40.0642 63.6133 40.0642 62.5737V34.3384H25.0054V62.5737C25.0054 63.6133 24.1626 64.456 23.123 64.456C22.0834 64.456 21.2407 63.6133 21.2407 62.5737V32.456Z"
    />
  </IconBox>
);
