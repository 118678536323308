import { HomeAdvertSlideType } from 'src/app/home/models/homeAdvertSlide';

export const pickSlideBackground = (slide: HomeAdvertSlideType) =>
  window.innerWidth >= 768
    ? slide.background.large
    : slide.background.small ?? slide.background.large;

export enum HomeAdvertSlideStep {
  onlyBackground = 'onlyBackground',
  hidden = 'hidden',
  static = 'static',
  animateIn = 'animate-in',
  animateOut = 'animate-out',
}
