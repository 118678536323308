import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

// eslint-disable-next-line import/no-default-export,react/display-name
export default (props: IconProps) => (
  <IconBox {...props}>
    <path d="M60.897 27.1144C60.196 27.1465 59.2769 27.2656 58.4619 27.4308C56.6933 27.7891 54.8452 28.3471 52.829 29.131C51.0039 29.841 49.0246 30.7605 47.2601 31.7187C47.0707 31.8214 47.0437 31.8403 47.1298 31.8074C47.138 31.8041 47.1396 31.8066 47.1347 31.814C47.1298 31.8222 47.1314 31.8239 47.1413 31.8197C47.1683 31.8099 47.6742 31.6792 47.9915 31.6012C50.2487 31.0448 52.11 30.7704 53.6202 30.7704C55.7733 30.7704 57.0188 31.3308 57.273 32.4147C57.3738 32.8453 57.3115 33.3523 57.0844 33.9464C56.8811 34.4781 56.4908 35.1601 56.071 35.7189C56.0382 35.7625 56.0029 35.8118 55.9939 35.8282C55.9841 35.8455 55.9546 35.889 55.9267 35.9244C55.8775 35.9901 55.8791 35.9959 55.939 35.9539C55.9513 35.9449 55.9611 35.9416 55.9611 35.9466C55.9611 35.9507 55.9578 35.9572 55.9529 35.9597C55.9488 35.9622 55.9447 35.9679 55.9447 35.972C55.9447 35.9802 56.203 35.8192 56.6416 35.5381C58.3471 34.4452 59.8082 33.3983 60.9216 32.4722C62.6213 31.0588 63.6036 29.9199 63.9053 29.0151C64.1168 28.3799 63.9734 27.8861 63.4814 27.5549C63.3879 27.4924 63.1748 27.3873 63.0452 27.3412C62.6984 27.2163 62.2704 27.1416 61.7374 27.1136C61.5603 27.1046 61.1102 27.1046 60.897 27.1144ZM61.8809 27.5245C62.3294 27.5557 62.6713 27.6321 62.946 27.7636C63.2526 27.9099 63.4494 28.1194 63.5404 28.3964C63.611 28.6092 63.6134 28.8615 63.5495 29.1335C63.5257 29.2337 63.4593 29.4392 63.4191 29.5378L63.3904 29.6076L63.3896 29.5353C63.3896 29.4959 63.3863 29.4638 63.383 29.4638C63.3797 29.4638 63.3691 29.4835 63.3601 29.5065L63.3445 29.5501L63.3396 29.3981C63.3273 29.0439 63.2313 28.7579 63.0378 28.4991C62.8861 28.2961 62.6615 28.1153 62.3885 27.9764C62.0031 27.78 61.5357 27.6535 60.9331 27.582C60.8568 27.573 60.7773 27.5656 60.756 27.5656C60.7347 27.5656 60.7158 27.5623 60.7134 27.559C60.7093 27.5524 60.8585 27.5393 61.0979 27.5245C61.2496 27.5154 61.7481 27.5154 61.8809 27.5245Z" />
    <path d="M2.02684 34.2923C0.924049 35.6499 0.0172183 36.7682 0.0106589 36.7773L0 36.7937H3.63798L5.50739 34.8224L5.51149 36.7937H8.44352L10.3121 34.824L10.3162 36.7937H13.4811V31.8222H9.89396L8.4755 33.2931L8.47304 32.5576L8.4714 31.8222L4.03154 31.823L2.02684 34.2923Z" />
    <path d="M20.3274 34.3079C20.3274 35.6753 20.3299 36.7937 20.3299 36.7937C20.3414 36.7954 25.8865 36.843 26.0603 36.843H26.2964L26.2981 36.5086L26.3005 36.1733L26.3497 36.2185C26.5072 36.3631 26.7474 36.5226 26.9565 36.622C27.1975 36.7362 27.4615 36.82 27.7444 36.8718L27.8592 36.8932L29.0883 36.8882L29.2399 36.8611C29.5532 36.8044 29.8409 36.7321 30.1599 36.6294C30.2575 36.5973 30.3395 36.5719 30.3419 36.5719C30.3444 36.5719 30.3468 36.6327 30.3468 36.7074V36.843H33.3067V32.7754H30.3468V32.911C30.3468 32.9858 30.3444 33.0466 30.3411 33.0466C30.3386 33.0466 30.2681 33.0227 30.1861 32.9932C28.8538 32.5198 27.6805 32.4911 26.8335 32.911C26.3989 33.1263 26.0669 33.4509 25.8742 33.8478C25.8069 33.9875 25.7717 34.0836 25.7356 34.2299C25.6963 34.3901 25.6807 34.5175 25.6807 34.6876V34.8298H23.6891V31.8222H20.3274V34.3079ZM29.8418 34.1518C30.1525 34.2061 30.4001 34.3893 30.4952 34.6366C30.6108 34.9341 30.5116 35.2382 30.232 35.4452C29.9385 35.6638 29.504 35.6951 29.162 35.5225C28.953 35.4165 28.807 35.2341 28.7636 35.0237C28.7414 34.9177 28.7422 34.7558 28.7644 34.6744C28.8365 34.4148 29.0956 34.2151 29.4408 34.1526C29.531 34.1362 29.7516 34.1362 29.8418 34.1518Z" />
    <path d="M16.9912 32.5782C16.0278 32.6225 15.2448 32.842 14.6536 33.2323C14.1756 33.547 13.8615 33.9793 13.7681 34.4485C13.7451 34.5627 13.7369 34.7862 13.7517 34.9111C13.7722 35.0878 13.8378 35.2973 13.9255 35.4625C14.0182 35.6384 14.128 35.7838 14.2879 35.9432C14.743 36.396 15.4514 36.7066 16.3098 36.8299C16.623 36.8751 16.8797 36.8923 17.2486 36.8923C18.0366 36.8923 18.8294 36.7806 19.7781 36.5365L19.979 36.4848L19.9814 35.8027L19.9831 35.1198L19.8338 35.1511C18.9918 35.3269 18.176 35.382 17.5479 35.3055C16.8821 35.225 16.4591 34.9892 16.564 34.7566C16.5861 34.7081 16.6632 34.6358 16.7395 34.5923C17.1986 34.3293 18.2227 34.2529 19.4993 34.386C19.6535 34.4024 19.8888 34.4304 19.9527 34.4402L19.9831 34.4452V32.934L19.9445 32.925C19.1394 32.7318 18.49 32.6283 17.8144 32.5864C17.6184 32.5741 17.1707 32.57 16.9912 32.5782Z" />
    <path d="M38.4009 32.579C37.6818 32.6094 37.0964 32.782 36.5987 33.1115C36.5536 33.1411 36.5143 33.1666 36.511 33.1682C36.5069 33.1698 36.5044 33.0819 36.5044 32.9734V32.7754H33.5445V36.843H36.5044V35.4066L36.5364 35.3581C36.5758 35.3006 36.6545 35.2234 36.7217 35.1774C36.9242 35.041 37.2112 34.9645 37.6318 34.9358C37.7696 34.9267 38.2943 34.9284 38.3042 34.9382C38.3066 34.9407 38.3165 34.9793 38.3255 35.0237C38.3714 35.2513 38.4862 35.4872 38.651 35.6959C38.7182 35.7805 38.8822 35.9465 38.9757 36.0238C39.5472 36.4987 40.3818 36.8052 41.3133 36.8841C41.4526 36.8965 42.3521 36.8907 42.5021 36.8767C43.0466 36.8274 43.4623 36.7453 43.8755 36.608C44.0706 36.5423 44.2182 36.4823 44.4043 36.3919C44.6503 36.2719 44.8561 36.1429 45.0357 35.9975C45.1267 35.9235 45.2743 35.7772 45.3374 35.6983C45.3956 35.6244 45.4989 35.46 45.4989 35.4403C45.4989 35.4321 45.3882 35.4296 44.9537 35.4296H44.4084L44.3379 35.4699C43.8821 35.732 43.1999 35.8142 42.5554 35.6844C42.2053 35.6137 41.8774 35.4699 41.6478 35.2858L41.5969 35.2447L45.6047 35.2406L45.6375 35.1396C45.7162 34.8955 45.7334 34.769 45.7113 34.5873C45.6178 33.8157 44.8168 33.1279 43.6172 32.7885C42.4316 32.4533 41.0574 32.5124 39.9645 32.9447C39.8095 33.0063 39.575 33.1189 39.4438 33.1937C39.2052 33.3309 39.0158 33.4722 38.8355 33.6497L38.71 33.7722V32.57L38.6305 32.5716C38.5862 32.5716 38.4829 32.5757 38.4009 32.579ZM42.4726 33.6719C42.7965 33.7023 43.0589 33.8478 43.1581 34.0524C43.1777 34.0935 43.1966 34.1395 43.1991 34.1535L43.2048 34.1806H41.5715V34.1568C41.5715 34.1222 41.6002 34.0343 41.6281 33.9867C41.7388 33.7944 42.0069 33.6711 42.33 33.6637C42.357 33.6637 42.421 33.667 42.4726 33.6719Z" />
    <path d="M50.9842 32.5946C50.4332 32.6291 49.8044 32.7647 49.1427 32.9923C49.0566 33.0219 48.9836 33.0465 48.9803 33.0465C48.9779 33.0465 48.9754 32.9742 48.9754 32.8863V32.7261H46.0155V36.843H48.9262V34.6005L48.9853 34.5544C49.1968 34.3885 49.4723 34.3013 49.7871 34.3013C50.088 34.3013 50.3381 34.3843 50.5087 34.5405L50.5661 34.5931V36.843H53.535L53.5317 35.4354C53.5292 34.4723 53.5251 34.0055 53.5186 33.9546C53.4768 33.6127 53.3595 33.4007 53.0955 33.1903C52.8545 32.9972 52.5404 32.8296 52.2461 32.7359C52.0575 32.6751 51.8418 32.6316 51.6147 32.6069C51.4721 32.5921 51.1359 32.5847 50.9842 32.5946Z" />
  </IconBox>
);
