import React, {
  cloneElement,
  memo,
  MouseEventHandler,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import {
  ClickAwayListener,
  Tooltip as MuiTooltip,
  TooltipProps,
} from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';

const TooltipProxy = memo<
  TooltipProps & {
    children: ReactElement<{
      onClick?: (event: MouseEventHandler) => void;
      onMouseEnter?: (event: MouseEventHandler) => void;
      onMouseLeave?: (event: MouseEventHandler) => void;
    }>;
  }
>(({ children, ...props }) => {
  const [open, setOpen] = useState(false);

  const proxiedChildren = useMemo(
    () =>
      cloneElement(children, {
        onClick: (event: MouseEventHandler) => {
          setOpen(true);
          children.props.onClick?.(event);
        },
        onMouseEnter: (event: MouseEventHandler) => {
          setOpen(true);
          children.props.onMouseEnter?.(event);
        },
        onMouseLeave: (event: MouseEventHandler) => {
          setOpen(false);
          children.props.onMouseLeave?.(event);
        },
      }),
    [children]
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <MuiTooltip
        // https://github.com/mui-org/material-ui/issues/11467
        classes={{ popper: props.className, tooltip: 'tooltip' }}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
        {...props}
      >
        {proxiedChildren}
      </MuiTooltip>
    </ClickAwayListener>
  );
});

const arrowShortEdge = '1.25rem';
const arrowLongEdge = '1.5rem';
const arrowSkew = '1rem';

export const Tooltip = styled(TooltipProxy)`
  & .tooltip {
    padding: 1.125rem;
    color: ${colors.white};
    background-color: ${colors.primary};
    border-radius: 0;
    font-size: ${typography.fontSizeSm};
    font-family: ${typography.fontFamilyCondensed};
    pointer-events: all;
    box-shadow: 0 0 1rem 0.25rem rgba(0, 0, 0, 0.3);

    & .MuiTooltip-arrow {
      &::before {
        background-color: ${colors.primary};
      }
    }

    &.MuiTooltip-tooltipPlacementTop {
      margin-bottom: calc(${arrowShortEdge} + 0.375rem);

      & .MuiTooltip-arrow {
        width: ${arrowLongEdge};
        height: ${arrowShortEdge};
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${arrowSkew}));
        margin-bottom: calc(-1 * ${arrowSkew});
        margin-left: calc(${arrowLongEdge} * -1);

        &::before {
          transform: none;
        }
      }
    }

    &.MuiTooltip-tooltipPlacementRight {
      margin-left: calc(${arrowShortEdge} + 0.375rem);

      & .MuiTooltip-arrow {
        width: ${arrowShortEdge};
        height: ${arrowLongEdge};
        clip-path: polygon(${arrowSkew} 0, 100% 0, 100% 100%, 0 100%);
        margin-left: calc(-1 * ${arrowSkew});
        margin-top: calc(${arrowLongEdge} * -1);

        &::before {
          transform: none;
        }
      }
    }

    &.MuiTooltip-tooltipPlacementBottom {
      margin-top: calc(${arrowShortEdge} + 0.375rem);

      & .MuiTooltip-arrow {
        width: ${arrowLongEdge};
        height: ${arrowShortEdge};
        clip-path: polygon(0 ${arrowSkew}, 100% 0, 100% 100%, 0 100%);
        margin-top: calc(-1 * ${arrowSkew});
        margin-left: calc(${arrowLongEdge} * -1);

        &::before {
          transform: none;
        }
      }
    }

    &.MuiTooltip-tooltipPlacementLeft {
      margin-right: calc(${arrowShortEdge} + 0.375rem);

      & .MuiTooltip-arrow {
        width: ${arrowShortEdge};
        height: ${arrowLongEdge};
        clip-path: polygon(0 0, calc(100% - ${arrowSkew}) 0, 100% 100%, 0 100%);
        margin-right: calc(-1 * ${arrowSkew});
        margin-top: calc(${arrowLongEdge} * -1);

        &::before {
          transform: none;
        }
      }
    }
  }
`;
