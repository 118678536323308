import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Seo } from 'src/public/components/Seo';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import { CompanyConfirmationRoute } from 'src/public/publicRoutes';
import { needConfirmationSelector } from 'src/state/companyConfirmation/companyConfirmationSelectors';
import { App as AppRoot } from 'src/app/App';

/**
 * This is a Gatsby proxy component to start React app.
 * Refer to src/app/AppRoot to make changes to application.
 *
 * This component is served with gatsby-plugin-create-client-paths.
 * SSR cannot be used beneath.
 */

const App = () => {
  const needConfirmation = useSelector(needConfirmationSelector);

  if (isPlatformBrowser() && needConfirmation) {
    navigate(CompanyConfirmationRoute);
    return null;
  }

  return typeof window === 'undefined' ? (
    <Seo />
  ) : (
    <>
      <Seo />
      <Suspense fallback={<></>}>
        <AppRoot />
      </Suspense>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;
