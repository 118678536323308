import React from 'react';
import { OfferInformationIcon } from 'src/app/search/components/OfferInformationIcon';
import { IconCode } from 'src/common/components/Icon';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { useSelector } from 'react-redux';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { countLabelFormatter } from '@summer/react-kit/functions';

const Container = styled.div`
  display: grid;

  grid-template-rows: min-content min-content;
  grid-template-columns: min-content min-content;
  justify-content: space-around;

  grid-row-gap: 3.875rem;

  ${media.w.min.px576(css`
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-template-rows: unset;
    grid-template-columns: unset;
  `)}
`;

const brandCountLabelFormatter = countLabelFormatter(
  '0 marek aut dostępnych od ręki',
  '1 marka aut dostępna od ręki',
  (a) => `${a} marki aut dostępne od ręki`,
  (a) => `${a} marek aut dostępnych od ręki`
);

export const NewProductOfferIconography = () => {
  const brands = useSelector(brandsListSelector);

  return (
    <Container>
      <OfferInformationIcon
        iconCode={IconCode.CarFront}
        label={brandCountLabelFormatter(brands?.length ?? 0)}
      />
      <OfferInformationIcon
        iconCode={IconCode.DiscountTag}
        label="MAX rabaty w jednej ofercie"
      />
      <OfferInformationIcon
        iconCode={IconCode.CashUmbrella}
        label="0% wpłaty własnej, plus pełen pakiet korzyści"
      />
      <OfferInformationIcon
        iconCode={IconCode.LongCheckList}
        label="3 modele finansowania najem, leasing, kredyt"
      />
    </Container>
  );
};
