import React, { memo, useEffect, useState } from 'react';
import { Product } from 'src/app/common/models/product';
import { ProductPageSection } from 'src/app/product/models/productPageSection';
import styled from 'styled-components';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductPageSectionHeading } from 'src/app/product/components/ProductPageSectionHeading';
import { ProductPageTestsSlide } from 'src/app/product/components/ProductPageTests/ProductPageTestsSlide';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { ProductTestGroup } from 'src/app/product/models/productTest';
import {
  prepareProductTests,
  shouldLoop,
} from 'src/app/product/components/ProductPageTests/utils';
import { ProductSectionContainer } from 'src/app/product/components/ProductSectionContainer';
import { GalleryModal } from 'src/app/product/components/GalleryModal';
import { colors } from 'src/config/colors';

SwiperCore.use([Mousewheel]);

const Overlay = styled.div`
  background-color: ${colors.background};
  bottom: 0;
  left: calc(50% + 1440px / 2);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  opacity: 0.95;
`;

const Heading = styled.div`
  margin-bottom: 2.1875rem;
`;

const SwiperContainer = styled.div`
  .swiper-container {
    overflow: visible;
    position: relative;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-wrapper {
    align-items: stretch;
  }
`;

export interface ProductPageTestsProps {
  product: Product;
  section: ProductPageSection;
  onModalToggle: (modalOpen: boolean) => void;
}

export const ProductPageTests = memo<ProductPageTestsProps>(
  ({ product, onModalToggle, section }) => {
    const [groups, setGroups] = useState<ProductTestGroup[]>([]);
    const [loop, setLoop] = useState(false);
    const [selectedSlide, setSelectedSlide] = useState<string | undefined>(
      undefined
    );

    useEffect(() => {
      onModalToggle(selectedSlide != null);
    }, [selectedSlide]);

    useEffect(() => {
      setGroups(prepareProductTests(product.ourTests));
    }, [product]);

    return (
      <div style={{ overflow: 'hidden' }}>
        <ProductSectionContainer>
          <Heading>
            <ProductPageSectionHeading
              icon={section.icon}
              subtitle={section.subtitle}
              title={section.title}
            />
          </Heading>

          {groups.length > 0 && (
            <SwiperContainer>
              <Swiper
                freeMode={true}
                freeModeMomentum={true}
                loop={loop}
                loopedSlides={6}
                mousewheel={{ releaseOnEdges: true, sensitivity: 2 }}
                slidesPerView="auto"
                threshold={5}
                touchReleaseOnEdges={true}
                onFromEdge={(swiper) => setLoop(shouldLoop(swiper))}
                onResize={(swiper) => {
                  setLoop(false);
                  setTimeout(() => setLoop(shouldLoop(swiper)));
                }}
              >
                {groups.map((group, index) => (
                  <SwiperSlide key={index}>
                    <ProductPageTestsSlide
                      group={group}
                      onSlideClick={setSelectedSlide}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperContainer>
          )}

          <ModalPortal
            open={selectedSlide != null}
            onClose={() => setSelectedSlide(undefined)}
          >
            <GalleryModal
              items={product.ourTests}
              initialSlide={product.ourTests.findIndex(
                (a) => a.id === selectedSlide
              )}
            />
          </ModalPortal>
        </ProductSectionContainer>

        <Overlay />
      </div>
    );
  }
);
