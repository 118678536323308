// eslint-disable-next-line import/no-default-export,react/display-name
import { IconBox, IconProps } from 'src/lib/IconBox';
import React from 'react';

// eslint-disable-next-line import/no-default-export
export default (props: IconProps) => (
  <IconBox {...props}>
    <g transform="scale(2.13, 2.37)">
      <path d="M2.5 0H29.5V27H2.5V16.75H3.5V26H28.5V1H3.5V10.25H2.5V0Z" />
      <path d="M18.2929 13.5L12.6465 7.85359L13.3536 7.14648L19.7071 13.5L13.3536 19.8536L12.6465 19.1465L18.2929 13.5Z" />
      <path d="M0 13H19V14H0V13Z" />
    </g>
  </IconBox>
);
