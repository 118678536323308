import React, { FC, ReactNode } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { FieldProps } from 'formik/dist/Field';
import { FieldValidator } from 'formik/dist/types';
import {
  Checkbox,
  CheckboxProps,
  InputWrapper,
} from 'src/common/components/Checkbox';

const StyledCheckbox = styled(Checkbox)`
  margin: 0.5rem 0 0.75rem 0;
  padding: 0 0.5rem 0 0;
  align-items: flex-start;

  ${InputWrapper} {
    padding: 0 0.75rem 0 0;
  }
`;

export interface CheckboxControlProps extends CheckboxProps {
  name: string;
  label: ReactNode;
  validate?: FieldValidator;
}

export const CheckboxControl: FC<CheckboxControlProps> = ({
  name,
  validate,
  onChange: $onChange,
  label,
  ...restProps
}: CheckboxControlProps) => (
  <Field name={name} validate={validate}>
    {({
      field: { name, value },
      form: { setFieldValue, setFieldTouched },
    }: FieldProps<boolean>) => (
      <StyledCheckbox
        name={name}
        value={value}
        label={label}
        onChange={(v) => {
          setFieldTouched(name);
          setFieldValue(name, v);
          $onChange?.(v);
        }}
        {...restProps}
      />
    )}
  </Field>
);

CheckboxControl.displayName = 'CheckboxControl';
